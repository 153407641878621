import {useCallback, useState} from 'react';

import {SourceType} from 'fslightbox-react';

import useAuth from 'auth/provider/useAuth';
import InspectionMediaPreview from 'components/inspection/media_items/InspectionMediaPreview';
import {Lightbox, Modal} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import {MediableItems} from 'models/inspections/MediableItems';
import {AccountRole} from 'models/users/User';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import useInspectionStore from 'stores/InspectionStore';
import {titleize} from 'utilities/StringHelpers';

import InspectionAddActionFromAttachmentModal from '../InspectionAddActionFromAttachmentModal';
import InspectionAddNotesFromAttachmentModal from '../InspectionAddNotesFromAttachmentModal';

const {useModal} = Modal.Imperative;

const InspectionGallery = ({
  item,
  sources,
}: {
  item: InspectionItem;
  sources: MediableItems;
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {currentUser} = useAuth();

  const openModal = useModal();

  const {activeAccountRole} = useLocalUserSettings();

  const inspection = useInspectionStore((state) => state.inspection);

  const handleClick = (index: number) => {
    setSelectedIndex(index + 1);
    setLightboxOpen(!lightboxOpen);
  };

  const onAddNoteClicked = useCallback(
    (attachment: any) => {
      openModal(InspectionAddNotesFromAttachmentModal, {
        inspectionItem: item,
        attachment,
      });
    },
    [openModal, item],
  );

  const onAddActionClicked = useCallback(
    (attachment: any) => {
      openModal(InspectionAddActionFromAttachmentModal, {
        inspectionItem: item,
        attachment,
      });
    },
    [openModal, item],
  );

  const deleteAttachment = useCallback(
    async (source: InspectionItemAttachment | InspectionAction) => {
      const attachId = source.id;

      const result = await source.destroy();

      if (result) {
        if (source.isType('inspection_item_attachments')) {
          item.inspectionItemAttachments =
            item.inspectionItemAttachments.filter((a) => a.id !== attachId);
        } else {
          item.inspectionActions = item.inspectionActions.filter(
            (a) => a.id !== attachId,
          );
        }

        // Purposefully only get these from the store here and not above
        // So we dont hold lots of references to inspectionitems all over the place
        // when its not needed.
        const inspectionItems = useInspectionStore.getState().inspectionItems;
        const setInspectionItems =
          useInspectionStore.getState().setInspectionItems;

        setInspectionItems([...(inspectionItems ?? [])]);
      }
    },
    [item],
  );

  const confirmDeleteAttachment = useCallback(
    (source: InspectionItemAttachment | InspectionAction) => {
      const type = source.attachmentMimeType?.includes('image')
        ? 'Photo'
        : 'Video';

      /**
       * Allow landlords to delete any attachment so long as the inspection is not complete
       * Even if it was taken by a tenant.
       */
      if (activeAccountRole === AccountRole.Landlord) {
        if (
          inspection.status === 'awaiting_inspection' ||
          inspection.status === 'awaiting_sign_offs'
        ) {
          setConfirmationOptions({
            color: 'error',
            title: `Remove ${type}`,
            message: `Are you sure you want to remove this ${type.toLowerCase()}?`,
            buttonTitle: 'Remove',
            action: () => deleteAttachment(source),
          });
        }
      } else if (activeAccountRole === AccountRole.Renter) {
        /**
         * Allow tenants to delete their own attachments so long as the inspection is still underway
         */
        if (
          inspection.status === 'awaiting_inspection' &&
          source.userId === currentUser.id
        ) {
          setConfirmationOptions({
            color: 'error',
            title: `Remove ${type}`,
            message: `Are you sure you want to remove this ${type.toLowerCase()}?`,
            buttonTitle: 'Remove',
            action: () => deleteAttachment(source),
          });
        }
      }
    },
    [
      inspection,
      deleteAttachment,
      currentUser,
      setConfirmationOptions,
      activeAccountRole,
    ],
  );

  return (
    <div>
      <div className="flex flex-wrap flex-start gap-4 my-4">
        {sources.map((source, index) => (
          <InspectionMediaPreview
            source={source}
            key={index}
            onClick={() => handleClick(index)}
            onLongPress={() => confirmDeleteAttachment(source)}
          />
        ))}
      </div>

      <Lightbox
        toggler={lightboxOpen}
        sources={sources.map((source) => source.attachment)}
        types={sources.map((source) => {
          if (source.attachmentMimeType) {
            return source.attachmentMimeType.split('/')[0] as SourceType;
          } else {
            return 'image';
          }
        })}
        slide={selectedIndex}
        disableThumbs
        captions={sources.map((source) => {
          if (source.isType('inspection_item_attachments')) {
            const attachment = source as InspectionItemAttachment;
            return (
              <div key={attachment.id} className="flex flex-col z-50">
                <h5 className="text-white text-lg">
                  {inspection.reportType === 'detailed'
                    ? `${item.room} - ${item.name}`
                    : item.room}
                </h5>
                <p className="text-white text-md">{attachment.notes}</p>

                {activeAccountRole &&
                  activeAccountRole === AccountRole.Landlord &&
                  inspection &&
                  inspection.status !== 'complete' && (
                    <div className="flex justify-between mt-2">
                      <button
                        className="btn btn-info btn-sm"
                        type="button"
                        onClick={() => onAddNoteClicked(attachment)}>
                        {currentUser.id === attachment.userId &&
                        attachment.notes &&
                        attachment.notes.length > 0
                          ? 'Edit Note'
                          : 'Add Note'}
                      </button>

                      <button
                        className="btn btn-warning btn-sm"
                        type="button"
                        onClick={() => onAddActionClicked(attachment)}>
                        Add Action
                      </button>
                    </div>
                  )}
              </div>
            );
          } else {
            const attachment = source as InspectionAction;
            return (
              <div key={attachment.id} className="flex flex-col">
                <h5 className="text-white text-lg">
                  {item.room} - {item.name}
                </h5>
                <p className="text-white text-md">
                  <strong>{titleize(attachment.actionType)} Action: </strong>
                  {attachment.action}
                </p>
              </div>
            );
          }
        })}
      />
    </div>
  );
};

export default InspectionGallery;
