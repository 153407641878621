import {AccountRole} from 'models/users/User';

/**
 * The format that the locally stored current user settings must adhere to.
 */
export interface UserSettings {
  activeAccountRole: AccountRole | null;
}

/**
 * A type guard to ensure that the locally stored settings for the user
 * is still of a valid format, and that the required object shape has
 * not changed since it was last stored.
 */
export const userSettingsIsValidShape = (
  userSettings: unknown,
): userSettings is UserSettings => {
  if (!userSettings || typeof userSettings !== 'object') {
    return false;
  }

  /**
   * Ensure the expected propertes exist as per the type definition above.
   */
  const isValid =
    'activeAccountRole' in userSettings &&
    (userSettings.activeAccountRole === null ||
      (typeof userSettings.activeAccountRole === 'string' &&
        Object.keys(AccountRole).includes(userSettings.activeAccountRole)));

  return isValid;
};
