import React from 'react';

import {Formik} from 'formik';
import {HiOutlineCurrencyDollar} from 'react-icons/hi';

import {InputField} from 'components/forms_fields';
import CopyButton from 'components/forms_fields/CopyButton';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {Card} from 'components_sb/layout';
import {FieldLabel, Paragraph} from 'components_sb/typography';
import CompanyInfoGlobals from 'globals/CompanyInfoGlobals';
import {LeaseDocumentType} from 'models/properties/Tenancy';
import TenancyMembership from 'models/properties/TenancyMembership';
import {toCurrency} from 'utilities/StringHelpers';

const UnpaidBondCard = ({
  tenancyMembership: data,
}: {
  tenancyMembership: TenancyMembership;
}) => {
  if (
    !data.tenancy.managedRentEnabled ||
    data.tenancy.leaseDocumentType ===
      LeaseDocumentType.TenancyServicesBoardingHouse
  ) {
    return null;
  }

  const doNothingOnSubmit = () => {
    'noop';
  };

  if (!data?.bondPaid && data.bondSplit > 0.0) {
    return (
      <Card title="Bond Payment Instructions" icon={HiOutlineCurrencyDollar}>
        <Paragraph>
          Your bond has not been paid, please make a payment as soon as possible
          with these details for your bond to be registered. It is{' '}
          <u>crucial that you enter these details accurately</u> or we will not
          be able to track your payment.
        </Paragraph>

        <Paragraph>
          Please note,{' '}
          <u>
            your bond reference code is different to your rent reference code.
          </u>
        </Paragraph>

        <Paragraph size="sm" secondary>
          It may take up to 24 hours for your bond payment to clear within our
          system.
        </Paragraph>

        <Formik initialValues={{}} onSubmit={doNothingOnSubmit}>
          {(formik) => (
            <>
              <FormRow responsive>
                <FormRowItem>
                  <FieldLabel title="Account Name" />
                  <div className="flex flex-start">
                    <InputField
                      name="bond-account-name"
                      value={CompanyInfoGlobals.bankAccountName}
                      readOnly
                      id="bond-account-name-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: CompanyInfoGlobals.bankAccountName,
                      }}
                    />
                  </div>
                </FormRowItem>
                <FormRowItem>
                  <FieldLabel title="Account Number" />
                  <div className="flex flex-start">
                    <InputField
                      name="bond-account-number"
                      value={CompanyInfoGlobals.bankAccountNumber}
                      readOnly
                      id="bond-account-number-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: CompanyInfoGlobals.bankAccountNumber,
                      }}
                    />
                  </div>
                </FormRowItem>
              </FormRow>
              <FormRow responsive>
                <FormRowItem>
                  <FieldLabel
                    title="Reference (case sensitive)"
                    helpText="Please copy this exactly, and paste it into the reference field. You do not need to provide anything in the particulars or code fields."
                  />
                  <div className="flex flex-start">
                    <InputField
                      name="bond-reference-field"
                      value={data.bondReference}
                      readOnly
                      id="bond-reference-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: data.bondReference,
                      }}
                    />
                  </div>
                </FormRowItem>
                <FormRowItem>
                  <FieldLabel title="Amount ($)" />
                  <div className="flex flex-start">
                    <InputField
                      name="bond-amount-field"
                      value={toCurrency(data?.bondSplit).replace('$', '')}
                      readOnly
                      id="bond-amount-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: data?.bondSplit.toString(),
                      }}
                    />
                  </div>
                </FormRowItem>
              </FormRow>
            </>
          )}
        </Formik>

        <div className="mt-2">
          <Paragraph size="sm" secondary>
            You will receive a notification once your payment clears and this
            warning will dissapear.
          </Paragraph>
        </div>
      </Card>
    );
  }

  return null;
};

export default UnpaidBondCard;
