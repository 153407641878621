import React from 'react';

import moment from 'moment';
import {BsPiggyBank} from 'react-icons/bs';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const InsolvencyRegistrarResultsCard = ({
  report,
}: {
  report: TenantCheckReport;
}) => {
  // Uncomment for test data to check the UI
  // report.bankruptcyInsolvencyResults = {
  //   search_url: '',
  //   results: [
  //     {
  //       name: 'Aaron Rama',
  //       alternate_name: 'Azzzaaa Rama',
  //       link: 'http://google.com',
  //       number: '503534',
  //       multiple_insolvencies: false,
  //       status: 'Bankrupt (Current)',
  //     },
  //   ],
  // };
  if (report.bankruptcyInsolvencyResultsFetchedAt) {
    return (
      <Card title="Insolvency Registrar" icon={BsPiggyBank}>
        {report.bankruptcyInsolvencyResults.results.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Number</TableHeadItem>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Status</TableHeadItem>
              <TableHeadItem>Multiple Insolvencies?</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.bankruptcyInsolvencyResults.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.number}
                  </Hyperlink>
                </TableRowItem>

                <TableRowItem>
                  <p>{result.name}</p>
                  <p className="text-xs">{result.alternate_name}</p>
                </TableRowItem>
                <TableRowItem>{result.status}</TableRowItem>
                {/* Should change this to an icon */}
                <TableRowItem>
                  {result.multiple_insolvencies ? 'Yes' : 'No'}
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the insolvency
            registrar.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.bankruptcyInsolvencyResultsFetchedAt).format(
            DATE_TIME_FORMAT,
          )}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Companies Registrar" icon={BsPiggyBank}>
        <Paragraph>
          Insolvency registrar results are currently being collected and will be
          updated on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default InsolvencyRegistrarResultsCard;
