import {useCallback, useEffect, useMemo, useState} from 'react';

import {useQueryClient} from 'react-query';

import apartmentIcon from 'assets/img/property_types/apartment.png';
import houseIcon from 'assets/img/property_types/house.png';
import townhouseIcon from 'assets/img/property_types/townhouse.png';
import unitIcon from 'assets/img/property_types/unit.png';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {GridSelect} from 'components_sb/input';
import {Modal} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import PrepareTenancySwitchToMigrateModal from '../common/PrepareTenancySwitchToMigrateModal';

const {useModal} = Modal.Imperative;

const OPTIONS = [
  {id: 'House', label: 'House', icon: houseIcon},
  {id: 'Apartment', label: 'Apartment', icon: apartmentIcon},
  {id: 'Townhouse', label: 'Townhouse', icon: townhouseIcon},
  {id: 'Unit', label: 'Unit', icon: unitIcon},
];

const PropertyTypeStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartPropertyTypeStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  const [selectedType, setSelectedType] = useState(
    property.propertyType ?? null,
  );

  const onSelectedTypeChange = useCallback((value: string | null) => {
    setSelectedType(value);
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const savePropertyType = async () => {
    setSubmitting(true);

    if (selectedType) {
      /**
       * Set changes on the property.
       */
      property.propertyType = selectedType;
      property.lastOnboardingStepCompleted = 'new_property_type';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track specific event type.
       */
      const event = {
        Apartment: TrackingService.Event.NewTenancy_SelectApartment,
        House: TrackingService.Event.NewTenancy_SelectHouse,
        Townhouse: TrackingService.Event.NewTenancy_SelectTownhouse,
        Unit: TrackingService.Event.NewTenancy_SelectUnit,
      }[selectedType];
      if (event) {
        TrackingService.trackEvent(event, {
          propertyId: property.id,
        });
      }
      /**
       * Track general completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompletePropertyTypeStep,
        {
          propertyId: property.id,
        },
      );

      setSubmitting(false);
    } else {
      setSubmitting(false);
    }
  };

  const hasCurrentListing = useMemo(
    () => property.hasCurrentListing,
    [property],
  );

  const openModal = useModal();

  const showSwitchToMigrateTenancyModal = useCallback(async () => {
    await openModal(PrepareTenancySwitchToMigrateModal, {property});
  }, [openModal, property]);

  /**
   * The previous button on this step behaves differently depending
   * on whether the user is initially following this onboarding flow
   * versus whether they are preparing a tenancy for a property that
   * has a current listing.
   */
  const onClickPrevious = useMemo(
    () => (hasCurrentListing ? showSwitchToMigrateTenancyModal : 'auto'),
    [hasCurrentListing, showSwitchToMigrateTenancyModal],
  );

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      previous: {
        disabled: tenancy.isPending,
        onClick: onClickPrevious,
      },
      next: {
        onClick: savePropertyType,
        disabled: !selectedType,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      align="center"
      title="What type of property do you have?"
      subtitle={
        <>
          <span>{`Most properties are houses but if you're unsure, `}</span>
          <Hyperlink
            href="https://help.keyhook.com/adding-a-property/type-of-property"
            openInNewTab>
            find out more here.
          </Hyperlink>
        </>
      }>
      <div className="w-full max-w-md">
        <GridSelect
          mode="manual:select"
          multiple={false}
          maxColumns={1}
          options={OPTIONS}
          value={selectedType}
          onChange={onSelectedTypeChange}
        />
      </div>
    </StepContainer>
  );
};

export default PropertyTypeStep;
