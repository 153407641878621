import {Form, Formik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {
  Base64FileField,
  InputField,
  SubmitButton,
  ToggleField,
} from 'components/forms_fields';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Document from 'models/properties/Document';
import Tenancy from 'models/properties/Tenancy';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {errorViewForError} from 'utilities/ErrorHelpers';

const EditDocumentPage = () => {
  const {
    params: {documentId, propertyId},
  } = useRoute();

  const router = useRouter();

  const {data, isLoading, error} = useQuery(
    `edit-document-${documentId}`,
    async () => {
      const doc = await Document.select({
        properties: ['id'],
        tenancies: ['id', 'is_new'],
      })
        .includes('documentable')
        .find(documentId);
      return doc.data;
    },
  );
  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const doc = data;
    doc.assignAttributes(formValues);

    const result = await doc.save();
    if (result) {
      toast.success('Document successfully updated!');
      queryClient.setQueryData(`edit-document-${documentId}`, doc);
      router.navigate(`/properties/${propertyId}`, {reloadCurrent: true});
    } else {
      for (const key of Object.keys(doc.errors)) {
        const message = doc.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }
    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return <Page title="Edit Document" loading />;
  } else {
    if (
      data.documentableType === 'Tenancy' &&
      (data.documentable as Tenancy).isNew &&
      data.documentType !== 'other'
    ) {
      return (
        <Page title="Edit Document">
          {() => (
            <Card title="Document">
              <Paragraph>
                You cannot edit documents that were created by Keyhook.
              </Paragraph>
            </Card>
          )}
        </Page>
      );
    } else {
      const schema =
        data.documentType === 'other'
          ? Yup.object().shape({
              document: Yup.string().optional().min(0).label('Document File'),
              name: Yup.string().label('Document Name').min(1).required(),
            })
          : Yup.object().shape({
              document: Yup.string().required().min(1).label('Document File'),
            });

      return (
        <Page title="Edit Document">
          {() => (
            <Card title="Document">
              <Formik
                initialValues={{
                  name: data.name,
                  document: '',
                  private: data.private,
                }}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={schema}>
                {(formik) => (
                  <Form>
                    <p>
                      You can update this document replacing the file with an
                      updated one below.
                    </p>
                    {data.documentType === 'other' && (
                      <InputField
                        formik={formik}
                        name="name"
                        labelProps={{
                          title: 'Name',
                        }}
                      />
                    )}

                    <div className="mt-4">
                      <Base64FileField
                        formik={formik}
                        name="document"
                        accept=".pdf"
                        labelProps={{
                          title: 'Document',
                          description: 'Please upload a PDF file',
                        }}
                      />
                    </div>

                    <div className="mt-4 w-1/2">
                      <ToggleField
                        name="private"
                        formik={formik}
                        label="Private"
                        helpText="Private documents are not viewable by tenants."
                      />
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Update Document"
                      submittingText="Updating"
                      className="mt-6"
                    />
                  </Form>
                )}
              </Formik>
            </Card>
          )}
        </Page>
      );
    }
  }
};

export default EditDocumentPage;
