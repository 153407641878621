import {useCallback, useMemo, useState} from 'react';

import {
  DocumentDuplicateIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import moment from 'moment';
import {HiOutlinePlus, HiOutlineRefresh, HiOutlineSave} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import AccountingRecordDetailModal from 'components/accounting/AccountingRecordDetailModal';
import AccountingStats from 'components/accounting/AccountingStats';
import AddEditAccountingRecordModal from 'components/accounting/AddEditAccountingRecordModal';
import AddEditAccountingRecurringModal from 'components/accounting/AddEditAccountingRecurringModal';
import ExportPaymentsModal from 'components/landlord_payment/ExportPaymentsModal';
import {Button} from 'components_sb/buttons';
import {Card, Modal} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import {Paragraph} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import AccountingRecord from 'models/accounting/AccountingRecord';
import AccountingRecurringRecord from 'models/accounting/AccountingRecurringRecord';
import Property from 'models/properties/Property';
import {Page} from 'router/components';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';
import {DATE_FORMAT, taxYearDates} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {paginationArray} from 'utilities/paginationArray';
import {toCurrency} from 'utilities/StringHelpers';

const {useModal} = Modal.Imperative;

type FilterType = 'all' | 'expenses' | 'income';

const AccountingRecordIndexPage = () => {
  const [page, setPage] = useState(1);

  const [filterType, setFilterType] = useState<FilterType>('all');

  const [statPeriod, setStatPeriod] = useState('30d');
  const [perPage, setPerPage] = useState(10);

  const isMobile = !useTailwindBreakpoint('sm');

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  // Use an Array here as its easier to have empty array
  // for where query when we want all tenancies
  const [propertyId, setPropertyId] = useState<string[]>([]);

  const propertiesResponse = useQuery(
    `landlord-payments-properties`,
    async () => {
      const properties = await Property.select(['id', 'street_address']).all();
      return properties.data;
    },
  );

  const statAccountingResponse = useQuery(
    ['landlord-accounting-stats', statPeriod, propertyId],
    async () => {
      let dateFilter: string;
      let maxRange = moment().add(10, 'years').format('YYYY-MM-DD');
      if (statPeriod === '30d') {
        dateFilter = moment().subtract(30, 'days').format('YYYY-MM-DD');
      } else if (statPeriod === '90d') {
        dateFilter = moment().subtract(90, 'days').format('YYYY-MM-DD');
      } else if (statPeriod === '6m') {
        dateFilter = moment().subtract(6, 'months').format('YYYY-MM-DD');
      } else if (statPeriod === '1y') {
        dateFilter = moment().subtract(1, 'year').format('YYYY-MM-DD');
      } else {
        const taxRange = taxYearDates();
        dateFilter = taxRange.startDate.format('YYYY-MM-DD');
        maxRange = taxRange.endDate.format('YYYY-MM-DD');
      }

      const records = await AccountingRecord.where({
        date: {gte: dateFilter, lte: maxRange},
      })
        .order({date: 'asc'})
        .where({propertyId})
        .stats({
          expenses_amount: 'sum',
          gst_amount: 'sum',
          total_amount: 'sum',
        })
        .all();

      const stats = {
        expenses: {
          value: Math.abs(records.meta.stats.expenses_amount.sum),
          chartData: records.data
            .map((ar) => Number(ar.subtotalAmount))
            .filter((a) => a < 0),
        },
        gst: {
          value: records.meta.stats.gst_amount.sum,
          chartData: records.data.map((ar) => Number(ar.gstAmount)),
        },
        total: {
          value: records.meta.stats.total_amount.sum,
          chartData: records.data.map((ar) => Number(ar.totalAmount)),
        },
      };

      return stats;
    },
    {keepPreviousData: true},
  );

  const tableAccountingResponse = useQuery(
    [`landlord-accounting-table`, page, perPage, propertyId, filterType],
    async () => {
      let payments = AccountingRecord.page(page)
        .per(perPage)
        .includes(['property', 'accounting_receipts'])
        .order({date: 'desc'})
        .select({
          properties: ['street_address'],
        })
        .where({propertyId})
        .stats({
          total: 'count',
        });

      if (filterType === 'expenses') {
        payments = payments.where({subtotalAmount: {lt: 0}});
      } else if (filterType === 'income') {
        payments = payments.where({subtotalAmount: {gt: 0}});
      }

      const resp = await payments.all();

      return resp;
    },
    {keepPreviousData: true},
  );

  const recurringAccountingResponse = useQuery(
    [`landlord-recurring-table`, propertyId],
    async () => {
      const payments = await AccountingRecurringRecord.per(200)
        .includes('property')
        .order({next_trigger_date: 'desc'})
        .select({
          properties: ['street_address'],
        })
        .where({propertyId})
        .all();
      return payments;
    },
    {keepPreviousData: true},
  );

  let datePeriod: string;
  if (statPeriod === '30d') {
    datePeriod = 'Last 30 Days';
  } else if (statPeriod === '90d') {
    datePeriod = 'Last 90 days';
  } else if (statPeriod === '6m') {
    datePeriod = 'Last 6 months';
  } else if (statPeriod === '1y') {
    datePeriod = 'Last 1 year';
  } else {
    const taxRange = taxYearDates();
    datePeriod = `Tax year (${taxRange.startDate.format(
      DATE_FORMAT,
    )} - ${taxRange.endDate.format(DATE_FORMAT)})`;
  }

  const openModal = useModal();
  const openExportModal = useCallback(
    () => openModal(ExportPaymentsModal, {}),
    [openModal],
  );

  const openAddRecurringModal = useCallback(() => {
    if (propertiesResponse.data.length > 0) {
      openModal(AddEditAccountingRecurringModal, {
        accountingRecurringRecord: new AccountingRecurringRecord(),
      });
    } else {
      toast.error('Please add a property first');
    }
  }, [propertiesResponse.data, openModal]);

  const openEditRecurringModal = (arr: AccountingRecurringRecord) => {
    openModal(AddEditAccountingRecurringModal, {
      accountingRecurringRecord: arr,
    });
  };

  const confirmDeleteRecurringRecord = (arr: AccountingRecurringRecord) => {
    setConfirmationOptions({
      title: 'Delete Recurring Transaction',
      message: `Are you sure you want to delete this recurring transaction?`,
      action: () => deleteRecurringRecord(arr),
      buttonTitle: 'Confirm',
      color: 'error',
    });
  };

  const deleteRecurringRecord = async (arr: AccountingRecurringRecord) => {
    const result = await arr.destroy();

    if (result) {
      toast.success('Recurring transaction was sucessfully deleted');

      queryClient.invalidateQueries(['landlord-accounting-table']);
      queryClient.invalidateQueries(['landlord-accounting-stats']);
    } else {
      toast.error(
        'There was an error while trying to delete this recurring transaction',
      );
    }
  };

  const openAddRecordModal = useCallback(() => {
    if (propertiesResponse.data.length > 0) {
      openModal(AddEditAccountingRecordModal, {
        accountingRecord: new AccountingRecord(),
      });
    } else {
      toast.error('Please add a property first');
    }
  }, [propertiesResponse.data, openModal]);

  const openEditRecordModal = (ar: AccountingRecord) => {
    openModal(AddEditAccountingRecordModal, {
      accountingRecord: ar,
    });
  };

  const confirmDeleteRecord = (ar: AccountingRecord) => {
    setConfirmationOptions({
      title: 'Delete Transaction',
      message: `Are you sure you want to delete this transaction?`,
      action: () => deleteRecord(ar),
      buttonTitle: 'Confirm',
      color: 'error',
    });
  };

  const deleteRecord = async (ar: AccountingRecord) => {
    const result = await ar.destroy();

    if (result) {
      toast.success('Transaction was sucessfully deleted');

      queryClient.invalidateQueries(['landlord-accounting-table']);
      queryClient.invalidateQueries(['landlord-accounting-stats']);
    } else {
      toast.error('There was an error while trying to delete this transaction');
    }
  };

  const showRecordModal = (arr: AccountingRecord) => {
    openModal(AccountingRecordDetailModal, {
      accountingRecord: arr,
    });
  };

  const renderPropertySelect = () => {
    if (propertiesResponse.data) {
      return (
        <div className="mb-4">
          <select
            className="select select-lg w-full"
            onChange={(e) => {
              const val = e.currentTarget.value;
              if (val === '0') {
                setPropertyId([]);
              } else {
                setPropertyId([val]);
              }
            }}>
            <option value="0">All Properties</option>
            {propertiesResponse.data.map((p) => (
              <option value={p.id} key={p.id}>
                {p.streetAddress}
              </option>
            ))}
          </select>
        </div>
      );
    } else {
      return null;
    }
  };

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () => [
      {
        label: 'Export',
        icon: HiOutlineSave,
        onClick: openExportModal,
      },
      {
        label: 'Add transaction',
        icon: HiOutlinePlus,
        onClick: openAddRecordModal,
      },
      {
        label: 'Add recurring transaction',
        icon: HiOutlineRefresh,
        onClick: openAddRecurringModal,
      },
    ],
    [openExportModal, openAddRecordModal, openAddRecurringModal],
  );

  if (statAccountingResponse.error) {
    return errorViewForError(statAccountingResponse.error);
  } else if (tableAccountingResponse.isLoading && page === 1) {
    return <Page title="Financials" loading />;
  } else if (
    tableAccountingResponse.data &&
    tableAccountingResponse.data.data.length === 0
  ) {
    return (
      <Page title="Financials" actions={pageActions}>
        {() => (
          <>
            {renderPropertySelect()}
            <EmptyContentPlaceholder
              type="financials"
              message="No financial records have been added"
            />
            <div className="text-center max-w-2xl mx-auto">
              <Paragraph size="sm" secondary>
                Keyhook will automatically add any records here when using our
                Smart Rent feature, you can also add any income or expenses you
                wish to track by clicking the option in the Actions menu.
              </Paragraph>
            </div>
          </>
        )}
      </Page>
    );
  } else {
    const total = tableAccountingResponse.data.meta.stats.total.count;
    const maxPages = Math.ceil(total / perPage);

    return (
      <Page title="Financials" actions={pageActions}>
        {() => (
          <>
            {renderPropertySelect()}
            <div className="flex flex-col lg:flex-row justify-between lg:items-center space-y-2 lg:space-y-0 mt-4 mb-2">
              <h5 className="text-xl font-semibold">{datePeriod}</h5>
              <div className="btn-group">
                {['30d', '90d', '6m', '1y', 'Tax Year'].map((period) => (
                  <button
                    key={period}
                    className={`w-auto btn btn-sm btn-${
                      statPeriod === period ? 'primary' : 'neutral'
                    }`}
                    onClick={() => setStatPeriod(period)}
                    type="button">
                    {period}
                  </button>
                ))}
              </div>
            </div>

            {statAccountingResponse.data && (
              <AccountingStats
                stats={[
                  {
                    label: 'Net Income',
                    value: statAccountingResponse.data.total.value,
                    chartLineColour: 'rgba(74, 222, 128, 1)',
                    chartFillColour: 'rgba(74, 222, 128, 0.2)',
                    chartData: statAccountingResponse.data.total.chartData,
                  },
                  {
                    label: 'GST Incurred',
                    value: statAccountingResponse.data.gst.value,
                    chartLineColour: 'rgba(251, 191, 36, 1)',
                    chartFillColour: 'rgba(251, 191, 36, 0.2)',
                    chartData: statAccountingResponse.data.gst.chartData,
                  },
                  {
                    label: 'Expenses',
                    value: statAccountingResponse.data.expenses.value,
                    chartLineColour: 'rgba(248, 113, 113, 1)',
                    chartFillColour: 'rgba(248, 113, 113, 0.2)',
                    chartData: statAccountingResponse.data.expenses.chartData,
                  },
                ]}
              />
            )}

            <div className="mt-8">
              <div className="flex flex-col lg:flex-row justify-between items-center mb-2">
                <h5 className="mb-0 font-xl font-semibold">
                  Financial History
                </h5>

                <div className="flex justify-end items-center gap-4">
                  <div className="flex justify-end items-center gap-2">
                    <label className="label cursor-pointer">
                      <span className="label-text mr-2">All</span>
                      <input
                        type="radio"
                        name="filterType"
                        className="radio radio-primary"
                        checked={filterType === 'all'}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.checked) {
                            setFilterType('all');
                            setPage(1);
                          }
                        }}
                      />
                    </label>

                    <label className="label cursor-pointer">
                      <span className="label-text mr-2">Expenses</span>
                      <input
                        type="radio"
                        name="filterType"
                        className="radio radio-primary"
                        checked={filterType === 'expenses'}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.checked) {
                            setFilterType('expenses');
                            setPage(1);
                          }
                        }}
                      />
                    </label>

                    <label className="label cursor-pointer">
                      <span className="label-text mr-2">Income</span>
                      <input
                        type="radio"
                        name="filterType"
                        className="radio radio-primary"
                        checked={filterType === 'income'}
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.checked) {
                            setFilterType('income');
                            setPage(1);
                          }
                        }}
                      />
                    </label>
                  </div>
                  <select
                    className="select select-bordered select-sm py-0"
                    onChange={(e) => setPerPage(Number(e.target.value))}
                    value={perPage}>
                    {[10, 25, 50, 100].map((count) => (
                      <option key={count} value={count}>
                        {count}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {isMobile ? (
                <div className="flex flex-col space-y-2">
                  {tableAccountingResponse.data.data &&
                    tableAccountingResponse.data.data.map((ar) => (
                      <Card key={ar.id}>
                        <div className="flex flex-col">
                          <strong>{moment(ar.date).format(DATE_FORMAT)}</strong>
                          <span className="text-sm">
                            {ar.property.streetAddress}
                          </span>
                          <span className="text-xs text-gray-500">
                            {ar.description}
                          </span>
                        </div>

                        <div className="grid grid-cols-3">
                          <div className="flex flex-col items-center">
                            <span className="text-xs">Subtotal</span>
                            <strong className="text-sm">
                              {toCurrency(ar.subtotalAmount)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">GST</span>
                            <strong className="text-sm">
                              {toCurrency(ar.gstAmount)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">Total</span>
                            <strong className="text-sm">
                              {toCurrency(ar.totalAmount)}
                            </strong>
                          </div>

                          <div className="mt-2 flex justify-between gap-2">
                            <Button
                              label="View Receipts"
                              size="sm"
                              mode="manual"
                              onClick={() => showRecordModal(ar)}
                              category="secondary"
                            />

                            <Button
                              label="Edit"
                              size="sm"
                              mode="manual"
                              onClick={() => openEditRecordModal(ar)}
                              category="secondary"
                            />

                            <Button
                              label="Delete"
                              size="sm"
                              mode="manual"
                              onClick={() => confirmDeleteRecord(ar)}
                              category="secondary"
                            />
                          </div>
                        </div>
                      </Card>
                    ))}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="table table-zebra w-full">
                    <thead className="thead-light">
                      <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Subtotal</th>
                        <th>GST</th>
                        <th>Total</th>
                        <th>Property</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableAccountingResponse.data.data &&
                        tableAccountingResponse.data.data.map((ar) => (
                          <tr key={ar.id} className="hover">
                            <td>{moment(ar.date).format(DATE_FORMAT)}</td>
                            <td>{ar.description}</td>
                            <td>{toCurrency(ar.subtotalAmount)}</td>
                            <td>{toCurrency(ar.gstAmount)}</td>
                            <td>{toCurrency(ar.totalAmount)}</td>
                            <td>{ar.property.streetAddress}</td>
                            <td>
                              <div className="flex justify-start gap-4">
                                <a
                                  onClick={() => showRecordModal(ar)}
                                  className="cursor-pointer tooltip"
                                  data-tip="View Receipts">
                                  <DocumentDuplicateIcon className="w-5 h-5" />
                                </a>

                                <a
                                  onClick={() => openEditRecordModal(ar)}
                                  className="cursor-pointer tooltip"
                                  data-tip="Edit">
                                  <PencilAltIcon className="w-5 h-5" />
                                </a>

                                <a
                                  onClick={() => confirmDeleteRecord(ar)}
                                  className="cursor-pointer tooltip"
                                  data-tip="Delete Transaction">
                                  <TrashIcon className="w-5 h-5" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="flex flex-wrap justify-center mt-8">
              <div className="btn-group">
                <button
                  className="btn w-auto"
                  onClick={() => setPage(page - 1)}
                  disabled={page == 0}>
                  Prev
                </button>
                {paginationArray(page - 1, maxPages).map((pageIter) =>
                  pageIter === '...' ? (
                    <button key={pageIter} className="btn w-auto btn-disabled">
                      {pageIter}
                    </button>
                  ) : (
                    <button
                      key={pageIter}
                      className={`btn w-auto ${
                        page === Number(pageIter) ? 'btn-active' : ''
                      }`}
                      onClick={() => setPage(Number(pageIter))}>
                      {pageIter}
                    </button>
                  ),
                )}
                <button
                  className="btn w-auto"
                  onClick={() => setPage(page + 1)}
                  disabled={tableAccountingResponse.data.data.length < perPage}>
                  Next
                </button>
              </div>
            </div>

            <div className="mt-8">
              <h5 className="mb-0 font-xl font-semibold">
                Recurring Transactions
              </h5>
              {isMobile ? (
                <div className="flex flex-col space-y-2">
                  {recurringAccountingResponse.data &&
                    recurringAccountingResponse.data.data &&
                    recurringAccountingResponse.data.data.map((arr) => (
                      <Card key={arr.id}>
                        <div className="flex flex-col">
                          <strong>
                            {moment(arr.nextTriggerDate).format(DATE_FORMAT)}
                          </strong>
                          <span className="text-sm">
                            {arr.property.streetAddress}
                          </span>
                          <span className="text-xs text-gray-500">
                            {arr.description}
                          </span>
                        </div>

                        <div className="grid grid-cols-3">
                          <div className="flex flex-col items-center">
                            <span className="text-xs">Subtotal</span>
                            <strong className="text-sm">
                              {toCurrency(arr.subtotalAmount)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">GST</span>
                            <strong className="text-sm">
                              {toCurrency(arr.gstAmount)}
                            </strong>
                          </div>

                          <div className="flex flex-col items-center">
                            <span className="text-xs">Total</span>
                            <strong className="text-sm">
                              {toCurrency(arr.totalAmount)}
                            </strong>
                          </div>

                          <div className="mt-2 flex justify-between gap-2">
                            <Button
                              label="Edit"
                              size="sm"
                              mode="manual"
                              onClick={() => openEditRecurringModal(arr)}
                              category="secondary"
                            />

                            <Button
                              label="Delete"
                              size="sm"
                              mode="manual"
                              onClick={() => confirmDeleteRecurringRecord(arr)}
                              category="secondary"
                            />
                          </div>
                        </div>
                      </Card>
                    ))}
                </div>
              ) : (
                <div className="overflow-x-auto">
                  <table className="table table-zebra w-full">
                    <thead className="thead-light">
                      <tr>
                        <th>Next Transaction Date</th>
                        <th>Description</th>
                        <th>Subtotal</th>
                        <th>GST</th>
                        <th>Total</th>
                        <th>Property</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recurringAccountingResponse.data &&
                        recurringAccountingResponse.data.data &&
                        recurringAccountingResponse.data.data.map((arr) => (
                          <tr key={arr.id} className="hover">
                            <td>
                              {moment(arr.nextTriggerDate).format(DATE_FORMAT)}
                            </td>
                            <td>{arr.description}</td>
                            <td>{toCurrency(arr.subtotalAmount)}</td>
                            <td>{toCurrency(arr.gstAmount)}</td>
                            <td>{toCurrency(arr.totalAmount)}</td>
                            <td>{arr.property.streetAddress}</td>
                            <td>
                              <div className="flex justify-start gap-4">
                                <a
                                  onClick={() => openEditRecurringModal(arr)}
                                  className="cursor-pointer tooltip"
                                  data-tip="Edit">
                                  <PencilAltIcon className="w-5 h-5" />
                                </a>

                                <a
                                  onClick={() =>
                                    confirmDeleteRecurringRecord(arr)
                                  }
                                  className="cursor-pointer tooltip"
                                  data-tip="Delete Transaction">
                                  <TrashIcon className="w-5 h-5" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </>
        )}
      </Page>
    );
  }
};

export default AccountingRecordIndexPage;
