import React, {
  useCallback,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {Network} from '@capacitor/network';
import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {TextareaField} from 'components/forms_fields';
import {ModalDefinition} from 'components_sb/layout';
import {useInspectionUploader} from 'providers/InspectionUploader';
import useInspectionStore, {PendingUploadRow} from 'stores/InspectionStore';

type FormValues = {
  notes: string;
};

const InspectionAddActionPhotoModal: ModalDefinition = {
  title: 'Add a photo with notes',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, inspectionItem, inspection, filePath, size} = props;

    const inspectionUploader = useInspectionUploader();

    const [networkConnected, setNetworkConnected] = useState<boolean>(true);

    const database = useInspectionStore((state) => state.database);

    useEffect(() => {
      Network.addListener('networkStatusChange', (status) => {
        setNetworkConnected(status.connected);
      });

      return () => {
        Network.removeAllListeners();
      };
    }, []);

    const handleSubmit = useCallback(
      async (formValues: FormValues, formikHelpers: any) => {
        const doc = {
          data: filePath,
          attachmentType: 'note',
          inspectionId: inspection.id,
          inspectionItemId: inspectionItem.id,
          notes: formValues.notes,
          _id: new Date().getTime().toString(),
          size: size,
        } as PendingUploadRow;
        const result = await database.put(doc);

        if (result.ok && networkConnected) {
          if (!inspectionUploader.isRunning) {
            try {
              inspectionUploader.processQueue(inspection.id);
            } catch (e) {
              console.log(e);
            }
          }
          formikHelpers.setSubmitting(false);
          closeModal();

          toast.success('Note successfully added!');
        } else {
          toast.error('There was an issue adding the note.');
        }

        formikHelpers.setSubmitting(false);
        closeModal();
      },
      [
        closeModal,
        database,
        filePath,
        inspection,
        inspectionItem,
        inspectionUploader,
        networkConnected,
        size,
      ],
    );

    const formik = useFormik<FormValues>({
      initialValues: {notes: ''},
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        notes: Yup.string().required().min(5).label('Notes'),
      }),
    });

    const onSave = useCallback(async () => {
      const result = await formik.validateForm();

      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        <div className="mt-2 flex justify-center">
          <img
            src={Capacitor.convertFileSrc(filePath)}
            className="h-[150px] object-fit"
          />
        </div>

        <div className="mt-2">
          <TextareaField
            formik={formik}
            labelProps={{title: 'Notes', size: 'base'}}
            name="notes"
            placeholder="Write your notes here"
          />
        </div>
      </>
    );
  },
};

export default InspectionAddActionPhotoModal;
