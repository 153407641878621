import {FunctionComponent} from 'react';

import clsx from 'clsx';
import {IconType} from 'react-icons/lib';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';

import Paragraph from '../Paragraph/Paragraph';

export type FieldLabelSize = 'base' | 'lg' | 'xl';

export interface FieldLabelProps {
  title: string;
  icon?: IconType;
  /**
   * A description for the field, shown below the title.
   */
  description?: string | string[];
  helpText?: string;
  htmlFor?: string;
  required?: boolean;
  size?: FieldLabelSize;
  noMargin?: boolean;
}

type FieldLabelComponent = FunctionComponent<FieldLabelProps>;

/**
 * A label for describing fields within a form.
 */
const FieldLabel: FieldLabelComponent = ({
  title,
  icon: Icon,
  description,
  helpText,
  htmlFor,
  required = false,
  size = 'base',
  noMargin = false,
}) => (
  <label
    htmlFor={htmlFor}
    className={clsx(
      'flex flex-col',
      size === 'base' && clsx('text-base gap-y-1', !noMargin && 'mb-3'),
      size === 'lg' && clsx('text-lg gap-y-2', !noMargin && 'mb-4'),
      size === 'xl' && clsx('text-xl gap-y-3', !noMargin && 'mb-5'),
    )}>
    <span className="flex flex-row gap-x-2 items-center">
      {!!Icon && (
        <Icon
          className={clsx(
            'text-brand-200',
            size === 'base' && 'w-5 h-5',
            size === 'lg' && 'w-6 h-6',
            size === 'xl' && 'w-8 h-8',
          )}
        />
      )}
      <span className="font-medium text-brand-850">
        {title}
        {required && <span className="ml-1 text-red-500 font-medium">*</span>}
      </span>

      {!!helpText && <HelpTextPresenter>{helpText}</HelpTextPresenter>}
    </span>
    {description && (
      <div className="max-w-2xl">
        <Paragraph secondary size="sm">
          {Array.isArray(description)
            ? description.map((line) => (
                <span key={line}>
                  {line}
                  <br />
                </span>
              ))
            : description}
        </Paragraph>
      </div>
    )}
  </label>
);

export default FieldLabel;
