import React from 'react';

import moment from 'moment';
import {HiCheck, HiX} from 'react-icons/hi';

import logo from 'assets/img/logos/keyhook/logo-main.png';
import PrintableMessages from 'components/chat/PrintableMessages';
import InspectionPrintItem from 'components/inspection/InspectionPrintItem';
import SignOffView from 'components/inspection/sign_offs/SignOffView';
import {Divider} from 'components_sb/layout';
import {Title} from 'components_sb/typography';
import Inspection from 'models/inspections/Inspection';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';

const helpTexts = {
  'Lights/Power points':
    'Are all lights and power points in the property working?',
  'Smoke alarms':
    "Test all smoke alarms in the property to check if they're working.",
} as Record<string, string>;

interface Props {
  inspection: Inspection;
  inspectionItems: Record<string, InspectionItem[]>;
}

const PrintableInspectionList = React.forwardRef((props: Props, ref) => {
  const {inspection, inspectionItems} = props;

  let title: string;
  if (inspection.inspectionPeriodType === 'pre_tenancy') {
    title = 'Initial Inspection';
  } else if (inspection.inspectionPeriodType === 'final') {
    title = 'Final Inspection';
  } else {
    title = 'Routine Inspection';
  }

  const sortAttachments = (
    item: InspectionItem,
  ): (InspectionItemAttachment | InspectionAction)[] => {
    const attachments = item.inspectionItemAttachments;
    const actions = item.inspectionActions;

    const sorted = [...attachments, ...actions].sort((a, b) => {
      // Check if both objects are InspectionActions
      const isActionA = a.isType('inspection_actions');
      const isActionB = b.isType('inspection_actions');

      // Handle InspectionActions first
      if (isActionA && isActionB) {
        // Compare InspectionActions by createdAt using moment.js
        const momentA = moment(a.createdAt);
        const momentB = moment(b.createdAt);
        return momentA.diff(momentB);
      } else if (isActionA) {
        // InspectionAction should appear before InspectionItemAttachment
        return -1;
      } else if (isActionB) {
        // InspectionAction should appear before InspectionItemAttachment
        return 1;
      } else {
        // Both are InspectionItemAttachments
        // Sort by notes existence (attachments with notes first)
        const aa = a as InspectionItemAttachment;
        const bb = b as InspectionItemAttachment;
        if (aa.notes && !bb.notes) {
          return -1;
        } else if (!aa.notes && bb.notes) {
          return 1;
        } else {
          // If both have notes or neither have notes, don't change their order
          return 0;
        }
      }
    });

    return sorted;
  };

  const renderRoomCard = (roomKey: string) => {
    const items = inspectionItems[roomKey];

    if (roomKey === 'All') {
      return null;
    }

    if (inspection.reportType === 'detailed') {
      const filteredItems = items.filter(
        (i) =>
          i.inspectionItemAttachments.length > 0 ||
          i.inspectionActions.length > 0,
      );

      if (filteredItems.length === 0) {
        return null;
      }

      return (
        <div key={roomKey} className="mb-8">
          {filteredItems.map((item, index) => (
            <div className="my-2" key={index}>
              <div className="mb-4">
                <Title level="h6" size="sm">
                  {item.room} - {item.name}
                </Title>
              </div>

              <div className="flex flex-wrap justify-start items-center">
                {sortAttachments(item).map((a, index) => (
                  <InspectionPrintItem
                    inspection={inspection}
                    item={a}
                    key={index}
                  />
                ))}
              </div>
            </div>
          ))}

          <Divider orientation="horizontal" />
        </div>
      );
    } else {
      const item = items[0];

      if (
        item.inspectionItemAttachments.length === 0 &&
        item.inspectionActions.length === 0
      ) {
        return null;
      }

      return (
        <div className="my-2">
          <div className="mb-4">
            <Title level="h6" size="sm">
              {item.room} - {item.name}
            </Title>
          </div>

          {sortAttachments(item).map((a, index) => (
            <InspectionPrintItem inspection={inspection} item={a} key={index} />
          ))}
        </div>
      );
    }
  };

  const renderSignOffs = () => {
    return (
      <div className="break-before-page">
        <Title level="h6" size="sm">
          Sign Offs
        </Title>
        <div className="mt-4 flex flex-col gap-4">
          {inspection.inspectionSignOffs.map((signOff) => (
            <SignOffView signOff={signOff} key={signOff.id} />
          ))}
        </div>
      </div>
    );
  };

  const renderAllRoom = () => {
    const all = inspectionItems.All;

    if (all) {
      return (
        <div>
          <Title level="h5" size="sm">
            All Rooms
          </Title>
          {all.map((item, index) => {
            const helpText = helpTexts[item.name];
            return (
              <div key={index} className={index > 0 ? 'mt-4' : ''}>
                <div className="flex flex-row gap-x-2 justify-between items-center">
                  <div className="flex flex-col">
                    <strong>{item.name}</strong>
                    <p>{helpText}</p>
                  </div>

                  <div className="flex-1 h-px bg-brand-50" />
                  {item.toggleState ? (
                    <HiCheck className="text-green-500 w-6 h-6" />
                  ) : (
                    <HiX className="text-red-500 w-6 h-6" />
                  )}
                </div>

                {item.inspectionItemAttachments.map((a) => (
                  <InspectionPrintItem
                    inspection={inspection}
                    item={a}
                    key={a.id}
                  />
                ))}

                {item.inspectionActions.map((a) => (
                  <InspectionPrintItem
                    inspection={inspection}
                    item={a}
                    key={a.id}
                  />
                ))}
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div ref={ref as any}>
      <Title level="h3">{title}</Title>
      <p className="text-secondary mt-[-8px]">
        {inspection.tenancy.property.streetAddress},
        {inspection.tenancy.property.suburb},{inspection.tenancy.property.city},
      </p>
      <p className="text-secondary">
        {moment(inspection?.finalizedDate).format(
          inspection?.newInspectionTimeFormat,
        )}
      </p>

      <div className="break-after-page">
        {Object.keys(inspectionItems).map(renderRoomCard)}
      </div>

      <div className="break-after-page">{renderAllRoom()}</div>

      <PrintableMessages
        chatableId={inspection.id}
        chatableType="Inspection"
        disableMedia
      />

      {renderSignOffs()}

      <div className="page-header">
        <img src={logo} alt="Keyhook Logo" className="w-24 h-10 object-fit" />
      </div>

      <div className="page-footer">
        <div className="flex justify-between items-center">
          <p>{inspection.tenancy.property.streetAddress} Inspection,</p>
          <p>
            {moment(inspection?.finalizedDate).format(
              inspection?.newInspectionTimeFormat,
            )}
          </p>
        </div>
      </div>
    </div>
  );
});

export default PrintableInspectionList;
