import clsx from 'clsx';
import {type FormikProps} from 'formik';
import moment from 'moment';
import Datetime, {DatetimepickerProps} from 'react-datetime';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import TrackingService from 'services/TrackingService';
import {DATE_FORMAT} from 'utilities/DateHelpers';

import './DateField.css';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: {
    input: clsx(
      'w-full max-w-full box-border', // Size
      'transition-all duration-300', // Transition
      'bg-brand-50 hover:bg-brand-75 ', // Background
      'placeholder-brand-800 placeholder-opacity-30', // Placeholder
      'outline-none border-none ring-1 ring-brand-75', // Untouched
      'focus:ring-2 focus:ring-brand-500', // Focus
      'text-brand-850', // Text
    ),
  },

  // Classes for disabled state
  disabled: {
    input: clsx(
      // Disable interaction
      'pointer-events-none',
      // Opacity
      'opacity-50',
      // Cursor
      'cursor-not-allowed',
    ),
  },

  // Classes for error state
  error: {
    // These classes are set as important to override default hover/focus states
    input: clsx('!ring-2 !ring-error'),
  },
  // Classes based on size
  size: {
    label: {
      base: clsx(
        // Text
        'text-base',
      ),
      lg: clsx(
        // Text
        'text-base',
      ),
    },
    input: {
      base: clsx(
        // Text
        'text-base',
        // Padding
        'px-4',
        // Roundness
        'rounded-lg',
      ),
      lg: clsx(
        // Text
        'text-base',
        // Padding
        'px-6',
        // Roundness
        'rounded-xl',
      ),
    },
  },
};

interface DateTimeFieldProps extends DatetimepickerProps {
  formik: FormikProps<any>;
  minDate: Date;
  maxDate: Date;
  label: string;
  name: string;
  helpText?: string;
  disabled?: boolean;
  placeholder?: string;
}

const DatetimeField = ({
  formik,
  label,
  name,
  minDate = new Date(),
  maxDate = new Date(),
  helpText,
  ...rest
}: DateTimeFieldProps) => {
  const classN = formik.errors[name] ? ' border border-danger' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  const valid = (currentDate: moment.Moment): boolean => {
    return (
      currentDate.isSameOrAfter(moment(minDate)) &&
      currentDate.isSameOrBefore(moment(maxDate))
    );
  };

  const value = valueFor(formik, name);
  const errors = errorsFor(formik, name);

  return (
    <>
      <label className="label flex justify-start items-center">
        <span className="label-text mr-2">{label}</span>
        <HelpTextPresenter>{helpText}</HelpTextPresenter>
      </label>
      <Datetime
        value={value ? moment(value) : ''}
        dateFormat={DATE_FORMAT}
        onChange={(val) => {
          const m = moment(val);
          formik.setFieldValue(name, m.toISOString());
          TrackingService.trackEvent(TrackingService.Event.FillField, {
            field: name,
          });
        }}
        isValidDate={valid}
        inputProps={{
          className: clsx(
            classes.base.input,
            classes.size.input['base'],
            !!errors && classes.error.input,
            !!rest.disabled && classes.disabled.input,
          ),
          placeholder: rest.placeholder ? rest.placeholder : 'Select a date',
        }}
        timeConstraints={{minutes: {step: 5, min: 1, max: 60}}} // 5 minute intervals
        {...rest}
      />
      {errors && (
        <span className="text-red-500 text-sm">
          <small>{errors}</small>
        </span>
      )}
    </>
  );
};

export default DatetimeField;
