import {useEffect, useState} from 'react';

import {useScript} from 'usehooks-ts';

import {PINPAYMENTS_PUBLIC_KEY, TARGET_ENV} from 'globals/app-globals';
import {CardDetails} from 'providers/CreditCardsProvider';

interface CreateCardTokenFunction {
  (cardDetails: CardDetails): Promise<any>; // TODO: Type the return from the API
}
interface PinPaymentsClientApi {
  createCardToken: CreateCardTokenFunction;
}
export interface UsePinPaymentsReturn {
  isLoading: boolean;
  isReady: boolean;
  errorLoading: boolean;
  api: PinPaymentsClientApi;
}

/**
 * Loads the external Pin Payments Pin.js script and creates
 * the API instance.
 */
const usePinPayments = (): UsePinPaymentsReturn => {
  /**
   * Load the Pin Payments Pin.js script.
   */
  const scriptStatus = useScript('https://cdn.pinpayments.com/pin.v2.js');

  /**
   * State for the Pin Payments API client instance.
   */
  const [api, setApi] = useState<PinPaymentsClientApi>();

  /**
   * Initialise the API instance once the script has loaded.
   */
  useEffect(() => {
    if (scriptStatus === 'ready') {
      if ((window as any).Pin) {
        setApi(
          new (window as any).Pin.Api(
            PINPAYMENTS_PUBLIC_KEY,
            TARGET_ENV === 'production' ? 'live' : 'test',
          ),
        );
      }
    }
  }, [scriptStatus]);

  return {
    /**
     * The API is ready when the script has been loaded and the API
     * instance has been initialised and set.
     */
    isLoading: scriptStatus === 'loading' || (scriptStatus === 'ready' && !api),
    isReady: scriptStatus === 'ready' && !!api,
    errorLoading: scriptStatus === 'error',
    api,
  };
};

export default usePinPayments;
