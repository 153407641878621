import {useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {InputField, SelectField, SubmitButton} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';

interface FormValues {
  name: string;
  email: string | null;
  phoneNumber: string | null;
  tradeCategory: string;
  propertyId: string;
}

const NewPreferredTradesmanPage = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const router = useRouter();
  const queryClient = useQueryClient();

  const [customTradeCategory, setCustomTradeCategory] = useState('');

  // PUll property to make sure they have permission to access this property
  const {isLoading, error} = useQuery(
    `property-${propertyId}-new-preferred-tradespeople`,
    async () => {
      const p = await Property.find(propertyId);
      return p.data;
    },
  );

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    if (values.tradeCategory === 'Other' && customTradeCategory.length === 0) {
      actions.setFieldError(
        'tradeCategory',
        'Other trade category is required',
      );
    } else {
      const tradie = new PreferredTradesman(values);
      if (values.tradeCategory === 'Other') {
        tradie.tradeCategory = customTradeCategory;
      }

      const result = await tradie.save();

      if (result) {
        toast.success('Preferred tradesperson was successfully added!');
        queryClient.invalidateQueries(
          `property-${propertyId}-preferred-tradespeople`,
        );

        TrackingService.trackEvent(
          TrackingService.Event.AddPreferredTradesperson,
        );

        router.navigate(`/properties/${propertyId}/preferred-tradespeople`);
      } else {
        for (const field in tradie.errors) {
          const error = tradie.errors[field];
          actions.setFieldError(field, error?.fullMessage);
        }
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Page title="Add Preferred Tradesperson" loading={isLoading} error={error}>
      {() => (
        <Card title="Add a new preferred tradesperson">
          <Paragraph>
            Preferred tradespeople are tradespeople that Keyhook will try to
            arrange quotes from first when you have approved a maintenance
            request. You can add multiple tradespeople for the same job
            category.
          </Paragraph>

          <Formik
            initialValues={{
              name: '',
              email: '',
              phoneNumber: '',
              tradeCategory: ServiceRequest.categoryTypes[0],
              propertyId,
            }}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape(
              {
                name: Yup.string()
                  .required()
                  .min(2)
                  .label('Tradesperson or Company name'),
                email: Yup.string()
                  .email()
                  .label('Email Adress')
                  .when('phoneNumber', {
                    is: (phone: any) => !phone || phone.length === 0,
                    then: Yup.string()
                      .email()
                      .required()
                      .label('Email Address'),
                    otherwise: Yup.string(),
                  }),
                phoneNumber: Yup.string()
                  .min(9)
                  .label('Phone Number')
                  .when('email', {
                    is: (email: any) => !email || email.length === 0,
                    then: Yup.string().required().min(9).label('Phone Number'),
                    otherwise: Yup.string(),
                  }),
                tradeCategory: Yup.string().required().label('Trade Category'),
                propertyId: Yup.string().required().label('Property Id'),
              },
              [['email', 'phoneNumber']],
            )}>
            {(formik) => (
              <Form>
                <InputField
                  formik={formik}
                  name="name"
                  labelProps={{
                    title: 'Tradesperson or company name',
                  }}
                />
                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="email"
                      labelProps={{
                        title: 'Email address',
                      }}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="phoneNumber"
                      labelProps={{
                        title: 'Phone number',
                      }}
                    />
                  </FormRowItem>
                </FormRow>
                <small className="tw-block mt-2 text-secondary">
                  Note you only need to provide an email address OR a phone
                  number. However providing both is recommended.
                </small>

                <SelectField
                  formik={formik}
                  name="tradeCategory"
                  labelProps={{
                    title: 'Trade category',
                  }}>
                  {ServiceRequest.categoryTypes.map((category) => (
                    <option value={category} key={category}>
                      {category}
                    </option>
                  ))}
                </SelectField>

                {formik.values.tradeCategory === 'Other' && (
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Other (Please Specify)</span>
                    </label>
                    <div className="input-group">
                      <input
                        onChange={(e) => setCustomTradeCategory(e.target.value)}
                        value={customTradeCategory}
                        className="input input-bordered w-full input-rounded"
                      />
                    </div>

                    {formik.errors.tradeCategory && (
                      <span className="text-red-500">
                        <small>{formik.errors.tradeCategory}</small>
                      </span>
                    )}
                  </div>
                )}

                <SubmitButton
                  formik={formik}
                  text="Add Tradesperson"
                  submittingText="Adding Tradesperson..."
                  className="mt-3"
                  block
                />
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </Page>
  );
};

export default NewPreferredTradesmanPage;
