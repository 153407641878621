import {Router} from 'framework7/types';

import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  /**
   * Welcome page is now located at the root path (when logged out).
   */
  {
    path: '/welcome',
    id: 'WelcomeRedirect',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
  },
  /**
   * Tenant registration is now located at: /register/renter
   */
  {
    path: '/register/tenant',
    id: 'TenantRegisterRedirect',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/register/renter'}),
  },

  /**
   * Paths for the previous auth implementation where the routes were prefixed with
   * /auth have been moved to the root path.
   */
  {
    path: '/auth/forgot-password',
    id: 'AuthForgotPasswordRedirect',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/reset-password'}),
  },
  {
    path: '/auth/reset-password',
    id: 'AuthResetPasswordRedirect',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/reset-password'}),
  },
  {
    path: '/auth/confirm-email',
    id: 'AuthConfirmEmailRedirect',
    async: resolveRoute(
      {conditions: [NEVER]},
      {redirect: '/register/confirm-email'},
    ),
  },
  {
    path: '/auth/confirm-email',
    id: 'AuthConfirmEmailRedirect',
    async: resolveRoute(
      {conditions: [NEVER]},
      {redirect: '/register/confirm-email'},
    ),
  },
  {
    path: '/auth/set-email',
    id: 'AuthSetEmailRedirect',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/register/details'}),
  },
  /**
   * Forgot password and reset password pages are now combined to be
   * located at /reset-password for requesting a password reset and
   * /reset-password/:token for setting the new password from a requested
   * reset.
   */
  {
    path: '/forgot-password',
    id: 'ForgotPasswordRedirect',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/reset-password'}),
  },
];

export default routes;
