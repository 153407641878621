import {AccountRole} from 'models/users/User';

/**
 * The format that the locally stored current user data must adhere to.
 */
export interface UserData {
  id: string;
  email: string;
  unconfirmedEmail: string | null;
  name: string;
  avatar: string;
  roles: Array<AccountRole>;
  confirmed: boolean;
}

/**
 * A type guard to ensure that the locally stored data for the user
 * is still of a valid format, and that the required object shape has
 * not changed since it was last stored.
 */
export const userDataIsValidShape = (
  userData: unknown,
): userData is UserData => {
  if (!userData || typeof userData !== 'object') {
    return false;
  }

  /**
   * Ensure the expected properties exist as per the type definition above.
   */
  const isValid =
    'id' in userData &&
    'email' in userData &&
    'unconfirmedEmail' in userData &&
    'name' in userData &&
    'avatar' in userData &&
    'roles' in userData &&
    Array.isArray(userData.roles) &&
    userData.roles.every((role) => Object.keys(AccountRole).includes(role)) &&
    'confirmed' in userData;

  return isValid;
};
