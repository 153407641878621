import {SocialPlatform, SocialAuthUserData} from './platforms';
import {authenticate as authenticateApple} from './platforms/apple';
import {authenticate as aunthenticateFacebook} from './platforms/facebook';
import {authenticate as aunthenticateGoogle} from './platforms/google';

const socialPlatformAuthentication = {
  [SocialPlatform.Google]: aunthenticateGoogle,
  [SocialPlatform.Facebook]: aunthenticateFacebook,
  [SocialPlatform.Apple]: authenticateApple,
};

export interface SocialPlatformAuthFunction {
  (onSuccess: (data: SocialAuthUserData) => void, onError: () => void): void;
}

const authenticate = (
  socialPlatform: SocialPlatform,
  onSuccess: (socialPlatform: SocialPlatform, data: SocialAuthUserData) => void,
  onError: (socialPlatform: SocialPlatform) => void,
) => {
  socialPlatformAuthentication[socialPlatform](
    /**
     * Pass the provider to the callback functions for reference/
     */
    (data: SocialAuthUserData) => onSuccess(socialPlatform, data),
    () => onError(socialPlatform),
  );
};

export default authenticate;
