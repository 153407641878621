/* eslint-disable @typescript-eslint/no-unused-vars */
import {useCallback, useEffect, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {useLocalStorageValue} from '@react-hookz/web';
import {useCookies} from 'react-cookie';
import {useLocalStorage} from 'usehooks-ts';

import useAuth from 'auth/provider/useAuth';
import {TARGET_ENV} from 'globals/app-globals';

/**
 * A hook to automatically (and conditionally) enable Customerly tracking
 * and chat bubble.
 */
const useCustomerly = () => {
  const {currentUser, isLoggedIn, isGhostingUser} = useAuth();

  const [{customerly_jwt}, _, removeCookie] = useCookies(['customerly_jwt']);

  /**
   * Properly removes the cookie set by Customerly. Note that only
   * using the logout function on the Customerly window object will
   * only unload the currently loaded user and does not delete the
   * cookie, so we need to handle this manually.
   */
  const clearCookie = useCallback(() => {
    if (customerly_jwt) {
      removeCookie('customerly_jwt');
    }
  }, [customerly_jwt, removeCookie]);

  const correctEnv = useMemo(
    () =>
      ['development', 'staging', 'production'].includes(TARGET_ENV) &&
      !Capacitor.isNativePlatform(),
    [],
  );

  useEffect(() => {
    const {customerly} = window as any;
    if (customerly && correctEnv) {
      /**
       * Only initialise Customerly for logged in users that
       * are not being ghosted. If a user is being ghosted,
       * clear any existing customerly cookies to avoid tracking
       * against a previously non-ghosted login.
       */
      if (isLoggedIn) {
        if (isGhostingUser) {
          clearCookie();
        } else {
          let configure: any;
          if (customerly.loaded) {
            configure = customerly.update;
          } else if (customerly.update) {
            configure = customerly.load;
          }

          const configuration = {
            app_id: '8bb60776',
            user_id: currentUser.id,
            name: currentUser.name,
            email: currentUser.email,
            attributes: {
              roles: currentUser.roles.join(', '),
              env: process.env.TARGET_ENV,
            },
            accentColor: '#3366FF ',
            visibleOnMobile: false,
          };

          if (configure) {
            if (currentUser) {
              configure(configuration);
            }
          }
        }
      } else {
        clearCookie();
      }
    }
  }, [currentUser, isLoggedIn, clearCookie, correctEnv, isGhostingUser]);
};

export default useCustomerly;
