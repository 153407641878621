import {useEffect, useState} from 'react';

/**
 * Detects the current scroll position for a given element.
 */
const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const scrollContainer = document.getElementById('page').firstElementChild;
    const scrollContainerContent = scrollContainer.firstElementChild;
    const updatePosition = () => {
      setScrollPosition(scrollContainerContent.scrollTop);
    };
    scrollContainerContent.addEventListener('scroll', updatePosition);
    updatePosition();
    return () =>
      scrollContainerContent.removeEventListener('scroll', updatePosition);
  }, []);
  return scrollPosition;
};

export default useScrollPosition;
