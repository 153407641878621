import {useEffect, useMemo} from 'react';

import {appleAuthHelpers} from 'react-apple-signin-auth';
import {useScript} from 'usehooks-ts';

import {TARGET_ENV} from 'globals/app-globals';

/**
 * Handles initialisation of the external scripts required for
 * authentication via social providers.
 */
const useSocialAuthScripts = () => {
  useEffect(() => {
    if (TARGET_ENV === 'development') {
      console.log('Loading social auth provider scripts...');
    }
  }, []);

  /**
   * Script for Facebook auth.
   */
  const facebookStatus = useScript(
    'https://connect.facebook.net/en_US/sdk.js',
    {
      removeOnUnmount: false,
    },
  );

  /**
   * Script for Google auth.
   */
  const googleStatus = useScript('https://accounts.google.com/gsi/client', {
    removeOnUnmount: false,
  });

  /**
   * Script for Apple auth.
   */
  const appleStatus = useScript(appleAuthHelpers.APPLE_SCRIPT_SRC, {
    removeOnUnmount: false,
  });

  const allScriptsReady = useMemo(
    () =>
      facebookStatus === 'ready' &&
      googleStatus === 'ready' &&
      appleStatus === 'ready',
    [facebookStatus, googleStatus, appleStatus],
  );

  useEffect(() => {
    if (allScriptsReady && TARGET_ENV === 'development') {
      console.log('Social auth provider scripts loaded!');
    }
  }, [allScriptsReady]);

  return {
    ready: allScriptsReady,
  };
};

export default useSocialAuthScripts;
