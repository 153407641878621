export enum AuthErrorCode {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  EMAIL_ALREADY_TAKEN = 'EMAIL_ALREADY_TAKEN',
  INVALID_CURRENT_PASSWORD = 'INVALID_CURRENT_PASSWORD',
  INVALID_EMAIL_CONFIRMATION_CODE = 'INVALID_EMAIL_CONFIRMATION_CODE',
  PASSWORD_RESET_TOKEN_EXPIRED = 'PASSWORD_RESET_TOKEN_EXPIRED',
}

export const errorIsAuthErrorCodes = (
  error: unknown,
): error is AuthErrorCode[] =>
  !!error &&
  Array.isArray(error) &&
  error.every((item) => item in AuthErrorCode);
