import {FunctionComponent, useCallback, useMemo, useState} from 'react';

import {CalendarIcon} from '@heroicons/react/outline';
import dayjs, {Dayjs} from 'dayjs';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import OpenHome from 'models/listings/OpenHome';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import {saveResource} from 'utilities/SpraypaintHelpers';

interface OpenHomeRowProps {
  openHome: OpenHome;
}

const OpenHomeRow: FunctionComponent<OpenHomeRowProps> = ({openHome}) => {
  const {isLoggedIn} = useAuth();
  const queryClient = useQueryClient();

  /**
   * Track when attending/unattending is in progress.
   */
  const [isToggling, setIsToggling] = useState<boolean>(false);

  /**
   * Construct the key for the query.
   */
  const queryKey = useMemo(
    () => [
      'open-home-attendence',
      {
        id: openHome.id,
      },
    ],
    [openHome.id],
  );

  /**
   * Fetch the open home attendee instance if it exists for the current user
   * for the open home.
   */
  const {data: attendee, isLoading: loadingAttendee} = useQuery(
    queryKey,
    async () => {
      /**
       * If we attempt to fetch all of the open home attendees for the open home,
       * only the record reflecting the current user will be permitted to be
       * returned, so we are able to assume that any attendee instance returned
       * represents the current user.
       */
      const attendees = (
        await OpenHomeAttendee.where({
          open_home_id: openHome.id,
        }).all()
      ).data;
      return attendees.length ? attendees[0] : null;
    },
    {
      /**
       * Only fetch if there is a current user logged in.
       */
      enabled: isLoggedIn,

      /**
       * When fetching or refetching is complete, indicate that toggling is complete if
       * it had previously been set as in progress.
       */
      onSettled: () => {
        if (isToggling) {
          setIsToggling(false);
        }
      },
    },
  );

  /**
   * If an attendee instance exists, we know that the user has indicated that
   * they will be attending the open home.
   */
  const isAttending = useMemo(() => !!attendee, [attendee]);

  /**
   * Invoked upon successfully toggling the user's attendance (either attending or unattending).
   */
  const onSuccessfullyToggled = useCallback(() => {
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  /**
   * Handle the user indicating they will be attending an open home.
   */
  const onAttend = useCallback(async () => {
    setIsToggling(true);
    try {
      /**
       * Handle invalid incovation of the function.
       */
      if (isAttending) {
        throw Error(
          'Cannot attend this open home - there is already an attendee instance for the user.',
        );
      }

      /**
       * Create and attempt to save a new attendee instance for the current
       * user for the open home.
       */
      if (
        !(await saveResource(new OpenHomeAttendee({openHomeId: openHome.id})))
      ) {
        throw Error('Failed to create attendee instance.');
      }

      /**
       * Successfully created attendee instance.
       */
      onSuccessfullyToggled();
    } catch (error) {
      toast.error('Sorry, there was an issue trying to attend this open home.');
    }
  }, [isAttending, openHome, onSuccessfullyToggled]);

  /**
   * Handle the user indicating they no longer want to attend an open home that
   * they have indicated they will be attending.
   */
  const onUnattend = useCallback(async () => {
    setIsToggling(true);
    try {
      /**
       * Handle invalid incovation of the function.
       */
      if (!isAttending) {
        throw Error(
          'Cannot unattend from open home - there is no attendee instance for the user.',
        );
      }

      /**
       * Attempt to destroy the attendee instance.
       */
      if (!(await attendee.destroy())) {
        throw Error('Failed to destroy attendee instance.');
      }

      /**
       * Successfully destroyed attendee instance.
       */
      onSuccessfullyToggled();
    } catch (error) {
      toast.error(
        'Sorry, there was an issue trying to unattend this open home.',
      );
    }
  }, [isAttending, attendee, onSuccessfullyToggled]);

  /**
   * Parse the open home start time as a Dayjs instance.
   */
  const startTime = useMemo<Dayjs>(
    () => dayjs(openHome.startTime),
    [openHome.startTime],
  );

  /**
   * Parse the open home end time as a Dayjs instance.
   */
  const endTime = useMemo<Dayjs>(
    () => dayjs(openHome.endTime),
    [openHome.endTime],
  );

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="flex justify-start items-center">
        <div className="mr-2">
          <CalendarIcon className="w-6 h-6 text-primary" />
        </div>
        <div>
          <span className="font-medium">
            {startTime.format('dddd DD MMMM')}
          </span>
          <p className="text-brand-850 opacity-70">
            {startTime.format('hh:mm a')} - {endTime.format('hh:mm a')}
          </p>
        </div>
      </div>

      <div className="flex-0">
        {isLoggedIn ? (
          <>
            {loadingAttendee ? (
              <SpinningLoader size="sm" color="brand" />
            ) : (
              <>
                {/* Attend / Unattend buttons */}
                {isAttending ? (
                  <Button
                    label="Unattend"
                    category="secondary"
                    size="sm"
                    mode="manual"
                    onClick={onUnattend}
                    loading={isToggling}
                  />
                ) : (
                  <Button
                    label="Attend"
                    category="primary"
                    size="sm"
                    mode="manual"
                    onClick={onAttend}
                    loading={isToggling}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <Button
            label="Login"
            category="secondary"
            size="sm"
            mode="link"
            linkTo="/login"
          />
        )}
      </div>
    </div>
  );
};

export default OpenHomeRow;
