import {useContext, useEffect, useId} from 'react';

import {SwipeCallbacks} from './types';

import {GlobalSwipeContext} from '.';

type UseGlobalSwipeHookProps = Partial<SwipeCallbacks>;

interface UseGlobalSwipeHook {
  (props: UseGlobalSwipeHookProps): void;
}

const useGlobalSwipe: UseGlobalSwipeHook = (callbacks) => {
  /**
   * Generate an ID for the swipe listener instance.
   */
  const id = useId();

  /**
   * Access the global swipe provider context.
   */
  const {addListener, removeListener} = useContext(GlobalSwipeContext);

  /**
   * Add the swipe listener instance to the global swipe provider and remove
   * the listener during clean up.
   */
  useEffect(() => {
    addListener(id, callbacks);
    return () => removeListener(id);
  }, [id, callbacks, addListener, removeListener]);
};

export default useGlobalSwipe;
