import {useCallback, useEffect, useState} from 'react';

import {type FormikHelpers, useFormik} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {CountableNumberField} from 'components/forms_fields';
import StepContainer from 'components/walkthrough/common/StepContainer';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  bedrooms: number;
  bathrooms: number;
  garages: number;
};

const GeneralInfoStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartRoomsStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    setSubmitting(true);

    /**
     * Set changes on the property.
     */
    property.assignAttributes(formValues);
    property.lastOnboardingStepCompleted = 'listing_general_information';
    property.setProfileStepAsCompleted('general_information');

    /**
     * Save the changes to the property.
     */
    if (!(await saveResource(property))) {
      setSubmitting(false);
      return;
    }

    listing.assignAttributes(formValues);

    /**
     * Save the changes to the listing.
     */
    if (!(await saveResource(listing))) {
      setSubmitting(false);
      return;
    }

    /**
     * Update the property data in the query cache.
     */
    queryClient.setQueryData(
      ['property', {id: property.id, context: 'onboarding-flow'}],
      property,
    );

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompleteRoomsStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );

    setSubmitting(false);
    actions.setSubmitting(false);
  };

  /**
   * Initialise the Formik form.
   */
  const form = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      bedrooms: listing.bedrooms || 1,
      bathrooms: listing.bathrooms || 1,
      garages: listing.garages || 0,
    },
    validationSchema: Yup.object().shape({
      bathrooms: Yup.number().min(1).max(10).required().label('Bathrooms'),
      bedrooms: Yup.number().min(0).max(12).required().label('Bedrooms'),
      garages: Yup.number().min(0).max(12).required().label('Garages'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer
      align="center"
      title="Tell us about your property..."
      subtitle="">
      <CountableNumberField
        labelProps={{
          title: 'Bedrooms',
          size: 'xl',
        }}
        name="bedrooms"
        form={form}
        align="center"
      />
      <CountableNumberField
        labelProps={{
          title: 'Bathrooms',
          size: 'xl',
        }}
        name="bathrooms"
        incrementValue={0.5}
        form={form}
        align="center"
      />
      <CountableNumberField
        labelProps={{
          title: 'Garages',
          size: 'xl',
        }}
        name="garages"
        form={form}
        align="center"
      />
    </StepContainer>
  );
};

export default GeneralInfoStep;
