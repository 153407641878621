import {useCallback, useImperativeHandle} from 'react';

import * as Sentry from '@sentry/react';
import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {TextField} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';
import BillingMethod from 'models/billing/BillingMethod';
import {useCreditCards} from 'providers/CreditCardsProvider';
import {saveResource} from 'utilities/SpraypaintHelpers';

const RenameCreditCardModal: ModalDefinition = {
  title: 'Rename credit/debit card',
  buttonsConfig: {
    actions: [
      {
        id: 'save',
        label: 'Save',
        handle: 'onSave',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    /**
     * Deconstruct and cast the credit card prop.
     */
    const creditCard = props.creditCard as BillingMethod;

    /**
     * Access the credit cards context.
     */
    const creditCards = useCreditCards();

    /**
     * Handle the rename action.
     */
    const handleRename = useCallback(
      ({nickname}: {nickname: string}) =>
        creditCards.rename(creditCard, nickname),
      [creditCard, creditCards],
    );

    const form = useFormik({
      onSubmit: handleRename,
      initialValues: {
        // Prefill the nickname with the current value if set
        nickname: creditCard.nickname ?? '',
      },
      validationSchema: Yup.string()
        .required('Please enter a nickname for the card')
        .min(3, 'Nicknames must be at least 3 characters')
        .max(25, 'Nicknames must be no more than 25 characters'),
      validateOnBlur: false,
      validateOnChange: false,
    });

    const onSave = useCallback(async () => {
      const result = await form.validateForm();
      if (isEmpty(result)) {
        await handleRename(form.values);
      }
      return false;
    }, [form, handleRename]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        {/* Cardholder name */}
        <TextField
          required
          name="nickname"
          label="Card nickname"
          description="Give your card a custom name to help you identify it in your Keyhook account."
          placeholder='E.g. "My Personal Card"'
          type="text"
          size="base"
          mode="formik"
          form={form}
        />
      </>
    );
  },
};

export default RenameCreditCardModal;
