import React from 'react';

import moment from 'moment';
import {AiOutlineUsergroupAdd} from 'react-icons/ai';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const InterpolResultsCard = ({report}: {report: TenantCheckReport}) => {
  // Uncomment to have some example data to test against
  // report.interpolDatabaseResults = {
  //   search_url: '',
  //   results: [
  //     {
  //       family_name: 'Rama',
  //       forename: 'Aaron',
  //       gender: 'Male',
  //       date_of_birth: '1993-26-09',
  //       place_of_birth: 'New Zealand',
  //       nationality: 'New Zealand',
  //       charges: 'Being a cool dude',
  //       photo_url:
  //         'https://keyhook.media/iFxOhj0HiWRcQYsXaW3ayqU_41JzQp_Ex2t8aVTrb3o/s:256:256/q:75/Z3M6Ly9rZXlob29r/LXVwbG9hZHMtcHVi/bGljLWRldmVsb3Bt/ZW50LWFhcm9uLXJh/bWEvdXNlci8xNzAv/YXZhdGFyL2UzZDYx/YTc3NjE2YjUwYzlk/MGJhZDg4MzcwNzZi/NWRjLmpwZWc.jpg',
  //       result_url: 'http://google.com',
  //     },
  //   ],
  // };

  if (report.interpolDatabaseResultsFetchedAt) {
    return (
      <Card title="Interpol Wanted Notices" icon={AiOutlineUsergroupAdd}>
        {report.interpolDatabaseResults.results.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Photo</TableHeadItem>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Gender</TableHeadItem>
              <TableHeadItem>Nationality</TableHeadItem>
              <TableHeadItem>Charges</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.interpolDatabaseResults.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <img
                    src={result.photo_url}
                    className="w-20 h-20 object-fit rounded-full"
                  />
                </TableRowItem>

                <TableRowItem>
                  <Hyperlink href={result.result_url} external openInNewTab>
                    {result.forename} {result.family_name}
                  </Hyperlink>
                </TableRowItem>
                <TableRowItem>{result.gender}</TableRowItem>
                <TableRowItem>{result.nationality}</TableRowItem>
                <TableRowItem>{result.charges}</TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the Interpol
            database.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.interpolDatabaseResultsFetchedAt).format(
            DATE_TIME_FORMAT,
          )}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Interpol Wanted Notices" icon={AiOutlineUsergroupAdd}>
        <Paragraph>
          Interpol results are currently being collected and will be updated on
          this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default InterpolResultsCard;
