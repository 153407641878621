import {FunctionComponent} from 'react';

import clsx from 'clsx';

import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';

import WebHeaderDesktopContent from './Desktop';
import WebHeaderMobileContent from './Mobile';

/**
 * The breakpoint at which the nav bar layout changes from
 * mobile (drawer) to desktop (within nav bar).
 */
export const DESKTOP_LAYOUT_BREAKPOINT = 'md';

/**
 * The content to render within the web header portal.
 */
const WebHeaderContent: FunctionComponent = () => {
  // // ========================================================================
  // // Unread Messages Badge Count Handling
  // // ========================================================================
  // const {channels} = usePusherService();
  // const [totalUnreadChatCount, setTotalUnreadChatCount] =
  //   useNavItemBadgeCount('chat');

  // const calculateUnreadCount = useCallback(() => {
  //   if (channels.length > 0) {
  //     const val = (channels as ChatChannel[])
  //       .map((chan) => chan.unreadCount || 0)
  //       .reduce((prevVal, currentVal) => prevVal + currentVal, 0);
  //     if (totalUnreadChatCount !== val) {
  //       setTotalUnreadChatCount(val);
  //     }
  //   } else {
  //     if (totalUnreadChatCount !== 0) {
  //       setTotalUnreadChatCount(0);
  //     }
  //   }
  // }, [channels, totalUnreadChatCount, setTotalUnreadChatCount]);

  // /**
  //  * Calculate the unread message count when the channels update.
  //  */
  // useEffect(() => {
  //   calculateUnreadCount();
  // }, [channels, calculateUnreadCount]);
  // // ========================================================================

  const desktopLayout = useTailwindBreakpoint(DESKTOP_LAYOUT_BREAKPOINT);

  return (
    <div className={clsx('w-full h-full', 'flex flex-row', 'items-center')}>
      {desktopLayout ? <WebHeaderDesktopContent /> : <WebHeaderMobileContent />}
    </div>
  );
};

export default WebHeaderContent;
