import {useContext} from 'react';

import {RouteContext} from 'router/components/RouteContextProvider';

/**
 * Returns the f7route object for the current route.
 */
const useRoute = () => {
  const {f7route, routeProps} = useContext(RouteContext);
  return {...f7route, props: routeProps};
};

export default useRoute;
