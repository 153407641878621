import {useCallback, useEffect, useState} from 'react';

import clsx from 'clsx';
import {f7} from 'framework7-react';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import {API_URL} from 'globals/app-globals';
import Page from 'router/components/Page';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';

import '@dotlottie/player-component';

type AddressOptionId = string | number;

interface PropertyAddress {
  streetAddress: string;
  suburb: string;
  city: string;
  country: string;
  postcode: string;
}

const isValidPropertyAddressSchema = (
  propertyAddress: unknown,
): propertyAddress is PropertyAddress => {
  return (
    typeof propertyAddress === 'object' &&
    'streetAddress' in propertyAddress &&
    typeof propertyAddress.streetAddress === 'string' &&
    'suburb' in propertyAddress &&
    typeof propertyAddress.suburb === 'string' &&
    'city' in propertyAddress &&
    typeof propertyAddress.city === 'string' &&
    'country' in propertyAddress &&
    typeof propertyAddress.country === 'string' &&
    'postcode' in propertyAddress &&
    typeof propertyAddress.postcode === 'string'
  );
};

const InitialisePropertyPage = () => {
  const router = useRouter();
  const route = useRoute();

  const {authCookies} = useAuth();

  const [propertyId, setPropertyId] = useState(null);
  const [hasInitialised, setHasInitialised] = useState<boolean>(false);

  const initialiseFromAddressOptionId = useCallback(
    async (id: AddressOptionId) => {
      const url = `${API_URL}/properties/setup_property.json?address_id=${id}`;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
        });

        const data = await response.json();

        if (response.ok) {
          const {property: id} = data;

          /**
           * Track completion of the step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.AddProperty_CompleteAddressStep,
            {
              id,
            },
          );

          /**
           * Track starting the onboarding type selection step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.AddProperty_StartOnboardTypeStep,
            {
              id,
            },
          );

          setPropertyId(id);
          setHasInitialised(true);
        } else {
          throw Error();
        }
      } catch (e) {
        toast.error('There was an error adding your property.');
        router.navigate(`/properties`, {
          props: {
            afterAddProperty: true,
          },
        });
      }
    },
    [authCookies, router],
  );

  const initialiseFromAddress = useCallback(
    async ({
      streetAddress,
      suburb,
      city,
      country,
      postcode,
    }: PropertyAddress) => {
      const params = new URLSearchParams();
      params.append('street_address', streetAddress);
      params.append('suburb', suburb);
      params.append('city', city);
      params.append('country', country);
      params.append('postcode', postcode);

      const url = `${API_URL}/properties/setup_property.json?${params.toString()}`;

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
        });

        const data = await response.json();

        if (response.ok) {
          const {property: id} = data;

          /**
           * Track completion of the step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.AddProperty_CompleteAddressStep,
            {
              id,
            },
          );

          /**
           * Track starting the onboarding type selection step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.AddProperty_StartOnboardTypeStep,
            {
              id,
            },
          );

          setPropertyId(id);
          setHasInitialised(true);
        } else {
          throw Error();
        }
      } catch (e) {
        toast.error('There was an error adding your property.');
        router.navigate(`/properties`, {
          props: {
            afterAddProperty: true,
          },
        });
      }
    },
    [authCookies, router],
  );

  useEffect(() => {
    if (hasInitialised) {
      setTimeout(() => {
        f7.views.main.router.navigate(`/properties/${propertyId}`, {
          props: {
            afterAddProperty: true,
          },
          reloadCurrent: true,
        });
      }, 3000);
    }
  }, [hasInitialised, router, propertyId]);

  const handleInit = useCallback(async () => {
    if ('addressOptionId' in route.props) {
      await initialiseFromAddressOptionId(route.props.addressOptionId);
    } else if ('address' in route.props) {
      await initialiseFromAddress(route.props.address);
    } else {
      /**
       * If the props provided were invalid, navigate the user back to their
       * list of properties.
       */
      router.goBack('/properties');
    }
  }, [
    route.props,
    initialiseFromAddressOptionId,
    initialiseFromAddress,
    router,
  ]);

  return (
    <Page disableNavbar disableTabs centred onPageInit={handleInit}>
      {() => (
        <div className="flex flex-col justify-center w-full">
          {/* Loading message */}
          <p className="font-semibold text-brand-850 text-xl md:text-2xl text-center">
            Getting your property ready...
          </p>

          {/* Animations */}
          <div className="w-full h-64 md:h-80 relative my-10">
            <div
              className={clsx(
                'w-full h-full',
                'transition-all duration-300',
                'origin-center scale-100',
                hasInitialised ? 'scale-0 opacity-0' : 'scale-100 opacity-100',
              )}>
              <dotlottie-player
                autoplay
                loop
                mode="normal"
                src="/assets/lottie/floating-house.lottie"
                style={{height: '100%', width: '100%'}}
              />
            </div>
            <div className="w-full h-full absolute top-0 left-0 flex items-center justify-center">
              {hasInitialised && (
                <dotlottie-player
                  autoplay
                  mode="normal"
                  src="/assets/lottie/success-check.lottie"
                  style={{height: '75%', width: '75%'}}
                />
              )}
            </div>
          </div>

          {/* Information about Keyhook */}
          <div className="text-center text-brand-850 flex flex-col gap-y-6">
            <div className="font-medium opacity-70 text-lg ">
              Did you know...
            </div>
            <div className="text-brand-850 text-base">
              Keyhook can save you $2,000+ in annual management fees per
              property
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default InitialisePropertyPage;
