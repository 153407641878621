import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import {
  CompaniesRegistrarSearchResults,
  GovernmentJusticeResults,
  InsolvencyRegistrarSearchResults,
  InterpolSearchResults,
  NewsArticleSearchResults,
  ObituarySearchResults,
  ParoleBoardSearchResults,
  PoliceDatabaseResults,
  SearchEngineResults,
  SocialsResults,
  TenancyTribunalSearchResults,
} from 'models/tenant_checks/ReportTypes';
import TenantCheckReference from 'models/tenant_checks/TenantCheckReference';
import TenantCheckRequest from 'models/tenant_checks/TenantCheckRequest';

export enum TenantCheckReportStatus {
  AwaitingExtraInformation = 'awaiting_extra_information',
  AwaitingResults = 'awaiting_results',
  CreditReportCompleted = 'credit_report_completed',
  Completed = 'completed',
}

@Model()
class TenantCheckReport extends ApplicationRecord {
  static jsonapiType = 'tenant_check_reports';

  @Attr({persist: false}) firstName: string;
  @Attr({persist: false}) middleName: string | null;
  @Attr({persist: false}) lastName: string;

  @Attr({persist: false}) status: TenantCheckReportStatus;

  @Attr({persist: false}) dateOfBirth: string;

  @Attr({persist: false}) idType: string | null;
  @Attr({persist: false}) idNumber: string | null;
  @Attr({persist: false}) idExtraInfo: string | null;

  @Attr({persist: false}) currentAddress: string;
  @Attr({persist: false}) stayDuration: string | null;

  @Attr({persist: false}) email: string;

  @Attr({persist: false}) centrixDocument: string;
  @Attr({persist: false}) centrixReport: any | null;
  @Attr({persist: false}) centrixReportFetchedAt: string | null;

  @Attr({persist: false}) searchResults: SearchEngineResults | null;
  @Attr({persist: false}) searchResultsFetchedAt: string | null;

  @Attr({persist: false}) socialsResults: SocialsResults | null;
  @Attr({persist: false}) socialsResultsFetchedAt: string | null;

  @Attr({persist: false}) govtJusticeResults: GovernmentJusticeResults | null;
  @Attr({persist: false}) govtJusticeResultsFetchedAt: string | null;

  @Attr({persist: false}) policeDatabaseResults: PoliceDatabaseResults | null;
  @Attr({persist: false}) policeDatabaseResultsFetchedAt: string | null;

  @Attr({persist: false})
  tenancyServicesResults: TenancyTribunalSearchResults | null;
  @Attr({persist: false}) tenancyServicesResultsFetchedAt: string | null;

  @Attr({persist: false}) paroleBoardResults: ParoleBoardSearchResults | null;
  @Attr({persist: false}) paroleBoardResultsFetchedAt: string | null;

  @Attr({persist: false}) interpolDatabaseResults: InterpolSearchResults | null;
  @Attr({persist: false}) interpolDatabaseResultsFetchedAt: string | null;

  @Attr({persist: false})
  companiesRegisterResults: CompaniesRegistrarSearchResults | null;
  @Attr({persist: false}) companiesRegisterResultsFetchedAt: string | null;

  @Attr({persist: false})
  bankruptcyInsolvencyResults: InsolvencyRegistrarSearchResults | null;
  @Attr({persist: false}) bankruptcyInsolvencyResultsFetchedAt: string | null;

  @Attr({persist: false}) obituariesResults: ObituarySearchResults | null;
  @Attr({persist: false}) obituariesResultsFetchedAt: string | null;

  @Attr({persist: false}) newsArticles: NewsArticleSearchResults | null;
  @Attr({persist: false}) newsArticlesFetchedAt: string | null;

  @Attr({persist: false}) tenantCheckRequestId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenant_check_requests') tenantCheckRequest: TenantCheckRequest;
  @HasMany('tenant_check_references')
  tenantCheckReferences: TenantCheckReference[];

  get fullName(): string {
    if (this.middleName) {
      return `${this.firstName} ${this.middleName} ${this.lastName}`;
    } else {
      return `${this.firstName} ${this.lastName}`;
    }
  }
}

export default TenantCheckReport;
