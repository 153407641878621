import {FunctionComponent, useMemo, useState} from 'react';

import {
  useFloating,
  useInteractions,
  offset,
  shift,
  useClick,
  useDismiss,
} from '@floating-ui/react';
import clsx from 'clsx';

import FloatingActions from 'components_sb/buttons/FloatingActions/FloatingActions';
import {classes} from 'components_sb/navigation/Hyperlink/Hyperlink';
import {Action} from 'types/actions';
import {NavItem} from 'utilities/nav-links/types';

interface SubItemLinkProps {
  children: string;
  subItems: NavItem[];
}

const SubItemLink: FunctionComponent<SubItemLinkProps> = ({
  children: label,
  subItems,
}) => {
  const [floatingActionsOpen, setFloatingActionsOpen] =
    useState<boolean>(false);

  /**
   * Create a floating instance.
   */
  const floating = useFloating({
    open: floatingActionsOpen,
    onOpenChange: setFloatingActionsOpen,
    placement: 'bottom',
    middleware: [
      /**
       * Add a gap between the link and the popover.
       */
      offset(10),

      /**
       * Keep the popover in view of the window along the x-axis.
       */
      shift({
        /**
         * Ensure at least 10px of horizontal padding when shifting.
         */
        padding: 10,
      }),
    ],
  });

  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
  ]);

  /**
   * Convert the sub nav items into actions.
   */
  const actions = useMemo<Action[]>(
    () =>
      subItems.map(({label, linkTo}) => ({
        label,
        linkTo,
      })),
    [subItems],
  );

  return (
    <>
      {/* TODO: Refactor the styling below to share code with Hyperlink component for consistency */}
      <span className="inline-block relative">
        <button
          ref={floating.refs.setReference}
          className={clsx(classes.base.main, classes.color.label['brand'])}
          {...getReferenceProps()}>
          <span className={'whitespace-nowrap'}>{label}</span>
          <span
            className={clsx(
              classes.base.underline,
              classes.color.underline['brand'],
            )}
          />
        </button>
      </span>
      <FloatingActions
        floating={floating}
        isOpen={floatingActionsOpen}
        onClose={() => setFloatingActionsOpen(false)}
        actions={actions}
        {...getFloatingProps()}
      />
    </>
  );
};

export default SubItemLink;
