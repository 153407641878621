import {FunctionComponent, useMemo} from 'react';

import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';

import {Card} from 'components_sb/layout';
import Property from 'models/properties/Property';
import {Page, RouterLink} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const LandlordListingIndexPage: FunctionComponent = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const {data, error, isLoading, isSuccess} = useQuery(
    `property-${propertyId}-listings`,
    async () => {
      const property = await Property.includes('listings').find(propertyId);
      return property.data;
    },
  );

  const hasActive =
    isSuccess && !!data.listings.find((listing) => listing.isActive);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      hasActive || !propertyId
        ? null
        : [
            {
              label: 'New listing',
              linkTo: `/properties/${propertyId}/listings/new`,
            },
          ],
    [hasActive, propertyId],
  );

  return (
    <Page
      title="Listings"
      loading={isLoading}
      error={error}
      actions={pageActions}>
      {() =>
        data.listings.length > 0 ? (
          <div>
            {data.listings.map((listing) => (
              <RouterLink href={listing.publicId} key={listing.id}>
                <Card
                  title={`Listing ${listing.publicId}`}
                  className="mt-2 cursor-pointer"
                  key={listing.id}>
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="text-secondary text-sm">
                        Created On:{' '}
                        {moment(listing.createdAt).format(DATE_FORMAT)}
                      </p>
                      {listing.isActive && (
                        <div className="badge badge-accent mt-2 text-white">
                          Active
                        </div>
                      )}
                    </div>
                    <div>
                      <AiOutlineRight className="w-5 h-5 text-secondary-300" />
                    </div>
                  </div>
                </Card>
              </RouterLink>
            ))}
          </div>
        ) : (
          <div></div>
        )
      }
    </Page>
  );
};

export default LandlordListingIndexPage;
