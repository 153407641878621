import {useCallback, useEffect, useMemo, useState} from 'react';

import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {InputField} from 'components/forms_fields';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {TextField} from 'components_sb/input';
import {Paragraph} from 'components_sb/typography';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  bankAccountName: string;
  bankAccountNumber: string;
};

const BankingStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartRentCollectionStep,
      {
        propertyId: property.id,
      },
    );
  }, [scrollToTop, property.id]);

  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the property.
       */
      property.assignAttributes(formValues);
      property.lastOnboardingStepCompleted = 'migrate_banking';

      /**
       * Save the changes to the property.
       */
      const savedProperty = await saveResource(property);

      if (savedProperty) {
        /**
         * Update the property data in the query cache.
         */
        queryClient.setQueryData(
          ['property', {id: property.id, context: 'onboarding-flow'}],
          property,
        );

        /**
         * Track completion of the step.
         */
        TrackingService.trackEvent(
          TrackingService.Event.MigrateTenancy_CompleteRentCollectionStep,
          {
            propertyId: property.id,
          },
        );
      } else {
        for (const key of Object.keys(property.errors)) {
          const message = property.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }

      setSubmitting(false);
    },
    [property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        bankAccountName: property.bankAccountName || '',
        bankAccountNumber: property.bankAccountNumber || '',
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        bankAccountName: Yup.string()
          .min(2)
          .required('Please enter your bank account name'),
        bankAccountNumber: Yup.string()
          .required('Please enter your bank account number')
          .test(
            'is-valid-format',
            'Please enter a valid NZ bank account number (including dashes)',
            (value) => {
              if (!value) return false;
              const parts = value.split('-');
              if (parts.length === 4) {
                return !!bankAccountValidator.isValidNZBankNumber(
                  parts[0],
                  parts[1],
                  parts[2],
                  parts[3],
                );
              } else {
                return false;
              }
            },
          ),
      }),
    }),
    [handleSubmit, property],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Enter your rent collecting account"
      subtitle="Where does rent get deposited?">
      <div className="flex w-full">
        <TextField
          label="Account Name"
          labelSize="xl"
          name="bankAccountName"
          mode="formik"
          form={form}
          placeholder="E.g. John Smith"
        />
      </div>

      <div className="flex w-full">
        <TextField
          label="Account Number (include dashes)"
          labelSize="xl"
          name="bankAccountNumber"
          mode="formik"
          form={form}
          placeholder="E.g. 02-1234-5678900-00"
        />
      </div>
    </StepContainer>
  );
};

export default BankingStep;
