import {useEffect, useRef, RefObject} from 'react';

import autoAnimate, {
  AutoAnimateOptions,
  AutoAnimationPlugin,
} from '@formkit/auto-animate';

export const useAutoAnimate = <T extends Element>(
  options: Partial<AutoAnimateOptions> | AutoAnimationPlugin = {},
): [RefObject<T>] => {
  const element = useRef<T>(null);
  useEffect(() => {
    if (element.current instanceof HTMLElement)
      autoAnimate(element.current, options);
  }, [element, options]);
  return [element];
};
