import {useCallback, useMemo, useState} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import Decimal from 'decimal.js';
import {Formik, Form, type FormikProps} from 'formik';
import moment from 'moment';
import {HiOutlineEye} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import headTenantIcon from 'assets/img/tenancy_clause_icons/head-tenant.svg';
import useAuth from 'auth/provider/useAuth';
import ChatableMessages from 'components/chat/ChatableMessages';
import LoadingView from 'components/common/LoadingView';
import TenancyClause from 'components/common/TenancyClause';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import DocumentsTable from 'components/document/DocumentsTable';
import {InputField, SelectField, SubmitButton} from 'components/forms_fields';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import Document from 'models/properties/Document';
import {LeaseDocumentType} from 'models/properties/Tenancy';
import TenancyRequest from 'models/properties/TenancyRequest';
import {Navigate, Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {shareEvenly} from 'utilities/MathHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const TenancyRequestDetailPage = () => {
  const {
    params: {tenancyRequestId},
  } = useRoute();

  const router = useRouter();

  const [leaseViewerVisible, setLeaseViewerVisible] = useState(false);
  const [bondViewerVisible, setBondViewerVisible] = useState(false);
  const [bondAdditionalViewerVisible, setBondAdditionalViewerVisible] =
    useState(false);

  const {authCookies} = useAuth();

  const {
    data: tenancyRequest,
    error,
    isLoading,
  } = useQuery(
    `renter-tenancy-request-detail-${tenancyRequestId}`,
    async () => {
      const res = await TenancyRequest.select({
        tenancy_requests: [
          'accepted',
          'is_head_tenant',
          'created_at',
          'renter_email',
          'rent_split',
          'bond_split',
        ],
        tenancies: [
          'start_date',
          'end_date',
          'total_rent',
          'rental_period',
          'bond',
          'is_new',
          'status',
          'custom_clauses',
          'weeks_rent_in_advance',
          'message_from_landlord',
          'documents',
          'lease_document_type',
        ],
        properties: [
          'street_address',
          'suburb',
          'city',
          'main_image',
          'pets_allowed',
          'allowed_pet_types',
          'smokers_allowed',
          'renters_insurance_required',
          'insurance_covers_tenants',
          'chattels',
          'courtesy_chattels',
          'max_tenants',
          'renters_insurance_required',
          'inspection_period',
          'documents',
        ],
        users: ['name', 'avatar'],
      })
        .includes({
          tenancy: [
            {property: ['documents', 'landlord']},
            'documents',
            'tenancy_requests',
          ],
        })
        .find(tenancyRequestId);
      return res.data;
    },
    {
      retry: 1,
      refetchOnWindowFocus: true,
    },
  );

  /**
   * For only new tenancies, show the healthy homes and house rules
   * documents if they exist on the property, but don't show any other documents.
   */
  const documentsToShow = useMemo(() => {
    if (tenancyRequest?.tenancy?.isNew) {
      return (
        tenancyRequest.tenancy.property?.documents.filter((document) =>
          ['healthy_homes_report', 'property_rules'].includes(
            document.documentType,
          ),
        ) ?? []
      );
    } else {
      return [];
    }
  }, [tenancyRequest]);

  const showDocument = useCallback(async (document: Document) => {
    await Browser.open({url: document.document, windowName: document.name});
  }, []);

  /**
   * Render the list of documents to show in a card.
   */
  const documentsCard = useMemo(
    () =>
      documentsToShow.length === 0 ? null : (
        <Card title="Supporting Documents">
          <Paragraph>
            Your landlord has uploaded the following additional documents for
            your reference.
          </Paragraph>
          <DocumentsTable
            documents={documentsToShow}
            clickHandler={showDocument}
            showParent={false}
          />
        </Card>
      ),
    [documentsToShow, showDocument],
  );

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: any, actions: any) => {
    tenancyRequest.assignAttributes(formValues);
    tenancyRequest.accepted = true;

    const result = await tenancyRequest.save();
    if (result) {
      queryClient.setQueryData(
        `renter-tenancy-request-detail-${tenancyRequestId}`,
        tenancyRequest,
      );
      queryClient.invalidateQueries('renter-tenancy-requests');

      const toastString =
        tenancyRequest.tenancy.tenancyRequests.length === 1
          ? 'You have successfully accepted this tenancy. More details will become available from your dashboard in a few moments once Keyhook has processed the tenancy.'
          : 'You have successfully accepted this tenancy. More details will be available once all invited tenants have signed.';

      toast.success(toastString);

      TrackingService.trackEvent(TrackingService.Event.AcceptNewLease);
    } else {
      for (const field in tenancyRequest.errors) {
        const error = tenancyRequest.errors[field];
        actions.setFieldError(field, error?.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  const acceptMigratedLease = async () => {
    tenancyRequest.accepted = true;

    const result = await tenancyRequest.save();
    if (result) {
      queryClient.setQueryData(
        `renter-tenancy-request-detail-${tenancyRequestId}`,
        tenancyRequest,
      );
      queryClient.invalidateQueries('renter-tenancy-requests');

      toast.success(
        'You have successfully accepted your landlords invitation!',
      );

      TrackingService.trackEvent(TrackingService.Event.AcceptMigratedLease);
    } else {
      toast.error('Something went wrong while accepting this tenancy.');
    }
  };

  const handleSplitsSubmit = async (formValues: any, actions: any) => {
    const response = await fetch(
      `${API_URL}/tenancies/${tenancyRequest.tenancy.id}/set_rent_splits.json`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
        body: JSON.stringify(formValues),
      },
    );

    if (response.ok) {
      const bondResponse = await fetch(
        `${API_URL}/tenancies/${tenancyRequest.tenancy.id}/set_bond_splits.json`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
          body: JSON.stringify(formValues),
        },
      );

      if (bondResponse.ok) {
        queryClient.invalidateQueries(
          `renter-tenancy-request-detail-${tenancyRequestId}`,
        );

        TrackingService.trackEvent(TrackingService.Event.SetRentBondSplits);
      } else {
        const error = await response.json();
        actions.setFieldError('tenancyRequests', error.errors.join(', '));
      }
    } else {
      const error = await response.json();
      actions.setFieldError('tenancyRequests', error.errors.join(', '));
    }
    actions.setSubmitting(false);
  };

  const splitRentEvenly = (formikProps: FormikProps<any>) => {
    const numTenants = tenancyRequest.tenancy.tenancyRequests.length;
    const totalRent = tenancyRequest.tenancy.totalRent;

    const amounts = shareEvenly(totalRent, numTenants);
    for (const index in amounts) {
      const amount = amounts[index];

      formikProps.setFieldValue(`tenancyRequests[${index}].rentSplit`, amount);
    }

    TrackingService.trackEvent(TrackingService.Event.SplitRentEvenly);
  };

  const splitBondEvenly = (formikProps: FormikProps<any>) => {
    const numTenants = tenancyRequest.tenancy.tenancyRequests.length;
    const totalBond = tenancyRequest.tenancy.bond;

    const amounts = shareEvenly(totalBond, numTenants);
    for (const index in amounts) {
      const amount = amounts[index];

      formikProps.setFieldValue(`tenancyRequests[${index}].bondSplit`, amount);
    }

    TrackingService.trackEvent(TrackingService.Event.SplitBondEvenly);
  };

  const renderRentSplitCard = () => {
    if (tenancyRequest.isHeadTenant) {
      const values = {
        tenancyRequests: tenancyRequest.tenancy.tenancyRequests.map(
          (request) => ({
            id: request.id,
            rentSplit: request.rentSplit,
            bondSplit: request.bondSplit,
          }),
        ),
      };
      return (
        <Card title="Rent & Bond Share">
          <Paragraph>
            You have been designated as the head tenant and are responsible for
            setting the portion of rent / bond each tenant will pay. Please be
            sure to agree to these amounts in person with other tenants. You
            will not be able to edit these amounts if you make a mistake.
          </Paragraph>

          {!tenancyRequest.tenancy.isNew && (
            <p className="mb-2">
              The bond amounts should reflect what each tenant has already paid
              to Tenancy Services.
            </p>
          )}

          <Paragraph>
            Tenants won't be able to sign the lease until this is filled in.
          </Paragraph>

          <Formik
            initialValues={values}
            validationSchema={Yup.object().shape({
              tenancyRequests: Yup.array()
                .of(
                  Yup.object().shape({
                    id: Yup.string().required('Required').label('Id'),
                    rentSplit: Yup.number()
                      .typeError('Please enter a number')
                      .required()
                      .label('Share of Rent'),
                    bondSplit: Yup.number()
                      .typeError('Please enter a number')
                      .required()
                      .label('Share of Bond'),
                  }),
                )
                .required('Must have rent/bond shares set')
                .test({
                  name: 'rent-amounts-equal-total',
                  message: `Rent amounts do not total up to $${tenancyRequest.tenancy.totalRent}`,
                  test: (values) => {
                    let total = new Decimal(0);
                    for (const ship of values) {
                      total = total.add(new Decimal(ship.rentSplit));
                    }

                    return total.equals(
                      new Decimal(tenancyRequest?.tenancy.totalRent),
                    );
                  },
                })
                .test({
                  name: 'bond-amounts-equal-total',
                  message: `Bond amounts do not total up to $${tenancyRequest.tenancy.bond}`,
                  test: (values) => {
                    let total = new Decimal(0);
                    for (const ship of values) {
                      total = total.add(new Decimal(ship.bondSplit));
                    }

                    return total.equals(
                      new Decimal(tenancyRequest?.tenancy.bond),
                    );
                  },
                }),
            })}
            onSubmit={handleSplitsSubmit}
            validateOnBlur={false}
            validateOnChange={false}>
            {(formik) => {
              return (
                <Form>
                  {tenancyRequest.tenancy.tenancyRequests.map(
                    (request, index) => {
                      return (
                        <div className="form-control" key={request.id}>
                          <span className="tw-block mt-2">
                            {request.renterEmail}
                          </span>
                          <div className="flex justify-between">
                            <div className="w-full">
                              <label className="label">
                                <span className="label-text">Rent</span>
                              </label>
                              <label className="input-group">
                                <span>$</span>
                                <InputField
                                  name={`tenancyRequests[${index}].rentSplit`}
                                  formik={formik}
                                  type="number"
                                  placeholder="E.g. 123.45"
                                  disabled={request.rentSplit !== null}
                                />
                              </label>
                            </div>
                            <div className="w-full ml-2">
                              <label className="label">
                                <span className="label-text">Bond</span>
                              </label>
                              <label className="input-group">
                                <span>$</span>
                                <InputField
                                  name={`tenancyRequests[${index}].bondSplit`}
                                  formik={formik}
                                  type="number"
                                  placeholder="E.g. 123.45"
                                  disabled={tenancyRequest.bondSplit !== null}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    },
                  )}

                  {tenancyRequest.rentSplit && tenancyRequest.bondSplit ? (
                    <div className="mt-4 text-success">
                      <p>
                        You have successfully set the rent and bond portions for
                        your tenancy. You and your other tenants can now sign
                        the lease.
                      </p>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <button
                        className="btn btn-neutral"
                        type="button"
                        onClick={() => splitRentEvenly(formik)}>
                        Split Rent Evenly
                      </button>
                      <button
                        className="btn btn-neutral ml-4"
                        type="button"
                        onClick={() => splitBondEvenly(formik)}>
                        Split Bond Evenly
                      </button>
                      <SubmitButton
                        submittingText="Saving"
                        text="Save"
                        formik={formik}
                        className="mt-3"
                      />

                      {formik.errors.tenancyRequests && (
                        <p className="mt-2 text-red-500">
                          {formik.errors.tenancyRequests
                            .toString()
                            .includes('[')
                            ? ''
                            : formik.errors.tenancyRequests.toString()}
                        </p>
                      )}

                      <p className="text-secondary text-sm mt-2">
                        When your rent or bond doesn't divide evenly, make sure
                        that each portion doesn't have recurring numbers.
                      </p>
                      <p className="text-secondary text-sm mt-2">
                        Please make sure your rent adds up to{' '}
                        {toCurrency(tenancyRequest.tenancy.totalRent)} and your
                        bond adds up to{' '}
                        {toCurrency(tenancyRequest.tenancy.bond)}
                      </p>

                      <p className="text-secondary text-sm mt-1">
                        0 is a valid amount if a tenant isn't going to be paying
                        rent or the bond, e.g., one half of a couple is paying
                        both portions of the rent/bond.
                      </p>
                    </div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Card>
      );
    } else {
      if (tenancyRequest.rentSplit && tenancyRequest.bondSplit) {
        return (
          <Card title="Your share of the rent / bond">
            <Paragraph>
              The head tenant has specified that your share of the rent will be{' '}
              {toCurrency(tenancyRequest.rentSplit)} and your share of the bond
              will be {toCurrency(tenancyRequest.bondSplit)}. If there are any
              issues with this amount, please discuss it with them and not your
              landlord.
            </Paragraph>
          </Card>
        );
      } else {
        return (
          <Card title="Waiting on the head tenant">
            <Paragraph>
              We are currently waiting on the head tenant to input your share of
              the rent and bond. This should be discussed in person as to how
              much each of you will pay.
            </Paragraph>

            <Paragraph>
              Once the head tenant has inputted your share of the rent and bond,
              you will be able to see it here and will then be able to accept
              the lease and sign any paperwork.
            </Paragraph>
          </Card>
        );
      }
    }
  };

  if (error) {
    const errMessage = (error as any).message as string;
    if (errMessage.includes('not found')) {
      queryClient.invalidateQueries('renter-tenancy-requests');
      return <Navigate href="/" />;
    } else {
      return errorViewForError(error);
    }
  } else if (isLoading) {
    return <Page title="Tenancy Request">{() => <LoadingView />}</Page>;
  } else {
    const tenancy = tenancyRequest.tenancy;
    const property = tenancy.property;
    const landlord = property.landlord;

    let leaseDocumentURL: string;
    let bondDocumentURL: string;
    let bondAdditionalURL: string;
    if (tenancy.isNew) {
      leaseDocumentURL = `${API_URL}/tenancy_requests/${tenancyRequestId}/lease_preview.pdf`;
      bondDocumentURL = `${API_URL}/tenancy_requests/${tenancyRequestId}/bond_preview.pdf`;
      bondAdditionalURL = `${API_URL}/tenancy_requests/${tenancyRequestId}/bond_additional_preview.pdf`;
    } else {
      const docs = tenancy.documents.filter(
        (doc) => doc.documentType === 'lease',
      );
      if (docs.length > 0) {
        const doc = docs[0];
        leaseDocumentURL = doc.document;
      }

      const docs2 = tenancy.documents.filter(
        (doc) => doc.documentType === 'bond',
      );
      if (docs2.length > 0) {
        const doc = docs2[0];
        bondDocumentURL = doc.document;
      }

      const docs3 = tenancy.documents.filter(
        (doc) => doc.documentType === 'bond_supplementary',
      );
      if (docs3.length > 0) {
        const doc = docs3[0];
        bondAdditionalURL = doc.document;
      }
    }

    const showBondViewer = () => {
      if (Capacitor.isNativePlatform()) {
        router.navigate(`/document-previews?url=${bondDocumentURL}`);
      } else {
        setBondViewerVisible(true);
        TrackingService.trackEvent(TrackingService.Event.ViewBond);
      }
    };

    const showLeaseViewer = () => {
      if (Capacitor.isNativePlatform()) {
        router.navigate(`/document-previews?url=${leaseDocumentURL}`);
      } else {
        setLeaseViewerVisible(true);
        TrackingService.trackEvent(TrackingService.Event.ViewLease);
      }
    };

    const showBondAdditionalViewer = () => {
      if (Capacitor.isNativePlatform()) {
        router.navigate(`/document-previews?url=${bondAdditionalURL}`);
      } else {
        setBondAdditionalViewerVisible(true);
      }
    };

    if (tenancy.isNew) {
      return (
        <Page title="Tenancy Request">
          {() => (
            <>
              <Card className="mt-4">
                <div className="alert items-start mb-4">
                  <div className="flex-1">
                    <UserAvatar user={landlord} size="10" />
                    <label className="ml-2">
                      <h4 className="font-semibold">{landlord.name}</h4>
                      <p className="text-sm text-base-content text-opacity-60">
                        Sent {moment(tenancyRequest.createdAt).fromNow()}
                      </p>
                    </label>
                  </div>
                </div>

                <strong className="tw-block text-2xl font-semibold">
                  {property.streetAddress}
                </strong>
                <span className="tw-block text-sm text-secondary">
                  {property.suburb}, {property.city}
                </span>

                <div className="flex justify-start mt-2">
                  <div className="w-1/2">
                    <span className="tw-block text-secondary">Rent</span>
                    <strong>{toCurrency(tenancy.totalRent)}</strong>
                    <span>/ {tenancy.rentalPeriod}</span>
                  </div>
                  <div className="w-1/2">
                    <span className="tw-block text-secondary">Bond</span>
                    <strong>{toCurrency(tenancy.bond)}</strong>
                  </div>
                </div>
                <div className="flex justify-start mt-2">
                  <div className="w-1/2">
                    <span className="tw-block text-secondary">
                      Lease Starts
                    </span>
                    <strong>
                      {moment(tenancy.startDate).format(DATE_FORMAT)}
                    </strong>
                  </div>
                  <div className="w-1/2">
                    <span className="tw-block text-secondary">Lease Ends</span>
                    <strong>
                      {tenancy.endDate
                        ? moment(tenancy.endDate).format(DATE_FORMAT)
                        : 'Ongoing'}
                    </strong>
                  </div>
                </div>

                {tenancy.weeksRentInAdvance > 0 && (
                  <div className="flex justify-start mt-2">
                    <div className="w-1/2">
                      <span className="tw-block text-secondary">
                        Rent in Advance
                      </span>
                      <strong>
                        {tenancy.weeksRentInAdvance}{' '}
                        {tenancy.weeksRentInAdvance === 1 ? 'Week' : 'Weeks'}
                      </strong>
                    </div>
                  </div>
                )}

                {tenancyRequest.tenancy.messageFromLandlord && (
                  <div className="my-4">
                    <span className="tw-block text-secondary">
                      Message from Landlord
                    </span>
                    <p>
                      <em>{tenancyRequest.tenancy.messageFromLandlord}</em>
                    </p>
                  </div>
                )}

                {tenancyRequest.isHeadTenant &&
                  tenancyRequest.tenancy.tenancyRequests.length > 1 && (
                    <TenancyClause
                      title="Head Tenant"
                      clause="You have been selected as the head tenant by the landlord. This means that you will be responsible for setting the portion of total rent that each tenant pays."
                      icon={headTenantIcon}
                    />
                  )}

                <div className="mt-2 flex flex-col gap-y-4">
                  <Paragraph secondary>
                    Note: The documents below are just a preview and will show
                    only your details under the tenant section. The full
                    documents will have all parties' details. You do not need to
                    fill any information into these documents.
                  </Paragraph>
                  <div className="flex flex-row gap-4 flex-wrap w-full">
                    <Button
                      category="primary"
                      size="base"
                      format="standard"
                      mode="manual"
                      label="Preview lease"
                      icon={HiOutlineEye}
                      onClick={showLeaseViewer}
                    />
                    {/* Don't show the bond preview for tenancies that use the boarding house lease */}
                    {tenancyRequest?.tenancy.leaseDocumentType !==
                      LeaseDocumentType.TenancyServicesBoardingHouse && (
                      <Button
                        category="primary"
                        size="base"
                        format="standard"
                        mode="manual"
                        label="Preview bond"
                        icon={HiOutlineEye}
                        onClick={showBondViewer}
                      />
                    )}

                    {bondAdditionalURL &&
                      tenancyRequest.tenancy.tenancyRequests.length > 2 && (
                        <Button
                          category="primary"
                          size="base"
                          format="standard"
                          mode="manual"
                          label="Preview bond supplementary"
                          icon={HiOutlineEye}
                          onClick={showBondAdditionalViewer}
                        />
                      )}
                  </div>
                  <DocumentPreviewModal
                    isVisible={leaseViewerVisible}
                    setIsVisible={setLeaseViewerVisible}
                    documentUrl={leaseDocumentURL}
                    title="Lease Preview"
                  />
                  <DocumentPreviewModal
                    isVisible={bondViewerVisible}
                    setIsVisible={setBondViewerVisible}
                    documentUrl={bondDocumentURL}
                    title="Bond Preview"
                  />

                  {bondAdditionalURL &&
                    tenancyRequest.tenancy.tenancyRequests.length > 2 && (
                      <DocumentPreviewModal
                        isVisible={bondAdditionalViewerVisible}
                        setIsVisible={setBondAdditionalViewerVisible}
                        documentUrl={bondAdditionalURL}
                        title="Bond Supplementary Preview"
                      />
                    )}
                </div>
              </Card>

              {documentsCard}

              {tenancyRequest.tenancy.tenancyRequests.length > 1 &&
                renderRentSplitCard()}

              {tenancyRequest.accepted ? (
                <Card title="Request Accepted">
                  {tenancyRequest.tenancy.tenancyRequests.length === 1 ? (
                    <Paragraph>
                      You have accepted the tenancy, our system is currently
                      processing the tenancy and it will become available from
                      your dashboard in a few moments.
                    </Paragraph>
                  ) : (
                    <Paragraph>
                      You have accepted the tenancy, once every other tenant has
                      signed you will receive a notification and this tenancy
                      will become available from your dashboard.
                    </Paragraph>
                  )}
                </Card>
              ) : (
                <Card title="Fill Info and Sign">
                  {tenancyRequest.rentSplit && tenancyRequest.bondSplit && (
                    <div>
                      <Paragraph>
                        Note that your ID information is only used for filling
                        in the lease, we delete this from our servers once the
                        lease is signed.
                      </Paragraph>

                      <Paragraph secondary>
                        By signing below you confirm that you agree to the terms
                        of the lease and bond.
                      </Paragraph>
                      <Formik
                        initialValues={{
                          identificationType: 'Passport',
                          identificationNumber: '',
                          phoneNumber: tenancyRequest.phoneNumber || '',
                          renterSignature: '',
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={Yup.object().shape({
                          identificationType: Yup.string()
                            .required()
                            .oneOf(['Passport', 'Drivers licence'])
                            .label('Identification Type'),
                          identificationNumber: Yup.string()
                            .required()
                            .min(5)
                            .max(12)
                            .label('Identification Number'),
                          phoneNumber: Yup.string().phone(
                            'NZ',
                            false, // Not strict
                            'Please enter a valid phone number',
                          ),
                          renterSignature: Yup.string()
                            .required()
                            .min(1)
                            .label('Signature'),
                        })}
                        validateOnBlur={false}
                        validateOnChange={false}>
                        {(formik) => (
                          <Form>
                            <div className="flex flex-col lg:flex-row justify-between">
                              <div className="flex-1">
                                <SelectField
                                  labelProps={{
                                    title: 'Identification type',
                                  }}
                                  name="identificationType"
                                  formik={formik}>
                                  {['Passport', 'Drivers licence'].map(
                                    (val) => (
                                      <option
                                        value={val}
                                        key={val}
                                        selected={
                                          formik.values.identificationType ==
                                          val
                                        }>
                                        {val}
                                      </option>
                                    ),
                                  )}
                                </SelectField>
                              </div>

                              <div className="flex-1 ml-0 lg:ml-2">
                                <InputField
                                  labelProps={{
                                    title: 'Identification number',
                                  }}
                                  name="identificationNumber"
                                  formik={formik}
                                />
                              </div>
                            </div>

                            <div className="mt-2">
                              <InputField
                                labelProps={{
                                  title: 'Phone number',
                                }}
                                name="phoneNumber"
                                placeholder="Enter your phone number here..."
                                formik={formik}
                              />
                            </div>

                            <div className="mt-3">
                              <SignatureModalInput
                                mode="formik"
                                name="renterSignature"
                                labelProps={{
                                  title: 'Signature',
                                }}
                              />
                            </div>

                            <div className="mt-2">
                              <SubmitButton
                                text="Accept Tenancy Request"
                                submittingText="Saving..."
                                formik={formik}
                                color="success"
                              />
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </Card>
              )}

              <ChatableMessages
                chatableId={tenancyRequest.tenancy.id}
                chatableType="Tenancy"
                isLandlord={false}
              />
            </>
          )}
        </Page>
      );
    } else {
      return (
        <Page title="Tenancy Request">
          {() => (
            <>
              <Card className="mt-4" key={tenancyRequest.id}>
                <div className="alert items-start mb-4">
                  <div className="flex-1">
                    <UserAvatar user={landlord} size="10" />
                    <label className="ml-2">
                      <h4 className="font-semibold">{landlord.name}</h4>
                      <p className="text-sm text-base-content text-opacity-60">
                        Sent {moment(tenancyRequest.createdAt).fromNow()}
                      </p>
                    </label>
                  </div>
                </div>

                <strong className="tw-block text-2xl font-semibold">
                  {property.streetAddress}
                </strong>
                <span className="tw-block text-sm text-secondary">
                  {property.suburb}, {property.city}
                </span>

                {tenancyRequest.tenancy.messageFromLandlord && (
                  <div className="my-2">
                    <span className="tw-block text-secondary">
                      Message from Landlord
                    </span>
                    <p>
                      <em>{tenancyRequest.tenancy.messageFromLandlord}</em>
                    </p>
                  </div>
                )}

                <div>
                  <Paragraph>
                    Your landlord has invited you to start using Keyhook to
                    manage your tenancy. Keyhook provides...
                  </Paragraph>

                  <ul className="ml-4 mt-2 list-disc">
                    <li>Easy maintenance requests</li>
                    <li>Privacy friendly inspections</li>
                    <li>Easy communication with your landlord</li>
                    <li>
                      All your agreements e-signed and stored in one place
                    </li>
                    <li>And lots more</li>
                  </ul>
                </div>

                {!tenancyRequest.accepted && (
                  <div className="mt-2">
                    <button
                      className="btn btn-success btn-block"
                      onClick={acceptMigratedLease}>
                      Accept Invite
                    </button>
                  </div>
                )}
              </Card>

              {documentsCard}

              {tenancyRequest.accepted && (
                <Card title="Invite Accepted">
                  {tenancyRequest.tenancy.startDate ? (
                    <div>
                      <Paragraph>
                        Congratulations, your landlord has already set a
                        commencement date of{' '}
                        {moment(tenancyRequest.tenancy.startDate).format(
                          'DD MMM YYYY',
                        )}
                        , this is when your payments, inspections and
                        maintenance will all be done through Keyhook. You can
                        view your tenancy information from your Keyhook
                        Dashboard for more information.
                      </Paragraph>
                    </div>
                  ) : (
                    <div>
                      <Paragraph>
                        Congratulations! You're almost ready to use Keyhook.
                        Your landlord is setting a commencement date after which
                        you'll be able to enjoy all of Keyhook's benefits for
                        free!
                      </Paragraph>

                      <Paragraph>
                        Once your landlord has set the commencement date, you
                        will be provided with updated banking information and
                        get access to the full suite of Keyhook features.
                      </Paragraph>
                    </div>
                  )}
                </Card>
              )}
            </>
          )}
        </Page>
      );
    }
  }
};

export default TenancyRequestDetailPage;
