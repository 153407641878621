import {useMemo, useCallback} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {
  HiOutlineChatAlt,
  HiOutlinePlus,
  HiOutlineSupport,
  HiOutlineUser,
  HiOutlineUserGroup,
  HiPlus,
} from 'react-icons/hi';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {Card, EmptyContentPlaceholder} from 'components_sb/layout';
import {NATIVE_LAYOUT_IN_WEB, TARGET_ENV} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Conversation from 'models/Conversation';
import {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import {ChatChannel, usePusherService} from 'services/PusherService';
import {Action} from 'types/actions';

import ConversationsList from './ConversationsList';

/**
 * IDs of the Keyhook support users (in production)
 */
const SUPPORT_USER_IDS = [
  32, // Maintenance support
  33, // General support
];

const ChatIndexPage = () => {
  const {channels: allChannels, channelsLoaded} = usePusherService();

  /**
   * Find any conversation channels with the explicitly defined Keyhook
   * support user accounts.
   */
  const supportChannels = useMemo<ChatChannel[]>(
    () =>
      allChannels.filter(
        (channel) =>
          channel.identifier === 'ConversationChannel' &&
          SUPPORT_USER_IDS.includes(
            Number((channel.model as Conversation).sender.id),
          ),
      ),
    [allChannels],
  );

  /**
   * Find all remaining channels that are not considered a support channel.
   */
  const generalChannels = useMemo<ChatChannel[]>(
    () =>
      allChannels.filter((channel) =>
        supportChannels.every(
          (supportChannel) => supportChannel.model.id !== channel.model.id,
        ),
      ),
    [allChannels, supportChannels],
  );

  const {currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  const {isGhostingUser} = useAuth();

  const showLiveSupport = useCallback(async () => {
    if (isGhostingUser) {
      alert('Contacting support is unavailable when ghosting a user.');
    } else {
      if (Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB) {
        const params = new URLSearchParams();
        params.set('user_id', currentUser.id);
        params.set('name', currentUser.name);
        params.set('email', currentUser.email);
        params.set('env', TARGET_ENV);
        let url = `https://livesupport.keyhook.com?${params.toString()}`;
        url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');
        await Browser.open({url: url});
      } else {
        if ((window as any).customerly) {
          (window as any).customerly.open();
        }
      }
    }
  }, [currentUser, isGhostingUser]);

  return (
    <Page title="Chat" disableBack loading={!channelsLoaded}>
      {() => (
        <>
          {/* Support conversations */}
          <Card title="Chat with Keyhook Support" icon={HiOutlineSupport}>
            <div className="flex flex-col gap-y-4">
              <ConversationsList channels={supportChannels} />
              <div className="max-w-auto sm:max-w-xs">
                <Button
                  category="primary"
                  size={'base'}
                  format="standard"
                  label="Contact support"
                  icon={HiOutlineChatAlt}
                  mode="manual"
                  fillWidth
                  onClick={showLiveSupport}
                />
              </div>
            </div>
          </Card>

          {/* Property conversations */}
          <Card
            icon={
              activeAccountRole === AccountRole.Renter
                ? HiOutlineUser
                : HiOutlineUserGroup
            }
            title={`Chat with your ${
              activeAccountRole === AccountRole.Renter ? 'Landlord' : 'Tenants'
            }`}>
            <div className={clsx('flex flex-col gap-y-4')}>
              {!generalChannels || generalChannels.length === 0 ? (
                <EmptyContentPlaceholder
                  type="chat"
                  message="You don't have any conversations at the moment"
                  alignX="start"
                />
              ) : (
                <ConversationsList channels={generalChannels} />
              )}
              <div className="max-w-auto sm:max-w-xs">
                <Button
                  category="primary"
                  size={'base'}
                  format="standard"
                  label="New conversation"
                  icon={HiPlus}
                  mode="link"
                  linkTo={`/chat/new`}
                  fillWidth
                />
              </div>
            </div>
          </Card>
        </>
      )}
    </Page>
  );
};

export default ChatIndexPage;
