import {FunctionComponent, ReactNode, useCallback} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import * as Sentry from '@sentry/react';
import {isMobile} from 'react-device-detect';
import {HiOutlineChat, HiOutlineRefresh} from 'react-icons/hi';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {NATIVE_LAYOUT_IN_WEB, TARGET_ENV} from 'globals/app-globals';

import '@dotlottie/player-component';

/**
 * Because Customerly requires auth data, and it's possible that the error boundary
 * itself could have been displayed as a result of an auth error, we need to use a
 * nested error boundary for the live chat support button so that the main error
 * boundary doesn't also  cause the app to crash by attempting to use auth data.
 */
const LiveSupportButton: FunctionComponent = () => {
  const {isLoggedIn, currentUser} = useAuth();
  const showLiveSupport = useCallback(async () => {
    if (Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB) {
      const params = new URLSearchParams();
      params.set('user_id', currentUser.id);
      params.set('name', currentUser.name);
      params.set('email', currentUser.email);
      params.set('env', TARGET_ENV);
      let url = `https://livesupport.keyhook.com?${params.toString()}`;
      url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');
      await Browser.open({url: url});
    } else {
      if ((window as any).customerly) {
        (window as any).customerly.open();
      }
    }
  }, [currentUser]);

  return !isLoggedIn ? null : (
    <Sentry.ErrorBoundary fallback={null}>
      <Button
        label="Talk to our team"
        icon={HiOutlineChat}
        category="primary"
        size="base"
        mode="manual"
        onClick={showLiveSupport}
      />
    </Sentry.ErrorBoundary>
  );
};

const Fallback = () => {
  const onReload = useCallback(() => {
    window.location.href = '/';
    window.location.reload();
  }, []);

  return (
    <div className="w-full h-full bg-white flex items-center justify-center">
      <div className="flex flex-col text-center">
        <div className="flex justify-center items-center">
          <dotlottie-player
            autoplay
            loop
            mode="normal"
            src="/assets/lottie/error_page.lottie"
            style={{height: 300, width: 300}}
          />
        </div>
        <p className="text-xl font-medium text-brand-850 opacity-70 mb-12">
          Something went wrong...
        </p>
        <div className="flex flex-col gap-y-6">
          <Button
            label={`${isMobile ? 'Tap' : 'Click'} here to reload`}
            icon={HiOutlineRefresh}
            category="primary"
            size="base"
            mode="manual"
            onClick={onReload}
          />
          <LiveSupportButton />
        </div>
      </div>
    </div>
  );
};

interface ErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = ({children}) => {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
