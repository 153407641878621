import React from 'react';

import moment from 'moment';

import Inspection from 'models/inspections/Inspection';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';

const InspectionPrintItem = ({
  item,
  inspection,
}: {
  inspection: Inspection;
  item: InspectionItemAttachment | InspectionAction;
}) => {
  const isAttachment = item.isType('inspection_item_attachments');

  const shouldShowUserName =
    inspection.doneBy === 'landlord' &&
    item.userId !== inspection.tenancy.property.landlordId;

  const fullWidth =
    !isAttachment ||
    ((item as InspectionItemAttachment).notes &&
      (item as InspectionItemAttachment).notes.length > 0);

  if (fullWidth) {
    return (
      <div className="w-full my-2">
        <div className="flex justify-start items-center gap-2">
          <a href={item.attachment}>
            <img
              className="bg-black tw-block h-[300px] w-[200px] object-fit break-inside-avoid"
              src={item.attachmentThumbnail || item.attachment}
            />
          </a>

          <div>
            <p className="text-xs text-secondary">
              {moment(item.createdAt).format('DD/MM/YYYY hh:mm a')}
            </p>
            <div className="font-semibold">
              {isAttachment
                ? 'Notes'
                : (item as InspectionAction).actionType == 'landlord'
                ? 'Landlord Action'
                : 'Tenant Action'}
            </div>
            {isAttachment ? (
              <div className="text-sm">
                {(item as InspectionItemAttachment).notes &&
                (item as InspectionItemAttachment).notes.length > 0
                  ? (item as InspectionItemAttachment).notes
                  : '-'}
              </div>
            ) : (
              <div className="text-sm">{(item as InspectionAction).action}</div>
            )}

            {shouldShowUserName && (
              <div className="text-xs text-gray-400 mt-2">
                Added by {item.user.name}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-1/4 my-2">
        <div className="flex justify-start items-center gap-2">
          <a href={item.attachment}>
            <img
              className="bg-black tw-block h-[250px] w-[150px] object-fit break-inside-avoid"
              src={item.attachmentThumbnail || item.attachment}
            />
          </a>
        </div>
      </div>
    );
  }
};

export default InspectionPrintItem;
