import {ReactNode, useMemo} from 'react';

import clsx from 'clsx';
import {isEmpty} from 'lodash';
import queryString from 'query-string';

import {RouterLink} from 'router/components';
import {RouterLinkProps} from 'router/components/RouterLink';
import useRoute from 'router/hooks/useRoute';

export const classes = {
  // Classes for all permutations
  base: {
    main: clsx(
      'cursor-pointer',
      'overflow-ellipsis',
      'text-base font-medium',
      'group transition duration-300',
      'inline-block', // Prevents filling full width
    ),
    underline: clsx(
      'tw-block',
      'max-w-0',
      'group-hover:max-w-full',
      'transition-all',
      'duration-500',
      'h-0.5',
    ),
  },
  // Classes based on color
  color: {
    label: {
      brand: 'text-brand-500',
      white: 'text-white',
    },
    underline: {
      brand: 'bg-brand-500',
      white: 'bg-white',
    },
  },
};

interface CustomHyperlinkProps {
  /**
   *  The text shown as the link.
   */
  children: string | ReactNode;
  /**
   * The path or URL to navigate to.
   */
  href: string;
  /**
   *  The color of the link.
   */
  color?: 'brand' | 'white';
  /**
   * Opens the link in a new tab instead of the current tab.
   */
  openInNewTab?: boolean;
  /**
   * Whether the link text should be able to span multiple lines.
   */
  allowWrap?: boolean;
  /**
   * Whether any existing query params should be persisted when
   * navigating to the new location.
   */
  persistQueryParams?: boolean;
}

type HyperlinkProps = CustomHyperlinkProps & RouterLinkProps;

/**
 *  A type of button intended for navigation or actions that
 *  do not directly affect the current page/flow.
 */
const Hyperlink = ({
  children,
  color = 'brand',
  openInNewTab = false,
  allowWrap,
  persistQueryParams,
  href,
  ...props
}: HyperlinkProps) => {
  /**
   * Get any query params that are present in the current URL.
   */
  const {query: existingQueryParams} = useRoute();

  /**
   * Get the path without any query params.
   */
  const path = useMemo(
    () => (href.includes('?') ? href.substring(0, href.indexOf('?')) : href),
    [href],
  );

  /**
   * Build the query params to be used in the link.
   */
  const queryParams = useMemo(() => {
    const providedQueryParams = href.includes('?')
      ? queryString.parse(href.slice(href.indexOf('?')))
      : {};

    return persistQueryParams
      ? {...existingQueryParams, ...providedQueryParams}
      : providedQueryParams;
  }, [persistQueryParams, existingQueryParams, href]);

  /**
   * Build the location to link to.
   */
  const processedHref = useMemo(
    () =>
      isEmpty(queryParams)
        ? path
        : `${path}?${queryString.stringify(queryParams)}`,
    [path, queryParams],
  );

  return (
    <span className="inline-block relative">
      <RouterLink
        href={processedHref}
        target={openInNewTab ? '_blank' : '_self'}
        className={clsx(classes.base.main, classes.color.label[color])}
        {...props}>
        <span
          className={clsx(
            allowWrap ? 'whitespace-normal' : 'whitespace-nowrap',
          )}>
          {children}
        </span>
        <span
          className={clsx(
            classes.base.underline,
            classes.color.underline[color],
          )}
        />
      </RouterLink>
    </span>
  );
};

export default Hyperlink;
