import {API_URL} from 'globals/app-globals';

import {SocialAuthUserData} from '../social/platforms';
import {AuthMethod, AuthResult} from '../types';
import parseAuthResponse from '../utils/parse-auth-response';

export interface LoginFormData {
  user: {
    email: string;
    password: string;
  };
}

export interface LoginFunctionProps {
  method: AuthMethod;
  loginData: LoginFormData | SocialAuthUserData;
}

export interface LoginFunction {
  (props: LoginFunctionProps): Promise<AuthResult>;
}

/**
 * Logs a user in.
 */
const login: LoginFunction = async ({method, loginData}) => {
  /**
   * Attempt to log the user in.
   */
  const response = await fetch(
    API_URL +
      (method === 'email' ? '/users/login.json' : `/identities/${method}.json`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    },
  );

  return await parseAuthResponse(response);
};

export default login;
