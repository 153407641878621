import {Attr, BelongsTo, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import User from 'models/users/User';

@Model()
class ListingUserNotes extends ApplicationRecord {
  static jsonapiType = 'listing_user_notes';

  @Attr() notes: string;
  @Attr() email: string;
  @Attr() userId: string;
  @Attr() listingId: string;

  @BelongsTo('listings') listing: Listing;
  @BelongsTo('users') user: User;
}

export default ListingUserNotes;
