import {Router} from 'framework7/types';

import {AccountRole} from 'models/users/User';
import ConfirmEmailPage from 'pages/shared/auth/ConfirmEmailPage';
import EnterMissingAccountDetailsPage from 'pages/shared/auth/EnterMissingAccountDetailsPage';
import LoginPage from 'pages/shared/auth/LoginPage';
import LogoutPage from 'pages/shared/auth/LogoutPage';
import RegisterPage from 'pages/shared/auth/RegisterPage';
import RequestPasswordResetPage from 'pages/shared/auth/RequestPasswordResetPage';
import ResetPasswordPage from 'pages/shared/auth/ResetPasswordPage';
import SelectAccountTypePage from 'pages/shared/auth/SelectAccountTypePage';
import GhostPage from 'pages/shared/ghost/GhostPage';
import resolveRoute, {
  ConditionsMode,
  RouteCondition,
} from 'router/utils/route-resolver';

const {
  NEVER,
  LOGGED_IN,
  LOGGED_OUT,
  NO_ACCOUNT_ROLE,
  MISSING_REQUIRED_ACCOUNT_DETAILS,
  PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION,
} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/register',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
    routes: [
      {
        path: 'landlord',
        id: 'LandlordRegistration',
        async: resolveRoute(
          {
            conditions: [LOGGED_OUT],
            component: RegisterPage,
          },
          {
            redirect: '/',
          },
        ),
        options: {
          props: {
            accountRole: AccountRole.Landlord,
          },
        },
      },
      {
        path: 'tenant',
        id: 'RenterRegistration',
        async: resolveRoute(
          {
            conditions: [LOGGED_OUT],
            component: RegisterPage,
          },
          {
            redirect: '/',
          },
        ),
        options: {
          props: {
            accountRole: AccountRole.Renter,
          },
        },
      },
      {
        path: 'confirm-email',
        id: 'ConfirmEmail',
        async: resolveRoute({
          conditions: [
            LOGGED_IN,
            MISSING_REQUIRED_ACCOUNT_DETAILS,
            PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION,
          ],
          conditionsMode: ConditionsMode.SOME,
          component: ConfirmEmailPage,
        }),
      },
      /**
       * This route is a safety net if the user somehow managed to
       * register an account without selecting an account type.
       */
      {
        path: 'account-type',
        id: 'SelectAccountType',
        async: resolveRoute({
          conditions: [LOGGED_IN, NO_ACCOUNT_ROLE],
          component: SelectAccountTypePage,
        }),
      },
      /**
       * This route is a safety net if the user has signed up and
       * is missing required account details - this typically will
       * occur if they have logged in via Apple and used Apple's
       * private email relay service to hide their details.
       */
      {
        path: 'details',
        id: 'EnterMissingAccountDetails',
        async: resolveRoute({
          conditions: [LOGGED_IN, MISSING_REQUIRED_ACCOUNT_DETAILS],
          component: EnterMissingAccountDetailsPage,
        }),
      },
    ],
  },
  {
    path: '/login',
    id: 'Login',
    async: resolveRoute(
      {
        conditions: [LOGGED_OUT],
        component: LoginPage,
      },
      {
        redirect: '/',
      },
    ),
  },
  {
    path: '/logout',
    id: 'Logout',
    async: resolveRoute({
      conditions: [
        LOGGED_IN,
        NO_ACCOUNT_ROLE,
        MISSING_REQUIRED_ACCOUNT_DETAILS,
      ],
      conditionsMode: ConditionsMode.SOME,
      component: LogoutPage,
    }),
  },
  {
    path: '/reset-password',
    id: 'RequestPasswordReset',
    async: resolveRoute(
      {
        conditions: [LOGGED_OUT],
        component: RequestPasswordResetPage,
      },
      {
        redirect: '/',
      },
    ),
    routes: [
      {
        path: ':token',
        id: 'ResetPassword',
        async: resolveRoute(
          {
            conditions: [LOGGED_OUT],
            component: ResetPasswordPage,
          },
          {
            redirect: '/',
          },
        ),
      },
    ],
  },
  {
    path: '/ghost',
    id: 'GhostUser',
    async: resolveRoute({
      component: GhostPage,
    }),
  },
];

export default routes;
