import LandlordSubscription from 'models/payments/LandlordSubscription';
import LandlordProfile from 'models/users/LandlordProfile';

interface FetchLandlordSubscription {
  (args: {currentUserId: string}): Promise<LandlordSubscription>;
}

/**
 * Fetch the Landlord subscription instance for the logged in Landlord.
 */
const fetchLandlordSubscription: FetchLandlordSubscription = async ({
  currentUserId,
}) => {
  /**
   * Fetch the Landlord profile instance for the currently logged in Landlord
   * with the LandlordSubscription relationship included.
   */
  const landlordProfile = await LandlordProfile.includes({
    landlord_subscription: 'subscription',
  })
    .where({user_id: currentUserId})
    .first();

  /**
   * Deconstruct and return the landlord subscription.
   */
  const {landlordSubscription} = landlordProfile.data;
  return landlordSubscription;
};

export default fetchLandlordSubscription;
