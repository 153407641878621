import {ComponentPropsWithoutRef, ElementRef, forwardRef} from 'react';

import * as AccordionPrimitive from '@radix-ui/react-accordion';

import cn from 'khui/utils/cn';

const AccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({className, ...props}, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-b-2 border-brand-50', className)}
    {...props}
  />
));

AccordionItem.displayName = 'AccordionItem';
export default AccordionItem;
