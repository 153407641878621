import {useCallback, useState} from 'react';

import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import WelcomeScreen from 'components/auth/WelcomeScreen';
import {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRouter from 'router/hooks/useRouter';

const SelectAccountTypePage = () => {
  const {selectAccountType} = useAuth();
  const router = useRouter();

  const [isSelectingAccountType, setIsSelectingAccountType] =
    useState<boolean>(false);

  const onSelectAccountType = useCallback(
    async (accountType: AccountRole) => {
      setIsSelectingAccountType(true);

      try {
        await selectAccountType(accountType);

        /**
         * Redirect the user to their dashboard.
         */
        router.navigate('/');
      } catch (error) {
        toast.error('Sorry, there was an issue setting your account type.');
        setIsSelectingAccountType(false);
      }
    },
    [selectAccountType, router],
  );

  return (
    <Page title="Account Type" disableNavbar centred>
      {() => (
        <WelcomeScreen
          onSelectAccountType={onSelectAccountType}
          loading={isSelectingAccountType}
        />
      )}
    </Page>
  );
};

export default SelectAccountTypePage;
