import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {createPortal} from 'react-dom';

import {HiChevronDown} from '@react-icons/all-files/hi/HiChevronDown';
import clsx from 'clsx';
import {Collapse} from 'react-collapse';
import {MdOutlineMenuOpen} from 'react-icons/md';

import useAuth from 'auth/provider/useAuth';
import {useMobileMenu} from 'components/navbar/MobileMenu';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {NumberBadge} from 'components_sb/feedback';
import {Divider, ScrollContainer} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import {AccountRole} from 'models/users/User';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import getLinkComponent from 'utilities/getLinkComponent';
import {useNavItems} from 'utilities/nav-links';
import {NavLink, NavCategory, NavItem} from 'utilities/nav-links/types';

const classes = {
  navItem: {
    main: clsx(
      'w-full px-6 h-16 flex items-center gap-x-2',
      'bg-white text-brand-500',
      'active:bg-brand-25',
      'transition-all duration-200',
      'group',
    ),
    label: clsx(
      'leading-none',
      'transition-all duration-200',
      'scale-100 group-active:scale-95',
      'flex flex-row gap-x-2 items-center',
    ),
  },
};

/**
 * A button for expanding and displaying sub nav link items
 * within a category.
 */
const NavCategoryButton = ({label, subItems, badgeCount}: NavCategory) => {
  /**
   * Determines the expanded state of the sub nav link items.
   */
  const [isOpened, setIsOpened] = useState<boolean>(false);

  /**
   * Expand or collase the sub nav link items.
   */
  const toggle = useCallback(
    () => setIsOpened((current) => !current),
    [setIsOpened],
  );

  return (
    <>
      <button className={classes.navItem.main} onClick={toggle}>
        <span className={classes.navItem.label}>
          <span>{label}</span>
          <span>
            <HiChevronDown
              className={clsx(
                'w-5 h-5',
                'transition-transform duration-300',
                isOpened ? 'rotate-180' : 'rotate-0',
              )}
            />
          </span>
          <NumberBadge count={badgeCount} positioning="inline" />
        </span>
      </button>
      <Collapse isOpened={isOpened}>
        {/* TODO: Update level by adding 1 to previous level */}
        <NavItems navItems={subItems} level={1} />
      </Collapse>
    </>
  );
};

/**
 * A button for navigating to a nav link location.
 */
const NavLinkButton = ({label, linkTo, badgeCount}: NavLink) => {
  const LinkComponent = useMemo(() => getLinkComponent(linkTo), [linkTo]);
  return (
    <LinkComponent href={linkTo} className={classes.navItem.main}>
      <span className={classes.navItem.label}>{label}</span>
      <NumberBadge count={badgeCount} positioning="inline" />
    </LinkComponent>
  );
};

const NavItems = ({navItems, level}: {navItems: NavItem[]; level: number}) => (
  <div style={{paddingLeft: level * 10}}>
    {navItems.map((navItem) => {
      return navItem.subItems ? (
        <NavCategoryButton key={navItem.id} {...(navItem as NavCategory)} />
      ) : (
        <NavLinkButton key={navItem.id} {...(navItem as NavLink)} />
      );
    })}
  </div>
);

const WebHeaderMobileContent = () => {
  const mobileMenu = useMobileMenu();

  return (
    <>
      {/* Menu button */}
      <div className="ml-auto h-full flex flex-col">
        <button
          role="button"
          onClick={mobileMenu.toggle}
          className={clsx('flex-1 group', 'px-6 lg:px-8', 'py-6')}>
          <MdOutlineMenuOpen
            className={clsx(
              'w-7 h-7',
              'duration-300 transition-all',
              'text-brand-500 group-hover:text-brand-400',
              mobileMenu.isOpen ? 'rotate-180' : 'rotate-0',
            )}
          />
        </button>
      </div>
    </>
  );
};

const MenuContent = () => {
  const {currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();
  const navItems = useNavItems();

  const router = useRouter();

  const onLogOutClicked = useCallback(() => {
    router.navigate('/logout', {animate: false});
  }, [router]);

  return (
    <div className="flex-1 flex flex-col min-w-64 max-h-full">
      {/* Navigation link items */}
      <ScrollContainer>
        {navItems && <NavItems navItems={navItems} level={0} />}
      </ScrollContainer>
      <Divider noMargin />

      <div className="flex flex-col gap-y-4 p-6">
        {/* Log in and register buttons */}
        {!currentUser && (
          <>
            <Button
              label="Register"
              category="primary"
              size="sm"
              mode="link"
              linkTo="/register"
            />
            <Button
              label="Log in"
              category="secondary"
              size="sm"
              mode="link"
              linkTo="/login"
            />
          </>
        )}
        {/* Log out button */}
        {currentUser && (
          <>
            {activeAccountRole !== null && (
              <>
                <Button
                  label="My Account"
                  category="secondary"
                  size="sm"
                  mode="link"
                  linkTo="/account"
                />
                {activeAccountRole === AccountRole.Landlord && (
                  <Button
                    label="Billing"
                    category="secondary"
                    size="sm"
                    mode="link"
                    linkTo="/account/billing"
                  />
                )}
                <Button
                  label="Settings"
                  category="secondary"
                  size="sm"
                  mode="link"
                  linkTo="/account/settings"
                />
              </>
            )}
            <Button
              label="Log out"
              category="secondary"
              size="sm"
              mode="manual"
              onClick={onLogOutClicked}
            />
          </>
        )}
      </div>
    </div>
  );
};

export {MenuContent};

export default WebHeaderMobileContent;
