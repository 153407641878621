import React, {useMemo, useState} from 'react';

import moment from 'moment';
import {FileIcon, defaultStyles} from 'react-file-icon';
import GaugeComponent from 'react-gauge-component';
import {AiOutlineCheckCircle, AiOutlineExclamationCircle} from 'react-icons/ai';
import {FaChartPie} from 'react-icons/fa';
import {useQuery} from 'react-query';

import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import ReferencesCard from 'components/tenant_checks/references/ReferencesCard';
import CompaniesRegistrarResultsCard from 'components/tenant_checks/result_cards/CompaniesRegistrarResultsCard';
import GovtJusticeResultsCard from 'components/tenant_checks/result_cards/GovtJusticeResultsCard';
import InsolvencyRegistrarResultsCard from 'components/tenant_checks/result_cards/InsolvencyRegistrarCard';
import InterpolResultsCard from 'components/tenant_checks/result_cards/InterpolResultsCard';
import NewsResultsCard from 'components/tenant_checks/result_cards/NewsResultsCard';
import ObituariesResultsCard from 'components/tenant_checks/result_cards/ObituariesResultsCard';
import ParoleBoardResultsCard from 'components/tenant_checks/result_cards/ParoleBoardResultsCard';
import PoliceResultsCard from 'components/tenant_checks/result_cards/PoliceResultsCard';
import SearchResultsCard from 'components/tenant_checks/result_cards/SearchResultsCard';
import SocialsResultsCard from 'components/tenant_checks/result_cards/SocialsResultsCard';
import TenancyTribunalResultsCard from 'components/tenant_checks/result_cards/TenancyTribunalResultsCard';
import {Card} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph, Title} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import TenantCheckReport, {
  TenantCheckReportStatus,
} from 'models/tenant_checks/TenantCheckReport';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {isDefined} from 'utilities/MiscHelpers';

/**
 * See this document for more info on scoring:
 * https://www.centrix.co.nz/wp-content/uploads/2020/06/Your-credit-file-what-does-it-mean-Centrix-June-2020.pdf
 * We use the halfway points between the ranges so that the tick labels on the gauge are in the center of the arc
 * segment
 */
const CentrixScores = {
  '247': 'Poor',
  '572': 'Fair',
  '709': 'Average',
  '807': 'Very Good',
  '922': 'Excellent',
} as Record<string, string>;

const ArcTooltipStyle = {
  textShadow: 'none',
  color: 'white',
  border: 'none',
};

interface CentrixSummary {
  judgements?: number;
  insolvencyNotices?: number;
  debtRepaymentOrders?: number;
  creditDefaults?: number;
  driversLicenseVerification?: boolean;
  companyAffiliations?: number;
}

const CentrixSummaryItem = ({
  label,
  number,
  passed,
}: {
  label: string;
  number?: number;
  passed: boolean;
}) => (
  <div className="flex justify-start items-center gap-4">
    {passed ? (
      <AiOutlineCheckCircle className="w-4 h-4 text-success" />
    ) : (
      <AiOutlineExclamationCircle className="w-4 h-4 text-error" />
    )}
    <p>
      {label}
      {isDefined(number) ? ':' : ''}
    </p>
    {number && <p>{number}</p>}
  </div>
);

const TenantCheckReportDetailPage = () => {
  const {
    params: {id},
  } = useRoute();

  const [centrixPdfOpen, setCentrixPdfOpen] = useState(false);

  const {data, isLoading, error} = useQuery(
    ['tenant-check-report', id],
    async () => {
      const report = await TenantCheckReport.includes([
        'tenant_check_references',
      ]).find(id);
      return report.data;
    },
  );

  const centrixScore = useMemo(() => {
    if (!data || !data.centrixReport) {
      return null;
    } else {
      let score = null;
      if (data.centrixReport['DataSets']) {
        const items = data.centrixReport['DataSets']['ExtraDataItems'];
        if (items) {
          const item = items.find(
            (item: any) => item['ExtraDataItemType'] === 'CentrixScore',
          );
          if (item) {
            const scoreItem = item['NameValuePairs'].find(
              (pair: any) => pair['Name'] === 'RawScore',
            );

            score = scoreItem['Value'];
          }
        }
      }

      return score;
    }
  }, [data]);

  const centrixSummary: CentrixSummary | null = useMemo(() => {
    if (data && data.centrixReport) {
      const summaries = data.centrixReport['DataSets']['SummaryItems'];
      const summary = {} as CentrixSummary;

      for (const item of summaries) {
        const type = item['SummaryItemType'];
        if (type === 'DirectorAffiliations') {
          for (const subItem of item['NameValuePairs']) {
            if (subItem['Name'] === 'TotalCurrentDirectorships') {
              summary.companyAffiliations = Number(subItem['Value']);
              break;
            }
          }
        } else if (type === 'Defaults') {
          for (const subItem of item['NameValuePairs']) {
            if (subItem['Name'] === 'TotalDefaults') {
              summary.creditDefaults = Number(subItem['Value']);
              break;
            }
          }
        } else if (type === 'Insolvencies') {
          for (const subItem of item['NameValuePairs']) {
            if (subItem['Name'] === 'TotalCurrentBankruptcies') {
              summary.insolvencyNotices = Number(subItem['Value']);
              break;
            }
          }
        } else if (type === 'Judgments') {
          for (const subItem of item['NameValuePairs']) {
            if (subItem['Name'] === 'TotalJudgments') {
              summary.judgements = Number(subItem['Value']);
              break;
            }
          }
        }
      }

      if (
        data.centrixReport['DataSets'] &&
        data.centrixReport['DataSets']['DriverLicenceVerification']
      ) {
        summary.driversLicenseVerification =
          data.centrixReport['DataSets']['DriverLicenceVerification'][
            'IsDriverLicenceVerified'
          ];
      }

      return summary;
    }

    return null;
  }, [data]);

  return (
    <Page title="Tenant Check Report" loading={isLoading} error={error}>
      {() => {
        if (data.status === TenantCheckReportStatus.AwaitingExtraInformation) {
          return (
            <Card title="Awaiting Extra Information">
              <Paragraph>
                We are awaiting extra information from the applicant before we
                can perform a tenant check. Once the tenant has provided it and
                the report is underway, you will be notified.
              </Paragraph>

              <Paragraph>
                If the applicant does not provide this information within 48
                hours of the request, you will be refunded.
              </Paragraph>
            </Card>
          );
        } else if (data.status === TenantCheckReportStatus.AwaitingResults) {
          return (
            <Card title="Report Underway">
              <Paragraph>
                The tenant check report is underway. You will be notified when
                it is complete.
              </Paragraph>
            </Card>
          );
        } else {
          return (
            <div>
              <Card title="Centrix Report" icon={FaChartPie}>
                {centrixScore && (
                  <div className="flex flex-col lg:flex-row lg:items-center lg:justify-start lg:gap-8">
                    <div>
                      <GaugeComponent
                        arc={{
                          subArcs: [
                            {
                              color: 'red',
                              showTick: false,
                              limit: 494,
                              tooltip: {
                                text: 'More likely to be rejected for most credit cards, loans and utility services',
                                style: ArcTooltipStyle,
                              },
                            },
                            {
                              color: 'orange',
                              showTick: false,
                              limit: 649,
                              tooltip: {
                                text: 'Might get credit cards, loans and utility services but these may have additional conditions',
                                style: ArcTooltipStyle,
                              },
                            },
                            {
                              color: 'yellow',
                              showTick: false,

                              limit: 768,
                              tooltip: {
                                text: 'Should be eligible for standard credit cards, loans and utility services',
                                style: {
                                  ...ArcTooltipStyle,
                                  color: 'black',
                                },
                              },
                            },
                            {
                              color: 'lime',
                              showTick: false,
                              limit: 845,
                              tooltip: {
                                text: 'Should be eligible for most credit cards, loans and utility services',
                                style: ArcTooltipStyle,
                              },
                            },
                            {
                              color: 'green',
                              showTick: false,

                              limit: 1000,
                              tooltip: {
                                text: 'Should be eligible for the best credit cards, loans and utility services (but there are no guarantees)',
                                style: ArcTooltipStyle,
                              },
                            },
                          ],
                        }}
                        labels={{
                          valueLabel: {
                            matchColorWithArc: true,
                            style: {
                              textShadow: 'none',
                              fontWeight: 'bold',
                            },
                          },
                          tickLabels: {
                            type: 'outer',
                            defaultTickValueConfig: {
                              formatTextValue: (val: number) =>
                                CentrixScores[val.toString()],
                              style: {
                                textShadow: 'none',
                                fontSize: 8,
                                color: 'black',
                                fill: 'unset',
                              },
                            },
                            hideMinMax: true,
                            ticks: Object.keys(CentrixScores).map((k) => {
                              return {value: Number(k)};
                            }),
                          },
                        }}
                        value={centrixScore}
                        minValue={0}
                        maxValue={1000}
                      />
                      <div className="text-center mt-2">
                        <Paragraph secondary size="xs">
                          (The higher the score the lower the risk)
                        </Paragraph>
                      </div>
                    </div>
                    {centrixSummary && (
                      <div>
                        <Title level="h4" size="md">
                          Summary Information
                        </Title>

                        {isDefined(centrixSummary.judgements) && (
                          <CentrixSummaryItem
                            label="Judgments"
                            number={centrixSummary.judgements}
                            passed={centrixSummary.judgements === 0}
                          />
                        )}

                        {isDefined(centrixSummary.insolvencyNotices) && (
                          <CentrixSummaryItem
                            label="Insolvency Notices"
                            number={centrixSummary.insolvencyNotices}
                            passed={centrixSummary.insolvencyNotices === 0}
                          />
                        )}

                        {isDefined(centrixSummary.creditDefaults) && (
                          <CentrixSummaryItem
                            label="Credit Defaults"
                            number={centrixSummary.creditDefaults}
                            passed={centrixSummary.creditDefaults === 0}
                          />
                        )}

                        {isDefined(
                          centrixSummary.driversLicenseVerification,
                        ) && (
                          <CentrixSummaryItem
                            label="Drivers License Verification"
                            passed={centrixSummary.driversLicenseVerification}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}

                {data.centrixDocument && (
                  <div className="mt-4">
                    <a onClick={() => setCentrixPdfOpen(true)}>
                      <div className="alert bg-gray-50 flex justify-start">
                        <div className="w-10">
                          <FileIcon extension="pdf" {...defaultStyles.pdf} />
                        </div>
                        <div className="flex flex-col items-start">
                          <strong>View Centrix Report</strong>
                          <p className="text-sm">
                            Generated on{' '}
                            {moment(data.centrixReportFetchedAt).format(
                              DATE_TIME_FORMAT,
                            )}
                          </p>
                        </div>
                      </div>
                    </a>

                    <div className="mt-2">
                      <Paragraph size="sm">
                        For more information about Centrix's scoring system,
                        please see{' '}
                        <Hyperlink
                          href="https://www.centrix.co.nz/wp-content/uploads/2020/06/Your-credit-file-what-does-it-mean-Centrix-June-2020.pdf"
                          external
                          openInNewTab>
                          this document
                        </Hyperlink>
                      </Paragraph>
                    </div>

                    <DocumentPreviewModal
                      isVisible={centrixPdfOpen}
                      setIsVisible={setCentrixPdfOpen}
                      documentUrl={`${API_URL}/tenant_check_reports/${data.id}/centrix_document.pdf`}
                      title={`Centrix Report - ${data.firstName} ${data.lastName}`}
                    />
                  </div>
                )}
              </Card>

              <TenancyTribunalResultsCard report={data} />
              <GovtJusticeResultsCard report={data} />
              <PoliceResultsCard report={data} />
              <ParoleBoardResultsCard report={data} />
              <CompaniesRegistrarResultsCard report={data} />
              <InsolvencyRegistrarResultsCard report={data} />
              <ObituariesResultsCard report={data} />
              <InterpolResultsCard report={data} />

              <SocialsResultsCard report={data} />
              <SearchResultsCard report={data} />
              <NewsResultsCard report={data} />
              <ReferencesCard references={data.tenantCheckReferences} />
            </div>
          );
        }
      }}
    </Page>
  );
};

export default TenantCheckReportDetailPage;
