import {useState} from 'react';

import ModalPhotos from 'components/chifis_theme/ModalPhotos';
import NcImage from 'components/chifis_theme/NcImage';
import Listing from 'models/listings/Listing';

interface PhotosProps {
  listing: Listing;
}

const Photos = ({listing}: PhotosProps) => {
  const photos = listing.listingPhotos
    .sort((photo) => photo.orderIndex)
    .map((photo) => photo.photo);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  if (photos.length === 1) {
    return (
      <>
        <div
          className="flex justify-start rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
          onClick={() => handleOpenModal(0)}>
          <NcImage
            className="object-cover w-full h-auto rounded-md sm:rounded-xl max-h-[250px]"
            src={photos[0]}
            prevImageHorizontal
          />
          <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
        </div>
        <ModalPhotos
          imgs={photos}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={() => handleOpenModal(0)}>
            <NcImage
              containerClassName="absolute inset-0"
              className="object-cover w-full h-full rounded-md sm:rounded-xl"
              src={photos[0]}
              prevImageHorizontal
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {photos
            .filter((_, i) => i >= 1 && i < 5)
            .map((photo, index) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? 'hidden sm:tw-block' : ''
                }`}>
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={photo}
                  prevImageHorizontal
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-500 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

          <div
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-white text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
            onClick={() => handleOpenModal(0)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg>
            <span className="ml-2 text-neutral text-sm font-medium">
              Show all photos
            </span>
          </div>
        </div>
        <ModalPhotos
          imgs={photos}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </>
    );
  }
};

export default Photos;
