import {omit} from 'lodash';

import {AuthErrorCode} from 'auth/errors';

import {AuthResult} from '../types';
import {userDataIsValidShape} from '../types/UserData';

interface ParseAuthResponseFunction {
  (response: Response, currentAuthToken?: string): Promise<AuthResult | null>;
}

interface InterpretErrorsFunction {
  (responseData: {
    error?: string;
    errors?: Record<string, string[]>;
  }): AuthErrorCode[];
}

const interpretErrors: InterpretErrorsFunction = (responseData) => {
  /**
   * Initialise the array to be returned.
   */
  let authErrors: AuthErrorCode[] = [];

  /**
   * Errors may be returned by the API as either a single string ('error') or an
   * object of errors ('errors'), and so we need to check for the format.
   */
  if ('error' in responseData) {
    /**
     * Handle 'error' property (single).
     */
    const {error} = responseData;

    /**
     * Handle invalid email / password.
     */
    if (error === 'Invalid Email or password.') {
      authErrors = [AuthErrorCode.INVALID_CREDENTIALS];
    }
  } else if ('errors' in responseData) {
    /**
     * Handle'errors' property (multiple).
     */
    const {errors} = responseData;
    authErrors = Object.entries(errors).reduce((result, [field, messages]) => {
      /**
       * Handle email already taken error.
       */
      if (field === 'email' && messages.includes('has already been taken')) {
        return [...result, AuthErrorCode.EMAIL_ALREADY_TAKEN];
      }

      /**
       * Handle current password is invalid.
       */
      if (field === 'current_password' && messages.includes('is invalid')) {
        return [...result, AuthErrorCode.INVALID_CURRENT_PASSWORD];
      }

      return result;
    }, []);
  }

  return authErrors;
};

const parseAuthResponse: ParseAuthResponseFunction = async (
  response,
  currentAuthToken, // If the user is already logged in, we can pass the current auth token to the function.
) => {
  /**
   * Determine if data exists in the response.
   */
  const hasBody = !!response.body;

  /**
   * Handle request failed.
   */
  if (!response.ok) {
    if (hasBody) {
      const responseData = await response.json();

      const authErrors = interpretErrors(responseData);
      if (authErrors.length) {
        throw authErrors;
      }
    }

    /**
     * Throw a generic error if no particular reason was given for the request failing.
     */
    throw new Error('Request failed');
  }

  /**
   * Convert the response to an object.
   */
  let data;
  try {
    data = await response.json();
  } catch (error) {
    data = null;
  }

  /**
   * If the response was OK but there is no data to parse (i.e. a 204 response), we return null instead.
   */
  if (!hasBody || !data) {
    return null;
  }

  /**
   * User data can be returned in two different formats, depending on the request that was performed.
   * Attempt to deconstruct the data from the reponse in either format.
   */
  let userData;
  if ('user' in data) {
    userData = data.user;
  } else if ('data' in data && 'id' in data.data && 'attributes' in data.data) {
    userData = {
      id: data.data.id,
      unconfirmedEmail: data.data.attributes.unconfirmedEmail ?? null, // Safety net in case unconfirmed_email is not returned
      ...data.data.attributes,
    };
  }

  /**
   * Handle missing response data.
   */
  if (!userData) {
    throw new Error('Response user data is missing');
  }

  /**
   * Handle response data being an incorrect type.
   */
  if (typeof userData !== 'object') {
    throw new Error('Response user data is not an object');
  }

  /**
   * Convert the 'isConfirmed' property to 'confirmed' for consistency with User model.
   */
  if ('isConfirmed' in userData) {
    userData = {
      ...omit(userData, 'isConfirmed'),
      confirmed: userData.isConfirmed,
    };
  }

  /**
   * Handle the user data not conforming to the type guard.
   */
  if (!userDataIsValidShape(userData)) {
    throw new Error('User data does not conform to required shape');
  }

  if (currentAuthToken) {
    /**
     * Validation and parsing was successful - return the parsed data.
     */
    return {userData, authToken: currentAuthToken};
  }

  /**
   * No current auth token provided - attempt to obtain from the response meta data.
   */
  const {meta} = data;

  /**
   * Handle missing response meta.
   */
  if (!meta) {
    throw new Error('Response meta is missing');
  }

  /**
   * Handle response meta being an incorrect type.
   */
  if (typeof meta !== 'object') {
    throw new Error('Response meta is missing or invalid');
  }

  /**
   * Handle response meta not including the 'authenticationToken' property.
   */
  if (!('authenticationToken' in meta)) {
    throw new Error(
      "Response meta does not include 'authenticationToken' property",
    );
  }

  /**
   * Deconstruct the authentication token from the response meta.
   */
  const {authenticationToken: authToken} = meta;

  /**
   * Handle the authentication token being an incorrect type.
   */
  if (typeof authToken !== 'string') {
    throw new Error('Authentication token is not a string');
  }

  /**
   * Validation and parsing was successful - return the parsed data.
   */
  return {userData, authToken};
};

export default parseAuthResponse;
