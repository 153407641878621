import {useCallback, useEffect, useMemo, useState} from 'react';

import {type FormikHelpers, useFormik, FormikConfig} from 'formik';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import StepContainer from 'components/walkthrough/common/StepContainer';
import {DatePicker} from 'components_sb/input';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  startDate: Date | null;
};

const AvailabilityStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartRentalAvailabilityStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the listing.
       */
      listing.assignAttributes({
        startDate: formValues.startDate.toString(),
      });

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'listing_availability';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompleteRentalAvailabilityStep,
        {
          propertyId: property.id,
          listingId: listing.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [listing, property, queryClient],
  );

  /**
   * Define initial form field values.
   */
  const initialValues = useMemo(
    () => ({
      /**
       * If a start date has already been selected, use this as
       * the initial value, otherwise leave it blank.
       */
      startDate: listing.startDate ? new Date(listing.startDate) : null,
    }),
    [listing],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormValues>>(
    () => ({
      initialValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        startDate: Yup.date()
          .typeError(
            'Please enter the date that the property is available from',
          )
          .required(
            'Please enter the date that the property is available from',
          ),
      }),
    }),
    [initialValues, handleSubmit],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      align="center"
      title="When is your property available?"
      subtitle="Set the date that your property will be available to rent.">
      <div className="flex flex-col w-full">
        <DatePicker
          mode="single"
          name="startDate"
          form={form}
          disabledDates={[
            {
              from: new Date(1900, 1, 1),
              to: moment().subtract(1, 'day').toDate(),
            },
          ]}
        />
      </div>
    </StepContainer>
  );
};

export default AvailabilityStep;
