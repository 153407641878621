import React from 'react';

import moment from 'moment';
import {AiOutlineUsergroupAdd} from 'react-icons/ai';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const TenancyTribunalResultsCard = ({report}: {report: TenantCheckReport}) => {
  // Uncomment to have some example data to test against
  // report.tenancyServicesResults = {
  //   search_url: '',
  //   results: [
  //     {
  //       case_id: '4540289',
  //       pdf_url:
  //         'https://forms.justice.govt.nz/search/Documents/TTV2/PDF/9266359-Tribunal_Order.pdf',
  //       case_name:
  //         'Lodge City Rentals Limited as agent for C Devadittiy vs Elliott, Rosie',
  //       date: '09/08/2023',
  //       case_url: '',
  //     },
  //   ],
  // };
  if (report.tenancyServicesResultsFetchedAt) {
    return (
      <Card title="Tenancy Tribunal" icon={AiOutlineUsergroupAdd}>
        {report.tenancyServicesResults.results.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Case Id</TableHeadItem>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Case Name</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.tenancyServicesResults.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Hyperlink href={result.pdf_url} external openInNewTab>
                    {result.case_id}
                  </Hyperlink>
                </TableRowItem>
                <TableRowItem>
                  {moment(result.date).format(DATE_FORMAT)}
                </TableRowItem>
                <TableRowItem>{result.case_name}</TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the tenancy
            tribunal database.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.tenancyServicesResultsFetchedAt).format(
            DATE_TIME_FORMAT,
          )}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Tenancy Tribunal" icon={AiOutlineUsergroupAdd}>
        <Paragraph>
          Tenancy tribunal results are currently being collected and will be
          updated on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default TenancyTribunalResultsCard;
