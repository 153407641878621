import clsx from 'clsx';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {NumberBadge} from 'components_sb/feedback';
import {Hyperlink} from 'components_sb/navigation';
import {useNavItems} from 'utilities/nav-links';

import AccountDropdown from './AccountDropdown';
import SubItemLink from './SubItemLink';

const WebHeaderDesktopContent = () => {
  const {isLoggedIn} = useAuth();
  const navItems = useNavItems();

  return (
    <div
      className={clsx(
        'flex-1',
        'flex flex-row items-center',
        'gap-x-10',
        'pr-6 lg:pr-8',
        'my-6',
      )}>
      {navItems.map((navItem) => {
        return navItem.subItems ? (
          <SubItemLink key={navItem.id} subItems={navItem.subItems}>
            {navItem.label}
          </SubItemLink>
        ) : (
          <Hyperlink
            key={navItem.id}
            href={navItem.linkTo}
            openInNewTab={navItem.linkTo.startsWith('http')}>
            <span>{navItem.label}</span>
            <NumberBadge count={navItem.badgeCount} />
          </Hyperlink>
        );
      })}

      {isLoggedIn ? (
        <div className="ml-auto pl-3">
          <AccountDropdown />
        </div>
      ) : (
        <div className="flex flex-row items-center gap-x-4 ml-auto">
          <Button
            label="Log in"
            category="secondary"
            size="base"
            mode="link"
            linkTo="/login"
          />
          <Button
            label="Register"
            category="primary"
            size="base"
            mode="link"
            linkTo="/"
          />
        </div>
      )}
    </div>
  );
};

export default WebHeaderDesktopContent;
