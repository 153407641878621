import {useCallback, useRef} from 'react';

import clsx from 'clsx';
import {Form, Formik, type FormikHelpers} from 'formik';
import {toJpeg} from 'html-to-image';
import _ from 'lodash';
import moment from 'moment';
import {BsHouseGear} from 'react-icons/bs';
import {FaFileSignature} from 'react-icons/fa';
import {
  HiOutlineChatAlt,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from 'react-icons/hi';
import {HiOutlineClipboardDocumentCheck} from 'react-icons/hi2';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {
  DateField,
  DOBField,
  InputField,
  PseudoBooleanField,
  SelectField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import RenterPetField from 'components/forms_fields/RenterPetsField';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import IdentifyVerificationCard from 'components/tenant_checks/IdentifyVerificationCard';
import {TextAreaField, TextField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph, FieldLabel} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import RentalApplication from 'models/listings/RentalApplication';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {useAutoAnimate} from 'utilities/hooks';

const EditRentalApplicationPage = () => {
  const {
    params: {rentalApplicationId},
  } = useRoute();

  const [applicantAnimateRef] = useAutoAnimate();
  const [petTypeAnimateRef] = useAutoAnimate();

  const proofOfConsentRef = useRef<HTMLDivElement>(null);

  const {authCookies} = useAuth();
  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `rental-application-${rentalApplicationId}`,
    async () => {
      const app = await RentalApplication.includes([
        'rental_application_applicants',
        'user',
      ])
        .order({'rental_applications.id': 'asc'})
        .find(rentalApplicationId);

      return app.data;
    },
  );

  const router = useRouter();

  // Doing this manually because spraypaint is refusing to work.
  const updateApplicant = async (applicant: any) => {
    const attrs = {...applicant};
    for (const key in attrs) {
      const val = attrs[key];
      if (val === '') {
        attrs[key] = null;
      }
    }

    const formattedAttrs = {
      weekly_income: attrs.weeklyIncome,
      name: attrs.name,
      email: attrs.email,
      date_of_birth: attrs.dateOfBirth,
      id_type: attrs.idType,
      id_number: attrs.idNumber,
      id_extra_info: attrs.idExtraInfo,
    };

    if (attrs.id && attrs.id !== '') {
      return await fetch(
        `${API_URL}/rental_application_applicants/${attrs.id}.jsonapi`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
          body: JSON.stringify({
            data: {
              id: attrs.id,
              type: 'rental_application_applicants',
              attributes: formattedAttrs,
            },
          }),
        },
      );
    } else {
      return await fetch(`${API_URL}/rental_application_applicants.jsonapi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
        body: JSON.stringify({
          data: {
            type: 'rental_application_applicants',
            attributes: formattedAttrs,
          },
        }),
      });
    }
  };

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const attrs = {...formValues};
    for (const key in attrs) {
      const val = attrs[key];
      if (val === '') {
        attrs[key] = null;
      }
    }
    await Promise.all(
      attrs.rentalApplicationApplicants.map(async (applicant: any) => {
        return await updateApplicant(applicant);
      }),
    );

    delete attrs.rentalApplicationApplicants;

    const app = data;
    app.assignAttributes(attrs);

    app.proofOfConsent = await toJpeg(proofOfConsentRef.current, {
      cacheBust: false,
      backgroundColor: 'white',
      filter: (node) => {
        return node.tagName !== 'BUTTON';
      },
    });

    const result = await app.save();

    if (result) {
      toast.success('Your application has been successfully updated!');
      queryClient.invalidateQueries(
        `rental-application-${rentalApplicationId}`,
      );
      router.navigate(`/rental-applications/${app.id}`);
    } else {
      for (const key of Object.keys(app.errors)) {
        const message = app.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
      console.log(app.errors);
    }

    actions.setSubmitting(false);
  };

  const handleApplicantSelectChange = (formik: any, e: any) => {
    e.preventDefault();
    const val = Number(e.target.value);

    const applicants = formik.values.rentalApplicationApplicants;

    if (val < applicants.length) {
      const diff = applicants.length - val;
      _.times(diff, () => {
        applicants.pop();
      });
    } else if (val > applicants.length) {
      const diff = val - applicants.length;
      _.times(diff, () => {
        applicants.push(new RentalApplicationApplicant());
      });
    }

    formik.setFieldValue('rentalApplicationApplicants', applicants);
  };

  const attrs = !data ? null : data.attributes;
  if (attrs) {
    attrs.rentalApplicationApplicants = data.rentalApplicationApplicants.map(
      (a) => a.asNavigatable,
    );
  }

  const handleVerificationComplete = useCallback(
    async (inquiryId: string) => {
      const u = data.user;
      data.user.idVerificationProvider = 'persona';
      data.user.idVerificationProviderId = inquiryId;

      const result = await u.save();
      if (result) {
        queryClient.setQueryData(
          `rental-application-${rentalApplicationId}`,
          data,
        );
      }
      if (result && u.idVerificationStatus === 'approved') {
        toast.success('Your identity has been successfully verified!');
      }
    },
    [data, queryClient, rentalApplicationId],
  );

  return (
    <Page title="Edit Rental Application" loading={isLoading} error={error}>
      {() => (
        <Formik
          initialValues={attrs}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            headTenantName: Yup.string().required().min(2).label('Full Name'),
            headTenantEmail: Yup.string().email().required().label('Email'),
            headTenantPhoneNumber: Yup.string()
              .required()
              .label('Phone Number'),
            headTenantDob: Yup.string()
              .optional()
              .nullable()
              .label('Date Of Birth')
              .test('is-valid-date', 'Please enter a valid date', (value) => {
                if (!value) return true;

                return (
                  moment(value, 'DD/MM/YYYY').isValid() ||
                  moment(value, 'YYYY-MM-DDD').isValid()
                );
              }),
            additionalTotalOccupants: Yup.number()
              .nullable()
              .min(0)
              .max(20)
              .label('Additional Number of total occupants')
              .required(),
            rentalApplicationApplicants: Yup.array()
              .of(
                Yup.object().shape({
                  name: Yup.string().required().label('Name').min(2),
                  email: Yup.string().required().label('Email'),
                  dateOfBirth: Yup.string()
                    .optional()
                    .nullable()
                    .label('Date Of Birth')
                    .test(
                      'is-valid-date',
                      'Please enter a valid date',
                      (value) => {
                        if (!value) return true;

                        return (
                          moment(value, 'DD/MM/YYYY').isValid() ||
                          moment(value, 'YYYY-MM-DDD').isValid()
                        );
                      },
                    ),
                  weeklyIncome: Yup.number()
                    .optional()
                    .nullable()
                    .label('Weekly Income')
                    .min(0),
                }),
              )
              .min(0)
              .max(5)
              .label('Other Tenants'),
            headTenantSignature: Yup.string()
              .required()
              .min(2)
              .nullable()
              .label('Signature'),
            preferredTenancyLength: Yup.number()
              .optional()
              .nullable()
              .label('Preferred Tenancy Length'),
          })}>
          {(formik) => (
            <Form>
              <Card title="Your Basic Information" icon={HiOutlineUserCircle}>
                <Paragraph>
                  Apply for rentals easily with Keyhook. Complete your
                  comprehensive application below and use it to apply for any
                  Keyhook listing.
                </Paragraph>

                <Paragraph>
                  Your information will auto save for this device.
                </Paragraph>
                <Paragraph>Fields with a * are required.</Paragraph>
                <Paragraph>
                  For free assistance with your application, book a call with
                  our support team{' '}
                  <Hyperlink
                    href="https://www.keyhook.com/book-a-call"
                    openInNewTab>
                    here.
                  </Hyperlink>
                </Paragraph>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="headTenantName"
                      labelProps={{
                        title: 'Full name',
                        required: true,
                      }}
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <DOBField
                      formik={formik}
                      name="headTenantDob"
                      label="Date Of Birth"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="headTenantEmail"
                      labelProps={{
                        title: 'Email address',
                        required: true,
                      }}
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="headTenantPhoneNumber"
                      labelProps={{
                        title: 'Phone number',
                        required: true,
                      }}
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <SelectField
                      formik={formik}
                      name="idType"
                      labelProps={{
                        title: 'Identification type',
                      }}>
                      <option value="">Choose an identification type</option>
                      {formik.values.idType === 'Passport' && (
                        <option value="Passport">Passport</option>
                      )}
                      <option value="Drivers License">Drivers License</option>
                    </SelectField>
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="idNumber"
                      labelProps={{
                        title: 'Identification number',
                      }}
                      placeholder="E.g. LMZ123"
                    />
                  </FormRowItem>
                  {formik.values.idType === 'Drivers License' && (
                    <FormRowItem>
                      <InputField
                        formik={formik}
                        name="idExtraInfo"
                        labelProps={{
                          title: 'Version number',
                        }}
                        placeholder="E.g. 653"
                      />
                    </FormRowItem>
                  )}
                </FormRow>

                <h2 className="card-title mt-2 mb-4 text-brand-850">
                  Employment Information
                </h2>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="occupation"
                      labelProps={{
                        title: 'Occupation',
                      }}
                      placeholder="E.g. Policy Analyst"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="weeklyIncome"
                      labelProps={{
                        title: 'Weekly income before tax ($)',
                      }}
                      type="number"
                      min={0}
                      max={50000}
                      step={1}
                      placeholder="E.g. 1835.00"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employer"
                      labelProps={{
                        title: 'Employer',
                      }}
                      placeholder="E.g. Ministry of Health"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="yearsEmployedAtCurrentPlace"
                      labelProps={{
                        title: 'Years employed there',
                      }}
                      placeholder="E.g. 1.5  or 0.5"
                      type="number"
                      min={0}
                      max={100}
                      step={0.5}
                    />
                  </FormRowItem>
                </FormRow>

                <div className="w-full lg:w-1/2">
                  <PseudoBooleanField
                    formik={formik}
                    name="headTenantEmployedFullTime"
                    label="Are you currently employed full time?"
                  />
                </div>

                <h2 className="card-title mt-2 mb-4 text-brand-850">
                  Current Living Situation
                </h2>
                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddress"
                      labelProps={{
                        title: 'What is your current address?',
                        description:
                          "Please use the format: 'Street, Suburb, City, Postcode, Country'",
                      }}
                      placeholder="E.g. 123 Example Street, Tawa, Wellington, 6035, New Zealand"
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddressStayLength"
                      labelProps={{
                        title: 'How long have you stayed there?',
                      }}
                      placeholder="E.g. 7 months"
                    />
                  </FormRowItem>
                </FormRow>

                <TextAreaField
                  mode="formik"
                  form={formik as any}
                  name="currentAddressReasonLeaving"
                  label="What is your reason for leaving?"
                  placeholder="E.g. Moving out of home."
                />
              </Card>

              {data.user && (
                <IdentifyVerificationCard
                  currentStatus={data.user.idVerificationStatus}
                  referenceId={`User-${data.user.id}}`}
                  onComplete={handleVerificationComplete}
                />
              )}

              <Card title="About Us" icon={HiOutlineChatAlt}>
                <Paragraph>
                  Include a short bio about yourself and any other
                  occupants/tenants.
                </Paragraph>

                <TextAreaField
                  mode="formik"
                  form={formik as any}
                  name="aboutUs"
                  placeholder="Share a bit about yourself..."
                  rows={5}
                />
              </Card>

              <Card title="Tenancy Information" icon={BsHouseGear}>
                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="preferredTenancyLength"
                      labelProps={{
                        title: 'Preferred tenancy length (months)',
                      }}
                      placeholder="E.g. 12"
                      type="number"
                      min={1}
                      max={1000}
                      step={1}
                    />
                  </FormRowItem>

                  <FormRowItem>
                    <DateField
                      formik={formik}
                      name="preferredTenancyStartDate"
                      label="Preferred Tenancy Start Date"
                      minDate={new Date()}
                      maxDate={new Date('2100-01-01')}
                    />
                  </FormRowItem>
                </FormRow>

                <TextAreaField
                  mode="formik"
                  form={formik as any}
                  name="cantCompleteTenancyReasons"
                  label="Are there any reasons you might not be able to complete the length of the tenancy?"
                  placeholder="E.g. Visa or Work permit expiring, possible work relocation etc."
                />

                <InputField
                  formik={formik}
                  name="vehiclesCount"
                  labelProps={{
                    title: 'How many vehicles will be parked at the property?',
                  }}
                  type="number"
                  min={0}
                  max={20}
                  step={1}
                  placeholder="E.g. 2"
                />

                <div className="w-full lg:w-1/2">
                  <PseudoBooleanField
                    formik={formik}
                    name="hasPets"
                    label="Do you or any tenant have pets?"
                  />

                  <div ref={petTypeAnimateRef as any}>
                    {Object.entries(formik.values.petTypes).map(
                      ([key, val], index) => (
                        <span className="tw-block" key={index}>
                          {`${key} x ${val}`}
                        </span>
                      ),
                    )}
                  </div>

                  {formik.values.hasPets === 'Yes' ? (
                    <div>
                      <RenterPetField formik={formik} formikKey="petTypes" />
                    </div>
                  ) : null}
                </div>

                <div className="w-full lg:w-1/2 mt-2">
                  <PseudoBooleanField
                    formik={formik}
                    name="anySmokers"
                    label="Are any of the proposed tenants smokers?"
                  />
                </div>

                <div className="w-full lg:w-1/2 mt-2">
                  <PseudoBooleanField
                    formik={formik}
                    name="canPayBondAndAdvance"
                    label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                  />
                </div>
              </Card>

              <Card title="References" icon={HiOutlineClipboardDocumentCheck}>
                <strong>Employer Reference</strong>

                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceName"
                      label="Name"
                      placeholder="eg: Jane Doe"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceCompanyName"
                      label="Company Name"
                      placeholder="eg: ABC Company"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceRelationship"
                      label="Relationship"
                      placeholder="eg: Manager"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceContactNumber"
                      label="Contact Number"
                      placeholder="eg: 0211234567"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceEmail"
                      label="Email address"
                      placeholder="eg: jane.smith@company.com"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>

                <strong>Landlord Reference</strong>

                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceName"
                      label="Name"
                      placeholder="eg: Jane Doe"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceRelationship"
                      label="Relationship"
                      placeholder="eg: Property Manager"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceContactNumber"
                      label="Contact Number"
                      placeholder="eg: 0211234567"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceEmail"
                      label="Email address"
                      placeholder="eg: jane.doe@gmailcom"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceTenancyAddress"
                      label="Tenancy Address"
                      placeholder="eg: 123 Example Street, Auckland"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceTenancyDate"
                      label="Tenancy Date"
                      placeholder="eg: 01/01/2020"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>

                <strong>Other Reference</strong>
                <small className="tw-block text-secondary">
                  This should preferably be another landlord.
                </small>

                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceName"
                      label="Name"
                      placeholder="eg: Jane Doe"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceRelationship"
                      label="Relationship"
                      placeholder="eg: Landlord"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceContactNumber"
                      label="Contact Number"
                      placeholder="eg: 0211234567"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceEmail"
                      label="Email address"
                      placeholder="eg: jane.doe@gmailcom"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
              </Card>

              <Card title="Other Tenants" icon={HiOutlineUserGroup}>
                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="additionalTotalOccupants"
                      labelProps={{
                        title: 'Additional number of occupants',
                      }}
                      type="number"
                      placeholder="Excluding yourself, E.g. 4"
                      min={0}
                      max={20}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <FieldLabel
                      title="Additional tenants who will sign the lease"
                      required
                    />
                    <select
                      className={clsx(
                        'w-full max-w-full box-border',
                        'transition-all duration-300',
                        'bg-brand-50 hover:bg-brand-75 ',
                        'placeholder-brand-800 placeholder-opacity-30',
                        'outline-none border-none ring-1 ring-brand-75',
                        'focus:ring-2 focus:ring-brand-500',
                        'text-base px-4 h-12 rounded-lg',
                      )}
                      value={formik.values.rentalApplicationApplicants.length}
                      onChange={(e) => handleApplicantSelectChange(formik, e)}>
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </FormRowItem>
                </FormRow>

                <div ref={applicantAnimateRef as any}>
                  {formik.values.rentalApplicationApplicants.map(
                    (applicant: any, index: number) => (
                      <div className="mt-4 bg-gray-100 p-4" key={index}>
                        <strong>Additional Tenant {index + 1}</strong>
                        <FormRow responsive>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].name`}
                              labelProps={{
                                title: 'Full name',
                                required: true,
                              }}
                              placeholder="E.g. Jane Smith"
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].email`}
                              labelProps={{
                                title: 'Email address',
                                required: true,
                              }}
                              placeholder="E.g. jane.smith@gmail.com"
                              type="email"
                            />
                          </FormRowItem>
                        </FormRow>
                        <FormRow responsive>
                          <FormRowItem>
                            <DOBField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].dateOfBirth`}
                              label="Date Of Birth"
                            />
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].weeklyIncome`}
                              labelProps={{
                                title: 'Weekly income before tax ($)',
                              }}
                              placeholder="E.g. 1835.0"
                              type="number"
                              min={0}
                              max={50000}
                              step={1}
                            />
                          </FormRowItem>
                        </FormRow>
                        <FormRow responsive>
                          <FormRowItem>
                            <SelectField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].idType`}
                              value={applicant.idType}
                              labelProps={{
                                title: 'Identification type',
                              }}>
                              <option value="">
                                Choose an identification type
                              </option>
                              {formik.values.rentalApplicationApplicants[index]
                                .idType === 'Passport' && (
                                <option value="Passport">Passport</option>
                              )}
                              <option value="Drivers License">
                                Drivers License
                              </option>
                            </SelectField>
                          </FormRowItem>
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].idNumber`}
                              labelProps={{
                                title: 'Identification number',
                              }}
                              placeholder="E.g. LMZ123"
                            />
                          </FormRowItem>
                          {applicant.idType === 'Drivers License' && (
                            <FormRowItem>
                              <InputField
                                formik={formik}
                                name={`rentalApplicationApplicants[${index}].idExtraInfo`}
                                labelProps={{
                                  title: 'Version number',
                                }}
                                placeholder="E.g. 653"
                              />
                            </FormRowItem>
                          )}
                        </FormRow>
                      </div>
                    ),
                  )}
                </div>

                <div className="mt-2 w-full lg:w-1/2">
                  <PseudoBooleanField
                    formik={formik}
                    name="applicantsCanProvideReferences"
                    label="Can all of the tenants above provide up to 3 references?"
                  />
                </div>
              </Card>

              <Card title="About the tenants">
                <Paragraph>
                  This information is voluntary and is not required to complete
                  your application. The questions below refer to all proposed
                  tenants included on this application, including yourself. If
                  you're unsure of an answer, leave unspecified.
                </Paragraph>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="anySmokers"
                    label="Are any of the proposed tenants smokers?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="involvedWithTenancyTribunal"
                    label="Have any of the proposed tenants been involved with the Tenancy Tribunal?"
                  />
                  {formik.values.involvedWithTenancyTribunal === 'YES' ? (
                    <TextareaField
                      formik={formik}
                      name="tenancyTribunalInformation"
                      labelProps={{
                        title: 'Please provide further information',
                        size: 'base',
                      }}
                    />
                  ) : null}
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="pastBondDeductions"
                    label="Has any proposed tenant ever had money deducted from their bond?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="oweMoneyToLandlord"
                    label="Does any proposed tenant currently owe money to a landlord or property manager?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="hasMissedRentPayments"
                    label="Has any proposed tenant ever missed a rent payment?"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="canPayBondAndAdvance"
                    label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                  />
                </div>

                {/* <div>
                <PseudoBooleanField
                  formik={formik}
                  name="wouldLikeHelpWithUtilities"
                  label="Would you like help setting up utilities? I.e., internet, power, gas, etc"
                />
              </div> */}

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="anyBadCredit"
                    label="Does any proposed tenant have a low credit score? (Below 500)"
                  />
                </div>

                <div>
                  <PseudoBooleanField
                    formik={formik}
                    name="anyCriminalRecords"
                    label="Do any of the proposed tenants have a criminal record?"
                  />
                </div>
              </Card>

              <div ref={proofOfConsentRef}>
                <Card
                  title="Confirmation of Application"
                  icon={FaFileSignature}>
                  <Paragraph size="sm" secondary>
                    By confirming your application and signing below, you
                    confirm that all the above information is true and accurate
                    and may be used for a credit and reference check.
                  </Paragraph>
                  <Paragraph size="sm" secondary>
                    I authorise the Landlord/Property Manager to:
                  </Paragraph>
                  <ul className="ml-4">
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; collect, retain and use this information for the
                        purpose of assessing my creditworthiness and suitability
                        for the tenancy; and
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; disclose information about me, whether collected
                        from me directly or from any other source, to any other
                        credit provider or any credit reporting agency for the
                        purposes of providing or obtaining a credit report
                        (which will involve the credit reporting agency
                        providing information about me to the Landlord/Property
                        Manager).{' '}
                      </Paragraph>
                    </li>
                  </ul>

                  <Paragraph size="sm" secondary>
                    I understand that the credit reporting agency:
                  </Paragraph>
                  <ul className=" ml-4 mb-4">
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; may hold my information on their credit reporting
                        database and use it for providing credit reporting
                        services, and they may disclose my information to their
                        subscribers for the purpose of credit checking or debt
                        collection; and
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; as part of providing a credit report, may check the
                        Ministry of Justice fines database for any overdue fines
                        Under the Privacy Act 2020, you have the right to ask
                        for a copy of all information held about you, and have
                        the right to request the correction of any incorrect
                        information.
                      </Paragraph>
                    </li>
                  </ul>

                  <SignatureModalInput
                    mode="formik"
                    name="headTenantSignature"
                    labelProps={{
                      title: 'Signature',
                    }}
                  />

                  <div className="mt-5">
                    <SubmitButton
                      formik={formik}
                      text="Update Application"
                      submittingText="Saving"
                    />
                  </div>
                </Card>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default EditRentalApplicationPage;
