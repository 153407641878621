import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {Device} from '@capacitor/device';
import {PushNotifications} from '@capacitor/push-notifications';

import useAuth from 'auth/provider/useAuth';
import {Modal} from 'components_sb/layout';
import PushDevice from 'models/users/PushDevice';
import useRouter from 'router/hooks/useRouter';

import PushPermissionsModal from './_PushPermissionsModal';

const {useModal} = Modal.Imperative;

interface PushPermissionsContextValue {
  hasChecked: boolean;
  hasGranted: boolean;
  hasDenied: boolean;
  hasResponded: boolean;
  requiresPrompt: boolean;
  request: () => Promise<void>;
}

const Context = createContext<PushPermissionsContextValue>(
  {} as PushPermissionsContextValue,
);

interface PushPermissionsProvider {
  (props: {children: ReactNode}): JSX.Element;
}

const PushPermissionsProvider: PushPermissionsProvider = ({children}) => {
  /**
   * Set push permissions as denied by default for web.
   */
  const [pushPermissions, setPushPermissions] = useState<string>(
    Capacitor.isNativePlatform() ? null : 'denied',
  );

  const {currentUser, isLoggedIn} = useAuth();

  const openModal = useModal();

  const router = useRouter();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('registration', async (token) => {
        const info = await Device.getInfo();

        const device = new PushDevice({
          token: token.value,
          os: info.operatingSystem,
          name: info.name,
          manufacturer: info.manufacturer,
          deviceModel: info.model,
          userId: currentUser.id,
        });

        const result = await device.save();
        if (result) {
          localStorage.setItem('push_device_id', device.id);
        }
      });

      PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          console.log('Push notification received: ', notification);
        },
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          const data = notification.notification.data;
          if (data && data.path && isLoggedIn) {
            router.navigate(data.path);
          }
        },
      );

      return () => {
        PushNotifications.removeAllListeners();
      };
    }
  }, [router, isLoggedIn, currentUser]);

  useEffect(() => {
    const check = async () => {
      if (Capacitor.isNativePlatform()) {
        const status = await PushNotifications.checkPermissions();
        console.log('PUSH PERMISSIONS: ' + status.receive);
        setPushPermissions(status.receive);
      }
    };
    check();
  }, []);

  const hasChecked = useMemo(() => !!pushPermissions, [pushPermissions]);

  const hasGranted = useMemo(
    () => pushPermissions === 'granted',
    [pushPermissions],
  );

  const hasDenied = useMemo(
    () => pushPermissions === 'denied',
    [pushPermissions],
  );

  const requiresPrompt = useMemo(
    () => isLoggedIn && !!pushPermissions?.includes('prompt'),
    [isLoggedIn, pushPermissions],
  );

  const hasResponded = useMemo(
    () => hasGranted || hasDenied,
    [hasGranted, hasDenied],
  );

  useEffect(() => {
    if (Capacitor.isNativePlatform() && hasGranted && isLoggedIn) {
      PushNotifications.register();
    }
  }, [hasGranted, isLoggedIn]);

  const request = useCallback(async () => {
    if (!isLoggedIn) {
      console.warn('User must be logged in to accept permissions');
    } else if (!hasChecked) {
      console.warn('Permissions have not yet been initially checked');
    } else if (hasGranted) {
      console.warn('User has already granted permissions');
    } else if (hasDenied) {
      console.warn('User has denied permissions');
    } else if (requiresPrompt) {
      if (Capacitor.isNativePlatform()) {
        setPushPermissions((await openModal(PushPermissionsModal)).receive);
      }
    }
  }, [
    hasChecked,
    hasGranted,
    hasDenied,
    openModal,
    isLoggedIn,
    requiresPrompt,
  ]);

  const [requesting, setRequesting] = useState(false);
  useEffect(() => {
    (async () => {
      if (requiresPrompt && !requesting) {
        setRequesting(true);
        await request();
        setRequesting(false);
      }
    })();
  }, [requiresPrompt, request, requesting]);

  const value = useMemo(
    () => ({
      hasChecked,
      hasGranted,
      hasDenied,
      hasResponded,
      requiresPrompt,
      request,
    }),
    [hasChecked, hasGranted, hasDenied, hasResponded, requiresPrompt, request],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const usePushPermissions = () => useContext(Context);

export default PushPermissionsProvider;
