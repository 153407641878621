import {useCallback} from 'react';

import WelcomeScreen from 'components/auth/WelcomeScreen';
import {Hyperlink} from 'components_sb/navigation';
import {AccountRole} from 'models/users/User';
import Page from 'router/components/Page';
import useRouter from 'router/hooks/useRouter';

const WelcomePage = () => {
  const router = useRouter();

  /**
   * Navigtate to the register page corresponding to the selected account type.
   */
  const onSelectAccountType = useCallback(
    (accountType: AccountRole) => {
      if (accountType === AccountRole.Landlord) {
        router.navigate('/register/landlord');
      } else if (accountType === AccountRole.Renter) {
        router.navigate('/register/tenant');
      }
    },
    [router],
  );

  return (
    <Page title="Welcome" disableNavbar centred>
      {() => (
        <WelcomeScreen
          onSelectAccountType={onSelectAccountType}
          additionalContent={
            <Hyperlink persistQueryParams color="brand" href="/login">
              Already using Keyhook? Click here to log in
            </Hyperlink>
          }
        />
      )}
    </Page>
  );
};

export default WelcomePage;
