import '@dotlottie/player-component';
import {useCallback, useEffect} from 'react';

import {CapacitorUpdater, latestVersion} from '@capgo/capacitor-updater';

import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';

const AppUpdatingModal: ModalDefinition = {
  title: 'Updating App',
  buttonsConfig: {},
  hideFooter: true,
  ContentComponent: (props) => {
    const {latestVersion} = props as {latestVersion: latestVersion};
    const {closeModal} = props;

    const updateApp = useCallback(async () => {
      const data = await CapacitorUpdater.download({
        url: latestVersion.url,
        version: latestVersion.version,
      });

      try {
        await CapacitorUpdater.set({id: data.id});
      } catch (err) {
        console.log(err);
      } finally {
        closeModal();
      }
      // Ignore deps, only want to try to update once.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      updateApp();
    }, [updateApp]);

    return (
      <div className="mt-2">
        <div className="flex justify-center w-full mt-2">
          <dotlottie-player
            autoplay
            loop
            mode="normal"
            src="/assets/lottie/app-update.lottie"
            style={{height: 250, width: 250}}
          />
        </div>
        <Paragraph>
          Please wait while the app is updated to the latest version (
          {latestVersion.version}).
        </Paragraph>
      </div>
    );
  },
};

export default AppUpdatingModal;
