import {useCallback, useEffect, useMemo, useRef} from 'react';

import {ClockIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {isMobile} from 'react-device-detect';
import {useQueryClient} from 'react-query';
import {useIntersectionObserver} from 'usehooks-ts';

import ListingStats from 'components/listing/ListingStats';
import TenancyStats from 'components/tenancy/TenancyStats';
import {PropertyCard} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {TagColor} from 'components_sb/typography/Tags/Tags';
import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import PropertyDetailQuery from 'queries/landlord/PropertyDetailQuery';
import {toCurrency} from 'utilities/StringHelpers';

const LandlordPropertyIndexCard = ({
  index,
  property,
  tenancy,
}: {
  index: number;
  property: Property;
  tenancy?: Tenancy;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    freezeOnceVisible: true,
  });

  const queryClient = useQueryClient();

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      queryClient.prefetchQuery(
        PropertyDetailQuery.queryKey(property.id),
        PropertyDetailQuery.queryFn(property.id),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry?.isIntersecting, property.id, queryClient]);

  const textForStatus = useCallback(() => {
    if (!tenancy) {
      return `This property is not currently tenanted, ${
        isMobile ? 'tap' : 'click'
      } this card to view this property and to see your options for starting a tenancy or listing.`;
    }

    if (tenancy.isDraft && !property.noTenancyActionType) {
      return `${
        isMobile ? 'Tap' : 'Click'
      } this card to view this property and to see your options for starting a tenancy or listing.`;
    }

    if (
      tenancy.status === TenancyStatus.Draft &&
      property.noTenancyActionType === 'advertise'
    ) {
      return 'Continue the listing setup process to advertise this property.';
    }

    if (tenancy.status === TenancyStatus.Pending) {
      return 'This property has a pending tenancy that the tenants have yet to accept.';
    }

    if (tenancy.status === TenancyStatus.AwaitingStartDate) {
      return 'This tenancy has been confirmed and is waiting for the start date.';
    }

    if (tenancy.isDraft) {
      return 'Continue the property set up process to get your tenancy started on Keyhook.';
    }
  }, [tenancy, property]);

  const stats = useMemo(() => {
    if (property && property.listings && property.listings.length > 0) {
      const listing = property.listings.find((l) => l.isCurrent);

      if (listing) {
        return (
          <ListingStats small noMargin interactive={false} listing={listing} />
        );
      }
    }

    if (
      property &&
      property.tenanciesCount > 0 &&
      tenancy &&
      ![
        TenancyStatus.Draft,
        TenancyStatus.Pending,
        TenancyStatus.AwaitingStartDate,
      ].includes(tenancy.status)
    ) {
      return (
        <TenancyStats
          small
          noMargin
          interactive={false}
          property={property}
          tenancy={tenancy}
        />
      );
    } else {
      return <Paragraph noMargin>{textForStatus()}</Paragraph>;
    }
  }, [property, tenancy, textForStatus]);

  const renderStartDateBanner = () => {
    if (tenancy && tenancy.status === TenancyStatus.AwaitingStartDate) {
      let timeToGo = moment(tenancy.startDate).diff(moment(), 'days');
      let unit = 'Days';

      if (timeToGo <= 3) {
        timeToGo = moment(tenancy.startDate).diff(moment(), 'hours');
        unit = 'Hours';
      }

      return (
        <div className="alert alert-info shadow-lg my-4">
          <div>
            <ClockIcon className="w-5 h-5" />
            <span>
              <strong className="underline">
                {timeToGo} {unit}
              </strong>{' '}
              until this tenancy is active on Keyhook.
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <PropertyCard
      index={index}
      property={property}
      href={`/properties/${property.id}`}
      tags={[
        ...(tenancy && tenancy.isCurrent && tenancy.totalRent
          ? [`${toCurrency(tenancy.totalRent)} / ${tenancy.rentalPeriod}`]
          : []),
        ...(tenancy && tenancy.managedRentEnabled && tenancy.inArrears
          ? [{text: 'In Arrears', color: TagColor.Red}]
          : []),
      ]}>
      {stats}
      {renderStartDateBanner()}
    </PropertyCard>
  );
};

export default LandlordPropertyIndexCard;
