import {useCallback, useMemo} from 'react';

import {UnmountClosed} from 'react-collapse';
import {
  HiOutlineDocumentText,
  HiOutlinePencilAlt,
  HiOutlineTemplate,
} from 'react-icons/hi';
import {HiOutlineRocketLaunch} from 'react-icons/hi2';
import {IconType} from 'react-icons/lib';

import advertiseIcon from 'assets/img/onboarding/onboarding_types/advertise.png';
import migrateIcon from 'assets/img/onboarding/onboarding_types/migrate.png';
import newIcon from 'assets/img/onboarding/onboarding_types/new.png';
import {Card, Modal} from 'components_sb/layout';
import {OBFS, OnboardingFlow} from 'constants/onboarding-flow-steps';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useRouter from 'router/hooks/useRouter';
import {Action} from 'types/actions';

import ActionButton from './ActionButton';
import useSelectOnboardingTypeHandler from './handlers/onboarding-type';
import PrepareTenancyInfoModal from './PrepareTenancyInfoModal';

const {useModal} = Modal.Imperative;

interface CardMetadata {
  icon: IconType;
  title: string;
  subtitle: string;
}

const MainActions = ({property}: {property: Property; tenancy?: Tenancy}) => {
  const router = useRouter();
  const openModal = useModal();

  /**
   * Wait for the property to have loaded in the parent component.
   */
  const isReady = useMemo(() => !!property, [property]);

  /**
   * ==================== HANDLERS ====================
   */

  /**
   * Handle selecting an onboarding flow.
   */
  const onSelectOnboardingType = useSelectOnboardingTypeHandler({property});

  /**
   * Handle entering one of the tenancy onboarding flows.
   */
  const enterTenancySetup = useCallback(() => {
    router.navigate('setup');
  }, [router]);

  /**
   * Handle entering the listing onboarding flow.
   */
  const enterListingSetup = useCallback(() => {
    router.navigate('setup');
  }, [router]);

  /**
   * Handle clicking the manage listing button when
   * there is a current listing.
   */
  const onManageListing = useCallback(() => {
    if (property.hasCurrentListing) {
      router.navigate(`listings/${property.currentListing.publicId}`);
    }
  }, [router, property]);

  /**
   * Handle clicking the prepare tenancy button when
   * there is a current listing.
   */
  const onPrepareTenancyForListing = useCallback(async () => {
    await openModal(PrepareTenancyInfoModal);
    enterTenancySetup();
  }, [openModal, enterTenancySetup]);

  /**
   * ==================== CONDITIONS ====================
   */

  /**
   * Determine whether the onboarding flow selection actions
   * should be shown.
   */
  const shouldShowOnboardingTypeSelect = useMemo(
    () =>
      property.currentOnboardingStep === OBFS.SelectOnboardingType &&
      !property.hasCurrentListing,
    [property],
  );

  /**
   * Determine whether to show the action for managing a listing.
   */
  const shouldShowManageListing = useMemo(
    () => property.hasCurrentListing,
    [property],
  );

  /**
   * Determine whether to show the action for preparing a tenancy
   * when there is an active listing.
   */
  const shouldShowPrepareListingTenancy = useMemo(
    () => property.hasCurrentListing,
    [property],
  );

  /**
   * Determine whether to show the action for continuing to
   * set up a listing.
   */
  const shouldShowContinueListingSetup = useMemo(
    () => property.currentOnboardingFlow === OnboardingFlow.NewListing,
    [property],
  );

  /**
   * Determine whether to show the action for continuing to
   * set up a tenancy when there is no current listing.
   */
  const shouldShowContinueTenancySetup = useMemo(
    () =>
      !property.hasCurrentListing &&
      [OnboardingFlow.MigrateTenancy, OnboardingFlow.NewTenancy].includes(
        property.currentOnboardingFlow,
      ),
    [property],
  );

  /**
   * ==================== METADATA ====================
   */

  /**
   * Determine the title, subtitle and icon to display for the card.
   */
  const cardMetadata = useMemo<CardMetadata>(() => {
    const metadata = {
      title: 'Actions',
      subtitle: 'What would you like to do?',
      icon: HiOutlineRocketLaunch,
    };

    if (shouldShowOnboardingTypeSelect) {
      metadata.title = "Let's get things set up...";
    } else if (
      shouldShowContinueListingSetup ||
      shouldShowContinueTenancySetup
    ) {
      metadata.title = "Let's continue getting things set up...";
    } else if (shouldShowManageListing) {
      metadata.title = "You're almost set up!";
    }

    return metadata;
  }, [
    shouldShowOnboardingTypeSelect,
    shouldShowContinueListingSetup,
    shouldShowContinueTenancySetup,
    shouldShowManageListing,
  ]);

  /**
   * ==================== ACTIONS ====================
   */

  /**
   * Actions for selecting an onboarding flow.
   */
  const selectOnboardingTypeActions = useMemo(
    () => [
      {
        label: 'I want to find a tenant',
        description:
          'Advertise your rental, host viewings, conduct tenant checks, create a tenancy agreement and file a bond.',
        icon: advertiseIcon,
        onClick: () => onSelectOnboardingType(OnboardingFlow.NewListing),
      },
      {
        label: 'I have a tenant who is currently renting',
        description:
          'Start using Keyhook to conduct maintenance repairs, perform inspections, calculate financials and much more!',
        icon: migrateIcon,
        onClick: () => onSelectOnboardingType(OnboardingFlow.MigrateTenancy),
      },
      {
        label: "I have found a tenant but I don't have a lease",
        description:
          'Create and e-sign a tenancy agreement, file a bond, conduct an initial inspection and begin self-managing.',
        icon: newIcon,
        onClick: () => onSelectOnboardingType(OnboardingFlow.NewTenancy),
      },
    ],
    [onSelectOnboardingType],
  );

  /**
   * Action for continuing the tenancy setup flow when
   * there is no current listing.
   */
  const continueListingSetupAction = useMemo(
    () => ({
      label: 'Continue setting up my listing',
      icon: HiOutlinePencilAlt,
      onClick: enterListingSetup,
    }),
    [enterListingSetup],
  );

  /**
   * Action for continuing the tenancy setup flow when
   * there is no current listing.
   */
  const continueTenancySetupAction = useMemo(
    () => ({
      label: 'Continue setting up my tenancy',
      icon: HiOutlinePencilAlt,
      onClick: enterTenancySetup,
    }),
    [enterTenancySetup],
  );

  /**
   * Action for managing a current listing.
   */
  const manageListingAction = useMemo(
    () => ({
      label: 'Manage my listing',
      description:
        'Check the status of your listing across all platforms, edit your listing and view your enquiries.',
      icon: HiOutlineTemplate,
      onClick: onManageListing,
    }),
    [onManageListing],
  );

  /**
   * Action for preparing a tenancy (for a listing).
   */
  const prepareListingTenancyAction = useMemo(
    () => ({
      label: 'Prepare my tenancy',
      description:
        'Found tenants? Build your lease, invite your tenants, and start your tenancy.',
      icon: HiOutlineDocumentText,
      onClick: onPrepareTenancyForListing,
    }),
    [onPrepareTenancyForListing],
  );

  /**
   * Determine the actions to show within the card.
   */
  const actions = useMemo<Action[]>(
    () => [
      ...(shouldShowOnboardingTypeSelect ? selectOnboardingTypeActions : []),
      ...(shouldShowContinueListingSetup ? [continueListingSetupAction] : []),
      ...(shouldShowContinueTenancySetup ? [continueTenancySetupAction] : []),
      ...(shouldShowManageListing ? [manageListingAction] : []),
      ...(shouldShowPrepareListingTenancy ? [prepareListingTenancyAction] : []),
    ],
    [
      shouldShowOnboardingTypeSelect,
      selectOnboardingTypeActions,
      shouldShowContinueListingSetup,
      continueListingSetupAction,
      shouldShowContinueTenancySetup,
      continueTenancySetupAction,
      shouldShowManageListing,
      manageListingAction,
      prepareListingTenancyAction,
      shouldShowPrepareListingTenancy,
    ],
  );

  /**
   * ==================== RENDER ====================
   */

  /**
   * Render the card.
   */
  return (
    <UnmountClosed isOpened={isReady && actions.length > 0}>
      <Card {...cardMetadata}>
        <div className="flex flex-col lg:flex-row gap-4">
          {actions.map((action) => (
            <ActionButton key={action.label} action={action} />
          ))}
        </div>
      </Card>
    </UnmountClosed>
  );
};

export default MainActions;

{
  /* <EmptyContentPlaceholder
          type="relax"
          message="There aren't any outstanding actions at the moment"
        /> */
}

// <GridSelect
//   mode="actions"
//   maxColumns={3}
//   minColumnWidth={250}
//   preventEmptyCells
//   actions={actions}
// />
