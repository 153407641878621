import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import localeData from 'dayjs/plugin/localeData';
import minMax from 'dayjs/plugin/minMax';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

/**
 * Enable plugins.
 */
dayjs.extend(advancedFormat);
dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.extend(minMax);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(weekday);

/**
 * Set the English locale configuration.
 */
dayjs.updateLocale('en', {
  weekdays: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
});

/**
 * Set English as the default global locale.
 */
dayjs.locale('en');

/**
 * Applies Dayjs configuration globally.
 */
const DayjsProvider = ({children}: {children: React.ReactNode}) => {
  return <>{children}</>;
};

export default DayjsProvider;
