import {AuthCookies} from 'auth/provider/_useAuthCookies';
import {
  FileItemStatus,
  FileUploaderStorageHandler,
  UploadedFileData,
} from 'components_sb/input/FileUploader/types';
import {API_URL} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import Listing from 'models/listings/Listing';

interface ListingPhotosUploader {
  (args: {
    authCookies: AuthCookies;
    listing: Listing;
  }): FileUploaderStorageHandler;
}

export const createListingPhotosStorageHandler: ListingPhotosUploader = ({
  authCookies,
  listing,
}) => ({
  upload: async ({file, onProgress}) => {
    const extension = file.type.split('/').pop();
    const filename = `${Date.now()}.${extension}`;

    // TODO: Resize and/or compress here

    /**
     * Perform the presign request.
     */
    const presignResponse = await fetch(
      `${API_URL}/presigns/listing_attachment.json?property_id=${listing.propertyId}&filename=${filename}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
      },
    );

    /**
     * Parse the presign response.
     */
    const presignInfo = (await presignResponse.json()) as PresignResponse;

    /**
     * Wrap the upload request in a promise so that we can await it and
     * then return the response to where the upload method was called.
     */
    return await new Promise((resolve, reject) => {
      if (presignInfo) {
        /**
         * Create and open a new XMLHttpRequest for the file upload using
         * the information from the presign response.
         */
        const request = new XMLHttpRequest();
        request.open(presignInfo.method, presignInfo.url);

        /**
         * Set the headers from the presign response.
         */
        for (const [key, value] of Object.entries(presignInfo.headers)) {
          request.setRequestHeader(key, value);
        }

        /**
         * When there is progress on the upload, invoke the onProgress
         * callback with the progress event.
         */
        request.upload.onprogress = (progressEvent) => {
          onProgress(progressEvent);
        };

        /**
         * When the file has been uploaded is complete, resolve
         * or rejectthe promise.
         */
        request.onload = function () {
          const {status} = request;
          if (status >= 200 && status < 300) {
            /**
             * File has been successfully uploaded!
             */

            /**
             * Get the URL from the presign response.
             */
            const url = new URL(presignInfo.url);

            /**
             * Find the ID of the uploaded file from the pathname of the URL.
             */
            const uploadedFileId = url.pathname.split('/').pop();

            /**
             * Construct the uploaded file data object using the ID of the
             * uploaded file and metadata from the file.
             */
            const uploadedFileData: UploadedFileData = {
              id: uploadedFileId,
              storage: 'cache',
              metadata: {
                size: file.size,
                filename: file.name,
                mime_type: file.type,
              },
            };

            /**
             * Resolve the promise with the uploaded file data.
             */
            resolve(uploadedFileData);
          } else {
            /**
             * There was an error uploading the file, reject the promise
             * with one of the supported FileUploader error statuses.
             */
            if (status === 413) {
              /**
               * File is too large.
               */
              reject(FileItemStatus.FileSizeTooLarge);
            } else if (status === 415) {
              /**
               * Unsupported file type.
               */
              reject(FileItemStatus.FileTypeNotAllowed);
            } else {
              /**
               * Indicate a generic error for all other cases.
               */
              reject(FileItemStatus.ErrorUploading);
            }
          }
        };

        /**
         * Start the file upload.
         */
        request.send(file);
      } else {
        /**
         * Reject the promise with a generic error if there
         * was no presign response.
         */
        reject(FileItemStatus.ErrorUploading);
      }
    });
  },
});
