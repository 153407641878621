import {useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import Decimal from 'decimal.js';
import {Form, Formik, type FormikProps} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import {Card} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import Tenancy from 'models/properties/Tenancy';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {shareEvenly} from 'utilities/MathHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const EditRentSplitsPage = () => {
  const {
    params: {tenancyId},
  } = useRoute();

  const queryClient = useQueryClient();

  const {authCookies, currentUser} = useAuth();

  const {data, isLoading, error} = useQuery(
    `renter-tenancy-${tenancyId}-rent-splits`,
    async () => {
      const t = await Tenancy.includes({active_tenancy_memberships: 'renter'})
        .select({
          tenancies: ['total_rent', 'bond', 'is_new', 'head_tenant_id'],
          users: ['name'],
        })
        .find(tenancyId);

      return t.data;
    },
  );

  const handleRentSplitsSubmit = async (formValues: any, actions: any) => {
    const response = await fetch(
      `${API_URL}/tenancies/${tenancyId}/update_rent_splits.json`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
        body: JSON.stringify(formValues),
      },
    );

    if (response.ok) {
      queryClient.invalidateQueries(`renter-tenancy-detail-${tenancyId}`);
      toast.success('Rent amounts successfully updated!');
    } else {
      const error = await response.json();
      actions.setFieldError('tenancyMemberships', error.errors.join(', '));
    }

    actions.setSubmitting(false);
  };

  const handleBondSplitsSubmit = async (formValues: any, actions: any) => {
    const response = await fetch(
      `${API_URL}/tenancies/${tenancyId}/update_bond_splits.json`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
        body: JSON.stringify(formValues),
      },
    );

    if (response.ok) {
      queryClient.invalidateQueries(`renter-tenancy-detail-${tenancyId}`);
      toast.success('Bond amounts successfully updated!');
    } else {
      const error = await response.json();
      actions.setFieldError('tenancyMemberships', error.errors.join(', '));
    }

    actions.setSubmitting(false);
  };

  const splitRentEvenly = (formikProps: FormikProps<any>) => {
    const numTenants = data.activeTenancyMemberships.length;
    const totalRent = data.totalRent;

    const amounts = shareEvenly(totalRent, numTenants);
    for (const index in amounts) {
      const amount = amounts[index];

      formikProps.setFieldValue(
        `tenancyMemberships[${index}].rentSplit`,
        amount,
      );
    }
  };

  if (error) {
    return (
      <Page title="Edit Rent Amounts">{() => errorViewForError(error)}</Page>
    );
  } else if (isLoading) {
    return <Page title="Edit Rent Amounts">{() => <LoadingView />}</Page>;
  } else {
    if (data.activeTenancyMemberships.length <= 1) {
      return (
        <Page title="Edit Rent Amounts">
          {() => (
            <Card title="Edit Rent Amounts">
              <Paragraph>
                {`You are the only tenant for this tenancy and therefore do not need
              to edit the portions of rent. If your other tenants have not been
              added to Keyhook, you can invite them `}
                <Hyperlink href={`/tenancies/${tenancyId}/invite-tenants`}>
                  here
                </Hyperlink>
                {`.`}
              </Paragraph>
            </Card>
          )}
        </Page>
      );
    }
    if (data.headTenantId === currentUser.id) {
      const values = {
        tenancyMemberships: data.activeTenancyMemberships.map((ship) => {
          return {
            id: ship.id,
            rentSplit: ship.rentSplit,
          };
        }),
      };

      const bondValues = {
        tenancyMemberships: data.activeTenancyMemberships.map((ship) => {
          return {
            id: ship.id,
            bondSplit: ship.bondSplit,
          };
        }),
      };

      return (
        <Page title="Edit Rent Amounts">
          {() => (
            <>
              <Card title="Edit Rent Amounts">
                <Paragraph>
                  Use the form below to adjust the portion of the total rent
                  each tenant pays. Any changes should be discussed with the
                  other tenants prior to changing.
                </Paragraph>

                <Paragraph>
                  Any changes will take effect immediately. Other tenants will
                  be notified that their portion of the rent has been changed.
                </Paragraph>

                <Formik
                  initialValues={values}
                  validationSchema={Yup.object().shape({
                    tenancyMemberships: Yup.array()
                      .of(
                        Yup.object().shape({
                          id: Yup.string().required('Required').label('Id'),
                          rentSplit: Yup.number()
                            .typeError('Please enter a number')
                            .required()
                            .label('Share of Rent'),
                        }),
                      )
                      .required('Must have rent amounts set')
                      .test({
                        name: 'rent-amounts-equal-total',
                        message: `Rent amounts do not total up to $${data.totalRent}`,
                        test: (values) => {
                          let total = new Decimal(0);
                          for (const ship of values) {
                            total = total.add(new Decimal(ship.rentSplit));
                          }

                          return total.equals(new Decimal(data.totalRent));
                        },
                      }),
                  })}
                  onSubmit={handleRentSplitsSubmit}
                  validateOnBlur={false}
                  validateOnChange={false}>
                  {(formik) => {
                    return (
                      <Form>
                        {data.activeTenancyMemberships.map((ship, index) => {
                          return (
                            <div className="form-control mt-2" key={ship.id}>
                              <label className="label">
                                <span className="label-text">
                                  {ship.renter.name}
                                </span>
                              </label>
                              <label className="input-group">
                                <span>$</span>
                                <InputField
                                  name={`tenancyMemberships[${index}].rentSplit`}
                                  formik={formik}
                                  type="number"
                                  placeholder="E.g. 123.45"
                                />
                              </label>
                            </div>
                          );
                        })}

                        <div className="mt-4">
                          <button
                            className="btn btn-neutral"
                            type="button"
                            onClick={() => splitRentEvenly(formik)}>
                            Split Rent Evenly
                          </button>

                          <SubmitButton
                            submittingText="Saving"
                            text="Save"
                            formik={formik}
                            className="mt-3"
                          />

                          {formik.errors.tenancyMemberships && (
                            <p className="mt-2 text-red-500">
                              {formik.errors.tenancyMemberships
                                .toString()
                                .includes('[')
                                ? ''
                                : formik.errors.tenancyMemberships.toString()}
                            </p>
                          )}

                          <p className="text-secondary text-sm mt-2">
                            When your rent doesn't divide evenly, make sure that
                            each portion doesn't have recurring numbers.
                          </p>
                          <p className="text-secondary text-sm mt-2">
                            Please make sure your rent adds up to{' '}
                            {toCurrency(data.totalRent)}.
                          </p>

                          <p className="text-secondary text-sm mt-1">
                            0 is a valid amount for when someone isn't going to
                            be paying rent, eg one half of a couple is paying
                            both portions of their rent.
                          </p>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Card>

              {!data.isNew && data.bond && (
                <Card title="Edit Bond Amounts">
                  <Paragraph>
                    Because your tenancy was migrated to Keyhook, we might not
                    have the amount of the bond each tenant contributed. Use the
                    form below to set these amounts, they will be used when a
                    bond refund is processed.
                  </Paragraph>

                  <Formik
                    initialValues={bondValues}
                    validationSchema={Yup.object().shape({
                      tenancyMemberships: Yup.array()
                        .of(
                          Yup.object().shape({
                            id: Yup.string().required('Required').label('Id'),
                            bondSplit: Yup.number()
                              .typeError('Please enter a number')
                              .required()
                              .label('Bond Contribution'),
                          }),
                        )
                        .required('Must have bond amounts set')
                        .test({
                          name: 'bond-amounts-equal-total',
                          message: `Bond amounts do not total up to $${data.bond}`,
                          test: (values) => {
                            let total = new Decimal(0);
                            for (const ship of values) {
                              total = total.add(
                                new Decimal(ship.bondSplit || 0),
                              );
                            }

                            return total.equals(new Decimal(data.bond));
                          },
                        }),
                    })}
                    onSubmit={handleBondSplitsSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}>
                    {(formik) => {
                      return (
                        <Form>
                          {data.activeTenancyMemberships.map((ship, index) => {
                            return (
                              <div className="form-control mt-2" key={ship.id}>
                                <label className="label">
                                  <span className="label-text">
                                    {ship.renter.name} - Bond Contribution
                                  </span>
                                </label>
                                <label className="input-group">
                                  <span>$</span>
                                  <InputField
                                    name={`tenancyMemberships[${index}].bondSplit`}
                                    formik={formik}
                                    type="number"
                                    placeholder="E.g. 123.45"
                                  />
                                </label>
                              </div>
                            );
                          })}

                          <div className="mt-4">
                            <SubmitButton
                              submittingText="Saving"
                              text="Save"
                              formik={formik}
                              className="mt-3"
                            />

                            {formik.errors.tenancyMemberships && (
                              <p className="mt-2 text-red-500">
                                {formik.errors.tenancyMemberships
                                  .toString()
                                  .includes('[')
                                  ? ''
                                  : formik.errors.tenancyMemberships.toString()}
                              </p>
                            )}

                            <p className="text-secondary text-sm mt-2">
                              Please make sure your bond amounts adds up to{' '}
                              {toCurrency(data.bond)}.
                            </p>

                            <p className="text-secondary text-sm mt-1">
                              0 is a valid amount for when someone didn't
                              contribute to the bond.
                            </p>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Card>
              )}
            </>
          )}
        </Page>
      );
    } else {
      return (
        <Page title="Edit Rent Amounts">
          {() => (
            <Card title="Edit Rent Amounts">
              <Paragraph>
                You are not the head tenant, only the head tenant can change
                rent amounts.
              </Paragraph>
            </Card>
          )}
        </Page>
      );
    }
  }
};

export default EditRentSplitsPage;
