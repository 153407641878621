import {useCallback, useMemo} from 'react';

import clsx from 'clsx';
import {isMobile} from 'react-device-detect';
import {useQuery} from 'react-query';

import ListingCardGrid from 'components/listing/ListingCardGrid';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {Title} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Listing from 'models/listings/Listing';
import {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRouter from 'router/hooks/useRouter';

import BasicFilters from './BasicFilters';
import HeroBackgroundImages from './HeroBackgroundImages';

const LATEST_LISTINGS_COUNT = 9;

const PublicListingsHomePage = () => {
  const {activeAccountRole} = useLocalUserSettings();

  const isLandlord = useMemo(
    () => activeAccountRole === AccountRole.Landlord,
    [activeAccountRole],
  );

  const {data: latestListings} = useQuery('latest-listings', async () => {
    const listings = await Listing.order({createdAt: 'desc'})
      .includes('listing_photos')
      .order({'listing_photos.order_index': 'asc'})
      .per(LATEST_LISTINGS_COUNT)
      .page(0)
      .all();
    return listings.data;
  });

  /**
   * To show all listings, we just navigate to the search results
   * page without any query params.
   */
  const router = useRouter();
  const onShowAllListings = useCallback(() => {
    router.navigate('search');
  }, [router]);

  return (
    <Page disableNavbar unbounded>
      {() => (
        <>
          <div
            className={clsx(
              'flex-1 w-full h-full max-w-7xl mx-auto',
              'px-6 lg:px-8',
              'pb-24 mdlg:pb-28',
            )}>
            {/* If the user's current active account type is "Landlord", then we show a
      banner notifying them that they can visit their dashboard to manage their
      own listings. */}
            {isLandlord && (
              <div className="my-6">
                <Alert
                  type="info"
                  title="Want to list your own property or manage your current listings?"
                  description={`${
                    isMobile ? 'Tap' : 'Click'
                  } here to go to your dashboard.`}
                  asLink
                  linkTo="/"
                />
              </div>
            )}

            {/* Hero section */}
            <div
              className={clsx(
                'w-full',
                'box-border',
                'flex flex-row',
                'justify-end items-center',
                'relative',
                'min-h-[500px]',
                'mt-10 mdlg:mt-0 mb-16 mdlg:mb-6',
              )}>
              {/* Hero background images */}
              <div
                className={clsx(
                  'absolute z-0',
                  'w-auto h-full',
                  'right-0 top-0',
                  'flex items-center',
                )}>
                <HeroBackgroundImages />
              </div>

              {/* Hero title and search bar */}
              <div
                className={clsx(
                  'static mdlg:absolute z-10',
                  'w-full h-full',
                  'left-0 top-0',
                  'flex flex-col',
                  'gap-y-8',
                  'justify-center',
                  'items-center mdlg:items-start',
                )}>
                <div
                  className={clsx(
                    'ml-0 mdlg:ml-10',
                    'text-center mdlg:text-left',
                  )}>
                  <Title
                    level="h1"
                    size="xl"
                    subtitle="The comprehensive marketplace for New Zealand rentals.">
                    Find your next home
                  </Title>
                </div>
                <BasicFilters />
              </div>
            </div>

            {/* Listing cards section */}
            <div className="flex flex-col gap-y-6">
              <div className="mx-auto text-center">
                <Title
                  level="h2"
                  size="lg"
                  subtitle="Check out the most recent rentals listed on Keyhook.">
                  Latest listings
                </Title>
              </div>
              <ListingCardGrid
                skeletonCount={LATEST_LISTINGS_COUNT}
                listings={latestListings}
              />
            </div>

            <div className="w-full mt-16 flex flex-row justify-center">
              <div className="max-w-sm flex-1">
                <Button
                  category="secondary"
                  size="base"
                  label="View all listings"
                  mode="manual"
                  onClick={onShowAllListings}
                />
              </div>
            </div>
          </div>
          {/* {Capacitor.isNativePlatform() &&
        activeAccountRole === AccountRole.Renter && (
          <BottomTabBar accountType={AccountRole.Renter} />
        )} */}
        </>
      )}
    </Page>
  );
};

export default PublicListingsHomePage;
