import {Capacitor} from '@capacitor/core';

export const NZ_TIMEZONE = 'Pacific/Auckland';

export const {
  NATIVE_BUILD_ENV,
  TARGET_ENV,
  GIT_BRANCH,
  GIT_COMMIT,
  PINPAYMENTS_PUBLIC_KEY,
  APP_VERSION,
  PERSONA_ENV_ID,
  PERSONA_TEMPLATE_ID,
  IS_E2E,
  E2E_HOST,
} = process.env;

/**
 * Parse the environment variable as boolean
 */
export const NATIVE_LAYOUT_IN_WEB = process.env.NATIVE_LAYOUT_IN_WEB === 'true';

/**
 * Whether we are allowed to display any communications or functionality
 * regarding subscriptions and billing management. Currently this is
 * disabled for the iOS app.
 */
export const SHOW_BILLING_COMMS =
  Capacitor.getPlatform() !== 'ios' && !NATIVE_LAYOUT_IN_WEB;

export const BASE_API_URL = (() => {
  /**
   * Staging
   */
  if (TARGET_ENV === 'staging') {
    if (IS_E2E) {
      return `https://${E2E_HOST}`;
    } else {
      return 'https://staging-api.keyhook.com';
    }
  }

  /**
   * Production
   */
  if (TARGET_ENV === 'production') {
    return 'https://api.keyhook.com';
  }

  /**
   * Development and all other cases
   */

  /**
   * Use the local IP for Android due to lack of mDNS prior to
   * Android 11 (meaning we can't use .local domains).
   */
  if (NATIVE_BUILD_ENV === 'android' || Capacitor.getPlatform() === 'android') {
    return process.env.LOCAL_IP
      ? `http://${process.env.LOCAL_IP}:8000`
      : 'http://locahost:8000';
  } else {
    return process.env.LOCAL_DOMAIN
      ? `http://api.${process.env.LOCAL_DOMAIN}`
      : 'http://locahost:8000';
  }
})();

export const API_NAMESPACE = '/api/v1';
export const API_URL = BASE_API_URL + API_NAMESPACE;

/**
 * The URL where the React app is running - this is only used in
 * development mode. Production and staging versions will serve
 * the React app from the build output bundled with the app.
 */
export const LOCAL_WEB_SERVER_URL = (() => {
  /**
   * Use the local IP for Android due to lack of mDNS prior to
   * Android 11 (meaning we can't use .local domains).
   */
  if (NATIVE_BUILD_ENV === 'android' || Capacitor.getPlatform() === 'android') {
    return process.env.LOCAL_IP
      ? `http://${process.env.LOCAL_IP}:3000`
      : 'http://locahost:3000';
  } else {
    return process.env.LOCAL_DOMAIN
      ? `http://app.${process.env.LOCAL_DOMAIN}`
      : 'http://locahost:3000';
  }
})();

/**
 * Log the local URLs for debugging.
 */
if (TARGET_ENV === 'development') {
  console.log('Native build environment:', NATIVE_BUILD_ENV);
  console.log('Using API URL:', BASE_API_URL);
  console.log('Using local web server URL:', LOCAL_WEB_SERVER_URL);
}
