import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import Pusher from 'pusher-js';
import PusherBatchAuthorizer from 'pusher-js-auth';

import useAuth from 'auth/provider/useAuth';
import {API_URL, TARGET_ENV} from 'globals/app-globals';
import useLocalUserSettings from 'hooks/useLocalUserSettings';

import {ChatChannel, ChatRoom, useChatChannels} from './_ChatChannel';

interface FetchChannelsFunction {
  (): Promise<Date | void>;
}

interface FetchRoomsFunction {
  (): Promise<ChatRoom[]>;
}

interface PusherServiceContextValue {
  client: Pusher;
  channels: ChatChannel[];
  channelsLoaded: boolean;
  fetchChannels: FetchChannelsFunction;
  unsubscribeAll: () => void;
}

const PusherServiceContext = createContext<PusherServiceContextValue>(
  {} as PusherServiceContextValue,
);

interface PusherServiceProvider {
  (props: {children: ReactNode}): JSX.Element;
}

let PUSHER_KEY: string;
if (TARGET_ENV === 'development') {
  PUSHER_KEY = '13b5a8ecf7d22bb508c9';
} else if (TARGET_ENV === 'staging') {
  PUSHER_KEY = 'a14543b8a5df35fa0921';
} else if (TARGET_ENV === 'production') {
  PUSHER_KEY = '43899dcc2bc207e39ca1';
} else {
  PUSHER_KEY = '';
}

export const PusherServiceProvider: PusherServiceProvider = ({children}) => {
  const {isLoggedIn, authCookies} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  const [channelsLoaded, setChannelsLoaded] = useState(false);

  /**
   * The time that the chat rooms were last attempted to be fetched. Note that this
   * only indicates the attempt of a request, and is not necessarily when the chat
   * rooms were last successfully fetched.
   */
  const [lastFetchAttempted, setLastFetchAttempted] = useState<number>();
  const [client, setClient] = useState<Pusher>();

  /**
   * The chat rooms are what are used to automatically create
   * the chat channels via the useChatChannels hook.
   */
  const [rooms, setRooms] = useState<ChatRoom[]>([]);

  /**
   * Fetches the chat rooms for the current user.
   */
  const fetchRooms: FetchRoomsFunction = useCallback(async () => {
    if (isLoggedIn) {
      const response = await fetch(API_URL + '/chat_rooms.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
          'X-ACCOUNT-TYPE': activeAccountRole,
        },
      });
      const data = await response.json();

      /**
       * Ensure that the response data includes the rooms.
       */
      if (data && data.rooms) {
        return data.rooms;
      } else {
        throw new Error('Chat rooms not present in request response.');
      }
    }
  }, [isLoggedIn, activeAccountRole, authCookies]);

  const fetchChannels = useCallback<FetchChannelsFunction>(async () => {
    if (!isLoggedIn) {
      console.warn('Cannot fetch chat channels - there is no logged in user.');
      return;
    }

    if (!activeAccountRole) {
      console.warn(
        'Cannot fetch chat channels - logged in user does not have an active account role.',
      );
      return;
    }

    /**
     * Prevent spamming of fetching channels since this is a
     * heavy server side controller action.
     */
    if (
      channelsLoaded &&
      lastFetchAttempted &&
      Date.now() - lastFetchAttempted <= 10000
    ) {
      return;
    }

    setLastFetchAttempted(Date.now());

    /**
     * Fetch and set the chat rooms for the current user.
     */
    try {
      setRooms(await fetchRooms());
      setChannelsLoaded(true);
    } catch (error) {
      // TODO: Show error on chat pages instead of toast
      console.error('Error fetching chat rooms', error);
      // toast.error('There was an issue loading your conversations.');
    }
  }, [
    isLoggedIn,
    activeAccountRole,
    channelsLoaded,
    lastFetchAttempted,
    setLastFetchAttempted,
    fetchRooms,
    setRooms,
    setChannelsLoaded,
  ]);

  const channels = useChatChannels({
    pusherClient: client,
    rooms,
  });

  const initialise = useCallback(() => {
    setClient(
      new Pusher(PUSHER_KEY, {
        authorizer: PusherBatchAuthorizer,
        authDelay: 200,
        cluster: 'ap4',
        authEndpoint: API_URL + '/pusher/batch_authorize.json',
        auth: {
          headers: {
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
            'X-ACCOUNT-TYPE': activeAccountRole,
          },
        },
      }),
    );
  }, [activeAccountRole, authCookies]);

  const handleAuthChange = useCallback(() => {
    initialise();
    fetchChannels();
  }, [initialise, fetchChannels]);

  /**
   * Initialise the Pusher client when there is a logged in user,
   * or if the current user changes their active account role,
   * and then fetch the channels for the user.
   */
  useEffect(() => {
    setChannelsLoaded(false);
    if (isLoggedIn && !!activeAccountRole) {
      handleAuthChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, activeAccountRole]);

  /**
   * Unsubscribe from all chat channels and disconencted the client.
   */
  const unsubscribeAll = useCallback(() => {
    for (const channel of channels) {
      channel.pusherChannel.disconnect();
    }
    client.disconnect();
  }, [client, channels]);

  return (
    <PusherServiceContext.Provider
      value={{
        client,
        channels,
        channelsLoaded,
        fetchChannels,
        unsubscribeAll,
      }}>
      {children}
    </PusherServiceContext.Provider>
  );
};

interface PusherServiceHook {
  (): PusherServiceContextValue;
}

export const usePusherService: PusherServiceHook = () => {
  const contextValue = useContext(PusherServiceContext);
  return contextValue;
};
