import React, {useMemo} from 'react';

import moment from 'moment';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {RouterLink} from 'router/components';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {titleize, removeUnderscores, toCurrency} from 'utilities/StringHelpers';

const RentStatusCard = ({tenancy}: {property: Property; tenancy?: Tenancy}) => {
  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () =>
      !tenancy
        ? null
        : [
            {
              label: 'View all rent payments',
              linkTo: `tenancies/${tenancy.id}/payments`,
            },
          ],
    [tenancy],
  );

  if (!tenancy || !tenancy.managedRentEnabled) {
    return null;
  }

  if (tenancy.unpaidLandlordPayments.length === 0) {
    return (
      <Card
        title="Rent Collection"
        actions={cardActions}
        className="mt-8 border-t-2 border-success">
        <Paragraph>
          There are no outstanding rent payments for this property.
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card
        title="Rent Collection"
        actions={cardActions}
        className="mt-8 border-t-2 border-error">
        {tenancy.unpaidLandlordPayments.length === 1 ? (
          <Paragraph>
            There is currently 1 rent payment that has not been paid. Keyhook
            will chase any arrears if they are over a day late.
          </Paragraph>
        ) : (
          <Paragraph>
            There are currently {tenancy.unpaidLandlordPayments.length} rent
            payments that have not been paid. Keyhook will chase any arrears if
            they are over a day late.
          </Paragraph>
        )}

        {tenancy.unpaidLandlordPayments.map((lp) => (
          <RouterLink
            key={lp.id}
            href={`tenancies/${tenancy.id}/payments/${lp.id}`}>
            <Card>
              <div className="flex justify-between items-center">
                <div className="flex flex-col">
                  <strong>{moment(lp.paymentDate).format(DATE_FORMAT)}</strong>

                  <span className="text-xs">
                    Status: {titleize(removeUnderscores(lp.status))}
                  </span>
                  <span className="text-xs text-gray-500">
                    Reference: {lp.reference}
                  </span>
                </div>
              </div>

              <div className="grid grid-cols-4">
                <div className="flex flex-col items-center">
                  <span className="text-xs">Expected</span>
                  <strong className="text-sm">
                    {toCurrency(lp.grossAmount)}
                  </strong>
                </div>

                <div className="flex flex-col items-center">
                  <span className="text-xs">Received</span>
                  <strong className="text-sm">
                    {toCurrency(lp.amountOfRentPaid || 0.0)}
                  </strong>
                </div>

                <div className="flex flex-col items-center">
                  <span className="text-xs">Pending</span>
                  <strong className="text-sm">
                    {toCurrency(lp.pendingAmount || 0.0)}
                  </strong>
                </div>
              </div>
            </Card>
          </RouterLink>
        ))}
      </Card>
    );
  }
};

export default RentStatusCard;
