import {Router} from 'framework7/types';

import PropertyIndexPage from 'pages/landlord/properties/PropertyIndexPage';
import TenancyIndexPage from 'pages/renter/tenancies/TenancyIndexPage';
import WelcomePage from 'pages/shared/auth/welcome';
import PageNotFound from 'pages/shared/not-found';

import resolveRoute, {RouteCondition} from '../utils/route-resolver';
import accountRoutes from './account';
import applicantRoutes from './applicants';
import associateRoutes from './associates';
import authRoutes from './auth';
import chatRoutes from './chat';
import demoRoutes from './demo';
import documentPreviewsRoutes from './document-previews';
import enquiryRoutes from './enquiries';
import financialRoutes from './financials';
import jobRoutes from './jobs';
import legacyRedirects from './legacy-redirects';
import privateViewingRoutes from './private-viewings';
import propertyRoutes from './properties';
import publicListingRoutes from './public-listings';
import referenceRoutes from './references';
import rentalApplicationRoutes from './rental-applications';
import tenancyRoutes from './tenancies';
import tenantCheckReportRoutes from './tenant-check-reports';
import viewingRoutes from './viewings';

const {LOGGED_IN, LOGGED_OUT, IS_LANDLORD, IS_RENTER} = RouteCondition;

/**
 * All routes for the application.
 */
const routes: Router.RouteParameters[] = [
  /**
   * Index route.
   */
  {
    path: '/',
    id: 'Home',
    async: resolveRoute([
      {
        conditions: [LOGGED_OUT],
        component: WelcomePage,
      },
      {
        conditions: [LOGGED_IN, IS_LANDLORD],
        component: PropertyIndexPage,
      },
      {
        conditions: [LOGGED_IN, IS_RENTER],
        component: TenancyIndexPage,
      },
    ]),
  },

  /**
   * App routes.
   */
  ...accountRoutes,
  ...applicantRoutes,
  ...associateRoutes,
  ...authRoutes,
  ...chatRoutes,
  ...documentPreviewsRoutes,
  ...demoRoutes,
  ...enquiryRoutes,
  ...financialRoutes,
  ...jobRoutes,
  ...privateViewingRoutes,
  ...propertyRoutes,
  ...publicListingRoutes,
  ...referenceRoutes,
  ...rentalApplicationRoutes,
  ...tenancyRoutes,
  ...tenantCheckReportRoutes,
  ...viewingRoutes,

  /**
   * Redirects for routes that were previouslt used and have moved, but
   * could still be potentially linked to in some places.
   */
  ...legacyRedirects,

  /**
   * Catch-all route (404 page).
   */
  {
    path: '(.*)',
    async: resolveRoute({component: PageNotFound}),
  },
];

export default routes;
