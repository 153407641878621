import {UseQueryResult, useQuery} from 'react-query';

import ListingRentalApplication from 'models/listings/ListingRentalApplication';

interface UseListingRentalApplicationQueryHook {
  (args: {id: string}): UseQueryResult<ListingRentalApplication> & {
    queryKey: string[];
  };
}

const useListingRentalApplicationQuery: UseListingRentalApplicationQueryHook =
  ({id}) => {
    const queryKey = ['listing-rental-application', id];
    return {
      queryKey,
      ...useQuery(
        queryKey,
        async () =>
          (
            await ListingRentalApplication.includes([
              {
                rental_application: ['rental_application_applicants', 'user'],
              },
              'listing',
            ]).find(id)
          ).data,
      ),
    };
  };

export default useListingRentalApplicationQuery;
