import {FunctionComponent, useMemo} from 'react';

import {sortBy} from 'lodash';
import {UseQueryResult} from 'react-query';

import {ContentError} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import EnquiryThread from 'models/listings/EnquiryThread';
import {AccountRole} from 'models/users/User';

import EnquiryThreadListItem from './EnquiryThreadListItem/EnquiryThreadListItem';
import {ListingEnquiryTitleMode} from './types';

const SKELETON_COUNT = 4;

interface EnquiryThreadsListProps {
  query: UseQueryResult<EnquiryThread[]>;
  titleMode: ListingEnquiryTitleMode;
}

const EnquiryThreadsList: FunctionComponent<EnquiryThreadsListProps> = ({
  query,
  titleMode,
}) => {
  const {data: enquiryThreads} = query;

  const sortedEnquiryThreads = useMemo(
    () => (!enquiryThreads ? null : sortBy(enquiryThreads, 'archived')),
    [enquiryThreads],
  );

  /**
   * Whether there are any enquiry threads to display.
   */
  const hasEnquiryThreads = useMemo(
    () => query.isSuccess && enquiryThreads.length > 0,
    [query.isSuccess, enquiryThreads],
  );

  const {activeAccountRole} = useLocalUserSettings();

  /**
   * Whether the current user's active account role is "Landlord".
   */
  const isLandlord = useMemo(
    () => activeAccountRole === AccountRole.Landlord,
    [activeAccountRole],
  );

  if (query.isError) {
    return (
      <ContentError message="Sorry, there was an error loading your enquiries." />
    );
  }

  /**
   * No enquiry threads to display.
   */
  if (query.isSuccess && !hasEnquiryThreads) {
    return (
      <div className="flex-1 w-full flex items-center justify-center">
        <EmptyContentPlaceholder
          type="inbox"
          message={
            isLandlord
              ? 'You have not received any new enquiries for this listing yet.'
              : 'You have not enquired about any listings yet.'
          }
        />
      </div>
    );
  }

  return (
    <div>
      {query.isLoading
        ? [...Array(SKELETON_COUNT).keys()].map((index) => (
            <EnquiryThreadListItem key={index} titleMode={titleMode} />
          ))
        : sortedEnquiryThreads.map((enquiryThread) => (
            <EnquiryThreadListItem
              key={enquiryThread.id}
              enquiryThread={enquiryThread}
              titleMode={titleMode}
            />
          ))}
    </div>
  );
};

export default EnquiryThreadsList;
