import React, {useMemo, useState} from 'react';

import FsLightbox from 'fslightbox-react';
import moment from 'moment';

import Action from 'components/inspection/actions/Action';
import Note from 'components/inspection/notes/Note';
import {Card, Lightbox} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import {MediableItems} from 'models/inspections/MediableItems';
import {AccountRole} from 'models/users/User';
import {Navigate, Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useInspectionStore from 'stores/InspectionStore';
import {titleize} from 'utilities/StringHelpers';

const InspectionAmendmentsPage = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {activeAccountRole} = useLocalUserSettings();

  const {
    params: {tenancyId, propertyId, inspectionId},
  } = useRoute();

  const [inspection, inspectionItems] = useInspectionStore((state) => [
    state.inspection,
    state.inspectionItems,
  ]);

  const roomItems = useMemo(() => {
    const items = [] as MediableItems;

    inspectionItems?.forEach((item) => {
      item.inspectionItemAttachments.forEach((attach) => {
        if (attach.amended) {
          items.push(attach);
        }
      });

      item.inspectionActions.forEach((action) => {
        if (action.amended) {
          items.push(action);
        }
      });
    });

    // Reverse order
    return items.sort(
      (a, b) => moment(b.updatedAt).unix() - moment(a.updatedAt).unix(),
    );
  }, [inspectionItems]);

  const mappedInspectionItems = useMemo(() => {
    const items = {} as Record<string, InspectionItem>;
    if (inspectionItems) {
      inspectionItems.forEach((item) => (items[item.id] = item));
    }
    return items;
  }, [inspectionItems]);

  const handlePhotoClick = (index: number) => {
    setSelectedIndex(index + 1);
    setLightboxOpen(true);
  };

  const renderItem = (
    attachment: InspectionItemAttachment | InspectionAction,
    index: number,
  ) => {
    const item = mappedInspectionItems[attachment.inspectionItemId];

    return (
      <div key={index} className="flex justify-start gap-4 mb-8">
        <img
          onClick={() => handlePhotoClick(index)}
          className="bg-black inline-block h-[120px] w-[120px] object-cover cursor-pointer rounded-lg"
          src={attachment.attachmentThumbnail || attachment.attachment}
        />

        <div>
          <strong>
            {item.room === 'All' ? item.name : `${item.room} - ${item.name}`}
          </strong>
          <p>
            Added by {attachment.user.name} -{' '}
            <em className="text-secondary">
              {moment(attachment.updatedAt).fromNow()}
            </em>
          </p>

          {attachment.isType('inspection_item_attachments') && (
            <Note
              attachment={attachment as InspectionItemAttachment}
              // TODO: Add onClick handler
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => {}}
            />
          )}

          {attachment.isType('inspection_actions') && (
            <Action
              action={attachment as InspectionAction}
              // TODO: Add onClick handler
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() => {}}
            />
          )}
        </div>
      </div>
    );
  };

  if (!inspection) {
    if (activeAccountRole === AccountRole.Renter) {
      return (
        <Navigate
          href={`/tenancies/${tenancyId}/inspections/${inspectionId}`}
        />
      );
    } else {
      return (
        <Navigate
          href={`/properties/${propertyId}/inspections/${inspectionId}`}
        />
      );
    }
  } else {
    return (
      <Page title="Revisions">
        {() => (
          <>
            <Card title="Revisions">
              {roomItems && roomItems.length === 0 && (
                <Paragraph>
                  There are no revisions for this inspection.
                </Paragraph>
              )}
              {roomItems.map(renderItem)}
            </Card>

            <Lightbox
              toggler={lightboxOpen}
              sources={roomItems.map((source) => source.attachment)}
              thumbs={roomItems.map((a) => a.attachmentThumbnail)}
              slide={selectedIndex}
              captions={roomItems.map((source) => {
                const item = mappedInspectionItems[source.inspectionItemId];

                if (source.isType('inspection_item_attachments')) {
                  const a = source as InspectionItemAttachment;
                  return (
                    <div key={a.id} className="flex flex-col">
                      <h5 className="text-white text-lg">
                        {item.room} - {item.name}
                      </h5>
                      <p className="text-white text-md">{a.notes}</p>
                    </div>
                  );
                } else {
                  const a = source as InspectionAction;
                  return (
                    <div key={a.id} className="flex flex-col">
                      <h5 className="text-white text-lg">
                        {item.room} - {item.name}
                      </h5>
                      <p className="text-white text-md">
                        <strong>{titleize(a.actionType)} Action: </strong>
                        {a.action}
                      </p>
                    </div>
                  );
                }
              })}
            />
          </>
        )}
      </Page>
    );
  }
};

export default InspectionAmendmentsPage;
