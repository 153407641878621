import React, {useState} from 'react';

import {ChevronDownIcon} from '@heroicons/react/outline';
import {Formik} from 'formik';
import {useQueryClient} from 'react-query';

import {InputField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PseudoBooleanDisplayView from 'components/rental_application/PseudoBooleanDisplayView';
import {TextAreaField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import ListingRentalApplication, {
  ListingRentalApplicationTag,
} from 'models/listings/ListingRentalApplication';
import useRoute from 'router/hooks/useRoute';

const RentalApplicationSummaryView = ({
  data,
}: {
  data: ListingRentalApplication;
}) => {
  const rentalApplication = data.rentalApplication;

  const [mutationLoading, setMutationLoading] = useState(false);

  const {
    params: {listingPublicId},
  } = useRoute();

  const queryClient = useQueryClient();

  const setTag = async (tag: 'shortlisted' | 'excluded') => {
    setMutationLoading(true);
    const app = data;
    app.landlordTag = tag as ListingRentalApplicationTag;

    const result = await app.save();
    if (result) {
      queryClient.invalidateQueries(['listing-applications', listingPublicId]);
    }
    setMutationLoading(false);
  };

  return (
    <div className="">
      <div className="mx-16 mb-16">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="card-title mb-4 mt-2 text-brand-850">Quick View</h2>
            <Paragraph>
              Click the table row above to view the full application.
            </Paragraph>
          </div>
          <div className="dropdown dropdown-end">
            <label tabIndex={0} className="btn btn-neutral btn-sm m-1">
              {mutationLoading ? (
                'Updating'
              ) : (
                <>
                  Actions
                  <ChevronDownIcon className="ml-2 text-white w-5 h-5" />
                </>
              )}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
              <li>
                <a onClick={() => setTag('shortlisted')}>Shortlist</a>
              </li>
              <li>
                <a onClick={() => setTag('excluded')}>Archive</a>
              </li>
            </ul>
          </div>
        </div>

        <Formik
          initialValues={rentalApplication}
          onSubmit={() => {
            ('');
          }}
          validateOnBlur={false}
          validateOnChange={false}>
          {(formik) => (
            <div>
              <Card title="Employment Information">
                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="occupation"
                      labelProps={{
                        title: 'Occupation',
                      }}
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="weeklyIncome"
                      labelProps={{
                        title: 'Weekly income before tax ($)',
                      }}
                      type="number"
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="employer"
                      labelProps={{
                        title: 'Employer',
                      }}
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="yearsEmployedAtCurrentPlace"
                      labelProps={{
                        title: 'Years employed there',
                      }}
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <div className="w-1/2">
                  <PseudoBooleanDisplayView
                    label="Are you currently employed full time?"
                    value={formik.values.headTenantEmployedFullTime}
                  />
                </div>

                <h2 className="card-title mt-2 mb-4 text-brand-850 flex justify-between">
                  Current Living Situation
                </h2>
                <FormRow>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddress"
                      labelProps={{
                        title: 'What is your current address?',
                      }}
                      disabled
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddressStayLength"
                      labelProps={{
                        title: 'How long have you stayed there?',
                      }}
                      disabled
                    />
                  </FormRowItem>
                </FormRow>

                <TextAreaField
                  mode="formik"
                  form={formik as any}
                  name="currentAddressReasonLeaving"
                  label="What is your reason for leaving?"
                  disabled
                />
              </Card>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RentalApplicationSummaryView;
