import {ComponentPropsWithoutRef, ElementRef, forwardRef} from 'react';

import * as AccordionPrimitive from '@radix-ui/react-accordion';
import {HiChevronDown} from 'react-icons/hi';

import cn from 'khui/utils/cn';

const AccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({className, children, ...props}, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'transition-all duration-300',
        'text-brand-500 hover:text-brand-400 font-medium',
        'flex flex-1 items-center justify-between py-4 transition-all [&[data-state=open]>svg]:rotate-180',
        className,
      )}
      {...props}>
      {children}
      <HiChevronDown className="h-6 w-6 shrink-0 transition-transform duration-300" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));

AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;
export default AccordionTrigger;
