import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {isMobile} from 'react-device-detect';

import useAuth from 'auth/provider/useAuth';
import {Hyperlink} from 'components_sb/navigation';
import {Title} from 'components_sb/typography';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {TARGET_ENV} from 'globals/app-globals';

interface HttpErrorProps {
  /**
   * The HTTP error code.
   */
  code: number;
  /**
   * A user friendly title for the HTTP error.
   */
  title: string;
  /**
   * A brief description about the HTTP error.
   */
  description: string;
}

/**
 * A full page error message to indicate an issue
 * relating to an HTTP error.
 */
const HttpErrorProps = ({code, title, description}: HttpErrorProps) => {
  const {currentUser} = useAuth();

  const showSupport = async () => {
    const params = new URLSearchParams();
    if (currentUser) {
      params.set('user_id', currentUser.id);
      params.set('name', currentUser.name);
      params.set('email', currentUser.email);
      params.set('env', TARGET_ENV);
    }

    let url = `https://livesupport.keyhook.com?${params.toString()}`;
    if (currentUser) {
      url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');
    }

    await Browser.open({url: url});
  };

  if (Capacitor.isNativePlatform()) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center gap-y-4 text-center p-12 h-full">
        {/* Error title */}
        <Title level="h1">The app ran into an issue.</Title>
        {/* Error description */}
        <Paragraph>
          Sorry about that, please contact our support team for assistance.
        </Paragraph>
        {/* Homepage link */}
        <a className="link link-primary tw-block mt-4" onClick={showSupport}>
          Open Live Chat Support
        </a>
      </div>
    );
  } else {
    return (
      <div className="flex-1 flex flex-col items-center justify-center gap-y-4 text-center p-12">
        {/* HTTP code */}
        <p className="font-semibold text-primary text-8xl">{code}</p>
        {/* Error title */}
        <Title level="h1">{title}</Title>
        {/* Error description */}
        <Paragraph>{description}</Paragraph>
        {/* Homepage link */}
        <Hyperlink href="/">
          {`${isMobile ? 'Tap' : 'Click'} here to return to the homepage`}
        </Hyperlink>
      </div>
    );
  }
};

export default HttpErrorProps;
