import {FunctionComponent, ReactNode, createContext, useMemo} from 'react';

import {RouteComponentProps} from '../types';

/**
 * Props required for the RouteWrapper component.
 */
type RouteWrapperProps = RouteComponentProps & {
  children: ReactNode;
};

/**
 * The values accessible to components within routes via the useRoute hook.
 */
type RouteContextValue = RouteComponentProps & {
  [key: string]: any; // Custom props passed to the route when navigating
};

/**
 * Create the context for the route.
 */
export const RouteContext = createContext<RouteContextValue>(
  {} as RouteContextValue,
);

/**
 * The component that all route commponents should be wrapped with to enable
 * accessing route and router functionality in the page component and any
 * nested component of the page via the useRoute hook.
 */
const RouteContextProvider: FunctionComponent<RouteWrapperProps> = ({
  /**
   * The route component to be rendered within the wrapper.
   */
  children,
  /**
   * The props that are passed to components defined in the Framework7 routes.
   */
  f7router,
  f7route,
  /**
   * Any additional custom props that have been passed to the route.
   */
  ...routeProps
}) => {
  /**
   * The value to be passed to the RouteContext.Provider.
   */
  const value = useMemo(
    () => ({
      f7router,
      f7route,
      routeProps,
    }),
    [f7router, f7route, routeProps],
  );

  return (
    <RouteContext.Provider value={value}>{children}</RouteContext.Provider>
  );
};

export default RouteContextProvider;
