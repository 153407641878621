import React from 'react';

import errorPlaceholder from 'assets/img/empty-content-placeholders/error.png';

interface ContentErrorProps {
  message?: string;
}

/**
 * A placeholder to be displayed when there was an error loading some content.
 */
const ContentErrorProps = ({message}: ContentErrorProps) => {
  return (
    <div className="flex flex-col items-center justify-center px-10 pt-6 pb-24 gap-y-2 opacity-70 mx-auto my-auto">
      <img src={errorPlaceholder} className="h-24" />
      <p className="text-red-400 text-base text-center font-medium">
        {message ?? 'Sorry, there was an issue loading this content.'}
      </p>
    </div>
  );
};

export default ContentErrorProps;
