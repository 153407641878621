import {Router} from 'framework7/types';

import EnquiryThreadAutoReplyPage from 'pages/renter/enquiry_threads/EnquiryThreadAutoReplyPage';
import EnquiryThreadDetailPage from 'pages/renter/enquiry_threads/EnquiryThreadDetailPage';
import EnquiryThreadIndexPage from 'pages/renter/enquiry_threads/EnquiryThreadIndexPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER, LOGGED_IN, IS_RENTER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/enquiries',
    id: 'Enquiries',
    async: resolveRoute({
      conditions: [LOGGED_IN, IS_RENTER],
      component: EnquiryThreadIndexPage,
    }),
    routes: [
      {
        path: ':enquiryThreadId',
        id: 'EnquiryThread',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_RENTER],
          component: EnquiryThreadDetailPage,
        }),
      },
      {
        path: ':replyToken',
        async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
        routes: [
          {
            path: 'auto-reply',
            id: 'EnquiryAutoReply',
            async: resolveRoute({
              component: EnquiryThreadAutoReplyPage,
            }),
          },
        ],
      },
    ],
  },
];

export default routes;
