import {Fragment} from 'react';

import {Menu, Transition} from '@headlessui/react';
import {
  ExclamationCircleIcon,
  MenuIcon,
  PencilIcon,
  PhotographIcon,
  TrashIcon,
} from '@heroicons/react/outline';

import useLocalUserSettings from 'hooks/useLocalUserSettings';
import {AccountRole} from 'models/users/User';

const ItemOptionsDropdown = ({
  addPhoto,
  addNote,
  addAction,
  deleteItem,
}: {
  addPhoto: () => void;
  addNote: () => void;
  addAction: () => void;
  deleteItem: () => void;
}) => {
  const {activeAccountRole} = useLocalUserSettings();
  return (
    <Menu as="div">
      <Menu.Button className="btn btn-neutral btn-xs md:btn-sm">
        <MenuIcon
          className="h-4 w-4 md:h-5 md:w-5 text-white"
          aria-hidden="true"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="px-1 py-1 ">
            <Menu.Item>
              {({active}) => (
                <button
                  onClick={addPhoto}
                  className={`${
                    active ? 'bg-secondary text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                  {active ? (
                    <PhotographIcon className="mr-2 h-5 w-5 text-white" />
                  ) : (
                    <PhotographIcon className="mr-2 h-5 w-5 text-primary" />
                  )}
                  Add Photos
                </button>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({active}) => (
                <button
                  onClick={addNote}
                  className={`${
                    active ? 'bg-info text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                  {active ? (
                    <PencilIcon className="mr-2 h-5 w-5 text-white" />
                  ) : (
                    <PencilIcon className="mr-2 h-5 w-5 text-info" />
                  )}
                  Add Note
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({active}) => (
                <button
                  onClick={addAction}
                  className={`${
                    active ? 'bg-warning text-white' : 'text-gray-900'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                  {active ? (
                    <ExclamationCircleIcon className="mr-2 h-5 w-5 text-white" />
                  ) : (
                    <ExclamationCircleIcon className="mr-2 h-5 w-5 text-warning" />
                  )}
                  Add Action
                </button>
              )}
            </Menu.Item>
          </div>
          {activeAccountRole === AccountRole.Landlord && (
            <div className="px-1 py-1">
              <Menu.Item>
                {({active}) => (
                  <button
                    onClick={deleteItem}
                    className={`${
                      active ? 'bg-error text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    {active ? (
                      <TrashIcon className="mr-2 h-5 w-5 text-white" />
                    ) : (
                      <TrashIcon className="mr-2 h-5 w-5 text-error" />
                    )}
                    Remove Item
                  </button>
                )}
              </Menu.Item>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ItemOptionsDropdown;
