import {useMemo} from 'react';

import {PencilIcon, TrashIcon} from '@heroicons/react/outline';
import {TbUserCheck} from 'react-icons/tb';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {Card} from 'components_sb/layout';
import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import {Paragraph} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import PreferredTradesman from 'models/service_requests/PreferredTradesman';
import {Page, RouterLink} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';

const PreferredTradesmanIndexPage = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const queryClient = useQueryClient();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  // PUll property to make sure they have permission to access this property
  // Todo: This should be one API call. Use an includes
  const {isLoading, error, data} = useQuery(
    `property-${propertyId}-preferred-tradespeople`,
    async () => {
      const tradies = await PreferredTradesman.where({propertyId}).all();
      const property = await Property.select(['street_address']).find(
        propertyId,
      );
      return {
        property: property.data,
        tradies: tradies.data,
      };
    },
  );

  const confirmDeleteTradie = async (tradie: PreferredTradesman) => {
    setConfirmationOptions({
      title: 'Remove Preferred Tradesperson',
      message: `Are you sure you want to remove ${tradie.name} as a preferred tradesperson?`,
      buttonTitle: 'Remove',
      action: () => deleteTradie(tradie),
      color: 'error',
    });
  };

  const deleteTradie = async (tradie: PreferredTradesman) => {
    const result = await tradie.destroy();
    if (result) {
      toast.success(
        `${tradie.name} has been removed as your preferred tradesperson.`,
      );
      queryClient.invalidateQueries(
        `property-${propertyId}-preferred-tradespeople`,
      );
    } else {
      toast.error(`There was an issue removing ${tradie.name}.`);
    }
  };

  const isMobile = !useTailwindBreakpoint('sm');

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !propertyId
        ? null
        : [
            {
              label: 'Add preferred tradesperson',
              linkTo: `/properties/${propertyId}/preferred-tradespeople/new`,
            },
          ],
    [propertyId],
  );

  return (
    <Page
      title="Preferred Tradespeople"
      loading={isLoading}
      error={error}
      actions={pageActions}>
      {() => (
        <Card title="Preferred Tradespeople">
          {data.tradies.length > 0 ? (
            <div>
              <Paragraph>
                These are your preferred tradespeople for the property at{' '}
                {data.property.streetAddress}
              </Paragraph>

              {isMobile ? (
                <ul>
                  {data.tradies.map((tradie) => (
                    <MobileListItem
                      key={tradie.id}
                      title={tradie.name}
                      subtitle={
                        <>
                          <span>{tradie.tradeCategory}</span>
                          <br />
                          <span>{tradie.phoneNumber}</span>
                          <br />
                          <span>{tradie.email}</span>
                        </>
                      }
                      link={`${tradie.id}/edit`}
                      icon={TbUserCheck}
                    />
                  ))}
                </ul>
              ) : (
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Trade Category</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.tradies.map((tradie) => (
                      <tr key={tradie.id} className="hover">
                        <td>{tradie.name}</td>
                        <td>{tradie.tradeCategory}</td>
                        <td>{tradie.phoneNumber}</td>
                        <td>{tradie.email}</td>
                        <td>
                          <div className="flex justify-center">
                            <RouterLink href={`${tradie.id}/edit`}>
                              <PencilIcon className="w-6 h-6 text-secondary" />
                            </RouterLink>
                            <TrashIcon
                              className="w-6 h-6 ml-3 text-secondary cursor-pointer"
                              onClick={() => confirmDeleteTradie(tradie)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <Paragraph>
              You do not currently have any preferred tradespeople for the
              property at {data.property.streetAddress}.
            </Paragraph>
          )}
        </Card>
      )}
    </Page>
  );
};

export default PreferredTradesmanIndexPage;
