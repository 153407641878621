import {Router} from 'framework7/types';

import AccountingRecordIndexPage from 'pages/landlord/accounting/AccountingRecordIndexPage';
import LandlordPaymentDetailPage from 'pages/landlord/landlord_payments/LandlordPaymentDetailPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {LOGGED_IN, IS_LANDLORD} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/financials',
    id: 'Financials',
    async: resolveRoute({
      conditions: [LOGGED_IN, IS_LANDLORD],
      component: AccountingRecordIndexPage,
    }),
    routes: [
      {
        path: ':paymentId',
        id: 'PaymentDetail',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_LANDLORD],
          component: LandlordPaymentDetailPage,
        }),
      },
    ],
  },
];

export default routes;
