import {Capacitor} from '@capacitor/core';
import {
  GoogleAuth,
  type User as GoogleUser,
} from '@codetrix-studio/capacitor-google-auth';

import {SocialPlatformAuthFunction} from '../authenticate';

/**
 * The object that is passed on the onSuccess function.
 */
export interface GoogleUserData {
  data: {
    token: string;
  };
}

const authenticateNative: SocialPlatformAuthFunction = async (
  onSuccess,
  onError,
) => {
  GoogleAuth.initialize();

  /**
   * Handle the response from succesful authentication and pass
   * releveant data to the callback function.
   */
  const handleSuccess = (tokenResponse: GoogleUser) => {
    if (tokenResponse && tokenResponse.authentication) {
      onSuccess({
        data: {
          token: tokenResponse.authentication.accessToken,
        },
      });
    } else {
      onError();
    }
  };

  const googleUser = await GoogleAuth.signIn();
  handleSuccess(googleUser);
};

const authenticateWeb: SocialPlatformAuthFunction = (onSuccess, onError) => {
  /**
   * Ensure the Google Identity Services script has been loaded
   */
  if (!(<any>window).google) {
    onError();
    return;
  }

  const {google} = <any>window;

  /**
   * Handle the response from succesful authentication and pass
   * releveant data to the callback function.
   */
  const handleSuccess = (tokenResponse: any) => {
    if (tokenResponse && tokenResponse.access_token) {
      onSuccess({
        data: {
          token: tokenResponse.access_token,
        },
      });
    } else {
      onError();
    }
  };

  /**
   * Create a new token client.
   */
  const client = google.accounts.oauth2.initTokenClient({
    client_id:
      '469157937967-86ntkk49m2mjqbf571u9mmkdqh0dae18.apps.googleusercontent.com',
    scope: `openid profile email`,
    callback: handleSuccess,
    error_callback: onError,
  });

  /**
   * Trigger the authentication flow.
   */
  client.requestAccessToken();
};

export const authenticate = Capacitor.isNativePlatform()
  ? authenticateNative
  : authenticateWeb;
