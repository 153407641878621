import {useEffect, useState} from 'react';

import {Device} from '@capacitor/device';
import {PreviewCamera} from '@numbersprotocol/preview-camera';

const CameraUnderlay = () => {
  const [isVirtual, setIsVirtual] = useState(true);

  useEffect(() => {
    Device.getInfo().then((info) => {
      setIsVirtual(info.isVirtual);
    });
  }, []);

  useEffect(() => {
    if (!isVirtual) {
      PreviewCamera.startPreview();
      PreviewCamera.setQuality({quality: 'hq'});
    }

    return () => {
      if (!isVirtual) {
        PreviewCamera.stopPreview();
      }
    };
  }, [isVirtual]);

  return <div></div>;
};

export default CameraUnderlay;
