import {useCallback, useImperativeHandle, useMemo} from 'react';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {SHOW_BILLING_COMMS} from 'globals/app-globals';
import Property from 'models/properties/Property';
import useRouter from 'router/hooks/useRouter';

const SubscriptionRequiredModal: ModalDefinition = {
  title: 'Property requires attention',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'view-account',
        label: 'Continue',
        handle: 'onContinue',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    /**
     * Deconstruct and cast the property instance from props.
     */
    const property = props.property as Property;

    /**
     * Determine whether the current tenancy for the property is in draft mode.
     */
    const currentTenancyIsDraft = useMemo<boolean>(
      () => property?.currentTenancy?.isDraft,
      [property],
    );

    const router = useRouter();

    /**
     * Handle the user clicking the continue button.
     */
    const onContinue = useCallback(async () => {
      router.navigate(
        currentTenancyIsDraft
          ? `/properties/${property.id}/setup`
          : '/account/billing',
      );
    }, [router, currentTenancyIsDraft, property?.id]);

    useImperativeHandle(ref, () => ({
      onContinue,
    }));

    return (
      <>
        {currentTenancyIsDraft ? (
          <>
            <Paragraph>
              Sorry! This action cannot be performed because you have not
              finished setting up your tenancy.
            </Paragraph>
            <Paragraph>Click the button below to continue.</Paragraph>
          </>
        ) : (
          <>
            {SHOW_BILLING_COMMS ? (
              <>
                <Paragraph>
                  Sorry! This action cannot be performed without an active
                  Keyhook subscription.
                </Paragraph>
                <Paragraph>
                  Click the button below to continue and view your subscription
                  options.
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>
                  Sorry! This action cannot be performed due to an issue with
                  your account.
                </Paragraph>
                <Paragraph>
                  Please log in to Keyhook on your computer or laptop to view
                  your options.
                </Paragraph>
              </>
            )}
          </>
        )}
      </>
    );
  },
};

export default SubscriptionRequiredModal;
