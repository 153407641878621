import React from 'react';

import moment from 'moment';
import {RiShieldUserLine} from 'react-icons/ri';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const ParoleBoardResultsCard = ({report}: {report: TenantCheckReport}) => {
  // Uncomment to have some example data to test against
  // report.paroleBoardResults = {
  //   search_url: '',
  //   results: [
  //     {
  //       title: 'Aaron Rama is a on parole',
  //       link: 'http://google.com',
  //       description: "Best watch yo'selves",
  //       date: '2023-09-09',
  //     },
  //   ],
  // };
  if (report.paroleBoardResultsFetchedAt) {
    return (
      <Card title="Parole Board" icon={RiShieldUserLine}>
        {report.paroleBoardResults.results.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Title</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.paroleBoardResults.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  {moment(result.date).format(DATE_FORMAT)}
                </TableRowItem>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.title}
                  </Hyperlink>
                  <p className="text-sm">{result.description}</p>
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the parole board
            database.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.paroleBoardResultsFetchedAt).format(DATE_TIME_FORMAT)}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Parole Board" icon={RiShieldUserLine}>
        <Paragraph>
          Parole Board results are currently being collected and will be updated
          on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default ParoleBoardResultsCard;
