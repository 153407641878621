import {useCallback, useMemo} from 'react';

import {useFormik} from 'formik';
import {isMobile} from 'react-device-detect';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {TextField} from 'components_sb/input';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph, Title} from 'components_sb/typography';
import {Page} from 'router/components';
import useRouter from 'router/hooks/useRouter';
import {emailIsApplePrivateRelay} from 'utilities/apple-private-relay';

type FormValues = {
  name: string;
  email: string;
};

const EnterMissingAccountDetailsPage = () => {
  const router = useRouter();

  const {currentUser, updateName, updateUser} = useAuth();

  const pendingEmail = useMemo<string | null>(
    () => currentUser.unconfirmedEmail,
    /**
     * We don't want dependencies here as we want to record the state
     * at page mount.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Whether the user has a second email address awaiting confirmation.
   */
  const hasPendingEmail = useMemo<boolean>(
    () => pendingEmail?.length > 0,
    [pendingEmail],
  );

  /**
   * Whether the user has signed up using Apple's private email relay service.
   */
  const isUsingApplePrivateRelay = useMemo(
    () => emailIsApplePrivateRelay(currentUser.email),
    /**
     * We don't want dependencies here as we want to record the state
     * at page mount.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Whether we should require the user to enter an email address.
   */
  const requireEmail = useMemo(
    () => isUsingApplePrivateRelay || !currentUser.email,
    /**
     * We don't want dependencies here as we want to record the state
     * at page mount.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * Whether we should require the user to enter their name.
   */
  const requireName = useMemo(
    () => !currentUser.name,
    /**
     * We don't want dependencies here as we want to record the state
     * at page mount.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = useCallback(
    async ({email, name}: FormValues) => {
      /**
       * Update the user's name if it has changed.
       */
      if (name !== currentUser.name) {
        await updateName(name);
      }

      /**
       * Update the user's email address if it has changed.
       */
      if (email !== currentUser.email) {
        await updateUser(
          {name: name ?? currentUser.name, email},
          {useJsonApi: true},
        );
      }

      /**
       * Navigate the user to the page to confirm their new emails.
       */
      if (isUsingApplePrivateRelay) {
        router.navigate('/register/confirm-email');
      }
    },
    [currentUser, updateName, updateUser, isUsingApplePrivateRelay, router],
  );

  const form = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      name: requireName ? '' : currentUser.name,
      email: requireEmail
        ? hasPendingEmail
          ? currentUser.unconfirmedEmail
          : ''
        : currentUser.email,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please enter your full name'),
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Please enter your real email address'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Page title="Account Details" disableNavbar centred>
      {() => (
        <div className="w-full max-w-2xl flex flex-col items-start mx-auto">
          <div className="flex flex-col gap-y-8 mb-8">
            <div className="flex flex-col">
              <Title level="h2" size="lg">
                Almost there!
              </Title>
              <Paragraph>
                We just need a couple more details to finish setting up your
                account.
              </Paragraph>
              <Paragraph secondary>
                You can use Keyhook to submit paperwork to Tenancy & Bond
                Services and perform other important actions so it's important
                that we have valid information.
              </Paragraph>
              <Paragraph secondary>
                You may view our{' '}
                <Hyperlink
                  href="https://www.keyhook.com/privacy-policy/"
                  openInNewTab>
                  Privacy Policy here.
                </Hyperlink>
              </Paragraph>
            </div>
            {/* Missing name */}
            {requireName && (
              <div className="flex flex-col gap-y-2">
                <Paragraph>
                  We don't currently have your full name set on your account,
                  please enter it below.
                </Paragraph>
                <TextField
                  name="name"
                  label="Full name"
                  description="As it appears on your passport or drivers licence"
                  placeholder="E.g. Jane Doe"
                  required
                  size="base"
                  mode="formik"
                  form={form}
                />
              </div>
            )}
            {/* Missing email or using Apple's private email relay service*/}
            {requireEmail && (
              <div className="flex flex-col gap-y-2">
                {isUsingApplePrivateRelay ? (
                  <Paragraph>
                    You have used a social login to register, which means we
                    were unable to collect an email address for your account.
                    Please enter a valid email address below.
                  </Paragraph>
                ) : (
                  <Paragraph>
                    We don't currently have your email address set on your
                    account, please enter it below.
                  </Paragraph>
                )}
                <Alert
                  asLink
                  linkTo="/register/confirm-email"
                  show={form.values['email'] === pendingEmail}
                  type="info"
                  title="The email address below is pending confirmation."
                  description={`${
                    isMobile ? 'Tap' : 'Click'
                  } here to enter the code we have sent you to confirm it.`}
                />
                <TextField
                  name="email"
                  label="Email address"
                  placeholder="E.g. jane.doe@gmail.com"
                  required
                  size="base"
                  mode="formik"
                  form={form}
                />
              </div>
            )}
          </div>
          <Button
            form={form}
            label="Save details"
            loadingLabel="Saving details..."
            category="primary"
            size="base"
            mode="formik"
            fillWidth
          />
        </div>
      )}
    </Page>
  );
};

export default EnterMissingAccountDetailsPage;
