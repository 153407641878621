import React, {useCallback, useMemo, useState} from 'react';

import {useQueryClient} from 'react-query';

import advertiseIcon from 'assets/img/onboarding/onboarding_types/advertise.png';
import newIcon from 'assets/img/onboarding/onboarding_types/new.png';
import ActionButton from 'components/property/landlord/detail_page_cards/MainActions/ActionButton';
import {InlineError} from 'components_sb/feedback';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {OBFS, OnboardingFlow} from 'constants/onboarding-flow-steps';
import useRouter from 'router/hooks/useRouter';
import {saveResource} from 'utilities/SpraypaintHelpers';

const PrepareNextTenancyModal: ModalDefinition = {
  title: 'Prepare next tenancy',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [],
  },
  ContentComponent: (props) => {
    const {closeModal, property} = props;

    const [errorMessages, setErrorMessages] = useState('');
    const queryClient = useQueryClient();

    const router = useRouter();

    const handleAction = useCallback(
      async (flow: OnboardingFlow.NewListing | OnboardingFlow.NewTenancy) => {
        /**
         * Set changes on the property.
         */
        property.setOnboardingFlow(flow);
        property.setOnboardingStepAsCompleted(OBFS.SelectOnboardingType);

        /**
         * Save the changes to the property.
         */
        const savedProperty = await saveResource(property);

        if (savedProperty) {
          queryClient.invalidateQueries([
            'property',
            {id: property.id, context: 'detail-page'},
          ]);

          queryClient.invalidateQueries([
            'property',
            {id: property.id, context: 'onboarding-flow'},
          ]);

          router.navigate(`/properties/${property.id}/setup`);

          closeModal();
        } else {
          let errorString = '';
          for (const key in property.errors) {
            const error = property.errors[key];
            errorString += error.message + ' ';
          }
          setErrorMessages(errorString);
        }
      },
      [closeModal, router, property, queryClient],
    );

    const selectOnboardingTypeActions = useMemo(
      () => [
        {
          label: 'I want to find a tenant',
          description:
            'Advertise your rental, host viewings, conduct tenant checks, create a tenancy agreement and file a bond.',
          icon: advertiseIcon,
          onClick: () => handleAction(OnboardingFlow.NewListing),
        },
        {
          label: "I have found a tenant but I don't have a lease",
          description:
            'Create and e-sign a tenancy agreement, file a bond, conduct an initial inspection and begin self-managing.',
          icon: newIcon,
          onClick: () => handleAction(OnboardingFlow.NewTenancy),
        },
      ],
      [handleAction],
    );

    return (
      <div>
        <Paragraph>
          If your current tenancy will not be continuing after the end date, you
          can choose an option below to prepare your next tenancy for this
          property.
        </Paragraph>

        <div className="flex flex-col lg:flex-row gap-4">
          {selectOnboardingTypeActions.map((action) => (
            <ActionButton key={action.label} action={action} />
          ))}
        </div>

        <div className="mt-2">
          {errorMessages.length > 0 && <InlineError error={errorMessages} />}
        </div>
      </div>
    );
  },
};

export default PrepareNextTenancyModal;
