import React from 'react';

import {CheckIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {HiOutlineClipboardCheck} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Listing from 'models/listings/Listing';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import RentalApplication from 'models/listings/RentalApplication';
import {AccountRole} from 'models/users/User';
import useRouter from 'router/hooks/useRouter';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

const ListingApplicationCard = ({listing}: {listing: Listing}) => {
  const {currentUser} = useAuth();

  const {activeAccountRole} = useLocalUserSettings();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const router = useRouter();

  const {data: rentalApplication, isLoading: rentalApplicationIsLoading} =
    useQuery(
      'user-rental-application-minmal',
      async () => {
        const rentalApp = await RentalApplication.order({
          createdAt: 'desc',
        })
          .includes('rental_application_applicants')
          .select({
            rental_applications: ['id', 'created_at'],
            rental_application_applicants: ['id', 'name'],
          })
          .first();

        return rentalApp.data;
      },
      {enabled: !!currentUser},
    );

  const {data: joinModel, isLoading: joinModelIsLoading} = useQuery(
    `rental-application-listing-${listing.id}`,
    async () => {
      const join = await ListingRentalApplication.where({
        listing_id: listing.id,
        rental_application_id: rentalApplication.id,
      }).first();

      return join.data;
    },
    {enabled: !!rentalApplication},
  );

  const confirmApplyForListing = async () => {
    setConfirmationOptions({
      title: 'Apply for Property',
      message: 'Are you sure you want to apply for this property?',
      color: 'success',
      buttonTitle: 'Apply',
      action: applyForListing,
    });
  };

  const applyForListing = async () => {
    const join = new ListingRentalApplication();
    join.listingId = listing.id;
    join.rentalApplicationId = rentalApplication.id;

    const result = await join.save();

    if (result) {
      toast.success('You have successfully applied for this property!');

      queryClient.invalidateQueries(`rental-application-listing-${listing.id}`);
    } else {
      console.log(join.errors);
    }
  };

  const createApplication = () => {
    localStorage.setItem(
      'listing-application-info',
      JSON.stringify({
        id: listing.id,
        publicId: listing.publicId,
      }),
    );

    router.navigate('/rental-applications/new');
  };

  return (
    <Card icon={HiOutlineClipboardCheck} title="Apply">
      {/* Listing has closed */}
      {listing.isHistorical && (
        <Paragraph>
          Applications cannot be made for inactive listings.
        </Paragraph>
      )}
      {currentUser ? (
        // User is logged in
        <>
          {activeAccountRole === AccountRole.Renter && (
            // User is a tenant
            <>
              {rentalApplicationIsLoading ? (
                // Loading tenant's rental application
                <LoadingView />
              ) : (
                <>
                  {rentalApplication ? (
                    // Tenant has filled in a rental application
                    <>
                      {joinModelIsLoading ? (
                        // Loading whether tenant has applied for the listing
                        <LoadingView />
                      ) : (
                        <>
                          {joinModel ? (
                            // Tenant has already applied for the listing
                            <div className="flex justify-start gap-2 items-center !mt-0">
                              <CheckIcon className="flex-shrink-0 w-4 h-4 text-white bg-success rounded-full" />
                              <div>
                                <Paragraph>
                                  You applied for this property on{' '}
                                  {moment(joinModel.createdAt).format(
                                    'DD MMMM',
                                  )}
                                  .
                                </Paragraph>
                                {rentalApplication.rentalApplicationApplicants
                                  .length > 0 && (
                                  <Paragraph>
                                    With:{' '}
                                    {rentalApplication.rentalApplicationApplicants
                                      .map((a) => a.name)
                                      .join(', ')}
                                  </Paragraph>
                                )}
                              </div>
                            </div>
                          ) : (
                            // Tenant has not yet applied for the listing
                            <>
                              <Paragraph>
                                Click the button below to apply for this
                                property. This will send your most recent
                                application to the landlord.
                              </Paragraph>
                              <Button
                                label="Apply"
                                category="primary"
                                size="base"
                                mode="manual"
                                onClick={confirmApplyForListing}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    // Tenant has not yet filled in a rental application
                    <>
                      <Paragraph>
                        You have not created a rental application yet. You only
                        need to create one once to apply for properties.
                      </Paragraph>
                      <Paragraph>
                        Click the button below to set up your rental
                        application.
                      </Paragraph>
                      <Button
                        label="Create Application"
                        category="primary"
                        size="base"
                        mode="manual"
                        onClick={createApplication}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {activeAccountRole !== AccountRole.Renter && (
            // User is not a tenant
            <Paragraph>
              {`You cannot apply for a property with a ${activeAccountRole} account.`}
            </Paragraph>
          )}
        </>
      ) : (
        // User is not logged in
        <>
          <Paragraph>
            Click the button below to apply for this property.
          </Paragraph>
          <Button
            label="Apply"
            category="primary"
            size="base"
            mode="manual"
            onClick={createApplication}
          />
        </>
      )}
    </Card>
  );
};

export default ListingApplicationCard;
