import {
  SignInWithApple,
  type SignInWithAppleResponse,
  type SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import {TARGET_ENV} from 'globals/app-globals';

import {SocialPlatformAuthFunction} from '../authenticate';

/**
 * The response returned upon successful authentication.
 */
type SignInResponse = {
  authorization: {
    state: string;
    code: string;
    id_token: string;
  };
  user: {
    email: string | null | undefined;
    name: {
      firstName: string | null | undefined;
      lastName: string | null | undefined;
    };
  };
};

/**
 * The object that is passed on the onSuccess function.
 */
export interface AppleUserData {
  name: string | null;
  jwt: string;
}

const authenticateNative: SocialPlatformAuthFunction = (onSuccess, onError) => {
  /**
   * Set the redirect URI to the current page origin without the path.
   */
  const redirectURI = window.location.origin;

  /**
   * Determine the client ID to use based on the environment.
   */
  let clientId: string;
  if (TARGET_ENV === 'staging') {
    clientId = 'com.keyhook.staging-app';
  } else if (TARGET_ENV === 'production') {
    clientId = 'com.keyhook.app';
  } else {
    clientId = 'com.keyhook.dev-app';
  }

  /**
   * Handle the response from succesful authentication and pass
   * releveant data to the callback function.
   */
  const handleSuccess = () => (response: SignInWithAppleResponse) => {
    if (response.response && response.response.identityToken) {
      const jwt = response.response.identityToken;

      const name = response.response.user
        ? response.response.givenName + ' ' + response.response.familyName
        : null;

      onSuccess({name, jwt});
    } else {
      onError();
    }
  };

  const options: SignInWithAppleOptions = {
    clientId: clientId,
    redirectURI: redirectURI,
    scopes: 'email name',
    state: 'state',
    nonce: 'nonce',
  };

  /**
   * Trigger the authentication flow.
   */
  SignInWithApple.authorize(options).then(handleSuccess).catch(onError);
};

export const authenticate = authenticateNative;
