import {FunctionComponent, useCallback, useEffect, useMemo} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {AuthErrorCode, errorIsAuthErrorCodes} from 'auth/errors';
import useAuth from 'auth/provider/useAuth';
import {SocialPlatform} from 'auth/social/platforms';
import useSocialAuthScripts from 'auth/social/useSocialAuthScripts';
import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {NATIVE_LAYOUT_IN_WEB} from 'globals/app-globals';
import Page from 'router/components/Page';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

interface LoginPageProps {
  redirect?: string;
}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const {login} = useAuth();

  const router = useRouter();
  const route = useRoute();

  /**
   * Check for a redirect URL from the route props, and if not present, check
   * for a redirect URL in the query params, otherwise default to redirecting
   * to the index page.
   */
  const redirect = useMemo(
    () => route.props.redirect ?? route.query.redirect ?? '/',
    [route.props.redirect, route.query],
  );

  useSocialAuthScripts();

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue logging you in. Please try again later or contact us for support.`,
    );
  }, []);

  const handleRedirect = useCallback(async () => {
    if (redirect.startsWith('http')) {
      await Browser.open({url: redirect});
    } else {
      /**
       * Clear history from the logged-out state and navigate to the dashboard.
       */
      router.navigate(redirect, {
        reloadAll: true,
        transition:
          Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB
            ? 'f7-cover-v'
            : undefined,
      });
    }
  }, [redirect, router]);

  const handleFormSubmit = useCallback(
    async (formData: any, formikHelpers: any) => {
      try {
        await login({method: 'email', loginData: {user: formData}});
        handleRedirect();
      } catch (error) {
        /**
         * Check if the error is an array of AuthErrorCode values.
         */
        if (errorIsAuthErrorCodes(error)) {
          const authErrorCodes = error;
          if (authErrorCodes.includes(AuthErrorCode.INVALID_CREDENTIALS)) {
            /**
             * Handle invalid email / password.
             */
            formikHelpers.setFieldError('email', true);
            formikHelpers.setFieldError(
              'password',
              'Incorrect email or password',
            );
          } else {
            /**
             * Unknown error code.
             */
            showGenericError();
          }
        } else {
          /**
           * Error performing the registration request.
           */
          showGenericError();
        }
        /**
         * Reset the submitting state.
         */
        formikHelpers.setSubmitting(false);
      }
    },
    [login, showGenericError, handleRedirect],
  );

  const onSocialAuthSuccess = useCallback(
    async (platform: SocialPlatform, data: any) => {
      try {
        /**
         * Attempt the login request.
         */
        await login({method: platform, loginData: data});

        handleRedirect();
      } catch (error) {
        /**
         * Error performing the login request.
         */
        showGenericError();
      }
    },
    [login, showGenericError, handleRedirect],
  );

  const onSocialAuthError = useCallback(() => {
    showGenericError();
  }, [showGenericError]);

  const form = useFormik({
    onSubmit: handleFormSubmit,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
      password: Yup.string().required('Please enter your password'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Page title="Log in" backUrl="/" centred>
      {() => (
        <AuthFormLayout
          title="Log In"
          socialAuthButtonConfig={{
            action: 'login',
            onSuccess: onSocialAuthSuccess,
            onError: onSocialAuthError,
          }}>
          <form className="flex flex-col items-center gap-y-8">
            <div className="w-full flex flex-col gap-y-8">
              <TextField
                name="email"
                label="Email"
                type="email"
                size="base"
                mode="formik"
                form={form}
                placeholder="Enter your email address here..."
                required
              />
              <TextField
                name="password"
                label="Password"
                type="password"
                size="base"
                mode="formik"
                form={form}
                placeholder="Enter your password here..."
                required
              />
            </div>
            <Button
              label="Log in"
              category="primary"
              size="base"
              mode="formik"
              form={form}
              loadingLabel="Logging in..."
            />
          </form>
          <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
            <Hyperlink persistQueryParams back force reloadAll href="/">
              Don't have an account yet?
            </Hyperlink>
            <Hyperlink persistQueryParams href="/reset-password">
              Forgot your password?
            </Hyperlink>
          </span>
        </AuthFormLayout>
      )}
    </Page>
  );
};

export default LoginPage;
