import {ReactNode, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {motion} from 'framer-motion';

import {SocialPlatform} from 'auth/social/platforms';
import {
  SocialAuthButton,
  type SocialAuthButtonConfig,
} from 'components_sb/buttons';
import {Divider} from 'components_sb/layout';
import {Title} from 'components_sb/typography';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';

interface appPreviewConfig {
  screenshot: string;
}

interface AuthFormLayoutProps {
  /**
   * The main title for the page/form.
   */
  title: string;
  /**
   * A brief description of the page/form.
   */
  description?: string;
  /**
   * Additional content to display below the title.
   */
  supportingContent?: ReactNode;
  /**
   * Configuration for the social authentication buttons.
   */
  socialAuthButtonConfig?: SocialAuthButtonConfig;
  /**
   * Configuration for the mobile app preview.
   */
  appPreviewConfig?: appPreviewConfig;
  /**
   * Intended to be a renderable instance of a Formik form component.
   */
  children: ReactNode;
}

const AuthFormLayout = ({
  title,
  description,
  supportingContent,
  socialAuthButtonConfig,
  appPreviewConfig,
  children,
}: AuthFormLayoutProps) => {
  const layoutHorizontal = useTailwindBreakpoint('xl');

  /**
   * Providing a configuration for the social auth buttons or the mobile app
   * previes implies that the respective feature/section should be shown.
   */
  const showSocialAuth = useMemo(
    () => !!socialAuthButtonConfig,
    [socialAuthButtonConfig],
  );
  const showAppPreview = useMemo(() => !!appPreviewConfig, [appPreviewConfig]);

  const authProviders = useMemo(() => {
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
      return [SocialPlatform.Google];
    } else {
      // Add facebook back in once working again.
      return [SocialPlatform.Google, SocialPlatform.Apple];
    }
  }, []);

  return (
    <div
      className={clsx(
        'bg-white w-full min-h-content flex flex-col justify-center items-center',
      )}>
      <div
        className={clsx(
          'flex-1 w-full flex flex-row justify-center gap-x-12',

          // Max width when NEITHER social auth and app preview are enabled
          !showSocialAuth && !showAppPreview && 'max-w-2xl',

          // Max width when EITHER social auth and app preview are enabled
          showSocialAuth !== showAppPreview &&
            'max-w-xl lg:max-w-2xl xl:max-w-5xl',

          // Max width when BOTH social auth and app preview are enabled
          showSocialAuth &&
            showAppPreview &&
            'max-w-xl lg:max-w-4xl xl:max-w-7xl',
        )}>
        <div className="flex-1 flex flex-col justify-center">
          <div className="flex flex-col-reverse xl:flex-row">
            {/* Primary form section */}
            <div className="flex-1">
              <div className="flex-1 flex flex-col justify-center gap-y-8">
                {/* Primary form */}
                {children}
              </div>
            </div>

            {showSocialAuth && (
              <>
                {/* Divider to separate the email and social auth sections */}
                <Divider
                  orientation={layoutHorizontal ? 'vertical' : 'horizontal'}
                  labelPosition={layoutHorizontal ? 'start' : 'middle'}>
                  OR
                </Divider>

                {/* Social auth section */}
                <div className="flex-1 flex flex-col gap-y-4 xl:gap-y-8 justify-start mt-0 xl:mt-7 max-w-none xl:max-w-sm">
                  {authProviders.map((platform) => (
                    <SocialAuthButton
                      key={platform}
                      platform={platform}
                      config={socialAuthButtonConfig}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>

        {/* Mobile app preview */}
        {showAppPreview && (
          <div className="w-60 lg:w-72 xl:w-80 pt-20 flex-col items-center justify-start hidden lg:flex">
            <motion.div
              className="flex flex-col gap-y-2 items-center"
              initial={{opacity: 0, y: 25}}
              animate={{opacity: 1, y: 0}}
              transition={{delay: 0.2, duration: 0.5, ease: 'easeOut'}}>
              <img
                src={appPreviewConfig.screenshot}
                className="drop-shadow-2xl"
              />
              <Paragraph secondary>View your properties at a glance</Paragraph>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthFormLayout;
