import React from 'react';

import moment from 'moment';
import {MdOutlineGavel} from 'react-icons/md';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import {
  CourtDecisionResult,
  DistrictCourtResult,
  JusticeDecisionResult,
} from 'models/tenant_checks/ReportTypes';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const GovtJusticeResultsCard = ({report}: {report: TenantCheckReport}) => {
  // Uncomment to have some example data to test against
  // report.govtJusticeResults = {
  //   courts_decisions: {
  //     search_url: '',
  //     results: [
  //       {
  //         title: 'Aaron Rama vs Sam Nicholls',
  //         link: 'http://google.com',
  //         description: '',
  //       },
  //     ],
  //   },
  //   justice_decisions: {
  //     search_url: '',
  //     results: [
  //       {
  //         title: 'Aaron Rama vs Sam Nicholls',
  //         link: 'http://google.com',
  //       },
  //     ],
  //   },
  //   district_courts: {
  //     search_url: '',
  //     results: [
  //       {
  //         title: 'Aaron Rama vs Sam Nicholls',
  //         link: 'http://google.com',
  //         description: '',
  //       },
  //     ],
  //   },
  // };

  if (report.govtJusticeResultsFetchedAt) {
    const allResults = [
      ...report.govtJusticeResults.courts_decisions.results,
      ...report.govtJusticeResults.district_courts.results,
      ...report.govtJusticeResults.justice_decisions.results,
    ] as (JusticeDecisionResult | CourtDecisionResult | DistrictCourtResult)[];

    return (
      <Card title="Courts" icon={MdOutlineGavel}>
        {allResults.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Case Name</TableHeadItem>
              <></>
            </TableHead>

            {/* Table rows */}
            {allResults.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.title}
                  </Hyperlink>
                </TableRowItem>
                <></>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the courts
            databases.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.govtJusticeResultsFetchedAt).format(DATE_TIME_FORMAT)}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Courts" icon={MdOutlineGavel}>
        <Paragraph>
          Court case results are currently being collected and will be updated
          on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default GovtJusticeResultsCard;
