import {useCallback, useImperativeHandle, useState} from 'react';

import Decimal from 'decimal.js';
import {useFormik, type FormikProps} from 'formik';
import {isEmpty} from 'lodash';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {DateField, InputField, SelectField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import MoneyField from 'components/forms_fields/MoneyField';
import {InlineError} from 'components_sb/feedback';
import {TextAreaField} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import AccountingReceipt from 'models/accounting/AccountingReceipt';
import AccountingRecord from 'models/accounting/AccountingRecord';
import Property from 'models/properties/Property';
import TrackingService from 'services/TrackingService';
import {roundMoney} from 'utilities/MathHelpers';

interface FormReceipt {
  attachment: string;
}

interface FormValues {
  date: string;

  propertyId: string;

  subtotalAmount: Decimal | null;
  gstAmount: Decimal | null;
  totalAmount: Decimal | null;

  description: string;
  notes: string;

  accountingReceipts: FormReceipt[];
}

const AddEditAccountingRecordModal: ModalDefinition = {
  title: 'Add Transaction',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onExport',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);

    const {closeModal, accountingRecord} = props;

    const model = accountingRecord as AccountingRecord;

    const {authCookies} = useAuth();
    const queryClient = useQueryClient();

    const handleSubmit = useCallback(
      async (values: FormValues, formikHelpers: any) => {
        const attrs = {...values};

        delete attrs['accountingReceipts'];

        model.assignAttributes(attrs);

        for (const attachmentData of values.accountingReceipts) {
          const receipt = new AccountingReceipt();
          receipt.attachment = attachmentData.attachment;

          model.accountingReceipts.push(receipt);
        }

        const result = await model.save({with: 'accountingReceipts'});

        if (result) {
          await queryClient.invalidateQueries('landlord-accounting-stats');
          await queryClient.invalidateQueries('landlord-accounting-table');
          toast.success('Transaction sucessfully added');

          TrackingService.trackEvent(TrackingService.Event.AddAccountingRecord);

          closeModal();
        } else {
          for (const attachment of model.accountingReceipts) {
            console.log(attachment.errors);
          }
          for (const key of Object.keys(model.errors)) {
            const message = model.errors[key].fullMessage;
            formikHelpers.setFieldError(key, message);
          }
          formikHelpers.setSubmitting(false);
        }
      },
      [closeModal, model, queryClient],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        date: model.date || '',
        propertyId: model.propertyId || '',
        subtotalAmount: model.subtotalAmount
          ? new Decimal(model.subtotalAmount)
          : null,
        gstAmount: model.gstAmount ? new Decimal(model.gstAmount) : null,
        totalAmount: model.totalAmount ? new Decimal(model.totalAmount) : null,
        description: model.description || '',
        notes: model.notes || '',
        accountingReceipts: [] as FormReceipt[],
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        date: Yup.date().required().label('Date'),
        description: Yup.string().required().min(4).label('Description'),
        notes: Yup.string().nullable().label('Notes'),
        subtotalAmount: Yup.number().required().nullable().label('Subtotal'),
        gstAmount: Yup.number().required().nullable().optional().label('GST'),
        // .test(
        //   'is valid amount',
        //   'GST must be zero or equal to 15% of the subtotal',
        //   function (value) {
        //     const val = new Decimal(value);
        //     const subtotal = new Decimal(this.parent.subtotalAmount);

        //     return (
        //       val.equals(new Decimal(0)) ||
        //       val.equals(subtotal.mul(0.15).abs().toDecimalPlaces(2))
        //     );
        //   },
        // ),
        totalAmount: Yup.number()
          .required()
          .label('Total')
          .nullable()
          .optional()
          .test(
            'is valid amount',
            'Total should equal subtotal plus gst',
            function (value) {
              const val = new Decimal(value).abs();
              const subtotal = new Decimal(this.parent.subtotalAmount).abs();
              const gst = new Decimal(this.parent.gstAmount).abs();

              return val.equals(subtotal.add(gst).toDecimalPlaces(2));
            },
          ),

        property_id: Yup.string().optional().nullable().label('Property'),
        accountingReceipts: Yup.array(
          Yup.object().shape({
            attachment: Yup.string().min(1).required().label('Receipt'),
          }),
        )
          .label('Receipts')
          .min(0),
      }),
    });

    const {data: properties} = useQuery(
      'landlord-financials-properties-add-transaction',
      async () => {
        const properties = await Property.all();
        return properties.data;
      },
      {
        onSuccess: (properties) => {
          if (!formik.values.propertyId && properties.length > 0) {
            formik.setFieldValue('propertyId', properties[0].id);
          }
        },
      },
    );

    const onExport = useCallback(async () => {
      const result = await formik.validateForm();
      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onExport,
    }));

    const processAttachments = async (
      formik: FormikProps<any>,
      attachments: FileList,
    ) => {
      setIsUploadingAttachment(true);

      const files = Array.from(attachments);

      const propertyId = formik.values.propertyId;

      for (const attachment of files) {
        const presignResponse = await fetch(
          `${API_URL}/presigns/accounting_receipt.json?property_id=${propertyId}&filename=${attachment.name}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          await fetch(presignInfo.url, {
            method: presignInfo.method,
            headers: presignInfo.headers as any,
            body: attachment,
          });

          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          const uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: attachment.size,
              filename: attachment.name,
              mime_type: attachment.type,
            },
          };

          const values: object[] = formik.values.accountingReceipts;
          values.push({attachment: JSON.stringify(uploadData)});

          formik.setFieldValue('accountingReceipts', values);
        }
      }

      setIsUploadingAttachment(false);
    };

    const removeAttachment = (formik: any, index: number) => {
      const values: object[] = formik.values.accountingReceipts;
      values.splice(index, 1);

      formik.setFieldValue('accountingReceipts', values);
    };

    return (
      <>
        <FormRow responsive>
          <FormRowItem>
            <DateField
              formik={formik}
              name="date"
              label="Date"
              helpText="The date this transaction took place."
              minDate={new Date('1900-01-01')}
              maxDate={new Date('2100-01-01')}
            />
          </FormRowItem>
          <FormRowItem>
            <SelectField
              name="property_id"
              formik={formik}
              labelProps={{title: 'Property'}}>
              {properties &&
                properties.length > 0 &&
                properties.map((prop) => (
                  <option key={prop.id} value={prop.id}>
                    {prop.streetAddress}
                  </option>
                ))}
            </SelectField>
          </FormRowItem>
        </FormRow>

        <FormRow responsive>
          <FormRowItem>
            <MoneyField
              name="subtotalAmount"
              label="Subtotal"
              allowNegative
              type="number"
              placeholder="E.g. 10.00"
              required
              helpText="The amount of the transaction excluding any gst."
              formik={formik}
              value={
                formik.values.subtotalAmount
                  ? formik.values.subtotalAmount.toString()
                  : ''
              }
              onValuesChange={(values) =>
                formik.setFieldValue(
                  'subtotalAmount',
                  new Decimal(values.value),
                )
              }
              onBlur={() => {
                const val = new Decimal(formik.values.subtotalAmount).abs();
                const gstNum = roundMoney(
                  new Decimal(val).mul(0.15).toNumber(),
                  2,
                );

                const gst = new Decimal(gstNum);

                formik.setFieldValue('gstAmount', gst);
                if (formik.values.subtotalAmount.toNumber() < 0) {
                  formik.setFieldValue(
                    'totalAmount',
                    val.add(gst).dividedBy(-1),
                  );
                } else {
                  formik.setFieldValue('totalAmount', val.add(gst));
                }
              }}
            />
          </FormRowItem>

          <FormRowItem>
            <MoneyField
              name="gstAmount"
              label="GST"
              type="number"
              placeholder="E.g. 1.50"
              helpText="Any GST incurred in this transaction. Can be 0.00"
              required
              formik={formik}
              value={
                formik.values.gstAmount
                  ? formik.values.gstAmount.toString()
                  : ''
              }
              onValuesChange={(values) =>
                formik.setFieldValue('gstAmount', new Decimal(values.value))
              }
              onBlur={() => {
                const val = new Decimal(formik.values.gstAmount).abs();
                const subtotal = new Decimal(formik.values.subtotalAmount);

                if (formik.values.subtotalAmount.toNumber() < 0) {
                  formik.setFieldValue(
                    'totalAmount',
                    -val.add(subtotal).dividedBy(-1),
                  );
                } else {
                  formik.setFieldValue('totalAmount', val.add(subtotal));
                }
              }}
            />
          </FormRowItem>

          <FormRowItem>
            <MoneyField
              name="totalAmount"
              label="Total"
              type="number"
              allowNegative
              helpText="The subtotal plus the gst amount"
              placeholder="E.g. 11.50"
              required
              formik={formik}
              value={
                formik.values.totalAmount
                  ? formik.values.totalAmount.toString()
                  : ''
              }
              onValuesChange={(values) =>
                formik.setFieldValue('totalAmount', new Decimal(values.value))
              }
            />
          </FormRowItem>
        </FormRow>

        <div className="mt-2">
          <Paragraph>
            Use a negative amount for subtotal / total to indicate an expense.
          </Paragraph>
        </div>

        <div>
          <InputField
            name="description"
            labelProps={{
              title: 'Description',
            }}
            placeholder="E.g. Rates"
            formik={formik}
          />
        </div>

        <TextAreaField
          rows={3}
          name="notes"
          label="Notes"
          placeholder="Any other notes you wish to include about this transaction."
          mode="formik"
          form={formik as any}
        />

        <div className="mt-2">
          <Title level="h6">Receipts</Title>
        </div>

        {isUploadingAttachment && (
          <span className="tw-block text-success">
            Processing attachments, please wait.
          </span>
        )}

        {formik.values.accountingReceipts.map((obj: any, index: number) => {
          const json = JSON.parse(obj.attachment);

          return (
            <div key={index}>
              <div className="flex justify-between items-center my-2">
                <strong>{json.metadata.filename}</strong>
                <button
                  className="btn w-auto btn-error btn-sm"
                  type="button"
                  onClick={() => removeAttachment(formik, index)}>
                  Remove
                </button>
              </div>
              <hr className="bg-gray-200 w-full" />
            </div>
          );
        })}

        <div className="mt-2">
          <input
            type="file"
            multiple
            accept=".png,.jpeg,.jpg,.pdf"
            id="attachments-input"
            onChange={(e) => processAttachments(formik, e.target.files)}
            className="hidden"
          />
          <button
            className="btn btn-neutral btn-sm"
            type="button"
            onClick={() =>
              document.getElementById('attachments-input').click()
            }>
            Select Files
          </button>
        </div>

        <InlineError error={formik.errors.accountingReceipts} />
      </>
    );
  },
};

export default AddEditAccountingRecordModal;
