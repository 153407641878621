import {FunctionComponent, Suspense, lazy} from 'react';
import {createPortal} from 'react-dom';

import {FsLightboxProps} from 'fslightbox-react';

const FsLightbox = lazy(() => import('fslightbox-react'));

/**
 * The modals that are created with the HeadlessUI Dialog component have a z-index of 100.
 * To allow modals to appaer above the lightbox, we need to set a z-index of < 100 on the
 * container, which is applied by the imported CSS file below (along with some other styles).
 */
import './styles.css';

/**
 * A lightweight drop-in wrapper arund the FsLightbox component to render
 * the lightbox in a portal outside of the main app element tree to ensure
 * that it is rendered on top of all other elements.
 */
const Lightbox: FunctionComponent<FsLightboxProps> = (props) =>
  createPortal(
    <Suspense fallback={<div />}>
      <FsLightbox {...props} />
    </Suspense>,
    document.body, // <-- Attach to the body element, outside of the main app element tree.
  );

export default Lightbox;
