import {API_URL} from 'globals/app-globals';
import {AccountRole} from 'models/users/User';

import {SocialAuthUserData} from '../social/platforms';
import {AuthMethod, AuthResult} from '../types';
import parseAuthResponse from '../utils/parse-auth-response';

export interface RegistrationFormData {
  name: string;
  email: string;
  phoneNumber?: string;
  password: string;
}

export interface RegisterFunctionProps {
  method: AuthMethod;
  registrationData: {
    inputUserData: RegistrationFormData | SocialAuthUserData;
    accountType: AccountRole;
    referralCode?: string;
    isTamaUser?: boolean;
  };
}

export interface RegisterFunction {
  (props: RegisterFunctionProps): Promise<AuthResult>;
}

/**
 * Create a new user account.
 */
const register: RegisterFunction = async ({method, registrationData}) => {
  const {inputUserData, accountType, referralCode, isTamaUser} =
    registrationData;

  /**
   * Attempt to register the user.
   */
  const response = await fetch(
    API_URL +
      (method === 'email' ? '/users.json' : `/identities/${method}.json`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account_type: accountType,
        promo_string: referralCode,
        /**
         * When the provider is email, we need to wrap the form
         * data in a 'user' property
         */
        ...(method === 'email'
          ? {
              user: {
                ...inputUserData,
                ['is_tama_user']: isTamaUser,
              },
            }
          : {...inputUserData, ['is_tama_user']: isTamaUser}),
      }),
    },
  );

  return await parseAuthResponse(response);
};

export default register;
