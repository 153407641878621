import {Model, Attr, BelongsTo, HasOne} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import LandlordSubscription from 'models/payments/LandlordSubscription';
import Subscription from 'models/payments/Subscription';
import User from 'models/users/User';

@Model()
class LandlordProfile extends ApplicationRecord {
  static jsonapiType = 'landlord_profiles';

  @Attr() phoneNumber: string;

  @Attr() address: string;

  @Attr() bankAccountName: string;
  @Attr() bankAccountNumber: string;

  @Attr() externalLandlordId: string;

  @Attr() addressMeta: object;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() userId: string;
  @Attr({persist: false}) subscriptionId: string;

  @BelongsTo('users') user: User;
  @HasOne('landlord_subscriptions') landlordSubscription: LandlordSubscription;
  @BelongsTo('subscriptions') subscription: Subscription;
}

export default LandlordProfile;
