import React from 'react';

import clsx from 'clsx';

import chat from 'assets/img/empty-content-placeholders/chat.png';
import creditCards from 'assets/img/empty-content-placeholders/credit-cards.png';
import documents from 'assets/img/empty-content-placeholders/documents.png';
import financials from 'assets/img/empty-content-placeholders/financials.png';
import images from 'assets/img/empty-content-placeholders/images.png';
import inbox from 'assets/img/empty-content-placeholders/inbox.png';
import inspections from 'assets/img/empty-content-placeholders/inspections.png';
import location from 'assets/img/empty-content-placeholders/location.png';
import maintenance from 'assets/img/empty-content-placeholders/maintenance.png';
import people from 'assets/img/empty-content-placeholders/people.png';
import property from 'assets/img/empty-content-placeholders/property.png';
import relax from 'assets/img/empty-content-placeholders/relax.png';

interface EmptyContentPlaceholderProps {
  testId?: string;
  type:
    | 'chat'
    | 'credit-cards'
    | 'documents'
    | 'financials'
    | 'images'
    | 'inbox'
    | 'inspections'
    | 'location'
    | 'maintenance'
    | 'people'
    | 'property'
    | 'relax';
  message?: string;
  alignX?: 'start' | 'center' | 'end';
}

const placeholders = {
  chat,
  'credit-cards': creditCards,
  documents,
  financials,
  images,
  inbox,
  inspections,
  location,
  maintenance,
  people,
  property,
  relax,
};

/**
 * A placeholder to be displayed when there is no content available.
 */
const EmptyContentPlaceholder = ({
  testId = undefined,
  type,
  message,
  alignX = 'center',
}: EmptyContentPlaceholderProps) => {
  const placeholder = placeholders[type];

  if (!placeholder) {
    throw new Error(`Placeholder type "${type}" is not supported.`);
  }

  return (
    <div
      data-testid={`${testId}--no-items`}
      className={clsx(
        'flex flex-col justify-center px-6 py-8 gap-y-2 opacity-70',
        alignX === 'start' && 'items-start',
        alignX === 'center' && 'items-center',
        alignX === 'end' && 'items-end',
      )}>
      <div className="flex flex-col items-center">
        <img src={placeholder} className="h-24 flex-shrink-0" />
        <p className="text-brand-300 text-base text-center font-medium">
          {message ?? 'There are currently no items to display'}
        </p>
      </div>
    </div>
  );
};

export default EmptyContentPlaceholder;
