import React, {useCallback, useMemo} from 'react';

import Persona from 'persona';
import {MdOutlineVerified} from 'react-icons/md';

import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {PERSONA_ENV_ID, PERSONA_TEMPLATE_ID} from 'globals/app-globals';

interface IdentifyVerificationCardProps {
  currentStatus: string | null | undefined;
  referenceId: string;
  onComplete: (status: string) => void;
}

const IdentifyVerificationCard = ({
  currentStatus,
  referenceId,
  onComplete,
}: IdentifyVerificationCardProps) => {
  const personaClient = useMemo(
    () =>
      new Persona.Client({
        templateId: PERSONA_TEMPLATE_ID,
        referenceId: referenceId,
        environmentId: PERSONA_ENV_ID,
        onError: (error) => console.log(error),
        onComplete: ({inquiryId}) => {
          onComplete(inquiryId);
        },
      }),
    [onComplete, referenceId],
  );

  const openClient = useCallback(() => personaClient.open(), [personaClient]);

  if (!currentStatus) {
    return (
      <Card title="Identity Verification" icon={MdOutlineVerified}>
        <Paragraph>
          Keyhook provides a secure and safe way for tenants to verify their
          identity without sharing any sensitive information directly with the
          landlord of properties they are interested in.
        </Paragraph>

        <Paragraph>
          To verify your identity, please click the button below to be taken to
          our identity verification partner, Persona.
        </Paragraph>

        <Paragraph secondary size="xs">
          Any information submitted through this step will be securely stored in
          Persona and will not be shared with anyone outside of Persona.
          Landlords will only be able to see that you have completed this step
          and that your identity has been verified.
        </Paragraph>

        <div className="mt-2">
          <Button
            mode="manual"
            onClick={openClient}
            label="Verify Identity"
            category="primary"
            size="base"
          />
        </div>
      </Card>
    );
  } else if (currentStatus === 'pending') {
    return (
      <Card title="Identity Verification" icon={MdOutlineVerified}>
        <Paragraph>
          Your identity verification is currently being processed.
        </Paragraph>
      </Card>
    );
  } else if (currentStatus === 'approved') {
    return (
      <Card title="Identity Verification" icon={MdOutlineVerified}>
        <Paragraph>
          Your identity has been successfully verified. Thank you for taking the
          time to complete this step.
        </Paragraph>
      </Card>
    );
  } else if (currentStatus === 'rejected') {
    return (
      <Card title="Identity Verification" icon={MdOutlineVerified}>
        <Paragraph>
          There was an issue verifying your identity, please contact support if
          you would like to find out why.
        </Paragraph>
      </Card>
    );
  }
};

export default IdentifyVerificationCard;
