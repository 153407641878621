import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from '@floating-ui/react';
import clsx from 'clsx';
import {AnimatePresence, motion} from 'framer-motion';
import {isMobile} from 'react-device-detect';
import {HiOutlineInformationCircle} from 'react-icons/hi';
import {toast} from 'react-toastify';

interface HelpTextPresenterProps {
  children?: string | ReactNode;
  customElement?: ReactNode;
  disableMargin?: boolean;
}

type HelpTextPresenterComponent = FunctionComponent<HelpTextPresenterProps>;

const HelpTextPresenter: HelpTextPresenterComponent = ({
  children: helpText,
  customElement,
  disableMargin = false,
}) => {
  const tooltipMode = useMemo(
    () => !Capacitor.isNativePlatform() && !isMobile,
    [],
  );

  const handleClick = useCallback(
    (e: React.MouseEvent<any>) => {
      if (!tooltipMode) {
        e.preventDefault();

        toast.dismiss();
        toast.info(helpText, {
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          autoClose: 5000,
        });
      }
    },
    [tooltipMode, helpText],
  );

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const {refs, floatingStyles, context} = useFloating({
    open: tooltipIsOpen,
    onOpenChange: setTooltipIsOpen,
    placement: 'top',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, {move: false});
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, {role: 'tooltip'});

  // Merge all the interactions into prop getters
  const {getReferenceProps, getFloatingProps} = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  if (!helpText) {
    return null;
  }

  return (
    <>
      <span
        ref={refs.setReference}
        {...getReferenceProps()}
        className={clsx(
          !disableMargin && 'mx-2',
          'inline-block',
          'cursor-help',
        )}
        onClick={handleClick}>
        {customElement ?? (
          <HiOutlineInformationCircle
            className={clsx(
              'w-5 h-5',
              'transition-colors duration-200',
              'text-brand-500 hover:text-brand-600',
            )}
          />
        )}
      </span>
      <FloatingPortal>
        <AnimatePresence>
          {tooltipIsOpen && (
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}>
              <motion.div
                className={clsx(
                  'max-w-sm',
                  'rounded-xl drop-shadow-2xl',
                  'bg-white px-6 py-4',
                  'flex flex-col',
                  'pointer-events-auto',
                )}
                transition={{
                  type: 'spring',
                  duration: 0.6,
                }}
                initial={{opacity: 0, y: 10}}
                animate={{opacity: 1, y: 0}}
                exit={{opacity: 0, y: 10}}>
                <span className="text-sm  text-brand-850 text-opacity-70">
                  {helpText}
                </span>
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  );
};

export default HelpTextPresenter;
