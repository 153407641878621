import {useMemo} from 'react';

import {
  HiOutlineClipboard,
  HiOutlineClock,
  HiOutlineCog,
  HiOutlineCurrencyDollar,
} from 'react-icons/hi';

import {PropertyCard, Stats} from 'components_sb/layout';
import Property from 'models/properties/Property';
import TenancyMembership from 'models/properties/TenancyMembership';
import {toCurrency} from 'utilities/StringHelpers';

const CurrentTenancyCard = ({
  index,
  membership,
}: {
  index: number;
  membership: TenancyMembership;
}) => {
  const stats = useMemo(() => {
    let nextInspectionDate: string;
    if (membership.tenancy.nextInspectionDate) {
      const today = new Date();
      const inspectionDate = new Date(membership.tenancy.nextInspectionDate);

      const timeDifference = inspectionDate.getTime() - today.getTime();

      const daysUntilNextInspection = Number(
        timeDifference / (1000 * 3600 * 24),
      );

      /**
       * Show as N/A if the inspection is overdue (i.e. negative days).
       */
      nextInspectionDate =
        daysUntilNextInspection < 0
          ? 'N/A'
          : daysUntilNextInspection.toFixed(0);
    } else {
      nextInspectionDate = 'Not set';
    }

    let leaseExpires: string;
    if (membership.tenancy.endDate) {
      const today = new Date();
      const leaseDate = new Date(membership.tenancy.endDate);

      const timeDifference = leaseDate.getTime() - today.getTime();
      leaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(0);
    }

    const serviceCount = membership.tenancy.openServiceRequests.length;

    return (
      <Stats
        small
        noMargin
        interactive={false}
        stats={[
          {
            icon: HiOutlineCurrencyDollar,
            title: 'Rent',
            value: toCurrency(membership.rentSplit),
            description: membership.tenancy.rentalPeriod,
          },
          {
            icon: HiOutlineCog,
            title: 'Maintenance requests',
            value: serviceCount,
            description: 'Open requests',
          },
          {
            icon: HiOutlineClipboard,
            title: 'Next inspection',
            value: nextInspectionDate ? nextInspectionDate : 'N/A',
            description: 'Days',
          },
          {
            icon: HiOutlineClock,
            title: 'Lease expires',
            value: leaseExpires ? leaseExpires : 'Ongoing',
            description: leaseExpires ? 'Days' : '',
          },
        ]}
      />
    );
  }, [membership]);

  /**
   * Get the property from the tenancy membership.
   */
  const property = useMemo<Property>(
    () => membership.tenancy.property,
    [membership],
  );

  return (
    <PropertyCard
      index={index}
      property={property}
      href={`/tenancies/${membership.tenancy.id}`}>
      {stats}
    </PropertyCard>
  );
};

export default CurrentTenancyCard;
