import React from 'react';

import moment from 'moment';
import {HiOutlineUserCircle} from 'react-icons/hi';

import {Card} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import TenantCheckReference from 'models/tenant_checks/TenantCheckReference';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {titleize} from 'utilities/StringHelpers';

const ReferencesCard = ({references}: {references: TenantCheckReference[]}) => {
  if (references.length === 0) {
    return (
      <Card title="References" icon={HiOutlineUserCircle}>
        <Paragraph>There are no references for this tenant check.</Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="References" icon={HiOutlineUserCircle}>
        {references.map((reference) => (
          <div key={reference.id} className="mb-4">
            <Title level="h4" size="sm" noMargin>
              {reference.referenceTypes.map((r) => titleize(r)).join(' / ')}{' '}
              Reference
            </Title>

            <Paragraph secondary size="sm" noMargin>
              {reference.referenceName} (
              {reference.referenceRelationships.join(', ')})
            </Paragraph>

            <Paragraph secondary size="xs" noMargin>
              {reference.referenceEmail} / {reference.referencePhoneNumber}
            </Paragraph>

            {reference.completed ? (
              <>
                <Paragraph size="xs" secondary noMargin>
                  Reference provided on{' '}
                  {moment(reference.createdAt).format(DATE_TIME_FORMAT)}
                </Paragraph>
                {reference.referenceCommunicationType === 'email' && (
                  <div>
                    {reference.userReference.map((writtenQuestion, index) => (
                      <div key={index} className="mt-4">
                        {writtenQuestion.label && (
                          <p className="text-xs">
                            <strong>{writtenQuestion.label}:</strong>
                          </p>
                        )}
                        <p className="mb-1">{writtenQuestion.question}</p>
                        <blockquote className="p-4 border-l-4 border-gray-300 bg-gray-50">
                          <p className="italic leading-relaxed text-gray-900">
                            "{writtenQuestion.answer}"
                          </p>
                        </blockquote>
                      </div>
                    ))}
                  </div>
                )}

                {reference.referenceCommunicationType === 'phone' && (
                  <div className="mt-4">
                    <blockquote className="p-4 border-l-4 border-gray-300 bg-gray-50">
                      {reference.keyhookReference
                        .split('\n')
                        .map((line, index) => (
                          <p
                            className="italic leading-relaxed text-gray-900 mb-4"
                            key={index}>
                            {line}
                          </p>
                        ))}
                    </blockquote>
                  </div>
                )}
              </>
            ) : (
              <Paragraph>
                We are still waiting for this reference to be completed.
              </Paragraph>
            )}
          </div>
        ))}
      </Card>
    );
  }
};

export default ReferencesCard;
