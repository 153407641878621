import {useCallback, useMemo} from 'react';

import {omit} from 'lodash';
import {useCookies} from 'react-cookie';
import {CookieSetOptions} from 'universal-cookie';

import {TARGET_ENV} from 'globals/app-globals';

export interface AuthCookies {
  userId: string;
  userEmail: string;
  token: string;
}

interface SetAuthCookiesFunction {
  (authCookies: AuthCookies): void;
}

interface ClearAuthCookiesFunction {
  (): void;
}

interface UseAuthCookiesHook {
  (): {
    authCookiesPresent: boolean;
    authCookies: AuthCookies;
    setAuthCookies: SetAuthCookiesFunction;
    clearAuthCookies: ClearAuthCookiesFunction;
  };
}

const useAuthCookies: UseAuthCookiesHook = () => {
  /**
   * Evaluate the options to use for setting cookies.
   */
  const cookieOptions = useMemo<CookieSetOptions>(
    () => ({
      /**
       * Make cookies available on all paths.
       */
      path: '/',
      domain: (() => {
        /**
         * Do not specify domain when running in development mode.
         */
        if (TARGET_ENV === 'development') {
          return undefined;
        }

        /**
         * If in staging and the hostname is a Vercel preview URL, then allow
         * setting cookies for this domain.
         */
        if (TARGET_ENV === 'staging') {
          const {hostname} = window.location;
          if (hostname.endsWith('-keyhook.vercel.app')) {
            return hostname;
          }
        }

        /**
         * Only allow the Keyhook domain (and subdomains) in production
         * and all other cases (i.e. if in staging but not on a Vercel preview URL).
         */
        return '.keyhook.com';
      })(),
    }),
    [],
  );

  /**
   * Configure read and write functionality for the auth related cookies.
   */
  const [authCookies, setCookie, removeCookie] = useCookies([
    'auth:user_id',
    'auth:user_email',
    'auth:token',
  ]);

  /**
   * Determine whether the user has all of the cookies requires for auth.
   */
  const authCookiesPresent = useMemo<boolean>(
    () =>
      'auth:user_id' in authCookies &&
      'auth:user_email' in authCookies &&
      'auth:token' in authCookies,
    [authCookies],
  );

  /**
   * Sets all required cookies for auth.
   */
  const setAuthCookies = useCallback<SetAuthCookiesFunction>(
    ({userId, userEmail, token}) => {
      setCookie('auth:user_id', userId, cookieOptions);
      setCookie('auth:user_email', userEmail, cookieOptions);
      setCookie('auth:token', token, cookieOptions);
    },
    [setCookie, cookieOptions],
  );

  /**
   * Clear all cookies required for auth.
   */
  const clearAuthCookies = useCallback(() => {
    removeCookie('auth:user_id', cookieOptions);
    removeCookie('auth:user_email', cookieOptions);
    removeCookie('auth:token', cookieOptions);

    // Preview permutations below

    removeCookie('auth:user_id');
    removeCookie('auth:user_email');
    removeCookie('auth:token');

    removeCookie('auth:user_id', omit(cookieOptions, 'domain'));
    removeCookie('auth:user_email', omit(cookieOptions, 'domain'));
    removeCookie('auth:token', omit(cookieOptions, 'domain'));

    removeCookie('auth:user_id', omit(cookieOptions, 'path'));
    removeCookie('auth:user_email', omit(cookieOptions, 'path'));
    removeCookie('auth:token', omit(cookieOptions, 'path'));
  }, [removeCookie, cookieOptions]);

  return {
    authCookiesPresent,
    authCookies: {
      userId: authCookies['auth:user_id'],
      userEmail: authCookies['auth:user_email'],
      token: authCookies['auth:token'],
    },
    setAuthCookies,
    clearAuthCookies,
  };
};

export default useAuthCookies;
