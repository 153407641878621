import React, {useCallback, useMemo, useEffect} from 'react';

import {useFormik} from 'formik';
import moment from 'moment';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {
  Base64FileField,
  DateField,
  InputField,
  TextareaField,
  ToggleField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import {Button} from 'components_sb/buttons';
import {Paragraph} from 'components_sb/typography';
import RentPriceChange from 'models/properties/RentPriceChange';
import Tenancy from 'models/properties/Tenancy';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const NewRentPriceChangeForm = ({
  tenancy,
  onSuccess,
}: {
  tenancy: Tenancy;
  onSuccess: (rpc: RentPriceChange) => Promise<void>;
}) => {
  const handleSubmit = async (fieldValues: any, actions: any) => {
    const attrs = {...fieldValues};

    if (attrs.attachment && attrs.attachment.trim().length === 0) {
      delete attrs.attachment;
    }
    if (
      fieldValues.messageFromLandlord &&
      fieldValues.messageFromLandlord.trim().length === 0
    ) {
      delete attrs.messageFromLandlord;
    }

    if (
      fieldValues.landlordSignature &&
      fieldValues.landlordSignature.trim().length === 0
    ) {
      delete attrs.landlordSignature;
    }

    if (
      fieldValues.reductionEndDate &&
      fieldValues.reductionEndDate.trim().length === 0
    ) {
      delete attrs.reductionEndDate;
    }

    const rentChange = new RentPriceChange(fieldValues);

    rentChange.tenancyId = tenancy.id;

    const result = await rentChange.save();
    if (result) {
      if (rentChange.wasServedManually) {
        toast.success(
          'The rent change has been successfully added, Keyhook will inform your tenant(s) and handle adjusting the rent on the set date.',
        );
      } else {
        toast.success(
          'The rent change has been successfully added, Keyhook will send formal notice to your tenant(s) and handle adjusting the rent on the set date.',
        );
      }

      onSuccess(rentChange);

      // await queryClient.invalidateQueries(
      //   ['property', {id: propertyId, context: 'detail-page'}],
      // );
      // router.navigate(`/properties/${propertyId}`, {replace: true});
    } else {
      if (rentChange.errors.effectiveDate) {
        actions.setFieldError(
          'effectiveDate',
          rentChange.errors.effectiveDate.message,
        );
      }
    }

    actions.setSubmitting(false);
  };

  const minDate = useMemo(() => moment().add(1, 'day'), []);
  const maxDate = useMemo(() => {
    if (tenancy.endDate) {
      return moment(tenancy.endDate);
    } else {
      return moment().add(2, 'years');
    }
  }, [tenancy]);

  const validDates = useMemo(() => {
    if (tenancy && minDate && maxDate) {
      const numDays = tenancy.rentalPeriod === 'Weekly' ? 7 : 14;
      const dates = new Set();
      let currentDate = moment(minDate);
      while (currentDate.isSameOrBefore(maxDate)) {
        dates.add(moment(currentDate).format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(numDays, 'days');
      }

      return dates;
    } else {
      return new Set();
    }
  }, [tenancy, maxDate, minDate]);

  const initialDate = useMemo(() => {
    const sixtyDays = moment().add(60, 'days');
    const date = Array.from(validDates).find((date) =>
      moment(date).isAfter(sixtyDays),
    );

    if (date) {
      return moment(date as string).toDate();
    } else {
      return null;
    }
  }, [validDates]);

  const dateFilter = useCallback(
    (currentDate: moment.Moment) => {
      return validDates.has(currentDate.format('YYYY-MM-DD'));
    },
    [validDates],
  );

  const formik = useFormik({
    initialValues: {
      newTotalRent: undefined,
      effectiveDate: undefined,
      isTemporaryRentReduction: false,
      reductionEndDate: '',
      wasServedManually: false,
      messageFromLandlord: '',
      attachment: null,
      landlordSignature: '',
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      newTotalRent: Yup.number().min(0).required().label('New Rent'),
      effectiveDate: Yup.string().required().label('Effective Date'),
      isTemporaryRentReduction: Yup.boolean()
        .label('Is Temporary Rent Reduction')
        .optional()
        .nullable(),
      reductionEndDate: Yup.string()
        .nullable()
        .optional()
        .label('Reduction end date')
        .test(
          'should-be-greather-than-effective-date',
          'Should be after the Effective Date',
          function (value) {
            if (!value || value === '') {
              return true;
            }
            const effectiveDate = this.parent.effectiveDate;
            if (!effectiveDate) {
              return true;
            }

            return moment(value) > moment(effectiveDate);
          },
        )
        .test(
          'required-if-temporary-rent-reduction',
          'This field is required',
          function (value) {
            if (this.parent.isTemporaryRentReduction) {
              return value && value.length > 0 && moment(value).isValid();
            } else {
              return true;
            }
          },
        ),
      wasServedManually: Yup.boolean()
        .label('Notice has already been served')
        .test(
          'required-if-increase-within-60-days',
          'This can only be done if you have already sent notice to your tenant(s).',
          function (value) {
            const sixtyDays = moment().add(60, 'days');

            if (this.parent.effectiveDate && this.parent.newTotalRent) {
              if (
                moment(this.parent.effectiveDate).isBefore(sixtyDays) &&
                this.parent.newTotalRent > tenancy.totalRent
              ) {
                return value === true;
              }
            }
            return true;
          },
        ),
      messageFromLandlord: Yup.string().optional().nullable().label('Message'),
      attachment: Yup.string().optional().nullable().label('Attachment'),
      landlordSignature: Yup.string()
        .optional()
        .nullable()
        .label('Signature')
        .test(
          'required-if-no-manual-notice-given',
          'This field is required',
          function (value) {
            if (this.parent.wasServedManually) {
              return true;
            }
            return !!value && value.length > 0;
          },
        ),
    }),
  });

  useEffect(() => {
    if (initialDate && !formik.values.effectiveDate) {
      formik.setFieldValue(
        'effectiveDate',
        moment(initialDate).format('YYYY-MM-DD'),
      );
    }
  }, [initialDate, formik]);

  const showManualField = useMemo(() => {
    if (!tenancy) {
      return false;
    }

    // Rent is decreased, Dont need confirmation
    if (
      formik.values.newTotalRent &&
      formik.values.newTotalRent < tenancy.totalRent
    ) {
      return false;
    }

    // Rent is increased before 60 days, need sign off
    // that its already been served.
    const sixtyDays = moment().add(60, 'days');
    if (
      formik.values.effectiveDate &&
      moment(formik.values.effectiveDate).isBefore(sixtyDays)
    ) {
      return true;
    }

    return false;
  }, [tenancy, formik.values.newTotalRent, formik.values.effectiveDate]);

  return (
    <div>
      <Paragraph>
        The current rent for this tenancy is{' '}
        <strong>{toCurrency(tenancy.totalRent)}</strong>
      </Paragraph>

      <div className="mt-2">
        <FormRow responsive>
          <FormRowItem>
            <InputField
              name="newTotalRent"
              formik={formik}
              labelProps={{
                title: 'New Rent ($)',
              }}
              placeholder="E.g. 780.00"
            />
          </FormRowItem>
          <FormRowItem>
            {initialDate && (
              <DateField
                name="effectiveDate"
                formik={formik}
                label="Effective Date"
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                dateFormat={DATE_FORMAT}
                isValidDate={dateFilter}
                defaultValue={initialDate}
              />
            )}
          </FormRowItem>
        </FormRow>

        {formik.values.newTotalRent &&
          formik.values.newTotalRent < tenancy.totalRent && (
            <div className="mt-2 lg:w-1/2">
              <ToggleField
                name="isTemporaryRentReduction"
                formik={formik}
                label="Is this a temporary rent reduction?"
              />
            </div>
          )}

        {formik.values.isTemporaryRentReduction && (
          <div className="mt-2 lg:w-1/2">
            <DateField
              name="reductionEndDate"
              formik={formik}
              label="Reduction end date"
              minDate={new Date()}
              maxDate={moment('2099-12-31').toDate()}
              dateFormat={DATE_FORMAT}
              helpText="Please note that Keyhook does not currently handle
                        adjusting for partial week changes. The rent will be
                        restored to the original amount on the next rent payment
                        date after the date you have selected."
            />
          </div>
        )}

        {showManualField && (
          <div className="mt-2 lg:w-1/2">
            <ToggleField
              name="wasServedManually"
              formik={formik}
              label="I confirm that I have already served notice to my tenant(s)."
              helpText="You have entered a date prior to 60 days from today. Please confirm that you have already served notice to your tenant(s)."
            />
          </div>
        )}

        <div className="mt-4">
          <TextareaField
            formik={formik}
            name="messageFromLandlord"
            labelProps={{title: 'Optionally include a message', size: 'base'}}
            placeholder="E.g. This will be sent along with the notice to your tenant(s)"
            rows={3}
          />

          <Base64FileField
            formik={formik}
            name="attachment"
            labelProps={{
              title: 'Optional supporting evidence',
              helpText:
                'Any supporting evidence you might want to include, E.g. A rent appraisal. This is optional.',
            }}
          />
        </div>

        <div className="mt-4">
          {!formik.values.wasServedManually && (
            <SignatureModalInput
              mode="manual"
              name="landlordSignature"
              labelProps={{
                title: 'Signature',
              }}
              onSave={(val) => formik.setFieldValue('landlordSignature', val)}
              signature={formik.values.landlordSignature}
            />
          )}
        </div>

        <div className="mt-8">
          <Button
            label={
              formik.values.wasServedManually ? 'Save' : 'Save & Send Notice'
            }
            category="primary"
            size="base"
            mode="formik"
            form={formik}
            loadingLabel="Saving..."
          />
        </div>
      </div>
    </div>
  );
};

export default NewRentPriceChangeForm;
