import {createElement} from 'react';

import {Capacitor} from '@capacitor/core';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {setWebviewBounce} from 'capacitor-plugin-ios-webview-configurator';
import Framework7React from 'framework7-react';
import Framework7 from 'framework7/lite/bundle';
import {createRoot} from 'react-dom/client';

import {TARGET_ENV} from 'globals/app-globals';

import App from './App';

import 'models/loadModels';

// ==============================================================================
// ================================ IMPORT STYLES ===============================
// ==============================================================================
import 'styles/framework7-vars.css'; // CSS variables for Framework7
import 'framework7/css'; // Framework7 core styles
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datetime/css/react-datetime.css';
import 'react-toggle/style.css';
import '@glidejs/glide/dist/css/glide.core.css';
import 'leaflet/dist/leaflet.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/styles/global.css';

/**
 *
 *
 *
 */
// ==============================================================================
// ========================== CAPACITOR INITIALISATION ==========================
// ==============================================================================
if (Capacitor.isNativePlatform()) {
  setWebviewBounce(false);
}
/**
 *
 *
 *
 */
// ==============================================================================
// =========================== SENTRY INITIALISATION ============================
// ==============================================================================
const initSentry = () => {
  Sentry.init({
    dsn: 'https://2ab6f77375f74b48a9b7d42fc135fef2@o4504233323855872.ingest.sentry.io/4504233326084096',
    integrations: [new BrowserTracing()],
    environment: TARGET_ENV,
    tracesSampleRate: 1.0,
  });
};
if (process.env.TARGET_ENV !== 'development') {
  initSentry();
}
/**
 *
 *
 *
 */
// ==============================================================================
// ========================= FRAMEWORK7 INITIALISATION ==========================
// ==============================================================================
Framework7.use(Framework7React);
/**
 *
 *
 *
 */
// ==============================================================================
// ============================ APP INITIALISATION ==============================
// ==============================================================================
const root = createRoot(document.getElementById('app'));
root.render(createElement(App));
