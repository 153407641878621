import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import isAsyncFunction from 'is-async-function';
import {toast} from 'react-toastify';

import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

import Modal, {EXIT_DURATION, ModalButtonsConfig} from './Modal';
import type {ModalButtonConfig} from './Modal';

/**
 * Opens a modal imperatively using the provided modal definition
 * and props for the content.
 */
export interface ImperativeModalValue {
  (modalDefinition: ModalDefinition, contentProps?: any): Promise<any>;
}

const ImperativeModalContext = React.createContext<ImperativeModalValue>(null);

/**
 * Modal content components can be provided any props.
 */
interface ModalDefinitionContentProps {
  [key: string]: any;
}

/**
 * Modal content components will receive the provided props
 * along with a closeModal function to explicitly close the modal.
 */
export interface ModalDefinitionContentHandle {
  /**
   * A handle that is defined within the config for an action button.
   */
  [handle: string]: () => any | Promise<any>;
  onCancel?: () => any | Promise<any>;
}

type ModalDefinitionContentComponent = ForwardRefRenderFunction<
  ModalDefinitionContentHandle,
  ModalDefinitionContentProps
>;

export interface ModalDefinition {
  title?: string;
  hideFooter?: boolean;
  ContentComponent: ModalDefinitionContentComponent;
  buttonsConfig: {
    cancel?: Omit<ModalButtonConfig, 'id' | 'onClick' | 'loading' | 'disabled'>;
    actions?: (Omit<ModalButtonConfig, 'onClick' | 'loading' | 'disabled'> & {
      /**
       * The handle for the function that will be invoked in the modal
       * content component upon clicking the button for the action.
       */
      handle: string;
      /**
       * Whether the modal should automatically close upon successfully invoking
       * the function defined by the handle.
       */
      closeOnSuccess?: boolean;
      /**
       * A toast message to automatically display upon successfully invoking
       * the function defined by the handle.
       */
      toastOnSuccess?: string;
    })[];
  };
}

interface CurrentModalState {
  definition: ModalDefinition;
  contentProps: ModalDefinitionContentProps;
  open: boolean;
  closing: boolean;
}

const Provider = ({children}: {children: ReactNode}) => {
  const [currentModal, setCurrentModal] = useState<CurrentModalState>();
  const resolveRef = useRef<(data: any) => void>();

  /**
   * Open the modal after the initial mount of the modal component.
   * This allow for the opening animation to occur.
   */
  useEffect(() => {
    if (currentModal) {
      const {open, closing} = currentModal;
      if (!open && !closing) {
        setCurrentModal(() => ({
          ...currentModal,
          open: true,
          closing: false,
        }));
      }
    }
  }, [currentModal, setCurrentModal]);

  /**
   * Triggers the opening process for the modal.
   */
  const closeModal = useCallback(() => {
    if (currentModal && !currentModal.closing) {
      /**
       * Close the modal.
       */
      setCurrentModal({
        ...currentModal,
        open: false,
        closing: true,
      });
      /**
       * Allow the closing animation to perform before
       * unmounting the modal.
       */
      setTimeout(() => setCurrentModal(null), EXIT_DURATION);
    }
  }, [currentModal, setCurrentModal]);

  /**
   * Triggers the opening process for the modal using the
   * provided configuration.
   */
  const openModal = useCallback(
    (
      definition: ModalDefinition,
      contentProps: ModalDefinitionContentProps,
    ) => {
      /**
       * Only one modal should be open at any given time.
       */
      if (currentModal) {
        console.warn(
          'There is already an existing modal imperatively opened! The existing modal will be replaced.',
        );
      }

      /**
       * The modal is not immediately opened, this ensures that it
       * gets mounted first, before opening, which is required for the
       * opening animation to occur.
       */
      setCurrentModal({
        definition,
        contentProps,
        open: false,
        closing: false,
      });

      return new Promise<void>((resolve) => {
        resolveRef.current = resolve;
      });
    },
    [currentModal, setCurrentModal, resolveRef],
  );

  const onResolve = useCallback(
    (data?: any) => {
      closeModal();
      const {current: resolve} = resolveRef;
      if (resolve) {
        resolve(data);
      }
    },
    [closeModal, resolveRef],
  );

  return (
    <ImperativeModalContext.Provider value={openModal}>
      {children}

      {/* Only render if there is a current modal. */}
      {currentModal && (
        <ModalFromDefinition
          resolve={onResolve}
          {...currentModal}
          contentProps={{
            ...currentModal.contentProps,
            /**
             * Append the closeModal function to allow content component to explicitly close the modal
             */
            closeModal,
          }}
        />
      )}
    </ImperativeModalContext.Provider>
  );
};

const ModalFromDefinition = ({
  definition,
  open,
  contentProps,
  resolve,
}: {
  definition: ModalDefinition;
  open: boolean;
  contentProps: any;
  resolve: (data?: any) => void;
}) => {
  const {title, ContentComponent, hideFooter} = definition;
  const contentRef = useRef<ModalDefinitionContentHandle>();

  const [activeAction, setActiveAction] = useState(null);

  const onCancel = useCallback(async () => {
    /**
     * Wait for the modal to perform any cancellation actions if an onCancel function
     * is provided to the useImperativeHandle hook in the content component.
     */
    if (contentRef.current) {
      const cancelFunction = contentRef.current.onCancel;
      if (cancelFunction) {
        /**
         * Set the active action state only if the cancel function is async.
         */
        if (isAsyncFunction(cancelFunction)) {
          setActiveAction('cancel');
        }

        /**
         * Attempt to perform the cancellation function.
         */
        try {
          const cancellationResult = await cancelFunction();
          resolve(cancellationResult);
          return;
        } catch (error) {
          /**
           * The error function failed, log the error in Sentry but don't prevent
           * the user from closing the modal (they'll probably refresh the page anyway).
           */
          Sentry.captureException(error);
        }
      }
    }
    /*
     * We couldn't get a return value from the cancellation function, or no cancellation
     * function was provide,so we will return null by default.
     */
    resolve(null);
  }, [contentRef, setActiveAction, resolve]);

  /**
   * Invokes a function provided to the useImperativeHandle hook in the content
   * component for a given action function handle.
   */
  const onAction = useCallback(
    async ({
      id,
      handle,
      closeOnSuccess,
      toastOnSuccess,
    }: {
      id: string;
      handle: string;
      closeOnSuccess: boolean;
      toastOnSuccess: string;
    }) => {
      if (contentRef.current) {
        const actionFunction = contentRef.current[handle];

        /**
         * Ensure that the modal content component has provided a function matching
         * the handle in the definition.
         */
        if (!actionFunction) {
          throw new Error(
            `Modal content component does not provide a ${handle} function within useImperativeHook.`,
          );
        }

        /**
         * Set the active action state only if the cancel function is async.
         */
        if (isAsyncFunction(actionFunction)) {
          setActiveAction(id);
        }

        /**
         * Attempt to perform the action function.
         */
        try {
          const actionResult = await actionFunction();
          /**
           * Action was successful - close the modal if required.
           */
          if (closeOnSuccess) {
            resolve(actionResult);
          }
          /**
           * Action was successful - show a toast if required.
           */
          if (toastOnSuccess) {
            toast.success(toastOnSuccess);
          }
          /**
           * Clear the active action to restore usability to the modal.
           */
          setActiveAction(null);
        } catch (error) {
          /**
           * Clear the active action to restore usability to the modal.
           */
          setActiveAction(null);
        }
      }
    },
    [contentRef, setActiveAction, resolve],
  );

  /**
   * Extend the provided buttons configuration to either omit a
   * button where configuration has not been provided, or to append
   * the handlers provided by the content reference and loading or
   * disabled states.
   */
  const buttonsConfig: ModalButtonsConfig = useMemo(
    () => ({
      cancel: {
        ...(definition.buttonsConfig.cancel ?? {}),
        onClick: onCancel,
        loading: activeAction === 'cancel',
        disabled: activeAction && activeAction !== 'cancel',
      },
      actions: !definition.buttonsConfig.actions
        ? undefined
        : definition.buttonsConfig.actions.map((action) => ({
            ...action,
            onClick: () => {
              // Close on success is set to true by default
              const {
                id,
                handle,
                closeOnSuccess = true,
                toastOnSuccess = null,
              } = action;
              onAction({id, handle, closeOnSuccess, toastOnSuccess});
            },
            loading: activeAction === action.id,
            disabled: activeAction && activeAction !== action.id,
          })),
    }),
    [onCancel, onAction, activeAction, definition.buttonsConfig],
  );

  const ForwardRefContentComponent = useMemo(
    () => forwardRef(ContentComponent),
    [ContentComponent],
  );

  return (
    <Modal
      open={open}
      title={title}
      buttonsConfig={buttonsConfig}
      hideFooter={hideFooter}>
      <ForwardRefContentComponent ref={contentRef} {...contentProps} />
    </Modal>
  );
};

const useModal = () => {
  return useContext(ImperativeModalContext);
};

export default {
  Provider,
  useModal,
};
