import Property from 'models/properties/Property';

export default {
  queryKey: (id?: string) =>
    id ? ['property', {id, context: 'detail-page'}] : ['property'],
  queryFn: (id: string) => {
    const fn = async () => {
      const res = await Property.select({
        // Select everything in properties
        tenancies: [
          'status',
          'total_rent',
          'rental_period',
          'start_date',
          'end_date',
          'next_inspection_date',
          'external_bond_id',
          'external_bond_filed',
          'ready_to_be_filed',
          'is_new',
          'bond',
          'head_tenant_id',
          'property_id',
          'in_arrears',
          'next_rent_payment_due',
          'managed_rent_enabled',
        ],
        users: ['name', 'avatar', 'email'],
        tenancy_requests: ['name', 'renter_email', 'created_at', 'accepted'],
        documents: [
          'document',
          'document_type',
          'private',
          'name',
          'documentable_type',
          'created_at',
        ],
        inspections: [
          'finalized_date',
          'estimated_date',
          'status',
          'done_by',
          'inspection_period_type',
          'inspection_actions_count',
        ],
        room_plans: ['id'],
        listings: [
          'id',
          'public_id',
          'property_id',
          'status',
          'created_at',
          'view_count',
          'open_homes_count',
          'listing_rental_applications_count',
          'enquiry_threads_count',
          'private_viewings_count',
        ],
      })
        .selectExtra(['extra_information'])
        .includes([
          'billing_method',
          'current_tenancy',
          'documents',
          {
            listings: ['external_listing_ads'],
            tenancies: [
              {tenancy_memberships: 'renter'},
              {tenancy_requests: 'renter'},
              {tenancy_invites: 'renter'},
              {inspections: 'inspection_actions'},
              'documents',
              'active_tenancy_continuation',
              'service_requests',
              'unprocessed_rent_price_changes',
              'unpaid_landlord_payments',
              'end_of_tenancy',
            ],
          },
          'room_plan',
        ])
        .order({'tenancies.start_date': 'desc', 'listings.created_at': 'desc'})
        .where({
          'listings.status': [
            'draft',
            'pending_approval',
            'active',
            'paused',
            'under_offer',
          ],
        })
        .find(id);
      return res.data;
    };

    return fn;
  },
};
