import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {createPortal} from 'react-dom';

import {motion, AnimatePresence} from 'framer-motion';

import useRouter from 'router/hooks/useRouter';

import CameraUnderlay from './CameraUnderlay';

/**
 * We add a short delay when entering to allow the camera
 * to load before transitioning in the UI.
 */
const ENTER_DELAY = 500;

/**
 * The duration of the animations on enter and exit (after the delay).
 */
const ANIMATION_DURATION = 300;

type CloseCameraFunctionArgs =
  | {
      action: 'back';
      backUrl?: string;
      href?: never;
    }
  | {
      action: 'navigate';
      backUrl?: never;
      href: string;
    };

export interface CloseCameraFunction {
  (args: CloseCameraFunctionArgs): void;
}

interface CameraProps {
  children: (close: CloseCameraFunction) => ReactNode;
}

const Camera: FunctionComponent<CameraProps> = ({children: overlay}) => {
  /**
   * Find the element for the root of the app.
   */
  const rootAppElement = useMemo(() => document.getElementById('app'), []);

  const router = useRouter();

  const [isClosing, setIsClosing] = useState<boolean>(false);

  useEffect(() => {
    rootAppElement.style.display = 'none';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = useCallback<CloseCameraFunction>(
    ({action, backUrl, href}) => {
      setIsClosing(true);
      setTimeout(() => {
        rootAppElement.style.display = 'block';
        if (action === 'back') {
          if (backUrl) {
            router.goBack(backUrl);
          } else {
            router.goBack();
          }
        } else if (action === 'navigate') {
          router.navigate(href);
        }
      }, ANIMATION_DURATION);
    },
    [rootAppElement, router],
  );

  return (
    <>
      {createPortal(
        <AnimatePresence>
          {!isClosing && (
            <motion.div
              className="z-90 fixed top-0 left-0 w-screen h-screen"
              transition={{
                ease: 'easeOut',
                duration: ANIMATION_DURATION / 1000, // ms -> s
              }}
              initial={{opacity: 'rgba(255,255,255,1)'}}
              animate={{
                transition: {delay: ENTER_DELAY / 1000},
                background: 'rgba(0,0,0,0)',
              }}
              exit={{background: 'rgba(255,255,255,1)'}}>
              <motion.div
                className="h-full w-full relative"
                transition={{
                  ease: 'easeOut',
                  duration: ANIMATION_DURATION / 1000, // ms -> s
                }}
                initial={{opacity: 0}}
                animate={{
                  transition: {delay: ENTER_DELAY / 1000},
                  opacity: 1,
                }}
                exit={{opacity: 0}}>
                {overlay(onClose)}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>,
        document.body,
      )}
      <CameraUnderlay />
    </>
  );
};

export default Camera;
