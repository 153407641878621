import {useCallback, useMemo} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {ChevronRightIcon} from '@heroicons/react/outline';

import useAuth from 'auth/provider/useAuth';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {
  APP_VERSION,
  GIT_BRANCH,
  GIT_COMMIT,
  NATIVE_LAYOUT_IN_WEB,
  TARGET_ENV,
} from 'globals/app-globals';
import {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRouter from 'router/hooks/useRouter';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

interface SettingsItem {
  title: string;
  subtitle: string;
}

const SettingsIndexPage = () => {
  const router = useRouter();
  const {currentUser, deleteAccount} = useAuth();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const settings = useMemo(() => {
    return [
      // {title: 'App Settings', subtitle: 'Configure how the app works and looks'},
      {title: 'Account information', subtitle: 'Change your email or password'},
      currentUser.roles.includes(AccountRole.Landlord) && {
        title: 'Refer a friend',
        subtitle: 'Invite a friend to manage their property with Keyhook',
      },
      {title: 'Avatar', subtitle: 'Upload an avatar'},
      currentUser.roles.includes(AccountRole.Landlord)
        ? {
            title: 'Landlord profile',
            subtitle:
              'Change any account information specific to your landlord profile',
          }
        : {
            title: 'Renter profile',
            subtitle:
              'Change any account information specific to your renter profile',
          },

      {
        title: 'Notifications',
        subtitle: 'Configure which notifications you receive',
      },
      {
        title: 'Live chat support',
        subtitle:
          'Contact our team to get support or to provide us with any feedback.',
      },
      {
        title: 'Email support',
        subtitle: 'Email our team to get support',
      },
      {
        title: 'Knowledgebase',
        subtitle: 'How to guides and frequently asked questions',
      },

      {
        title: 'Logout',
        subtitle: 'Log out of the app',
      },
    ].filter((s) => !!s) as SettingsItem[];
  }, [currentUser]);

  const showSupport = useCallback(async () => {
    const params = new URLSearchParams();
    params.set('user_id', currentUser.id);
    params.set('name', currentUser.name);
    params.set('email', currentUser.email);
    params.set('env', TARGET_ENV);
    let url = `https://livesupport.keyhook.com?${params.toString()}`;
    url = url + currentUser.roles.map((role) => `&roles=${role}`).join('');

    await Browser.open({url: url});
  }, [currentUser]);

  const handlePress = useCallback(
    (itemTitle: string) => {
      switch (itemTitle) {
        case 'Account information':
          router.navigate('general');
          break;
        case 'Notifications':
          router.navigate('notifications');
          break;
        case 'Avatar':
          router.navigate('avatar');
          break;
        case 'Landlord profile':
          router.navigate('profile/landlord');
          break;
        case 'Renter profile':
          router.navigate('profile/renter');
          break;
        case 'Live chat support':
          showSupport();
          break;
        case 'Email support':
          window.open('mailto:support@keyhook.com', '_blank');
          break;
        case 'Knowledgebase':
          Browser.open({url: 'https://keyhook.customerly.help/'});
          break;
        case 'Refer a friend':
          router.navigate('/refer-a-friend');
          break;
        case 'Logout':
          router.navigate('/logout', {animate: false});
          break;
        default:
          break;
      }
    },
    [router, showSupport],
  );

  const confirmDeleteAccount = useCallback(() => {
    setConfirmationOptions({
      color: 'error',
      title: 'Delete Your Account',
      message:
        'Are you sure you want to delete your Keyhook Account? This cannot be undone. If you are part way through a tenancy, you may be legally liable for any missed actions.',
      action: deleteAccount,
      buttonTitle: 'Delete Account',
    });
  }, [deleteAccount, setConfirmationOptions]);

  return (
    <Page title="Settings" disableBack>
      {() => (
        <>
          <div>
            {settings.map((item, index) => (
              <Card
                key={index}
                className="mt-2 cursor-pointer"
                onClick={() => handlePress(item.title)}>
                <div className="flex items-center justify-between gap-2">
                  <div className="flex flex-col">
                    <strong className="text-sm">{item.title}</strong>
                    <Paragraph size="sm" secondary>
                      {item.subtitle}
                    </Paragraph>
                  </div>
                  <ChevronRightIcon className="w-4 h-4 text-gray-500" />
                </div>
              </Card>
            ))}

            {(TARGET_ENV === 'development' ||
              (Capacitor.isNativePlatform() &&
                Capacitor.getPlatform() === 'ios')) && (
              <Card
                className="mt-8 cursor-pointer"
                onClick={confirmDeleteAccount}>
                <div className="flex items-center justify-between gap-2">
                  <div className="flex flex-col">
                    <strong className="text-sm text-error">
                      Delete Account
                    </strong>
                    <Paragraph size="sm" secondary>
                      Permanently delete your Keyhook account.
                    </Paragraph>
                  </div>
                  <ChevronRightIcon className="w-4 h-4 text-gray-500" />
                </div>
              </Card>
            )}

            {(Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB) && (
              <div className="mt-4 flex flex-col gap-y-1 items-center text-brand-850 opacity-50">
                <Paragraph>
                  <span className="font-medium">Version:</span>
                  &nbsp;
                  <span>
                    {APP_VERSION ?? 'N/A'} ({TARGET_ENV.at(0).toUpperCase()})
                  </span>
                </Paragraph>
                {/* Only show branch/commit details in non-production environments */}
                {TARGET_ENV !== 'production' && (
                  <>
                    <Paragraph>
                      <span className="font-medium">Branch:</span>
                      &nbsp;
                      <span>{GIT_BRANCH ?? 'N/A'}</span>
                    </Paragraph>
                    <Paragraph>
                      <span className="font-medium">Commit:</span>
                      &nbsp;
                      <span>{GIT_COMMIT ?? 'N/A'}</span>
                    </Paragraph>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </Page>
  );
};

export default SettingsIndexPage;
