import {useCallback, useState} from 'react';

import {Formik, Form, FormikHelpers} from 'formik';
import moment from 'moment';
import {HiOutlineCog, HiOutlineUser} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {AuthErrorCode, errorIsAuthErrorCodes} from 'auth/errors';
import useAuth from 'auth/provider/useAuth';
import ConfirmEmailAlert from 'components/auth/ConfirmEmailAlert';
import LoadingView from 'components/common/LoadingView';
import LandlordProfileForm from 'components/forms/LandlordProfileForm';
import RenterProfileForm from 'components/forms/RenterProfileForm';
import {InputField, SubmitButton} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import LandlordProfile from 'models/users/LandlordProfile';
import RenterProfile from 'models/users/RenterProfile';
import User, {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRouter from 'router/hooks/useRouter';
import {DATE_FORMAT} from 'utilities/DateHelpers';

interface UserDetailsFormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
}

const MyAccountPage = () => {
  const [avatarUploading, setAvatarUploading] = useState(false);

  const {currentUser, updateUser, setAvatar, updateName} = useAuth();

  const {isLoading, error, data} = useQuery('user-account-page', async () => {
    const user = await User.includes([
      'landlord_profile',
      'renter_profile',
    ]).find(currentUser.id);

    if (!user.data.landlordProfile) {
      user.data.landlordProfile = new LandlordProfile({userId: currentUser.id});
    }
    if (!user.data.renterProfile) {
      user.data.renterProfile = new RenterProfile({userId: currentUser.id});
    }

    return user.data;
  });
  const queryClient = useQueryClient();

  const saveLandlordProfile = async (formData: any, actions: any) => {
    const profile = data.landlordProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const saveRenterProfile = async (formData: any, actions: any) => {
    const profile = data.renterProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const showGenericError = useCallback(
    () => toast.error('Sorry, there was an error updating your account.'),
    [],
  );

  const saveUserName = async (formData: any, actions: any) => {
    const user = data;
    user.assignAttributes(formData);
    const result = await user.save();

    if (result) {
      await updateName(user.name);
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(user.errors)) {
        const message = user.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  const router = useRouter();

  const saveUserDetails = async (
    formValues: UserDetailsFormValues,
    formikHelpers: FormikHelpers<UserDetailsFormValues>,
  ) => {
    try {
      await updateUser(formValues);

      toast.success('Your account has been successfully updated!');

      /**
       * Navigate the user to confirm their email if they changed it.
       */
      if (formValues.email !== currentUser.email) {
        router.navigate('/register/confirm-email');
      }
    } catch (error) {
      /**
       * Check if the error is an array of AuthErrorCode values.
       */
      if (errorIsAuthErrorCodes(error)) {
        const authErrorCodes = error;
        if (authErrorCodes.includes(AuthErrorCode.INVALID_CURRENT_PASSWORD)) {
          /**
           * Handle invalid current password.
           */
          formikHelpers.setFieldError(
            'currentPassword',
            'Incorrect current password',
          );
        } else if (authErrorCodes.includes(AuthErrorCode.EMAIL_ALREADY_TAKEN)) {
          /**
           * Handle new email already taken.
           */
          formikHelpers.setFieldError(
            'email',
            'An account with this email address already exists.',
          );
        } else {
          /**
           * Unknown error code.
           */
          showGenericError();
        }
      } else {
        /**
         * Error performing the registration request.
         */
        showGenericError();
      }
    } finally {
      /**
       * Reset the submitting state.
       */
      formikHelpers.setSubmitting(false);
    }
  };

  const readAvatarImage = async (event: any) => {
    const file = event.target.files[0];
    const maxAllowedSize = 1 * 1024 * 1024;

    if (file && file.size <= maxAllowedSize) {
      const reader = new FileReader();

      reader.onload = async (readerEvent) => {
        setAvatarUploading(true);
        const base64 = readerEvent.target.result.toString();

        const user: User = data;
        data.avatar = base64;
        const result = await data.save();

        if (result) {
          await setAvatar(user.avatar);
          queryClient.setQueryData('user-account-page', user);
        }

        setAvatarUploading(false);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Page title="My Account" loading={isLoading} error={error}>
      {() => (
        <>
          <ConfirmEmailAlert />
          <Card className="mt-2">
            <div className="flex justify-start items-start">
              <div>
                <UserAvatar user={currentUser} size="14" />
              </div>
              <div className="ml-3">
                <h2 className="card-title mb-0 text-brand-850">{data.name}</h2>
                <Paragraph>
                  {data.email}{' '}
                  {data.unconfirmedEmail && (
                    <span className="text-secondary">
                      (Unconfirmed Pending Email: {data.unconfirmedEmail})
                    </span>
                  )}
                </Paragraph>
                <Paragraph size="sm" secondary>
                  Keyhook Member Since{' '}
                  {moment(data.createdAt).format(DATE_FORMAT)}
                </Paragraph>
                <div>
                  <input
                    type="file"
                    id="avatar-image-hidden-input"
                    accept=".png,.jpeg,.jpg"
                    onChange={readAvatarImage}
                    className="hidden"
                  />

                  <button
                    className="btn btn-sm btn-neutral"
                    type="button"
                    onClick={() =>
                      document
                        .getElementById('avatar-image-hidden-input')
                        .click()
                    }>
                    {avatarUploading
                      ? 'Uploading profile picture...'
                      : 'Change profile picture'}
                  </button>

                  <small className="text-secondary ml-2">
                    ( Max allowed file size 1MB )
                  </small>
                </div>
              </div>
            </div>
          </Card>

          <Card icon={HiOutlineUser} title="Personal Information">
            <Formik
              initialValues={{
                name: data.name,
              }}
              onSubmit={saveUserName}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required()
                  .label('Full Name')
                  .min(2)
                  .max(128)
                  .test(
                    'two-names-plus',
                    'Please input your first and last name',
                    (value) => {
                      return value.split(' ').length >= 2;
                    },
                  ),
              })}>
              {(formik) => (
                <Form>
                  <InputField
                    placeholder="E.g. John Smith"
                    labelProps={{
                      title: 'Full name',
                      helpText:
                        'This is your legal name that will be used in any agreements and paperwork.',
                    }}
                    name="name"
                    formik={formik}
                  />

                  <SubmitButton
                    formik={formik}
                    text="Update Information"
                    submittingText="Saving"
                  />
                </Form>
              )}
            </Formik>
          </Card>

          <Card icon={HiOutlineCog} title="Account Security">
            <Formik
              initialValues={{
                email: data.email,
                password: '',
                passwordConfirmation: '',
                currentPassword: '',
              }}
              onSubmit={saveUserDetails}
              validationSchema={Yup.object().shape({
                email: Yup.string().email().required().label('Email'),
                password: Yup.string().min(6).max(128).label('Password'),
                passwordConfirmation: Yup.string()
                  .min(6)
                  .max(128)
                  .label('Password Confirmation')
                  .test(
                    'passwords-match',
                    'Passwords must match',
                    function (value) {
                      return this.parent.password === value;
                    },
                  ),
                currentPassword: Yup.string()
                  .min(6)
                  .max(128)
                  .label('Current Password')
                  .required(),
                avatar: Yup.string().min(1).label('Avatar'),
              })}
              validateOnBlur={false}
              validateOnChange={false}>
              {(formik) => {
                return (
                  <Form>
                    <InputField
                      placeholder="E.g. johnsmith@gmail.com"
                      labelProps={{
                        title: 'Email address',
                      }}
                      name="email"
                      type="email"
                      formik={formik}
                      autoComplete="email"
                    />
                    <small className="text-secondary tw-block mt-2 mb-4">
                      ( You will need to confirm your new email address if you
                      change it )
                    </small>

                    <FormRow responsive>
                      <FormRowItem>
                        <InputField
                          placeholder="*********"
                          labelProps={{
                            title: 'New password',
                            description:
                              "Leave blank if you don't want to change it",
                          }}
                          name="password"
                          formik={formik}
                          type="password"
                          autoComplete="new-password"
                        />
                      </FormRowItem>
                      <FormRowItem>
                        <InputField
                          placeholder="*********"
                          labelProps={{
                            title: 'Confirm new password',
                            description:
                              'Please input if you are changing your password',
                          }}
                          name="passwordConfirmation"
                          formik={formik}
                          type="password"
                          autoComplete="new-password"
                        />
                      </FormRowItem>
                    </FormRow>
                    <div>
                      <InputField
                        placeholder="*********"
                        labelProps={{
                          title: 'Current password',
                          description:
                            'We need this to confirm your changes. You may need to reset your password if you signed in with Google or Facebook.',
                        }}
                        name="currentPassword"
                        formik={formik}
                        type="password"
                        autoComplete="password"
                      />
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Update Information"
                      submittingText="Saving"
                    />
                  </Form>
                );
              }}
            </Formik>
          </Card>

          {currentUser.roles.includes(AccountRole.Landlord) && (
            <Card icon={HiOutlineUser} title="Landlord Profile">
              {!data.landlordProfile.isPersisted ? (
                <div>
                  <div tabIndex={0} className="collapse collapse-arrow">
                    <input type="checkbox" />
                    <div className="collapse-title">
                      <Paragraph>
                        You do not currently have a landlord profile, click here
                        to reveal the form to create one. Doing so will allow
                        you to manage your rentals with Keyhook.
                      </Paragraph>
                    </div>
                    <div className="collapse-content">
                      <LandlordProfileForm
                        model={data.landlordProfile}
                        submitHandler={saveLandlordProfile}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <LandlordProfileForm
                  model={data.landlordProfile}
                  submitHandler={saveLandlordProfile}
                />
              )}
            </Card>
          )}

          {currentUser.roles.includes(AccountRole.Renter) && (
            <Card title="Renter Profile">
              {!data.renterProfile.isPersisted ? (
                <div>
                  <div tabIndex={0} className="collapse collapse-arrow">
                    <input type="checkbox" />
                    <div className="collapse-title">
                      <p className="mb-2">
                        You do not currently have a renter profile, click here
                        to reveal the form to create one. Doing so will allow
                        you to rent properties on Keyhook.
                      </p>
                    </div>
                    <div className="collapse-content">
                      <RenterProfileForm
                        model={data.renterProfile}
                        submitHandler={saveRenterProfile}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <RenterProfileForm
                  model={data.renterProfile}
                  submitHandler={saveRenterProfile}
                />
              )}
            </Card>
          )}
        </>
      )}
    </Page>
  );
};

export default MyAccountPage;
