import {FunctionComponent} from 'react';

import clsx from 'clsx';
import {HiOutlineHome} from 'react-icons/hi';
import {useQuery} from 'react-query';

import {
  Card,
  ContentError,
  EmptyContentPlaceholder,
} from 'components_sb/layout';
import Property from 'models/properties/Property';

import PropertyBilling from './PropertyBilling/PropertyBilling';

export const LANDLORD_PROPERTIES_BILLING_QUERY_KEY =
  'landlord_properties_billing';

const SKELETON_COUNT = 6;

/**
 * A list of all of the Landlord's properties and the corresponding billing
 * status and method for the properties.
 */
const PropertiesBillingSection: FunctionComponent = () => {
  /**
   * Fetch all properties for the Landlord.
   */
  const propertiesQuery = useQuery(
    LANDLORD_PROPERTIES_BILLING_QUERY_KEY,
    async () =>
      (
        await Property.includes(['current_tenancy', 'billing_method'])
          .per(200)
          .order({
            created_at: 'desc',
          })
          .all()
      ).data,
  );

  /**
   * Deconstruct the properties from the query.
   */
  const {data: properties} = propertiesQuery;

  return (
    <Card cssQueryContainer icon={HiOutlineHome} title="Property billing">
      {/* Error */}
      {propertiesQuery.isError && (
        <ContentError message="Sorry, there was an issue loading your properties." />
      )}

      {/* No properties */}
      {propertiesQuery.isSuccess && properties.length === 0 && (
        <div>
          <EmptyContentPlaceholder
            testId="properties-billing"
            type="property"
            message="You do not currently have any properties to manage billing for."
          />
        </div>
      )}

      {/* Loading / Success */}
      <div className="flex flex-col gap-y-4">
        <div
          className={clsx(
            'flex flex-row flex-wrap gap-4',
            'grid gap-4',
            'grid-cols-1',
            '@3xl:grid-cols-2',
            '@6xl:grid-cols-3',
          )}>
          {/* List of properties with corresponding status and billing method */}
          {propertiesQuery.isSuccess
            ? properties.map((property) => (
                <PropertyBilling key={property.id} property={property} />
              ))
            : [...Array(SKELETON_COUNT).keys()].map((index) => (
                <PropertyBilling key={index} />
              ))}
        </div>
        {/* TODO: Add total current subscription fees across properties */}
        {/* <div className="text-xl">Total: TODO</div> */}
      </div>
    </Card>
  );
};

export default PropertiesBillingSection;
