import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {UnmountClosed} from 'react-collapse';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {CountableNumberField} from 'components/forms_fields';
import PetTypeField from 'components/forms_fields/PetTypeField';
import StepContainer from 'components/walkthrough/common/StepContainer';
import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {isDefined} from 'utilities/MiscHelpers';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  petsAllowed: boolean;
  allowedPetTypes: string[];
  smokingAllowed: boolean;
  maxTenants: number;
};

const RequirementsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPropertyRequirementsStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the property.
       */
      property.assignAttributes(formValues);
      property.smokersAllowed = formValues.smokingAllowed;
      property.lastOnboardingStepCompleted = 'listing_requirements';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the listing.
       */
      listing.assignAttributes(formValues);

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        setSubmitting(false);
        return;
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompletePropertyRequirementsStep,
        {
          propertyId: property.id,
          listingId: listing.id,
        },
      );

      actions.setSubmitting(false);

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );
      setSubmitting(false);
    },
    [listing, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        petsAllowed: isDefined(listing.petsAllowed)
          ? listing.petsAllowed
          : null,
        allowedPetTypes: listing.allowedPetTypes || [],
        smokingAllowed: isDefined(listing.smokingAllowed)
          ? listing.smokingAllowed
          : null,
        maxTenants: listing.maxTenants || 1,
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        petsAllowed: Yup.boolean().required().label('Pets Allowed?'),
        allowedPetTypes: Yup.array()
          .optional()
          .nullable()
          .label('Allowed Pet Types')
          .min(0),
        smokingAllowed: Yup.boolean()
          .nullable()
          .required()
          .label('Smokers Allowed?'),

        maxTenants: Yup.number()
          .min(1)
          .max(20)
          .required()
          .label('Max Number of Tenants'),
      }),
    }),
    [handleSubmit, listing],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer title="Select your property requirements" subtitle="">
      <div className="flex w-full">
        <BooleanSelect
          labelProps={{
            title: 'Are pets allowed?',
            size: 'xl',
          }}
          preset="yes/no"
          mode="formik"
          name="petsAllowed"
          form={form}
        />
      </div>

      <div className="flex flex-col w-full">
        <UnmountClosed isOpened={form.values.petsAllowed}>
          <PetTypeField
            name="allowedPetTypes"
            form={form}
            labelProps={{
              title: 'Which pets are allowed?',
              size: 'xl',
            }}
          />
        </UnmountClosed>
      </div>

      <div className="flex w-full">
        <BooleanSelect
          labelProps={{
            title: 'Is smoking allowed?',
            size: 'xl',
          }}
          preset="yes/no"
          mode="formik"
          name="smokingAllowed"
          form={form}
        />
      </div>

      <div className="flex w-full">
        <CountableNumberField
          form={form}
          name="maxTenants"
          labelProps={{
            title: 'Max tenants',
            size: 'xl',
          }}
        />
      </div>
    </StepContainer>
  );
};

export default RequirementsStep;
