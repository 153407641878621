import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import RentalApplication from 'models/listings/RentalApplication';

export enum ListingRentalApplicationTag {
  Shortlisted = 'shortlisted',
  Excluded = 'excluded',
}

@Model()
class ListingRentalApplication extends ApplicationRecord {
  static jsonapiType = 'listing_rental_applications';

  @Attr() accepted: boolean;
  @Attr() landlordNotes: string;
  @Attr() landlordTag: ListingRentalApplicationTag | null;

  @Attr() rentalApplicationId: string;
  @Attr() listingId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('rental_applications') rentalApplication: RentalApplication;
  @BelongsTo('listings') listing: Listing;
}

export default ListingRentalApplication;
