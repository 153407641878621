import {useEffect, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import Tracker from '@openreplay/tracker';
import * as Sentry from '@sentry/browser';

import useAuth from 'auth/provider/useAuth';
import {TARGET_ENV} from 'globals/app-globals';

/**
 * A hook to configure OpenReplay user tracking.
 */
const useOpenReplay = () => {
  const {currentUser} = useAuth();

  const tracker = useMemo(() => {
    try {
      const tracker = new Tracker({
        projectKey: 'xx4EH6mHyZE7ArR8lYFT',
        ingestPoint: 'https://sessions.keyhook.com/ingest',
        onStart: ({sessionToken}) => {
          // This is to link from OpenReplay -> Sentry
          Sentry.setTag('openReplaySessionToken', sessionToken);

          // This is to link from Sentry -> OpenReplay (requires tracker v3.6.0+)
          Sentry.setTag('openReplaySessionURL', tracker.getSessionURL());
        },
        __DISABLE_SECURE_MODE:
          TARGET_ENV === 'development' || Capacitor.isNativePlatform(),
        respectDoNotTrack: false,
      });

      return tracker;
    } catch (e) {
      return null;
    }
  }, []);

  useEffect(() => {
    try {
      const allowedEnvironments = ['production', 'staging'];
      if (allowedEnvironments.includes(TARGET_ENV) && currentUser) {
        if (tracker) {
          if (!tracker.isActive()) {
            tracker
              .start()
              .then(() => {
                tracker.setUserID(`${currentUser.id}-${TARGET_ENV}`);
                tracker.setMetadata('email', currentUser.email);
                tracker.setMetadata('name', currentUser.name);
                tracker.setMetadata('roles', currentUser.roles.join(','));
                tracker.setMetadata('environment', TARGET_ENV);
              })
              .catch((e) => console.log(e));
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [tracker, currentUser]);
};

export default useOpenReplay;
