import '@dotlottie/player-component';
import {useCallback, useImperativeHandle} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {latestVersion} from '@capgo/capacitor-updater';

import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';

const AppStoreUpdateAvailableModal: ModalDefinition = {
  title: 'App Update Available',
  buttonsConfig: {
    cancel: {
      label: 'Update Later',
    },
    actions: [
      {
        id: 'go-to-app-store',
        label: {
          idle: 'Go To App Store',
          loading: 'Opening App Store...',
        },
        handle: 'openAppStore',
        closeOnSuccess: true,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {latestVersion} = props as {latestVersion: latestVersion};

    const openAppStore = useCallback(async () => {
      if (Capacitor.getPlatform() === 'ios') {
        await Browser.open({
          url: 'https://apps.apple.com/us/app/propup/id1570942532',
        });
      } else {
        await Browser.open({
          url: 'https://play.google.com/store/apps/details?id=com.getpropup.app',
        });
      }
      return true;
    }, []);

    useImperativeHandle(ref, () => ({
      openAppStore,
    }));

    return (
      <div className="mt-2">
        <div className="flex justify-center w-full mt-2">
          <dotlottie-player
            autoplay
            loop
            mode="normal"
            src="/assets/lottie/app-store-update.lottie"
            style={{height: 250, width: 250}}
          />
        </div>
        <Paragraph>
          Version {latestVersion.version} is available on the App Store. Click
          the button below to be taken to the app store to update, we highly
          recommending updating now.
        </Paragraph>
      </div>
    );
  },
};

export default AppStoreUpdateAvailableModal;
