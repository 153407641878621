import {useQuery, useQueryClient} from 'react-query';

import NewRentPriceChangeForm from 'components/rent_price_changes/NewRentPriceChangeForm';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Tenancy from 'models/properties/Tenancy';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

const NewRentPriceChangePage = () => {
  const {
    params: {tenancyId, propertyId},
  } = useRoute();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-new-rent-change`,
    async () => {
      const tenancy = await Tenancy.select([
        'id',
        'start_date',
        'end_date',
        'rental_period',
        'total_rent',
      ]).find(tenancyId);

      return tenancy.data;
    },
  );
  const queryClient = useQueryClient();
  const router = useRouter();

  const onSuccess = async () => {
    await queryClient.invalidateQueries([
      'property',
      {id: propertyId, context: 'detail-page'},
    ]);
    router.navigate(`/properties/${propertyId}`, {reloadCurrent: true});
  };

  return (
    <Page title="Rent Change" loading={isLoading} error={error}>
      {() => (
        <Card title="Rent Change" className="overflow-visible">
          <Paragraph>
            Please note that under the new NZ tenancy laws, you are only allowed
            to increase rent once every 12 months. You must also provide your
            tenants with a minimum of 60 days notice.
          </Paragraph>
          <Paragraph>
            Keyhook also only allow days that match up with the day of the week
            your tenants currently pay rent.
          </Paragraph>

          <NewRentPriceChangeForm tenancy={data} onSuccess={onSuccess} />
        </Card>
      )}
    </Page>
  );
};

export default NewRentPriceChangePage;
