import React, {useMemo, useState} from 'react';

import moment from 'moment';
import DataTable, {type TableColumn} from 'react-data-table-component';
import {useQuery} from 'react-query';

import ManageListingMenu from 'components/listing/ManageListingMenu';
import RentalApplicationSummaryView from 'components/rental_application/RentalApplicationSummaryView';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Listing from 'models/listings/Listing';
import ListingRentalApplication, {
  ListingRentalApplicationTag,
} from 'models/listings/ListingRentalApplication';
import {Page, RouterLink} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {Action} from 'types/actions';

type PseudoBoolean = 'Unspecified' | 'Yes' | 'No' | undefined;

const pseudoBooleanFormatter = (val: PseudoBoolean) => {
  if (val === 'Yes') {
    return '';
  } else if (val === 'No') {
    return 'X';
  } else {
    return 'Unspecified';
  }
};

const columns: TableColumn<ListingRentalApplication>[] = [
  {
    name: 'Name',
    selector: (row) => row.rentalApplication.headTenantName,
    sortable: false,
    reorder: true,
  },
  {
    name: 'Date Applied',
    selector: (row) => row.createdAt,
    format: (row) => moment(row.createdAt).format('DD MMM'),
    sortable: false,
    reorder: true,
  },
  {
    name: 'Other Tenants',
    selector: (row) => row.rentalApplication.rentalApplicationApplicantsCount,
    sortable: false,
    reorder: true,
  },
  {
    name: 'Pets',
    selector: (row) => row.rentalApplication.hasPets,
    format: (row) => pseudoBooleanFormatter(row.rentalApplication.hasPets),
    sortable: false,
    reorder: true,
  },
  {
    name: 'Smoker',
    selector: (row) => row.rentalApplication.anySmokers,
    format: (row) => pseudoBooleanFormatter(row.rentalApplication.anySmokers),
    sortable: false,
    reorder: true,
  },
  {
    name: 'Preferred Start Date',
    selector: (row) => row.rentalApplication.preferredTenancyStartDate,
    format: (row) =>
      row.rentalApplication.preferredTenancyStartDate
        ? moment(row.rentalApplication.preferredTenancyStartDate).format(
            'Do MMMM',
          )
        : '-',
    sortable: false,
    reorder: true,
  },
  {
    name: 'Preferred Tenancy Length',
    selector: (row) => row.rentalApplication.preferredTenancyLength,
    format: (row) =>
      row.rentalApplication.preferredTenancyLength
        ? row.rentalApplication.preferredTenancyLength + ' Months'
        : '-',
    sortable: false,
    reorder: true,
  },
];

const conditionalRowStyles = [
  {
    when: (row: ListingRentalApplication) =>
      row.landlordTag === ListingRentalApplicationTag.Shortlisted,
    classNames: ['border-l-8 border-success'],
  },
  {
    when: (row: ListingRentalApplication) =>
      row.landlordTag === ListingRentalApplicationTag.Excluded,
    classNames: ['border-l-8 border-error'],
  },
];

const RentalApplicationIndexPage = () => {
  const {
    params: {propertyId, listingPublicId},
  } = useRoute();

  const useListView = !useTailwindBreakpoint('md');

  const [excludePets, setExcludePets] = useState(false);
  const [excludeSmokers, setExcludeSmokers] = useState(false);
  const [showShortlisted, setShowShortlisted] = useState(false);
  const [showArchived, setSetShowArchived] = useState(false);
  const [hideInsufficientReferences, setHideInsufficientReferences] =
    useState(false);

  const router = useRouter();

  const {data: listing, isLoading: listingIsLoading} = useQuery(
    `listing-id-only-${listingPublicId}`,
    async () => {
      const l = await Listing.select(['id']).find(listingPublicId);

      return l.data;
    },
  );

  const {data, isLoading, error} = useQuery(
    ['listing-applications', listingPublicId],
    async () => {
      const apps = await ListingRentalApplication.includes('rental_application')
        .where({listingId: listing.id})
        .per(1000)
        .all();

      return apps.data;
    },
    {
      enabled: !!listing,
    },
  );

  const filteredItems = useMemo((): ListingRentalApplication[] => {
    if (!data) {
      return [];
    }

    const filtered = [];

    for (const lra of data) {
      if (excludePets && lra.rentalApplication.hasPets === 'Yes') {
        continue;
      }

      if (excludeSmokers && lra.rentalApplication.anySmokers === 'Yes') {
        continue;
      }

      if (
        showShortlisted &&
        lra.landlordTag !== ListingRentalApplicationTag.Shortlisted
      ) {
        continue;
      }

      if (
        !showArchived &&
        lra.landlordTag === ListingRentalApplicationTag.Excluded
      ) {
        continue;
      }

      // Check to see we have names for all 3 references.
      if (
        hideInsufficientReferences &&
        (!lra.rentalApplication.employerReferenceName ||
          !lra.rentalApplication.landlordReferenceName ||
          !lra.rentalApplication.otherReferenceName)
      ) {
        continue;
      }

      filtered.push(lra);
    }

    return filtered;
  }, [
    data,
    excludePets,
    excludeSmokers,
    showShortlisted,
    showArchived,
    hideInsufficientReferences,
  ]);

  const acceptedApplication = useMemo(() => {
    const list = data || ([] as ListingRentalApplication[]);

    return list.find((lra) => lra.accepted);
  }, [data]);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !listingPublicId
        ? null
        : [
            {
              label: 'View listing ad',
              linkTo: `/listings/${listingPublicId}`,
            },
          ],
    [listingPublicId],
  );

  if (error) {
    return <Page title="Applications" error={error} />;
  } else if (useListView) {
    return (
      <Page title="Applications" actions={pageActions}>
        {() => (
          <>
            <ManageListingMenu
              propertyId={propertyId}
              listingPublicId={listingPublicId}
            />{' '}
            {acceptedApplication && (
              <div className="alert alert-success shadow-lg mb-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>
                    You have accepted an application from{' '}
                    {acceptedApplication.rentalApplication.headTenantName}.{' '}
                    <RouterLink
                      href={acceptedApplication.id}
                      className="text-white font-bold underline">
                      Click here
                    </RouterLink>{' '}
                    to view it.
                  </span>
                </div>
              </div>
            )}
            <Card title="Filters" className="mb-4">
              <div className="flex flex-wrap justify-start items-center gap-x-2 gap-y-2">
                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="No Pets"
                    category={excludePets ? 'primary' : 'secondary'}
                    onClick={() => setExcludePets((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Non Smokers"
                    category={excludeSmokers ? 'primary' : 'secondary'}
                    onClick={() => setExcludeSmokers((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Promote Shortlisted"
                    category={showShortlisted ? 'primary' : 'secondary'}
                    onClick={() => setShowShortlisted((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Show Archived"
                    category={showArchived ? 'primary' : 'secondary'}
                    onClick={() => setSetShowArchived((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Has References"
                    category={
                      hideInsufficientReferences ? 'primary' : 'secondary'
                    }
                    onClick={() =>
                      setHideInsufficientReferences((prevVal) => !prevVal)
                    }
                    fillWidth={false}
                  />
                </div>
              </div>
            </Card>
            <div className="mb-4">
              <h2 className="card-title mb-4 text-brand-850 flex justify-between">
                Applications
              </h2>
            </div>
            <div className="flex flex-col space-y-4">
              {filteredItems.map((row) => (
                <RouterLink key={row.id} href={row.id}>
                  <Card
                    title={`${row.rentalApplication.headTenantName} - ${moment(
                      row.createdAt,
                    ).format('DD MMM')}`}>
                    <div className="text-sm">
                      <span className="font-medium">Other Tenants: </span>
                      <span>
                        {row.rentalApplication
                          .rentalApplicationApplicantsCount === 0
                          ? 'None'
                          : row.rentalApplication
                              .rentalApplicationApplicantsCount}
                      </span>
                    </div>

                    <div className="grid grid-cols-2">
                      <div className="text-sm">
                        <span className="font-medium">Pets?: </span>
                        <span>{row.rentalApplication.hasPets}</span>
                      </div>

                      <div className="text-sm">
                        <span className="font-medium">Smoker?: </span>
                        <span>{row.rentalApplication.anySmokers}</span>
                      </div>
                    </div>

                    <div className="text-sm">
                      <span className="font-medium">
                        Preferred Start Date:{' '}
                      </span>
                      <span>
                        {row.rentalApplication.preferredTenancyStartDate
                          ? moment(
                              row.rentalApplication.preferredTenancyStartDate,
                            ).format('Do MMMM')
                          : 'Unspecified'}
                      </span>
                    </div>

                    <div className="text-sm">
                      <span className="font-medium">
                        Preferred Tenancy Length:{' '}
                      </span>
                      <span>
                        {row.rentalApplication.preferredTenancyLength
                          ? row.rentalApplication.preferredTenancyLength +
                            ' Months'
                          : 'Unspecified'}
                      </span>
                    </div>
                  </Card>
                </RouterLink>
              ))}
            </div>
          </>
        )}
      </Page>
    );
  } else {
    return (
      <Page title="Applications" actions={pageActions}>
        {() => (
          <>
            <ManageListingMenu
              propertyId={propertyId}
              listingPublicId={listingPublicId}
            />
            {acceptedApplication && (
              <div className="alert alert-success shadow-lg mb-4">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current flex-shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>
                    You have accepted an application from{' '}
                    {acceptedApplication.rentalApplication.headTenantName}.{' '}
                    <RouterLink
                      href={acceptedApplication.id}
                      className="text-white font-bold underline">
                      Click here
                    </RouterLink>
                    {` to view it.`}
                  </span>
                </div>
              </div>
            )}
            <Card title="Filters" className="mb-4">
              <div className="flex flex-wrap justify-start items-center gap-x-2 gap-y-2">
                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="No Pets"
                    category={excludePets ? 'primary' : 'secondary'}
                    onClick={() => setExcludePets((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Non Smokers"
                    category={excludeSmokers ? 'primary' : 'secondary'}
                    onClick={() => setExcludeSmokers((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Promote Shortlisted"
                    category={showShortlisted ? 'primary' : 'secondary'}
                    onClick={() => setShowShortlisted((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Show Archived"
                    category={showArchived ? 'primary' : 'secondary'}
                    onClick={() => setSetShowArchived((prevVal) => !prevVal)}
                    fillWidth={false}
                  />
                </div>

                <div>
                  <Button
                    size="sm"
                    mode="manual"
                    label="Has References"
                    category={
                      hideInsufficientReferences ? 'primary' : 'secondary'
                    }
                    onClick={() =>
                      setHideInsufficientReferences((prevVal) => !prevVal)
                    }
                    fillWidth={false}
                  />
                </div>
              </div>
            </Card>
            <DataTable
              columns={columns}
              data={filteredItems}
              selectableRows={false}
              expandableRows
              expandableRowsComponent={RentalApplicationSummaryView}
              progressPending={listingIsLoading || isLoading}
              highlightOnHover
              striped
              pointerOnHover
              fixedHeader
              fixedHeaderScrollHeight="300"
              responsive
              pagination
              onRowClicked={(row) => {
                router.navigate(row.id);
                // if (Capacitor.isNativePlatform()) {

                // } else {
                //   if (window.location.href.endsWith('/')) {
                //     window.open(window.location.href + row.id, '_blank');
                //   } else {
                //     window.open(window.location.href + '/' + row.id, '_blank');
                //   }
                // }
              }}
              conditionalRowStyles={conditionalRowStyles}
            />
          </>
        )}
      </Page>
    );
  }
};

export default RentalApplicationIndexPage;
