import {FunctionComponent} from 'react';

import ChatIndexCard from 'components/chat2/ChatIndexCard';
import {ChatChannel} from 'services/PusherService';

interface ConversationsListProps {
  channels: ChatChannel[];
}

const ConversationsList: FunctionComponent<ConversationsListProps> = ({
  channels,
}) =>
  !channels || channels.length === 0 ? null : (
    <ul>
      {channels.map((channel, index) => (
        <ChatIndexCard key={index} channel={channel} />
        // TODO: Refactor the chat index card to be consistent with the MobileListItem component
        // <MobileListItem
        //   key={index} // <-- Replace this with the channel ID when available
        //   onClick={}
        //   title=""
        //   subtitle=""
        // />
      ))}
    </ul>
  );

export default ConversationsList;
