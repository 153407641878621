import {ReactNode, createContext, useCallback, useMemo, useState} from 'react';

import {omit} from 'lodash';
import {SwipeCallback, useSwipeable} from 'react-swipeable';

import {SwipeCallbacksPartial} from './types';

interface AddListenerFunction {
  (id: string, callbacks: SwipeCallbacksPartial): void;
}

interface RemoveListenerFunction {
  (id: string): void;
}

interface GlobalSwipeContextValue {
  addListener: AddListenerFunction;
  removeListener: RemoveListenerFunction;
}

export const GlobalSwipeContext = createContext<GlobalSwipeContextValue>(
  {} as GlobalSwipeContextValue,
);

interface GlobalSwipeProviderProps {
  children: ReactNode;
}

/**
 * Enables accessing swipe events from anywhere in the app.
 */
const GlobalSwipeProvider = ({children}: GlobalSwipeProviderProps) => {
  /**
   * The current listeners.
   */
  const [listeners, setListeners] = useState<{
    [id: string]: SwipeCallbacksPartial;
  }>({});

  /**
   * After any swipe.
   */
  const onSwiped = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current listeners.
       */
      Object.values(listeners).forEach(({onSwiped}) => {
        if (onSwiped) {
          onSwiped(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * After LEFT swipe.
   */
  const onSwipedLeft = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current refs.
       */
      Object.values(listeners).forEach(({onSwipedLeft}) => {
        if (onSwipedLeft) {
          onSwipedLeft(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * After RIGHT swipe.
   */
  const onSwipedRight = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current listeners.
       */
      Object.values(listeners).forEach(({onSwipedRight}) => {
        if (onSwipedRight) {
          onSwipedRight(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * After UP swipe.
   */
  const onSwipedUp = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current listeners.
       */
      Object.values(listeners).forEach(({onSwipedUp}) => {
        if (onSwipedUp) {
          onSwipedUp(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * After DOWN swipe.
   */
  const onSwipedDown = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current listeners.
       */
      Object.values(listeners).forEach(({onSwipedDown}) => {
        if (onSwipedDown) {
          onSwipedDown(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * Start of swipe.
   */
  const onSwipeStart = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current listeners.
       */
      Object.values(listeners).forEach(({onSwipeStart}) => {
        if (onSwipeStart) {
          onSwipeStart(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * Start of swipe.
   */
  const onSwiping = useCallback<SwipeCallback>(
    (eventData) => {
      /**
       * Invoke the callback on all of the current listeners.
       */
      Object.values(listeners).forEach(({onSwiping}) => {
        if (onSwiping) {
          onSwiping(eventData);
        }
      });
    },
    [listeners],
  );

  /**
   * Create the swipe handlers.
   */
  const handlers = useSwipeable({
    /**
     * Event callbacks.
     */
    onSwiped,
    onSwipedLeft,
    onSwipedRight,
    onSwipedUp,
    onSwipedDown,
    onSwipeStart,
    onSwiping,

    /**
     * Min distance( px) before a swipe starts.
     */
    delta: 10,

    /**
     * Allow scrolling at the same time as swiping.
     * (this avoids unresponsive scrolling)
     */
    preventScrollOnSwipe: false,

    /**
     * Only allow touch events (ignore mouse swiping).
     */
    trackTouch: true,
    trackMouse: false,

    /**
     * Swipes longer than this value (milliseconds) will be ignored.
     */
    swipeDuration: 250,
  });

  /**
   * Add a new listener.
   */
  const handleAddListener = useCallback<AddListenerFunction>(
    (id, callbacks) => {
      setListeners((current) => ({...current, [id]: callbacks}));
    },
    [],
  );

  /**
   * Remove an existing listener.
   */
  const handleRemoveListener = useCallback<RemoveListenerFunction>((id) => {
    setListeners((current) => omit(current, id));
  }, []);

  /**
   * Create the context value.
   */
  const contextValue = useMemo(
    () => ({
      addListener: handleAddListener,
      removeListener: handleRemoveListener,
    }),
    [handleAddListener, handleRemoveListener],
  );

  return (
    <GlobalSwipeContext.Provider value={contextValue}>
      <div className="w-full h-full" {...handlers}>
        {children}
      </div>
    </GlobalSwipeContext.Provider>
  );
};

export default GlobalSwipeProvider;
