import {FunctionComponent, ReactNode, useCallback, useEffect} from 'react';

import {Button} from 'components_sb/buttons';
import {Title} from 'components_sb/typography';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {AccountRole} from 'models/users/User';

import KeyHandoverAnimation from '../../animated-svgs/key-handover';

interface WelcomeScreenProps {
  onSelectAccountType: (accountType: AccountRole) => void;
  loading?: boolean;
  additionalContent?: ReactNode;
}

const WelcomeScreen: FunctionComponent<WelcomeScreenProps> = ({
  onSelectAccountType,
  loading = false,
  additionalContent,
}) => {
  /**
   * Start animation shortly after mount.
   */
  useEffect(() => {
    window.setTimeout(() => {
      const element = document.querySelector('#freepik_stories-apartment-rent');
      if (element) {
        element.classList.add('animated');
      }
    }, 1000);
  }, []);

  /**
   * Handle selecting landlord as the account type.
   */
  const onSelectLandlord = useCallback(() => {
    onSelectAccountType(AccountRole.Landlord);
  }, [onSelectAccountType]);

  /**
   * Handle selecting tenant as the account type.
   */
  const onSelectTenant = useCallback(() => {
    onSelectAccountType(AccountRole.Renter);
  }, [onSelectAccountType]);

  return (
    <div className="w-full max-w-3xl flex flex-col gap-y-4 sm:gap-y-8 justify-center items-center">
      <div className="text-left md:text-center">
        <Title level="h1" size="2xl">
          Welcome to Keyhook
        </Title>
        <p className="text-brand-850 text-opacity-70 text-xl font-medium">
          Let's start creating better tenancies together.
        </p>
      </div>
      <div className="w-full max-w-xs sm:max-w-sm xl:max-w-md">
        <KeyHandoverAnimation />
      </div>
      <div className="flex flex-col items-center gap-y-6 w-full text-sm">
        <Paragraph>
          To get started, let us know what best describes you:
        </Paragraph>
        <div className="w-full flex flex-col md:flex-row gap-x-6 gap-y-4 max-w-md md:max-w-2xl">
          <Button
            label="I'm a landlord"
            category="primary"
            size="lg"
            mode="manual"
            onClick={onSelectLandlord}
            disabled={loading}
          />
          <Button
            label="I'm a tenant"
            category="primary"
            size="lg"
            mode="manual"
            onClick={onSelectTenant}
            disabled={loading}
          />
        </div>
        {additionalContent}
      </div>
    </div>
  );
};

export default WelcomeScreen;
