import React from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {SubmitButton} from 'components/forms_fields';
import StackedButtonSelectField from 'components/forms_fields/StackedButtonSelectField';
import {Card} from 'components_sb/layout';
import Inspection from 'models/inspections/Inspection';
import Tenancy from 'models/properties/Tenancy';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {errorViewForError} from 'utilities/ErrorHelpers';

type FormValues = {
  inspectionPeriodType: string;
  reportType: string;
};

const NewInspectionPage = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const router = useRouter();
  const route = useRoute();

  // Just acts a permission check
  const {data, isLoading, error} = useQuery(
    `new-inspection-property-${propertyId}`,
    async () => {
      const result = await Tenancy.where({property_id: propertyId})
        .order({created_at: 'desc'})
        .first();

      return result.data;
    },
  );

  const handleSubmit = async (
    formValues: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const inspection = new Inspection();
    inspection.assignAttributes(formValues);
    inspection.doneBy = 'landlord';
    inspection.status = 'awaiting_inspection';
    inspection.estimatedDate = moment().format('YYYY-MM-DD');
    inspection.finalizedDate = moment().startOf('hour').toISOString();
    inspection.tenancyId = data.id;

    const result = await inspection.save();
    if (result) {
      toast.success('Inspection successfully created!');

      const newPath = route.path.replace('new', inspection.id);
      // Replace the current history entry so that the user can't go back to the
      // new inspection page.
      router.navigate(newPath, {reloadCurrent: true});
    } else {
      for (const key of Object.keys(inspection.errors)) {
        const message = inspection.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return <Page title="New Inspection" loading />;
  } else {
    return (
      <Page title="New Inspection">
        {() => (
          <Formik
            initialValues={
              {
                reportType: '',
                inspectionPeriodType: '',
              } as FormValues
            }
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              reportType: Yup.string()
                .required()
                .oneOf(['detailed', 'basic'])
                .label('Report type'),
              inspectionPeriodType: Yup.string()
                .oneOf(['pre_tenancy', 'normal', 'final'])
                .required()
                .nullable()
                .label('Inspection type'),
            })}
            onSubmit={handleSubmit}>
            {(formik) => (
              <Form>
                <Card title="New Inspection" className="mt-4 overflow-visible">
                  <StackedButtonSelectField
                    label="Report Type"
                    helpText="Detailed reports will guide you through a detailed inspection.
                Basic reports will enable you to inspect the property unguided."
                    name="reportType"
                    formik={formik}
                    options={[
                      {label: 'Detailed', value: 'detailed'},
                      {label: 'Basic', value: 'basic'},
                    ]}
                  />

                  <StackedButtonSelectField
                    label="Inspection Type"
                    name="inspectionPeriodType"
                    formik={formik}
                    options={[
                      {label: 'Pre-tenancy', value: 'pre_tenancy'},
                      {label: 'Routine', value: 'normal'},
                      {label: 'Final', value: 'final'},
                    ]}
                  />

                  <SubmitButton
                    className="mt-4"
                    formik={formik}
                    submittingText="Updating"
                    text="Save"
                  />
                </Card>
              </Form>
            )}
          </Formik>
        )}
      </Page>
    );
  }
};

export default NewInspectionPage;
