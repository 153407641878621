import {RouterLink} from 'router/components';

/**
 * Returns either the appropriate component type depending on whether
 * a given link is internal or external.
 * @param linkTo A link to either an internal or external destination.
 */
const getLinkComponent = (href: string) => {
  // Use the default anchor link if the link is external ( starts with http ) or
  // the react router link if the link is internal.
  return href.startsWith('http') ? 'a' : RouterLink;
};

export default getLinkComponent;
