import React, {Fragment, FC} from 'react';

import {Popover, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/outline';
import {BiBuildingHouse} from '@react-icons/all-files/bi/BiBuildingHouse';
import moment from 'moment';

import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

export interface PropertyTypeSelectProps {
  property: Property;
  tenancies: Tenancy[];
  selectedTenancy: Tenancy;
  setSelectedTenancyId: (id: string) => void;
}

const TenancySelectField: FC<PropertyTypeSelectProps> = ({
  property,
  tenancies,
  selectedTenancy,
  setSelectedTenancyId,
}) => {
  const tenancy = selectedTenancy;

  const hasDraftTenancy =
    tenancies.filter((t) => t.status === 'draft').length > 0;

  const selectTenancy = (id: string) => {
    setSelectedTenancyId(id);
    const el = document.getElementsByClassName(
      'tenancy-select-field-button',
    )[0];
    (el as HTMLButtonElement).click();
  };

  const renderSelected = () => {
    if (!tenancy) {
      return (
        <div className="flex-grow">
          <span className="tw-block text-sm lg:text-lg font-semibold">
            Next Tenancy
          </span>

          <span className="tw-block mt-1 text-xs text-neutral-400 leading-none font-light ">
            Prepare your next tenancy
          </span>
        </div>
      );
    } else {
      return (
        <div className="flex-grow">
          {tenancy.startDate ? (
            <span className="tw-block text-sm lg:text-lg font-semibold">
              {moment(tenancy.startDate).format('DD MMMM YYYY')} -{' '}
              {titleize(removeUnderscores(tenancy.status))}
            </span>
          ) : (
            <span className="tw-block text-sm lg:text-lg font-semibold">
              Draft Tenancy
            </span>
          )}

          {tenancy.tenancyMemberships.length === 0 ? (
            <span className="tw-block mt-1 text-xs text-neutral-400 leading-none font-light ">
              No Assigned Tenants
            </span>
          ) : (
            <span className="tw-block mt-1 text-xs text-neutral-400 leading-none font-light ">
              {tenancy.tenancyMemberships
                .map((tm) => tm.renter.name)
                .join(', ')}
            </span>
          )}
        </div>
      );
    }
  };

  return (
    <Popover className="flex relative [ nc-flex-1 ] mt-2">
      {({open}) => (
        <>
          <Popover.Button
            className={`tenancy-select-field-button flex text-left w-full flex-shrink-0 items-center py-2 px-4 space-x-3 focus:outline-none cursor-pointer border-3 border-gray-200 ${
              open ? 'shadow-2xl dark:bg-neutral-800' : ''
            }`}>
            <div className="text-neutral-300 dark:text-neutral-400">
              <BiBuildingHouse className="w-8 h-8" />
            </div>
            {renderSelected()}
            <div>
              <ChevronDownIcon className="w-4 h-4 text-gray ml-4" />
            </div>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute left-0 z-10 w-full bg-white top-full py-4 sm:py-6 px-4 sm:px-8 rounded-sm shadow-xl">
              <div>
                <div className="relative flex flex-col">
                  {!hasDraftTenancy && (
                    <div
                      className="cursor-pointer"
                      onClick={() => selectTenancy('-1')}>
                      <div className="flex-grow">
                        <span className="tw-block text-sm lg:text-md font-semibold">
                          Next Tenancy
                        </span>

                        <span className="tw-block mt-1 text-xs lg:text-sm text-neutral-400 leading-none font-light ">
                          Prepare your next tenancy
                        </span>
                      </div>

                      <div className="divider"></div>
                    </div>
                  )}
                  {tenancies.map((t, index) => (
                    <div
                      key={t.id}
                      className="cursor-pointer"
                      onClick={() => selectTenancy(t.id)}>
                      <div className="flex-grow">
                        {t.startDate ? (
                          <span className="tw-block text-sm lg:text-md font-semibold">
                            {moment(t.startDate).format('DD MMMM YYYY')} -{' '}
                            {titleize(removeUnderscores(t.status))}
                          </span>
                        ) : (
                          <span className="tw-block text-sm lg:text-md font-semibold">
                            Draft Tenancy
                          </span>
                        )}

                        {t.tenancyMemberships.length === 0 ? (
                          <span className="tw-block mt-1 text-xs lg:text-sm text-neutral-400 leading-none font-light ">
                            No Assigned Tenants
                          </span>
                        ) : (
                          <span className="tw-block mt-1 text-xs lg:text-sm text-neutral-400 leading-none font-light ">
                            {t.tenancyMemberships
                              .map((tm) => tm.renter.name)
                              .join(', ')}
                          </span>
                        )}
                      </div>
                      {index !== tenancies.length - 1 && (
                        <div className="divider my-2"></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default TenancySelectField;
