import moment from 'moment';
import {HiOutlineUser} from 'react-icons/hi';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyRequest from 'models/properties/TenancyRequest';
import {RouterLink} from 'router/components';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';

const TenancyRequestsTable = ({
  tenancyRequests,
  tenancyInvites,
  propertyId,
}: {
  tenancyRequests: TenancyRequest[];
  tenancyInvites: TenancyInvite[];
  propertyId: string;
}) => {
  const queryClient = useQueryClient();
  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {activeAccountRole} = useLocalUserSettings();

  const confirmDeleteTenancyInvite = async (invite: TenancyInvite) => {
    setConfirmationOptions({
      title: 'Delete Tenant Invite',
      message: 'Are you sure you want to delete the invite for this tenant?',
      buttonTitle: 'Delete',
      action: () => deleteTenancyInvite(invite),
      color: 'error',
    });
  };

  const deleteTenancyInvite = async (invite: TenancyInvite) => {
    const result = await invite.destroy();
    if (result) {
      queryClient.invalidateQueries([
        'property',
        {id: propertyId, context: 'detail-page'},
      ]);

      toast.success('The invitation has been deleted.');
    }
  };

  const nameForRequest = (request: TenancyRequest) => {
    const name = request.renter?.name ?? request.name;
    const email = request.renter?.email ?? request.renterEmail;
    return email ? `${name} (${email})` : name;
  };

  if (tenancyRequests.length > 0 || tenancyInvites.length > 0) {
    return (
      <div>
        <div className="overflow-x-auto hidden lg:tw-block">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Tenant</th>
                <th>Sent</th>
                <th>Signed Up To Keyhook</th>
                <th>Accepted Invite</th>
                {activeAccountRole === 'Landlord' && <th></th>}
              </tr>
            </thead>
            <tbody>
              {tenancyRequests.map((req) => (
                <tr key={req.id}>
                  <td>{nameForRequest(req)}</td>
                  <td>{moment(req.createdAt).fromNow()}</td>
                  <td>{req.renter ? 'Yes' : 'No'}</td>
                  <td>{req.accepted ? 'Yes' : 'No'}</td>
                  {activeAccountRole === 'Landlord' && (
                    <td>
                      <RouterLink
                        href={`tenancy-requests/${req.id}/edit`}
                        className="link link-primary">
                        Edit
                      </RouterLink>
                    </td>
                  )}
                </tr>
              ))}
              {tenancyInvites.map((req) => (
                <tr key={req.id}>
                  <td>{req.renter ? req.renter.name : req.email}</td>
                  <td>{moment(req.createdAt).fromNow()}</td>
                  <td>{req.renter ? 'Yes' : 'No'}</td>
                  <td>{req.accepted ? 'Yes' : 'No'}</td>
                  {activeAccountRole === 'Landlord' && (
                    <td>
                      <a
                        className="link link-primary"
                        onClick={() => confirmDeleteTenancyInvite(req)}>
                        Delete
                      </a>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="tw-block lg:hidden">
          <ul className="flex flex-col gap-2">
            {tenancyRequests.map((request) => (
              <MobileListItem
                key={request.id}
                link={
                  activeAccountRole === 'Landlord'
                    ? `tenancy-requests/${request.id}/edit`
                    : undefined
                }
                title={
                  request.renter ? request.renter.name : request.renterEmail
                }
                icon={HiOutlineUser}
                subtitle={
                  <>
                    <p>Signed up to Keyhook: {request.renter ? 'Yes' : 'No'}</p>
                    <br />
                    <p>Accepted Invite: {request.accepted ? 'Yes' : 'No'}</p>
                  </>
                }
              />
            ))}

            {tenancyInvites.map((request) => (
              <MobileListItem
                key={request.id}
                title={request.renter ? request.renter.name : request.email}
                subtitle={
                  <>
                    <p>Signed up to Keyhook: {request.renter ? 'Yes' : 'No'}</p>
                    <br />
                    <p>Accepted Invite: {request.accepted ? 'Yes' : 'No'}</p>
                    <br />

                    {activeAccountRole === 'Landlord' && (
                      <button
                        className="btn btn-sm btn-warning mt-2"
                        onClick={() => confirmDeleteTenancyInvite(request)}>
                        Delete
                      </button>
                    )}
                  </>
                }
              />
            ))}
          </ul>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default TenancyRequestsTable;
