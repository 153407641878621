import React, {useCallback, useRef} from 'react';

import {Form, Formik, FormikHelpers} from 'formik';
import {toJpeg} from 'html-to-image';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {DOBField, InputField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import IdentifyVerificationCard from 'components/tenant_checks/IdentifyVerificationCard';
import {Button} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

interface FormValues {
  name: string;
  email: string;
  dateOfBirth: string;
  weeklyIncome: number;

  currentAddress: string;

  employerReferenceName: string;
  employerReferenceCompanyName: string;
  employerReferenceRelationship: string;
  employerReferenceContactNumber: string;

  landlordReferenceName: string;
  landlordReferenceRelationship: string;
  landlordReferenceContactNumber: string;
  landlordReferenceTenancyAddress: string;
  landlordReferenceTenancyDate: string;

  otherReferenceName: string;
  otherReferenceRelationship: string;
  otherReferenceContactNumber: string;

  signature: string;
}

const EditRentalApplicationApplicantPage = () => {
  const {
    params: {publicAccessToken},
  } = useRoute();

  const proofOfConsentRef = useRef<HTMLDivElement>(null);

  const {data, error, isLoading} = useQuery(
    ['rental-application-applicant-', publicAccessToken],
    async () => {
      const applicant = await RentalApplicationApplicant.includes(
        'rental_application',
      ).find(publicAccessToken);

      return applicant.data;
    },
  );

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      const app = data;
      app.id = publicAccessToken;

      app.assignAttributes(values);

      app.proofOfConsent = await toJpeg(proofOfConsentRef.current, {
        cacheBust: false,
        backgroundColor: 'white',
        filter: (node) => {
          return node.tagName !== 'BUTTON';
        },
      });

      const result = await app.save();

      if (result) {
        toast.success('Successfully saved rental application');
      } else {
        toast.error('Failed to save rental application');
        for (const key of Object.keys(app.errors)) {
          const message = app.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
        console.log(app.errors);
      }

      actions.setSubmitting(false);
    },
    [data, publicAccessToken],
  );

  const handleVerificationComplete = useCallback(
    async (inquiryId: string) => {
      const u = data;
      u.idVerificationProvider = 'persona';
      u.idVerificationProviderId = inquiryId;

      const result = await u.save();
      if (result) {
        queryClient.setQueryData(
          ['rental-application-applicant-', publicAccessToken],
          u,
        );
      }
      if (result && u.idVerificationStatus === 'approved') {
        toast.success('Your identity has been successfully verified!');
      }
    },
    [data, queryClient, publicAccessToken],
  );

  return (
    <Page title="Rental Application" loading={isLoading} error={error}>
      {() => (
        <Formik
          initialValues={(data ? data.attributes : {}) as FormValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            name: Yup.string().required().label('Name').min(2),
            email: Yup.string().required().label('Email'),
            dateOfBirth: Yup.string()
              .optional()
              .nullable()
              .label('Date Of Birth')
              .test('is-valid-date', 'Please enter a valid date', (value) => {
                if (!value) return true;

                return (
                  moment(value, 'DD/MM/YYYY').isValid() ||
                  moment(value, 'YYYY-MM-DDD').isValid()
                );
              }),
            currentAddress: Yup.string()
              .nullable()
              .optional()
              .required()
              .label('Current Address')
              .min(1),
            weeklyIncome: Yup.number()
              .optional()
              .nullable()
              .label('Weekly Income')
              .min(0),
            employerReferenceName: Yup.string()
              .optional()
              .nullable()
              .label('Employer Reference Name'),
            employerReferenceCompanyName: Yup.string()
              .optional()
              .nullable()
              .label('Employer Reference Company Name'),
            employerReferenceRelationship: Yup.string()
              .optional()
              .nullable()
              .label('Employer Reference Relationship'),
            employerReferenceContactNumber: Yup.string()
              .optional()
              .nullable()
              .label('Employer Reference Contact Number'),
            landlordReferenceName: Yup.string()
              .optional()
              .nullable()
              .label('Landlord Reference Name'),
            landlordReferenceRelationship: Yup.string()
              .optional()
              .nullable()
              .label('Landlord Reference Relationship'),
            landlordReferenceContactNumber: Yup.string()
              .optional()
              .nullable()
              .label('Landlord Reference Contact Number'),
            landlordReferenceTenancyAddress: Yup.string()
              .optional()
              .nullable()
              .label('Landlord Reference Tenancy Address'),
            landlordReferenceTenancyDate: Yup.string()
              .optional()
              .nullable()
              .label('Landlord Reference Tenancy Date'),
            otherReferenceName: Yup.string()
              .optional()
              .nullable()
              .label('Other Reference Name'),
            otherReferenceRelationship: Yup.string()
              .optional()
              .nullable()
              .label('Other Reference Relationship'),
            otherReferenceContactNumber: Yup.string()
              .optional()
              .nullable()
              .label('Other Reference Contact Number'),
            signature: Yup.string().required().label('Signature'),
          })}>
          {(formik) => (
            <Form>
              <Card title="Basic Information">
                <Paragraph>
                  You have been added to a rental application by{' '}
                  {data.rentalApplication.headTenantName}. The next step in your
                  application may involve tenant checks and reference checks. To
                  make your application process more efficient, you can fill in
                  tenant check information.
                </Paragraph>

                <div className="mt-2"></div>

                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="name"
                      label="Full Name"
                      placeholder="eg: John David Smith"
                      mode="formik"
                      form={formik}
                      required
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <DOBField
                      formik={formik}
                      name="dateOfBirth"
                      label="Date Of Birth"
                      required
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow>
                  <FormRowItem>
                    <TextField
                      name="email"
                      label="Email"
                      placeholder="eg: johndavidsmith@gmail.com"
                      mode="formik"
                      form={formik}
                      required
                    />
                  </FormRowItem>

                  <FormRowItem>
                    <TextField
                      name="weeklyIncome"
                      label="Weekly Income"
                      placeholder="eg: 500"
                      mode="formik"
                      form={formik}
                      type="number"
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="currentAddress"
                      labelProps={{
                        required: true,
                        title: 'What is your current address?',
                        description:
                          "Please use the format: 'Street, Suburb, City, Postcode, Country'",
                      }}
                      placeholder="E.g. 123 Example Street, Tawa, Wellington, 6035, New Zealand"
                      required
                    />
                  </FormRowItem>
                </FormRow>
              </Card>

              <IdentifyVerificationCard
                currentStatus={data.idVerificationStatus}
                referenceId={`RentalApplicationApplicant-${data.id}}`}
                onComplete={handleVerificationComplete}
              />

              <Card title="Employer Reference">
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceName"
                      label="Name"
                      placeholder="eg: Jane Doe"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>

                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceCompanyName"
                      label="Company Name"
                      placeholder="eg: ABC Company"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceRelationship"
                      label="Relationship"
                      placeholder="eg: Manager"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceContactNumber"
                      label="Contact Number"
                      placeholder="eg: 0211234567"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="employerReferenceEmail"
                      label="Email address"
                      placeholder="eg: jane.smith@company.com"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
              </Card>

              <Card title="Landlord Reference">
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceName"
                      label="Name"
                      placeholder="eg: Jane Doe"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceRelationship"
                      label="Relationship"
                      placeholder="eg: Property Manager"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceContactNumber"
                      label="Contact Number"
                      placeholder="eg: 0211234567"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceEmail"
                      label="Email address"
                      placeholder="eg: jane.doe@gmailcom"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceTenancyAddress"
                      label="Tenancy Address"
                      placeholder="eg: 123 Example Street, Auckland"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="landlordReferenceTenancyDate"
                      label="Tenancy Date"
                      placeholder="eg: 01/01/2020"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
              </Card>

              <Card title="Other Reference">
                <Paragraph secondary>
                  This should preferably be another landlord.
                </Paragraph>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceName"
                      label="Name"
                      placeholder="eg: Jane Doe"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceRelationship"
                      label="Relationship"
                      placeholder="eg: Landlord"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
                <FormRow responsive>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceContactNumber"
                      label="Contact Number"
                      placeholder="eg: 0211234567"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <TextField
                      name="otherReferenceEmail"
                      label="Email address"
                      placeholder="eg: jane.doe@gmailcom"
                      mode="formik"
                      form={formik}
                    />
                  </FormRowItem>
                </FormRow>
              </Card>

              <div ref={proofOfConsentRef}>
                <Card title="Confirmation of Application">
                  <Paragraph size="sm" secondary>
                    By confirming your application and signing below, you
                    confirm that all the above information is true and accurate
                    and may be used for a credit and reference check.
                  </Paragraph>
                  <Paragraph size="sm" secondary>
                    I authorise the Landlord/Property Manager to:
                  </Paragraph>
                  <ul className="ml-4">
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; collect, retain and use this information for the
                        purpose of assessing my creditworthiness and suitability
                        for the tenancy; and
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; disclose information about me, whether collected
                        from me directly or from any other source, to any other
                        credit provider or any credit reporting agency for the
                        purposes of providing or obtaining a credit report
                        (which will involve the credit reporting agency
                        providing information about me to the Landlord/Property
                        Manager).{' '}
                      </Paragraph>
                    </li>
                  </ul>

                  <Paragraph size="sm" secondary>
                    I understand that the credit reporting agency:
                  </Paragraph>
                  <ul className=" ml-4 mb-4">
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; may hold my information on their credit reporting
                        database and use it for providing credit reporting
                        services, and they may disclose my information to their
                        subscribers for the purpose of credit checking or debt
                        collection; and
                      </Paragraph>
                    </li>
                    <li>
                      <Paragraph size="sm" secondary>
                        &gt; as part of providing a credit report, may check the
                        Ministry of Justice fines database for any overdue fines
                        Under the Privacy Act 2020, you have the right to ask
                        for a copy of all information held about you, and have
                        the right to request the correction of any incorrect
                        information.
                      </Paragraph>
                    </li>
                  </ul>

                  <SignatureModalInput
                    mode="formik"
                    name="signature"
                    labelProps={{
                      title: 'Signature',
                    }}
                  />

                  <div className="mt-5">
                    <Button
                      mode="formik"
                      form={formik}
                      label="Update Application"
                      loadingLabel="Saving"
                      size="base"
                      category="primary"
                    />
                  </div>
                </Card>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Page>
  );
};

export default EditRentalApplicationApplicantPage;
