import {useCallback, useState} from 'react';

import * as Sentry from '@sentry/react';
import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import Page from 'router/components/Page';
import useRouter from 'router/hooks/useRouter';

interface PasswordResetFormData {
  email: string;
}

const RequestPasswordResetPage = () => {
  const {isLoggedIn, requestPasswordReset} = useAuth();

  const router = useRouter();

  if (isLoggedIn) {
    router.navigate('/', {reloadAll: true});
  }

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue resetting your password. Please try again later or contact us for support.`,
    );
    // Errors only need to be logged to Sentry if not caused by a user/field error
    Sentry.captureMessage('Error sending password reset email');
  }, []);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const handleFormSubmit = useCallback(
    async ({email}: PasswordResetFormData, actions: any) => {
      try {
        await requestPasswordReset(email);
        setShowSuccess(true);
      } catch (error) {
        showGenericError();
      } finally {
        /**
         * Reset the submitting state.
         */
        actions.setSubmitting(false);
      }
    },
    [requestPasswordReset, showGenericError],
  );

  const form = useFormik({
    onSubmit: handleFormSubmit,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Page title="Reset Password" backUrl="/login" centred>
      {() => (
        <AuthFormLayout
          title="Forgot Your Password?"
          description="Fill in the form below to have a reset link sent to your email address.">
          <Alert type="success" show={showSuccess}>
            If an account with the entered email address exists, a link to reset
            your password will be sent to you!
          </Alert>
          <form className="flex flex-col items-center gap-y-8">
            <div className="w-full flex flex-col gap-y-8">
              <TextField
                name="email"
                label="Email"
                type="email"
                size="base"
                mode="formik"
                form={form}
                disabled={showSuccess}
                placeholder="Enter your email address here..."
                required
              />
            </div>
            <Button
              label="Send reset link"
              category="primary"
              size="base"
              mode="formik"
              form={form}
              disabled={showSuccess}
              loadingLabel="Sending reset link..."
            />
          </form>
          <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
            <Hyperlink persistQueryParams back force href="/login">
              Back to login page
            </Hyperlink>
            <Hyperlink
              persistQueryParams
              external
              href="https://keyhook.com/contact?_gl=1*1hnaab6*_ga*MjAyOTQwMjM1NS4xNjczMjEwMDg0*_ga_5NQ88EK4DT*MTY3MzgzMjQ5Ny4xMS4xLjE2NzM4MzI3MjUuNTcuMC4w">
              Having trouble?
            </Hyperlink>
          </span>
        </AuthFormLayout>
      )}
    </Page>
  );
};

export default RequestPasswordResetPage;
