import {useCallback, useEffect, useState} from 'react';

import {FormikHelpers, useFormik} from 'formik';
import {isMobile} from 'react-device-detect';
import {toast} from 'react-toastify';
import {StringParam, useQueryParam} from 'use-query-params';
import * as Yup from 'yup';

import {AuthErrorCode, errorIsAuthErrorCodes} from 'auth/errors';
import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {CodeInput} from 'components_sb/input';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import {Page} from 'router/components';
import useRouter from 'router/hooks/useRouter';

interface FormValues {
  confirmationCode: string;
}

const ConfirmEmailPage = () => {
  const [redirect] = useQueryParam('redirect', StringParam);

  const router = useRouter();

  const [isResendingCode, setIsResendingCode] = useState<boolean>(false);

  const {currentUser, authCookies, confirmEmail} = useAuth();

  /**
   * Handle redirection after confirming the email address, or if the page
   * is loaded when the email is already confirmed.
   */
  const handleRedirect = useCallback(() => {
    /**
     * Navigate to the redirect URL/path if it exists in query params, otherwise
     * redirect to the dashboard.
     */
    if (!!redirect && redirect.startsWith('http')) {
      window.open(redirect, '_self');
    }
    router.navigate('/', {reloadAll: true});
  }, [redirect, router]);

  /**
   * Check if the user's email address has already been confirmed on mount.
   */
  const handleInitialMount = useCallback(() => {
    if (currentUser.confirmed) {
      toast.info('Your email address has already been confirmed!');
      handleRedirect();
    }
  }, [currentUser, handleRedirect]);

  /**
   * Invoke the post-confirm function if the user is already confirmed on mount.
   */
  useEffect(() => {
    handleInitialMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendConfirmationCode = useCallback(async () => {
    setIsResendingCode(true);
    const response = await fetch(
      API_URL + `/users/${currentUser.id}/resend_email_confirmation.json`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
      },
    );

    if (response.ok) {
      toast.success('The confirmation code email has been resent to you.');
    } else {
      toast.error('Sorry, there was an issue resending the confirmation code.');
    }

    setIsResendingCode(false);
  }, [authCookies, currentUser]);

  const showGenericError = useCallback(() => {
    toast.error('Sorry, there was an issue confirming your email address.');
  }, []);

  const handleSubmit = useCallback(
    async (
      {confirmationCode}: FormValues,
      formikHelpers: FormikHelpers<FormValues>,
    ) => {
      try {
        await confirmEmail({confirmationCode});

        formikHelpers.setStatus('success');
        toast.success('Your email address has been confirmed!');

        handleRedirect();
      } catch (error) {
        /**
         * Check if the error is an array of AuthErrorCode values.
         */
        if (errorIsAuthErrorCodes(error)) {
          const authErrorCodes = error;
          if (
            authErrorCodes.includes(
              AuthErrorCode.INVALID_EMAIL_CONFIRMATION_CODE,
            )
          ) {
            /**
             * Handle invalid email confirmation code.
             */
            formikHelpers.setFieldError(
              'confirmationCode',
              `This confirmation code is invalid, ${
                isMobile ? 'tap' : 'click'
              } below if you need to have it resent to you.`,
            );
          } else {
            /**
             * Unknown error code.
             */
            showGenericError();
          }
        } else {
          /**
           * Error performing the registration request.
           */
          showGenericError();
        }
      } finally {
        formikHelpers.setSubmitting(false);
      }
    },
    [confirmEmail, showGenericError, handleRedirect],
  );

  const form = useFormik({
    onSubmit: handleSubmit,
    initialValues: {
      confirmationCode: '',
    },
    validationSchema: Yup.object().shape({
      confirmationCode: Yup.string()
        .required('Please enter the confirmation code we have sent to you')
        .length(4, 'Verification code must be 4 digits long'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Page title="Confirm Email" centred>
      {() => (
        <div className="w-full max-w-2xl flex flex-col items-center mx-auto text-center">
          <Paragraph>
            We have sent an email with a 4 digit verification code to:{' '}
            <strong>{currentUser.unconfirmedEmail ?? currentUser.email}</strong>
          </Paragraph>
          <Paragraph>Please enter the code below.</Paragraph>

          <form className="w-full flex flex-col items-center">
            <div className="w-full flex flex-row justify-center mt-6 mb-10">
              <CodeInput
                name="confirmationCode"
                form={form}
                length={4}
                submitOnFill
              />
            </div>
            <div className="w-full flex flex-col-reverse sm:flex-row gap-x-6 gap-y-4">
              <Button
                label="Resend confirmation code"
                category="secondary"
                size="base"
                mode="manual"
                onClick={resendConfirmationCode}
                disabled={form.isSubmitting}
                loading={isResendingCode}
                loadingLabel="Resending confirmation code..."
                fillWidth
              />
              <Button
                label="Confirm email"
                category="primary"
                size="base"
                mode="formik"
                form={form}
                loadingLabel="Confirming email..."
                fillWidth
              />
            </div>
          </form>
        </div>
      )}
    </Page>
  );
};

export default ConfirmEmailPage;
