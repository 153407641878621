import {Attr, BelongsTo, HasMany, Model} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import Listing from 'models/listings/Listing';

@Model()
class EnquiryThread extends ApplicationRecord {
  static jsonapiType = 'enquiry_threads';

  @Attr() email: string;
  @Attr() name: string;

  @Attr() listingId: string;

  @Attr() archived: boolean;

  @Attr() hasApplied: boolean;
  @Attr() listingRentalApplicationId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @HasMany('enquiry_messages') enquiryMessages: EnquiryMessage[];
  @BelongsTo('listings') listing: Listing;
}

export default EnquiryThread;
