import {
  Children,
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  type StepType,
  TourProvider as ReactourProvider,
  useTour as useReactour,
} from '@reactour/tour';
import clsx from 'clsx';

import EndTourConfirmation from 'components/onboarding/EndTourConfirmationModal';
import {Button} from 'components_sb/buttons';
import {Modal} from 'components_sb/layout';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import useRoute from 'router/hooks/useRoute';

const {useModal} = Modal.Imperative;

// import useAuth from 'auth/provider/useAuth';

type CustomStepType = StepType & {
  buttonText?: {
    next?: string;
    prev?: string;
  };
};

export interface Tour {
  id: string;
  steps: CustomStepType[];
}

interface SetTourPresentedFunction {
  (tour: Tour): void;
}

interface ToursContextValue {
  tourActive: boolean;
  setTourActive: (active: boolean) => void;

  presentedTourIds: string[];
  setTourPresented: SetTourPresentedFunction;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Context = createContext<ToursContextValue | undefined>(undefined);

interface ToursProvider {
  (props: {children: ReactNode}): JSX.Element;
}

interface PopoverContent {
  (props: {content: string | string[]}): JSX.Element;
}

const PopoverContent: PopoverContent = ({content}) => {
  return <div>{content}</div>;
};

/**
 * The props provided to the nextButton and prevButton props
 * on the Reactour provided (copied from library since not
 * directly exported).
 */
interface PopoverNav {
  (props: {
    steps: StepType[];
    currentStep: number;
    setCurrentStep: (step: number) => void;
    setIsOpen: (isOpen: boolean) => void;
    disableAll?: boolean;
  }): JSX.Element;
}

const PopoverNav: PopoverNav = (props) => {
  const {steps, currentStep, setCurrentStep, setIsOpen, disableAll} = props;

  const isLastStep = useMemo(
    () => currentStep === steps.length - 1,
    [steps, currentStep],
  );

  const onPrevious = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [setCurrentStep, currentStep]);

  const onNext = useCallback(() => {
    if (isLastStep) {
      setIsOpen(false);
      setCurrentStep(0);
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [isLastStep, setIsOpen, setCurrentStep, currentStep]);

  return disableAll ? null : (
    <div className="w-full flex flex-row justify-between gap-x-4">
      <div className="flex-1">
        {currentStep > 0 && (
          <Button
            label={'Previous'}
            category="secondary"
            size="base"
            mode="manual"
            onClick={onPrevious}
          />
        )}
      </div>
      <div className="flex-1">
        <Button
          label={isLastStep ? 'End tour' : 'Next'}
          category="primary"
          size="base"
          mode="manual"
          onClick={onNext}
        />
      </div>
    </div>
  );
};

const MobilePopover = (props: any) => {
  return (
    <div
      className={clsx(
        'z-50',
        'w-full fixed bottom-0 left-0',
        'bg-white shadow-2xl rounded-t-lg p-6',
        'border-3 border-brand-75',
        'flex flex-col gap-y-4',
      )}>
      {props.children}
    </div>
  );
};

const Wrapper = ({children}: {children: JSX.Element}) => {
  const desktopMode = useTailwindBreakpoint('md');

  /**
   * Separate the popover component from the other children
   */
  const childrenArray = Children.toArray(children);
  const [popover] = childrenArray.splice(3);

  return desktopMode ? (
    <>{children}</>
  ) : (
    <>
      <MobilePopover {...(popover as ReactElement).props} />
      {childrenArray}
    </>
  );
};

/**
 * The provider for the Reactour library.
 */
export const ToursProvider: ToursProvider = ({children}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const route = useRoute();

  const nextStep = useCallback(() => {
    setCurrentStep((current) => current + 1);
  }, [setCurrentStep]);

  /**
   * Automatically go to the next step on route change.
   */
  useEffect(() => {
    nextStep();
  }, [route.path, nextStep]);

  const openModal = useModal();
  const handleExit = useCallback(
    async ({setIsOpen}: {setIsOpen: (isOpen: boolean) => void}) => {
      setIsOpen(!(await openModal(EndTourConfirmation)));
    },
    [openModal],
  );
  return (
    <ReactourProvider
      // Defaults to an empty array but gets set upon starting
      // a tour via the custom useTour hook
      steps={[]}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      // Enable smooth scrolling to components that are not
      // currently in the viewport
      scrollSmooth
      inViewThreshold={{
        y: 100,
      }}
      // Prefer showing popover below
      position="bottom"
      // Prevent interaction with the highlighted area
      disableInteraction
      // Disable the step number badge
      showBadge={false}
      // Popover class name
      className="rounded-lg flex flex-col gap-y-4"
      // Mask class name
      maskClassName="!z-0"
      onClickMask={handleExit}
      onClickClose={handleExit}
      // Custom wrapper component
      Wrapper={Wrapper}
      // Custom popover components
      components={{
        Navigation: PopoverNav,
        Content: PopoverContent,
      }}>
      {children}
    </ReactourProvider>
  );
};

interface UseTourHookValue {
  isReady: boolean;
  hasBeenPresented: boolean;
  isActive: boolean;
  skip: () => void;
  start: () => void;
}

interface UseTourHook {
  (tour?: Tour): UseTourHookValue;
}

/**
 * A hook leveraging Reactour to provide user feature tours.
 */
export const useTour: UseTourHook = (tour) => {
  const {setSteps, setCurrentStep, isOpen, setIsOpen} = useReactour();

  /**
   * A 'presented' tour refers to a tour that the user has been offered and has
   * either followed through with or has skipped.
   */
  const presentedTourFlag = useCurrentUserFlag(`presented_tour_${tour.id}`);

  /**
   * Indicate that a tour has been presented but the user has chosen
   * not to start the tour (prevents it from being automatically
   * shown next time).
   */
  const skip = useCallback(() => {
    presentedTourFlag.set(true);
  }, [presentedTourFlag]);

  /**
   * Starts the tour provided to the hook.
   */
  const start = useCallback(() => {
    if (!tour.steps) {
      throw new Error('Cannot start tour, the provided tour has no steps.');
    } else if (isOpen) {
      throw new Error(
        'Cannot start tour, there is already a tour currently active.',
      );
    } else {
      setCurrentStep(0);
      setSteps(tour.steps);
      setIsOpen(true);
      presentedTourFlag.set(true);
    }
  }, [isOpen, tour, setSteps, setCurrentStep, setIsOpen, presentedTourFlag]);

  /**
   * Properties accessible by the custom useTour hook.
   */
  const value = useMemo<UseTourHookValue>(
    () => ({
      isReady: presentedTourFlag.isReady,
      hasBeenPresented: presentedTourFlag.value,
      isActive: !!isOpen,
      skip,
      start,
    }),
    [presentedTourFlag, isOpen, skip, start],
  );

  return value;
};
