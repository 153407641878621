import {useCallback} from 'react';

import {Clipboard} from '@capacitor/clipboard';
import {isMobile} from 'react-device-detect';
import {HiOutlineClipboardCopy} from 'react-icons/hi';
import {IoLogoFacebook} from 'react-icons/io';
import {toast} from 'react-toastify';

import {Button} from 'components_sb/buttons';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import copyToClipboard from 'utilities/copy-to-clipboard';

const ShareListingToFacebookGroupModal: ModalDefinition = {
  title: 'Share listing to group',
  buttonsConfig: {
    cancel: {
      label: 'Done',
    },
  },
  ContentComponent: (props) => {
    const {facebookGroup, listing} = props;

    const onCopy = useCallback(() => {
      copyToClipboard({
        string: `Hi! I have a ${listing.bedrooms} bedroom property available to rent in ${listing.city}.\nCheck out my listing for details here: https://keyhook.rentals/${listing.publicId}`,
      });
    }, [listing]);

    return (
      <div className="flex flex-col gap-y-6">
        <div>
          <Paragraph>
            {`First, ${
              isMobile ? 'tap' : 'click'
            } below to copy the description and link to the listing:`}
          </Paragraph>
          <Button
            label="Copy description and link"
            icon={HiOutlineClipboardCopy}
            category="secondary"
            size="base"
            mode="manual"
            onClick={onCopy}
          />
        </div>
        <div>
          <Paragraph>
            {`Then ${
              isMobile ? 'tap' : 'click'
            } below to visit the Facebook group page where you can
            paste the listing details to share.`}
          </Paragraph>
          <Button
            label="Visit Facebook group"
            icon={IoLogoFacebook}
            category="secondary"
            size="base"
            mode="link"
            linkTo={`https://www.facebook.com/groups/${facebookGroup.facebookId}/`}
          />
        </div>
      </div>
    );
  },
};

export default ShareListingToFacebookGroupModal;
