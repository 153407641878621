import {HTMLProps} from 'react';

import clsx from 'clsx';
import {type FormikProps} from 'formik';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';
import {FieldLabelSize} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';

interface DOBFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  label: string;
  name: string;
  helpText?: string;
  description?: string;
  required?: boolean;
  labelSize?: FieldLabelSize;
  size?: any;
}

export const DOBField = ({
  formik,
  label,
  name,
  helpText,
  description = null,
  required = false,
  labelSize = 'base',
  size = 'md',
  ...rest
}: DOBFieldProps) => {
  const classN = formik.errors[name] ? ' border border-danger' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  const val = valueFor(formik, name);
  const errors = errorsFor(formik, name);

  const handleBlur = () => {
    formik.handleBlur(name);

    const v = formik.values[name] as string | undefined | null;
    if (v && v.toString().length > 0) {
      TrackingService.trackEvent(TrackingService.Event.FillField, {
        field: name,
      });
    }
  };

  return (
    <>
      {label && (
        <FieldLabel
          title={label}
          description={description}
          htmlFor={name}
          required={required}
          size={labelSize}
          helpText={helpText}
        />
      )}
      <NumberFormat
        className={clsx(
          'w-full max-w-full box-border',
          'transition-all duration-300',
          'bg-brand-50 hover:bg-brand-75 ',
          'placeholder-brand-800 placeholder-opacity-30',
          'outline-none border-none ring-1 ring-brand-75',
          'focus:ring-2 focus:ring-brand-500',

          size === 'sm' && clsx('text-base', 'px-2', 'h-10', 'rounded-md'),
          size === 'md' && clsx('text-base', 'px-4', 'h-12', 'rounded-lg'),
          size === 'lg' && clsx('text-lg', 'px-6', 'h-14', 'rounded-xl'),
        )}
        format="##/##/####"
        placeholder="DD/MM/YYYY"
        mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
        defaultValue={val ? moment(val).format('DD/MM/YYYY') : ''}
        onValueChange={(values) => {
          formik.setFieldValue(name, values.formattedValue);
        }}
        onBlur={handleBlur}
      />
      {errors && <InlineError error={errors} />}
    </>
  );
};
