import {FunctionComponent, useCallback, useContext, useMemo} from 'react';

import clsx from 'clsx';
import {
  HiOutlineCheck,
  HiOutlineClock,
  HiOutlineCog,
  HiOutlineCreditCard,
  HiOutlineExclamation,
  HiOutlineEye,
  HiOutlinePlus,
  HiOutlineStar,
  HiOutlineSwitchHorizontal,
} from 'react-icons/hi';
import {IconType} from 'react-icons/lib';
import Skeleton from 'react-loading-skeleton';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import AddCreditCardModal from 'components/payment/AddCreditCardModal';
import {Button} from 'components_sb/buttons';
import ActionsDropdownButton from 'components_sb/buttons/ActionsDropdownButton/ActionsDropdownButton';
import {Modal} from 'components_sb/layout';
import usePropertyBillingDetails from 'hooks/usePropertyBillingDetails';
import BillingMethod from 'models/billing/BillingMethod';
import Property from 'models/properties/Property';
import {useCreditCards} from 'providers/CreditCardsProvider';
import {FullScreenLoaderContext} from 'providers/FullScreenLoader';
import PropertyDetailQuery from 'queries/landlord/PropertyDetailQuery';
import {saveResource} from 'utilities/SpraypaintHelpers';

import {LANDLORD_PROPERTIES_BILLING_QUERY_KEY} from '../PropertiesBillingSection';
import PropertyBillingDetailsList from './PropertyBillingDetailsList/PropertyBillingDetailsList';

const {useModal} = Modal.Imperative;

interface PropertyBillingProps {
  property?: Property;
}

/**
 * The current billing status and method for a property.
 */
const PropertyBilling: FunctionComponent<PropertyBillingProps> = ({
  property,
}) => {
  const openModal = useModal();

  /**
   * Access the credit cards context.
   */
  const creditCards = useCreditCards();

  /**
   * The hook below evaluates everything we need to know about the billing
   * status and method for the property.
   */
  const propertyBillingDetails = usePropertyBillingDetails(property);

  const fullScreenLoader = useContext(FullScreenLoaderContext);

  const {
    paymentMethodType,
    paymentConfigured,
    linkedCard,
    hasExplicitlySetCard,
    billingActionRequired,
    subscriptionIsActive,
  } = propertyBillingDetails;

  /**
   * Warn the user if there is an active subscription for the property but
   * payment has not been configured.
   */
  const showSubscriptionWarning = useMemo<boolean>(
    () => billingActionRequired,
    [billingActionRequired],
  );

  /**
   * Indicate to the user that the property has an active subscription and
   * payment has been fully configured.
   */
  const showSubscriptionActive = useMemo<boolean>(
    () => subscriptionIsActive && paymentConfigured,
    [subscriptionIsActive, paymentConfigured],
  );

  const queryClient = useQueryClient();

  const setCreditCardOnProperty = useCallback(
    async (creditCard: BillingMethod | null) => {
      fullScreenLoader.activate();
      property.assignAttributes({
        /**
         * If no credit card was passed, we set null to indicate that the
         * default should be used.
         */
        billingMethodId: creditCard?.id ?? null,
        BillingMethod: creditCard ?? null,
      });
      const success = await saveResource(property, {showErrorToast: false});
      fullScreenLoader.deactivate();
      if (success) {
        /**
         * Refetch the properties in the billing details page.
         */
        queryClient.invalidateQueries(LANDLORD_PROPERTIES_BILLING_QUERY_KEY);
        /**
         * Update the already-fetched data for the property.
         */
        queryClient.setQueryData(
          PropertyDetailQuery.queryKey(property.id),
          () => property.dup(),
        );
        toast.success('The card for your property has been updated!');
      } else {
        property.rollback();
        toast.error(
          'Sorry, there was an issue updating the card for the property.',
        );
      }
    },
    [fullScreenLoader, property, queryClient],
  );

  /**
   * Handle clearing any explicitly defined card on the property.
   */
  const onSetDefaultCard = useCallback(() => {
    setCreditCardOnProperty(null);
  }, [setCreditCardOnProperty]);

  /**
   * Handle setting an existing card on the property.
   */
  const onSetExistingCard = useCallback(
    (creditCard: BillingMethod) => {
      setCreditCardOnProperty(creditCard);
    },
    [setCreditCardOnProperty],
  );

  /**
   * Handle adding a new credit/debit card and setting it on the property.
   */
  const onSetNewCard = useCallback(async () => {
    /**
     * Open the modal to add a new credit/debit card and await the result.
     */
    const creditCard = await openModal(AddCreditCardModal);

    /**
     * Set the newly added credit/debit card on the property if a card was
     * successfully added.
     */
    if (creditCard) {
      onSetExistingCard(creditCard);
    }
  }, [openModal, onSetExistingCard]);

  return (
    <div
      data-testclass="property-billing-card"
      className={clsx(
        'flex flex-col rounded-2xl overflow-hidden',
        'bg-white',
        'rounded-xl',
        'border-2',
        (() => {
          if (showSubscriptionWarning) {
            return 'border-red-200';
          } else if (showSubscriptionActive) {
            return 'border-green-200';
          } else {
            return 'border-brand-50';
          }
        })(),
      )}>
      <div className="flex flex-col gap-y-4">
        {/* Heading */}
        <div
          className={clsx(
            'flex flex-row items-center',
            'gap-x-2 @2xl:gap-x-4',
            'p-2 @2xl:p-4',
            'box-border relative',
            (() => {
              if (showSubscriptionWarning) {
                return 'bg-red-200';
              } else if (showSubscriptionActive) {
                return 'bg-green-200';
              } else {
                return 'bg-brand-50';
              }
            })(),
          )}>
          {/* Main image */}
          <div
            className={clsx(
              'flex-shrink-0 relative rounded-full overflow-hidden',
              'w-8 @2xl:w-10',
              'h-8 @2xl:h-10',
            )}>
            {!property ? (
              <Skeleton
                containerClassName="flex w-full h-full"
                borderRadius={0}
              />
            ) : (
              <>
                <img
                  className="object-cover origin-center max-w-full max-h-full w-full h-full"
                  src={property.mainImage}
                />
              </>
            )}
          </div>
          {/* Property address */}
          <div
            className={clsx(
              'flex-1 flex flex-col gap-y-2',
              (() => {
                if (showSubscriptionWarning) {
                  return 'text-red-700';
                } else if (showSubscriptionActive) {
                  return 'text-green-700';
                } else {
                  return 'text-brand-850';
                }
              })(),
            )}>
            <div className="flex flex-col whitespace-nowrap">
              {/* Title */}
              <div
                data-testclass="property-billing-card--street-address"
                className="text-sm font-semibold">
                {!property ? <Skeleton width="90%" /> : property.streetAddress}
              </div>
              {/* Subtitle */}
              <div className="text-xs opacity-70">
                {!property ? (
                  <Skeleton width="100%" />
                ) : (
                  `${property.suburb}, ${property.city}`
                )}
              </div>
            </div>
          </div>
          {!!property && (
            <HelpTextPresenter
              disableMargin
              customElement={
                <div
                  className={clsx(
                    'h-full flex flex-col gap-0.5 items-center justify-center',
                    (() => {
                      if (showSubscriptionWarning) {
                        return 'text-red-700';
                      } else if (showSubscriptionActive) {
                        return 'text-green-700';
                      } else {
                        return 'text-brand-850 opacity-70';
                      }
                    })(),
                  )}>
                  <div
                    className={clsx(
                      'flex flex-row items-center justify-center',
                    )}>
                    {(() => {
                      let Icon: IconType;
                      if (showSubscriptionWarning) {
                        Icon = HiOutlineExclamation;
                      } else if (showSubscriptionActive) {
                        Icon = HiOutlineCheck;
                      } else {
                        Icon = HiOutlineClock;
                      }
                      return <Icon className="w-5 h-5" />;
                    })()}
                  </div>
                  <div className="text-xxs whitespace-nowrap font-medium">
                    What's this?
                  </div>
                </div>
              }>
              {(() => {
                if (showSubscriptionWarning) {
                  return (
                    <>
                      You don't have a payment method set up. To continue using
                      Keyhook, add a card below or contact Keyhook support.
                    </>
                  );
                } else if (showSubscriptionActive) {
                  return (
                    <>
                      You're all set up! See below for your next payment date.
                    </>
                  );
                } else {
                  return (
                    <>
                      It looks like you're still setting things up. This
                      property is not due to be billed.
                    </>
                  );
                }
              })()}
            </HelpTextPresenter>
          )}
        </div>
        <div className="px-2 @2xl:px-4">
          <PropertyBillingDetailsList
            propertyBillingDetails={propertyBillingDetails}
            showSkeleton={!property}
          />
        </div>

        <div className="mt-auto flex flex-row flex-wrap justify-center gap-2 p-2 border-t-2 border-brand-50">
          {!property ? (
            <div className="flex-1 relative">
              <Skeleton height={30} width="100%" />
            </div>
          ) : (
            <>
              {property?.currentTenancy?.isDraft ? (
                <Button
                  label="Finish setup"
                  icon={HiOutlineCog}
                  category="tertiary"
                  size="sm"
                  fillWidth={false}
                  mode="link"
                  linkTo={`/properties/${property.id}/setup`}
                />
              ) : (
                <Button
                  label={'View property'}
                  icon={HiOutlineEye}
                  category="tertiary"
                  size="sm"
                  fillWidth={false}
                  mode="link"
                  linkTo={`/properties/${property.id}`}
                />
              )}
              {paymentMethodType === 'card' && (
                <>
                  {linkedCard ? (
                    <ActionsDropdownButton
                      label={'Change card'}
                      icon={HiOutlineSwitchHorizontal}
                      hideArrow
                      actions={[
                        ...(!creditCards.all
                          ? []
                          : [
                              ...creditCards.all
                                .filter(
                                  ({id}) =>
                                    !hasExplicitlySetCard ||
                                    id !== linkedCard.id,
                                )
                                .map((billingMethod) => ({
                                  label: billingMethod.nickname,
                                  icon: HiOutlineCreditCard,
                                  onClick: () =>
                                    onSetExistingCard(billingMethod),
                                })),
                              ...(hasExplicitlySetCard
                                ? [
                                    {
                                      label: 'Use default card',
                                      icon: HiOutlineStar,
                                      onClick: onSetDefaultCard,
                                    },
                                  ]
                                : []),
                            ]),
                        {
                          label: `Add a new card`,
                          icon: HiOutlinePlus,
                          onClick: onSetNewCard,
                        },
                      ]}
                    />
                  ) : (
                    <Button
                      label="Add a card"
                      icon={HiOutlinePlus}
                      category="tertiary"
                      size="sm"
                      fillWidth={false}
                      mode="manual"
                      onClick={onSetNewCard}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyBilling;
