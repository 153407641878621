import {omit} from 'lodash';

import {AccountRole} from 'models/users/User';

import {UserData} from './UserData';

export interface HistoricalUserData {
  id: string;
  email: string;
  name: string;
  avatar: string;
  roles: Array<AccountRole>;
  isConfirmed: boolean;
  meta: {
    authenticationToken: string;
  };
}

export const userDataIsHistorical = (
  userData: unknown,
): userData is HistoricalUserData => {
  if (!userData || typeof userData !== 'object') {
    return false;
  }

  /**
   * Ensure the expected propertes exist as per the type definition above.
   */
  const isHistorical =
    'id' in userData &&
    'email' in userData &&
    'name' in userData &&
    'avatar' in userData &&
    'roles' in userData &&
    Array.isArray(userData.roles) &&
    userData.roles.every((role) => Object.keys(AccountRole).includes(role)) &&
    'isConfirmed' in userData &&
    'meta' in userData &&
    typeof userData.meta === 'object' &&
    'authenticationToken' in userData.meta &&
    typeof userData.meta.authenticationToken === 'string';

  return isHistorical;
};

export const convertHistoricalUserDataToNewFormat = (
  historicalUserData: HistoricalUserData,
): UserData => ({
  ...omit(historicalUserData, ['meta', 'isConfirmed']),
  unconfirmedEmail: undefined,
  confirmed: historicalUserData.isConfirmed,
});
