import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';

import useAuth from 'auth/provider/useAuth';
import {UserData} from 'auth/types/UserData';
import {NATIVE_LAYOUT_IN_WEB} from 'globals/app-globals';
import LandlordSubscription from 'models/payments/LandlordSubscription';
import {AccountRole} from 'models/users/User';
import {useCreditCards} from 'providers/CreditCardsProvider';

import SubscriptionsContext from './context';
import useLandlordSubscriptionQuery from './queries/landlord-subscription';
import fetchLandlordSubscription from './queries/landlord-subscription';
import useTotalPropertiesWithCardPaymentQuery from './queries/total-properties-with-card-payment';
import {
  InitialiseFunction,
  ResetFunction,
  SubscriptionsContextValue,
} from './types';
import useSubscriptionStatus from './utils/use-subscription-status';

interface SubscriptionsProviderProps {
  children: ReactNode;
}

const SubscriptionsProvider: FunctionComponent<SubscriptionsProviderProps> = ({
  children,
}) => {
  /**
   * Access the credit cards context.
   */
  const creditCards = useCreditCards();

  // const {isLoggedIn, currentUser} = useAuth();

  // /**
  //  * Whether there is a current user logged in who is a Landlord.
  //  */
  // const landlordLoggedIn = useMemo<boolean>(
  //   () => isLoggedIn && currentUser.roles.includes(AccountRole.Landlord),
  //   [isLoggedIn, currentUser],
  // );

  // /**
  //  * Fetch the Landlord subscription instance for the logged in Landlord.
  //  */
  // const landlordSubscriptionQuery = useLandlordSubscriptionQuery({
  //   currentUserId: currentUser?.id,
  //   // Only perform the query when there is a user logged in and the user is a Landlord.
  //   enabled: landlordLoggedIn,
  // });

  // /**
  //  * Deconstruct the user's Landlord subscription from the query.
  //  */
  // const {data: landlordSubscription} = landlordSubscriptionQuery;

  const [landlordSubscription, setLandlordSubscription] =
    useState<LandlordSubscription>();

  /**
   * Fetch the total number of properties that have credit card set as their
   * payment method for subscription fees.
   */
  const totalPropertiesWithCardPaymentQuery =
    useTotalPropertiesWithCardPaymentQuery({
      enabled: !!landlordSubscription,
    });

  /**
   * Deconstruct the total number of properties that have credit card set as their
   * payment method for subscription fees from the query.
   */
  const {data: totalPropertiesWithCardPayment} =
    totalPropertiesWithCardPaymentQuery;

  /**
   * Determine whether there are any properties that require billing action.
   */
  const hasPropertiesRequiringBillingAction = useMemo<boolean>(
    () =>
      totalPropertiesWithCardPaymentQuery.isSuccess &&
      totalPropertiesWithCardPayment > 0 &&
      creditCards.isSuccess &&
      !creditCards.exist,
    [
      totalPropertiesWithCardPaymentQuery,
      totalPropertiesWithCardPayment,
      creditCards,
    ],
  );

  const initialise = useCallback<InitialiseFunction>(async ({currentUser}) => {
    if (currentUser.roles.includes(AccountRole.Landlord)) {
      const subscription = await fetchLandlordSubscription({
        currentUserId: currentUser.id,
      });
      setLandlordSubscription(subscription);
    }
  }, []);

  const reset = useCallback<ResetFunction>(() => {
    setLandlordSubscription(null);
    totalPropertiesWithCardPaymentQuery.remove();
  }, [totalPropertiesWithCardPaymentQuery]);

  /**
   * Interprets the Landlord subscription instance to produce a set of convenient
   * reference properties for the various states in which the subscription may be in.
   */
  const subscriptionStatus = useSubscriptionStatus(landlordSubscription);

  /**
   * The value accessible by components via the useSubscriptions hook.
   */
  const value = useMemo<SubscriptionsContextValue>(
    () => ({
      // subscriptionsContextReady: isReady,
      // subscriptionsContextError: isError,
      initialise,
      reset,
      landlordSubscription,
      subscriptionStatus,
      hasPropertiesRequiringBillingAction,
    }),
    [
      // isReady,
      // isError,
      initialise,
      reset,
      landlordSubscription,
      subscriptionStatus,
      hasPropertiesRequiringBillingAction,
    ],
  );

  return (
    <SubscriptionsContext.Provider value={value}>
      {children}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionsProvider;
