import {
  createElement,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useRef,
} from 'react';

import {Browser} from '@capacitor/browser';
import clsx from 'clsx';
import {FormikProps, useFormik} from 'formik';
import {type IconType} from 'react-icons/lib';

import {SpinningLoader} from 'components_sb/feedback';
import useRouter from 'router/hooks/useRouter';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: clsx(
    'flex',
    'justify-center',
    'items-center',
    'font-medium',
    'whitespace-nowrap',
    'text-center',
    'leading-none',
    'transition-all',
    'duration-200',
    'scale-100',
    'active:scale-95',
    'ring-0',
    'focus:ring-2',
    'border-2',
    'select-none',
  ),
  // Classes for disabled state
  disabled: clsx(
    // Disable interaction
    'pointer-events-none',
    // Opacity
    'opacity-50',
  ),
  // Classes based on category
  category: {
    primary: clsx(
      // Background
      'bg-brand-500 hover:bg-brand-600',
      // Border
      'border-brand-500 hover:border-brand-600',
      // Text
      'text-brand-0',
      // Ring
      'ring-brand-200',
    ),
    secondary: clsx(
      // Background
      'bg-transparent hover:bg-brand-100 hover:bg-opacity-20',
      // Border
      'border-brand-500 hover:border-brand-600',
      // Text
      'text-brand-500',
      // Ring
      'ring-brand-200',
    ),
    tertiary: clsx(
      // Background
      'bg-brand-100 bg-opacity-0 hover:bg-opacity-20',
      // Border
      'border-brand-100 border-opacity-0 hover:border-opacity-20',
      // Text
      'text-brand-500',
      // Ring
      'ring-brand-200',
    ),
    warning: clsx(
      // Background
      'bg-amber-500 hover:bg-amber-600',
      // Border
      'border-amber-500 hover:border-amber-600',
      // Text
      'text-amber-900',
      // Ring
      'ring-amber-300',
    ),
    danger: clsx(
      // Background
      'bg-red-500 hover:bg-red-600',
      // Border
      'border-red-500 hover:border-red-600',
      // Text
      'text-white',
      // Ring
      'ring-red-300',
    ),
  },
  // Classes based on format
  format: {
    standard: clsx('flex-1', 'w-full'),
    icon: clsx('flex-0'),
  },
  // Classes based on size
  size: {
    main: {
      base: {
        sm: clsx(
          // Text
          'text-xs',
          // Height
          'h-8',
          'min-h-8',
          'max-h-8',
          // Gap between spinning loader
          'gap-x-2',
        ),
        base: clsx(
          // Text
          'text-base',
          // Height
          'h-12',
          'min-h-12',
          'max-h-12',
          // Gap between spinning loader
          'gap-x-3',
        ),
        lg: clsx(
          // Text
          'text-base',
          // Height
          'h-14',
          'min-h-14',
          'max-h-14',
          // Gap between spinning loader
          'gap-x-3',
        ),
        xl: clsx(
          // Text
          'text-lg',
          // Height
          'h-16',
          'min-h-16',
          'max-h-16',
          // Gap between spinning loader
          'gap-x-4',
        ),
      },
      format: {
        standard: {
          sm: clsx(
            // Padding
            'px-3',
            // Roundness
            'rounded-lg',
          ),
          base: clsx(
            // Padding
            'px-6',
            // Roundness
            'rounded-lg',
          ),
          lg: clsx(
            // Padding
            'px-12',
            // Roundness
            'rounded-xl',
          ),
          xl: clsx(
            // Padding
            'px-16',
            // Roundness
            'rounded-xl',
          ),
        },
        icon: {
          sm: clsx(
            // Padding
            'px-0',
            // Roundness
            'rounded-full',
            // Width
            'w-8 min-w-[32px] max-w-[32px]',
          ),
          base: clsx(
            // Padding
            'px-0',
            // Roundness
            'rounded-full',
            // Width
            'w-12 min-w-[48px] max-w-[48px]',
          ),
          lg: clsx(
            // Padding
            'px-0',
            // Roundness
            'rounded-full',
            // Width
            'w-14 min-w-[56px] max-w-[56px]',
          ),
          xl: clsx(
            // Padding
            'px-0',
            // Roundness
            'rounded-full',
            // Width
            'w-16 min-w-[64px] max-w-[64px]',
          ),
        },
      },
    },
    icon: {
      sm: 'w-4 h-4',
      base: 'w-5 h-5',
      lg: 'w-6 h-6',
      xl: 'w-7 h-7',
    },
  },
  // Classes based on the fill width setting
  fillWidth: {
    main: {
      true: 'flex-1 w-full',
      false: 'flex-0 w-auto self-start',
    },
  },
};

export type ButtonCategory =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'danger'
  | 'custom';

interface BaseButtonProps {
  /**
   * Indicates the visual importance of the button.
   */
  category: ButtonCategory;
  /**
   * The size of the button.
   */
  size: 'sm' | 'base' | 'lg' | 'xl';
  /**
   * The strategy for handling button events and states.
   *  Whether the button should fill the available flex layout width.
   */
  fillWidth?: boolean;
  /**
   *  The strategy for handling button events and states.
   */
  mode: 'manual' | 'formik' | 'link' | 'html-label';
  /**
   * Prevents the button from being interacted with.
   */
  disabled?: boolean;
  /**
   * An ID that can be used to target byt buttin in automated tests.
   */
  testId?: string;
}

type ConditionalFormatProps =
  /**
   * Types when format is 'standard' or unspecified.
   */
  | {
      format?: 'standard' | undefined;
      /**
       * The text shown within the button.
       */
      label: string;
      /**
       * The text shown within the button when loading.
       */
      loadingLabel?: string;
      /**
       * An icon exported from react-icons to include beside the label.
       * (icon is optional for 'standard' format)
       */
      icon?: IconType;
    }
  /**
   * Types when format is 'icon'.
   */
  | {
      format?: 'icon';
      /**
       * The text shown within the button.
       */
      label?: never;
      /**
       * The text shown within the button when loading.
       */
      loadingLabel?: never;
      /**
       * An icon exported from react-icons to include beside the label.
       * (icon is required for 'icon' format)
       */
      icon: IconType;
    };

type ConditionalCustomConfigProps =
  /**
   * Types when category is anything except 'custom'.
   */
  | {
      category: 'primary' | 'secondary' | 'tertiary' | 'warning' | 'danger';
      customClasses?: never;
    }
  /**
   * Types when category is 'custom'.
   */
  | {
      category: 'custom';
      customClasses: string;
    };

type ConditionalModeProps =
  /**
   * Types when mode is 'manual'.
   */
  | {
      mode: 'manual';
      loading?: boolean;
      onClick: (event: MouseEvent<HTMLElement>) => void;
      form?: never;
      linkTo?: never;
      htmlFor?: never;
    }
  /**
   * Types when mode is 'formik'.
   */
  | {
      mode: 'formik';
      loading?: never;
      onClick?: never;
      form: ReturnType<typeof useFormik> | FormikProps<any>;
      linkTo?: never;
      htmlFor?: never;
    }
  /**
   * Types when mode is 'link'.
   */
  | {
      mode: 'link';
      loading?: never;
      onClick?: never;
      form?: never;
      linkTo: string;
      htmlFor?: never;
    }
  /**
   * Types when mode is 'html-label'.
   */
  | {
      mode: 'html-label';
      loading?: never;
      onClick?: never;
      form?: never;
      linkTo?: never;
      htmlFor: string;
    };

/**
 * Conditional types combined with the base types.
 */
export type ButtonProps = BaseButtonProps &
  ConditionalFormatProps &
  ConditionalModeProps &
  ConditionalCustomConfigProps;

/**
 * Properties that wrapper components must provide to the base component.
 */
interface WrapperOutput {
  onClick: (event: MouseEvent<HTMLElement>) => void;
  type: 'button' | 'submit';
  loading: boolean;
}

/**
 * The minimum properties that wrapper components must receive from
 * the base component.
 */
interface BaseWrapperInput {
  children: (props: WrapperOutput) => JSX.Element;
}

/**
 * A component that wraps the based component to provide functionality
 * based on particular configuration.
 */
interface WrapperComponent {
  (props: BaseWrapperInput): JSX.Element;
}

/**
 * Wraps the component to enable manual control.
 */
const ManualWrapper = ({
  onClick,
  loading,
  children,
  ...props
}: BaseWrapperInput & WrapperOutput) => {
  /**
   * Prevent manual buttons from automatically submitting forms.
   */
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClick(event);
    },
    [onClick],
  );

  /**
   * For the manual wrapper, the input must include the base properties
   * but also include the expected output for direct passing
   */
  return children({
    ...props,
    onClick: handleOnClick,
    type: 'button',
    loading,
  });
};

/**
 * Wraps the base component to enable support for
 * integration with the current Formik context.
 */
const FormikWrapper = ({
  form,
  children,
  ...props
}: BaseWrapperInput & {
  form: ReturnType<typeof useFormik> | FormikProps<any>;
}) => {
  const onClick = useCallback(
    (event: MouseEvent<HTMLElement>): void => {
      event.preventDefault();
      form.submitForm();
    },
    [form],
  );
  return children({
    ...props,
    onClick,
    type: 'submit',
    loading: form.isSubmitting,
  });
};

/**
 * Wraps the base component to enable the button to act as a link.
 * Note that it is usuually preferred to use the Link component
 * instead of this setting - since a button with 'link; mode only
 * simulates navigation, and does not directly create an anchor tag.
 */
const LinkWrapper = ({
  linkTo,
  children,
  ...props
}: BaseWrapperInput & {linkTo: string}) => {
  const router = useRouter();
  const onClick = useCallback(() => {
    if (linkTo.startsWith('http')) {
      Browser.open({url: linkTo});
    } else {
      router.navigate(linkTo);
    }
  }, [router, linkTo]);

  // TODO: Use an anchor element instead of the button element
  return children({
    ...props,
    onClick,
    type: 'button',
    loading: false,
  });
};

/**
 * Wraps the base component to enable the button to act as an HTML
 * label element (allowing it to be used to control other elements).
 */
const HtmlLabelWrapper = ({
  htmlFor,
  children,
  ...props
}: BaseWrapperInput & {htmlFor: string}) => {
  /**
   * Programatically click the label element when the
   * contained button is clicked.
   */
  const labelRef = useRef();
  const onClick = useCallback(() => {
    (labelRef.current as HTMLElement).click();
  }, [labelRef]);

  return (
    <label htmlFor={htmlFor} ref={labelRef}>
      {children({
        ...props,
        onClick,
        type: 'button',
        loading: false,
      })}
    </label>
  );
};

/**
 * Primary UI component for user interaction.
 */
const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      label,
      category,
      size,
      format = 'standard',
      fillWidth = true,
      mode,
      customClasses = '',
      icon = null,
      disabled = false,
      loadingLabel = null,
      testId = undefined,
    } = props;

    /**
     * Select the wrapper based on the mode
     */
    const Wrapper: WrapperComponent = {
      manual: ManualWrapper,
      formik: FormikWrapper,
      link: LinkWrapper,
      'html-label': HtmlLabelWrapper,
    }[mode];

    return (
      <Wrapper {...props}>
        {({onClick, type, loading = false}: WrapperOutput) => (
          <button
            data-testid={testId}
            ref={ref}
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={clsx(
              classes.base,
              classes.fillWidth.main[fillWidth ? 'true' : 'false'],
              category === 'custom'
                ? customClasses
                : classes.category[category],
              classes.format[format],
              classes.size.main.base[size],
              classes.size.main.format[format][size],
              (disabled || loading) && classes.disabled,
            )}>
            {loading && (
              <SpinningLoader
                size={'xs'}
                color={category === 'primary' ? 'white' : 'brand'}
              />
            )}
            {loading && loadingLabel ? (
              loadingLabel
            ) : (
              <>
                {icon &&
                  // Hide icon if the format is "icon" and the button is currently loading
                  !(format === 'icon' && loading) &&
                  createElement(icon, {
                    className: clsx('flex-shrink-0', classes.size.icon[size]),
                  })}
                {label && <span>{label}</span>}
              </>
            )}
          </button>
        )}
      </Wrapper>
    );
  },
);

export default Button;
