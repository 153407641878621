import {FunctionComponent, useMemo} from 'react';

import {Link} from 'framework7-react';
import {LinkProps} from 'framework7-react/components/link';

import useRouter from 'router/hooks/useRouter';

export type RouterLinkProps = Omit<LinkProps, 'noLinkClass'>;

/**
 * A wrapper around the Framework7 Link component to set particular props
 * for all instances of the component.
 */
const RouterLink: FunctionComponent<RouterLinkProps> = ({...props}) => {
  const router = useRouter();

  const external = useMemo(() => {
    if (
      props.external ||
      (typeof props.href === 'string' && props.href.startsWith('http'))
    ) {
      return true;
    } else if (props.target === '_blank') {
      return true;
    } else {
      return false;
    }
  }, [props.external, props.target, props.href]);

  /**
   * If the link navigates backwards, and a path has been explicitly set,
   * then we need to check if the path of the previous route is the same
   * and where we are navigating to. If it is, then we don't need to set
   * the 'href' or 'force' parameters on the link component.
   */
  if (!!props.back && !!props.href) {
    const previousPath = router?.previousRoute.path;
    if (previousPath === props.href) {
      props.href = undefined;
      props.force = undefined;
    }
  }

  return <Link {...props} noLinkClass external={external} />;
};

export default RouterLink;
