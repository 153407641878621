import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import RentalApplication from 'models/listings/RentalApplication';

@Model()
class RentalApplicationApplicant extends ApplicationRecord {
  static jsonapiType = 'rental_application_applicants';

  @Attr() name: string;
  @Attr() email: string;
  @Attr() dateOfBirth: string;
  @Attr() weeklyIncome: number;

  @Attr() idType: string;
  @Attr() idNumber: string;
  @Attr() idExtraInfo: string;

  @Attr({persist: false}) idVerificationStatus: string;
  @Attr() idVerificationProvider: string;
  @Attr() idVerificationProviderId: string;

  @Attr() currentAddress: string;

  @Attr() employerReferenceName: string;
  @Attr() employerReferenceCompanyName: string;
  @Attr() employerReferenceRelationship: string;
  @Attr() employerReferenceContactNumber: string;
  @Attr() employerReferenceEmail: string;

  @Attr() landlordReferenceName: string;
  @Attr() landlordReferenceRelationship: string;
  @Attr() landlordReferenceContactNumber: string;
  @Attr() landlordReferenceTenancyAddress: string;
  @Attr() landlordReferenceTenancyDate: string;
  @Attr() landlordReferenceEmail: string;

  @Attr() otherReferenceName: string;
  @Attr() otherReferenceRelationship: string;
  @Attr() otherReferenceContactNumber: string;
  @Attr() otherReferenceEmail: string;

  @Attr() signature: string;
  @Attr() signedAt: string;

  @Attr() proofOfConsent: string;

  @Attr() rentalApplicationId: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('rental_applications') rentalApplication: RentalApplication;
}

export default RentalApplicationApplicant;
