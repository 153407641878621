import {useCallback, useMemo} from 'react';

import {HiOutlinePlus} from 'react-icons/hi';
import {IoHammerOutline} from 'react-icons/io5';

import ServiceRequestsTable from 'components/service_request/ServiceRequestsTable';
import {Card} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useSubscriptionGuard from 'providers/Subscriptions/hooks/useSubscriptionGuard';
import useRouter from 'router/hooks/useRouter';
import {Action} from 'types/actions';

const MaintenanceRequestsCard = ({
  property,
  tenancy,
}: {
  property?: Property;
  tenancy?: Tenancy;
}) => {
  const router = useRouter();

  const showServiceRequest = useCallback(
    (serviceRequestId: string) => {
      router.navigate(`service-requests/${serviceRequestId}`);
    },
    [router],
  );

  const {activeAccountRole} = useLocalUserSettings();

  const {requireSubscription} = useSubscriptionGuard({
    property,
  });

  const onNewMaintenanceRequest = useCallback(() => {
    if (activeAccountRole === 'Landlord') {
      requireSubscription(() =>
        router.navigate(`tenancies/${tenancy.id}/service-requests/new`),
      );
    } else {
      router.navigate('service-requests/new');
    }
  }, [activeAccountRole, router, requireSubscription, tenancy?.id]);

  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () =>
      !tenancy
        ? null
        : [
            {
              label: 'New maintenance request',
              icon: HiOutlinePlus,
              onClick: onNewMaintenanceRequest,
            },
          ],
    [tenancy, onNewMaintenanceRequest],
  );

  return (
    <Card
      id="maintenance-requests"
      icon={IoHammerOutline}
      title="Maintenance Requests"
      actions={cardActions}>
      {tenancy && tenancy.serviceRequests.length > 0 ? (
        <ServiceRequestsTable
          requests={tenancy.serviceRequests}
          clickHandler={showServiceRequest}
          accountType={activeAccountRole}
        />
      ) : (
        <EmptyContentPlaceholder
          type="maintenance"
          message="There are currently no maintenance requests for this property"
        />
      )}
    </Card>
  );
};

export default MaintenanceRequestsCard;
