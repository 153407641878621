import {useCallback, useImperativeHandle} from 'react';

import * as Sentry from '@sentry/react';
import {toast} from 'react-toastify';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import BillingMethod from 'models/billing/BillingMethod';
import {useCreditCards} from 'providers/CreditCardsProvider';

const RemoveCreditCardConfirmationModal: ModalDefinition = {
  title: 'Are you sure you want to remove this card?',
  buttonsConfig: {
    cancel: {
      label: 'No, keep card',
    },
    actions: [
      {
        id: 'confirm',
        label: 'Yes, remove card',
        handle: 'onConfirm',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    /**
     * Deconstruct and cast the credit card prop.
     */
    const creditCard = props.creditCard as BillingMethod;

    /**
     * Access the credit cards context.
     */
    const creditCards = useCreditCards();

    /**
     * Remove the credit card upon confirmation.
     */
    const onConfirm = useCallback(
      async () => creditCards.remove(creditCard),
      [creditCard, creditCards],
    );

    useImperativeHandle(ref, () => ({
      onConfirm,
    }));

    return (
      <>
        <Paragraph>
          {`Please confirm that you would like to remove `}
          <strong>
            {creditCard.nickname
              ? creditCard.nickname
              : `the card ending in ${creditCard.metadata.display_number.substring(
                  creditCard.metadata.display_number.lastIndexOf('-') + 1,
                  creditCard.metadata.display_number.length,
                )}`}
          </strong>
          {` from your account.`}
        </Paragraph>
        {creditCard.isPrimary ? (
          <>
            <Paragraph>
              <strong>This is your default card.</strong>
            </Paragraph>
            <>
              {creditCards.all.length > 1 ? (
                <>
                  <Paragraph>
                    If you remove this card, one of your other added cards will
                    become your default card.
                  </Paragraph>
                  <Paragraph>
                    Any properties that are currently set to use your default
                    card for subscription payments will be updated to use the
                    new default card.
                  </Paragraph>
                </>
              ) : (
                <Paragraph>
                  You do not have any other cards added to your account. To
                  avoid service disruption, we recommend adding another card
                  first before removing this one.
                </Paragraph>
              )}
            </>
          </>
        ) : (
          <Paragraph>
            Any properties that are have been explicitly set to use this card
            for subscription payments will be updated to instead use your
            default card.
          </Paragraph>
        )}
        <Paragraph noMargin>
          This action can only be reversed by manually adding your card again.
        </Paragraph>
      </>
    );
  },
};

export default RemoveCreditCardConfirmationModal;
