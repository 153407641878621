import {FunctionComponent, ReactElement} from 'react';

interface ConditionalWrapperProps {
  /**
   * The condition determining when to wrap the children.
   */
  condition: boolean;
  /**
   *  The component to wrap the  to apply to the children.
   */
  wrapper: (children: ReactElement) => JSX.Element;
  /**
   * The children to conditionally wrap.
   */
  children: ReactElement;
}

/**
 * Conditionally applies a wrapper to the provided
 * children when a condition is true.
 */
const ConditionalWrapper: FunctionComponent<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children);

export default ConditionalWrapper;
