import {useCallback, useEffect, useState} from 'react';

import {Form, Formik, FormikHelpers} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {AuthErrorCode, errorIsAuthErrorCodes} from 'auth/errors';
import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {InputField, SubmitButton} from 'components/forms_fields';
import {Hyperlink} from 'components_sb/navigation';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import Page from 'router/components/Page';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

interface ResetPasswordFormValues {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}
const ResetPasswordPage = () => {
  const {
    params: {token},
  } = useRoute();

  const {isLoggedIn} = useAuth();

  const [validatingToken, setValidatingToken] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);

  const {validatePasswordResetToken, resetPassword} = useAuth();

  const router = useRouter();

  // Validate that the password reset token is still valid upon page load
  useEffect(() => {
    const validateToken = async () => {
      try {
        if (!token) {
          throw Error();
        }
        setTokenValid(await validatePasswordResetToken(token));
      } catch (error) {
        setTokenValid(false);
      } finally {
        setValidatingToken(false);
      }
    };
    validateToken();
  }, [token, validatePasswordResetToken]);

  const showGenericError = useCallback(() => {
    toast.error('Sorry, there was an issue setting your password.');
  }, []);

  const handleSubmit = useCallback(
    async (
      {password, resetPasswordToken}: ResetPasswordFormValues,
      formikHelpers: FormikHelpers<ResetPasswordFormValues>,
    ) => {
      try {
        await resetPassword({
          passwordResetToken: resetPasswordToken,
          newPassword: password,
        });
        toast.success(
          'Your password has been successfully updated! Please log in with your new password.',
        );
        router.navigate('/login');
      } catch (error) {
        /**
         * Check if the error is an array of AuthErrorCode values.
         */
        if (errorIsAuthErrorCodes(error)) {
          const authErrorCodes = error;
          if (
            authErrorCodes.includes(AuthErrorCode.PASSWORD_RESET_TOKEN_EXPIRED)
          ) {
            /**
             * Handle password reset token expired.
             */
            formikHelpers.setFieldError(
              'password',
              'Your password reset link has expired. Please request a new one.',
            );
          } else {
            /**
             * Unknown error code.
             */
            showGenericError();
          }
        } else {
          /**
           * Error performing the registration request.
           */
          showGenericError();
        }
        /**
         * Reset the submitting state.
         */
        formikHelpers.setSubmitting(false);
      }
    },
    [resetPassword, showGenericError, router],
  );
  return (
    <Page
      title="Set New Password"
      backUrl="/login"
      centred
      redirect={isLoggedIn ? '/' : undefined}>
      {() => (
        <div className="mx-auto flex justify-center">
          {/* Token is being validated */}
          {validatingToken && (
            <div className="w-full h-52 flex items-center justify-center">
              <LoadingView />
            </div>
          )}

          {/* Either no token exists in the URL or the token is invalid */}
          {!validatingToken && !tokenValid && (
            <div className="w-full h-52 flex flex-col gap-y-2 items-center justify-center">
              <p>Your password reset link is invalid or has expired.</p>
              <Hyperlink persistQueryParams href="/reset-password">
                Click here to request a new one
              </Hyperlink>
            </div>
          )}

          {/* Token is valid and password reset may now be performed */}
          {!validatingToken && tokenValid && (
            <div>
              <Paragraph>
                Enter a new password for your Keyhook account below.
              </Paragraph>

              <div className="mt-4 mb-8">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    password: '',
                    passwordConfirmation: '',
                    resetPasswordToken: token,
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .required('Please enter a new password')
                      .min(6, 'Password must be at least 6 characters')
                      .max(128, 'Password must be 128 characters or fewer'),
                    passwordConfirmation: Yup.string()
                      .required('Please confirm the above password')
                      .test(
                        'passwords-match',
                        'Passwords must match',
                        function (value) {
                          return this.parent.password === value;
                        },
                      ),
                  })}>
                  {(formik) => (
                    <Form>
                      <InputField
                        formik={formik}
                        placeholder="New Password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        className="!rounded-full"
                        labelProps={{
                          title: 'New password (6+ characters)',
                        }}
                      />

                      <div className="mt-4">
                        <InputField
                          formik={formik}
                          labelProps={{
                            title: 'Confirm password',
                          }}
                          placeholder="Confirm Password"
                          name="passwordConfirmation"
                          type="password"
                          autoComplete="new-password"
                          className="!rounded-full"
                        />
                      </div>

                      <InputField
                        formik={formik}
                        name="resetPasswordToken"
                        type="hidden"
                      />
                      <SubmitButton
                        color="primary"
                        className="mt-4 rounded-full"
                        formik={formik}
                        text="Set new password"
                        submittingText="Saving new password"
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      )}
    </Page>
  );
};

export default ResetPasswordPage;
