import {useCallback, useEffect, useState} from 'react';

import {useQueryClient} from 'react-query';

import apartmentIcon from 'assets/img/property_types/apartment.png';
import houseIcon from 'assets/img/property_types/house.png';
import townhouseIcon from 'assets/img/property_types/townhouse.png';
import unitIcon from 'assets/img/property_types/unit.png';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {GridSelect, GridSelectOption} from 'components_sb/input';
import {Hyperlink} from 'components_sb/navigation';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

const OPTIONS: GridSelectOption[] = [
  {id: 'House', label: 'House', icon: houseIcon},
  {id: 'Apartment', label: 'Apartment', icon: apartmentIcon},
  {id: 'Townhouse', label: 'Townhouse', icon: townhouseIcon},
  {id: 'Unit', label: 'Unit', icon: unitIcon},
];

const PropertyTypeStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();

    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPropertyTypeStep,
      {
        propertyId: property.id,
        listingId: listing?.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const [selectedType, setSelectedType] = useState<string>(
    listing.propertyType ?? null,
  );

  const onSelectedTypeChange = useCallback((value: string | null) => {
    setSelectedType(value);
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const onClickNext = async () => {
    setSubmitting(true);

    if (selectedType) {
      /**
       * Set changes on the property.
       */
      property.propertyType = selectedType;
      property.lastOnboardingStepCompleted = 'listing_property_type';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the listing
       */
      listing.propertyType = selectedType;

      /**
       * Save the changes to the listing.
       */
      if (!(await saveResource(listing))) {
        setSubmitting(false);
        return;
      }

      /**
       * Track specific event type.
       */
      const event = {
        Apartment: TrackingService.Event.ListProperty_SelectApartment,
        House: TrackingService.Event.ListProperty_SelectHouse,
        Townhouse: TrackingService.Event.ListProperty_SelectTownhouse,
        Unit: TrackingService.Event.ListProperty_SelectUnit,
      }[selectedType];
      if (event) {
        TrackingService.trackEvent(event, {
          propertyId: property.id,
          listingId: listing.id,
        });
      }
      /**
       * Track general completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.ListProperty_CompletePropertyTypeStep,
        {
          propertyId: property.id,
        },
      );

      setSubmitting(false);

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );
    } else {
      setSubmitting(false);
    }
  };

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
        disabled: !selectedType,
      },
    },
  });

  return (
    <StepContainer
      align="center"
      title="What type of property do you have?"
      subtitle={
        <>
          <span>{`Most properties are houses but if you're unsure, `}</span>
          <Hyperlink
            href="https://help.keyhook.com/adding-a-property/type-of-property"
            openInNewTab>
            find out more here.
          </Hyperlink>
        </>
      }>
      <div className="w-full max-w-md">
        <GridSelect
          mode="manual:select"
          size="lg"
          multiple={false}
          maxColumns={1}
          options={OPTIONS}
          value={selectedType}
          onChange={onSelectedTypeChange}
        />
      </div>
    </StepContainer>
  );
};

export default PropertyTypeStep;
