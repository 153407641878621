const LeaseClause = ({
  title,
  clause,
  icon,
  onEdit,
  onDelete,
}: {
  title: string;
  clause: string;
  icon?: any;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  return (
    <div className="flex flex-1 items-center py-3 pr-2 w-full">
      {icon && <img src={icon} className="w-8 h-8 text-gray-200 mr-2" />}
      <label className="w-full">
        <h4 className="flex justify-between items-center">
          <span>{title}</span>
          <div className="flex z-0">
            {onEdit && (
              <a
                className="link link-secondary inline-block ml-2"
                onClick={onEdit}>
                Edit
              </a>
            )}
            {onDelete && (
              <a
                className="link link-secondary inline-block ml-2"
                onClick={onDelete}>
                Delete
              </a>
            )}
          </div>
        </h4>
        <p className="text-sm text-base-content text-opacity-60">{clause}</p>
      </label>
    </div>
  );
};

export default LeaseClause;
