import {Button} from 'components_sb/buttons';
import {Paragraph, Title} from 'components_sb/typography';

interface EmptyDataSetProps {
  title: string;
  message: string;
  image: any;
  buttonTitle?: string;
  buttonAction?: () => void;
}

const EmptyDataSet = ({
  title,
  message,
  image,
  buttonTitle,
  buttonAction,
}: EmptyDataSetProps) => {
  return (
    <div className="mx-auto flex flex-col items-center justify-center mt-3 max-w-xl">
      <div className="flex justify-center mb-8">
        <img src={image} style={{width: 'auto', height: 150}} />
      </div>

      <div className="flex justify-center">
        <Title size="xl" level="h2">
          {title}
        </Title>
      </div>

      <div className="flex justify-center mb-6">
        <Paragraph>{message}</Paragraph>
      </div>

      {buttonTitle && (
        <Button
          label={buttonTitle}
          category="primary"
          size="base"
          mode="manual"
          onClick={buttonAction}
        />
      )}
    </div>
  );
};

export default EmptyDataSet;
