import React from 'react';

import moment from 'moment';
import {HiOutlineBuildingOffice2} from 'react-icons/hi2';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const CompaniesRegistrarResultsCard = ({
  report,
}: {
  report: TenantCheckReport;
}) => {
  if (report.companiesRegisterResultsFetchedAt) {
    return (
      <Card title="Companies Registrar" icon={HiOutlineBuildingOffice2}>
        {report.companiesRegisterResults.results.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>NZBN</TableHeadItem>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Incorporation Date</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.companiesRegisterResults.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Hyperlink
                    href={result.company_record_link}
                    external
                    openInNewTab>
                    {result.nzbn}
                  </Hyperlink>
                </TableRowItem>

                <TableRowItem>
                  <p>{result.company_name}</p>
                  <p className="text-xs">{result.directors.join(', ')}</p>
                </TableRowItem>
                <TableRowItem>
                  {moment(result.incorporation_date).format(DATE_FORMAT)}
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the companies
            registrar.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.companiesRegisterResultsFetchedAt).format(
            DATE_TIME_FORMAT,
          )}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Companies Registrar" icon={HiOutlineBuildingOffice2}>
        <Paragraph>
          Company registrar results are currently being collected and will be
          updated on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default CompaniesRegistrarResultsCard;
