import React from 'react';

import {type FormikProps, type FormikHelpers, useFormik} from 'formik';
import {useQuery} from 'react-query';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {ResizingImageField} from 'components/forms_fields';

import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import User from 'models/users/User';
import {Page} from 'router/components';
import {errorViewForError} from 'utilities/ErrorHelpers';

type AvatarFormValues = {
  avatar: string;
};

const SettingsAvatarPage = () => {
  const {currentUser, setAvatar} = useAuth();

  const {isLoading, error, data} = useQuery('user-avatar-page', async () => {
    const user = await User.find(currentUser.id);
    return user.data;
  });

  const handleSubmit = async (
    formValues: AvatarFormValues,
    actions: FormikHelpers<AvatarFormValues>,
  ) => {
    const user = data;
    await setAvatar(user.avatar);
    actions.setSubmitting(false);
  };

  const previewUser = (formik: FormikProps<AvatarFormValues>) => {
    if (formik.values.avatar && formik.values.avatar.length > 0) {
      return new User({name: currentUser.name, avatar: formik.values.avatar});
    } else {
      return currentUser;
    }
  };

  const form = useFormik({
    initialValues: {avatar: ''},
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape({
      avatar: Yup.string().required().label('Avatar').min(1),
    }),
  });

  return (
    <Page title="Avatar" loading={isLoading} error={error}>
      {() => (
        <Card title="Change your avatar" className="mt-2">
          <form className="mt-2">
            <div className="flex justify-center">
              <UserAvatar user={previewUser(form)} size="20" />
            </div>

            <div className="mt-4">
              <ResizingImageField
                formik={form}
                label="Choose an image"
                name="avatar"
                width={500}
                height={500}
              />
            </div>

            <div className="mt-4">
              <Button
                label="Save avatar"
                mode="formik"
                form={form}
                category="primary"
                size="base"
              />
            </div>
          </form>
        </Card>
      )}
    </Page>
  );
};

export default SettingsAvatarPage;
