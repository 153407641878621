import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Browser} from '@capacitor/browser';
import {Capacitor} from '@capacitor/core';
import {Share} from '@capacitor/share';
import mailtoLink from 'mailto-link';
import {
  HiOutlineClipboardCopy,
  HiOutlineMail,
  HiOutlineShare,
} from 'react-icons/hi';
import {useQuery} from 'react-query';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import LinkShareButtons from 'components_sb/buttons/LinkShareButtons/LinkShareButtons';
import {TextField} from 'components_sb/input';
import {Paragraph} from 'components_sb/typography';
import PromoCode from 'models/users/PromoCode';
import {Page} from 'router/components';
import TrackingService from 'services/TrackingService';
import Event from 'services/TrackingService/events/enum';
import copyToClipboard from 'utilities/copy-to-clipboard';

import '@dotlottie/player-component';

const EMAIL_SUBJECT = 'I think you should check out Keyhook!';

const ReferralPage: FunctionComponent = () => {
  const {currentUser} = useAuth();

  /**
   * Whether sharing functionality with Capacitor is supported (null if not yet determined).
   */
  const [sharingSupported, setSharingSupported] = useState<boolean | null>(
    null,
  );

  /**
   * Fetch the user's referral code.
   */
  const referralCodeQuery = useQuery('user-referral-code', async () => {
    /**
     * We attempt to fetch all referral codes, since only the referral code for the
     * current will be allowed to be returned.
     */
    const {data: referralCodes} = await PromoCode.all();

    /**
     * Handle the array containing referral codes being empty.
     */
    if (!referralCodes.length) {
      throw new Error('No referral codes found');
    }

    /**
     * Retrun the code for the first (and only) referral code in the array
     * (this will be the user's referral code).
     */
    const [referralCode] = referralCodes;
    return referralCode.code;
  });

  /**
   * Deconstruct the user's referral code from the query.
   */
  const {data: referralCode} = referralCodeQuery;

  /**
   * Will be set to true once the page is ready to render.
   */
  const initialised = useMemo<boolean>(
    () => referralCodeQuery.isSuccess && typeof sharingSupported === 'boolean',
    [referralCodeQuery.isSuccess, sharingSupported],
  );

  /**
   * Checks if sharing functionality with Capacitor is supported and sets
   * the page as initialised once complete.
   */
  const checkIfSharingSupported = useCallback(async () => {
    setSharingSupported((await Share.canShare()).value);
  }, []);

  /**
   * Initialise the page on mount by checking if sharing functionality with
   * Capacitor is supported.
   */
  useEffect(() => {
    checkIfSharingSupported();
  }, [checkIfSharingSupported]);

  /**
   * Construct the referral link for the user using the referral code.
   */
  const referralLink = useMemo<string>(
    () => `https://keyhook.com/${referralCode}`,
    [referralCode],
  );

  /**
   * Text to include in social messaging apps - a shorter format than the email body.
   */
  const shareText = useMemo<string>(
    () =>
      `I thought you might be interested in using Keyhook to manage your rental property!\n\nFollow this link to check it out:\n${referralLink}`,
    [referralLink],
  );

  /**
   * Share via an external app (native only).
   */
  const onShare = useCallback(async () => {
    const {activityType} = await Share.share({
      /**
       * This will be the subject if sharing to email.
       */
      title: EMAIL_SUBJECT,
      /**
       * Title for the share modal (only supported on Android).
       */
      dialogTitle: 'Invite to Keyhook',
      /**
       * The message to share.
       */
      text: shareText,
    });

    /**
     * Track successful app share events.
     */
    if (activityType) {
      TrackingService.trackEvent(Event.ShareReferralLinkViaApp, {
        referralLink,
        appIdentifier: activityType,
      });
    }
  }, [referralLink, shareText]);

  /**
   * Send an email with the referral link.
   */
  const onEmail = useCallback(async () => {
    /**
     * Open the user's email client and prepopulate the email.
     * (using the Capacitor Browser plugin does not work for mailto links)
     */
    window.location.href = mailtoLink({
      subject: EMAIL_SUBJECT,
      body: [
        'Hey,',
        "I'm reaching out because I thought you might benefit from this great app I'm using called Keyhook.",
        "It's a property management app and it practically runs the show for you, handling everything in the background.",
        "It's very easy to use. It takes care of the rent collection, expense tracking, maintenance, etc. It basically does it all. And their customer service is awesome. Whenever I need anything, they sort me out immediately.",
        "Here's the website:",
        referralLink,
        "I promise you won't regret it. This app has made my life as a landlord so much easier.",
        `Kind regards,\n${currentUser.name}`,
      ].join('\n\n'),
    });

    /**
     * Track the intention to send the referral link via email.
     * (we can't determine if the email was actually sent)
     */
    TrackingService.trackEvent(Event.ShareReferralLinkViaEmail, {
      referralLink,
    });
  }, [referralLink, currentUser.name]);

  /**
   * Copy the referral link to the clipboard.
   */
  const onCopy = useCallback(async () => {
    await copyToClipboard({url: referralLink});
    /**
     * Track copying to the clipboard.
     */
    TrackingService.trackEvent(Event.CopyReferralLink, {
      referralLink,
    });
  }, [referralLink]);

  return (
    <Page
      title="Refer a Friend"
      loading={!initialised}
      error={referralCodeQuery.error}>
      {() => (
        <div className="flex flex-col items-start md:items-center">
          <div className="flex justify-center w-full max-w-[250px] md:max-w-sm mx-auto relative">
            {/* This wrapper div holds the position of the dotlottie-player while it's loading */}
            <div className="w-full aspect-1 -mt-10 -mb-4">
              <dotlottie-player
                autoplay
                loop
                mode="normal"
                src="/assets/lottie/share.lottie"
                style={{
                  height: 'auto',
                  width: '100%',
                }}
              />
            </div>
          </div>
          <div className="flex flex-col w-full max-w-2xl">
            <div className="mb-4">
              <Paragraph>
                Send your friends a personal invitation link to manage their
                property with Keyhook!
              </Paragraph>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="text-left md:text-center">
                <TextField
                  name="referralLink"
                  mode="read-only"
                  value={referralLink}
                />
              </div>
              <div className="w-full flex flex-col gap-y-4">
                {/* Use the native app share interface if supported, otherwise fall back to direct links below other buttoms. */}
                {sharingSupported && (
                  <Button
                    label="Share link"
                    icon={HiOutlineShare}
                    category="primary"
                    size="base"
                    mode="manual"
                    onClick={onShare}
                  />
                )}
                <Button
                  label="Email link"
                  icon={HiOutlineMail}
                  category="primary"
                  size="base"
                  mode="manual"
                  onClick={onEmail}
                />
                <Button
                  label="Copy link to clipboard"
                  icon={HiOutlineClipboardCopy}
                  category="primary"
                  size="base"
                  mode="manual"
                  onClick={onCopy}
                />
                {!sharingSupported && (
                  <LinkShareButtons url={referralLink} text={shareText} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default ReferralPage;
