import {useMemo} from 'react';

import dayjs, {Dayjs, ManipulateType} from 'dayjs';
import {isMobile} from 'react-device-detect';
import {useQuery} from 'react-query';

import useAuth from 'auth/provider/useAuth';
import {Alert} from 'components_sb/feedback';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import User from 'models/users/User';

type DayjsAddParams = [number, ManipulateType];
const TWO_WEEKS: DayjsAddParams = [2, 'weeks'];
const ONE_MONTH: DayjsAddParams = [1, 'month'];

const ReferAFriendAlert = () => {
  const {isLoggedIn, currentUser} = useAuth();

  /**
   * Fetch the date that the current user was created.
   */
  const {data: userCreatedAt, isSuccess: fetchedUserCreatedAt} = useQuery(
    'current_user_created_at',
    async () => {
      const {
        data: {createdAt},
      } = await User.find(currentUser.id);
      return createdAt;
    },
    {
      enabled: isLoggedIn,
    },
  );

  /**
   * Fetch the number of active tenancies the user has across their properties.
   */
  const {data: hasActiveTenancies, isSuccess: fetchedActiveTenanciesCount} =
    useQuery(
      'current_user_has_active_tenancies',
      async () => {
        /**
         * TODO: We perform two requests below because we cannot currently use
         * OR conditions for the status. When this has implemented, the below
         * should be refactored to a single request.
         */

        /**
         * First check if there are any active fixed tenancies.
         */
        const {data: firstActiveFixedTenancy} = await Tenancy.where({
          status: TenancyStatus.Active,
        }).first();

        /**
         * If a tenancy was returned, then we know that there is at least
         * one active fixed tenancy.
         */
        if (firstActiveFixedTenancy) {
          return true;
        }

        /**
         * If there were no active fixed tenancies, check if there are
         * any active periodic tenancies.
         */
        const {data: firstActivePeriodicTenancy} = await Tenancy.where({
          status: TenancyStatus.ActivePeriodic,
        }).first();

        /**
         * If a tenancy was returned, then we know that there is at least
         * one active fixed tenancy.
         */
        if (firstActivePeriodicTenancy) {
          return true;
        }

        /**
         * No tenancies were found, return false to indicate that
         * the current user has no active tenancies.
         */
        return false;
      },
      {
        enabled: isLoggedIn,
      },
    );

  /**
   * Indicates when all required data for determining the
   * visibility has been fetched.
   */
  const fetchedAllData = useMemo<boolean>(
    () => fetchedUserCreatedAt && fetchedActiveTenanciesCount,
    [fetchedUserCreatedAt, fetchedActiveTenanciesCount],
  );

  /**
   * Get the current date as a Dayjs instance.
   */
  const currentDate = useMemo<Dayjs>(() => dayjs(), []);

  /**
   * Determine whether the alert should be shown:
   * If the current user has active tenancies, then it should be shown after
   * two weeks since their account was created, otherwise we show it after onc month.
   */
  const shouldShow = useMemo(
    () =>
      fetchedAllData &&
      currentDate.isAfter(
        dayjs(userCreatedAt).add(
          ...(hasActiveTenancies ? TWO_WEEKS : ONE_MONTH),
        ),
      ),
    [fetchedAllData, userCreatedAt, currentDate, hasActiveTenancies],
  );

  return (
    <Alert
      type="info"
      asLink
      linkTo="/refer-a-friend"
      show={shouldShow}
      hideable
      id="refer-a-friend"
      title="Refer a friend"
      description={`${
        isMobile ? 'Tap' : 'Click'
      } here to invite a friend to manage their property with Keyhook`}
    />
  );
};

export default ReferAFriendAlert;
