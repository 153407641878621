import React, {useCallback, useMemo} from 'react';

import {UnmountClosed} from 'react-collapse';
import {useQuery} from 'react-query';

import useAuth from 'auth/provider/useAuth';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import LandlordProfile from 'models/users/LandlordProfile';

import PromoCodeEntryModal from './PromoCodeEntryModal';

const {useModal} = Modal.Imperative;

const PromoCodeEntry = () => {
  const openModal = useModal();

  const {currentUser} = useAuth();

  const hidePromoCodeEntry = useCurrentUserFlag('hide_promo_code_entry');

  const {data: landlordProfile, isFetched: fetchedLandlordProfile} = useQuery(
    'landlord-profile-with-subscription',
    async () => {
      const profile = await LandlordProfile.includes({
        landlord_subscription: 'subscription',
      })
        .where({user_id: currentUser.id})
        .first();
      return profile.data;
    },
  );

  // TODO: We need a flag for determining if a promo code has been applied since promoString is not returned
  const hasAlreadyAppliedPromoCode = useMemo(
    () =>
      fetchedLandlordProfile &&
      !!landlordProfile.landlordSubscription.promoString,
    [fetchedLandlordProfile, landlordProfile],
  );

  const onAddPromoCode = useCallback(async () => {
    const success = !!(await openModal(PromoCodeEntryModal, {landlordProfile}));
    if (success) {
      hidePromoCodeEntry.set(true);
    }
  }, [openModal, landlordProfile, hidePromoCodeEntry]);

  const onHide = useCallback(() => {
    hidePromoCodeEntry.set(true);
  }, [hidePromoCodeEntry]);

  const actions = useMemo(
    () => [
      {
        label: 'Yes',
        onClick: onAddPromoCode,
      },
      {
        label: 'No',
        onClick: onHide,
      },
    ],
    [onAddPromoCode, onHide],
  );

  const shouldShow = useMemo<boolean>(
    () =>
      !!hidePromoCodeEntry.isReady &&
      !hidePromoCodeEntry.value &&
      !!fetchedLandlordProfile &&
      !hasAlreadyAppliedPromoCode,
    [hidePromoCodeEntry, fetchedLandlordProfile, hasAlreadyAppliedPromoCode],
  );

  return (
    <Alert show={shouldShow} actions={actions}>
      Do you have a promo or referral code?
    </Alert>
  );
};

export default PromoCodeEntry;
