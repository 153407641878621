import {Model, Attr, BelongsTo, HasMany} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Listing from 'models/listings/Listing';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import {
  ManualTenant,
  RentalApplicationApplicantTenant,
  RentalApplicationTenant,
} from 'models/tenant_checks/RequestTypes';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import User from 'models/users/User';

export type TenantCheckType = 'free' | 'basic' | 'comprehensive';

@Model()
class TenantCheckRequest extends ApplicationRecord {
  static jsonapiType = 'tenant_check_requests';

  @Attr({persist: false}) completed: boolean;
  @Attr({persist: false}) completedAt: string | null;

  @Attr() checkType: TenantCheckType;
  @Attr() tenants: (
    | RentalApplicationTenant
    | RentalApplicationApplicantTenant
    | ManualTenant
  )[];

  @Attr({persist: false}) approved: boolean;

  @Attr() shouldPerformReferenceChecks: boolean;

  @Attr({persist: false}) requesterId: string;
  @Attr() listingId: string | null;
  @Attr() listingRentalApplicationId: string | null;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('listings') listing: Listing | null;
  @BelongsTo('users') requester: User | null;
  @BelongsTo('listing_rental_applications')
  listingRentalApplication: ListingRentalApplication | null;

  @HasMany('tenant_check_reports') tenantCheckReports: TenantCheckReport[];
}

export default TenantCheckRequest;
