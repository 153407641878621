import {type FormikProps} from 'formik';

import {errorsFor} from 'components/forms_fields/Helpers';

interface RadioOption {
  text: string;
  value: string | number;
}

interface RadioFieldProps {
  formik: FormikProps<any>;
  name: string;
  label: string;
  options: RadioOption[];
  type?: string;
  id?: string;
}

const RadioField = ({
  formik,
  label,
  name,
  options,
  // type = 'radio',
  ...rest
}: RadioFieldProps) => {
  const errors = errorsFor(formik, name);

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">{label}</span>
      </label>
      {options.map((opt, index) => (
        <label className="flex items-center mt-2" key={index}>
          <input
            type="radio"
            name={name}
            checked={formik.values[name].toString() === opt.value.toString()}
            className="radio radio-primary"
            value={opt.value}
            onChange={(e) => {
              formik.setFieldValue(name, e.target.value);
            }}
            {...rest}
          />
          <span className="label-text ml-4">{opt.text}</span>
        </label>
      ))}
      {errors && (
        <span className="text-red-500 tw-block">
          <small>{errors}</small>
        </span>
      )}
    </div>
  );
};

export default RadioField;
