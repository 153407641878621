import {FunctionComponent, ReactNode, useMemo} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';
import {
  HiCheck,
  HiOutlineCreditCard,
  HiOutlineSparkles,
  HiX,
} from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import colors from 'tailwindcss/colors';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import Tags, {
  TagColor,
  TagDefinition,
} from 'components_sb/typography/Tags/Tags';
import {PropertyBillingDetails} from 'hooks/usePropertyBillingDetails';
import {PaymentMethodType} from 'models/properties/Property';
import {TenancyStatus} from 'models/properties/Tenancy';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const SKELETON_COUNT = 6;

interface TenancyStatusTagProps {
  status: TenancyStatus;
}

const TenancyStatusTag: FunctionComponent<TenancyStatusTagProps> = ({
  status,
}) => {
  const tag = useMemo<TagDefinition>(() => {
    switch (status) {
      case TenancyStatus.Draft:
      case TenancyStatus.Ended:
        return {
          text: titleize(removeUnderscores(status)),
          color: TagColor.Grey,
        };

      case TenancyStatus.Active:
      case TenancyStatus.ActivePeriodic:
        return {
          text: 'Active',
          color: TagColor.Green,
        };

      case TenancyStatus.Pending:
      case TenancyStatus.AwaitingStartDate:
        return {
          text: titleize(removeUnderscores(status)),
          color: TagColor.Yellow,
        };
    }
  }, [status]);

  return (
    <Tags size="base" emphasis="secondary">
      {[tag]}
    </Tags>
  );
};

interface PaymentMethodTypeTagProps {
  type: PaymentMethodType;
}

const PaymentMethodTypeTag: FunctionComponent<PaymentMethodTypeTagProps> = ({
  type,
}) => {
  return (
    <Tags size="base" emphasis="secondary">
      {[
        {
          text: type === 'card' ? 'Credit/debit Card' : 'Smart Rent',
          color: TagColor.Blue,
          icon: type === 'card' ? HiOutlineCreditCard : HiOutlineSparkles,
        },
      ]}
    </Tags>
  );
};

interface StatusRowProps {
  testClass: string;
  label?: string;
  helpText?: string;
  status?: ReactNode;
}

const StatusRow: FunctionComponent<StatusRowProps> = ({
  testClass,
  label,
  helpText,
  status,
}) => (
  <div
    className={clsx(
      'flex-shrink-0',
      'w-full',
      'text-xs',
      'font-medium',
      'whitespace-nowrap',
      'flex flex-row items-center gap-x-4',
    )}>
    <div>
      {label ? (
        <span className="text-brand-850 text-opacity-70 flex flex-row gap-x-2 items-center">
          {!!helpText && (
            <HelpTextPresenter disableMargin>{helpText}</HelpTextPresenter>
          )}
          {label}
        </span>
      ) : (
        <Skeleton height={20} width={100} />
      )}
    </div>
    <div className="flex-1 h-0.5 bg-brand-50"></div>
    <div data-testClass={`property-billing-status--${testClass}`}>
      {status ?? <Skeleton height={24} width={50} borderRadius={999} />}
    </div>
  </div>
);

interface CreateStatusIconFunction {
  (args: {
    testValue: string;
    icon: IconType;
    color: 'red' | 'green' | 'gray';
  }): ReactNode;
}

const createStatusIcon: CreateStatusIconFunction = ({
  testValue,
  icon,
  color,
}) => {
  const Icon = icon;
  return (
    <div
      className={clsx(
        'w-6 h-6 relative',
        'p-1 box-border',
        'rounded-full flex items-center justify-center',
      )}
      style={{
        backgroundColor: colors[color][200],
      }}>
      <span className="hidden">{testValue}</span>
      <Icon
        className="w-full h-full"
        style={{
          color: colors[color][700],
        }}
      />
    </div>
  );
};

interface PropertyBillingDetailsListProps {
  propertyBillingDetails?: PropertyBillingDetails;
  showSkeleton?: boolean;
}

const PropertyBillingDetailsList: FunctionComponent<
  PropertyBillingDetailsListProps
> = ({propertyBillingDetails, showSkeleton}) => {
  /**
   * Access the required property billing details to display.
   */
  const {
    currentTenancyStatus,
    paymentMethodType,
    hasExplicitlySetCard,
    linkedCard,
    paymentConfigured,
    subscriptionPaymentSchedule,
    nextSubscriptionPaymentDate,
  } = propertyBillingDetails;

  /**
   * Reusable element for indicating a status is enabled.
   */
  const EnabledStatus = useMemo<ReactNode>(
    () => createStatusIcon({testValue: 'true', icon: HiCheck, color: 'green'}),
    [],
  );

  /**
   * Reusable element for indicating a status is disabled.
   */
  const DisabledStatus = useMemo<ReactNode>(
    () => createStatusIcon({testValue: 'false', icon: HiX, color: 'red'}),
    [],
  );

  /**
   * Reusable element for indicating a status is not applicable or available.
   */
  const NotApplicableStatus = useMemo<ReactNode>(
    () => (
      <div className="opacity-50">
        <Tags size="base" emphasis="secondary">
          {[
            {
              text: 'N/A',
              color: TagColor.Grey,
            },
          ]}
        </Tags>
      </div>
    ),
    [],
  );

  return (
    <div className={clsx('flex flex-col gap-y-2')}>
      {showSkeleton ? (
        [...Array(SKELETON_COUNT).keys()].map((index) => (
          <StatusRow testClass="skeleton" key={index} />
        ))
      ) : (
        <>
          <StatusRow
            testClass="current-tenancy-status"
            label="Current tenancy"
            helpText="The status of the current tenancy for the property."
            status={
              currentTenancyStatus ? (
                <TenancyStatusTag status={currentTenancyStatus} />
              ) : (
                NotApplicableStatus
              )
            }
          />
          <StatusRow
            testClass="payment-method"
            label="Payment method"
            helpText="How subscription fees will be paid for the property."
            status={
              !paymentMethodType ? (
                <Tags size="base" emphasis="secondary">
                  {[
                    {
                      text: 'Unchosen',
                      color: TagColor.Grey,
                    },
                  ]}
                </Tags>
              ) : (
                <PaymentMethodTypeTag type={paymentMethodType} />
              )
            }
          />
          <StatusRow
            testClass="payment-configured"
            label="Payment configured"
            helpText="Whether the chosen payment method for the property has been configured so that subscription fee payments can be made."
            status={paymentConfigured ? EnabledStatus : DisabledStatus}
          />
          <StatusRow
            testClass="linked-card"
            label="Linked card"
            helpText="The credit or debit card linked to this property that the subscription fee payments will be charged to."
            status={
              !paymentConfigured || paymentMethodType === 'rent' ? (
                NotApplicableStatus
              ) : (
                <Tags size="base" emphasis="secondary">
                  {[
                    {
                      text: hasExplicitlySetCard
                        ? linkedCard.nickname
                        : `Default (${linkedCard.nickname})`,
                      color: TagColor.Blue,
                    },
                  ]}
                </Tags>
              )
            }
          />
          <StatusRow
            testClass="subscription-schedule"
            label="Subscription"
            helpText="The currently active subscription fee and billing period for the property."
            status={
              !subscriptionPaymentSchedule ? (
                NotApplicableStatus
              ) : (
                <Tags size="base" emphasis="secondary">
                  {[
                    {
                      text: subscriptionPaymentSchedule,
                      color: TagColor.Blue,
                    },
                  ]}
                </Tags>
              )
            }
          />
          <StatusRow
            testClass="next-payment-date"
            label="Next payment"
            helpText="The date in which the next subscription fee will incur via the chosen payment method."
            status={
              !nextSubscriptionPaymentDate ? (
                NotApplicableStatus
              ) : (
                <Tags size="base" emphasis="secondary">
                  {[
                    {
                      text: nextSubscriptionPaymentDate.format('DD/MM/YYYY'),
                      color: TagColor.Blue,
                    },
                  ]}
                </Tags>
              )
            }
          />
        </>
      )}
    </div>
  );
};

export default PropertyBillingDetailsList;
