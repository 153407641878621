import {FunctionComponent} from 'react';

interface OnlyAvailableOnWebProps {
  featureName?: string;
}

const OnlyAvailableOnWeb: FunctionComponent<OnlyAvailableOnWebProps> = ({
  featureName,
}) => (
  <div className="flex-1 flex flex-col items-center justify-center gap-y-10">
    <div className="w-72 h-72 -my-16 flex flex-row justify-center">
      <dotlottie-player
        autoplay
        mode="normal"
        src="/assets/lottie/laptop.lottie"
        style={{height: '100%', width: '100%'}}
      />
    </div>
    <div className="flex flex-col gap-y-4 text-brand-850 text-center leading-snug max-w-md">
      <p className="text-lg font-medium">
        {featureName ?? 'This feature'} is currently only available on the web
        version of Keyhook.
      </p>
      <p className="text-base">
        Please sign in to Keyhook using your computer or mobile browser to
        continue.
      </p>
    </div>
  </div>
);

export default OnlyAvailableOnWeb;
