import {useContext} from 'react';

import {AuthContext, AuthContextValue} from '.';

type UseAuthHookReturn = AuthContextValue;

/**
 * A minimal wrapper hook for accessing the context value from the AuthProvider.
 */
const useAuth = (): UseAuthHookReturn => {
  const authContextValue = useContext(AuthContext);
  if (authContextValue === undefined) {
    throw Error('useAuth hook must be used within an AuthProvider');
  }
  return authContextValue;
};

export default useAuth;
