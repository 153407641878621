import {createStore} from 'framework7/lite/bundle';

import {AccountRole} from 'models/users/User';

interface F7StoreState {
  isLoggedIn: boolean;
  activeAccountRole: AccountRole | null;
  name: string | null;
  email: string | null;
  unconfirmedEmail: string | null;
}

const DEFAULT_STATE: F7StoreState = {
  isLoggedIn: false,
  activeAccountRole: null,
  name: null,
  email: null,
  unconfirmedEmail: null,
};

const F7Store = createStore({
  state: DEFAULT_STATE,

  getters: {
    isLoggedIn: ({state: {isLoggedIn}}: {state: F7StoreState}) => isLoggedIn,
    activeAccountRole: ({state: {activeAccountRole}}: {state: F7StoreState}) =>
      activeAccountRole,
  },
});

export default F7Store;
