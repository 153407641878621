import {useCallback, useMemo, useState} from 'react';

import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import {Transition} from '@headlessui/react';
import {type IconType} from '@react-icons/all-files';
import {HiCheckCircle} from '@react-icons/all-files/hi/HiCheckCircle';
import clsx from 'clsx';
import {FaFacebook, FaApple} from 'react-icons/fa';
import {FcGoogle} from 'react-icons/fc';

import socialAuth from 'auth/social';
import {SocialAuthUserData, SocialPlatform} from 'auth/social/platforms';
import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {Paragraph} from 'components_sb/typography';
import {TARGET_ENV} from 'globals/app-globals';

/**
 * Tailwind class configuration
 */
const classes = {
  // Classes for all permutations
  base: 'shadow-md',
};

/**
 * The callback invoked by the social auth handler upon success.
 */
interface SuccessCallback {
  (platform: SocialPlatform, userData: SocialAuthUserData): void;
}

/**
 * The callback invoked by the social auth handler upon an error.
 */
interface ErrorCallback {
  (platform: SocialPlatform): void;
}

export interface SocialAuthButtonConfig {
  /**
   * The action being performed by the button.
   */
  action: 'login' | 'register';
  /**
   * A callback function that is invoked upon successful authentication.
   */
  onSuccess: SuccessCallback;
  /**
   * A callback function that is invoked upon authentication failure.
   */
  onError: ErrorCallback;
}

interface SocialAuthButtonProps {
  /**
   * The auth provider to use for the button.
   */
  platform: SocialPlatform;
  /**
   * Configuration for the social authentication.
   */
  config: SocialAuthButtonConfig;
}

/**
 * Configuration for an auth provider at a component level.
 */
interface AuthProviderConfig {
  label: string;
  icon: IconType;
  classes: string;
}

/**
 * Definitions for each of the auth providers.
 */
const SOCIAL_PLATFORM_CONFIGS: Record<string, AuthProviderConfig> = {
  [SocialPlatform.Facebook]: {
    label: 'Facebook',
    icon: FaFacebook,
    classes:
      'bg-[#1877F2] border-[#1877F2] hover:bg-[#3587f3] hover:border-[#3587f3] text-white',
  },
  [SocialPlatform.Google]: {
    label: 'Google',
    icon: FcGoogle,
    classes:
      'bg-white border-[#f7f7f7] hover:bg-[#eeeeee] hover:border-[#eeeeee] text-[#757575]',
  },
  [SocialPlatform.Apple]: {
    label: 'Apple',
    icon: FaApple,
    classes:
      'bg-black border-black hover:bg-[#262626] hover:border-[#262626] text-white',
  },
};

/**
 * A button for authenticating a user via a social platform.
 */
const SocialAuthButton = ({platform, config}: SocialAuthButtonProps) => {
  const platformConfig = SOCIAL_PLATFORM_CONFIGS[platform];

  const labelPrefix = useMemo(
    () => (config.action === 'register' ? 'Register with' : 'Log in with'),
    [config.action],
  );

  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);

  /**
   * Intercept the success callback.
   */
  const onSuccess = useCallback<SuccessCallback>(
    (...args) => {
      setSuccess(true);
      setWaiting(false);
      config.onSuccess(...args);
    },
    [config],
  );

  /**
   * Intercept the error callback.
   */
  const onError = useCallback<ErrorCallback>(
    (...args) => {
      setWaiting(false);
      config.onError(...args);
    },
    [config],
  );

  const authenticateApple = useCallback(async () => {
    /**
     * Set the redirect URI to the current page origin without the path.
     */
    const redirectURI = window.location.origin;

    /**
     * Determine the client ID to use based on the environment.
     */
    let clientId: string;
    if (TARGET_ENV === 'staging') {
      clientId = 'com.keyhook.staging-app';
    } else if (TARGET_ENV === 'production') {
      clientId = 'com.keyhook.app';
    } else {
      clientId = 'com.keyhook.dev-app';
    }

    /**
     * Handle the response from succesful authentication and pass
     * releveant data to the callback function.
     */
    const handleSuccess = () => (response: SignInWithAppleResponse) => {
      if (response.response && response.response.identityToken) {
        const jwt = response.response.identityToken;

        const name = response.response.user
          ? response.response.givenName + ' ' + response.response.familyName
          : null;

        onSuccess(SocialPlatform.Apple, {name, jwt});
      } else {
        onError(SocialPlatform.Apple);
      }
    };

    const options: SignInWithAppleOptions = {
      clientId: clientId,
      redirectURI: redirectURI,
      scopes: 'email name',
      state: 'state',
      nonce: 'nonce',
    };

    /**
     * Trigger the authentication flow.
     */
    try {
      const resp = SignInWithApple.authorize(options);
      resp.then(handleSuccess());
    } catch (e) {
      onError(SocialPlatform.Apple);
    }
  }, [onSuccess, onError]);

  const onClick = useCallback(() => {
    setWaiting(true);
    if (platform === SocialPlatform.Apple) {
      authenticateApple();
      return;
    }

    socialAuth.authenticate(platform, onSuccess, onError);
  }, [platform, onSuccess, onError, authenticateApple]);

  return (
    <>
      <Button
        label={`${labelPrefix} ${platformConfig.label}`}
        category="custom"
        customClasses={clsx(classes.base, platformConfig.classes)}
        size="base"
        mode="manual"
        onClick={onClick}
        icon={platformConfig.icon}
      />
      <Transition
        show={waiting || success}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx(
          'fixed z-50 top-0 left-0',
          'w-screen h-screen',
          'bg-white',
          'flex justify-center items-center',
        )}>
        {waiting && (
          <SpinningLoader
            color="brand"
            size="lg"
            message={`Waiting for ${platformConfig.label}...`}
          />
        )}
        {success && (
          <div className="flex flex-col gap-y-6 items-center justify-center text-center">
            <div className="text-green-600 flex flex-row gap-x-2 items-center justify-center">
              <HiCheckCircle className="w-10 h-10" />
              <span className="text-xl">Success!</span>
            </div>
            <Paragraph secondary>
              You will be redirected in a few moments...
            </Paragraph>
          </div>
        )}
      </Transition>
    </>
  );
};

export default SocialAuthButton;
