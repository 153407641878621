import {FunctionComponent} from 'react';

import {Router} from 'framework7/types';

import useRouter from 'router/hooks/useRouter';

/**
 * Require a location to navigate to and also accept any of the options that
 * can be provided to the Framework7 navigate function as props.
 */
type NavigateProps = {href: string} & Router.RouteOptions;

/**
 * A (semi) drop-in replacement for React Router's Navigate component that instead
 * users the Frameowrk7 router to navigate.
 */
const Navigate: FunctionComponent<NavigateProps> = ({
  href,
  ...routeOptions
}) => {
  /**
   * Access the Framework7 router from the route context.
   */
  const router = useRouter();

  /**
   * Invoke the navigation as soon as the component is rendered.
   */
  router.navigate(href, routeOptions);

  /**
   * Do not render anything.
   */
  return null;
};

export default Navigate;
