import {Model, Attr, BelongsTo, HasMany, HasOne} from 'spraypaint';

import {BASE_API_URL} from 'globals/app-globals';
import ApplicationRecord from 'models/ApplicationRecord';
import InspectionAction from 'models/inspections/InspectionAction';
import Tenancy from 'models/properties/Tenancy';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import ServiceRequestQuote from 'models/service_requests/ServiceRequestQuote';
import User from 'models/users/User';

@Model()
class ServiceRequest extends ApplicationRecord {
  static jsonapiType = 'service_requests';

  static categoryTypes = [
    'Plumbing',
    'Electrical',
    'Building',
    'Appliance Repair',
    'Window Repair',
    'Gardening',
    'Other',
  ];

  @Attr() title: string;
  @Attr() description: string;
  @Attr() category: string;

  @Attr() status: string;

  @Attr() publicId: string;

  @Attr() finalizedCost: number;

  @Attr({persist: false}) customerInvoiceUrl: string;

  @Attr() appointmentTime: string;

  @Attr({persist: false}) serviceRequestQuotesCount: number;

  @Attr() renterContactName: string;
  @Attr() renterContactPhoneNumber: string;

  @Attr() landlordRejectedReason: string;
  @Attr() applianceMakeAndModel: string;

  @Attr() completedReason: string;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() tenancyId: string;
  @Attr({persist: false}) userId: string;

  @Attr({persist: false}) location: string;

  @BelongsTo('tenancies') tenancy: Tenancy;
  @BelongsTo('users') user: User;

  @HasMany('service_request_attachments')
  serviceRequestAttachments: ServiceRequestAttachment[];
  @HasMany('service_request_quotes')
  serviceRequestQuotes: ServiceRequestQuote[];

  @HasOne('service_request_quotes')
  acceptedServiceRequestQuote: ServiceRequestQuote;

  @HasOne('inspection_actions') inspectionAction: InspectionAction;

  get shareableUrl(): string {
    return `${BASE_API_URL}/jobs/${this.publicId}`;
  }
}

export default ServiceRequest;
