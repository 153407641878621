import {FunctionComponent} from 'react';

import * as RadixSwitch from '@radix-ui/react-switch';
import {cva} from 'class-variance-authority';
import clsx from 'clsx';

import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';

interface BaseSwitchProps {
  id: string;
  value: boolean;
  onChange: (value: boolean) => void;
  size?: 'base' | 'lg';
}

interface SwitchWithLabelProps extends BaseSwitchProps {
  labelProps: Omit<FieldLabelProps, 'htmlFor' | 'noMargin'>;
  labelPosition?: 'left' | 'above';
}

type SwitchProps = BaseSwitchProps | SwitchWithLabelProps;

const classes = {
  container: cva(['flex justify-start'], {
    variants: {
      labelPosition: {
        left: 'flex-row items-center gap-x-4',
        above: 'flex-col items-start gap-y-2',
      },
    },
  }),
  switchRoot: cva(
    [
      'group',
      'rounded-full',
      'transition-colors duration-300',
      'bg-brand-200 hover:bg-brand-300',
      'data-[state=checked]:bg-brand-500 data-[state=checked]:hover:bg-brand-600',
      'relative',
    ],
    {
      variants: {
        size: {
          base: 'w-[56px] h-[28px]', // w-14 h-7
          lg: 'w-[84px] h-[42px]', // 1.5 * base
        },
      },
    },
  ),
  switchThumb: cva(
    [
      'tw-block rounded-full m-[3px]',
      'transition-all duration-300',
      'bg-white drop-shadow-lg',
      'translate-x-0',
    ],
    {
      variants: {
        size: {
          base: [
            'w-[calc(28px_-_6px)] h-[calc(28px_-_6px)]', // root height - double margin (6px)
            'data-[state=checked]:translate-x-[calc(56px_-_28px)]', // root width - root height - double margin (6px)
          ],
          lg: [
            'w-[calc(42px_-_6px)] h-[calc(42px_-_6px)]', // root height - margin (6px)
            'data-[state=checked]:translate-x-[calc(84px_-_42px)]', // root width - root height - double margin (6px)
          ],
        },
      },
    },
  ),
};

const Switch: FunctionComponent<SwitchProps> = ({
  id,
  value,
  onChange,
  size = 'base',
  ...props
}) => {
  //
  console.log();
  return (
    <div
      className={classes.container({
        labelPosition: 'labelPosition' in props ? props.labelPosition : null,
      })}>
      {'labelProps' in props && (
        <div className="whitespace-nowrap cursor-pointer">
          <FieldLabel
            htmlFor={id}
            noMargin={
              'labelPosition' in props && props.labelPosition === 'left'
            }
            {...props.labelProps}
          />
        </div>
      )}
      <RadixSwitch.Root
        id={id}
        checked={value}
        onCheckedChange={onChange}
        className={classes.switchRoot({size})}>
        <RadixSwitch.Thumb className={classes.switchThumb({size})} />
      </RadixSwitch.Root>
    </div>
  );
};

export default Switch;
