import {FunctionComponent} from 'react';

import useCustomerly from 'hooks/useCustomerly';

interface CustomerlyProviderProps {
  children: JSX.Element;
}

const CustomerlyProvider: FunctionComponent<CustomerlyProviderProps> = ({
  children,
}) => {
  useCustomerly();
  return children;
};

export default CustomerlyProvider;
