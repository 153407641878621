import clsx from 'clsx';
import {LuPartyPopper} from 'react-icons/lu';

import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useSubscriptions from 'providers/Subscriptions/hooks/useSubscriptions';

const FreePeriodSection = () => {
  const {
    subscriptionStatus: {
      isWithinFreePeriod,
      freeDaysRemaining,
      freePeriodTypeLabel,
      freePeriodEndDateLabel,
    },
  } = useSubscriptions();

  /**
   * Do not render the card unless the Landlord is currently within their
   * trial or bonus period.
   */
  if (!isWithinFreePeriod) {
    return null;
  }

  return (
    <Card icon={LuPartyPopper} title="Free access" testId="free-period-section">
      <Paragraph testId="free-period-section--heading">
        <span className="font-medium text-lg">
          {`You are currently on a ${freePeriodTypeLabel}!`}
        </span>
      </Paragraph>
      <Paragraph>
        You may use Keyhook for free<span className="font-bold">*</span>
        &nbsp;during this time and will no subscription fees will be incurred.
      </Paragraph>
      <Paragraph testId="free-period-section--time-remaining">
        Your {freePeriodTypeLabel} ends&nbsp;
        <span
          className={clsx(
            'font-bold',
            freeDaysRemaining > 3 ? 'text-green-500' : 'text-yellow-500',
          )}>
          {freePeriodEndDateLabel}
        </span>
        .
      </Paragraph>
      <div className="mt-4">
        <Paragraph secondary>
          <span className="font-bold">*</span>&nbsp;Please note that charges for
          optional extras may still apply, including Trade Me listings, tenant
          checks, and for offerings wherever else stated.
        </Paragraph>
      </div>
    </Card>
  );
};

export default FreePeriodSection;
