import {useCallback} from 'react';

import {isMobile} from 'react-device-detect';
import {HiArrowLeft} from 'react-icons/hi';

import {Button} from 'components_sb/buttons';
import Page from 'router/components/Page';
import useRouter from 'router/hooks/useRouter';

import '@dotlottie/player-component';

const PageNotFound = () => {
  const router = useRouter();

  const onGoHome = useCallback(() => {
    router.navigate('/', {force: true});
  }, [router]);

  return (
    <Page disableNavbar centred>
      {() => (
        <div className="flex flex-col text-center">
          <div className="flex justify-center items-center">
            <dotlottie-player
              autoplay
              loop
              mode="normal"
              src="/assets/lottie/error_page.lottie"
              style={{height: 300, width: 300}}
            />
          </div>
          <p className="text-xl font-medium text-brand-850 opacity-70 mb-12">
            We couldn't find the page you were looking for
          </p>
          <Button
            label={`${isMobile ? 'Tap' : 'Click'} here to go home`}
            icon={HiArrowLeft}
            category="primary"
            size="base"
            mode="manual"
            onClick={onGoHome}
          />
        </div>
      )}
    </Page>
  );
};

export default PageNotFound;
