import {useMemo} from 'react';

import {Alert} from 'components_sb/feedback';
import useRoute from 'router/hooks/useRoute';

const AfterAddPropertyAlert = () => {
  const route = useRoute();

  /**
   * If the afterAddProperty was set to true in the route props
   * when navigating to the page, show the alert.
   */
  const showAfterAddPropertyAlert = useMemo(
    () =>
      'afterAddProperty' in route.props &&
      route.props.afterAddProperty === true,
    [route.props],
  );

  return (
    <Alert
      type="success"
      title="Nice work, you've added your property!"
      show={showAfterAddPropertyAlert}
    />
  );
};

export default AfterAddPropertyAlert;
