import React, {FunctionComponent, useCallback, useMemo} from 'react';

import {pick} from 'lodash';
import moment from 'moment';
import {
  HiOutlineClipboardList,
  HiOutlineCog,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
} from 'react-icons/hi';

import {Stats, StatsProps} from 'components_sb/layout';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

type TenancyStatsProps = {
  property: Property;
  tenancy?: Tenancy;
} & Pick<StatsProps, 'small' | 'noMargin' | 'interactive'>;

const TenancyStats: FunctionComponent<TenancyStatsProps> = ({
  tenancy,
  ...props
}) => {
  const route = useRoute();
  const router = useRouter();

  const maintenanceRequestCount = useMemo(() => {
    if (!tenancy) {
      return 0;
    }

    let serviceCount = 0;
    if (tenancy.openServiceRequests.length > 0) {
      serviceCount = tenancy.openServiceRequests.length;
    } else {
      serviceCount = tenancy.serviceRequests.filter(
        (req) =>
          req.status !== 'landlord_rejected' && req.status !== 'completed',
      ).length;
    }

    return serviceCount;
  }, [tenancy]);

  const nextInspectionDate = useMemo(() => {
    if (!tenancy) {
      return '-';
    }

    if (tenancy.nextInspectionDate) {
      return moment(tenancy.nextInspectionDate).format('DD MMM');
    } else {
      return '-';
    }
  }, [tenancy]);

  const nextInspectionSubtitle = useMemo(() => {
    if (!tenancy) {
      return '';
    }

    let nextInspectionDate: string;

    if (tenancy.nextInspectionDate) {
      const today = new Date();
      const inspectionDate = new Date(tenancy.nextInspectionDate);

      const timeDifference = inspectionDate.getTime() - today.getTime();
      const days = Math.floor(Number(timeDifference / (1000 * 3600 * 24)));

      if (days < 0) {
        nextInspectionDate = 'Overdue';
      } else if (days === 0) {
        nextInspectionDate = 'Today';
      } else {
        nextInspectionDate = `${days.toFixed(0)} Days`;
      }
    } else {
      nextInspectionDate = 'Not set';
    }

    return nextInspectionDate;
  }, [tenancy]);

  const leaseExpires = useMemo(() => {
    if (!tenancy) {
      return '-';
    }

    if (tenancy.endDate) {
      return moment(tenancy.endDate).format('DD MMM');
    } else {
      return 'Ongoing';
    }
  }, [tenancy]);

  const leaseExpiresSubtitle = useMemo(() => {
    if (!tenancy) {
      return '';
    }

    if (tenancy.endDate) {
      const today = new Date();
      const leaseDate = new Date(tenancy.endDate);

      const timeDifference = leaseDate.getTime() - today.getTime();
      const num = Number(timeDifference / (1000 * 3600 * 24)).toFixed(0);

      return `${num} Days`;
    } else {
      return 'Periodic Lease';
    }
  }, [tenancy]);

  /**
   * Construct the base path to the management page for the property
   * that the tenancy stats are for.
   */
  const propertyPath = useMemo(
    () => `/properties/${tenancy.propertyId}`,
    [tenancy],
  );

  /**
   * Scrolls to the element if the user is already on the property page to
   * avoid an unnecessary page reload, otherwise navigates to the property
   * page and passes the scrollToIdOnMount prop to scroll to the element
   * once the page has loaded.
   */
  const handleScrollOrNavigate = useCallback(
    (elementId: string) => {
      if (route.path === propertyPath) {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      } else {
        router.navigate(propertyPath, {
          props: {
            scrollToIdOnMount: elementId,
          },
        });
      }
    },
    [route.path, propertyPath, router],
  );

  return tenancy && tenancy.isCurrent ? (
    <Stats
      {...pick(props, ['small', 'noMargin', 'interactive'])}
      stats={[
        {
          icon: HiOutlineCurrencyDollar,
          title: 'Next rent payment due',
          value: moment(tenancy.nextRentPaymentDue).format('DD MMM'),
          description: moment(tenancy.nextRentPaymentDue).format('dddd'),
          href: '/financials',
        },
        {
          icon: HiOutlineClipboardList,
          title: 'Next inspection',
          value: nextInspectionDate,
          description: nextInspectionSubtitle,
          onClick: (event) => {
            event.preventDefault();
            handleScrollOrNavigate('inspections');
          },
        },
        {
          icon: HiOutlineDocumentText,
          title: 'Lease end date',
          value: leaseExpires,
          description: leaseExpiresSubtitle,
          onClick: () => handleScrollOrNavigate('documents'),
          // href: `${basePropertyPath}/documents/LEASE_DOCUMENT_ID`, TODO: Link to lease document
        },
        {
          icon: HiOutlineCog,
          title: 'Maintenance requests',
          value: maintenanceRequestCount.toString(),
          description: 'Open',
          onClick: () => handleScrollOrNavigate('maintenance-requests'),
        },
      ]}
    />
  ) : null;
};

export default TenancyStats;
