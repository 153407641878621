import {useRef, useState} from 'react';

import {
  useFloating,
  useInteractions,
  offset,
  shift,
  useClick,
  useDismiss,
} from '@floating-ui/react';
import clsx from 'clsx';
import {HiChevronDown, HiDotsHorizontal} from 'react-icons/hi';
import {useResizeDetector} from 'react-resize-detector';

import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';

import {Action, actionIsButton, actionIsLink} from '../../../types/actions';
import Button from '../Button/Button';
import FloatingActions from '../FloatingActions/FloatingActions';

interface InlineActionItem {
  (props: {action: Action}): JSX.Element;
}

const InlineActionItem: InlineActionItem = ({action}) => {
  const {label, icon} = action;

  if (actionIsButton(action)) {
    return (
      <Button
        testId={action.testId}
        label={label}
        icon={icon}
        category="secondary"
        size="sm"
        mode="manual"
        onClick={action.onClick}
      />
    );
  }

  if (actionIsLink(action)) {
    return (
      <Button
        testId={action.testId}
        label={label}
        icon={icon}
        category="secondary"
        size="sm"
        mode="link"
        linkTo={action.linkTo}
      />
    );
  }

  /**
   * The action shape was not valid.
   */
  return null;
};

interface ResponsiveActionSelect {
  (props: {
    actions: Action[];
    justify?: 'start' | 'center' | 'end';
  }): JSX.Element;
}

/**
 * A set of actions (either buttons or links) that are displayed as a series of
 * buttons when space permits, or as a dropdown select menu otherwise.
 */
const ResponsiveActionSelect: ResponsiveActionSelect = ({
  actions,
  justify = 'start',
}) => {
  const [floatingActionsOpen, setFloatingActionsOpen] = useState(false);
  const inlineContentRef = useRef<HTMLDivElement>();

  const xsBreakpoint = useTailwindBreakpoint('xs');

  /**
   * Create a floating instance.
   */
  const floating = useFloating({
    open: floatingActionsOpen,
    onOpenChange: setFloatingActionsOpen,
    placement: 'bottom',
    middleware: [
      /**
       * Add a gap between the link and the popover.
       */
      offset(10),

      /**
       * Keep the popover in view of the window along the x-axis.
       */
      shift({
        /**
         * Ensure at least 10px of horizontal padding when shifting.
         */
        padding: 10,
      }),
    ],
  });

  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
  ]);

  /**
   * Set the content to be measured when there are
   * changes to the actions or the container width.
   */
  // useEffect(() => {
  //   if (xsBreakpoint) {
  //     setMeasuring(true);
  //   }
  // }, [actions, containerWidth, xsBreakpoint]);

  /**
   * Measure the content when required as indicated by the
   * 'measuring' property.
   */
  // useEffect(() => {
  //   if (measuring) {
  //     if (containerRef.current && inlineContentRef.current) {
  //       setWidthRequiredToFit(inlineContentRef.current.scrollWidth);
  //       setMeasuring(false);
  //     }
  //   }
  // }, [containerRef, measuring]);

  /**
   * Determine whether the content can fit within the current
   * size of the container.
   */
  // const contentCanFit = useMemo(() => {
  //   return (
  //     !!widthRequiredToFit &&
  //     !!containerWidth &&
  //     widthRequiredToFit <= containerWidth
  //   );
  // }, [containerWidth, widthRequiredToFit]);
  // TODO: Fix flickering loop when measuring
  const contentCanFit = false;

  const smBreakpoint = useTailwindBreakpoint('sm');

  return actions.length === 0 ? null : (
    <div
      className={clsx('flex-1', 'flex flex-row', 'overflow-visible visible')}
      style={{
        justifyContent: justify,
      }}>
      {/* Inline actions */}
      {((actions.length === 1 && smBreakpoint) ||
        (contentCanFit && xsBreakpoint)) && (
        <div
          ref={inlineContentRef}
          className="flex-1 xs:flex-none flex flex-row gap-x-4 gap-y-2">
          {actions.map((action) => (
            <InlineActionItem key={action.label} action={action} />
          ))}
        </div>
      )}

      {/* Floating actions */}
      {(actions.length > 1 || !smBreakpoint) &&
        (!contentCanFit || !xsBreakpoint) && (
          <>
            {/* TODO: Refactor to use the base button component */}
            <button
              data-testid="page-header-actions-dropdown"
              ref={floating.refs.setReference}
              className={clsx(
                'flex',
                'flex-row',
                'gap-x-1',
                'justify-center',
                'items-center',
                'font-medium',
                'whitespace-nowrap',
                'text-center',
                'leading-none',
                'transition-all',
                'duration-200',
                'scale-100',
                'active:scale-95',
                'ring-0',
                'focus:ring-2',
                'border-2',
                'select-none',
                'bg-transparent hover:bg-brand-100 hover:bg-opacity-20',
                'border-brand-500 hover:border-brand-600',
                'text-brand-500',
                'ring-brand-200',
                'text-xs',
                'h-8',
                'min-h-8',
                'max-h-8',

                // Icon format
                'px-0',
                'rounded-full',
                'w-8',

                // Standard format
                'xs:pl-4',
                'xs:pr-3',
                'xs:rounded-lg',
                'xs:w-auto',
              )}
              {...getReferenceProps()}>
              {xsBreakpoint ? (
                // Button with text (larger screens)
                <>
                  <span className="hidden xs:flex">Actions</span>
                  <HiChevronDown
                    className={clsx(
                      'hidden xs:flex',
                      'w-4 h-4',
                      'transition-transform duration-200',
                      floatingActionsOpen ? 'rotate-180' : 'rotate-0',
                    )}
                  />
                </>
              ) : (
                // Icon button (smaller screens)
                <HiDotsHorizontal className={clsx('flex xs:hidden w-5 h-5')} />
              )}
            </button>
            <FloatingActions
              floating={floating}
              isOpen={floatingActionsOpen}
              onClose={() => setFloatingActionsOpen(false)}
              actions={actions}
              {...getFloatingProps()}
            />
          </>
        )}
    </div>
  );
};

export default ResponsiveActionSelect;
