import {
  type ForwardRefRenderFunction,
  useCallback,
  useMemo,
  useImperativeHandle,
} from 'react';

import validateCard from 'card-validator';
import {useFormik, type FormikConfig} from 'formik';
import {isEmpty} from 'lodash';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {InlineError, SpinningLoader} from 'components_sb/feedback';
import {TextField} from 'components_sb/input';
import {Divider, FormFieldsContainer} from 'components_sb/layout';
import {FieldLabel, Title} from 'components_sb/typography';
import {TARGET_ENV} from 'globals/app-globals';
import useFormErrorHelpers from 'hooks/useFormErrorHelpers';
import {CardDetails, useCreditCards} from 'providers/CreditCardsProvider';

import CardTestingTools from './_CardTestingTools';
/**
 * CardTestingTools is for development/testing use ONLY.
 */

type CreditCardInput = ForwardRefRenderFunction<any, any>;

/**
 * A form for entering card payment details securely via Pin Payments
 * wrapped as an input component for usage in other forms.
 */
const CreditCardInput: CreditCardInput = (props, ref) => {
  /**
   * Get current user details.
   */
  const {isLoggedIn} = useAuth();

  /**
   * Access the credit cards context.
   */
  const creditCards = useCreditCards();

  /**
   * Handle saving payment details via Pin Payments.
   */
  const handleSubmit = useCallback(
    async (cardDetails: CardDetails) => {
      /**
       * Save and return the billing method.
       */
      return await creditCards.add(cardDetails);
    },
    [creditCards],
  );

  const initialValues = useMemo<CardDetails>(
    () => ({
      nickname: '',
      name: '',
      number: '',
      expiry_month: '',
      expiry_year: '',
      cvc: '',
      address_line1: '',
      address_line2: '',
      address_city: '',
      address_state: '',
      address_postcode: '',
      address_country: '',
    }),
    [],
  );

  /**
   * Create the form config for entering billing information.
   */
  const formikConfig = useMemo<FormikConfig<CardDetails>>(
    () => ({
      initialValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        /**
         * Custom name for the card
         */
        nickname: Yup.string()
          .required('Please enter a nickname for the card')
          .min(3, 'Nicknames must be at least 3 characters')
          .max(25, 'Nicknames must be no more than 25 characters'),
        /**
         * Cardholder name
         */
        name: Yup.string()
          .required('Please enter the name on the card')
          .test(
            'test-number',
            'Please enter a valid cardholder name',
            (value) => validateCard.cardholderName(value).isValid,
          ),
        /**
         * Card number
         */
        number: Yup.string()
          .required('Please enter the credit/debit card number')
          .test(
            'test-number',
            'Please enter a valid credit/debit card number',
            (value) => validateCard.number(value).isValid,
          ),
        /**
         * Card expiry month
         */
        expiry_month: Yup.string()
          .required('Please enter the month of expiry for the card')
          .test(
            'test-number',
            'Please enter a valid month of expiry',
            (value) => value && validateCard.expirationMonth(value).isValid,
          ),
        /**
         * Card expiry year
         */
        expiry_year: Yup.string()
          .required('Please enter the year of expiry for the card')
          .test(
            'test-number',
            'Please enter a valid year of expiry',
            (value) => value && validateCard.expirationYear(value).isValid,
          ),
        /**
         * CVC / Security code
         */
        cvc: Yup.string()
          .required('Please enter the CVC code for the card')
          .test(
            'test-number',
            'Please enter a valid CVC code',
            (value) => value && validateCard.cvv(value, [3, 4]).isValid,
          ),
        /**
         * Address line 1
         */
        address_line1: Yup.string().required(
          'Please enter the first line of the address',
        ),
        /**
         * Address line 2
         */
        address_line2: Yup.string(),
        /**
         * City
         */
        address_city: Yup.string().required(
          'Please enter the city for the address',
        ),
        /**
         * Region / State
         */
        address_state: Yup.string().required(
          'Please enter the region for the address',
        ),
        /**
         * Postal code
         */
        address_postcode: Yup.string()
          .required('Please enter the postal code for the address')
          .test(
            'test-number',
            'Please enter a valid postal code',
            (value) =>
              value && validateCard.postalCode(value, {minLength: 4}).isValid,
          ),
        /**
         * Country
         */
        address_country: Yup.string().required(
          'Please enter the country for the address',
        ),
      }),
    }),
    [initialValues, handleSubmit],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Enable form error helpers for the form.
   */
  useFormErrorHelpers(form, {
    enableToast: true,
    enableScroll: true,
  });

  /**
   * Submit the Formik form in response to the onSave
   * function being invoked via the imperative ref.
   */
  const onSave = useCallback(async () => {
    const formErrors = await form.validateForm();
    if (!isEmpty(formErrors)) {
      throw new Error('Form has errors');
    }
    return await form.submitForm();
  }, [form]);

  /**
   * Provide the function to the parent to enable the form
   * to be submitted.
   */
  useImperativeHandle(ref, () => ({
    onSave,
  }));

  /**
   * Handle view for no current user.
   */
  if (!isLoggedIn) {
    return (
      <InlineError error="You must be logged in to add billing details." />
    );
  }

  /**
   * Handle view for loading Pin.js script.
   */
  if (creditCards.pinPayments.isLoading) {
    return (
      <div className="flex-1 flex justify-center">
        <SpinningLoader size="lg" color="brand" />
      </div>
    );
  }

  /**
   * Handle view for Pin.js script load error.
   */
  if (creditCards.pinPayments.errorLoading) {
    return (
      <InlineError error="There was an issue trying to load our payment provider. Please try again later or constact us for support." />
    );
  }

  return (
    <>
      {/* Include useful autofill buttons in development mode for testing various card behaviors */}
      {TARGET_ENV === 'development' && <CardTestingTools form={form} />}

      {/* Card details */}
      <div>
        <Title level="h3">Card details</Title>
        <FormFieldsContainer vertical>
          {/* Cardholder name */}
          <TextField
            required
            name="nickname"
            label="Card nickname"
            description="Give your card a custom name to help you identify it in your Keyhook account."
            placeholder='E.g. "My Personal Card"'
            type="text"
            size="base"
            mode="formik"
            form={form}
          />
          {/* Cardholder name */}
          <TextField
            name="name"
            label="Cardholder name"
            placeholder="Jane Doe"
            type="text"
            size="base"
            mode="formik"
            form={form}
            required
          />

          {/* Card number */}
          <TextField
            name="number"
            label="Card number"
            placeholder="4242 4242 4242 4242"
            size="base"
            mode="formik"
            form={form}
            required
            maxLength={16}
          />

          {/* Card expiry */}
          <FormFieldsContainer horizontal>
            {/* Card expiry month */}
            <TextField
              name="expiry_month"
              label="Expiry month"
              placeholder="MM"
              size="base"
              mode="formik"
              form={form}
              required
              maxLength={2}
            />

            {/* Card expiry year */}
            <TextField
              name="expiry_year"
              label="Expiry year"
              placeholder="YYYY"
              size="base"
              mode="formik"
              form={form}
              required
              maxLength={4}
            />

            {/* CVC / Security code */}
            <TextField
              name="cvc"
              label="CVC"
              placeholder="123"
              size="base"
              mode="formik"
              form={form}
              required
              maxLength={4}
            />
          </FormFieldsContainer>
        </FormFieldsContainer>
      </div>

      <Divider />

      {/* Billing address details */}
      {/* TODO: Add Google address lookup to autofill */}
      <div>
        <Title level="h3">Billing address</Title>
        {/* Address lines */}
        <FormFieldsContainer vertical>
          <div>
            <FieldLabel title="Address" required />
            <FormFieldsContainer vertical>
              <TextField
                name="address_line1"
                type="text"
                size="base"
                mode="formik"
                form={form}
                required
              />
              <TextField
                name="address_line2"
                type="text"
                size="base"
                mode="formik"
                form={form}
              />
            </FormFieldsContainer>
          </div>

          {/* City */}
          <TextField
            name="address_city"
            label="City"
            type="text"
            size="base"
            mode="formik"
            form={form}
            required
          />

          {/* Region / State */}
          {/* TODO: Change to dropdown select based on country */}
          <TextField
            name="address_state"
            label="Region"
            type="text"
            size="base"
            mode="formik"
            form={form}
            required
          />

          {/* Postal code */}
          <TextField
            name="address_postcode"
            label="Postal code"
            size="base"
            mode="formik"
            form={form}
            required
            maxLength={5}
          />

          {/* Country */}
          {/* TODO: Change to dropdown select */}
          <TextField
            name="address_country"
            label="Country"
            type="text"
            size="base"
            mode="formik"
            form={form}
            required
          />
        </FormFieldsContainer>
      </div>
    </>
  );
};

export default CreditCardInput;
