import React from 'react';

import moment from 'moment';
import {AiOutlineFileSearch} from 'react-icons/ai';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const SearchResultsCard = ({report}: {report: TenantCheckReport}) => {
  if (report.searchResultsFetchedAt) {
    return (
      <Card title="Web Search" icon={AiOutlineFileSearch}>
        {report.searchResults.google.results.length +
          report.searchResults.bing.results.length >
        0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Image</TableHeadItem>
              <TableHeadItem>Title</TableHeadItem>
              <TableHeadItem>Source</TableHeadItem>
            </TableHead>
            {/* Table rows */}
            {report.searchResults.google.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <img
                    src={result.image_url}
                    className="w-20 h-10 object-fit rounded-sm"
                  />
                </TableRowItem>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.title}
                  </Hyperlink>
                  <p className="text-xs">{result.snippet}</p>
                </TableRowItem>
                <TableRowItem>
                  <p>Google</p>
                </TableRowItem>
              </TableRow>
            ))}

            {report.searchResults.bing.results.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <img
                    src={result.thumbnailUrl}
                    className="w-20 h-10 object-fit rounded-sm"
                  />
                </TableRowItem>
                <TableRowItem>
                  <Hyperlink href={result.url} external openInNewTab>
                    {result.name}
                  </Hyperlink>
                  <p className="text-xs">{result.snippet}</p>
                </TableRowItem>
                <TableRowItem>
                  <p>Bing</p>
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no web search results found for this applicant.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.searchResultsFetchedAt).format(DATE_TIME_FORMAT)}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Web Search" icon={AiOutlineFileSearch}>
        <Paragraph>
          Web search results are currently being collected and will be updated
          on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default SearchResultsCard;
