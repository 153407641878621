import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import keyhookIcon from 'assets/img/logos/keyhook/icon.png';
import tenancyServicesIcon from 'assets/img/logos/tenancy-services/icon.png';
import LeaseConditionsOnboardFlow from 'components/tenancy/LeaseConditionsOnboardFlow';
import {GridSelect, GridSelectOption} from 'components_sb/input';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {Clause} from 'models/properties/ClauseLibrary';
import {CustomClauses, LeaseDocumentType} from 'models/properties/Tenancy';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import StepContainer from '../common/StepContainer';

type FormValues = {
  customClauses: CustomClauses;
  clauses: Clause[];
  leaseDocumentType: string;
};

const LEASE_OPTIONS: GridSelectOption[] = [
  {
    id: LeaseDocumentType.KeyhookResidential,
    label: 'Residential',
    description: 'Provided by Keyhook',
    icon: keyhookIcon,
  },
  {
    id: LeaseDocumentType.TenancyServicesResidential,
    label: 'Residential',
    description: 'Provided by Tenancy Services',
    icon: tenancyServicesIcon,
  },
  {
    id: LeaseDocumentType.TenancyServicesBoardingHouse,
    label: 'Boarding House',
    description: 'Provided by Tenancy Services',
    icon: tenancyServicesIcon,
  },
];

const LeaseConditionsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartBuildLeaseStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      const {clauses, leaseDocumentType} = formValues;

      /**
       * Set changes on the tenancy.
       */
      tenancy.assignAttributes({
        ...formValues,

        /**
         * If the user has selected the boarding house lease template, we ignore
         * the clauses set in the form.
         */
        clauses:
          leaseDocumentType === LeaseDocumentType.TenancyServicesBoardingHouse
            ? []
            : clauses,
      });

      /**
       * Save the changes to the tenancy.
       */
      if (!(await saveResource(tenancy))) {
        setSubmitting(false);
        return;
      }
      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = 'new_lease_conditions';

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteBuildLeaseStep,
        {
          propertyId: property.id,
        },
      );

      localStorage.removeItem('new-tenancy-clauses');

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [property, queryClient, tenancy],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        customClauses: tenancy.customClauses,
        clauses: tenancy.clauses,
        leaseDocumentType:
          tenancy.leaseDocumentType ?? LeaseDocumentType.KeyhookResidential,
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        leaseDocumentType: Yup.string()
          .required()
          .oneOf(Object.values(LeaseDocumentType))
          .label('Lease Type'),
        customClauses: Yup.object()
          .shape({
            clauses: Yup.array().of(Yup.string().min(0).label('Condition')),
          })
          .label('Conditions'),
      }),
    }),
    [handleSubmit, tenancy],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  /**
   * The clause library should only be enabled if not using
   * the boarding house lease template.
   */
  const clauseLibraryEnabled = useMemo(
    () =>
      form.values.leaseDocumentType !==
      LeaseDocumentType.TenancyServicesBoardingHouse,
    [form],
  );

  return (
    <StepContainer
      fullWidth
      title="Build your lease"
      subtitle="Use Keyhook's lease builder to add the clauses relevant to your property">
      <GridSelect
        mode="formik"
        form={form}
        name="leaseDocumentType"
        labelProps={{
          title: 'Choose the template for your lease',
          description:
            'For periodic and fixed term tenancies, we recommend using the template provided by Keyhook, or you can use one of the lease templates provided by Tenancy Services.',
          size: 'xl',
        }}
        minColumnWidth={240}
        preventEmptyCells
        multiple={false}
        options={LEASE_OPTIONS}
      />

      <LeaseConditionsOnboardFlow
        clauseLibraryEnabled={clauseLibraryEnabled}
        property={property}
        form={form}
      />
    </StepContainer>
  );
};

export default LeaseConditionsStep;
