import {useCallback, useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {HiOutlineChat} from 'react-icons/hi';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {InputField, SubmitButton} from 'components/forms_fields';
import {Alert} from 'components_sb/feedback';
import {TextAreaField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import Listing from 'models/listings/Listing';
import {AccountRole} from 'models/users/User';

type EnquiryFormValues = {
  name: string;
  email: string;
  message: string;
};

const ListingEnquireCard = ({listing}: {listing: Listing}) => {
  const [messageSent, setMessageSent] = useState(false);

  const {isLoggedIn, currentUser} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  const handleEnquiryFormSubmit = useCallback(
    async (
      values: EnquiryFormValues,
      actions: FormikHelpers<EnquiryFormValues>,
    ) => {
      actions.setSubmitting(true);
      setMessageSent(false);

      const message = new EnquiryMessage({
        name: values.name,
        email: values.email,
        message: values.message,
      });

      if (isLoggedIn) {
        message.userId = currentUser.id;
      }

      const thread = new EnquiryThread({
        name: values.name,
        email: values.email,
        listingId: listing.id,
      });
      thread.enquiryMessages = [message];
      const result = await thread.save({with: 'enquiryMessages'});

      if (result) {
        setMessageSent(result);
        actions.resetForm();
      } else {
        if (thread.errors.email) {
          actions.setFieldError(
            'email',
            'You have already enquired about this listing, please reply via your enquiry inbox or email.',
          );
        }
      }

      actions.setSubmitting(false);
    },
    [currentUser, listing, isLoggedIn],
  );

  return (
    <Card id="enquiry-card" icon={HiOutlineChat} title="Enquire">
      {listing.isHistorical ? (
        <Paragraph>Enquriries cannot be made for inactive listings.</Paragraph>
      ) : (
        <>
          {activeAccountRole === AccountRole.Landlord ? (
            <Paragraph>
              You cannot enquire about a property with a Landlord account.
            </Paragraph>
          ) : (
            <div className="!mt-0">
              {messageSent && (
                <Alert
                  type="success"
                  title="Your message has been sent!"
                  description={
                    isLoggedIn
                      ? 'Replies will be sent to your enquiry inbox.'
                      : 'Any replies from the landlord will be emailed to you.'
                  }
                />
              )}

              <Formik
                initialValues={{
                  name: currentUser?.name || '',
                  email: currentUser?.email || '',
                  message: '',
                }}
                onSubmit={handleEnquiryFormSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .trim()
                    .required('Please enter your name')
                    .min(2),
                  email: Yup.string()
                    .trim()
                    .email('Please enter a valid email')
                    .required('Please enter your email'),
                  message: Yup.string()
                    .trim()
                    .required('Please enter a message')
                    .min(10),
                })}>
                {(formik) => (
                  <Form className="flex flex-col gap-y-6">
                    <div>
                      <InputField
                        name="name"
                        formik={formik}
                        labelProps={{
                          title: 'Your name',
                        }}
                        placeholder="E.g. John Smith"
                        disabled={!!currentUser}
                      />
                    </div>

                    <div>
                      <InputField
                        name="email"
                        formik={formik}
                        labelProps={{
                          title: 'Email address',
                        }}
                        placeholder="E.g. johnsmith@gmail.com"
                        type="email"
                        disabled={!!currentUser}
                      />
                    </div>

                    <div>
                      <TextAreaField
                        name="message"
                        mode="formik"
                        form={formik as any}
                        label="Message"
                        placeholder="Your message in here..."
                      />
                    </div>

                    <div>
                      <SubmitButton
                        formik={formik}
                        text="Send"
                        submittingText="Sending..."
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default ListingEnquireCard;
