import {Router} from 'framework7/types';

import ChatDetailPage from 'pages/shared/chat/ChatDetailPage';
import ChatIndexPage from 'pages/shared/chat/ChatIndexPage';
import NewChatPage from 'pages/shared/chat/NewChatPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {LOGGED_IN} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/chat',
    id: 'ChatIndex',
    async: resolveRoute({
      conditions: [LOGGED_IN],
      component: ChatIndexPage,
    }),
    routes: [
      {
        path: 'new',
        id: 'NewChat',
        async: resolveRoute({
          conditions: [LOGGED_IN],
          component: NewChatPage,
        }),
      },
      {
        path: ':roomKey',
        id: 'ChatConversation',
        async: resolveRoute({
          conditions: [LOGGED_IN],
          component: ChatDetailPage,
        }),
      },
    ],
  },
];

export default routes;
