import {useCallback} from 'react';

/**
 * Provides scroll functions for the given element ID.
 */
const useScroll = () => {
  const getElement = useCallback(
    () => document.getElementById('page').firstElementChild,
    [],
  );

  const scrollToTop = useCallback(() => {
    const element = getElement();
    if (element) {
      /**
       * For the ScrollContainer components, the actual scrollable element is the first child.
       */
      element.scrollTo({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    }
  }, [getElement]);

  return {
    scrollToTop,
  };
};

export default useScroll;
