import React, {useEffect, useRef, useState} from 'react';

import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/outline';
import Confetti from 'react-confetti';
import {toast} from 'react-toastify';

import AssetInformationAction from 'components/property/landlord/profile_blocks/AssetInformationAction';
import ChattelsAction from 'components/property/landlord/profile_blocks/ChattelsAction';
import InspectionSettingsAction from 'components/property/landlord/profile_blocks/InspectionSettingsAction';
import InsurancePoliciesAction from 'components/property/landlord/profile_blocks/InsurancePoliciesAction';
import MaintenanceSettingsAction from 'components/property/landlord/profile_blocks/MaintenanceSettingsAction';
import PersonalProfileAction from 'components/property/landlord/profile_blocks/PersonalProfileAction';
import ProgressCard from 'components/property/landlord/profile_blocks/ProgressCard';
import PropertyDocumentsAction from 'components/property/landlord/profile_blocks/PropertyDocumentsAction';
import PropertyImageAction from 'components/property/landlord/profile_blocks/PropertyImageAction';
import PropertyInformationAction from 'components/property/landlord/profile_blocks/PropertyInformationAction';
import TenancyInformationAction from 'components/property/landlord/profile_blocks/TenancyInformationAction';
import {Title} from 'components_sb/typography';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {useAutoAnimate} from 'utilities/hooks';

const CompleteProfileSection = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy: Tenancy;
}) => {
  const steps = 10;
  // Start the progress at 30%
  const [percent, setPercent] = useState(
    30 + (70 / steps) * property.completedProfileSteps.length,
  );
  const [showConfetti, setShowConfetti] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newPercent =
      30 + (70 / steps) * property.completedProfileSteps.length;

    if (newPercent !== percent) {
      setPercent(newPercent);

      if (newPercent === 100) {
        setShowConfetti(true);
        toast.success('Your property profile has been fully completed!');
      }
    }
  }, [property.completedProfileSteps.length]);

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => setShowConfetti(false), 5000);
    }
  }, [showConfetti, setShowConfetti]);

  const [autoAnimate] = useAutoAnimate();

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      left: -256,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    // scrollRef.current.scrollLeft += 256;
    scrollRef.current.scrollBy({
      left: 256,
      behavior: 'smooth',
    });
  };

  if (percent === 100 && showConfetti) {
    return <Confetti />;
  } else if (percent === 100) {
    return null;
  } else {
    return (
      // TODO: Move this inside a Card component?
      <div className="flex flex-col gap-y-4 my-6">
        <div className="flex justify-between items-center">
          <Title level="h3" size="sm" noMargin>
            Finish setting up your property profile
          </Title>
          <div className="flex justify-end gap-2">
            <button
              className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 bg-white hover:bg-neutral hover:text-white"
              type="button"
              onClick={scrollLeft}>
              <ArrowLeftIcon className="w-4 h-4" />
            </button>

            <button
              className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 bg-white hover:bg-neutral hover:text-white"
              type="button"
              onClick={scrollRight}>
              <ArrowRightIcon className="w-4 h-4" />
            </button>
          </div>
        </div>

        <div
          className="flex overflow-x-auto pb-4 scrollbar-hide"
          ref={scrollRef}>
          <div className="flex flex-nowrap gap-4" ref={autoAnimate as any}>
            <ProgressCard percent={percent} />

            {!property.completedProfileSteps.includes(
              'general_information',
            ) && (
              <PropertyInformationAction
                property={property}
                tenancy={tenancy}
                storeKey="general_information"
              />
            )}

            {!property.completedProfileSteps.includes('personal_profile') && (
              <PersonalProfileAction
                property={property}
                tenancy={tenancy}
                storeKey="personal_profile"
              />
            )}

            {!property.completedProfileSteps.includes('main_image') && (
              <PropertyImageAction
                property={property}
                tenancy={tenancy}
                storeKey="main_image"
              />
            )}

            {!property.completedProfileSteps.includes('insurance_policies') && (
              <InsurancePoliciesAction
                property={property}
                tenancy={tenancy}
                storeKey="insurance_policies"
              />
            )}

            {!property.completedProfileSteps.includes(
              'maintenance_settings',
            ) && (
              <MaintenanceSettingsAction
                property={property}
                tenancy={tenancy}
                storeKey="maintenance_settings"
              />
            )}

            {!property.completedProfileSteps.includes(
              'inspection_settings',
            ) && (
              <InspectionSettingsAction
                property={property}
                tenancy={tenancy}
                storeKey="inspection_settings"
              />
            )}

            {!property.completedProfileSteps.includes('property_documents') && (
              <PropertyDocumentsAction
                property={property}
                tenancy={tenancy}
                storeKey="property_documents"
              />
            )}

            {!property.completedProfileSteps.includes(
              'tenancy_information',
            ) && (
              <TenancyInformationAction
                property={property}
                tenancy={tenancy}
                storeKey="tenancy_information"
              />
            )}

            {!property.completedProfileSteps.includes('property_chattels') && (
              <ChattelsAction
                property={property}
                tenancy={tenancy}
                storeKey="property_chattels"
              />
            )}

            {!property.completedProfileSteps.includes('asset_information') && (
              <AssetInformationAction
                property={property}
                tenancy={tenancy}
                storeKey="asset_information"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default CompleteProfileSection;
