import {Router} from 'framework7/types';

import TenantCheckReportDetailPage from 'pages/landlord/tenant_check_reports/TenantCheckReportDetailPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/tenant-check-reports',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
    routes: [
      {
        path: ':id',
        id: 'TenantCheckReportDetailPage',
        async: resolveRoute({component: TenantCheckReportDetailPage}),
      },
    ],
  },
];

export default routes;
