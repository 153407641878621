import {useCallback, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {FormikHelpers, useFormik} from 'formik';
import {toast} from 'react-toastify';
import {BooleanParam, useQueryParam} from 'use-query-params';
import * as Yup from 'yup';

import 'yup-phone';
import LandlordDashboardScreenshot from 'assets/img/iphone-mobile-landlord-dashboard.png';
import {RegistrationFormData} from 'auth/actions/register';
import {AuthErrorCode, errorIsAuthErrorCodes} from 'auth/errors';
import useAuth from 'auth/provider/useAuth';
import {SocialAuthUserData, SocialPlatform} from 'auth/social/platforms';
import useSocialAuthScripts from 'auth/social/useSocialAuthScripts';
import {Button} from 'components_sb/buttons';
import {Alert} from 'components_sb/feedback';
import {TextField} from 'components_sb/input';
import {AuthFormLayout} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {NATIVE_LAYOUT_IN_WEB} from 'globals/app-globals';
import useReferralCodeCookie from 'hooks/useReferralCodeCookie';
import {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {isFullName} from 'utilities/StringHelpers';

interface RegisterScreenProps {
  /**
   * The types of account a user can register for.
   */
  accountRole: AccountRole;
}

const RegisterPage = () => {
  /**
   * Load the external scripts required for social authentication.
   */
  useSocialAuthScripts();

  const router = useRouter();
  const route = useRoute();

  const accountRole = route.path.includes('landlord')
    ? AccountRole.Landlord
    : AccountRole.Renter;

  const [redirectedFromTama] = useQueryParam('tama', BooleanParam);

  const referralCode = useReferralCodeCookie();

  const isLandlord = useMemo(
    () => accountRole === AccountRole.Landlord,
    [accountRole],
  );

  const {register} = useAuth();

  const showGenericError = useCallback(() => {
    toast.error(
      `There was an issue registering your account. Please try again later or contact us for support.`,
    );
  }, []);

  const handleFormSubmit = useCallback(
    async (
      formData: RegistrationFormData,
      formikHelpers: FormikHelpers<RegistrationFormData>,
    ) => {
      try {
        /**
         * Attempt the registration request.
         */
        await register({
          method: 'email',
          registrationData: {
            inputUserData: formData,
            /**
             * Include the account type and referral code (if applicable)
             * alongside the form data.
             */
            accountType: accountRole,
            referralCode: referralCode.isValid ? referralCode.value : undefined,
            isTamaUser: redirectedFromTama,
          },
        });

        /**
         * Clear the referral code cookie.
         */
        referralCode.clear();

        /**
         * Clear history from the logged-out state and navigate to the dashboard.
         */
        router.navigate('/', {
          reloadAll: true,
          transition:
            Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB
              ? 'f7-cover-v'
              : undefined,
        });
      } catch (error) {
        /**
         * Check if the error is an array of AuthErrorCode values.
         */
        if (errorIsAuthErrorCodes(error)) {
          const authErrorCodes = error;
          if (authErrorCodes.includes(AuthErrorCode.EMAIL_ALREADY_TAKEN)) {
            /**
             * Handle email already taken.
             */
            formikHelpers.setFieldError(
              'email',
              'An account with this email address already exists. Please try logging in instead.',
            );
          } else {
            /**
             * Unknown error code.
             */
            showGenericError();
          }
        } else {
          /**
           * Error performing the registration request.
           */
          showGenericError();
        }

        /**
         * Reset the submitting state.
         */
        formikHelpers.setSubmitting(false);
      }
    },
    [
      router,
      accountRole,
      referralCode,
      register,
      showGenericError,
      redirectedFromTama,
    ],
  );

  const onSocialAuthSuccess = useCallback(
    async (platform: SocialPlatform, inputUserData: SocialAuthUserData) => {
      try {
        /**
         * Attempt the registration request.
         */
        await register({
          method: platform,
          registrationData: {
            inputUserData,
            /**
             * Include the account type and referral code (if applicable)
             * alongside the social auth data.
             */
            accountType: accountRole,
            referralCode: referralCode.isValid ? referralCode.value : undefined,
            isTamaUser: redirectedFromTama,
          },
        });

        /**
         * Clear the referral code cookie.
         */
        referralCode.clear();

        router.navigate('/', {
          reloadAll: true,
          transition:
            Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB
              ? 'f7-cover-v'
              : undefined,
        });
      } catch (error) {
        /**
         * Error performing the registration request.
         */
        showGenericError();
      }
    },
    [
      accountRole,
      register,
      showGenericError,
      referralCode,
      redirectedFromTama,
      router,
    ],
  );

  const form = useFormik({
    onSubmit: handleFormSubmit,
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      password: '',
    },
    validationSchema: Yup.object().shape(
      {
        name: Yup.string()
          .required('Please enter your full name')
          .min(1, 'Must be 1 character or more')
          .max(128, 'Must be 128 characters or less')
          .test(
            'two-names-plus',
            'Please enter both your first and last name',
            (value) => {
              if (!value) {
                return false;
              }
              return isFullName(value);
            },
          ),
        email: Yup.string()
          .email('Invalid email address')
          .required('Please enter your email address'),
        phoneNumber: Yup.string().when('phoneNumber', {
          is: (value: any) => !value,
          then: Yup.string().notRequired(),
          otherwise: Yup.string().phone(
            'NZ',
            false, // Not strict
            'Please enter a valid phone number',
          ),
        }),
        password: Yup.string()
          .required('Please enter a password for your account')
          .min(6, 'Your password must be at least 6 characters')
          .max(128, 'Your password must be 128 characters or less'),
      },
      [['phoneNumber', 'phoneNumber']],
    ),
    validateOnBlur: false,
    validateOnChange: false,
  });

  const title = useMemo(() => {
    if (window.location.origin.includes('tama.')) {
      return 'Register to use Tama';
    } else {
      return `Register as a ${isLandlord ? 'Landlord' : 'Tenant'}`;
    }
  }, [isLandlord]);

  const description = useMemo(() => {
    if (window.location.origin.includes('tama.')) {
      return "Help us confirm you're a real person so we can prevent misuse of Tama.";
    } else {
      if (isLandlord) {
        return `Self-manage easily in less than 5 minutes.`;
      } else {
        return `Set up an account to help create healthier tenancies.`;
      }
    }
  }, [isLandlord]);

  return (
    <Page title="Register" centred>
      {() => (
        <AuthFormLayout
          title={title}
          description={description}
          supportingContent={
            <Alert
              show={referralCode.isValid}
              title={
                referralCode.details.referrer?.name
                  ? `${referralCode.details.referrer.name} has invited you to Keyhook!`
                  : "You've been invited to Keyhook!"
              }
              description={[
                `This referral provides you with ${referralCode.details.duration} ${referralCode.details.period} of Keyhook for FREE!`,
                'Your referral code will be automatically applied when registering below.',
              ]}
              icon={
                referralCode.details.referrer?.avatar ? (
                  <img
                    className="w-10 h-10 rounded-full"
                    src={referralCode.details.referrer?.avatar}
                    alt={`${referralCode.details.referrer?.name} Profile Picture`}
                  />
                ) : undefined
              }
            />
          }
          socialAuthButtonConfig={{
            action: 'register',
            onSuccess: onSocialAuthSuccess,
            onError: showGenericError,
          }}
          appPreviewConfig={
            isLandlord
              ? {
                  screenshot: LandlordDashboardScreenshot,
                }
              : null
          }>
          <form className="flex flex-col items-center gap-y-8">
            <div className="w-full flex flex-col gap-y-8">
              <TextField
                name="name"
                label="Full name"
                type="text"
                size="base"
                mode="formik"
                form={form}
                placeholder="Enter your full name here..."
                required
              />
              <TextField
                name="email"
                label="Email"
                type="email"
                size="base"
                mode="formik"
                form={form}
                placeholder="Enter your email address here..."
                required
              />
              {isLandlord && (
                <TextField
                  name="phoneNumber"
                  label="Phone number"
                  type="text"
                  size="base"
                  mode="formik"
                  form={form}
                  placeholder="Enter your phone number here..."
                />
              )}
              <TextField
                name="password"
                label="Password"
                description="(6+ characters)"
                type="password"
                size="base"
                mode="formik"
                form={form}
                placeholder="Enter a password here..."
                required
              />
            </div>
            <Paragraph>
              <span>
                {`By clicking the button below, you acknowledge that you accept the `}
              </span>
              <Hyperlink
                openInNewTab
                href="https://www.keyhook.com/terms-of-use">
                Terms of Service
              </Hyperlink>
              <span>{` and `}</span>
              <Hyperlink
                openInNewTab
                href="https://www.keyhook.com/privacy-policy">
                Privacy Policy
              </Hyperlink>
              <span>.</span>
            </Paragraph>
            <Button
              label="Register"
              category="primary"
              size="base"
              mode="formik"
              form={form}
              loadingLabel="Registering..."
            />
          </form>
          <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
            <Hyperlink persistQueryParams href="/login">
              Already have an account?
            </Hyperlink>
            <Hyperlink
              persistQueryParams
              href={`/register/${isLandlord ? 'tenant' : 'landlord'}`}>
              {`I want to register as a ${isLandlord ? 'tenant' : 'landlord'}`}
            </Hyperlink>
          </span>
        </AuthFormLayout>
      )}
    </Page>
  );
};

export default RegisterPage;
