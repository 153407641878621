import clsx from 'clsx';
import {Formik} from 'formik';
import moment from 'moment';
import {BsHouseGear} from 'react-icons/bs';
import {
  HiOutlineChatAlt,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from 'react-icons/hi';
import {HiOutlineClipboardDocumentCheck} from 'react-icons/hi2';

import {InputField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import PseudoBooleanDisplayView from 'components/rental_application/PseudoBooleanDisplayView';
import {TextAreaField, TextField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {FieldLabel} from 'components_sb/typography';
import Tags, {TagColor} from 'components_sb/typography/Tags/Tags';
import {ListingRentalApplicationTag} from 'models/listings/ListingRentalApplication';
import RentalApplication from 'models/listings/RentalApplication';

const RentalApplicationDetailView = ({
  rentalApplication,
  tag = '',
}: {
  rentalApplication: RentalApplication;
  tag?: '' | 'shortlisted' | 'excluded';
}) => {
  return (
    <div>
      <Card className="mt-4">
        <div className="flex justify-start items-center gap-2">
          <div className="flex-1">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold">
                {rentalApplication.headTenantName}
              </h3>
              <Tags size="lg">
                {(() => {
                  if (tag === ListingRentalApplicationTag.Shortlisted) {
                    return [{text: 'Shortlisted', color: TagColor.Green}];
                  } else if (tag === ListingRentalApplicationTag.Excluded) {
                    return [{text: 'Archived', color: TagColor.Grey}];
                  } else {
                    return [];
                  }
                })()}
              </Tags>
            </div>
            {rentalApplication.headTenantDob && (
              <p className="text-secondary">
                DOB:{' '}
                {moment(rentalApplication.headTenantDob).format('DD MMM YYYY')}
              </p>
            )}
          </div>
        </div>
      </Card>

      <Formik
        initialValues={rentalApplication}
        onSubmit={() => {
          'noop';
        }}
        validateOnBlur={false}
        validateOnChange={false}>
        {(formik) => (
          <div>
            <Card title="Basic Information" icon={HiOutlineUserCircle}>
              <FormRow>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="headTenantEmail"
                    labelProps={{
                      title: 'Email address',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="headTenantPhoneNumber"
                    labelProps={{
                      title: 'Phone number',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="idType"
                    labelProps={{
                      title: 'Identification type',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="idNumber"
                    labelProps={{
                      title: 'Identification number',
                    }}
                    placeholder="E.g. LMZ123"
                    disabled
                  />
                </FormRowItem>
                {formik.values.idType === 'Drivers License' && (
                  <FormRowItem>
                    <InputField
                      formik={formik}
                      name="idExtraInfo"
                      labelProps={{
                        title: 'Version number',
                      }}
                      disabled
                    />
                  </FormRowItem>
                )}
              </FormRow>

              <h2 className="card-title mb-4 text-brand-850 flex justify-between">
                Employment Information
              </h2>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="occupation"
                    labelProps={{
                      title: 'Occupation',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="weeklyIncome"
                    labelProps={{
                      title: 'Weekly income before tax ($)',
                    }}
                    type="number"
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="employer"
                    labelProps={{
                      title: 'Employer',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="yearsEmployedAtCurrentPlace"
                    labelProps={{
                      title: 'Years employed there',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <div className="w-full lg:w-1/2">
                <PseudoBooleanDisplayView
                  label="Are you currently employed full time?"
                  value={formik.values.headTenantEmployedFullTime}
                />
              </div>

              <h2 className="card-title mt-2 mb-4 text-brand-850 flex justify-between">
                Current Living Situation
              </h2>
              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="currentAddress"
                    labelProps={{
                      title: 'What is your current address?',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="currentAddressStayLength"
                    labelProps={{
                      title: 'How long have you stayed there?',
                    }}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <TextAreaField
                mode="formik"
                form={formik as any}
                name="currentAddressReasonLeaving"
                label="What is your reason for leaving?"
                disabled
              />
            </Card>

            <Card title="About Us" icon={HiOutlineChatAlt}>
              <p className="whitespace-pre-line">
                {rentalApplication.aboutUs ?? 'N/A'}
              </p>
            </Card>

            <Card title="Tenancy Information" icon={BsHouseGear}>
              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="preferredTenancyLength"
                    labelProps={{
                      title: 'Preferred tenancy length',
                    }}
                    value={
                      rentalApplication.preferredTenancyLength
                        ? `${rentalApplication.preferredTenancyLength} Months`
                        : ''
                    }
                    disabled
                  />
                </FormRowItem>

                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="preferredTenancyStartDate"
                    labelProps={{
                      title: 'Preferred tenancy start date',
                    }}
                    disabled
                    value={
                      rentalApplication.preferredTenancyStartDate
                        ? moment(
                            rentalApplication.preferredTenancyStartDate,
                          ).format('DD MMMM YYYY')
                        : ''
                    }
                  />
                </FormRowItem>
              </FormRow>

              <TextAreaField
                mode="formik"
                form={formik as any}
                name="cantCompleteTenancyReasons"
                label="Are there any reasons you might not be able to complete the length of the tenancy?"
                disabled
              />

              <InputField
                formik={formik}
                name="vehiclesCount"
                labelProps={{
                  title: 'How many vehicles will be parked at the property?',
                }}
                disabled
              />

              <div className="mt-2"></div>

              <PseudoBooleanDisplayView
                label="Does any tenant have pets?"
                value={formik.values.hasPets}
              />

              {Object.keys(rentalApplication.petTypes).length > 0 && (
                <div className="ml-2">
                  <strong className="text-md">Pets</strong>
                  <ul className="list-disc ml-4">
                    {Object.entries(formik.values.petTypes).map(
                      ([key, val], index) => (
                        <li className="text-md" key={index}>
                          {key} x {val}
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              )}

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.anySmokers}
                  label="Are any of the proposed tenants smokers?"
                />
              </div>

              <div>
                <PseudoBooleanDisplayView
                  value={formik.values.canPayBondAndAdvance}
                  label="Are you able to pay any rent in advance and bond immediately upon being accepted?"
                />
              </div>
            </Card>

            <Card title="References" icon={HiOutlineClipboardDocumentCheck}>
              <strong>Employer Reference</strong>

              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="employerReferenceName"
                    label="Name"
                    mode="formik"
                    form={formik}
                    disabled
                  />
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="employerReferenceCompanyName"
                    label="Company Name"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="employerReferenceRelationship"
                    label="Relationship"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>
              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="employerReferenceContactNumber"
                    label="Contact Number"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="employerReferenceEmail"
                    label="Email address"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>

              <strong>Landlord Reference</strong>

              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="landlordReferenceName"
                    label="Name"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="landlordReferenceRelationship"
                    label="Relationship"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>
              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="landlordReferenceContactNumber"
                    label="Contact Number"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="landlordReferenceEmail"
                    label="Email address"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>
              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="landlordReferenceTenancyAddress"
                    label="Tenancy Address"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="landlordReferenceTenancyDate"
                    label="Tenancy Date"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>

              <strong>Other Reference</strong>

              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="otherReferenceName"
                    label="Name"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="otherReferenceRelationship"
                    label="Relationship"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>
              <FormRow responsive>
                <FormRowItem>
                  <TextField
                    name="otherReferenceContactNumber"
                    label="Contact Number"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
                <FormRowItem>
                  <TextField
                    name="otherReferenceEmail"
                    label="Email address"
                    disabled
                    mode="formik"
                    form={formik}
                  />
                </FormRowItem>
              </FormRow>
            </Card>

            <Card title="Other Tenants" icon={HiOutlineUserGroup}>
              <FormRow responsive>
                <FormRowItem>
                  <InputField
                    formik={formik}
                    name="additionalTotalOccupants"
                    labelProps={{
                      title: 'Additional number of occupants',
                    }}
                    disabled
                  />
                </FormRowItem>
                <FormRowItem>
                  <FieldLabel title="Additional tenants who will sign the lease" />
                  <input
                    type="text"
                    className={clsx(
                      'w-full max-w-full box-border',
                      'transition-all duration-300',
                      'bg-brand-50 hover:bg-brand-75 ',
                      'placeholder-brand-800 placeholder-opacity-30',
                      'outline-none border-none ring-1 ring-brand-75',
                      'focus:ring-2 focus:ring-brand-500',
                      'text-base px-4 h-12 rounded-lg',
                    )}
                    disabled
                    value={rentalApplication.rentalApplicationApplicants.length}
                  />
                </FormRowItem>
              </FormRow>

              <div>
                {formik.values.rentalApplicationApplicants.map(
                  (applicant, index) => (
                    <div className="mt-4 bg-gray-100 p-4" key={index}>
                      <strong>Additional Tenant {index + 1}</strong>
                      <FormRow responsive>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].name`}
                            labelProps={{
                              title: 'Full name',
                            }}
                            disabled
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].email`}
                            labelProps={{
                              title: 'Email address',
                            }}
                            type="email"
                            disabled
                          />
                        </FormRowItem>
                      </FormRow>
                      <FormRow responsive>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].dateOfBirth`}
                            labelProps={{
                              title: 'Date of birth',
                            }}
                            value={
                              rentalApplication.rentalApplicationApplicants[
                                index
                              ].dateOfBirth
                                ? moment(
                                    rentalApplication
                                      .rentalApplicationApplicants[index]
                                      .dateOfBirth,
                                  ).format('DD MMMM YYYY')
                                : null
                            }
                            disabled
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].weeklyIncome`}
                            labelProps={{
                              title: 'Weekly income before tax ($)',
                            }}
                            disabled
                          />
                        </FormRowItem>
                      </FormRow>
                      <FormRow responsive>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].idType`}
                            labelProps={{
                              title: 'Identification type',
                            }}
                            disabled
                          />
                        </FormRowItem>
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name={`rentalApplicationApplicants[${index}].idNumber`}
                            labelProps={{
                              title: 'Identification number',
                            }}
                            placeholder="E.g. LMZ123"
                            disabled
                          />
                        </FormRowItem>
                        {applicant.idType === 'Drivers License' && (
                          <FormRowItem>
                            <InputField
                              formik={formik}
                              name={`rentalApplicationApplicants[${index}].idExtraInfo`}
                              labelProps={{
                                title: 'Version number',
                              }}
                              placeholder="E.g. 653"
                              disabled
                            />
                          </FormRowItem>
                        )}
                      </FormRow>
                    </div>
                  ),
                )}
              </div>

              <div className="mt-2 w-full lg:w-1/2">
                <PseudoBooleanDisplayView
                  value={formik.values.applicantsCanProvideReferences}
                  label="Can all of the tenants above provide up to 3 references?"
                />
              </div>
            </Card>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default RentalApplicationDetailView;
