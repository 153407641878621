import {FunctionComponent, useCallback, useState} from 'react';

import {HiCheckCircle, HiOutlinePlus} from 'react-icons/hi';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import AddCreditCardModal from 'components/payment/AddCreditCardModal';
import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph, Title} from 'components_sb/typography';
import usePropertyBillingDetails from 'hooks/usePropertyBillingDetails';
import BillingMethod from 'models/billing/BillingMethod';
import Property from 'models/properties/Property';
import {LANDLORD_PROPERTIES_BILLING_QUERY_KEY} from 'pages/landlord/billing/PropertiesBillingSection/PropertiesBillingSection';
import {useCreditCards} from 'providers/CreditCardsProvider';
import PropertyDetailQuery from 'queries/landlord/PropertyDetailQuery';
import {saveResource} from 'utilities/SpraypaintHelpers';

const {useModal} = Modal.Imperative;

interface BillingMethodSectionProps {
  hideTitle?: boolean;
  property?: Property;
}

const BillingMethodSection: FunctionComponent<BillingMethodSectionProps> = ({
  hideTitle = false,
  property,
}) => {
  const [isSettingOnProperty, setIsSettingOnProperty] =
    useState<boolean>(false);

  const creditCards = useCreditCards();

  const propertyBillingDetails = usePropertyBillingDetails(property);

  /**
   * Handle adding a new billing method.
   */
  const openModal = useModal();

  const queryClient = useQueryClient();

  const setCreditCardOnProperty = useCallback(
    async (creditCard: BillingMethod) => {
      if (!property) {
        throw new Error(
          'Cannot set credit card on property since no property was provided via props.',
        );
      }

      /**
       * Indicate that the newly added card is now being set on the property.
       */
      setIsSettingOnProperty(true);

      /**
       * Set the credit card on the property.
       */
      property.assignAttributes({
        billingMethodId: creditCard.id,
        BillingMethod: creditCard,
      });
      const success = await saveResource(property, {showErrorToast: false});

      if (success) {
        /**
         * Refetch the properties in the billing details page.
         */
        queryClient.invalidateQueries(LANDLORD_PROPERTIES_BILLING_QUERY_KEY);
        /**
         * Update the already-fetched data for the property.
         */
        queryClient.setQueryData(
          PropertyDetailQuery.queryKey(property.id),
          () => property.dup(),
        );
        toast.success('The card for your property has been updated!');
      } else {
        property.rollback();
        toast.error(
          'Sorry, there was an issue updating the card for the property.',
        );
      }
      /**
       * Indicate that the attempt to set the card on the property is now complete.
       */
      setIsSettingOnProperty(false);
    },
    [property, queryClient],
  );

  /**
   * Handle adding a new credit/debit card and setting it on the property if a
   * property was provided.
   */
  const onAddCreditCard = useCallback(async () => {
    /**
     * Open the modal to add a new credit/debit card and await the result.
     */
    const creditCard = await openModal(AddCreditCardModal);

    /**
     * Set the newly added credit/debit card on the property if a card was
     * successfully added.
     */
    if (!!creditCard && !!property) {
      await setCreditCardOnProperty(creditCard);
    }
  }, [openModal, property, setCreditCardOnProperty]);

  return (
    <div className="w-full">
      {!hideTitle && (
        <Title size="sm" level="h2">
          <span className="flex flex-row gap-x-3">Billing method</span>
        </Title>
      )}

      {isSettingOnProperty ? (
        <div className="flex flex-row justify-start pb-4">
          <SpinningLoader size="base" color="brand" />
        </div>
      ) : (
        <>
          {creditCards.exist ? (
            <div className="flex flex-col">
              {!!property && propertyBillingDetails.hasExplicitlySetCard ? (
                <>
                  <Paragraph>
                    <HiCheckCircle className="inline w-5 h-5 mr-1 text-green-600" />
                    You have set a credit/debit card for this property (
                    <strong>
                      {propertyBillingDetails.linkedCard.nickname}
                    </strong>
                    ) - we will use this card for payment.
                  </Paragraph>
                  <Paragraph>
                    You can manage the card set on your property from your{' '}
                    <Hyperlink href="/account/billing">
                      account settings.
                    </Hyperlink>
                  </Paragraph>
                </>
              ) : (
                <>
                  <Paragraph>
                    <HiCheckCircle className="inline w-5 h-5 mr-1 text-green-600" />
                    You have
                    {creditCards.all.length === 1
                      ? ' a credit/debit card '
                      : ' several credit/debit cards '}
                    saved with Keyhook.
                  </Paragraph>
                  <Paragraph>
                    Your default card is currently{' '}
                    <strong>{creditCards.default.nickname}</strong> - we will
                    use this card for payment.
                  </Paragraph>
                  <Paragraph secondary>
                    {`You can manage your default card or set a specific card for
                    a property from your `}
                    <Hyperlink href="/account/billing">
                      account settings
                    </Hyperlink>
                  </Paragraph>
                </>
              )}
            </div>
          ) : (
            <div className="flex flex-col gap-y-2 mb-4">
              <Paragraph>
                Please add a credit or debit card to continue.
              </Paragraph>
              <div className="max-w-auto md:max-w-xs">
                <Button
                  label="Add a new card"
                  icon={HiOutlinePlus}
                  category="primary"
                  size="base"
                  fillWidth
                  mode="manual"
                  onClick={onAddCreditCard}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BillingMethodSection;
