import {Alert} from 'components_sb/feedback';
import Tenancy from 'models/properties/Tenancy';

const PreTenancyInspectionAlert = ({tenancy}: {tenancy?: Tenancy}) => {
  if (tenancy && tenancy.inspections.length > 0) {
    const hasPendingPreTenancyInspection =
      tenancy.inspections.filter(
        (i) =>
          i.inspectionPeriodType === 'pre_tenancy' && i.status !== 'complete',
      ).length > 0;

    if (hasPendingPreTenancyInspection) {
      return (
        <Alert
          type="warning"
          title="You have a pre tenancy inspection that has yet to be completed"
          description="We recommend you conduct this inspection as soon as possible."
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default PreTenancyInspectionAlert;
