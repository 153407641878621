import {useQuery} from 'react-query';

import EnquiryThreadsList from 'components/enquiries/EnquiryThreadsList/EnquiryThreadsList';
import {ListingEnquiryTitleMode} from 'components/enquiries/EnquiryThreadsList/types';
import EnquiryThread from 'models/listings/EnquiryThread';
import {Page} from 'router/components';

const EnquiryThreadIndexPage = () => {
  const enquiryThreadQuery = useQuery('renter-listing-enquiries', async () => {
    const {data: enquiryThreads} = await EnquiryThread.includes([
      {enquiry_messages: 'user'},
      {listing: 'listing_photos'},
    ])
      .select({
        listings: ['street_address', 'suburb', 'city'],
      })
      .order({updated_at: 'desc', 'listing_photos.order_index': 'asc'})
      .all();

    return enquiryThreads;
  });

  return (
    <Page title="Your Enquiries">
      {() => (
        <EnquiryThreadsList
          query={enquiryThreadQuery}
          titleMode={ListingEnquiryTitleMode.Listing}
        />
      )}
    </Page>
  );
};

export default EnquiryThreadIndexPage;
