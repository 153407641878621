import {useEffect} from 'react';

import {App, URLOpenListenerEvent} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';

import useAuth from 'auth/provider/useAuth';
import useRouter from 'router/hooks/useRouter';

const AppLinkProvider = ({children}: {children: React.ReactNode}) => {
  const router = useRouter();

  const {isLoggedIn} = useAuth();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        // Example url: https://app.keyhook.com/properties/1234
        // slug = /properties/1234
        const slug = event.url.split('app.keyhook.com').pop();
        // Only navigate if the user is logged in
        if (slug && isLoggedIn) {
          router.navigate(slug);
        }
      });
    }

    return () => {
      if (Capacitor.isNativePlatform()) {
        App.removeAllListeners();
      }
    };
  }, [isLoggedIn]);

  return <>{children}</>;
};

export default AppLinkProvider;
