import {useEffect} from 'react';

import useAuth from 'auth/provider/useAuth';
import Page from 'router/components/Page';
import useRouter from 'router/hooks/useRouter';

const LogoutPage = () => {
  const router = useRouter();
  const {isLoggedIn, logout} = useAuth();

  useEffect(() => {
    if (!isLoggedIn) {
      /**
       * If the logout was invoked from the dashboard page, we need to clear
       * the previous history so that the route is forced to re-resolve to
       * show the welcome page component rather than the dashboard page component
       * that it had previously resolved to. We don't want to clear previous history
       * for other pages, since this causes an issue where the previous page attempts
       * to also be re-resolved, resulting in a navigation to the login page by the
       * router resolver function.
       */
      if (router.previousRoute.path === '/') {
        router.clearPreviousHistory();
      }
      setTimeout(() => router.goBack('/'), 1000);
    }
  }, [isLoggedIn, router]);

  return <Page disableNavbar centred onPageInit={logout} loading />;
};

export default LogoutPage;
