import {useMemo} from 'react';

import {Capacitor} from '@capacitor/core';

import CompletedInspectionList from 'components/inspection/CompletedInspectionList';
import InspectionInProgressCard from 'components/inspection/InspectionInProgressCard';
import LandlordScheduleInspectionCard from 'components/inspection/LandlordScheduleInspectionCard';
import PendingUploadModal from 'components/inspection/PendingUploadModal';
import RenterAwaitingInspectionTypeCard from 'components/inspection/RenterAwaitingInspectionTypeCard';
import RoomList from 'components/inspection/RoomList';
import Modal from 'components_sb/layout/Modal/Modal';
import useInspectionQueries from 'hooks/useInspectionQueries';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {Action} from 'types/actions';

const {useModal} = Modal.Imperative;

const InspectionDetailPage = () => {
  const openModal = useModal();

  const {
    params: {inspectionId},
  } = useRoute();

  const {activeAccountRole} = useLocalUserSettings();

  const inspectionQueries = useInspectionQueries(inspectionId);

  const {inspection} = inspectionQueries;

  const landlordViewForStatus = () => {
    if (!inspectionQueries.isSuccess) {
      return null;
    }

    if (inspection.status === 'scheduled') {
      return <LandlordScheduleInspectionCard inspection={inspection} />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'landlord'
    ) {
      return <RoomList />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'tenant'
    ) {
      return <InspectionInProgressCard />;
    } else if (inspection.status === 'awaiting_sign_offs') {
      return <RoomList />;
    } else if (inspection.status === 'complete') {
      return <CompletedInspectionList />;
    }
  };

  const renterViewForStatus = () => {
    if (!inspectionQueries.isSuccess) {
      return null;
    }

    if (inspection.status === 'scheduled') {
      return <RenterAwaitingInspectionTypeCard />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'tenant'
    ) {
      return <RoomList />;
    } else if (
      inspection.status === 'awaiting_inspection' &&
      inspection.doneBy === 'landlord'
    ) {
      return <InspectionInProgressCard />;
    } else if (inspection.status === 'awaiting_sign_offs') {
      return <RoomList />;
    } else if (inspection.status === 'complete') {
      return <CompletedInspectionList />;
    }
  };

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(() => {
    return !inspectionQueries.isSuccess
      ? null
      : [
          ...(activeAccountRole === AccountRole.Landlord &&
          inspection.status !== 'awaiting_sign_offs' &&
          inspection.status !== 'complete'
            ? [
                {
                  label: 'Edit',
                  linkTo: `edit`,
                },
              ]
            : []),
          ...(inspection.status === 'awaiting_inspection' &&
          Capacitor.isNativePlatform()
            ? [
                {
                  label: 'Pending Uploads',
                  onClick: () => openModal(PendingUploadModal),
                },
              ]
            : []),
        ];
  }, [inspectionQueries.isSuccess, activeAccountRole, inspection, openModal]);

  return (
    <Page
      title="Inspection"
      actions={pageActions}
      loading={inspectionQueries.isLoading}
      error={inspectionQueries.isError}>
      {() =>
        activeAccountRole === AccountRole.Landlord
          ? landlordViewForStatus()
          : renterViewForStatus()
      }
    </Page>
  );
};

export default InspectionDetailPage;
