import React from 'react';

import {type FormikHelpers} from 'formik';
import {GrUserAdmin} from 'react-icons/gr';
import {HiOutlineUser} from 'react-icons/hi';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import LandlordProfileForm from 'components/forms/LandlordProfileForm';

import {Card} from 'components_sb/layout';
import LandlordProfile from 'models/users/LandlordProfile';
import User from 'models/users/User';
import {Page} from 'router/components';
import {errorViewForError} from 'utilities/ErrorHelpers';

type LandlordFormValues = {
  name: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
};

const SettingsLandlordProfilePage = () => {
  const {currentUser} = useAuth();

  const {isLoading, error, data} = useQuery(
    'user-landlord-profile-page',
    async () => {
      const user = await User.includes(['landlord_profile']).find(
        currentUser.id,
      );

      if (!user.data.landlordProfile) {
        user.data.landlordProfile = new LandlordProfile({
          userId: currentUser.id,
        });
      }

      return user.data;
    },
  );

  const saveLandlordProfile = async (
    formData: LandlordFormValues,
    actions: FormikHelpers<LandlordFormValues>,
  ) => {
    const profile = data.landlordProfile;
    profile.assignAttributes(formData);
    const result = await profile.save();

    if (result) {
      toast.success('Your profile has been successfully updated!');
    } else {
      for (const key of Object.keys(profile.errors)) {
        const message = profile.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Page title="Landlord Profile" loading={isLoading} error={error}>
      {() => (
        <Card
          icon={HiOutlineUser}
          title="Your Landlord Profile"
          className="mt-2">
          <LandlordProfileForm
            model={data.landlordProfile}
            submitHandler={saveLandlordProfile}
          />
        </Card>
      )}
    </Page>
  );
};

export default SettingsLandlordProfilePage;
