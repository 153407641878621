import {FunctionComponent} from 'react';

import {IconType} from '@react-icons/all-files';
import {cva} from 'class-variance-authority';

import {removeUnderscores, titleize} from 'utilities/StringHelpers';

/**
 * Colour options for a tag.
 */
export enum TagColor {
  Red = 'red',
  Green = 'green',
  Blue = 'blue',
  Yellow = 'yellow',
  Grey = 'grey',
}

/**
 * Provide only a string representing the tag content, default styling will br used.
 */
type BasicTagDefinition = string;

/**
 * Provide specific configuration for the content and styling of the tag.
 */
interface DetailedTagDefinition {
  text: string;
  color?: TagColor;
  icon?: IconType;
}

export type TagDefinition = BasicTagDefinition | DetailedTagDefinition;

/**
 * Type guard for determining if a TagDefinition is a DetailedTagDefinition.
 */
const tagIsBasic = (tag: TagDefinition): tag is BasicTagDefinition =>
  typeof tag === 'string';

/**
 * Type guard for determining if a TagDefinition is a DetailedTagDefinition.
 */
const tagIsDetailed = (tag: TagDefinition): tag is DetailedTagDefinition =>
  typeof tag === 'object' && 'text' in tag;

/**
 * Props for the Tags component.
 */
interface TagsProps {
  children: TagDefinition[];
  size?: 'sm' | 'base' | 'lg';
  emphasis?: 'primary' | 'secondary';
}

const generateTagClasses = cva(
  ['rounded-full', 'flex flex-row items-center', 'font-semibold', 'text-white'],
  {
    variants: {
      size: {
        sm: 'text-xxs px-2 py-0.5 ',
        base: 'text-xs px-3 py-1',
        lg: 'text-base px-4 py-2',
      },
      emphasis: {
        primary: 'text-white',
        secondary: null,
      },
      color: {
        [TagColor.Red]: null,
        [TagColor.Green]: null,
        [TagColor.Blue]: null,
        [TagColor.Yellow]: null,
        [TagColor.Grey]: null,
      },
    },
    compoundVariants: [
      {
        emphasis: 'primary',
        color: [TagColor.Red],
        className: 'bg-red-500',
      },
      {
        emphasis: 'secondary',
        color: [TagColor.Red],
        className: 'bg-red-200 text-red-700',
      },
      {
        emphasis: 'primary',
        color: [TagColor.Green],
        className: 'bg-green-600',
      },
      {
        emphasis: 'secondary',
        color: [TagColor.Green],
        className: 'bg-green-200 text-green-700',
      },
      {
        emphasis: 'primary',
        color: [TagColor.Blue],
        className: 'bg-brand-500',
      },
      {
        emphasis: 'secondary',
        color: [TagColor.Blue],
        className: 'bg-brand-100 text-brand-600',
      },
      {
        emphasis: 'primary',
        color: [TagColor.Yellow],
        className: 'bg-yellow-500',
      },
      {
        emphasis: 'secondary',
        color: [TagColor.Yellow],
        className: 'bg-yellow-200 text-yellow-700',
      },
      {
        emphasis: 'primary',
        color: [TagColor.Grey],
        className: 'bg-gray-400',
      },
      {
        emphasis: 'secondary',
        color: [TagColor.Grey],
        className: 'bg-gray-100 text-gray-600',
      },
    ],
  },
);

/**
 * A series of tags.
 */
const Tags: FunctionComponent<TagsProps> = ({
  children: tags,
  size = 'base',
  emphasis = 'primary',
}) => {
  return (
    !!tags &&
    tags.length > 0 && (
      <div className="flex flex-row flex-wrap gap-2">
        {tags.map((tag) => {
          let text = '';
          let color = TagColor.Blue;
          let Icon = null;

          /**
           * Handle basic tag definition.
           */
          if (tagIsBasic(tag)) {
            text = tag;
          }

          /**
           * Handle detailed tag definition.
           */
          if (tagIsDetailed(tag)) {
            text = tag.text;
            if ('color' in tag) {
              color = tag.color;
            }
            if ('icon' in tag) {
              Icon = tag.icon;
            }
          }

          /**
           * Do not render if the text is an empty string.
           */
          return !text ? null : (
            <div
              key={text}
              className={generateTagClasses({
                size,
                color,
                emphasis,
              })}>
              {Icon && <Icon className={'mr-0.5 h-full w-auto'} />}
              {text}
            </div>
          );
        })}
      </div>
    )
  );
};

export default Tags;
