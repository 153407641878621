import {useCallback} from 'react';

import {MiddlewareStack} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';

interface ResetHeadersFunction {
  (): void;
}

type SpraypaintHeaders = Record<string, string>;

interface SetHeadersFunction {
  (headers: SpraypaintHeaders): void;
}

interface SpraypaintMiddlewareHookReturn {
  // hasHeadersSet: boolean;
  setHeaders: SetHeadersFunction;
  resetHeaders: ResetHeadersFunction;
}

interface SpraypaintMiddlewareHook {
  (): SpraypaintMiddlewareHookReturn;
}

export const useSpraypaintMiddleware: SpraypaintMiddlewareHook = () => {
  // const [hasHeadersSet, setHasHeadersSet] = useState<boolean>(false);

  const applyHeaders = useCallback<SetHeadersFunction>((headers) => {
    /**
     * Create the middleware stack.
     */
    const middleware = new MiddlewareStack();

    /**
     * Add headers to outgoing requests.
     */
    middleware.beforeFilters.push((url, options) => {
      /**
       * Merge existing and additional headers.
       */
      options.headers = {...options.headers, ...headers};
    });

    /**
     * Log the user out if an UNAUTHORIZED response is received.
     */
    // middleware.afterFilters.push((response) => {
    //   if (response.status === 401) {
    //     try {
    //       f7.views.main.router.navigate('/logout', {
    //         animate: false,
    //       });
    //     } catch (error) {
    //       console.error(
    //         'Failed to log out user after receiving a UNAUTHORIZED response from Spraypaint.',
    //         error,
    //       );
    //     }
    //   }
    // });

    /**
     * Apply the middleware stack.
     */
    ApplicationRecord.middlewareStack = middleware;
  }, []);

  const setHeaders = useCallback<SetHeadersFunction>(
    (headers) => {
      applyHeaders(headers);
      // setHasHeadersSet(true);
    },
    [applyHeaders],
  );

  const resetHeaders = useCallback<ResetHeadersFunction>(() => {
    applyHeaders({});
    // setHasHeadersSet(false);
  }, [applyHeaders]);

  return {
    // hasHeadersSet,
    setHeaders,
    resetHeaders,
  };
};

export default useSpraypaintMiddleware;
