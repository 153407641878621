import {lazy, Suspense, useCallback, ReactElement} from 'react';
import {createPortal} from 'react-dom';

import {DownloadIcon} from '@heroicons/react/outline';
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from '@react-pdf-viewer/default-layout';
import {saveAs} from 'file-saver';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';

const Worker: any = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Worker};
  }),
);
const Viewer = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Viewer};
  }),
);

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DocumentPreviewModal = ({
  isVisible,
  setIsVisible,
  documentUrl,
  title,
}: {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  documentUrl: string;
  title: string;
}) => {
  const {isLoggedIn, authCookies} = useAuth();

  const handleModalClick = (event: any) => {
    if (event.target.classList.contains('modal')) {
      if (isVisible) {
        setIsVisible(false);
      }
    }
  };

  const downloadDocument = useCallback(async () => {
    const response = await fetch(documentUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-USER-TOKEN': authCookies.token,
        'X-USER-EMAIL': authCookies.userEmail,
      },
    });

    const blob = await response.blob();
    const filename = Date.now();
    saveAs(blob, `${filename}.pdf`);
  }, [documentUrl, authCookies]);

  const renderToolbar = useCallback(
    (Toolbar: (props: ToolbarProps) => ReactElement) => (
      <Toolbar>
        {(slots: ToolbarSlot) => {
          const {EnterFullScreen, ShowSearchPopover, Zoom, ZoomIn, ZoomOut} =
            slots;
          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}>
              <div style={{padding: '0px 2px'}}>
                <ShowSearchPopover />
              </div>
              <div style={{padding: '0px 2px'}}>
                <ZoomOut />
              </div>
              <div style={{padding: '0px 2px'}}>
                <Zoom />
              </div>
              <div style={{padding: '0px 2px'}}>
                <ZoomIn />
              </div>
              <div style={{padding: '0px 2px', marginLeft: 'auto'}}>
                <EnterFullScreen />
              </div>
              <div
                style={{padding: '0px 2px'}}
                className="tooltip tooltip-bottom"
                data-tip="Download">
                <DownloadIcon
                  className="w-5 h-5 cursor-pointer mr-2 "
                  style={{color: 'var(--rpv-core__minimal-button-color)'}}
                  onClick={downloadDocument}
                />
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    [downloadDocument],
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });

  return createPortal(
    <div
      className={'z-100 modal' + (isVisible ? ' modal-open' : '')}
      onClick={handleModalClick}>
      <div
        className="modal-box max-w-full w-10/12 md:w-18/12 max-h-full h-5/6"
        onClick={handleModalClick}>
        <h5 className="text-2xl font-semibold">{title}</h5>
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
              <Viewer
                fileUrl={documentUrl}
                plugins={[defaultLayoutPluginInstance]}
                httpHeaders={
                  isLoggedIn
                    ? {
                        'X-USER-TOKEN': authCookies.token,
                        'X-USER-EMAIL': authCookies.userEmail,
                      }
                    : {}
                }
                renderLoader={() => (
                  <div>
                    <LoadingView />
                  </div>
                )}
                renderError={() => (
                  <div>
                    <p>
                      There was an issue trying to load this document, please
                      try again later.
                    </p>
                  </div>
                )}
              />
            </Worker>
          </Suspense>
        </div>
        <div className="modal-action">
          <button
            className="btn btn-neutral"
            type="button"
            onClick={() => setIsVisible(false)}>
            Close
          </button>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default DocumentPreviewModal;
