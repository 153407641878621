import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  validateYupSchema,
  yupToFormErrors,
  useFormik,
} from 'formik';
import {pick} from 'lodash';
import {BiMailSend} from 'react-icons/bi';
import {HiCheck} from 'react-icons/hi';
import {TbSend} from 'react-icons/tb';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import AddTenantsSection from 'components/tenancy/AddTenantsSection';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {Button} from 'components_sb/buttons';
import {OBFS} from 'constants/onboarding-flow-steps';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {LeaseDocumentType, TenancyStatus} from 'models/properties/Tenancy';
import TenancyRequest from 'models/properties/TenancyRequest';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormTenancyRequest = {
  renterEmail: string;
  name?: string | null;
};

const tenancyInviteSchema = Yup.object().shape({
  messageFromLandlord: Yup.string().nullable().optional(),
  tenancyRequests: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().optional().nullable().label('Tenant name'),
        renterEmail: Yup.string()
          .email()
          .required()
          .label('Tenant email')
          .test('Unique', 'Email needs te be unique', function (val) {
            const ctx = this.options.context;

            if (ctx) {
              const reqs = ctx.tenancyRequests as FormTenancyRequest[];
              if (reqs.filter((r) => r.renterEmail === val).length > 1) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }),
      }),
    )
    .required('Must have tenant invites'),
});

type FormValues = {
  numberOfTenants: number;
  messageFromLandlord?: string | null;
  tenancyRequests: FormTenancyRequest[];
};

const InviteTenantsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartInviteTenantsStep,
      {
        propertyId: property.id,
        tenancyId: tenancy?.id,
      },
    );
  }, [property.id, tenancy?.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();
  const router = useRouter();

  /**
   * Find any existing requests that have been sent for the tenancy.
   * (this will be the case if they are entering this step again to edit their tenancy)
   */
  const existingRequests = useMemo(
    () =>
      tenancy.tenancyRequests
        ? tenancy.tenancyRequests.sort((request) =>
            request.isHeadTenant ? -1 : 1,
          )
        : [],
    [tenancy],
  );

  const hasAlreadySentRequests = useMemo(
    () => existingRequests.length > 0,
    [existingRequests],
  );

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      // TODO: Show warning that this will end the listing

      setSubmitting(true);

      /**
       * Set the landlord message on the tenancy if there is a
       * value for it in the form.
       */
      if (formValues.messageFromLandlord) {
        tenancy.messageFromLandlord = formValues.messageFromLandlord;
      }

      /**
       * If there are existing tenancy requests (i.e. the landlord is editing
       * their tenancy after initially inviting their tenants), then we need to
       * make updates to the existing request instances, otherwise we create
       * new request instances.
       */
      let tenancyRequests = formValues.tenancyRequests.map(
        (formRequest, index) => {
          /**
           * Check if there is an existing request with the email on the current
           * form request item.
           */
          const existingRequest = existingRequests.find(
            ({renterEmail}) => renterEmail === formRequest.renterEmail,
          );

          /**
           * Create a new tenancy request with the given email if no existing
           * request was found for the email, otherwise use the existing request
           * that already has the email set.
           */
          const request =
            existingRequest ??
            new TenancyRequest({
              renterEmail: formRequest.renterEmail,
            });

          /**
           * Set the name on the request.
           */
          request.name = formRequest.name?.trim() ?? null;

          /**
           * Set the head tenant status based on the position of the request
           * in the form.
           */
          request.isHeadTenant = index === 0;

          /**
           * Set the rent splits on the request. If there is only one tenant, then
           * this tenant will pay the full rent and bond. Otherwise, clear the rent
           * splits so that the tenants can decide this themselves.
           */
          if (formValues.tenancyRequests.length === 1) {
            request.rentSplit = tenancy.totalRent;
            request.bondSplit = tenancy.bond;
          } else {
            request.rentSplit = null;
            request.bondSplit = null;
          }

          return request;
        },
      );

      /**
       * Add any previous requests that were not included in the form
       * to the array of requests, but mark them for destruction so that
       * they get destroyed.
       */
      tenancyRequests = [
        ...tenancyRequests,
        ...existingRequests.reduce((toDestroy, existingRequest) => {
          /**
           * If we cant find the email of the existing request in the form
           * request items, then we need to destroy the existing request.
           */
          const shouldDestroy = !formValues.tenancyRequests.find(
            ({renterEmail}) => renterEmail === existingRequest.renterEmail,
          );

          /**
           * Mark the existing request for destruction and add it to the array
           * of requests to be saved on the tenancy.
           */
          if (shouldDestroy) {
            existingRequest.isMarkedForDestruction = true;
            return [...toDestroy, existingRequest];
          } else {
            /**
             * Return the array of requests to be destroyed unchanged.
             */
          }
          return toDestroy;
        }, []),
      ];

      /**
       * Set the requests on the tenancy.
       */
      tenancy.tenancyRequests = tenancyRequests;

      /**
       * Set the tenancy as pending if not already set.
       */
      if (tenancy.status !== TenancyStatus.Pending) {
        tenancy.status = TenancyStatus.Pending;
      }

      if (!(await saveResource(tenancy, {with: 'tenancyRequests'}))) {
        setSubmitting(false);
        return;
      }

      /**
       * Set changes on the property.
       */
      property.lastOnboardingStepCompleted = OBFS.NewInviteTenants;

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteInviteTenantsStep,
        {
          propertyId: property.id,
          tenancyId: tenancy.id,
        },
      );

      /**
       * Track completion of the flow.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteFlow,
        {
          propertyId: property.id,
          tenancyId: tenancy.id,
        },
      );

      await queryClient.invalidateQueries([
        'property',
        {id: property.id, context: 'detail-page'},
      ]);

      actions.setSubmitting(false);

      localStorage.removeItem('new-property-id');

      localStorage.removeItem(`property-${property.id}-new-invite-tenants`);

      toast.success(
        hasAlreadySentRequests
          ? 'Your tenancy has been successfully updated.'
          : `Your lease has been successfully sent to your ${
              tenancyRequests.length === 1 ? 'tenant' : 'tenants'
            }.`,
      );

      router.navigate(`/properties/${property.id}`, {reloadCurrent: true});

      setSubmitting(false);
    },
    [
      hasAlreadySentRequests,
      existingRequests,
      router,
      property,
      queryClient,
      tenancy,
    ],
  );

  const onClickSkip = useCallback(() => {
    /**
     * Track that the step has been saved for later.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_SaveInviteTenantsStep,
      {
        propertyId: property.id,
      },
    );

    /**
     * Track completion of the flow.
     */
    TrackingService.trackEvent(TrackingService.Event.NewTenancy_CompleteFlow, {
      propertyId: property.id,
    });

    /**
     * Navigate the user to their dashboard.
     */
    router.navigate(`/properties/${property.id}`);
  }, [router, property.id]);

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const form = useFormik<FormValues>({
    initialValues: {
      numberOfTenants: existingRequests.length ?? 1,
      messageFromLandlord: tenancy.messageFromLandlord ?? undefined,
      tenancyRequests: (existingRequests.length
        ? existingRequests.map((request) =>
            pick(request, ['renterEmail', 'name']),
          )
        : [
            {
              renterEmail: '',
              name: '',
            },
          ]) as FormTenancyRequest[],
    },
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validate: (value) => {
      try {
        validateYupSchema(value, tenancyInviteSchema, true, value);
      } catch (err) {
        return yupToFormErrors(err); //for rendering validation errors
      }
      return {};
    },
  });

  const hasModifiedExistingTenantDetails = useMemo(
    () => hasAlreadySentRequests && form.dirty,
    [hasAlreadySentRequests, form],
  );

  useEffect(() => {
    const savedValues = localStorage.getItem(
      `property-${property.id}-new-invite-tenants`,
    );

    if (savedValues) {
      form.setValues(JSON.parse(savedValues));
    }
    // DONT ADD FORMIK TO THE DEPENDENCIES
    // OTHERWISE USERS CANT EDIT THE FIELDS.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property.id]);

  /**
   * If the user has chosen the boarding house lease template, then the
   * maximum number of tenants is 2, otherwise the maximum is 6.
   */
  const maxTenants = useMemo(
    () =>
      tenancy.leaseDocumentType ===
      LeaseDocumentType.TenancyServicesBoardingHouse
        ? 2
        : 6,
    [tenancy],
  );

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        ...(!hasAlreadySentRequests || hasModifiedExistingTenantDetails
          ? {
              label: hasModifiedExistingTenantDetails ? 'Resend' : 'Send',
              icon: TbSend,
            }
          : {
              label: 'Confirm',
              icon: HiCheck,
            }),
        onClick: onClickNext,
        loading: submitting,
      },
      ...(!hasAlreadySentRequests
        ? {
            skip: {
              onClick: onClickSkip,
            },
          }
        : {}),
    },
  });

  return (
    <StepContainer
      title="Send your tenants the lease"
      subtitle="Be sure to include all tenants who will appear on the lease">
      <AddTenantsSection formik={form} maxTenants={maxTenants} />
    </StepContainer>
  );
};

export default InviteTenantsStep;
