import {useCallback, useEffect, useMemo, useState} from 'react';

import {FormikConfig, FormikValues, useFormik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import StepContainer from 'components/walkthrough/common/StepContainer';
import {Alert} from 'components_sb/feedback';
import {TextAreaField, TextField} from 'components_sb/input';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import TenancyRequest from 'models/properties/TenancyRequest';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  renterEmail: string;
  messageFromLandlord?: string | null;
  phoneNumber?: string | null;
};

const InviteFirstTenantStep: OnboardingFlowStepComponent = ({
  step,
  property,
}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  /**
   * Find the most recent tenancy request for the tenancy.
   */
  const mostRecentTenancyRequest = useMostRecentlyCreated(
    tenancy.tenancyRequests,
  );

  /**
   * If a tenancy request exists for the tenancy, then this
   * indicates that the user has already invited their first tenant.
   */
  const hasInvitedFirstTenant = useMemo(
    () => !!mostRecentTenancyRequest,
    [mostRecentTenancyRequest],
  );

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartInviteTenantStep,
      {
        propertyId: property.id,
        tenancyId: tenancy.id,
      },
    );
  }, [property.id, tenancy.id, scrollToTop]);

  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Create a new tenancy request if there has not yet been one created.
       */
      const tenancyRequest = mostRecentTenancyRequest ?? new TenancyRequest();

      /**
       * Set changes on the tenancy.
       */
      if (
        formValues.messageFromLandlord &&
        formValues.messageFromLandlord.length > 0
      ) {
        tenancy.messageFromLandlord = formValues.messageFromLandlord;
      }
      tenancyRequest.renterEmail = formValues.renterEmail;
      tenancyRequest.phoneNumber = formValues.phoneNumber;
      tenancyRequest.isHeadTenant = true;
      tenancyRequest.rentSplit = tenancy.totalRent;
      tenancy.tenancyRequests = [tenancyRequest];

      /**
       * Save the changes to the tenancy.
       */
      const savedTenancy = await saveResource(tenancy, {
        with: 'tenancyRequests',
      });

      if (savedTenancy) {
        /**
         * Set changes on the property.
         */
        property.lastOnboardingStepCompleted = 'migrate_invite_tenant';

        /**
         * Save the changes to the property.
         */
        const savedProperty = await saveResource(property);

        if (savedProperty) {
          /**
           * Update the property data in the query cache.
           */
          queryClient.setQueryData(
            ['property', {id: property.id, context: 'onboarding-flow'}],
            property,
          );

          /**
           * Track completion of the step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.MigrateTenancy_CompleteInviteTenantStep,
            {
              propertyId: property.id,
              tenancyId: tenancy.id,
              tenancyRequestId: tenancyRequest.id,
            },
          );
        }
      } else {
        for (const key of Object.keys(tenancy.errors)) {
          const message = tenancy.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }

      setSubmitting(false);
    },
    [mostRecentTenancyRequest, property, queryClient, tenancy],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        renterEmail: mostRecentTenancyRequest
          ? mostRecentTenancyRequest.renterEmail
          : '',
        messageFromLandlord: tenancy.messageFromLandlord || '',
        phoneNumber: mostRecentTenancyRequest
          ? mostRecentTenancyRequest.phoneNumber
          : '',
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        renterEmail: Yup.string()
          .email('Please enter a valid email address')
          .required('An email address is required'),
        messageFromLandlord: Yup.string().optional(),
        phoneNumber: Yup.string().optional().nullable(),
      }),
    }),
    [handleSubmit, tenancy, mostRecentTenancyRequest],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Set the step as completed without saving any of the
   * form values.
   */
  const onClickSkip = useCallback(async () => {
    setSubmitting(true);
    /**
     * Set the step as completed.
     */
    property.setOnboardingStepAsCompleted(step);
    /**
     * Save the changes to the property.
     */
    const savedProperty = await saveResource(property);

    if (savedProperty) {
      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );
    }

    setSubmitting(false);
  }, [property, step, queryClient]);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback<() => void>(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      skip: {
        onClick: onClickSkip,
      },
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Invite your tenant to join you!"
      subtitle="To start, invite one tenant to download the Keyhook app.">
      <div className="w-full">
        <Alert
          show={
            hasInvitedFirstTenant &&
            mostRecentTenancyRequest.renterEmail !== form.values.renterEmail
          }
          type="info"
          title="You have already sent an invitiation"
          description={[
            'If you change the email address below, a new invitation will be sent to the new email address and the previous invitation will be cancelled.',
          ]}
        />
      </div>
      <div className="flex w-full">
        <TextField
          label="Email"
          labelSize="xl"
          name="renterEmail"
          mode="formik"
          form={form}
          placeholder="E.g. name@email.com"
        />
      </div>
      <div className="flex w-full">
        <TextField
          label="Phone number (optional)"
          labelSize="xl"
          name="phoneNumber"
          mode="formik"
          form={form}
          placeholder="E.g. 021 123 4567"
        />
      </div>
      <div className="flex w-full">
        <TextAreaField
          form={form}
          mode="formik"
          name="messageFromLandlord"
          label="Add a message (optional)"
          labelSize="xl"
          placeholder="Include a welcome message for your tenant."
        />
      </div>
    </StepContainer>
  );
};

export default InviteFirstTenantStep;
