import React, {Fragment, useCallback, useMemo, useState} from 'react';

import moment from 'moment';
import {Collapse, UnmountClosed} from 'react-collapse';
import {HiChevronDown, HiChevronUp} from 'react-icons/hi';
import {useQuery} from 'react-query';

import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import OpenHome from 'models/listings/OpenHome';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

import ListingUserNotesEditor from '../../ListingUserNotesEditor/ListingUserNotesEditor';

const OpenHomeDetailPage = () => {
  const {
    params: {openHomeId},
  } = useRoute();

  // const queryClient = useQueryClient();

  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [selectedAttendee, setSelectedAttendee] =
  //   useState<OpenHomeAttendee | null>(null);

  const {
    data: openHome,
    isLoading,
    error,
  } = useQuery(
    `open-home-${openHomeId}`,
    async () =>
      (
        await OpenHome.includes({
          open_home_attendees: 'user',
        }).find(openHomeId)
      ).data,
  );

  const [attendeeShowingNotesFor, setAttendeeShowingNotesFor] =
    useState<OpenHomeAttendee | null>(null);

  /**
   * Handle adding notes for a particular attendee from the list.
   */
  const onToggleViewNotesForAttendee = useCallback(
    (openHomeAttendee: OpenHomeAttendee) => {
      if (attendeeShowingNotesFor?.userId === openHomeAttendee.userId) {
        setAttendeeShowingNotesFor(null);
      } else {
        setAttendeeShowingNotesFor(openHomeAttendee);
      }
    },
    [attendeeShowingNotesFor],
  );

  /**
   * Whether anyone has RSVPd to the open home.
   */
  const hasAttendees = useMemo<boolean>(
    () => openHome?.openHomeAttendees.length > 0,
    [openHome],
  );

  // console.log('data', data);

  return (
    <Page title="Open Home Attendees" loading={isLoading} error={error}>
      {() => (
        <Card
          title={`Open Home Attendees${
            hasAttendees ? ` (${openHome.openHomeAttendees.length})` : ''
          }`}>
          {!hasAttendees ? (
            <Paragraph>
              Currently there are no RSVP's for this open home.
            </Paragraph>
          ) : (
            <>
              <div className="mb-2">
                <Paragraph>
                  These are all the people who have RSVP'd that they will attend
                  your open home.
                </Paragraph>
              </div>
              <div className="flex flex-col gap-y-4">
                {openHome.openHomeAttendees.map((openHomeAttendee) => {
                  const {id, user, createdAt} = openHomeAttendee;
                  const viewingNotes =
                    attendeeShowingNotesFor?.userId === openHomeAttendee.userId;
                  return (
                    <div key={id} className="flex flex-col">
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-x-2 gap-y-2 mb-4">
                        <div className="flex justify-start items-center gap-2">
                          <div className="hidden sm:flex">
                            <UserAvatar user={user} size="10" />
                          </div>
                          <div>
                            <strong>{user.name}</strong>
                            <Paragraph secondary size="sm">
                              RSVP'd on{' '}
                              {moment(createdAt).format(DATE_TIME_FORMAT)}
                            </Paragraph>
                          </div>
                        </div>
                        <div className="float-right">
                          <Button
                            label={`${viewingNotes ? 'Hide' : 'View'} notes`}
                            icon={viewingNotes ? HiChevronUp : HiChevronDown}
                            category="primary"
                            size="sm"
                            mode="manual"
                            onClick={() =>
                              onToggleViewNotesForAttendee(openHomeAttendee)
                            }
                          />
                        </div>
                      </div>
                      <Collapse isOpened={viewingNotes}>
                        <div className="ml-0 md:ml-12 pb-6">
                          <ListingUserNotesEditor
                            listingPrivateId={openHome.listingId}
                            userId={openHomeAttendee.userId}
                          />
                        </div>
                      </Collapse>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </Card>
      )}
    </Page>
  );
};

export default OpenHomeDetailPage;
