import React from 'react';

import {Form, Formik, FormikHelpers} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import {Button} from 'components_sb/buttons';
import {TextAreaField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReference from 'models/tenant_checks/TenantCheckReference';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

type Item = {
  question: string;
  answer: string;
};

interface FormValues {
  userReference: Item[];
}

const EditTenantCheckReferencePage = () => {
  const {
    params: {publicAccessToken},
  } = useRoute();

  const {data, error, isLoading} = useQuery(
    ['tenant-check-reference-', publicAccessToken],
    async () => {
      const reference = await TenantCheckReference.find(publicAccessToken);
      return reference.data;
    },
  );

  const handleSubmit = async (
    values: FormValues,
    actions: FormikHelpers<FormValues>,
  ) => {
    const ref = data;

    /**
     * This is to handle the security check for
     * using the public access token as the main identifier
     */
    ref.id = publicAccessToken;

    ref.userReference = values.userReference;
    ref.completed = true;

    const result = await ref.save();

    if (result) {
      actions.setStatus({success: 'Reference updated successfully'});
      toast.success('Reference added successfully');
    }

    actions.setSubmitting(false);
  };

  return (
    <Page title="Provide a reference" loading={isLoading} error={error}>
      {() => (
        <div>
          <Card title="Provide a reference">
            <p>
              {data.name} has listed you as a{' '}
              {data.referenceTypes.join(' and ')} reference for a rental
              property application. Please answer the following questions to the
              best of your ability.
            </p>

            <Formik
              initialValues={
                {
                  userReference: data.userReference,
                } as FormValues
              }
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}>
              {(formik) => (
                <Form>
                  {data.userReference.map((item, index) => (
                    <div className="mt-2" key={index}>
                      {item.label && <strong>{item.label}</strong>}
                      <TextAreaField
                        name={`userReference[${index}].answer`}
                        label={item.question}
                        placeholder="Write your answer here..."
                        mode="formik"
                        form={formik as any}
                      />
                    </div>
                  ))}

                  <div className="mt-2">
                    <Paragraph>
                      Your responses will be handled in accordance with the
                      Privacy Act 2020 and will only be used for the purpose of
                      our tenant evaluation.
                    </Paragraph>

                    <Paragraph>
                      Thank you in advance for your time and assistance in
                      helping us make an informed decision.
                    </Paragraph>
                  </div>

                  <div className="mt-4">
                    <Button
                      mode="formik"
                      form={formik as any}
                      category="primary"
                      size="base"
                      label="Submit Reference"
                      loadingLabel="Saving"
                    />
                  </div>

                  <PersistFormikValues
                    name={`reference-${publicAccessToken}`}
                    persistInvalid={true}
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      )}
    </Page>
  );
};

export default EditTenantCheckReferencePage;
