import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {
  Base64FileField,
  InputField,
  SelectField,
  SubmitButton,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Document from 'models/properties/Document';
import Property from 'models/properties/Property';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {errorViewForError} from 'utilities/ErrorHelpers';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const DOCUMENT_TYPES = [
  'other',
  'insurance_information',
  'general_information',
  'property_rules',
  'body_corporate_information',
  'healthy_homes_report',
];

const TENANCY_DOCUMENT_TYPES = [
  'lease',
  'bond',
  'bond_supplementary',
  'bond_receipt',
];

const NewDocumentPage = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const router = useRouter();
  const queryClient = useQueryClient();

  // Used to check landlord has permission for property
  const {data, isLoading, error} = useQuery(
    `property-${propertyId}-add-document`,
    async () => {
      const property = await Property.select({
        properties: ['id', 'has_insulation_report'],
        tenancies: ['id', 'is_new'],
      })
        .includes('current_tenancy')
        .find(propertyId);

      return property.data;
    },
  );

  const handleSubmit = async (formValues: any, actions: FormikHelpers<any>) => {
    const doc = new Document(formValues);

    /*
     * The other_tenancy is a small hack so that we can allow properties and tenancies to
     * both have other documents.
     * We use other_tenancy so that we know its a tenancy document. But we have to set the Document key to be other.
     */

    if (
      TENANCY_DOCUMENT_TYPES.includes(doc.documentType) ||
      formValues.documentType === 'other_tenancy'
    ) {
      doc.documentableType = 'Tenancy';
      doc.documentableId = data.currentTenancy.id;
      doc.documentType = 'other';
    } else {
      doc.documentableType = 'Property';
      doc.documentableId = propertyId;
    }

    if (doc.name.length === 0 && doc.documentType !== 'other') {
      doc.name = null;
    }

    const result = await doc.save();
    if (result) {
      queryClient.invalidateQueries([
        'property',
        {id: propertyId, context: 'detail-page'},
      ]);
      toast.success('Document successfully created!');
      router.goBack();
    } else {
      for (const key of Object.keys(doc.errors)) {
        const message = doc.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return <Page title="Add New Document" loading />;
  } else {
    const showTenancyDocuments = !!data.currentTenancy;

    const docTypes = showTenancyDocuments
      ? DOCUMENT_TYPES.concat(TENANCY_DOCUMENT_TYPES)
      : DOCUMENT_TYPES;
    return (
      <Page title="Add New Document">
        {() => (
          <Card title="Add New Document">
            <Paragraph>
              Store all your documents in your property profile to have them
              accessible in one place.
            </Paragraph>

            <div className="mt-3">
              <Formik
                initialValues={{
                  document: '',
                  documentType: 'other',
                  name: '',
                  private: true,
                }}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                  document: Yup.string()
                    .required()
                    .min(1)
                    .label('Document File'),
                  documentType: Yup.string()
                    .required()
                    .oneOf(docTypes.concat(['other_tenancy']))
                    .label('Document Type'),
                  name: Yup.string()
                    .label('Document Name')
                    .min(0)
                    .when('documentType', {
                      is: (docType: any) => docType === 'other',
                      then: Yup.string()
                        .required('Document Name is required')
                        .min(1)
                        .label('Document Name'),
                    }),
                  private: Yup.boolean().label('Private'),
                })}>
                {(formik) => (
                  <Form>
                    <FormRow responsive>
                      <FormRowItem>
                        <SelectField
                          formik={formik}
                          name="documentType"
                          labelProps={{
                            title: 'Document type',
                          }}>
                          <optgroup label="Property Documents">
                            {DOCUMENT_TYPES.map((type) => (
                              <option value={type} key={type}>
                                {titleize(removeUnderscores(type))}
                              </option>
                            ))}
                          </optgroup>

                          {showTenancyDocuments && (
                            <optgroup label="Tenancy Documents">
                              <option value="other_tenancy">Other</option>
                              {TENANCY_DOCUMENT_TYPES.map((type) => (
                                <option value={type} key={type}>
                                  {titleize(removeUnderscores(type))}
                                </option>
                              ))}
                            </optgroup>
                          )}
                        </SelectField>
                      </FormRowItem>

                      {(formik.values.documentType === 'other' ||
                        formik.values.documentType === 'other_tenancy') && (
                        <FormRowItem>
                          <InputField
                            formik={formik}
                            name="name"
                            labelProps={{
                              title: 'Document name',
                            }}
                            placeholder="E.g. Oven manual"
                          />
                        </FormRowItem>
                      )}
                    </FormRow>

                    {showTenancyDocuments && (
                      <div className="mt-4">
                        <Paragraph>
                          Tenancy documents will only be available to the
                          tenants in the current tenancy for this property
                          (unless marked as private). If you are creating your
                          lease through Keyhook, your lease and bond documents
                          will be automatically generated for you.
                        </Paragraph>
                      </div>
                    )}

                    <div className="mt-4">
                      <Base64FileField
                        formik={formik}
                        name="document"
                        accept=".pdf"
                        labelProps={{
                          title: 'Document',
                          description: 'Please upload a PDF file',
                        }}
                      />
                    </div>

                    <div className="mt-4 w-1/2">
                      <BooleanSelect
                        labelProps={{
                          title: 'Is this a private document?',
                          description:
                            'Private documents are not viewable by tenants',
                        }}
                        preset="yes/no"
                        mode="formik"
                        name="private"
                        form={formik}
                      />
                    </div>

                    <div className="mt-4">
                      <SubmitButton
                        formik={formik}
                        text="Upload Document"
                        submittingText="Uploading"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        )}
      </Page>
    );
  }
};

export default NewDocumentPage;
