import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {App as CapacitorApp} from '@capacitor/app';
import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {
  Page as F7Page,
  Navbar as F7Navbar,
  NavLeft,
  NavTitle,
  NavRight,
  Toolbar,
  NavTitleLarge,
  f7,
} from 'framework7-react';
import {PageProps as F7PageProps} from 'framework7-react/components/page';
import {HiArrowLeft} from 'react-icons/hi';

import useAuth from 'auth/provider/useAuth';
import {ResponsiveActionSelect} from 'components_sb/buttons';
import ErrorBoundary from 'components_sb/error-boundaries/ErrorBoundary';
import {SpinningLoader} from 'components_sb/feedback';
import BottomTabBar from 'components_sb/navigation/BottomTabBar/BottomTabBar';
import {NATIVE_LAYOUT_IN_WEB} from 'globals/app-globals';
import {useKeyboard} from 'providers/KeyboardProvider';
import useSubscriptions from 'providers/Subscriptions/hooks/useSubscriptions';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';
import {Action} from 'types/actions';
import {errorViewForError} from 'utilities/ErrorHelpers';

type PullToRefreshProps =
  | 'onPtrPullStart'
  | 'onPtrPullMove'
  | 'onPtrPullEnd'
  | 'onPtrRefresh'
  | 'onPtrDone';

type PageEventProps =
  | 'onPageMounted'
  | 'onPageInit'
  | 'onPageReinit'
  | 'onPageBeforeIn'
  | 'onPageBeforeOut'
  | 'onPageAfterOut'
  | 'onPageAfterIn'
  | 'onPageBeforeRemove'
  | 'onPageBeforeUnmount'
  | 'onPageTabShow'
  | 'onPageTabHide';

type ConditionalProps =
  | {
      disableNavbar: true;
      disableBack?: never;
      backUrl?: never;
      actions?: never;
    }
  | {
      disableNavbar?: never;
      disableBack?: boolean;
      backUrl?: string;
      actions?: Action[];
    };

/**
 * Allow selected props from the Framework7 page component to be set
 * on the custom page component.
 */
type PassThroughF7PageProps = Pick<
  F7PageProps,
  PullToRefreshProps | PageEventProps
>;

/**
 * The props for the custom Page component.
 */
type CustomPageProps = {
  children?: (props: {isIn: boolean}) => ReactNode;
  title?: string;
  loading?: boolean;
  error?: unknown;
  redirect?: string;
  largeTitle?: boolean;
  disableTabs?: boolean;
  unbounded?: boolean;
  centred?: boolean;
  pullToRefresh?: boolean;
} & ConditionalProps &
  PassThroughF7PageProps;

/**
 * A custom Page component that wraps the Framework7 Page component for
 * additional functionality.
 */
const Page: FunctionComponent<CustomPageProps> = ({
  children,
  title = null,
  loading: pageContentLoading = false,
  error: pageContentError = null,
  redirect = null,
  largeTitle = true,
  unbounded = false,
  centred = false,
  disableNavbar = false,
  disableTabs = false,
  disableBack: disableBackProp = false,
  pullToRefresh = false,
  backUrl,
  actions = [],
  ...passthroughProps
}) => {
  const router = useRouter();
  const route = useRoute();

  const pageRef = useRef<{el: HTMLElement | null}>();

  const handleRedirect = useCallback(() => {
    router.navigate(redirect, {reloadCurrent: true});
  }, [router, redirect]);

  useEffect(() => {
    if (redirect) {
      handleRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  const {isLoggedIn} = useAuth();

  /**
   * The back button will only be shown for native platforms, and only
   * if the disableBack prop is not set.
   */
  const disableBack = useMemo(() => !!disableBackProp, [disableBackProp]);

  /**
   * Link the back button on native devices to the Framework7 router, when
   * going back has not been disabled.
   */
  useEffect(() => {
    if (!disableBack) {
      CapacitorApp.addListener('backButton', () => {
        router.goBack();
      });
    }
    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [router, disableBack]);

  /**
   * If a back URL is provided, we force navigation to go back
   * to the specified URL. Otherwise, we just go back based
   * on the browser history.
   */
  const handleGoBack = useCallback(() => {
    if (backUrl) {
      router.goBack(backUrl);
    } else {
      router.goBack();
    }
  }, [backUrl, router]);

  const [isEntering, setIsEntering] = useState<boolean>(false);
  const [isIn, setIsIn] = useState<boolean>(false);
  const [isExiting, setIsExiting] = useState<boolean>(false);

  const {keyboardIsOpening, keyboardIsOpen, keyboardIsClosing} = useKeyboard();

  /**
   * Set the page title.
   */
  useEffect(() => {
    if (isEntering || isIn) {
      document.title = title ? `${title} - Keyhook` : 'Keyhook';
    }
  }, [isEntering, isIn, title]);

  /**
   * Hide / Show the toolbar when the keyboard visibility changes
   * in Capacitor environments.
   */
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      if (keyboardIsOpening || keyboardIsOpen) {
        f7.toolbar.hide('#toolbar');
      } else if (keyboardIsClosing || !keyboardIsOpen) {
        f7.toolbar.show('#toolbar');
      }
    }
  }, [keyboardIsOpening, keyboardIsOpen, keyboardIsClosing]);

  /**
   * Invoked when the page is about to transition into view.
   */
  const onPageBeforeIn = useCallback(() => {
    setIsEntering(true);

    /**
     * Invoke the passed through prop if provided.
     */
    if ('onPageBeforeIn' in passthroughProps) {
      passthroughProps.onPageBeforeIn();
    }
  }, [passthroughProps]);

  /**
   * Invoked after the page has transitioned into view.
   */
  const onPageAfterIn = useCallback(() => {
    setIsIn(true);
    setIsEntering(false);

    /**
     * Track the page view.
     */
    TrackingService.trackEvent(TrackingService.Event.PageView, {
      name: route.route.id ?? 'Unidentified Page',
    });

    /**
     * Invoke the passed through prop if provided.
     */
    if ('onPageAfterIn' in passthroughProps) {
      passthroughProps.onPageAfterIn();
    }
  }, [route, passthroughProps]);

  /**
   * Invoked when the page is about to transition out of view.
   */
  const onPageBeforeOut = useCallback(() => {
    setIsExiting(true);

    /**
     * Show the toolbar in case it has been hidden on scroll.
     */
    f7.toolbar.show('#toolbar');

    /**
     * Invoke the passed through prop if provided.
     */
    if ('onPageBeforeOut' in passthroughProps) {
      passthroughProps.onPageBeforeOut();
    }
  }, [passthroughProps]);

  /**
   * Invoked after the page transitioned out of view.
   */
  const onPageAfterOut = useCallback(() => {
    setIsIn(false);
    setIsExiting(false);

    /**
     * Invoke the passed through prop if provided.
     */
    if ('onPageAfterOut' in passthroughProps) {
      passthroughProps.onPageAfterOut();
    }
  }, [passthroughProps]);

  const shouldRenderGlobalUI = useMemo<boolean>(
    () => (isIn || isEntering) && !isExiting,
    [isIn, isEntering, isExiting],
  );

  /**
   * Modify the provided loading prop to also account for whether
   * any contexts are still loading.
   */
  const loading = useMemo<boolean>(
    () => pageContentLoading,
    [pageContentLoading],
  );

  /**
   * Modify the provided error prop to also account for whether
   * any contexts have errors.
   */
  const error = useMemo<unknown>(() => pageContentError, [pageContentError]);

  /**
   * The page content is ready to be rendered when a redirect is not
   * due to occur, it is not loading and there is no error.
   */
  const ready = useMemo<boolean>(
    () => !redirect && !loading && !error,
    [redirect, loading, error],
  );

  /**
   * The value of scrollTop on the page content element at the last
   * scroll event.
   */
  const [previousScrollTop, setPreviousScrollTop] = useState<number>(0);

  /**
   * Handle scroll events on the page content.
   * (Hide and show the toolbar based on the scroll direction.)
   */
  const onPageScroll = useCallback(
    (event: Event) => {
      const pageContentElement = event.target as HTMLElement;

      const {scrollTop: currentScrollTop} = pageContentElement;

      /**
       * If the keyboard is visible in a Capacitor environment, we don't want to
       * show the toolbar when the user scrolls up.
       */
      if (Capacitor.isNativePlatform() && !keyboardIsOpen) {
        if (currentScrollTop > previousScrollTop) {
          f7.toolbar.hide('#toolbar');
        } else {
          f7.toolbar.show('#toolbar');
        }
      }

      /**
       * Negative scroll can occur when the page is pulled down to refresh or the scroll
       * bounces at the top, so we want to consider any scroll positions that are less than
       * 0 to be the same as 0.
       */
      if (currentScrollTop >= 0) {
        setPreviousScrollTop(currentScrollTop);
      }
    },
    [previousScrollTop, keyboardIsOpen],
  );

  /**
   * Listen for scroll events on the page content.
   */
  useEffect(() => {
    if (pageRef.current?.el) {
      const {el: pageElement} = pageRef.current;
      const pageContentElement =
        pageElement.getElementsByClassName('page-content')?.[0];
      if (pageContentElement) {
        pageContentElement.addEventListener('scroll', onPageScroll);
        return () =>
          pageContentElement.removeEventListener('scroll', onPageScroll);
      }
    }
  }, [pageRef, onPageScroll]);

  /**
   * Handle scrolling to the specified element if it can be found in the page
   * upon initial load of the page.
   */
  const [hasHandledScrollOnMount, setHasHandledScrollOnMount] =
    useState<boolean>(false);
  useEffect(() => {
    const {scrollToIdOnMount} = route.props;
    if (!hasHandledScrollOnMount && isIn && !loading && !error) {
      if (scrollToIdOnMount) {
        const element = document.getElementById(scrollToIdOnMount);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      }
      setHasHandledScrollOnMount(true);
    }
  }, [hasHandledScrollOnMount, route.props, isIn, loading, error]);

  return (
    <>
      {/* Tab bar (only shown on native when logged in) */}
      {/* Hidden when keyboard is visible */}
      {shouldRenderGlobalUI &&
        isLoggedIn &&
        !disableTabs &&
        (Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB) && (
          <Toolbar id="toolbar" bottom tabbar inner={false}>
            <BottomTabBar />
          </Toolbar>
        )}
      <F7Page
        id="page"
        ref={pageRef}
        noNavbar={disableNavbar}
        onPageBeforeIn={onPageBeforeIn}
        onPageAfterIn={onPageAfterIn}
        onPageBeforeOut={onPageBeforeOut}
        onPageAfterOut={onPageAfterOut}
        ptr={pullToRefresh}
        /**
         * Pass through remaining props
         */
        {...passthroughProps}>
        {/* Navbar */}
        {!disableNavbar && (
          <F7Navbar
            sliding
            large={
              (Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB) &&
              largeTitle
            }
            className="flex flex-row justify-center"
            innerClassName={clsx(
              'max-w-7xl left-auto',
              /**
               * If the back button is disabled, we need to add some padding
               * to account for the padding that would have otherwise been within
               * the button.
               */
              disableBack && 'pl-6 lg:pl-8',
            )}>
            {/* Back button (only in native) */}
            {!disableBack && (
              <NavLeft className="h-full">
                <button
                  role="button"
                  onClick={handleGoBack}
                  className="group h-full pl-4 lg:pl-8 lg:pr-2">
                  <HiArrowLeft
                    className={clsx(
                      'w-6 h-6',
                      'duration-300 transition-colors',
                      'text-brand-500 group-hover:text-brand-400',
                    )}
                  />
                </button>
              </NavLeft>
            )}

            {/* Title */}
            {title && <NavTitle>{title}</NavTitle>}
            {title &&
              largeTitle &&
              (Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB) && (
                // Large title only in native
                <NavTitleLarge className="[&>.title-large-text]:pt-0">
                  {title}
                </NavTitleLarge>
              )}

            {/* Actions */}
            {actions?.length > 0 && (
              <NavRight className="max-w-md">
                <div className="mr-6 lg:mr-8">
                  <ResponsiveActionSelect actions={actions} justify="end" />
                </div>
                {/* TODO: Use the button below for consistent UI with the back button */}
                {/* <button
                  role="button"
                  // onClick={handleGoBack}
                  className="group h-full px-6 lg:px-8">
                  <HiDotsHorizontal
                    className={clsx(
                      'w-7 h-7',
                      'duration-300 transition-colors',
                      'text-brand-500 group-hover:text-brand-400',
                    )}
                  />
                </button> */}
              </NavRight>
            )}
          </F7Navbar>
        )}

        {/* Main content */}
        {ready && !!children && (
          <main
            className={clsx(
              'w-full',
              'h-auto min-h-full',
              'overflow-auto',
              'flex flex-col',
              'relative',
              !unbounded && clsx('bounded-x', 'bounded-y'),
              centred &&
                clsx(
                  'items-center justify-center',
                  /**
                   * Increased bottom padding so that the centred content "feels" more accurately centred
                   */
                  '!pb-16',
                ),
            )}>
            <ErrorBoundary>{children({isIn})}</ErrorBoundary>
          </main>
        )}

        {(loading || error) && (
          <div
            className={clsx(
              'w-full h-full',
              'flex items-center justify-center',
            )}>
            {/* Loading indicator */}
            {loading && <SpinningLoader size="lg" color="brand" />}
            {/* Error indicator */}
            {!loading && !!error && errorViewForError(error)}
          </div>
        )}
      </F7Page>
    </>
  );
};

export default Page;
