import {FunctionComponent, useMemo, useState} from 'react';

import {
  useFloating,
  useInteractions,
  offset,
  shift,
  useClick,
  useDismiss,
} from '@floating-ui/react';
import clsx from 'clsx';
import {
  HiOutlineCog,
  HiOutlineCreditCard,
  HiOutlineLogout,
  HiOutlineUser,
  HiOutlineUserAdd,
} from 'react-icons/hi';

import useAuth from 'auth/provider/useAuth';
import UserAvatar from 'components/user/UserAvatar';
import FloatingActions from 'components_sb/buttons/FloatingActions/FloatingActions';
import {AccountRole} from 'models/users/User';
import {Action} from 'types/actions';

const AccountDropdown: FunctionComponent = () => {
  const {currentUser} = useAuth();

  const [floatingActionsOpen, setFloatingActionsOpen] =
    useState<boolean>(false);

  /**
   * Create a floating instance.
   */
  const floating = useFloating({
    open: floatingActionsOpen,
    onOpenChange: setFloatingActionsOpen,
    placement: 'bottom',
    middleware: [
      /**
       * Add a gap between the link and the popover.
       */
      offset(10),

      /**
       * Keep the popover in view of the window along the x-axis.
       */
      shift({
        /**
         * Ensure at least 10px of horizontal padding when shifting.
         */
        padding: 10,
      }),
    ],
  });

  const click = useClick(floating.context);
  const dismiss = useDismiss(floating.context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
  ]);

  /**
   * Define the dropdown actions.
   */
  const actions = useMemo<Action[]>(
    () => [
      {
        label: 'My account',
        icon: HiOutlineUser,
        linkTo: '/account',
      },
      /**
       * Conditionally include the billing and referral links if the user has the Landlord account role.
       */
      ...(currentUser.roles.includes(AccountRole.Landlord)
        ? [
            {
              label: 'Billing',
              icon: HiOutlineCreditCard,
              linkTo: '/account/billing',
            },
            {
              label: 'Refer a friend',
              icon: HiOutlineUserAdd,
              linkTo: '/refer-a-friend',
            },
          ]
        : []),
      {
        label: 'Settings',
        icon: HiOutlineCog,
        linkTo: '/account/settings',
      },
      {
        label: 'Log out',
        icon: HiOutlineLogout,
        linkTo: '/logout',
        routeOptions: {
          animate: false,
        },
      },
    ],
    [currentUser?.roles],
  );

  return (
    <>
      <button
        ref={floating.refs.setReference}
        className={clsx(
          'rounded-full flex items-center text-sm',
          'transition-all duration-300',
          'opacity-100 hover:opacity-90 active:opacity-100',
        )}
        {...getReferenceProps()}>
        <span className="sr-only">Open user menu</span>
        <UserAvatar user={currentUser} size="10" />
      </button>
      <FloatingActions
        floating={floating}
        isOpen={floatingActionsOpen}
        onClose={() => setFloatingActionsOpen(false)}
        actions={actions}
        {...getFloatingProps()}
      />
    </>
  );
};

export default AccountDropdown;
