import React, {useCallback} from 'react';

import {PermissionState} from '@capacitor/core';
import {MicrophoneIcon, VideoCameraIcon} from '@heroicons/react/outline';
import {PermissionStatus, PreviewCamera} from '@numbersprotocol/preview-camera';
import Sheet from 'react-modal-sheet';

import {Button} from 'components_sb/buttons';
import {Paragraph, Title} from 'components_sb/typography';

const CameraPermissionSheet = ({
  isOpen,
  setIsOpen,
  permissionsStatus,
  setPermissionStatus,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  permissionsStatus: PermissionStatus;
  setPermissionStatus: (s: PermissionStatus) => void;
}) => {
  const requestPermissions = useCallback(async () => {
    const result = await PreviewCamera.requestPermissions();
    setPermissionStatus(result);

    if (result.camera === 'granted' && result.microphone === 'granted') {
      setIsOpen(false);
    }
  }, [setPermissionStatus, setIsOpen]);

  const buttonForPermissionStatus = useCallback(
    (status: PermissionState) => {
      if (status === 'prompt' || status === 'prompt-with-rationale') {
        return (
          <Button
            size="sm"
            fillWidth={false}
            category="primary"
            label="Grant"
            mode="manual"
            onClick={requestPermissions}
          />
        );
      } else if (status === 'denied') {
        return (
          <Button
            size="sm"
            fillWidth={false}
            category="danger"
            label="Denied"
            mode="manual"
            onClick={() => ''}
          />
        );
      } else if (status === 'granted') {
        return (
          <Button
            size="sm"
            fillWidth={false}
            category="custom"
            customClasses="bg-green-500 text-white"
            label="Granted"
            mode="manual"
            onClick={() => ''}
          />
        );
      }
    },
    [requestPermissions],
  );

  if (!permissionsStatus) {
    return null;
  }

  return (
    <Sheet
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      snapPoints={[0.5, 0]}
      tweenConfig={{ease: 'easeOut', duration: 0.3}}>
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="pb-8 px-4">
            <Title level="h3">Permissions Required</Title>
            <Paragraph>
              The permissions below are required to open the Inspection Camera.
            </Paragraph>

            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <Title level="h6" size="sm">
                  <VideoCameraIcon className="text-neutral w-6 h-6 mr-2 inline-block" />
                  Camera
                </Title>
                {permissionsStatus.camera === 'denied' && (
                  <Paragraph size="xs" secondary>
                    Please enable camera permissions from your phones settings
                    app.
                  </Paragraph>
                )}
                {permissionsStatus.camera === 'granted' && (
                  <Paragraph size="xs" secondary>
                    Camera permissions have been successfully granted.
                  </Paragraph>
                )}
              </div>
              <div>{buttonForPermissionStatus(permissionsStatus.camera)}</div>
            </div>

            <div className="flex justify-between items-center mt-4">
              <div className="flex flex-col">
                <Title level="h6" size="sm">
                  <MicrophoneIcon className="text-neutral w-6 h-6 mr-2 inline-block" />
                  Microphone
                </Title>
                {permissionsStatus.microphone === 'denied' && (
                  <Paragraph size="xs" secondary>
                    Please enable microphone permissions from your phones
                    settings app.
                  </Paragraph>
                )}
                {permissionsStatus.microphone === 'granted' && (
                  <Paragraph size="xs" secondary>
                    Microphone permissions have been successfully granted.
                  </Paragraph>
                )}
              </div>
              <div>
                {buttonForPermissionStatus(permissionsStatus.microphone)}
              </div>
            </div>
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={() => setIsOpen(false)} />
    </Sheet>
  );
};

export default CameraPermissionSheet;
