import {AiOutlineDelete} from '@react-icons/all-files/ai/AiOutlineDelete';
import {BiEdit} from '@react-icons/all-files/bi/BiEdit';
import moment from 'moment';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Document from 'models/properties/Document';
import {AccountRole} from 'models/users/User';
import {RouterLink} from 'router/components';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const DocumentsTable = ({
  documents,
  clickHandler,
  showParent,
  tenancyisMigrated = false,
  queryKey,
}: {
  documents: Document[];
  clickHandler: (document: Document) => void;
  showParent: boolean;
  tenancyisMigrated?: boolean;
  queryKey?: string | (string | object)[];
}) => {
  const {activeAccountRole} = useLocalUserSettings();
  const isLandlord = activeAccountRole === AccountRole.Landlord;

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const queryClient = useQueryClient();

  const confirmDeleteDocument = (doc: Document) => {
    setConfirmationOptions({
      title: 'Delete Document',
      message:
        'Are you sure you want to delete this document? This cannot be undone.',
      color: 'error',
      buttonTitle: 'Delete',
      action: () => deleteDocument(doc),
    });
  };

  const deleteDocument = async (doc: Document) => {
    const result = await doc.destroy();

    if (result && queryKey && !doc.isPersisted) {
      toast.success('Document has been deleted.');
      queryClient.invalidateQueries(queryKey);
    }
  };

  const renderActions = (doc: Document) => {
    const actions = [];
    if (doc.documentableType === 'Property') {
      actions.push('edit');

      if (doc.documentType !== 'healthy_homes_report') {
        actions.push('delete');
      }
    } else if (doc.documentableType === 'Tenancy') {
      if (tenancyisMigrated) {
        actions.push('edit');
        actions.push('delete');
      } else {
        if (doc.documentType === 'other') {
          actions.push('edit');
          actions.push('delete');
        }
      }
    }

    return (
      <div className="flex justify-start space-x-2">
        {actions.includes('edit') && (
          <RouterLink href={`documents/${doc.id}/edit`}>
            <BiEdit className="w-5 h-5 pointer-events-none" />
          </RouterLink>
        )}
        {actions.includes('delete') && (
          <a
            className="cursor-pointer"
            onClick={() => confirmDeleteDocument(doc)}>
            <AiOutlineDelete className="w-5 h-5 pointer-events-none" />
          </a>
        )}
      </div>
    );
  };

  const handleClick = (event: any, document: Document) => {
    if (event.target.tagName === 'TD') {
      clickHandler(document);
    }
  };

  return (
    <div>
      <div className="overflow-x-auto hidden lg:tw-block">
        <table className="table w-full">
          <thead className="thead-light">
            <tr>
              <th>Document Type</th>
              <th>Date Added</th>
              {showParent && <th>Type</th>}
              {isLandlord && <th>Private</th>}
              {showParent && isLandlord && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {documents.map((document) => (
              <tr
                key={document.id}
                onClick={(e) => handleClick(e, document)}
                className="hover cursor-pointer">
                <td>{document.name}</td>
                <td>{moment(document.createdAt).format(DATE_FORMAT)}</td>
                {showParent && <td>{document.documentableType}</td>}
                {isLandlord && <td>{document.private ? 'Yes' : 'No'}</td>}
                {showParent && isLandlord && <td>{renderActions(document)}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="tw-block lg:hidden">
        <ul className="flex flex-col gap-2">
          {documents.map((document) => (
            <MobileListItem
              key={document.id}
              onClick={() => clickHandler(document)}
              link={document.document}
              title={
                document.name && document.name.length > 0
                  ? document.name
                  : titleize(removeUnderscores(document.documentType))
              }
              subtitle={
                <>
                  <span>{moment(document.createdAt).format(DATE_FORMAT)}</span>
                  {showParent && (
                    <>
                      <br />
                      <span>{document.documentableType}</span>
                    </>
                  )}
                  {isLandlord && document.private && (
                    <>
                      <br />
                      <strong>Private</strong>
                    </>
                  )}
                </>
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DocumentsTable;
