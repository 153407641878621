import {Fragment, FunctionComponent, MouseEventHandler, ReactNode} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';
import {LinkProps} from 'framework7-react/components/link';

import {RouterLink} from 'router/components';

interface BaseStat {
  icon: IconType;
  title: string;
  value: number | string | undefined;
  description: string;
}

interface LinkStat extends BaseStat {
  href: string;
  linkProps?: LinkProps;
}

const statIsLink = (stat: Stat): stat is LinkStat => 'href' in stat;

interface ButtonStat extends BaseStat {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

const statIsButton = (stat: Stat): stat is ButtonStat => 'onClick' in stat;

type Stat = BaseStat | LinkStat | ButtonStat;

export interface StatsProps {
  stats: Stat[];
  small?: boolean;
  noMargin?: boolean;
  interactive?: boolean;
}

interface StatItemProps {
  stat: Stat;
  small: boolean;
}

interface ItemWrapperProps {
  stat: Stat;
  interactive: boolean;
  children: ReactNode;
}

/**
 * Conditionally wraps the stats item in a link or button appropriate props have
 * been provided, and the current device is not mobile, since the hover effect is
 * not possible on mobile to indicate that it is a link which can interfere with
 * interactions.
 */
const ItemWrapper: FunctionComponent<ItemWrapperProps> = ({
  stat,
  interactive,
  children,
}) => {
  if (interactive) {
    if (statIsButton(stat)) {
      return (
        <button onClick={stat.onClick} className="group flex flex-col">
          {children}
        </button>
      );
    } else if (statIsLink(stat)) {
      return (
        <RouterLink
          href={stat.href}
          className="group flex flex-col"
          {...stat.linkProps}>
          {children}
        </RouterLink>
      );
    }
  }
  return <>{children}</>;
};

const StatListItem: FunctionComponent<StatItemProps> = ({
  stat: {icon: Icon, title, value},
  small,
}) => (
  <div
    className={clsx(
      'select-none',
      'flex-1',
      'flex flex-row items-center',
      small ? 'gap-x-2' : 'gap-x-3',
    )}>
    <Icon
      className={clsx(
        'flex-shrink-0',
        'transition-all duration-300',
        'text-brand-500',
        'text-opacity-70 group-hover:text-opacity-100',
        small ? 'w-5 h-5' : 'w-6 h-6',
      )}
    />
    <div
      className={clsx(
        'w-full',
        'text-brand-850',
        'whitespace-nowrap',
        'flex flex-row items-center gap-x-4',
        small ? 'text-sm' : 'text-base',
      )}>
      <div
        className={clsx(
          'transition-all duration-300',
          'text-brand-850 group-hover:text-brand-500',
          'text-opacity-70 group-hover:text-opacity-100',
        )}>
        {title}
      </div>
      <div className="flex-1 h-0.5 bg-brand-50"></div>
      <div className="font-medium">
        {value !== undefined ? value : <span className="opacity-30">N/A</span>}
      </div>
    </div>
  </div>
);

const StatGridItem: FunctionComponent<StatItemProps> = ({
  stat: {icon: Icon, title, value, description},
  small,
}) => (
  <div
    className={clsx(
      'select-none',
      'flex-1',
      'flex flex-col',
      'items-center',
      'transition-colors duration-300',
      'bg-brand-50 group-hover:bg-brand-75',
      small
        ? clsx('gap-y-1', 'px-2 sm:px-3', 'py-2 sm:py-3')
        : clsx('gap-y-2', 'px-4 sm:px-6', 'py-3 sm:py-4'),
    )}>
    <div className={clsx('flex flex-row items-center', 'gap-x-1 sm:gap-x-2')}>
      {/* Icon */}
      <Icon
        className={clsx(
          'text-brand-500',
          'flex-shrink-0',
          small ? 'w-5 h-5' : clsx('w-5 sm:w-6', 'h-5 sm:h-6'),
        )}
      />
      {/* Title */}
      <div
        className={clsx(
          'transition-all duration-300',
          'text-brand-850 group-hover:text-brand-500',
          'text-opacity-70 group-hover:text-opacity-100',
          'font-medium',
          small ? 'text-xs md:text-sm' : 'text-sm md:text-base',
        )}>
        {title}
      </div>
    </div>
    <div
      className={clsx(
        'flex-1 flex flex-col items-center justify-evenly',
        small ? 'gap-y-1' : 'gap-y-2',
      )}>
      {/* Value */}
      <div
        className={clsx(
          'text-brand-850',
          'font-semibold',
          small ? 'text-lg md:text-xl' : 'text-xl sm:text-2xl',
        )}>
        {value !== undefined ? value : <span className="opacity-30">N/A</span>}
      </div>
      {/* Description */}
      {value !== undefined && (
        <div
          className={clsx(
            'text-brand-850 text-opacity-50',
            'font-normal',
            small ? 'text-xxs sm:text-xs' : 'text-xs md:text-sm',
          )}>
          {description ?? <>&nbsp;</>}
        </div>
      )}
    </div>
  </div>
);

const Stats: FunctionComponent<StatsProps> = ({
  stats,
  small = false,
  noMargin = false,
  interactive = true,
}) => {
  return (
    <div className={clsx('@container', 'flex-1', !noMargin && 'mb-8')}>
      {/* Grid layout */}
      <div
        className={clsx(
          'hidden @sm:grid',
          'gap-0.5 bg-brand-75 rounded-xl overflow-hidden',
          small
            ? clsx('grid-cols-1', '@md:grid-cols-2', '@4xl:grid-cols-4')
            : clsx('grid-cols-1', '@xl:grid-cols-2', '@5xl:grid-cols-4'),
        )}>
        {stats.map((stat) => (
          <ItemWrapper key={stat.title} stat={stat} interactive={interactive}>
            <StatGridItem stat={stat} small={small} />
          </ItemWrapper>
        ))}
      </div>
      {/* Icons list layout */}
      <div className={clsx('flex @sm:hidden', 'flex-col gap-y-2')}>
        {stats.map((stat) => (
          <ItemWrapper key={stat.title} stat={stat} interactive={interactive}>
            <StatListItem stat={stat} small={small} />
          </ItemWrapper>
        ))}
      </div>
    </div>
  );
};

export default Stats;
