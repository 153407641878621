import {Router} from 'framework7/types';

import DocumentPreviewPage from 'pages/shared/documents/DocumentPreviewPage';
import resolveRoute from 'router/utils/route-resolver';

const routes: Router.RouteParameters[] = [
  {
    path: '/document-previews',
    id: 'DocumentPreviews',
    async: resolveRoute({
      component: DocumentPreviewPage,
    }),
  },
];

export default routes;
