import React, {useState} from 'react';

import _ from 'lodash';

import Note from 'components/inspection/notes/Note';
import {Lightbox} from 'components_sb/layout';
import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';

const NoteList = ({
  inspection,
  item,
}: {
  inspection: Inspection;
  item: InspectionItem;
}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const attachments = item.inspectionItemAttachments.filter(
    (a) => a.notes && a.notes.length > 0,
  );

  const landlordAttachments = attachments.filter(
    (a) => a.userId === inspection?.tenancy.property.landlordId,
  );

  const tenantAttachments = attachments.filter(
    (a) => a.userId !== inspection?.tenancy.property.landlordId,
  );

  const handleClick = (attachment: InspectionItemAttachment) => {
    const index = attachments.findIndex((a) => a.id === attachment.id);
    setSelectedIndex(index + 1);
    setLightboxOpen(!lightboxOpen);
  };

  if (attachments.length > 0) {
    if (inspection.doneBy === 'landlord') {
      const groupedTenantAttachments = _.groupBy(
        tenantAttachments,
        (a) => a.user.name,
      );

      return (
        <div>
          {landlordAttachments.length > 0 && (
            <div className="mt-2">
              {landlordAttachments.map((attach) => (
                <Note
                  key={attach.id}
                  attachment={attach}
                  onClick={() => handleClick(attach)}
                />
              ))}
            </div>
          )}

          {tenantAttachments.length > 0 && (
            <div className="mt-2">
              {Object.entries(groupedTenantAttachments).map(
                ([name, attachmentGroup]) => (
                  <div key={name} className="mt-2">
                    <strong>Added by {name}</strong>

                    {attachmentGroup.map((attach) => (
                      <Note
                        key={attach.id}
                        attachment={attach}
                        onClick={() => handleClick(attach)}
                      />
                    ))}
                  </div>
                ),
              )}
            </div>
          )}

          <Lightbox
            toggler={lightboxOpen}
            sources={attachments.map((a) => a.attachment)}
            thumbs={attachments.map((a) => a.attachmentThumbnail)}
            slide={selectedIndex}
            captions={attachments.map((a) => (
              <div key={a.id} className="flex flex-col">
                <h5 className="text-white text-lg">
                  {item.room} - {item.name}
                </h5>
                <p className="text-white text-md">{a.notes}</p>
              </div>
            ))}
          />
        </div>
      );
    } else {
      return (
        <div>
          {tenantAttachments.length > 0 && (
            <div className="mt-2">
              {tenantAttachments.map((attach) => (
                <Note
                  key={attach.id}
                  attachment={attach}
                  onClick={() => handleClick(attach)}
                />
              ))}
            </div>
          )}

          {landlordAttachments.length > 0 && (
            <div className="mt-2">
              <strong>Added by {landlordAttachments[0].user.name}</strong>
              {landlordAttachments.map((attach) => (
                <Note
                  key={attach.id}
                  attachment={attach}
                  onClick={() => handleClick(attach)}
                />
              ))}
            </div>
          )}

          <Lightbox
            toggler={lightboxOpen}
            sources={attachments.map((a) => a.attachment)}
            thumbs={attachments.map((a) => a.attachmentThumbnail)}
            slide={selectedIndex}
            captions={attachments.map((a) => (
              <div key={a.id} className="flex flex-col">
                <h5 className="text-white text-lg">
                  {item.room} - {item.name}
                </h5>
                <p className="text-white text-md">{a.notes}</p>
              </div>
            ))}
          />
        </div>
      );
    }
  } else {
    return null;
  }
};

export default NoteList;
