import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  useFormik,
  FormikConfig,
  FormikValues,
} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {AutocompleteField} from 'components/forms_fields';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {TextField} from 'components_sb/input';
import {API_URL} from 'globals/app-globals';
import useScroll from 'hooks/useScroll';
import User from 'models/users/User';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  address: string;
  addressMeta: object;
  phoneNumber: string;
  tradingName?: string;
  externalLandlordId?: string | null;
};

const PersonalProfileStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartLandlordDetailsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const {authCookies, currentUser} = useAuth();

  /**
   * TODO: Wait for landlord profile to load before allowing the form to be edited,
   * so that we don't get blank fields initially until the profile loads.
   */
  const {data: landlordProfile} = useQuery(
    'landlord-profile-checklist',
    async () => {
      return (await User.includes(['landlord_profile']).find(currentUser.id))
        .data.landlordProfile;
    },
  );

  const queryAddresses = async (str: string) => {
    if (str.length <= 2) {
      return [];
    }

    const url = `${API_URL}/addresses.json?query=${str}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
      });
      const data = await response.json();
      const places = data.addresses;
      return places;
    } catch (e) {
      return [];
    }
  };

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the landlord profile.
       */
      const profile = landlordProfile;
      const profileVals = {...formValues};
      delete profileVals.tradingName;
      profile.assignAttributes(profileVals);

      /**
       * Save the changes to the landlord profile.
       */
      if (!(await saveResource(profile))) {
        setSubmitting(false);
        return;
      }

      property.lastOnboardingStepCompleted = 'new_personal_profile';
      property.tradingName = formValues.tradingName;
      property.setProfileStepAsCompleted('personal_profile');

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteLandlordDetailsStep,
        {
          propertyId: property.id,
        },
      );

      actions.setSubmitting(false);
      setSubmitting(false);
    },
    [landlordProfile, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      enableReinitialize: true,
      initialValues: {
        address: landlordProfile?.address || '',
        addressMeta: landlordProfile?.addressMeta || null,
        phoneNumber: landlordProfile?.phoneNumber || '',
        tradingName: property.tradingName || '',
        externalLandlordId: landlordProfile?.externalLandlordId || '',
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        address: Yup.string().required().label('Address'),
        phoneNumber: Yup.string().min(7).required().label('Phone Number'),
        tradingName: Yup.string().optional().nullable(),
        externalLandlordId: Yup.string()
          .optional()
          .label('Tenancy Services Landlord Id')
          .min(5)
          .max(7),
      }),
    }),
    [handleSubmit, landlordProfile, property],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  const selectAddress = useCallback(
    async (option: any): Promise<any[]> => {
      if (option) {
        const url = `${API_URL}/addresses/${option['id']}.json`;

        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
          });
          const data = await response.json();

          const ad = data.address;

          form.setFieldValue('addressMeta', ad);
          form.setFieldValue('address', ad.full);
        } catch (e) {
          return [];
        }
      }
    },
    [form, authCookies],
  );

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Add your personal details"
      subtitle="These will be used for lease and bond documents">
      <AutocompleteField
        labelProps={{
          title: 'Landlord Postal Address',
          size: 'xl',
        }}
        name="address"
        formik={form}
        placeholder="E.g. 123 Example Street, Suburb, City"
        filter={queryAddresses}
        select={(option: any) => selectAddress(option)}
        value={form.values.address}
      />
      <div className="w-full flex">
        <TextField
          name="phoneNumber"
          label="Phone Number"
          labelSize="xl"
          size="base"
          mode="formik"
          form={form}
          placeholder="E.g. 027 123 4567"
          required
        />
      </div>

      <div className="w-full flex">
        <TextField
          name="tradingName"
          label="Trading Name"
          labelSize="xl"
          size="base"
          mode="formik"
          form={form}
          placeholder="E.g. ABC Investments Ltd"
          description="If you have a trading name, that you would like used on the lease and bond documents, enter it here."
        />
      </div>

      <div className="w-full flex">
        <TextField
          name="externalLandlordId"
          label="Tenancy Services Landlord ID"
          labelSize="xl"
          size="base"
          mode="formik"
          form={form}
          placeholder="If you don't know, leave blank"
        />
      </div>
    </StepContainer>
  );
};

export default PersonalProfileStep;
