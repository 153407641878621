import {Router} from 'framework7/types';

import EditRentalApplicationPage from 'pages/renter/rental_applications/EditRentalApplicationPage';
import NewRentalApplicationPage from 'pages/renter/rental_applications/NewRentalApplicationPage';
import RentalApplicationDetailPage from 'pages/renter/rental_applications/RentalApplicationDetailPage';
import RentalApplicationIndexPage from 'pages/renter/rental_applications/RentalApplicationIndexPage';
import RentalApplicationListingsPage from 'pages/renter/rental_applications/RentalApplicationListingsPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {LOGGED_IN, IS_RENTER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/rental-applications',
    id: 'RentalApplications',
    async: resolveRoute({
      conditions: [LOGGED_IN, IS_RENTER],
      component: RentalApplicationIndexPage,
    }),
    routes: [
      {
        path: 'new',
        id: 'NewRentalApplication',
        async: resolveRoute({
          conditions: [],
          component: NewRentalApplicationPage,
        }),
      },
      {
        path: ':rentalApplicationId',
        id: 'RentalApplication',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_RENTER],
          component: RentalApplicationDetailPage,
        }),
        routes: [
          {
            path: 'edit',
            id: 'EditRentalApplication',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: EditRentalApplicationPage,
            }),
          },
          {
            path: 'listings',
            id: 'RentalApplicationListings',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: RentalApplicationListingsPage,
            }),
          },
        ],
      },
    ],
  },
];

export default routes;
