import {useCallback, useMemo} from 'react';

import {useAutoAnimate} from '@formkit/auto-animate/react';
import clsx from 'clsx';
import {HiOutlineCreditCard, HiPlus} from 'react-icons/hi';

import AddCreditCardModal from 'components/payment/AddCreditCardModal';
import {InlineError, SpinningLoader} from 'components_sb/feedback';
import {Card, Modal} from 'components_sb/layout';
import {useCreditCards} from 'providers/CreditCardsProvider';

import PinCardSummary from './PinCardSummary';
// import SmartRentSummary from './SmartRentSummary';

const {useModal} = Modal.Imperative;

const CreditCardsSection = () => {
  const openModal = useModal();

  /**
   * Handle adding a new credit/debit card.
   */
  const onAddCreditCard = useCallback(
    () => openModal(AddCreditCardModal),
    [openModal],
  );

  const creditCards = useCreditCards();

  const [cardListContainerRef] = useAutoAnimate<HTMLDivElement>();

  return (
    <Card icon={HiOutlineCreditCard} title="Credit/debit cards">
      {creditCards.isLoading && (
        <div className="flex items-center justify-center my-10">
          <SpinningLoader size="base" color="brand" />
        </div>
      )}

      {creditCards.isError && (
        <InlineError error="There was an issue loading your credit/debit cards. Please try again later or contact us for support." />
      )}

      {creditCards.isSuccess && (
        <div className="flex flex-col gap-y-4">
          {/* A list of each of the billing methods for the user */}
          <div
            ref={cardListContainerRef}
            className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
            {creditCards.exist &&
              creditCards.all.map((creditCard) =>
                creditCard.provider === 'pin_payments' ? (
                  <PinCardSummary
                    key={creditCard.id}
                    creditCard={creditCard}
                    isDefault={creditCard.isPrimary}
                  />
                ) : (
                  <></> // Unsupported billing method
                ),
              )}

            {/* Add a credit/debit card */}
            <button
              onClick={onAddCreditCard}
              className={clsx(
                'min-h-56',
                'relative flex justify-center items-center md:space-y-0 rounded-xl overflow-hidden p-3 w-full mx-auto bg-white',
                'transition-all duration-300',
                'border-2 border-dashed',
                'border-brand-100 hover:border-brand-300',
                'text-brand-100 hover:text-brand-300',
              )}>
              <div className="flex flex-col gap-y-2 items-center justify-center">
                <HiPlus className="h-6 w-6" />
                <div className="text-lg font-medium">
                  Add a credit/debit card
                </div>
              </div>
            </button>
          </div>
        </div>
      )}
    </Card>
  );
};

export default CreditCardsSection;
