import React from 'react';

import moment from 'moment';
import {MdOutlineLocalPolice} from 'react-icons/md';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const PoliceResultsCard = ({report}: {report: TenantCheckReport}) => {
  // Uncomment to have some example data to test against
  // report.policeDatabaseResults = [
  //   {
  //     title: 'Aaron Rama is a stabber on the lose',
  //     link: 'http://google.com',
  //     snippet:
  //       'Hide your wives, hide your daughters, they stabbing everyone out here',
  //     image_url: 'http://google.com',
  //     date_published: '2023-09-09',
  //   },
  // ];
  if (report.policeDatabaseResultsFetchedAt) {
    return (
      <Card title="NZ Police Database" icon={MdOutlineLocalPolice}>
        {report.policeDatabaseResults.length > 0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Date</TableHeadItem>
              <TableHeadItem>Title</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.policeDatabaseResults.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  {moment(result.date_published).format(DATE_FORMAT)}
                </TableRowItem>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.title}
                  </Hyperlink>
                  <p className="text-xs">{result.snippet}</p>
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no results found for this applicant in the police
            database.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.policeDatabaseResultsFetchedAt).format(
            DATE_TIME_FORMAT,
          )}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="NZ Police Database" icon={MdOutlineLocalPolice}>
        <Paragraph>
          Police database results are currently being collected and will be
          updated on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default PoliceResultsCard;
