import {FunctionComponent, useMemo} from 'react';

import {ChevronRightIcon} from '@heroicons/react/outline';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import useAuth from 'auth/provider/useAuth';
import ConditionalWrapper from 'components/common/ConditionalWrapper';
import {Card} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import Tags, {
  TagColor,
  TagDefinition,
} from 'components_sb/typography/Tags/Tags';
import EnquiryThread from 'models/listings/EnquiryThread';
import {RouterLink} from 'router/components';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

import {ListingEnquiryTitleMode} from '../types';

interface EnquiryThreadListItemProps {
  enquiryThread?: EnquiryThread;
  titleMode: ListingEnquiryTitleMode;
}

const EnquiryThreadListItem: FunctionComponent<EnquiryThreadListItemProps> = ({
  enquiryThread,
  titleMode,
}) => {
  const {currentUser} = useAuth();

  /**
   * Present the card in skeleton mode when an enquiry thread
   * has not been provided.
   */
  const showSkeleton = useMemo(() => !enquiryThread, [enquiryThread]);

  /**
   * Find the most recent message in the enquiry thread.
   */
  const lastMessage = useMemo(
    () =>
      showSkeleton
        ? null
        : enquiryThread.enquiryMessages[
            enquiryThread.enquiryMessages.length - 1
          ] ?? null,
    [showSkeleton, enquiryThread],
  );

  /**
   * Determine if the last message in the enquiry thread was sent by the current user.
   */
  const currentUserSentLastMessage = useMemo<boolean>(
    () => !!lastMessage && lastMessage.email === currentUser.email,
    [lastMessage, currentUser],
  );

  const tags = useMemo<TagDefinition[]>(() => {
    return [
      {
        text: 'Test Tag',
        color: TagColor.Red,
      },
      {
        text: 'Test Tag',
        color: TagColor.Green,
      },
      {
        text: 'Test Tag',
        color: TagColor.Blue,
      },
      {
        text: 'Test Tag',
        color: TagColor.Yellow,
      },
      {
        text: 'Test Tag',
        color: TagColor.Grey,
      },
    ];
    // if (showSkeleton) {
    //   return [];
    // } else {
    //   const tagItems: TagDefinition[] = [];
    //   if (enquiryThread?.hasApplied) {
    //     tagItems.push({text: 'Applied', color: TagColor.Blue});
    //   }
    //   if (!!lastMessage && !currentUserSentLastMessage) {
    //     tagItems.push({text: 'Awaiting Your Reply', color: TagColor.Yellow});
    //   }
    //   return tagItems;
    // }
  }, [showSkeleton, enquiryThread, lastMessage, currentUserSentLastMessage]);

  return (
    /**
     * A conditional wrapper is used so that the router link is only
     * applied when not showing the skeleton version of the component.
     */
    <ConditionalWrapper
      condition={!showSkeleton}
      wrapper={(children) => (
        <RouterLink href={enquiryThread.id}>{children}</RouterLink>
      )}>
      <Card
        banner={
          !showSkeleton && enquiryThread?.archived
            ? {
                color: 'gray',
                text: 'Archived',
              }
            : undefined
        }>
        <div className="flex justify-between items-center">
          <div>
            <div className="flex flex-col mb-2 gap-y-2">
              <div className="flex flex-col gap-y-1">
                {tags.length > 0 && (
                  <div className="mb-1">
                    <Tags size="base" emphasis="secondary">
                      {tags}
                    </Tags>
                  </div>
                )}
                {/* TODO: Add photo of either listing or user */}
                {showSkeleton ? (
                  <Skeleton width={120} height={20} borderRadius={24} />
                ) : (
                  <Title level="h3" size="sm" noMargin>
                    {titleMode === ListingEnquiryTitleMode.Listing
                      ? enquiryThread.listing.streetAddress
                      : enquiryThread.name}
                  </Title>
                )}
                <div className="text-brand-500/70 text-sm">
                  {showSkeleton ? (
                    <Skeleton width={140} height={12} borderRadius={24} />
                  ) : titleMode === ListingEnquiryTitleMode.Listing ? (
                    `${enquiryThread.listing.suburb}, ${enquiryThread.listing.city}`
                  ) : (
                    enquiryThread.email
                  )}
                </div>
              </div>
              <div className="text-brand-850/70 flex flex-col text-xs">
                {showSkeleton ? (
                  <>
                    <Skeleton width={180} height={10} borderRadius={24} />
                    <Skeleton width={160} height={10} borderRadius={24} />
                  </>
                ) : (
                  <>
                    <div>
                      Last message{' '}
                      {currentUserSentLastMessage ? 'sent' : 'received'}:
                    </div>
                    <div className="font-normal">
                      {moment(enquiryThread.updatedAt).format(DATE_TIME_FORMAT)}
                    </div>
                  </>
                )}
              </div>
            </div>
            <Paragraph noMargin>
              {showSkeleton ? (
                <>
                  <Skeleton width={280} height={16} borderRadius={24} />
                  <Skeleton width={240} height={16} borderRadius={24} />
                </>
              ) : (
                <>
                  {lastMessage ? (
                    <>
                      <span className="font-medium">
                        {currentUserSentLastMessage
                          ? 'You'
                          : /**
                             * A user may not existing on lastMessage if the enquiry was sent from
                             * the listing page without the user creating an account, therefore we
                             * need to access the name of the other user from the enquiry thread.
                             */
                            enquiryThread.name}
                        :
                      </span>
                      &nbsp;
                      {`"${lastMessage.message}"`}
                    </>
                  ) : (
                    <span className="italic">No messages yet...</span>
                  )}
                </>
              )}
            </Paragraph>
          </div>
          <ChevronRightIcon className="flex-shrink-0 w-6 h-6 text-brand-100" />
        </div>
      </Card>
    </ConditionalWrapper>
  );
};

export default EnquiryThreadListItem;
