import {Router} from 'framework7/types';

import ViewingsIndexPage from 'pages/renter/viewings/ViewingsIndexPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {LOGGED_IN, IS_RENTER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/viewings',
    id: 'Viewings',
    async: resolveRoute({
      conditions: [LOGGED_IN, IS_RENTER],
      component: ViewingsIndexPage,
    }),
  },
];

export default routes;
