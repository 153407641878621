import React from 'react';

import moment from 'moment';
import {IoShareSocialOutline} from 'react-icons/io5';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const SocialsResultsCard = ({report}: {report: TenantCheckReport}) => {
  if (report.socialsResultsFetchedAt) {
    return (
      <Card title="Social Profiles" icon={IoShareSocialOutline}>
        {report.socialsResults.facebook.length +
          report.socialsResults.linkedin.length >
        0 ? (
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Name</TableHeadItem>
              <TableHeadItem>Site</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {report.socialsResults.facebook.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.title}
                  </Hyperlink>
                  <p className="text-xs">{result.snippet}</p>
                </TableRowItem>
                <TableRowItem>Facebook</TableRowItem>
              </TableRow>
            ))}

            {report.socialsResults.linkedin.map((result, index) => (
              <TableRow key={index}>
                <TableRowItem>
                  <Hyperlink href={result.link} external openInNewTab>
                    {result.title}
                  </Hyperlink>
                  <p className="text-xs">{result.snippet}</p>
                </TableRowItem>
                <TableRowItem>Linkedin</TableRowItem>
              </TableRow>
            ))}
          </Table>
        ) : (
          <Paragraph>
            There were no social profiles found for this applicant.
          </Paragraph>
        )}

        <Paragraph secondary size="xs">
          Search performed on{' '}
          {moment(report.socialsResultsFetchedAt).format(DATE_TIME_FORMAT)}
        </Paragraph>
      </Card>
    );
  } else {
    return (
      <Card title="Social Profiles" icon={IoShareSocialOutline}>
        <Paragraph>
          Social profiles results are currently being collected and will be
          updated on this page once gathered.
        </Paragraph>
      </Card>
    );
  }
};

export default SocialsResultsCard;
