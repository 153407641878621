import {useCallback, useMemo, useState} from 'react';

import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import {AiOutlineWarning} from '@react-icons/all-files/ai/AiOutlineWarning';
import moment from 'moment';
import {isMobile} from 'react-device-detect';
import {
  HiOutlineChatAlt2,
  HiOutlineDocumentText,
  HiOutlineEye,
  HiOutlineHome,
} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {useInterval} from 'usehooks-ts';

import emptyDashboard from 'assets/img/empty_dataset_images/tenant_dashboard.svg';
import ConfirmEmailAlert from 'components/auth/ConfirmEmailAlert';
import EmptyDataSet from 'components/common/EmptyDataSet';
import CurrentTenancyCard from 'components/property/renter/CurrentTenancyCard';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import RentPayment from 'models/payments/RentPayment';
import {TenancyStatus} from 'models/properties/Tenancy';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import TenancyRequest from 'models/properties/TenancyRequest';
import {Page, RouterLink} from 'router/components';
import useRouter from 'router/hooks/useRouter';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const TenancyIndexPage = () => {
  const queryClient = useQueryClient();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const [inviteActionLoading, setInviteActionLoading] = useState(false);

  const router = useRouter();

  const memberships = useQuery(
    'renter-tenancies',
    async () => {
      const res = await TenancyMembership.select({
        tenancy_memberships: [
          'active',
          'bond_paid',
          'rent_split',
          'created_at',
        ],
        tenancies: [
          'status',
          'bond',
          'total_rent',
          'rental_period',
          'next_inspection_date',
          'start_date',
          'end_date',
          'created_at',
          'is_new',
        ],
        properties: ['street_address', 'suburb', 'city', 'main_image'],
      })
        .includes([
          {tenancy: ['property', 'open_service_requests']},
          'not_paid_rent_payments',
        ])
        .all();
      return res.data;
    },
    {
      retry: 1,
    },
  );

  const tenancyRequests = useQuery(
    'renter-tenancy-requests',
    async () => {
      const res = await TenancyRequest.includes({
        tenancy: {property: 'landlord'},
      })
        .select({
          tenancy_requests: ['accepted', 'created_at'],
          tenancies: [
            'status',
            'start_date',
            'end_date',
            'total_rent',
            'rental_period',
            'bond',
            'is_new',
          ],
          properties: ['street_address', 'suburb', 'city', 'main_image'],
          users: ['name', 'avatar'],
        })
        .all();
      return res.data;
    },
    {retry: 1},
  );

  const tenancyInvites = useQuery(
    'renter-tenancy-invites',
    async () => {
      const res = await TenancyInvite.includes([
        'sending_user',
        {tenancy: 'property'},
      ])
        .select({
          tenancies: [
            'status',
            'start_date',
            'end_date',
            'total_rent',
            'rental_period',
            'bond',
            'is_new',
          ],
          properties: ['street_address', 'suburb', 'city', 'main_image'],
          users: ['name', 'avatar'],
        })
        .all();
      return res.data;
    },
    {retry: 1},
  );

  // If there are no memberships, requests or invites
  // We try to refetch every 3 seconds.
  // This handles the case when a tenancy is being confirmed
  // and no membership is present
  // and someone goes back to the dashboard. It should load the membership when its ready.
  useInterval(
    () => {
      memberships.refetch();
    },
    memberships.data &&
      memberships.data.length == 0 &&
      tenancyRequests.data &&
      tenancyRequests.data.length === 0 &&
      tenancyInvites.data &&
      tenancyInvites.data.length === 0
      ? 3000
      : null,
  );

  const renderTenancyRequestCards = () => {
    if (tenancyRequests.data && tenancyRequests.data.length > 0) {
      return (
        <div>
          <Title level="h2" size="md">
            Tenancy Requests
          </Title>
          <div className="flex flex-col gap-y-8">
            {tenancyRequests.data.map((request) => {
              const tenancy = request.tenancy;
              const property = tenancy.property;
              const landlord = property.landlord;

              let period: string;
              if (tenancy.rentalPeriod === 'Monthly') {
                period = 'month';
              } else if (tenancy.rentalPeriod === 'Fortnightly') {
                period = 'fortnight';
              } else {
                period = 'week';
              }

              return (
                <Card className="mt-4" key={request.id}>
                  <div className="alert items-start">
                    <div className="flex-1">
                      <UserAvatar user={landlord} size="8" />
                      <label className="ml-2">
                        <h4 className="font-semibold">{landlord.name}</h4>
                        <p className="text-sm text-base-content text-opacity-60">
                          Sent {moment(request.createdAt).fromNow()}
                        </p>
                      </label>
                    </div>
                  </div>

                  {tenancy.isNew ? (
                    <>
                      <Paragraph>
                        {landlord.name} has invited you to start a tenancy at{' '}
                        <strong>{property.streetAddress}</strong>,{' '}
                        {property.suburb}, {property.city}, for{' '}
                        <strong>{toCurrency(tenancy.totalRent)}</strong>/
                        {period} (total) with a bond of{' '}
                        <strong>{toCurrency(tenancy.bond)}</strong> (total).
                      </Paragraph>
                      <Paragraph>
                        This tenancy would begin on
                        <strong>
                          {' ' + moment(tenancy.startDate).format(DATE_FORMAT)}
                        </strong>
                        {tenancy.endDate
                          ? ' and would end on '
                          : ' and would be ongoing'}
                        {tenancy.endDate ? (
                          <strong>
                            {moment(tenancy.endDate).format(DATE_FORMAT)}
                          </strong>
                        ) : null}
                        .
                      </Paragraph>
                      {request.accepted && (
                        <p className="text-success mb-2">
                          You have accepted this request, we are currently
                          waiting for other tenants to sign.
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <Paragraph>
                        {landlord.name} has invited you to migrate your tenancy
                        at <strong>{property.streetAddress}</strong>,{' '}
                        {property.suburb}, {property.city} to Keyhook.
                      </Paragraph>
                      {request.accepted && (
                        <>
                          <p className="text-green-600 font-medium mb-2">
                            You have accepted this invite.
                          </p>
                          {tenancy.startDate ? (
                            <Paragraph>
                              {`Your landlord has set a commencement date of
                              ${moment(tenancy.startDate).format(
                                'DD MMM YYYY',
                              )}.`}
                            </Paragraph>
                          ) : (
                            <Paragraph>{`Your landlord has not yet set a commencement date.`}</Paragraph>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <RouterLink href={`/tenancy-requests/${request.id}`}>
                    <button className="btn btn-neutral btn-block">
                      {request.accepted ? 'See Details' : 'NEXT'}
                    </button>
                  </RouterLink>
                </Card>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  const confirmRejectTenancyInvite = (invite: TenancyInvite) => {
    setConfirmationOptions({
      title: 'Reject Tenancy Invite',
      message: 'Are you sure you want to reject the invite for this tenancy?',
      buttonTitle: 'Reject',
      action: () => rejectTenancyInvite(invite),
      color: 'error',
    });
  };

  const rejectTenancyInvite = async (invite: TenancyInvite) => {
    setInviteActionLoading(true);
    const result = await invite.destroy();
    if (result) {
      toast.success('You have successfully rejected the invitation.');
      queryClient.invalidateQueries('renter-tenancy-invites');
    }
    setInviteActionLoading(false);
  };

  const acceptInvite = async (invite: TenancyInvite) => {
    setInviteActionLoading(true);
    invite.accepted = true;

    const result = await invite.save();
    if (result) {
      toast.success('You have successfully accepted the invitation!');
      queryClient.invalidateQueries('renter-tenancy-invites');
    } else {
      toast.error('There was an issue accepting the invitation.');
    }
    setInviteActionLoading(false);
  };

  const renderTenancyInviteCards = () => {
    if (tenancyInvites.data && tenancyInvites.data.length > 0) {
      return (
        <div>
          <Title level="h2" size="md">
            Tenancy Invites
          </Title>
          <div className="flex flex-col gap-y-8">
            {tenancyInvites.data.map((invite) => {
              const tenancy = invite.tenancy;
              const property = tenancy.property;
              const user = invite.sendingUser;

              return (
                <Card className="mt-4" key={invite.id}>
                  <div className="alert">
                    <div className="flex-1">
                      <UserAvatar user={user} size="8" />
                      <label className="ml-2">
                        <h4 className="font-semibold">{user.name}</h4>
                        <p className="text-sm text-base-content text-opacity-60">
                          Sent {moment(invite.createdAt).fromNow()}
                        </p>
                      </label>
                    </div>
                  </div>

                  <Paragraph>
                    {user.name} has invited you to join the tenancy at{' '}
                    <strong>{property.streetAddress}</strong>, {property.suburb}
                    , {property.city} on Keyhook.
                  </Paragraph>

                  <Paragraph>
                    Accepting this invite will give you access to the tenancy,
                    allowing you to create maintenance requests, see documents,
                    contact your landlord and more.
                  </Paragraph>

                  {invite.accepted && (
                    <p className="text-success">
                      You have accepted this invite, you will be added to the
                      tenancy momentarily.
                    </p>
                  )}

                  {!invite.accepted && (
                    <div className="flex justify-start space-x-4">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => acceptInvite(invite)}
                          disabled={inviteActionLoading}>
                          Accept Invite
                        </button>
                      </div>

                      <div>
                        <button
                          type="button"
                          className="btn btn-neutral"
                          onClick={() => confirmRejectTenancyInvite(invite)}
                          disabled={inviteActionLoading}>
                          Reject Invite
                        </button>
                      </div>
                    </div>
                  )}
                </Card>
              );
            })}
          </div>
        </div>
      );
    }
    return null;
  };

  const PastTenanciesTable = () => {
    const pastStatuses: TenancyStatus[] = [TenancyStatus.Ended];

    const pastTenancies = memberships.data.filter((membership) =>
      pastStatuses.includes(membership.tenancy.status),
    );

    if (pastTenancies && pastTenancies.length > 0) {
      return (
        <div className="my-2">
          <Title level="h2" size="md">
            Past Tenancies
          </Title>
          <div className="flex flex-col gap-y-8">
            {pastTenancies.map((membership, index) => (
              <CurrentTenancyCard
                index={index}
                key={membership.id}
                membership={membership}
              />
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const UpcomingTenanciesTable = () => {
    const upcomingTenancies = memberships.data.filter(
      (membership) => membership.tenancy.isUpcoming,
    );

    if (upcomingTenancies && upcomingTenancies.length > 0) {
      return (
        <div className="my-2">
          <Title level="h2" size="md">
            Upcoming Tenancies
          </Title>
          <div className="flex flex-col gap-y-8">
            {upcomingTenancies.map((membership, index) => (
              <CurrentTenancyCard
                index={index}
                key={membership.id}
                membership={membership}
              />
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const CurrentTenanciesTable = () => {
    const currentTenancies = memberships.data.filter(
      (membership) => membership.tenancy?.isActive,
    );

    if (currentTenancies && currentTenancies.length > 0) {
      return (
        <div className="my-2">
          <Title level="h2" size="md">
            Current Tenancies
          </Title>
          <div className="flex flex-col gap-y-8">
            {currentTenancies.map((membership, index) => (
              <CurrentTenancyCard
                index={index}
                key={membership.id}
                membership={membership}
              />
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const lateRentPaymentsAlert = useCallback(() => {
    if (memberships.data) {
      let count = 0;
      memberships.data.forEach((m) => {
        count += m.notPaidRentPayments.filter(
          (rp) => !rp.isRentInAdvance,
        ).length;
      });

      if (count > 0) {
        return (
          <div>
            <div className="alert alert-error shadow-lg text-white mb-4">
              <div>
                <AiOutlineWarning className="w-6 h-6" />
                <span>
                  You have <strong>{count} unpaid rent payment(s)</strong>. If
                  you think this is a mistake,{' '}
                  <a
                    className="text-white underline font-semibold"
                    href="https://help.keyhook.com/financials/why-does-keyhook-tell-me-my-rent-is-overdue-after-i-ve-paid-it"
                    target="_blank">
                    click here
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    return null;
  }, [memberships.data]);

  const rentInAdvanceAlerts = useCallback(() => {
    if (memberships.data) {
      const rps = [] as RentPayment[];

      memberships.data.forEach((m) => {
        m.notPaidRentPayments.forEach((rp) => {
          if (rp.isRentInAdvance) {
            rps.push(rp);
          }
        });
      });

      if (rps.length > 0) {
        return (
          <>
            {rps.map((rp) => (
              <div
                className="alert bg-blue-400 shadow-lg text-white mb-4"
                key={rp.id}>
                <div>
                  <AiOutlineInfoCircle className="w-6 h-6" />
                  <span>Rent in advance due: {toCurrency(rp.amountDue)}</span>
                </div>
              </div>
            ))}
          </>
        );
      }
    }

    return null;
  }, [memberships.data]);

  const pageError = useMemo(() => {
    if (memberships.error) {
      return memberships.error;
    } else if (tenancyRequests.error) {
      return tenancyRequests.error;
    } else if (tenancyInvites.error) {
      return tenancyInvites.error;
    }
    return undefined;
  }, [memberships.error, tenancyRequests.error, tenancyInvites.error]);

  const isLoading = useMemo(
    () =>
      memberships.isLoading ||
      tenancyRequests.isLoading ||
      tenancyInvites.isLoading,
    [
      memberships.isLoading,
      tenancyRequests.isLoading,
      tenancyInvites.isLoading,
    ],
  );

  const noTenancies = useMemo(
    () =>
      memberships.data?.length === 0 &&
      tenancyRequests.data?.length === 0 &&
      tenancyInvites.data?.length === 0,
    [memberships.data, tenancyRequests.data, tenancyInvites.data],
  );

  return (
    <Page
      title="Dashboard"
      disableBack
      loading={isLoading}
      error={pageError}
      centred={noTenancies}>
      {() =>
        noTenancies ? (
          <div className="w-full max-w-2xl flex flex-col items-center mx-auto text-center">
            <EmptyDataSet
              title="No active tenancies"
              message={`You're not part of any tenancies yet or a tenancy you have signed is currently being confirmed. Looking for a rental? ${
                isMobile ? 'Tap' : 'Click'
              } below to view the listings on Keyhook, or manage your enquiries/applications.`}
              image={emptyDashboard}
            />
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
              <Button
                label="View listings"
                icon={HiOutlineHome}
                category="primary"
                size="base"
                mode="link"
                linkTo="/listings"
                fillWidth
              />
              <Button
                label="My enquiries"
                icon={HiOutlineChatAlt2}
                category="secondary"
                size="base"
                mode="link"
                linkTo="/enquiries"
                fillWidth
              />
              <Button
                label="My applications"
                icon={HiOutlineDocumentText}
                category="secondary"
                size="base"
                mode="link"
                linkTo="/rental-applications"
                fillWidth
              />
              <Button
                label="My viewings"
                icon={HiOutlineEye}
                category="secondary"
                size="base"
                mode="link"
                linkTo="/viewings"
                fillWidth
              />
            </div>
          </div>
        ) : (
          <>
            {lateRentPaymentsAlert()}
            {rentInAdvanceAlerts()}
            <ConfirmEmailAlert />
            {renderTenancyRequestCards()}
            {renderTenancyInviteCards()}

            <CurrentTenanciesTable />
            <UpcomingTenanciesTable />
            <PastTenanciesTable />
          </>
        )
      }
    </Page>
  );
};

export default TenancyIndexPage;
