import {useQuery} from 'react-query';

import Property from 'models/properties/Property';

interface useBillingActionRequiredQueryProps {
  enabled: boolean;
}

/**
 * Fetch the total number of properties that have credit card set as their
 * payment method for subscription fees.
 */
const useTotalPropertiesWithCardPaymentQuery = ({
  enabled,
}: useBillingActionRequiredQueryProps) =>
  useQuery(
    ['landlord_property_billing_action_required'],
    async () => {
      const response = await Property.where({
        paymentMethodType: 'card',
      })
        .per(200)
        .stats({total: 'count'})
        .first();
      const {count: totalPropertiesWithCardPayment} = response.meta.stats.total;
      return totalPropertiesWithCardPayment;
    },
    {
      enabled,
    },
  );

export default useTotalPropertiesWithCardPaymentQuery;
