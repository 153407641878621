import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {File as CordovaFile} from '@awesome-cordova-plugins/file';
import {
  FileTransfer,
  type FileUploadOptions,
} from '@awesome-cordova-plugins/file-transfer';
import {Capacitor} from '@capacitor/core';
import {Filesystem, Directory} from '@capacitor/filesystem';

import {API_URL} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import InspectionAction from 'models/inspections/InspectionAction';
import InspectionItemAttachment from 'models/inspections/InspectionItemAttachment';
import User from 'models/users/User';
import useInspectionStore, {
  InspectionEventEmitter,
  PendingUploadRow,
} from 'stores/InspectionStore';

import useAuth from '../auth/provider/useAuth';

interface ProcessQueueFunction {
  (inspectionId: string): Promise<void>;
}
interface ProcessItemFunction {
  (
    db: PouchDB.Database<any>,
    record: PouchDB.Core.ExistingDocument<any>,
  ): Promise<void>;
}

interface ContextValue {
  isRunning: boolean;
  processQueue: ProcessQueueFunction;
  processItem: ProcessItemFunction;
}

const Context = createContext<ContextValue>({} as ContextValue);

interface InspectionUploaderProvider {
  (props: {children: ReactNode}): JSX.Element;
}

const getMimeType = (filename: string): string | null => {
  const extension = filename.split('.').pop().toLowerCase();

  let mime = null;
  if (extension === 'jpg' || extension === 'jpeg') {
    mime = 'image/jpeg';
  } else if (extension === 'png') {
    mime = 'image/png';
  } else if (extension === 'mp4') {
    mime = 'video/mp4';
  } else if (extension === 'mov') {
    mime = 'video/mov';
  }

  return mime;
};

export const InspectionUploaderProvider: InspectionUploaderProvider = ({
  children,
}) => {
  const [isRunning, setIsRunning] = useState(false);

  const {currentUser, authCookies} = useAuth();

  const uploadAttachment = useCallback(
    async (item: PendingUploadRow) => {
      const filename = item.data.split('/').pop();
      const mime = getMimeType(filename);

      let size;
      if (item.size) {
        size = item.size;
      } else {
        const directory =
          Capacitor.getPlatform() === 'android'
            ? Directory.Data
            : Directory.Documents;
        const stats = await Filesystem.stat({
          path: filename,
          directory: directory,
        });
        size = stats.size;
      }

      const presignResponse = await fetch(
        `${API_URL}/presigns/inspection_item_attachment.json?inspection_item_id=${item.inspectionItemId}&filename=${filename}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
        },
      );

      if (presignResponse.ok) {
        const presignInfo = (await presignResponse.json()) as PresignResponse;

        const directory =
          Capacitor.getPlatform() === 'android'
            ? CordovaFile.dataDirectory
            : CordovaFile.applicationStorageDirectory + '/Documents/';

        const path = await CordovaFile.resolveLocalFilesystemUrl(
          directory + filename,
        );

        const filePath =
          Capacitor.getPlatform() === 'android'
            ? directory + filename
            : path.toURL();

        const options = {} as FileUploadOptions;
        options.fileKey = 'file';
        options.fileName = filename;
        options.mimeType = mime;
        options.headers = presignInfo.headers as any;
        options.httpMethod = presignInfo.method.toUpperCase();

        const ft = FileTransfer.create();

        const res = await ft.upload(
          filePath,
          encodeURI(presignInfo.url),
          options,
          true,
        );

        if (res.responseCode === 200) {
          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          const uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: size,
              filename: filename,
              mime_type: mime,
            },
          };

          const attach = new InspectionItemAttachment({
            attachment: JSON.stringify(uploadData),
            inspectionItemId: item.inspectionItemId,
          });
          if (item.notes) {
            attach.notes = item.notes;
          }

          const result = await attach.save();
          if (result) {
            attach.user = new User({
              id: currentUser.id,
              name: currentUser.name,
              avatar: currentUser.avatar,
              email: currentUser.email,
            });
            attach.user.isPersisted = true;
            attach.userId = currentUser.id;

            return attach;
          }
        } else {
          console.log(`Presign Failed: ${res.responseCode} ${res.response}`);
        }
      }

      return null;
    },
    [authCookies, currentUser],
  );

  const uploadAction = useCallback(
    async (item: PendingUploadRow) => {
      const filename = item.data.split('/').pop();
      const mime = getMimeType(filename);

      let size;
      if (item.size) {
        size = item.size;
      } else {
        const directory =
          Capacitor.getPlatform() === 'android'
            ? Directory.Data
            : Directory.Documents;
        const stats = await Filesystem.stat({
          path: filename,
          directory: directory,
        });
        size = stats.size;
      }

      const presignResponse = await fetch(
        `${API_URL}/presigns/inspection_action.json?inspection_item_id=${item.inspectionItemId}&filename=${filename}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
        },
      );

      if (presignResponse.ok) {
        const presignInfo = (await presignResponse.json()) as PresignResponse;

        const directory =
          Capacitor.getPlatform() === 'android'
            ? CordovaFile.dataDirectory
            : CordovaFile.applicationStorageDirectory + '/Documents/';

        const path = await CordovaFile.resolveLocalFilesystemUrl(
          directory + filename,
        );

        const filePath =
          Capacitor.getPlatform() === 'android'
            ? directory + filename
            : path.toURL();

        const options = {} as FileUploadOptions;
        options.fileKey = 'file';
        options.fileName = filename;
        options.mimeType = mime;
        options.headers = presignInfo.headers as any;
        options.httpMethod = presignInfo.method.toUpperCase();

        const ft = FileTransfer.create();

        const res = await ft.upload(
          filePath,
          encodeURI(presignInfo.url),
          options,
          true,
        );

        if (res.responseCode === 200) {
          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          const uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: size,
              filename: filename,
              mime_type: mime,
            },
          };

          const action = new InspectionAction({
            attachment: JSON.stringify(uploadData),
            inspectionItemId: item.inspectionItemId,
            propertyId: item.actionParams.propertyId,
            action: item.actionParams.action,
            actionType: item.actionParams.actionType,
            shouldCreateMaintenanceRequest:
              item.actionParams.shouldCreateMaintenanceRequest,
          });

          const result = await action.save();
          if (result) {
            action.user = new User({
              id: currentUser.id,
              name: currentUser.name,
              avatar: currentUser.avatar,
              email: currentUser.email,
            });
            action.user.isPersisted = true;
            action.userId = currentUser.id;

            return action;
          }
        } else {
          console.log(`Presign Failed: ${res.responseCode} ${res.response}`);
        }
      }

      return null;
    },
    [currentUser, authCookies],
  );

  const processItem: ProcessItemFunction = useCallback(
    async (
      db: PouchDB.Database<any>,
      record: PouchDB.Core.ExistingDocument<any>,
    ) => {
      const item = record as any as PendingUploadRow;

      if (!item.data) {
        db.remove(record);
        return;
      }

      if (item.attachmentType === 'normal' || item.attachmentType === 'note') {
        const attachment = await uploadAttachment(item);
        if (attachment) {
          // Update the inspection items store so that it re-renders the UI for rooms etc
          const inspectionItems = useInspectionStore.getState().inspectionItems;
          const setInspectionItems =
            useInspectionStore.getState().setInspectionItems;

          const foundItem = inspectionItems?.find(
            (i) => i.id === item.inspectionItemId,
          );
          if (item) {
            foundItem.inspectionItemAttachments.push(attachment);

            setInspectionItems(inspectionItems);
          }

          db.remove(record);
        }
      } else if (item.attachmentType === 'action') {
        const action = await uploadAction(item);
        if (action) {
          // Update the inspection items store so that it re-renders the UI for rooms etc
          const inspectionItems = useInspectionStore.getState().inspectionItems;
          const setInspectionItems =
            useInspectionStore.getState().setInspectionItems;

          const foundItem = inspectionItems?.find(
            (i) => i.id === item.inspectionItemId,
          );
          if (action) {
            foundItem.inspectionActions.push(action);

            setInspectionItems(inspectionItems);
          }

          db.remove(record);
        }
      }
    },
    [uploadAction, uploadAttachment],
  );

  const processQueue = useCallback(
    async (inspectionId: string) => {
      setIsRunning(true);

      const inspectionStore = useInspectionStore.getState();
      const db = inspectionStore.database;

      await db.createIndex({
        index: {
          fields: ['inspectionId'],
        },
      });

      const records = await db.find({
        selector: {
          inspectionId,
        },
      });

      for (const record of records.docs) {
        try {
          await processItem(db, record);
          InspectionEventEmitter.emit('INSPECTION_UPDATED', {
            inspectionId: inspectionId,
          });
        } catch (e) {
          console.log(e);
          continue;
        }
      }

      setIsRunning(false);
    },
    [processItem],
  );

  const value = useMemo(
    () => ({
      isRunning,
      processQueue,
      processItem,
    }),
    [isRunning, processQueue, processItem],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useInspectionUploader = () => {
  return useContext(Context);
};
