import {useQuery} from 'react-query';

import {ResultMessage} from 'components_sb/feedback';
import Listing from 'models/listings/Listing';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

import PrivateViewingForm from './shared/PrivateViewingForm';

const NewPrivateViewingPage = () => {
  /**
   * Get the public listing ID from the URL.
   */
  const {
    params: {listingPublicId},
  } = useRoute();

  /**
   * We need the private ID of the listing to create a private viewing, and
   * we only have the public ID from the URL. So we need to fetch the listing
   * first to obtain the private ID to then provide to the form.
   */
  const {
    data: listing,
    isLoading: isLoadingListing,
    isError: errorLoadingListing,
    isSuccess: successfullyLoadedListing,
  } = useQuery(
    ['listing', listingPublicId],
    async () => (await Listing.find(listingPublicId)).data,
  );

  return (
    <Page title="Schedule Private Viewing" loading={isLoadingListing}>
      {() => (
        <>
          {errorLoadingListing && (
            <ResultMessage type="error">
              Sorry, there was an issue loading the details for this private
              viewing.
            </ResultMessage>
          )}
          {successfullyLoadedListing && (
            <PrivateViewingForm
              mode="new"
              listingPublicId={listingPublicId}
              listingPrivateId={listing.id}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default NewPrivateViewingPage;
