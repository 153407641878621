import {useState} from 'react';

import {Formik, Form} from 'formik';
import type {FormikProps} from 'formik';
import _ from 'lodash';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {
  InputField,
  SelectField,
  SubmitButton,
  TextareaField,
} from 'components/forms_fields';
import {InlineError} from 'components_sb/feedback';
import {Card} from 'components_sb/layout';
import {API_URL, TARGET_ENV} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import {Navigate, Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';

interface UploadedItem {
  attachment: any;
}

const EditServiceRequestPage = () => {
  const {
    params: {serviceRequestId},
  } = useRoute();

  const {
    isLoading,
    data: serviceRequest,
    error,
  } = useQuery(
    `landlord-edit-service-request-${serviceRequestId}`,
    async () => {
      const request = await ServiceRequest.includes([
        'service_request_attachments',
        'tenancy',
      ]).find(serviceRequestId);

      return request.data;
    },
  );

  const {authCookies, currentUser} = useAuth();

  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);

  const queryClient = useQueryClient();
  const router = useRouter();

  const handleSubmit = async (formData: any, actions: any) => {
    // Doing this manually, because for some reason it doesn't like automatically
    // assigning the attributes with the nested relationship ?!?!?!?
    serviceRequest.title = formData.title;
    serviceRequest.description = formData.description;
    serviceRequest.category = formData.category;
    serviceRequest.renterContactName = formData.renterContactName;
    serviceRequest.renterContactPhoneNumber = formData.renterContactPhoneNumber;
    if (serviceRequest.category === 'Appliance Repair') {
      serviceRequest.applianceMakeAndModel = formData.applianceMakeAndModel;
    }

    serviceRequest.serviceRequestAttachments =
      formData.serviceRequestAttachments.map((attachmentData: any) => {
        const attach = new ServiceRequestAttachment();
        attach.attachment = attachmentData.attachment;
        attach.taken = 'before';
        attach.serviceRequest = serviceRequest;

        return attach;
      });

    const result = await serviceRequest.save({
      with: 'serviceRequestAttachments',
    });

    if (result) {
      await queryClient.invalidateQueries(
        `landlord-service-request-${serviceRequestId}`,
      );
      toast.success('Maintenance request succesfully updated!');
      TrackingService.trackEvent(TrackingService.Event.EditMaintenanceRequest);
      router.goBack();
    } else {
      for (const attachment of serviceRequest.serviceRequestAttachments) {
        console.log(attachment.errors);
      }
      actions.setSubmitting(false);
    }
  };

  const processAttachments = async (
    formik: FormikProps<any>,
    attachments: FileList,
  ) => {
    setIsUploadingAttachment(true);

    const files = Array.from(attachments);

    for (const attachment of files) {
      if (formik.values.serviceRequestAttachments.length >= 5) {
        setIsUploadingAttachment(false);
        return;
      }

      if (TARGET_ENV === 'development') {
        const formdata = new FormData();
        formdata.append('file', attachment);
        const uploadResponse = await fetch(
          `${API_URL}/uploads/service_request_attachment?property_id=${serviceRequest.tenancy.propertyId}`,
          {
            method: 'POST',
            headers: {
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
            body: formdata,
          },
        );

        const uploadData = await uploadResponse.json();
        const values: object[] = formik.values.serviceRequestAttachments;
        values.push({attachment: JSON.stringify(uploadData)});

        formik.setFieldValue('serviceRequestAttachments', values);
      } else {
        const presignResponse = await fetch(
          `${API_URL}/presigns/service_request_attachment.json?property_id=${serviceRequest.tenancy.propertyId}&filename=${attachment.name}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          await fetch(presignInfo.url, {
            method: presignInfo.method,
            headers: presignInfo.headers as any,
            body: attachment,
          });

          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          const uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: attachment.size,
              filename: attachment.name,
              mime_type: attachment.type,
            },
          };

          const values: object[] = formik.values.serviceRequestAttachments;
          values.push({attachment: JSON.stringify(uploadData)});

          formik.setFieldValue('serviceRequestAttachments', values);
        }
      }
    }

    setIsUploadingAttachment(false);
  };

  const removeAttachment = (formik: any, index: number) => {
    const values: object[] = formik.values.serviceRequestAttachments;
    values.splice(index, 1);

    formik.setFieldValue('serviceRequestAttachments', values);
  };

  if (error) {
    <Page title="Edit Maintenance Request" error={error} />;
  } else if (isLoading) {
    return <Page title="Edit Maintenance Request" loading />;
  } else {
    if (
      serviceRequest.userId !== currentUser.id ||
      serviceRequest.status === 'completed'
    ) {
      return (
        <Navigate
          href={`/properties/${serviceRequest.tenancy.propertyId}/service-requests/${serviceRequestId}`}
        />
      );
    }

    const attrs = _.pick(serviceRequest?.attributes, [
      'title',
      'description',
      'category',
      'renterContactName',
      'renterContactPhoneNumber',
      'applianceMakeAndModel',
    ]) as Record<string, any>;

    attrs.serviceRequestAttachments = [] as UploadedItem[];

    return (
      <Page title="Edit Maintenance Request">
        {() => (
          <Card title="Edit Maintenance Request" className="mt-3">
            <Formik
              initialValues={attrs}
              validationSchema={Yup.object().shape({
                title: Yup.string()
                  .required()
                  .min(10)
                  .max(200)
                  .label('Short Description'),
                description: Yup.string()
                  .required()
                  .min(50)
                  .label('Description'),
                category: Yup.string()
                  .required()
                  .oneOf(ServiceRequest.categoryTypes)
                  .label('Category'),
                renterContactName: Yup.string()
                  .required()
                  .min(1)
                  .label('Tenant Name'),
                renterContactPhoneNumber: Yup.string()
                  .required()
                  .min(5)
                  .label('Tenant Phone Number'),
                applianceMakeAndModel: Yup.string()
                  .nullable()
                  .test(
                    'Appliance Repair Chosen',
                    'Add the appliance make and model',
                    function (value) {
                      if (this.parent.category === 'Appliance Repair') {
                        return value && value.length > 0;
                      } else {
                        return true;
                      }
                    },
                  )
                  .label('Appliance Make And Modal'),
                serviceRequestAttachments: Yup.array(
                  Yup.object().shape({
                    attachment: Yup.string()
                      .min(1)
                      .required()
                      .label('Attachment'),
                  }),
                )
                  .label('Attachments')
                  .min(0)
                  .max(10),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={handleSubmit}>
              {(formik) => (
                <Form>
                  <InputField
                    name="title"
                    formik={formik}
                    labelProps={{
                      title: 'Short description',
                    }}
                    placeholder="E.g. Dishwasher not working"
                  />

                  <TextareaField
                    name="description"
                    formik={formik}
                    labelProps={{
                      title: 'Description',
                      size: 'base',
                    }}
                    placeholder="Describe the issue in as much detail as possible."
                    rows={4}
                    className="h-auto w-full"
                  />

                  <SelectField
                    name="category"
                    formik={formik}
                    labelProps={{
                      title: 'Category',
                    }}>
                    <option>Choose a category</option>
                    {ServiceRequest.categoryTypes.map((cat) => (
                      <option
                        key={cat}
                        value={cat}
                        selected={formik.values.category === cat}>
                        {cat}
                      </option>
                    ))}
                  </SelectField>

                  {formik.values.category === 'Appliance Repair' ? (
                    <InputField
                      name="applianceMakeAndModel"
                      formik={formik}
                      labelProps={{
                        title: 'Appliance make and model',
                      }}
                      placeholder="E.g. Bosch H1ZTY"
                    />
                  ) : null}

                  <strong className="tw-block mt-4">
                    Who should we contact to coordinate the maintenance?
                  </strong>

                  <div>
                    <InputField
                      name="renterContactName"
                      formik={formik}
                      labelProps={{
                        title: 'Tenant Name',
                      }}
                      placeholder="E.g. John Smith"
                    />
                    <InputField
                      name="renterContactPhoneNumber"
                      formik={formik}
                      labelProps={{
                        title: 'Tenant mobile number',
                      }}
                      placeholder="E.g. 021 123 4567"
                    />
                  </div>

                  <strong className="tw-block mt-4">Attachments</strong>

                  <p className="mb-4">
                    Attach any extra photos or videos.
                    <small className="tw-block text-secondary">
                      Please note images should be smaller than 5mb and videos
                      smaller than 200mb.
                    </small>
                  </p>

                  {isUploadingAttachment && (
                    <span className="tw-block text-success">
                      Processing attachments, please wait.
                    </span>
                  )}

                  {formik.values.serviceRequestAttachments.map(
                    (obj: any, index: number) => {
                      const json = JSON.parse(obj.attachment);

                      return (
                        <div key={index}>
                          <div className="flex justify-between items-center my-2">
                            <strong>{json.metadata.filename}</strong>
                            <button
                              className="btn btn-error btn-sm"
                              type="button"
                              onClick={() => removeAttachment(formik, index)}>
                              Remove
                            </button>
                          </div>
                          <hr className="bg-gray-200 w-full" />
                        </div>
                      );
                    },
                  )}

                  {formik.values.serviceRequestAttachments.length < 5 && (
                    <div className="mt-2">
                      <input
                        type="file"
                        multiple
                        accept=".png,.jpeg,.jpg,.mp4,.mov"
                        id="attachments-input"
                        onChange={(e) =>
                          processAttachments(formik, e.target.files)
                        }
                        className="hidden"
                      />
                      <button
                        className="btn btn-neutral btn-sm"
                        type="button"
                        onClick={() =>
                          document.getElementById('attachments-input').click()
                        }>
                        Select Files
                      </button>
                    </div>
                  )}

                  <InlineError
                    error={formik.errors.serviceRequestAttachments}
                  />

                  {!isUploadingAttachment && (
                    <SubmitButton
                      className="mt-3"
                      formik={formik}
                      text="Save"
                      submittingText="Updating..."
                    />
                  )}
                </Form>
              )}
            </Formik>
          </Card>
        )}
      </Page>
    );
  }
};

export default EditServiceRequestPage;
