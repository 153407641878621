import {FunctionComponent, ReactNode} from 'react';

import {CookiesProvider} from 'react-cookie';
import {SkeletonTheme, SkeletonThemeProps} from 'react-loading-skeleton';
import {QueryClient, QueryClientProvider} from 'react-query';

import useSocialAuthScripts from 'auth/social/useSocialAuthScripts';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import MobileMenu from 'components/navbar/MobileMenu';
import {Toaster} from 'components_sb/feedback';
import {Modal} from 'components_sb/layout';
import AppLinkProvider from 'providers/AppLinkProvider';
import AppUpdateManager from 'providers/AppUpdateManager';
import CreditCardsProvider from 'providers/CreditCardsProvider';
import CustomerlyProvider from 'providers/CustomerlyProvider';
import DayjsProvider from 'providers/DayjsProvider';
import FullScreenLoader from 'providers/FullScreenLoader';
import GlobalSwipeProvider from 'providers/GlobalSwipeProvider';
import {InspectionUploaderProvider} from 'providers/InspectionUploader';
import KeyboardProvider from 'providers/KeyboardProvider';
import OpenReplayProvider from 'providers/OpenReplayProvider';
import {PushPermissionsProvider} from 'providers/PushPermissions';
import SubscriptionGuardProvider from 'providers/Subscriptions/provider';
import {ToursProvider} from 'providers/ToursProvider';
import RouterProvider from 'router/RouterProvider';
import {PusherServiceProvider} from 'services/PusherService';
import NavItemBadgeCount from 'utilities/nav-links/NavItemBadgeCount';

import {AuthProvider} from './auth';
import {colors} from './themes/keyhook';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const skeletonThemeConfig: SkeletonThemeProps = {
  baseColor: colors.brand[50],
  highlightColor: colors.brand[25],
  borderRadius: 10,
};

interface GlobalProvidersProps {
  children: ReactNode;
}

/**
 * Providers to apply to the app at a global level (i.e. these providers
 * will share context across pages). Note that any providers declared here
 * will NOT have access to the routing context and therefore cannot use
 * the useRoute or useRouter hooks within them.
 */
const GlobalProviders: FunctionComponent<GlobalProvidersProps> = ({
  children,
}) => {
  /**
   * Load the external scripts required for social authentication.
   */
  useSocialAuthScripts();

  return (
    <RouterProvider>
      <GlobalSwipeProvider>
        <KeyboardProvider>
          <DayjsProvider>
            <QueryClientProvider client={queryClient}>
              <CookiesProvider>
                <OpenReplayProvider>
                  {/* CreditCardsProvider must be outside SubscriptionGuardProvider */}
                  <CreditCardsProvider>
                    {/* SubscriptionGuardProvider must be outside AuthProvider */}
                    <SubscriptionGuardProvider>
                      <AuthProvider>
                        <CustomerlyProvider>
                          <SkeletonTheme {...skeletonThemeConfig}>
                            <FullScreenLoader.Provider>
                              <InspectionUploaderProvider>
                                <Modal.Imperative.Provider>
                                  <PushPermissionsProvider>
                                    <PusherServiceProvider>
                                      <NavItemBadgeCount.Provider>
                                        <ToursProvider>
                                          <MobileMenu.Provider>
                                            <AppLinkProvider>
                                              <AppUpdateManager>
                                                {children}
                                              </AppUpdateManager>
                                            </AppLinkProvider>
                                            <ConfirmationModal />
                                          </MobileMenu.Provider>
                                        </ToursProvider>
                                      </NavItemBadgeCount.Provider>
                                    </PusherServiceProvider>
                                  </PushPermissionsProvider>
                                </Modal.Imperative.Provider>
                              </InspectionUploaderProvider>
                            </FullScreenLoader.Provider>
                          </SkeletonTheme>
                        </CustomerlyProvider>
                        <Toaster />
                      </AuthProvider>
                    </SubscriptionGuardProvider>
                  </CreditCardsProvider>
                </OpenReplayProvider>
              </CookiesProvider>
            </QueryClientProvider>
          </DayjsProvider>
        </KeyboardProvider>
      </GlobalSwipeProvider>
    </RouterProvider>
  );
};

export default GlobalProviders;
