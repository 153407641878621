import {FunctionComponent, useCallback, useState} from 'react';

import {DocumentDuplicateIcon} from '@heroicons/react/outline';

import copyToClipboard, {CopyOptions} from 'utilities/copy-to-clipboard';

interface CopyButtonProps {
  copyOptions: CopyOptions;
}

const CopyButton: FunctionComponent<CopyButtonProps> = ({copyOptions}) => {
  const [toolTipOpen, setToolTipOpen] = useState(false);

  const handleClick = useCallback(async () => {
    await copyToClipboard({...copyOptions, showSuccessToast: false});
    setTimeout(() => {
      setToolTipOpen(false);
    }, 2500);
  }, [copyOptions]);

  return (
    <>
      <div
        data-tip="Copied to clipboard!"
        className={`tooltip ${
          toolTipOpen ? 'tooltip-open' : ''
        } tooltip-accent`}>
        <button
          className="btn btn-neutral rounded-l-none"
          type="button"
          onClick={handleClick}>
          <DocumentDuplicateIcon className="w-6 h-6 text-white" />
        </button>
      </div>
    </>
  );
};

export default CopyButton;
