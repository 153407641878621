import {useMemo} from 'react';

import clsx from 'clsx';
import moment from 'moment';
import {Collapse} from 'react-collapse';
import {
  HiOutlineClipboardList,
  HiOutlineCog,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentText,
} from 'react-icons/hi';

import {PropertyCard, Stats} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import Property from 'models/properties/Property';

import '@dotlottie/player-component';

const DemoPropertyCard = ({tourIsActive}: {tourIsActive: boolean}) => {
  const hideDemoPropertyFlag = useCurrentUserFlag('hide_demo_property');

  /**
   * TODO: Do we want this? The demo property will be hidden after adding a property anyway.
   */
  // const onHideDemoProperty = useCallback(
  //   (event: MouseEvent<HTMLElement>) => {
  //     /**
  //      * Prevent parent link from navigating to demo property.
  //      */
  //     event.preventDefault();

  //     /**
  //      * Set the flag.
  //      */
  //     hideDemoPropertyFlag.set(true);
  //   },
  //   [hideDemoPropertyFlag],
  // );

  const demoProperty = useMemo<Property>(
    () =>
      new Property({
        id: -1,
        mainImage:
          'https://storage.googleapis.com/propup-demo-media/property-1.jpeg',
        suburb: 'Thorndon',
        city: 'Wellington',
        streetAddress: '1 Example Street',
      }),
    [],
  );

  return (
    <Collapse
      isOpened={hideDemoPropertyFlag.isReady && !hideDemoPropertyFlag.value}>
      <div className="relative" data-tour="demo-property-item">
        <PropertyCard
          demo
          property={demoProperty}
          href="demo/properties/1"
          tags={['Demo Property']}>
          <Paragraph>
            See Keyhook's main features in action in this demo property.
          </Paragraph>
          <Paragraph secondary>
            This will go away when you add your first property.
          </Paragraph>
          <Stats
            small
            noMargin
            interactive={false}
            stats={[
              {
                icon: HiOutlineCurrencyDollar,
                title: 'Next rent payment due',
                value: moment().add(1, 'week').format('DD MMM'),
                description: moment().add(1, 'week').format('dddd'),
              },
              {
                icon: HiOutlineClipboardList,
                title: 'Next inspection',
                value: 'Today',
                description: '',
              },
              {
                icon: HiOutlineDocumentText,
                title: 'Lease end date',
                value: 123,
                description: 'Days',
              },
              {
                icon: HiOutlineCog,
                title: 'Maintenance requests',
                value: 1,
                description: 'Open',
              },
            ]}
          />
        </PropertyCard>
        {/* Finger tap animation */}
        <div
          id="demo-card-finger-tap"
          // Hide by default
          style={{
            display: 'none',
          }}
          className={clsx(
            `absolute w-full h-full top-0 left-0 pointer-events-none`,
            'flex justify-center items-center',
          )}>
          <div className="h-48 drop-shadow-xl mt-20 md:mt-0">
            <dotlottie-player
              autoplay
              loop
              mode="normal"
              src="/assets/lottie/finger-tap.lottie"
              style={{height: '100%', width: '100%'}}
            />
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default DemoPropertyCard;
