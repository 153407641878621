import {useMemo, useState} from 'react';

import {Browser} from '@capacitor/browser';
import {Formik, Form, type FormikProps} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import ChatableMessages from 'components/chat/ChatableMessages';
import LightboxGallery from 'components/common/LightboxGallery';
import {SubmitButton, TextareaField} from 'components/forms_fields';
import {InlineError} from 'components_sb/feedback';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL, TARGET_ENV} from 'globals/app-globals';
import PresignResponse from 'helpers/PresignResponse';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestAttachment from 'models/service_requests/ServiceRequestAttachment';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';

const ServiceRequestDetailPage = () => {
  const {
    params: {tenancyId, id},
  } = useRoute();

  const [completedReason, setCompletedReason] = useState<
    'Tradesperson' | 'Other'
  >('Tradesperson');

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {isLoading, data, error, isSuccess} = useQuery(
    `renter-service-request-${id}`,
    async () => {
      const request = await ServiceRequest.includes([
        'service_request_attachments',
        'accepted_service_request_quote',
      ]).find(id);

      return request.data;
    },
  );

  const queryClient = useQueryClient();

  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);

  const {authCookies, currentUser} = useAuth();

  const confirmMarmJobAsCompleted = (formData: any, actions: any) => {
    setConfirmationOptions({
      title: 'Mark job as completed',
      message: 'Are you sure you want to mark this job as completed?',
      buttonTitle: 'Confirm',
      action: () => markJobAsCompleted(formData, actions),
      color: 'success',
    });
  };
  const markJobAsCompleted = async (formData: any, actions: any) => {
    const request: ServiceRequest = data;
    request.status = 'completed';
    request.completedReason = formData.completedReason;

    const result = await request?.save();
    if (result) {
      for (const attachmentData of formData.serviceRequestAttachments) {
        const attach = new ServiceRequestAttachment();
        attach.attachment = attachmentData.attachment;
        attach.taken = 'after';
        attach.serviceRequestId = request.id;

        const attachResult = await attach.save();
        if (attachResult) {
          request.serviceRequestAttachments.push(attach);
        }
      }

      queryClient.setQueryData(`renter-service-request-${id}`, request);
      toast.success('This job has been successfully completed!');
    } else {
      console.log(request.errors);
    }

    actions.setSubmitting(false);
  };

  const processAttachments = async (
    formik: FormikProps<any>,
    attachments: FileList,
  ) => {
    setIsUploadingAttachment(true);

    const files = Array.from(attachments);
    const filteredFiles = files.filter((file) => {
      const extension = file.type.split('/').pop();
      if (['jpg', 'jpeg', 'png'].includes(extension)) {
        return file.size <= 3 * 1024 * 1024;
      } else if (['mp4', 'mov'].includes('extension')) {
        return file.size <= 200 * 1024 * 1024;
      } else {
        return false;
      }
    });

    for (const attachment of filteredFiles) {
      if (formik.values.serviceRequestAttachments.length >= 5) {
        setIsUploadingAttachment(false);
        return;
      }

      if (TARGET_ENV === 'development') {
        const formdata = new FormData();
        formdata.append('file', attachment);
        const uploadResponse = await fetch(
          `${API_URL}/uploads/service_request_attachment?tenancy_id=${tenancyId}`,
          {
            method: 'POST',
            headers: {
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
            body: formdata,
          },
        );

        const uploadData = await uploadResponse.json();
        const values: object[] = formik.values.serviceRequestAttachments;
        values.push({attachment: JSON.stringify(uploadData)});

        formik.setFieldValue('serviceRequestAttachments', values);
      } else {
        const presignResponse = await fetch(
          `${API_URL}/presigns/service_request_attachment.json?tenancy_id=${tenancyId}&filename=${attachment.name}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
          },
        );
        const presignInfo = (await presignResponse.json()) as PresignResponse;
        if (presignInfo) {
          await fetch(presignInfo.url, {
            method: presignInfo.method,
            headers: presignInfo.headers as any,
            body: attachment,
          });

          const url = new URL(presignInfo.url);
          const id = url.pathname.split('/').pop();
          const uploadData = {
            id,
            storage: 'cache',
            metadata: {
              size: attachment.size,
              filename: attachment.name,
              mime_type: attachment.type,
            },
          };

          const values: object[] = formik.values.serviceRequestAttachments;
          values.push({attachment: JSON.stringify(uploadData)});

          formik.setFieldValue('serviceRequestAttachments', values);
        }
      }
    }

    setIsUploadingAttachment(false);
  };

  const removeAttachment = (formik: any, index: number) => {
    const values: object[] = formik.values.serviceRequestAttachments;
    values.splice(index, 1);

    formik.setFieldValue('serviceRequestAttachments', values);
  };

  const statusForRequest = () => {
    if (data.status === 'landlord_rejected') {
      return (
        <div>
          <Paragraph>Your landlord did not approve this request.</Paragraph>

          <Paragraph>
            They have provided this reason why: {data.landlordRejectedReason}
          </Paragraph>
        </div>
      );
    } else if (data.status === 'awaiting_site_visit') {
      return (
        <Paragraph>
          A tradesperson will be in contact shortly to organise a visit to the
          property to assess this request. This tradesperson is only visiting to
          assess your request and provide a quote. They will not perform and
          maintenance on the initial visit.
        </Paragraph>
      );
    } else if (data.status === 'awaiting_quote') {
      return (
        <Paragraph>
          Keyhook is currently gathering quotes for this job to send to your
          landlord to review. We will keep you updated as things progress.
        </Paragraph>
      );
    } else if (data.status === 'awaiting_quote_acceptance') {
      return (
        <Paragraph>
          We have sent quotes to your landlord to review. You will be updated
          once they have reviewed them.
        </Paragraph>
      );
    } else if (data.status === 'confirming_job_with_service_person') {
      return (
        <Paragraph>
          Keyhook has found a tradesperson for your maintenance request. They
          will contact you on the phone number provided.
        </Paragraph>
      );
    } else if (data.status === 'awaiting_job_completion') {
      return (
        <Paragraph>
          Keyhook has found a tradesperson for your maintenance request. They
          will contact you on the phone number provided.
        </Paragraph>
      );
    } else if (data?.status === 'completed') {
      return (
        <Paragraph>
          This maintenance request has been completed. - {data.completedReason}
        </Paragraph>
      );
    }
  };

  const beforeAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'before',
      )
    : [];
  const afterAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'after',
      )
    : [];

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !isSuccess || !currentUser
        ? null
        : [
            ...(data.userId === currentUser.id && data.status !== 'completed'
              ? [
                  {
                    label: 'Edit request',
                    linkTo: `/tenancies/${tenancyId}/service-requests/${id}`,
                  },
                ]
              : []),
          ],
    [isSuccess, tenancyId, id, data, currentUser],
  );

  const openHowToFixIt = async () => {
    await Browser.open({
      url: 'https://help.keyhook.com/maintenance-requests/how-to-fix-it',
    });
  };

  return (
    <Page
      title="Maintenance Request"
      actions={pageActions}
      loading={isLoading}
      error={error}>
      {() => (
        <>
          <Card title={data.title} className="mt-2">
            <span className="tw-block text-sm text-secondary">
              Category: {data.category}
            </span>
            {data.renterContactName && (
              <span className="tw-block text-sm text-secondary">
                Tenant Contact Information: {data.renterContactName} -{' '}
                {data.renterContactPhoneNumber}
              </span>
            )}

            <Paragraph>{data.description}</Paragraph>

            {beforeAttachments.length > 0 && (
              <div>
                <strong className="tw-block mb-4">Attachments</strong>

                <LightboxGallery
                  sources={beforeAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}

            {afterAttachments.length > 0 && (
              <div className="mt-2">
                <strong>Attachments ( Post Completion )</strong>

                <LightboxGallery
                  sources={afterAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}

            <strong className="tw-block mt-2">Status</strong>
            <div>{statusForRequest()}</div>

            <strong className="tw-block mt-2">
              Have you checked everything?
            </strong>
            <Paragraph>
              Run through{' '}
              <a className="link link-primary" onClick={openHowToFixIt}>
                this quick checklist
              </a>{' '}
              of easy fixes to common household issues.
            </Paragraph>
          </Card>

          {!['landlord_rejected', 'completed'].includes(data.status) && (
            <Card title="Resolve maintenance request">
              <Paragraph>
                Let the Keyhook team know your maintenance request was resolved.
              </Paragraph>

              <Formik
                initialValues={{
                  serviceRequestAttachments: [],
                  completedReason: 'Job Completed by tradesperson',
                }}
                validationSchema={Yup.object().shape({
                  completedReason: Yup.string()
                    .required()
                    .min(1)
                    .label('Completion Information'),
                  serviceRequestAttachments: Yup.array(
                    Yup.object().shape({
                      attachment: Yup.string()
                        .min(1)
                        .required()
                        .label('Attachment'),
                    }),
                  )
                    .label('Attachments')
                    .max(5)
                    .test(
                      'has-attachments',
                      'Please provide at least 1 attachment',
                      (value) => {
                        // Require 1 attachment if tradesperson fixed issue.
                        if (completedReason === 'Tradesperson') {
                          return value.length >= 1;
                        } else {
                          return true;
                        }
                      },
                    ),
                })}
                onSubmit={confirmMarmJobAsCompleted}
                validateOnBlur={false}
                validateOnChange={false}>
                {(formik) => (
                  <Form>
                    <label className="label">
                      <span className="label-text">Select on option:</span>
                    </label>
                    <select
                      className="select select-bordered w-full mb-2"
                      value={completedReason}
                      onChange={(e) => {
                        const val = e.target.value;
                        setCompletedReason(val as any);
                        if (val === 'Tradesperson') {
                          formik.setFieldValue(
                            'completedReason',
                            'Job Completed by Tradesperson',
                          );
                        } else {
                          formik.setFieldValue('completedReason', '');
                        }
                      }}>
                      <option value="Tradesperson">
                        Job Completed by tradesperson
                      </option>
                      <option value="Other">Other</option>
                    </select>

                    {completedReason === 'Other' && (
                      <TextareaField
                        formik={formik}
                        name="completedReason"
                        labelProps={{
                          title: 'Resolution',
                          size: 'base',
                        }}
                        rows={2}
                        className="h-auto"
                        placeholder="How was your maintenance request resolved?"
                      />
                    )}

                    <strong className="tw-block mt-2 text-secondary">
                      Attachments
                    </strong>

                    <Paragraph>
                      Please submit evidence of the resolved maintenance
                      request.{' '}
                      <small className="tw-block text-secondary">
                        Max sizes: image 3Mb, video 200Mb.
                      </small>
                    </Paragraph>

                    {isUploadingAttachment && (
                      <span className="tw-block text-success">
                        Processing attachments, please wait.
                      </span>
                    )}

                    {formik.values.serviceRequestAttachments.map(
                      (obj: any, index: number) => {
                        const json = JSON.parse(obj.attachment);
                        return (
                          <div key={index}>
                            <div className="flex justify-between items-center my-2">
                              <strong>{json.metadata.filename}</strong>
                              <button
                                className="btn btn-error btn-sm"
                                type="button"
                                onClick={() => removeAttachment(formik, index)}>
                                Remove
                              </button>
                            </div>
                            <hr className="bg-gray-200 w-full" />
                          </div>
                        );
                      },
                    )}

                    {formik.values.serviceRequestAttachments.length < 5 && (
                      <div className="mt-2">
                        <input
                          type="file"
                          multiple
                          accept=".png,.jpeg,.jpg,.mp4,.mov"
                          id="attachments-input"
                          onChange={(e) =>
                            processAttachments(formik, e.target.files)
                          }
                          className="hidden"
                        />
                        <button
                          className="btn btn-neutral btn-sm"
                          type="button"
                          onClick={() =>
                            document.getElementById('attachments-input').click()
                          }>
                          Select Files
                        </button>
                      </div>
                    )}

                    <InlineError
                      error={formik.errors.serviceRequestAttachments}
                    />

                    {!isUploadingAttachment && (
                      <SubmitButton
                        className="mt-3"
                        formik={formik}
                        text="Mark as completed"
                        submittingText="Saving..."
                      />
                    )}
                  </Form>
                )}
              </Formik>
            </Card>
          )}

          <ChatableMessages
            chatableId={data.id}
            chatableType="ServiceRequest"
            isLandlord={false}
          />
        </>
      )}
    </Page>
  );
};

export default ServiceRequestDetailPage;
