import React, {FunctionComponent, useMemo} from 'react';

import {pick} from 'lodash';
import {
  HiOutlineChatAlt2,
  HiOutlineDocumentDuplicate,
  HiOutlineEye,
  HiOutlineHome,
} from 'react-icons/hi';

import {Stats, StatsProps} from 'components_sb/layout';
import Listing from 'models/listings/Listing';

type ListingStats = {
  listing: Listing;
} & Pick<StatsProps, 'small' | 'noMargin' | 'interactive'>;

const ListingStats: FunctionComponent<ListingStats> = ({listing, ...props}) => {
  const viewCount = useMemo(
    () =>
      !listing
        ? 0
        : Number(listing.viewCount) +
          listing.externalListingAds.reduce(
            (sum, ad) => sum + Number(ad.viewCount),
            0,
          ),
    [listing],
  );

  /**
   * Construct the base path to the management page for the listing that
   * the stats are for.
   */
  const baseListingPath = useMemo(
    () => `/properties/${listing.propertyId}/listings/${listing.publicId}`,
    [listing],
  );

  return (
    <Stats
      {...pick(props, ['small', 'noMargin', 'interactive'])}
      stats={[
        {
          icon: HiOutlineEye,
          title: 'Views',
          value: viewCount,
          description: 'Total',
          href: baseListingPath,
        },
        {
          icon: HiOutlineDocumentDuplicate,
          title: 'Applications',
          value: listing.listingRentalApplicationsCount,
          description: 'Received',
          href: `${baseListingPath}/applications`,
        },
        {
          icon: HiOutlineChatAlt2,
          title: 'Enquiries',
          value: listing.enquiryThreadsCount,
          description: 'Received',
          href: `${baseListingPath}/enquiries`,
        },
        {
          icon: HiOutlineHome,
          title: 'Viewings',
          value: listing.openHomesCount + listing.privateViewingsCount,
          description: 'Scheduled',
          href: `${baseListingPath}/viewings`,
        },
      ]}
    />
  );
};

export default ListingStats;
