import {useState} from 'react';

import {PencilIcon, TrashIcon, ViewGridAddIcon} from '@heroicons/react/outline';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import LoadingView from 'components/common/LoadingView';

import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import RoomPlan, {Room, RoomItem} from 'models/properties/RoomPlan';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {errorViewForError} from 'utilities/ErrorHelpers';

const RoomItemCard = ({
  value,
  roomIndex,
  roomPlan,
  setRoomPlan,
}: {
  value: RoomItem;
  roomIndex: number;
  roomPlan: RoomPlan;
  setRoomPlan: (plan: RoomPlan) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState(value.name);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmRemoveItem = () => {
    setConfirmationOptions({
      title: 'Remove Item',
      message: `Are you sure you want to remove '${value.name}'?`,
      buttonTitle: 'Remove',
      action: removeItem,
      color: 'error',
    });
  };

  const removeItem = () => {
    const room = roomPlan.roomPlan[roomIndex];
    room.items = room.items.filter((item) => item.name !== value.name);
    setRoomPlan(roomPlan);
  };

  const saveItemName = () => {
    if (editingText.length > 0) {
      value.name = editingText;
      setRoomPlan(roomPlan);
      setIsEditing(false);
    }
  };

  return (
    <div className="p-2 mb-2 bg-gray-100 flex justify-between">
      <div>
        {isEditing ? (
          <div className="input-group">
            <input
              type="text"
              placeholder="Room name..."
              className="input input-bordered w-full"
              value={editingText}
              onChange={(e) => {
                setEditingText(e.target.value);
              }}
            />
            <button
              disabled={editingText.length === 0}
              className="btn btn-square px-2"
              onClick={saveItemName}>
              Save
            </button>
          </div>
        ) : (
          value.name
        )}
      </div>
      {!isEditing && (
        <div className="btn-group">
          <button
            className="btn btn-sm btn-ghost tooltip"
            data-tip="Remove Item"
            onClick={confirmRemoveItem}>
            <TrashIcon className="w-5 h-5 pointer-events-none" />
          </button>
          <button
            className="btn btn-sm btn-ghost tooltip"
            data-tip="Edit Item"
            onClick={() => setIsEditing(true)}>
            <PencilIcon className="w-5 h-5 pointer-events-none" />
          </button>
        </div>
      )}
    </div>
  );
};

const RoomCard = ({
  value,
  roomIndex,
  roomPlan,
  setRoomPlan,
}: {
  value: Room;
  roomIndex: number;
  roomPlan: RoomPlan;
  setRoomPlan: (plan: RoomPlan) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editingText, setEditingText] = useState(value.name);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const [addingText, setAddingText] = useState('');

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const saveRoomName = () => {
    if (editingText.length > 0) {
      value.name = editingText;
      setRoomPlan(roomPlan);
      setIsEditing(false);
      setEditingText('');
    }
  };

  const addItem = () => {
    if (addingText.length > 0) {
      value.items.push({name: addingText});
      setRoomPlan(roomPlan);
      setIsAddingItem(false);
      setAddingText('');
    }
  };

  const confirmRemoveRoom = () => {
    setConfirmationOptions({
      title: 'Remove Room',
      message: `Are you sure you want to remove '${value.name}'?`,
      buttonTitle: 'Remove',
      action: removeRoom,
      color: 'error',
    });
  };

  const removeRoom = () => {
    const newRooms = roomPlan.roomPlan.filter((r) => r.name !== value.name);
    roomPlan.roomPlan = newRooms;
    setRoomPlan(roomPlan);
  };

  return (
    <Card
      actions={[
        {
          label: 'Add Item',
          onClick: () => setIsAddingItem(true),
          icon: ViewGridAddIcon,
        },
        {
          label: 'Edit',
          onClick: () => setIsEditing(true),
          icon: PencilIcon,
        },
        {
          label: 'Remove',
          onClick: confirmRemoveRoom,
          icon: TrashIcon,
        },
      ]}
      title={
        <>
          <div>
            {isEditing ? (
              <div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Room name..."
                    className="input input-bordered w-full"
                    value={editingText}
                    onChange={(e) => {
                      setEditingText(e.target.value);
                    }}
                  />
                  <button
                    disabled={editingText.length === 0}
                    className="btn btn-square px-2"
                    onClick={saveRoomName}>
                    Save
                  </button>
                </div>
              </div>
            ) : (
              value.name
            )}
          </div>
        </>
      }
      className="mb-4">
      <div>
        {value.items.map((value) => (
          <RoomItemCard
            key={`item-${value.name}`}
            roomIndex={roomIndex}
            value={value}
            roomPlan={roomPlan}
            setRoomPlan={setRoomPlan}
          />
        ))}
      </div>
      {isAddingItem && (
        <div className="mt-2">
          <div className="form-control">
            <label className="label-text">Name</label>
            <div className="input-group">
              <input
                type="text"
                placeholder="Item name..."
                className="input input-bordered w-1/2"
                value={addingText}
                onChange={(e) => {
                  setAddingText(e.target.value);
                }}
              />
              <button
                disabled={addingText.length === 0}
                className="btn btn-square px-2"
                onClick={addItem}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

const EditRoomPlanPage = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const [isSaving, setIsSaving] = useState(false);
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [addingRoomText, setAddingRoomText] = useState('');

  const cacheKey = `property-${propertyId}-room-plan`;

  const {data, isLoading, error} = useQuery(cacheKey, async () => {
    const rp = await RoomPlan.where({propertyId}).first();

    return rp.data;
  });
  const queryClient = useQueryClient();

  const saveRoomPlan = async () => {
    setIsSaving(true);
    const result = await data.save();
    if (result) {
      toast.success('Changes successfully saved!');
    }
    setIsSaving(false);
    setHasMadeChanges(false);
  };

  const addRoom = () => {
    if (addingRoomText.length > 0) {
      const d = data;
      d.roomPlan.push({
        name: addingRoomText,
        items: [
          {name: 'Walls/Doors'},
          {name: 'Windows/Curtains'},
          {name: 'Ceiling'},
          {name: 'Floors'},
        ],
      });
      setData(d);
      setAddingRoomText('');
    }
  };

  const setData = (d: RoomPlan) => {
    queryClient.setQueryData(cacheKey, d);
    setHasMadeChanges(true);
  };

  return (
    <Page title="Edit Inspection Template" loading={isLoading} error={error}>
      {() => (
        <>
          <Card title="Edit Inspection Template">
            <Paragraph>
              The cards below represent the areas for your property. These areas
              are used as a template to generate your inspections, so we highly
              recommend customising this to make inspections easier.
            </Paragraph>

            <div className="mt-2">
              <h2 className="card-title mb-4 text-brand-850">Add an area</h2>
              <Paragraph size="sm" secondary>
                Use the field below to add any areas or sections that might be
                missing from your property.
              </Paragraph>

              <div className="mt-2">
                <div className="form-control">
                  <label className="label-text">Name</label>
                  <div className="input-group">
                    <input
                      type="text"
                      placeholder="Area name..."
                      className="input input-bordered w-1/2"
                      value={addingRoomText}
                      onChange={(e) => {
                        setAddingRoomText(e.target.value);
                      }}
                    />
                    <button
                      disabled={addingRoomText.length === 0}
                      className="btn btn-square px-2"
                      onClick={addRoom}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          <div className="mt-4">
            {data &&
              data.roomPlan &&
              data.roomPlan.map((value, index) => (
                <RoomCard
                  key={`room-${value.name}`}
                  roomIndex={index}
                  value={value}
                  roomPlan={data}
                  setRoomPlan={setData}
                />
              ))}
          </div>

          <Button
            mode="manual"
            label="Update"
            loadingLabel="Updating..."
            loading={isSaving}
            onClick={saveRoomPlan}
            disabled={!hasMadeChanges}
            size="lg"
            category="primary"
          />
        </>
      )}
    </Page>
  );
};

export default EditRoomPlanPage;
