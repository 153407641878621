import {HTMLProps, useRef} from 'react';

import {type FormikProps} from 'formik';
import _ from 'lodash';
import TextareaAutosize from 'react-textarea-autosize';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';
import TrackingService from 'services/TrackingService';

interface InputFieldProps extends HTMLProps<HTMLTextAreaElement> {
  formik: FormikProps<any>;
  labelProps: FieldLabelProps;
  name: string;
  helpText?: string;
}

const TextareaField = ({
  formik,
  labelProps,
  name,
  helpText,
  ...rest
}: InputFieldProps) => {
  const textarea = useRef<HTMLTextAreaElement>(null);
  const errors = errorsFor(formik, name);
  const value = valueFor(formik, name);

  const classN = errors ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }
  rest.className =
    'textarea textarea-bordered w-full tw-block' + rest.className;

  const handleBlur = () => {
    formik.handleBlur(name);

    const v = value as string | undefined | null;
    if (v && v.toString().length > 0) {
      TrackingService.trackEvent(TrackingService.Event.FillField, {
        field: name,
      });
    }
  };

  const allowedRest = _.pick(rest, ['id', 'placeholder', 'className']);
  const minRows = rest.rows ? rest.rows : 2;
  const maxRows = 15;

  return (
    <div className="flex-1 flex flex-col">
      {labelProps && <FieldLabel {...labelProps} />}
      <TextareaAutosize
        ref={textarea}
        name={name}
        onChange={(e) => formik.setFieldValue(name, e.target.value)}
        value={value}
        onBlur={handleBlur}
        minRows={minRows}
        maxRows={maxRows}
        {...allowedRest}
      />

      <InlineError error={errors} />
    </div>
  );
};

export default TextareaField;
