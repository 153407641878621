import {useCallback, useEffect, useMemo} from 'react';

import {Modal} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import usePropertyBillingDetails from 'hooks/usePropertyBillingDetails';
import Property from 'models/properties/Property';
import {AccountRole} from 'models/users/User';

import SubscriptionRequiredModal from '../modals/SubscriptionRequiredModal';
import {RequireSubscriptionWrapper} from '../types';
import useSubscriptions from './useSubscriptions';

const {useModal} = Modal.Imperative;
interface UseSubscriptionGuardProps {
  property: Property | undefined;
}

interface UseSubscriptionGuardReturn {
  requireSubscription: RequireSubscriptionWrapper;
}

interface UseSubscriptionGuardHook {
  (props: UseSubscriptionGuardProps): UseSubscriptionGuardReturn;
}

/**
 * Handles the logic for guarding access to features based on whether the
 * property has an active subscription or the Landlord is within a free period.
 */
const useSubscriptionGuard: UseSubscriptionGuardHook = ({property}) => {
  const {activeAccountRole} = useLocalUserSettings();

  const isLandlord = useMemo(
    () => activeAccountRole === AccountRole.Landlord,
    [activeAccountRole],
  );

  const {paymentConfigured} = usePropertyBillingDetails(property);

  const {subscriptionStatus} = useSubscriptions();

  /**
   * Whether access to all features of the platform should be restricted for the
   * current user based on whether they are a Landlord.
   */
  const shouldRestrictAccess = useMemo<boolean | null>(
    () =>
      isLandlord &&
      !subscriptionStatus.isWithinFreePeriod &&
      !paymentConfigured,
    [isLandlord, subscriptionStatus?.isWithinFreePeriod, paymentConfigured],
  );

  const openModal = useModal();

  /**
   * Opens the modal indicating that the action cannot be performed until
   * their subscription payment has been configured.
   */
  const showSubscriptionRequired = useCallback(() => {
    openModal(SubscriptionRequiredModal, {
      property,
    });
  }, [openModal, property]);

  /**
   * A wrapper function to only permit invocation of the provided function if the
   * user has an active subscription.
   */
  const requireSubscription = useCallback<RequireSubscriptionWrapper>(
    (guardedFunction, options = {}) => {
      /**
       * Deconstruct the options object.
       */
      const {handleReturn, handleDenied, modal = {}} = options;

      /**
       * Handle the user not having an active subscription.
       */
      if (shouldRestrictAccess) {
        /**
         * Show the subscription required modal unless disabled.
         */
        if (!modal.disable) {
          showSubscriptionRequired();
        }

        /**
         * If a callback is provided to handle the invocation being denied,
         * invoke this callback.
         */
        if (handleDenied) {
          handleDenied();
        }

        /**
         * Do not proceed.
         */
        return;
      }

      /**
       * Invoke the guarded function.
       */
      const returnValue = guardedFunction();

      /**
       * If a callback is provided to handle the return value of the input
       * function, invoke this callback with the return value.
       */
      if (handleReturn) {
        handleReturn(returnValue);
      }
    },
    [shouldRestrictAccess, showSubscriptionRequired],
  );

  return {
    requireSubscription,
  };
};

export default useSubscriptionGuard;
