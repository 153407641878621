import {FunctionComponent, useCallback, useEffect, useMemo} from 'react';

import {Time} from '@internationalized/date';
import {useFormik} from 'formik';
import {pick} from 'lodash';
import moment from 'moment';
import {MdOutlineScheduleSend} from 'react-icons/md';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {Button} from 'components_sb/buttons';
import {DatePicker, TextField, TimePicker} from 'components_sb/input';
import {yupTimeSchema} from 'components_sb/input/TimePicker/TimePicker';
import useScroll from 'hooks/useScroll';
import PrivateViewing from 'models/listings/PrivateViewing';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {saveResource} from 'utilities/SpraypaintHelpers';

interface PrivateViewingFormBaseProps {
  listingPublicId: string;
}

/**
 * Require an instance of a private viewing when the mode is set to 'edit'.
 */
type PrivateViewingFormModeProps =
  | {
      mode: 'new';
      listingPrivateId: string;
      privateViewing?: never;
    }
  | {
      mode: 'edit';
      listingPrivateId?: never;
      privateViewing: PrivateViewing;
    };

type PrivateViewingFormProps = PrivateViewingFormBaseProps &
  PrivateViewingFormModeProps;

type FormInput = Pick<PrivateViewing, 'name' | 'email'> & {
  date: Date;
  startTime: Time;
  endTime: Time;
};

const PrivateViewingForm: FunctionComponent<PrivateViewingFormProps> = ({
  mode,
  listingPrivateId,
  listingPublicId,
  ...props
}) => {
  const router = useRouter();
  const route = useRoute();

  const queryClient = useQueryClient();

  /**
   * Scroll to the top of the page on load.
   */
  const {scrollToTop} = useScroll();
  useEffect(() => {
    scrollToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Determine the initial values for the form.
   */
  const initialValues = useMemo<FormInput>(() => {
    if (mode === 'new') {
      return {
        date: null,
        startTime: null,
        endTime: null,
        /**
         * If the private viewing is being created via an enquiry, we can obtain the
         * initial values relating to the user who the private viewing is for
         * from the props passed when navigating.
         */
        ...('enquiry' in route.props && typeof route.props.enquiry === 'object'
          ? {
              name:
                'name' in route.props.enquiry &&
                typeof route.props.enquiry.name === 'string'
                  ? route.props.enquiry.name
                  : '',
              email:
                'email' in route.props.enquiry &&
                typeof route.props.enquiry.email === 'string'
                  ? route.props.enquiry.email
                  : '',
            }
          : {
              name: '',
              email: '',
            }),
      };
    }
    /**
     * If we are editing a private viewing, a private viewing instance will have
     * been provided, so we use the values from that.
     */
    if (mode === 'edit') {
      const {privateViewing} = props;
      const startDate = moment(privateViewing.startsAt).toDate();
      const endDate = moment(privateViewing.endsAt).toDate();
      return {
        ...pick(privateViewing, ['name', 'email']),
        date: startDate,
        startTime: new Time(startDate.getHours(), startDate.getMinutes()),
        endTime: new Time(endDate.getHours(), endDate.getMinutes()),
      };
    }
  }, [mode, props, route.props]);

  /**
   * Mutation for saving the private viewing.
   */
  const {mutateAsync: savePrivateViewing, isLoading: isSaving} = useMutation(
    async (privateViewing: PrivateViewing) => saveResource(privateViewing),
    {
      onSuccess: () => {
        /**
         * Clear the queyr cache to ensure the saved private viewing is shown in the list
         * after redirecting.
         */
        queryClient.invalidateQueries(
          `listing-${listingPublicId}-private-viewings`,
        );

        toast.success('Your private viewing has been saved!');
        /**
         * Redirect back to the listing viewings index page.
         */
        router.goBack();
      },
      onError: () => {
        toast.success('Sorry, there was an error saving your private viewing.');
      },
    },
  );

  /**
   * Invoked upon submitting the form.
   */
  const onSubmit = useCallback(
    async (formValues: FormInput) => {
      const privateViewing = props.privateViewing ?? new PrivateViewing();
      const {date, startTime, endTime} = formValues;
      privateViewing.assignAttributes({
        listingId: privateViewing.listingId ?? listingPrivateId,
        ...pick(formValues, ['name', 'email']),
        startsAt: moment(date)
          .hour(startTime.hour)
          .minute(startTime.minute)
          .toISOString(),
        endsAt: moment(date)
          .hour(endTime.hour)
          .minute(endTime.minute)
          .toISOString(),
      });
      await savePrivateViewing(privateViewing);
    },
    [props, listingPrivateId, savePrivateViewing],
  );

  /**
   * Initialise the form.
   */
  const form = useFormik<FormInput>({
    onSubmit,
    initialValues,
    validationSchema: Yup.object().shape({
      listingId: Yup.string(),
      name: Yup.string().required(
        'Please enter the name of who the private viewing is for',
      ),
      email: Yup.string()
        .email('Please enter a valid email address')
        .required(
          'Please enter the email address of who the private viewing is for',
        ),
      date: Yup.date()
        .typeError('Please enter the date of the viewing')
        .required('Please enter the date of the viewing'),
      startTime: yupTimeSchema
        .typeError('Please enter the time the viewing will end')
        .required('Please enter the time the viewing will start'),
      endTime: yupTimeSchema
        .typeError('Please enter the time the viewing will end')
        .required('Please enter the time the viewing will end'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className="flex flex-col gap-y-8 w-full max-w-4xl mx-auto">
      <TextField
        required
        name="name"
        label="Name"
        description="The name of the person who the private viewing is for."
        mode="formik"
        form={form}
        disabled={isSaving}
      />
      <TextField
        required
        name="email"
        label="Email"
        description="We will send the private viewing details to this email address."
        mode="formik"
        form={form}
        disabled={isSaving}
      />
      <DatePicker
        required
        name="date"
        labelProps={{
          title: 'Date',
          description: 'The date of the private viewing.',
        }}
        mode="single"
        form={form}
        disabledDates={[
          {
            from: new Date(1900, 1, 1),
            to: moment().subtract(1, 'day').toDate(),
          },
        ]}
      />
      <div className="w-full flex flex-col sm:flex-row gap-8">
        <div className="flex-1">
          <TimePicker
            name="startTime"
            labelProps={{
              title: 'Start time',
              description: 'When will the private viewing start?',
              required: true,
            }}
            form={form}
          />
        </div>
        <div className="flex-1">
          <TimePicker
            name="endTime"
            labelProps={{
              title: 'End time',
              description: 'When will the private viewing end?',
              required: true,
            }}
            form={form}
          />
        </div>
      </div>
      <div className="flex w-full mt-6 max-w-lg">
        <Button
          label={`${mode === 'new' ? 'Schedule' : 'Update'} private viewing`}
          icon={MdOutlineScheduleSend}
          category="primary"
          size="base"
          mode="formik"
          form={form}
        />
      </div>
    </div>
  );
};

export default PrivateViewingForm;
