import {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import type {FormikHelpers} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {
  Base64FileField,
  TextareaField,
  ToggleField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {InlineError} from 'components_sb/feedback';
import {ModalDefinition} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionAction from 'models/inspections/InspectionAction';
import User, {AccountRole} from 'models/users/User';
import useInspectionStore from 'stores/InspectionStore';

type FormValues = {
  action: string;
  actionType: 'landlord' | 'tenant' | null;
  attachment: string | null;
  shouldCreateMaintenanceRequest: boolean;
};

const InspectionAddActionModal: ModalDefinition = {
  title: 'Add an action',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {currentUser} = useAuth();
    const {activeAccountRole} = useLocalUserSettings();
    const [inspectionItems, setInspectionItems] = useInspectionStore(
      ({inspectionItems, setInspectionItems}) => [
        inspectionItems,
        setInspectionItems,
      ],
    );

    const {closeModal, inspectionItem} = props;

    const handleSubmit = async (
      formValues: FormValues,
      actions: FormikHelpers<FormValues>,
    ) => {
      const action = new InspectionAction(formValues);
      action.inspectionItemId = inspectionItem.id;

      const result = await action.save();

      if (result) {
        action.user = new User({
          id: currentUser.id,
          name: currentUser.name,
          avatar: currentUser.avatar,
          email: currentUser.email,
        });
        action.user.isPersisted = true;
        action.userId = currentUser.id;

        // Update the inspection items store so that it re-renders the UI for rooms etc

        const item = inspectionItems?.find((i) => i.id === inspectionItem.id);
        if (item) {
          item.inspectionActions.push(action);

          setInspectionItems(inspectionItems);
        }

        actions.setSubmitting(false);
        closeModal();
        toast.success('Action successfully added!');
      } else {
        for (const key of Object.keys(action.errors)) {
          const message = action.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
        actions.setSubmitting(false);
      }
    };

    const formik = useFormik<FormValues>({
      initialValues: {
        action: '',
        actionType: null,
        attachment: null,
        shouldCreateMaintenanceRequest: false,
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        action: Yup.string().required().min(5).label('Action'),
        actionType: Yup.string()
          .required()
          .nullable()
          .oneOf(['landlord', 'tenant'])
          .label('Action responsibility'),
        attachment: Yup.string().required().nullable().label('Photo'),
        shouldCreateMaintenanceRequest: Yup.boolean().label(
          'Create a maintenance request?',
        ),
      }),
    });

    const onSave = useCallback(async () => {
      formik.submitForm();
      return false;
    }, [formik]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <form>
        <div className="mt-2">
          <TextareaField
            formik={formik}
            labelProps={{title: 'Action', size: 'base'}}
            name="action"
            placeholder="What needs to be done?"
          />
        </div>

        <div className="mt-2">
          <label className="label flex justify-start items-center">
            <span className="label-text mr-2">Who is the action for?</span>
          </label>
          <FormRow>
            <FormRowItem>
              <button
                type="button"
                disabled={
                  formik.isSubmitting ||
                  activeAccountRole === AccountRole.Renter
                }
                className={`btn  w-full ${
                  formik.values.actionType === 'landlord'
                    ? 'btn-primary'
                    : 'btn-neutral btn-outline'
                }`}
                onClick={() => formik.setFieldValue('actionType', 'landlord')}>
                Landlord
              </button>
            </FormRowItem>

            <FormRowItem>
              <button
                type="button"
                disabled={formik.isSubmitting}
                className={`btn w-full ${
                  formik.values.actionType === 'tenant'
                    ? 'btn-primary'
                    : 'btn-neutral btn-outline'
                }`}
                onClick={() => formik.setFieldValue('actionType', 'tenant')}>
                Tenant
              </button>
            </FormRowItem>
          </FormRow>
          <InlineError error={formik.errors.actionType} />
        </div>

        <div className="mt-2">
          <ToggleField
            formik={formik}
            name="shouldCreateMaintenanceRequest"
            label="Create a maintenance request"
          />
        </div>

        <div className="mt-2">
          <Base64FileField
            formik={formik}
            name="attachment"
            accept=".png,.jpeg,.jpg"
            labelProps={{
              title: 'Attachment',
            }}
          />
        </div>
      </form>
    );
  },
};

export default InspectionAddActionModal;
