import {ReactNode} from 'react';

import clsx from 'clsx';
import {type FormikProps} from 'formik';
import moment from 'moment';
import Datetime, {DatetimepickerProps} from 'react-datetime';

import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import {InlineError} from 'components_sb/feedback';
import TrackingService from 'services/TrackingService';
import {DATE_FORMAT} from 'utilities/DateHelpers';

import './DateField.css';

interface DateFieldProps extends DatetimepickerProps {
  formik: FormikProps<any>;
  minDate: Date;
  maxDate: Date;
  label: string;
  name: string;
  helpText?: string;
  children?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
}

const DateField = ({
  formik,
  label,
  name,
  minDate = new Date(),
  maxDate = new Date(),
  helpText,
  size = 'md',
  ...rest
}: DateFieldProps) => {
  const classN = formik.errors[name] ? ' border border-red-500' : '';
  if (rest.className) {
    rest.className += classN;
  } else {
    rest.className = classN;
  }

  if (rest.children) {
    rest.className += ' flex-1';
  }

  const valid = (currentDate: moment.Moment): boolean => {
    return (
      currentDate.isSameOrAfter(moment(minDate)) &&
      currentDate.isSameOrBefore(moment(maxDate))
    );
  };

  if (rest.children) {
    return (
      <div className="form-control">
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter>{helpText}</HelpTextPresenter>
        </label>
        <div className="input-group">
          <Datetime
            value={formik.values[name] ? moment(formik.values[name]) : ''}
            onChange={(val) => {
              const m = moment(val);
              formik.setFieldValue(name, m.format('YYYY-MM-DD'));
              TrackingService.trackEvent(TrackingService.Event.FillField, {
                field: name,
              });
            }}
            dateFormat={DATE_FORMAT}
            isValidDate={valid}
            timeFormat={false}
            inputProps={{
              placeholder: 'Click to select date',
              readOnly: true,
              className: clsx(
                'w-full max-w-full box-border',
                'transition-all duration-300',
                'bg-brand-50 hover:bg-brand-75 ',
                'placeholder-brand-800 placeholder-opacity-30',
                'outline-none border-none ring-1 ring-brand-75',
                'focus:ring-2 focus:ring-brand-500',

                size === 'sm' &&
                  clsx('text-base', 'px-2', 'h-10', 'rounded-md'),
                size === 'md' &&
                  clsx('text-base', 'px-4', 'h-12', 'rounded-lg'),
                size === 'lg' && clsx('text-lg', 'px-6', 'h-14', 'rounded-xl'),
              ),
            }}
            closeOnSelect
            {...rest}
          />
          {rest.children}
        </div>
        <InlineError error={formik.errors[name]} />
      </div>
    );
  } else {
    return (
      <>
        <label className="label flex justify-start items-center">
          <span className="label-text mr-2">{label}</span>
          <HelpTextPresenter>{helpText}</HelpTextPresenter>
        </label>
        <Datetime
          value={formik.values[name] ? moment(formik.values[name]) : ''}
          onChange={(val) => {
            const m = moment(val);
            formik.setFieldValue(name, m.format('YYYY-MM-DD'));
          }}
          dateFormat={DATE_FORMAT}
          isValidDate={valid}
          timeFormat={false}
          inputProps={{
            placeholder: 'Click to select date',
            readOnly: true,
            className: clsx(
              'w-full max-w-full box-border',
              'transition-all duration-300',
              'bg-brand-50 hover:bg-brand-75 ',
              'placeholder-brand-800 placeholder-opacity-30',
              'outline-none border-none ring-1 ring-brand-75',
              'focus:ring-2 focus:ring-brand-500',

              size === 'sm' && clsx('text-base', 'px-2', 'h-10', 'rounded-md'),
              size === 'md' && clsx('text-base', 'px-4', 'h-12', 'rounded-lg'),
              size === 'lg' && clsx('text-lg', 'px-6', 'h-14', 'rounded-xl'),
            ),
          }}
          closeOnSelect
          {...rest}
        />
        <InlineError error={formik.errors[name]} />
      </>
    );
  }
};

export default DateField;
