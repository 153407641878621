import {lazy, ReactElement, Suspense, useCallback} from 'react';

import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from '@react-pdf-viewer/default-layout';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {Page} from 'router/components';

const Worker: any = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Worker};
  }),
);
const Viewer = lazy(() =>
  import('@react-pdf-viewer/core').then((module) => {
    return {default: module.Viewer};
  }),
);

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const DocumentPreviewPage = () => {
  const params = new URLSearchParams(window.location.search);
  const documentUrl = params.get('url');
  const {authCookies, isLoggedIn} = useAuth();

  const renderToolbar = useCallback(
    (Toolbar: (props: ToolbarProps) => ReactElement) => (
      <Toolbar>
        {(slots: ToolbarSlot) => {
          const {ShowSearchPopover, Zoom, ZoomIn, ZoomOut} = slots;
          return (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
              }}>
              <div style={{padding: '0px 2px'}}>
                <ShowSearchPopover />
              </div>
              <div style={{padding: '0px 2px'}}>
                <ZoomOut />
              </div>
              <div style={{padding: '0px 2px'}}>
                <Zoom />
              </div>
              <div style={{padding: '0px 2px'}}>
                <ZoomIn />
              </div>
            </div>
          );
        }}
      </Toolbar>
    ),
    [],
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: () => [],
    renderToolbar,
  });

  return (
    <Page title="Document Preview">
      {() => (
        <Suspense fallback={<div>Loading...</div>}>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
            <Viewer
              fileUrl={documentUrl}
              plugins={[defaultLayoutPluginInstance]}
              httpHeaders={
                isLoggedIn
                  ? {
                      'X-USER-TOKEN': authCookies.token,
                      'X-USER-EMAIL': authCookies.userEmail,
                    }
                  : {}
              }
              renderLoader={() => (
                <div>
                  <LoadingView />
                </div>
              )}
              renderError={() => (
                <div>
                  <p>
                    There was an issue trying to load this document, please try
                    again later.
                  </p>
                </div>
              )}
            />
          </Worker>
        </Suspense>
      )}
    </Page>
  );
};

export default DocumentPreviewPage;
