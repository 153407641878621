import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {useFormik} from 'formik';
import {BiEdit, BiSave} from 'react-icons/bi';
import {HiOutlineTrash, HiPlus, HiX} from 'react-icons/hi';
import {QueryKey, useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import ConditionalWrapper from 'components/common/ConditionalWrapper';
import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {TextAreaField} from 'components_sb/input';
import {Card, Modal} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import ListingUserNotes from 'models/listings/ListingUserNotes';
import {saveResource} from 'utilities/SpraypaintHelpers';

import UnsavedNotesWarningModal from './UnsavedNotesWarningModal';

const {useModal} = Modal.Imperative;

interface FormValues {
  notes: string;
}

interface BaseProps {
  /**
   * The private ID the listing the notes relates to.
   */
  listingPrivateId: string;
  /**
   * Whether the editor should be rendered within a card component.
   */
  asCard?: boolean;
}

interface PropsWithUserEmail extends BaseProps {
  /**
   * The email address of the user the notes relate to.
   */
  userEmail: string;
}

interface PropsWithUserID extends BaseProps {
  /**
   * The ID of the user the notes relate to.
   */
  userId: string;
}

type ListingUserNotesEditorProps = PropsWithUserEmail | PropsWithUserID;

/**
 * For Landlords to view and edit notes about a user for a particular listing.
 */
const ListingUserNotesEditor: FunctionComponent<
  ListingUserNotesEditorProps
> = ({listingPrivateId, asCard = false, ...props}) => {
  /**
   * Create a reference for the card element.
   */
  const cardRef = useRef<HTMLDivElement>();

  /**
   * Access the imperative modal context.
   */
  const openModal = useModal();

  /**
   * Ensures the card is fully within the viewport, since switching between
   * modes can dynamically change the height of the card and cause some
   * content to be rendered out of the scroll view.
   */
  const scrollCardIntoView = useCallback(() => {
    if (cardRef.current) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [cardRef]);

  /**
   * Whether the user is currently editing the notes.
   */
  const [editing, setEditing] = useState<boolean>(false);

  /**
   * Handle toggling between edit and view modes.
   */
  const handleToggleEditMode = useCallback(
    (enabled: boolean) => {
      setEditing(enabled);
      if (enabled) {
        setTimeout(() => scrollCardIntoView(), 100);
      }
    },
    [scrollCardIntoView],
  );

  /**
   * We can use the user email and user ID interchangeably, so we can
   * abstract the data here based on the props signature.
   */
  const identifyingUserData = useMemo(
    () => ({
      ...('userEmail' in props ? {email: props.userEmail} : {}),
      ...('userId' in props ? {userId: props.userId} : {}),
    }),
    [props],
  );

  /**
   * Get the query client instance.
   */
  const queryClient = useQueryClient();

  /**
   * Construct the key for the query for fetching the listing user notes.
   */
  const constructQueryKey = useCallback(
    (data: object) => [
      'listing-user-notes',
      {
        listingPrivateId,
        ...data,
      },
    ],
    [listingPrivateId],
  );

  /**
   * Construct the query key for the query performed by this component instance.
   */
  const currentQueryKey = useMemo<QueryKey>(
    () => constructQueryKey(identifyingUserData),
    [constructQueryKey, identifyingUserData],
  );

  /**
   * Attempt to fetch an existing instance of the listing user notes.
   */
  const query = useQuery(
    currentQueryKey,
    async () =>
      (
        await ListingUserNotes.where({
          listingId: listingPrivateId,
          ...identifyingUserData,
        }).first()
      ).data,
  );

  /**
   * Creates a new instance of the listing user notes.
   */
  const createNewListingUserNotes = useCallback(
    () =>
      new ListingUserNotes({
        listingId: listingPrivateId,
        ...identifyingUserData,
      }),
    [listingPrivateId, identifyingUserData],
  );

  const showSuccessToast = useCallback(
    () => toast.success('Notes saved successfully!'),
    [],
  );

  const showErrorToast = useCallback(
    () => toast.error('Sorry, there was an issue saving your notes.'),
    [],
  );

  /**
   * Since this component can be EITHER provided with a user email OR user ID,
   * and the query key is based on this, we need to update the query data
   * for both query keys, so that the data is properly updated when navigating
   * between pages where components are used with different prop signatures.
   * This function accepts both the user email and user ID since these will be
   * set on the listing user notes instance upon saving.
   */
  const setQueryData = useCallback(
    ({
      newData,
      userEmail,
      userId,
    }: {
      newData: ListingUserNotes | null;
      userEmail?: string;
      userId?: string;
    }) => {
      if (userEmail) {
        queryClient.setQueryData(
          constructQueryKey({email: userEmail}),
          newData,
        );
      }
      if (userId) {
        queryClient.setQueryData(constructQueryKey({userId}), newData);
      }
    },
    [queryClient, constructQueryKey],
  );

  /**
   * Save changes to the notes.
   */
  const onSaveNotes = useCallback(
    async ({notes}: FormValues) => {
      /**
       * Don't allow saving if the attempt to fetch the existing user notes instance
       * has failed or is still being fetched.
       */
      if (!query.isSuccess) {
        console.error(
          'Cannot save notes until initial fetch has completed successfully.',
        );
        return;
      }

      /**
       * Get the existing listing user notes instance (if it exists).
       */
      const existingListingUserNotes = query.data;

      /**
       * Handle clearing notes.
       */
      if (!notes) {
        if (existingListingUserNotes) {
          try {
            await existingListingUserNotes.destroy();
          } catch (error) {
            showErrorToast();
            return;
          }
        }

        /**
         * Update the data for the query to be null (indicating no instance exists).
         */
        setQueryData({
          newData: null,
          userEmail: existingListingUserNotes.email,
          userId: existingListingUserNotes.userId,
        });

        /**
         * Toggle back to view mode.
         */
        handleToggleEditMode(false);

        /**
         * Indicate to the user that the save was successful.
         */
        showSuccessToast();
        return;
      }

      /**
       * Use the existing fetched instance if it exists, otherwise
       * create a new instance.
       */
      const listingUserNotes: ListingUserNotes =
        existingListingUserNotes ?? createNewListingUserNotes();

      /**
       * Set the current notes on the instance.
       */
      listingUserNotes.assignAttributes({
        notes,
      });

      /**
       * Attempt to save the listing user notes instance.
       */
      if (
        await saveResource(listingUserNotes, {
          showErrorToast: false,
        })
      ) {
        /**
         * Update the data for the query with the new updated user notes instance.
         */
        setQueryData({
          newData: listingUserNotes,
          userEmail: listingUserNotes.email,
          userId: listingUserNotes.userId,
        });

        /**
         * Toggle back to view mode.
         */
        handleToggleEditMode(false);

        /**
         * Indicate to the user that the save was successful.
         */
        showSuccessToast();
      } else {
        /**
         * Indicate to the user that the save failed.
         */
        showErrorToast();
      }
    },
    [
      query,
      createNewListingUserNotes,
      setQueryData,
      handleToggleEditMode,
      showSuccessToast,
      showErrorToast,
    ],
  );

  /**
   * Deconstruct the user notes from the query.
   */
  const {data: listingUserNotes} = query;

  /**
   * Create the form instance.
   */
  const form = useFormik<FormValues>({
    /**
     * Save the notes upon submitting.
     */
    onSubmit: onSaveNotes,
    initialValues: {
      notes: listingUserNotes?.notes ?? '',
    },
    /**
     * We need to allow the initial values to be reinitialised since
     * any existing notes may not have been fetched yet, and this
     * will allow the value to be set once the fetch completes.
     */
    enableReinitialize: true,
    validationSchema: Yup.object({
      notes: Yup.string().optional(),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  /**
   * Sets the value of the notes on the form field to an empty string.
   * Note that saving after clearing is still required.
   */
  const onClearNotes = useCallback(() => {
    form.setFieldValue('notes', '');
  }, [form]);

  /**
   * Reset the form field value back to the default and exit edit mode.
   */
  const onCancelEditing = useCallback(async () => {
    if (
      (!listingUserNotes && !form.values.notes) ||
      form.values.notes === listingUserNotes?.notes ||
      (await openModal(UnsavedNotesWarningModal))
    ) {
      form.setFieldValue('notes', listingUserNotes?.notes ?? '');
      handleToggleEditMode(false);
    }
  }, [openModal, form, listingUserNotes, handleToggleEditMode]);

  /**
   * Whether the user currently has any notes added.
   * Note that this does NOT reflect whether a ListingUserNotes instance
   * exists, since an instance may still exist but have no notes.
   */
  const hasNotes = useMemo(
    () => !!form.values.notes && form.values.notes.length > 0,
    [form.values.notes],
  );

  return (
    <ConditionalWrapper
      condition={asCard}
      wrapper={(children) => (
        <Card ref={cardRef} id="listing-user-notes-card" title="Notes">
          {children}
        </Card>
      )}>
      {query.isLoading ? (
        <div className="py-6">
          <SpinningLoader color="brand" size="base" />
        </div>
      ) : (
        <div className="flex flex-col gap-y-4">
          {editing ? (
            <>
              <TextAreaField
                mode="formik"
                form={form}
                name="notes"
                placeholder="Add your notes here..."
                disabled={form.isSubmitting}
                rows={5}
              />
              <div className="w-full flex flex-col md:flex-row gap-3">
                <div className="w-full max-w-auto md:max-w-xs">
                  <Button
                    mode="formik"
                    form={form}
                    label="Save notes"
                    loadingLabel="Saving notes..."
                    icon={BiSave}
                    category="primary"
                    size="base"
                  />
                </div>
                <div className="w-full max-w-auto md:max-w-xs">
                  <Button
                    label="Clear"
                    icon={HiOutlineTrash}
                    category="secondary"
                    size="base"
                    mode="manual"
                    disabled={form.isSubmitting}
                    onClick={onClearNotes}
                  />
                </div>
                <div className="w-full max-w-auto md:max-w-xs">
                  <Button
                    label="Cancel"
                    icon={HiX}
                    category="secondary"
                    size="base"
                    mode="manual"
                    disabled={form.isSubmitting}
                    onClick={onCancelEditing}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              {!hasNotes ? (
                <div>
                  <Paragraph>
                    You may add any relevant notes about this person in relation
                    to your listing here.
                  </Paragraph>
                  <Paragraph secondary size="sm">
                    Any notes you add are for your own reference and will only
                    be visible to you.
                  </Paragraph>
                </div>
              ) : (
                <div className="italic">
                  <Paragraph>{form.values.notes}</Paragraph>
                </div>
              )}
              <div className="max-w-auto md:max-w-xs">
                <Button
                  label={`${hasNotes ? 'Edit' : 'Add'} notes`}
                  icon={hasNotes ? BiEdit : HiPlus}
                  category="primary"
                  size="base"
                  mode="manual"
                  onClick={() => handleToggleEditMode(true)}
                />
              </div>
            </>
          )}
        </div>
      )}
    </ConditionalWrapper>
  );
};

export default ListingUserNotesEditor;
