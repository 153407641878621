import {Router} from 'framework7/types';

import PublicServiceRequestDetailPage from 'pages/shared/service_requests/PublicServiceRequestDetailPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/jobs',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
    routes: [
      {
        path: ':serviceRequestId',
        id: 'PublicServiceRequest',
        async: resolveRoute({component: PublicServiceRequestDetailPage}),
      },
    ],
  },
];

export default routes;
