import {useMemo} from 'react';

import L, {LatLngTuple} from 'leaflet';
import {HiOutlineLocationMarker} from 'react-icons/hi';
import {
  AttributionControl,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
} from 'react-leaflet';

import {Card} from 'components_sb/layout';
import Listing from 'models/listings/Listing';

interface LocationCardProps {
  listing: Listing;
}

const LocationCard = ({listing}: LocationCardProps) => {
  const markerIcon = new L.Icon({
    iconUrl: require('assets/img/leaflet/marker-icon.png'),
    iconRetinaUrl: require('assets/img/leaflet/marker-icon-2x.png'),
    iconSize: [48, 72],
    iconAnchor: [24, 36],
  });

  const hasCoordinates = useMemo(
    () => listing.latitude && listing.longitude,
    [listing.latitude, listing.longitude],
  );

  const coordinates = useMemo(
    () =>
      hasCoordinates
        ? ([
            parseFloat(listing.latitude),
            parseFloat(listing.longitude),
          ] as LatLngTuple)
        : null,
    [hasCoordinates, listing.latitude, listing.longitude],
  );

  return !hasCoordinates ? null : (
    <Card icon={HiOutlineLocationMarker} title="Location">
      {/* MAP */}
      <div className="w-full h-auto">
        <div className="rounded-xl overflow-hidden">
          <MapContainer
            className="z-0"
            center={coordinates}
            zoom={15}
            style={{height: 400}}
            scrollWheelZoom={false}
            attributionControl={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <AttributionControl position="bottomright" prefix="Leaflet" />
            <Marker position={coordinates} icon={markerIcon}>
              <Popup>{listing.listingTitle}</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
    </Card>
  );
};

export default LocationCard;
