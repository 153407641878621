import {useCallback, useEffect, useState} from 'react';

import {Capacitor} from '@capacitor/core';

import useAuth from 'auth/provider/useAuth';
import ChatMessageBubble from 'components/chat2/ChatMessageBubble';
import useScrollPosition from 'hooks/useScrollPosition';
import User from 'models/users/User';
import {usePusherService} from 'services/PusherService';
import type {ChatChannel} from 'services/PusherService';

const ChatDetailBody = ({
  channel,
  users,
}: {
  channel: ChatChannel;
  users: User[];
}) => {
  const {currentUser} = useAuth();
  const {channels} = usePusherService();

  const [isLoadingEarlier, setIsLoadingEarlier] = useState(false);
  const [infiniteScrollEnabled, setInfiniteScrollEnabled] = useState(false);
  const [page, setPage] = useState(channel.lastFetchedPage || 0);

  const [messages, setMessages] = useState(channel.messages.slice().reverse());

  const updateMessages = useCallback(() => {
    setMessages(channel.messages.slice().reverse());
  }, [setMessages, channel]);

  useEffect(() => {
    updateMessages();
  }, [channels, updateMessages]);

  useEffect(() => {
    channel.markMessagesAsRead();
  }, [channel]);

  useEffect(() => {
    loadEarlier();
  }, []);

  useEffect(() => {
    if (page === 1 && messages.length > 0) {
      const el = document.getElementById('messages');

      if (Capacitor.isNativePlatform()) {
        el.scrollIntoView(false);
      } else {
        el.scrollTop = el.scrollHeight;
      }

      // This is a bit of a hack
      // But without this, it will load 2 pages
      // And then wont be scrolled to the bottom.
      setTimeout(() => {
        setInfiniteScrollEnabled(true);
      }, 1000);
    }
  }, [page, messages.length]);

  const loadEarlier = async () => {
    if (!isLoadingEarlier) {
      setIsLoadingEarlier(true);
      await channel.fetchMessages(page + 1);
      setPage((p) => p + 1);
      setIsLoadingEarlier(false);
    }
  };

  const scrollPosition = useScrollPosition();

  const hasMoreMessages =
    messages && messages.length >= 20 && !channel.isOutOfOlderMessages;

  useEffect(() => {
    if (scrollPosition <= 20 && hasMoreMessages && infiniteScrollEnabled) {
      loadEarlier();
    }
  }, [scrollPosition, hasMoreMessages, infiniteScrollEnabled]);

  return (
    <div
      id="messages"
      className="flex-1 flex flex-col justify-end space-y-4 py-2 bounded-x w-full">
      {hasMoreMessages && (
        <div className="flex justify-center" key="LOAD_MORE">
          Loading...
        </div>
      )}

      {messages &&
        messages.map((message) => {
          return (
            <div key={message.id}>
              <ChatMessageBubble
                message={message}
                senderUserId={currentUser.id}
                users={users}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ChatDetailBody;
