import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {useLocalStorageValue} from '@react-hookz/web';
import {GoogleOAuthProvider} from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import camelcaseKeys from 'camelcase-keys';
import {pick} from 'lodash';
import {useMutation, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import {useLocalStorage} from 'usehooks-ts';

import {ConfirmEmailFunction} from 'auth/actions/confirm-email';
import {DeleteAccountFunction} from 'auth/actions/delete-account';
import {GhostUserFunction} from 'auth/actions/ghost-user';
import {LogoutFunction} from 'auth/actions/logout';
import {RequestPasswordResetFunction} from 'auth/actions/request-password-reset';
import {ResetPasswordFunction} from 'auth/actions/reset-password';
import {SelectAccountTypeFunction} from 'auth/actions/select-account-type';
import {SetAvatarFunction} from 'auth/actions/set-avatar';
import {UpdateUserFunction} from 'auth/actions/update-user';
import {ValidatePasswordResetTokenFunction} from 'auth/actions/validate-password-reset-token';
import {AuthErrorCode} from 'auth/errors';
import {
  HistoricalUserData,
  convertHistoricalUserDataToNewFormat,
  userDataIsHistorical,
} from 'auth/types/HistoricalUserData';
import {UserSettings, userSettingsIsValidShape} from 'auth/types/UserSettings';
import parseAuthResponse from 'auth/utils/parse-auth-response';
import {SpinningLoader} from 'components_sb/feedback';
import {API_URL} from 'globals/app-globals';
import {useSpraypaintMiddleware} from 'hooks/useSpraypaintMiddleware';
import LandlordSubscription from 'models/payments/LandlordSubscription';
import LandlordProfile from 'models/users/LandlordProfile';
import RenterProfile from 'models/users/RenterProfile';
import User, {AccountRole} from 'models/users/User';
import {useCreditCards} from 'providers/CreditCardsProvider';
import useSubscriptions from 'providers/Subscriptions/hooks/useSubscriptions';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';
import F7Store from 'stores/F7Store';
import clearLocalStorageWithWhitelist from 'utilities/clearLocalStorageWithWhitelist';
import {saveResource} from 'utilities/SpraypaintHelpers';

import login, {LoginFunctionProps} from '../actions/login';
import register, {RegisterFunctionProps} from '../actions/register';
import {AuthResult} from '../types';
import {UserData, userDataIsValidShape} from '../types/UserData';
import useAuthCookiesPrimary, {AuthCookies} from './_useAuthCookies';
import useAuthCookiesIOS from './_useAuthCookiesIOS';

/**
 * Flag to force existing cookies to be reset on load if present.
 * This is to ensure that cookies have the correct attributes.
 */
const FORCE_RESET_COOKIES_ON_LOAD = true;

/**
 * Temporary drop-in hook to use local storage instead of cookies to resolve issues
 * with cookies not working in the production build of the capacitor app.
 */
const useAuthCookies = Capacitor.isNativePlatform()
  ? useAuthCookiesIOS
  : useAuthCookiesPrimary;

/**
 * The key for the local storage item that stores the current user data.
 */
const CURRENT_USER_DATA_LOCAL_STORAGE_KEY = 'current_user';

/**
 * The key for the local storage item that stores the current user settings.
 */
const CURRENT_USER_SETTINGS_LOCAL_STORAGE_KEY = 'current_user:settings';

/**
 * The key for the local storage item that stores the current user data.
 */
export const IS_GHOSTING_USER_LOCAL_STORAGE_KEY = 'ghosting';

/**
 * Keys for entries in local storage that should not be cleared
 * when logging out / changing users.
 */
const WHITELISTED_LOCAL_STORAGE_KEYS = [
  'listing-application-info',
  'new-rental-application-form',
];

interface GenerateUserSettingsFunctions {
  (args: {userData: UserData; existingUserSettings?: object}): UserSettings;
}

/**
 * Generates a default set of user settings conforming to the required type.
 * If the user has any existing settings but they do not conform to the type,
 * (i.e. from a previous version before new settings have been added), then we
 * can merge these settings with the default settings here.
 */
const generateUserSettings: GenerateUserSettingsFunctions = ({
  userData,
  existingUserSettings = null,
}): UserSettings => {
  // TODO: Merge existing settings

  return {
    activeAccountRole: userData.roles.length ? userData.roles[0] : null,
  };
};

/**
 * The value accessible via the useAuth hook.
 */
export interface AuthContextValue {
  /**
   * Whether there is a currently logged in user.
   */
  isLoggedIn: boolean;

  /**
   * The auth related cookies converted into a more usable format.
   */
  authCookies: AuthCookies;

  /**
   * Data for the current user.
   */
  currentUser: UserData | undefined;

  /**
   * The settings for the current user.
   */
  userSettings: UserSettings | undefined;

  /**
   * Register a new user.
   */
  register: (props: RegisterFunctionProps) => Promise<void>;

  /**
   * Log the user in.
   */
  login: (props: LoginFunctionProps) => Promise<void>;

  /**
   * Log the user out.
   */
  logout: LogoutFunction;

  /**
   * Ghost a user.
   */
  ghostUser: GhostUserFunction;

  /**
   * Whether a user is currently being ghosted.
   */
  isGhostingUser: boolean;

  /**
   * Delete the user's account.
   */
  deleteAccount: DeleteAccountFunction;

  /**
   * Confirm the email address for a user account.
   */
  confirmEmail: ConfirmEmailFunction;

  /**
   * Send an email to the user to allow them to reset their password.
   */
  requestPasswordReset: RequestPasswordResetFunction;

  /**
   * Check whether a password reset token is valid.
   */
  validatePasswordResetToken: ValidatePasswordResetTokenFunction;

  /**
   * Reset the user's password using a valid password reset token.
   */
  resetPassword: ResetPasswordFunction;

  /**
   * Update the name for the current user.
   */
  updateName: (name: string) => Promise<void>;

  /**
   * Update the details for an existing user account.
   */
  updateUser: UpdateUserFunction;

  /**
   * Updates the current account type and adds the role to the user if
   * not already present.
   */
  selectAccountType: SelectAccountTypeFunction;

  /**
   * Update the avatar for the current user.
   */
  setAvatar: SetAvatarFunction;
}

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue,
);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FunctionComponent<AuthProviderProps> = ({children}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const creditCards = useCreditCards();
  const subscriptionsContext = useSubscriptions();

  /**
   * We need to track when the auth has been initialised so that we know
   * when it is safe to render the app after initial configuration has
   * been performed.
   */
  const [initialised, setInitialised] = useState(false);

  /**
   * Initialise read/write functionality for auth cookies.
   */
  const {authCookiesPresent, authCookies, setAuthCookies, clearAuthCookies} =
    useAuthCookies();

  /**
   * Store the data for the current user in state rather than local storage as this
   * will be refetched upon each initial load of the app.
   */
  const [currentUserData, setCurrentUserData] = useState<UserData | undefined>(
    undefined,
  );

  /**
   * Attempt to read the legacy format for the current user data from local storage.
   * This will only be present if the user has not logged in since the change from
   * local storage to component state for the current user data.
   */
  const [legacyCurrentUserData] = useLocalStorage(
    CURRENT_USER_DATA_LOCAL_STORAGE_KEY,
    undefined,
  );

  /**
   * Clear the legacy current user data from local storage if present.
   */
  useEffect(() => {
    if (legacyCurrentUserData) {
      localStorage.removeItem(CURRENT_USER_DATA_LOCAL_STORAGE_KEY);
    }
  }, [legacyCurrentUserData]);

  /**
   * Configure read and write functionality for the current user settings in local storage.
   */
  const [currentUserSettings, setCurrentUserSettings] = useLocalStorage<
    UserSettings | undefined
  >(CURRENT_USER_SETTINGS_LOCAL_STORAGE_KEY, undefined);

  /**
   * Whether an entry for the current user settings exists in local storage.
   */
  const currentUserSettingsPresent = useMemo<boolean>(
    () => !!currentUserSettings,
    [currentUserSettings],
  );

  /**
   * Whether the current user settings are valid.
   */
  const currentUserSettingsValid = useMemo<boolean>(
    () =>
      !!currentUserSettingsPresent &&
      userSettingsIsValidShape(currentUserSettings),
    [currentUserSettingsPresent, currentUserSettings],
  );

  /**
   * Initialise the Spraypaint middleware context for managing auth headers.
   */
  const spraypaintMiddleware = useSpraypaintMiddleware();

  /**
   * Track when the user is logged in.
   */
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  /**
   * Configure read and write functionality for the flag in local storage indicating
   * whether a user is being ghosted.
   */
  const {value: isGhostingUser, set: setIsGhostingUser} =
    useLocalStorageValue<boolean>(IS_GHOSTING_USER_LOCAL_STORAGE_KEY, {
      defaultValue: false,
    });

  /**
   * Cleans up the auth state, by clearing the session and removing any auth
   * related cookies, local storage, and Spraypaint middleware.
   */
  const clearAuth = useCallback(async () => {
    /**
     * End the session.
     */
    if (authCookiesPresent) {
      try {
        await fetch(API_URL + '/users/logout.json', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
        });
      } catch (error) {
        console.error('Failed to clear session');
      }

      /**
       * Clear auth cookies.
       */
      clearAuthCookies();
    }

    /**
     * Log the user out of the Customerly integration.
     */
    const {customerly} = window as any;
    if (customerly && customerly.logout) {
      customerly.logout();
    }

    /**
     * We need to manually change this to false rather than relying on the
     * function that clears local storage below, otherwise the ghosting
     * user banner will not properly receive the state update.
     */
    if (isGhostingUser) {
      setIsGhostingUser(false);
    }

    /**
     * Clear all data from local storage except for the whitelisted properties.
     */
    clearLocalStorageWithWhitelist(WHITELISTED_LOCAL_STORAGE_KEYS);

    /**
     * Clear user related data from the F7 store.
     */
    F7Store.state.activeAccountRole = undefined;
    F7Store.state.name = undefined;
    F7Store.state.email = undefined;
    F7Store.state.unconfirmedEmail = undefined;

    /**
     * Reset Spraypaint middleware.
     */
    spraypaintMiddleware.resetHeaders();

    /**
     * Clear any cached queries.
     */
    const win = window as any;
    if (win.queryClient) {
      win.queryClient.clear();
    }

    /**
     * Reset the subscriptions context.
     */
    subscriptionsContext.reset();

    /**
     * Reset the credit cards context.
     */
    creditCards.reset();

    /**
     * Indicate that the user is now logged out.
     */
    setIsLoggedIn(false);

    /**
     * Set the logged in status in the F7 store for resolving routes.
     */
    F7Store.state.isLoggedIn = false;
  }, [
    creditCards,
    subscriptionsContext,
    authCookiesPresent,
    authCookies,
    clearAuthCookies,
    spraypaintMiddleware,
    isGhostingUser,
    setIsGhostingUser,
  ]);

  /**
   * Fetch the data for the current user if required.
   */
  const {mutateAsync: fetchUserData} = useMutation(
    ['user-data', {id: authCookies.userId}],
    async () => {
      const userDataResponse = await fetch(
        API_URL + `/users/${authCookies.userId}.json`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-EMAIL': authCookies.userEmail,
            'X-USER-TOKEN': authCookies.token,
          },
        },
      );

      if (!userDataResponse.ok) {
        throw new Error();
      }

      let userData = camelcaseKeys(
        pick((await userDataResponse.json()).data, [
          'id',
          'email',
          'name',
          'unconfirmed_email',
          'avatar',
          'roles',
          'confirmed',
        ]),
      );

      /**
       * Since the confirmed attribute is returned as a string, we need
       * to parse it to a boolean.
       */
      userData = {
        ...userData,
        confirmed: 'confirmed' in userData && userData.confirmed === 'true',
      };

      if (typeof userData === 'object' && userDataIsValidShape(userData)) {
        return userData;
      } else {
        throw new Error();
      }
    },
  );

  const migrateHistoricalUserData = useCallback(
    async (historicalUserData: HistoricalUserData) => {
      /**
       * Set the auth cookies from the historical user data.
       */
      const authCookiesFromLocalStorage = {
        userId: historicalUserData.id,
        userEmail: historicalUserData.email,
        token: historicalUserData.meta.authenticationToken,
      };
      setAuthCookies(authCookiesFromLocalStorage);

      /**
       * Convert the historical user data into the new format and set it
       * in local storage.
       */
      const migratedUserData =
        convertHistoricalUserDataToNewFormat(historicalUserData);
      setCurrentUserData(migratedUserData);

      /**
       * Set the user's name and email(s) in the F7 store for resolving routes.
       */
      F7Store.state.name = migratedUserData.name;
      F7Store.state.email = migratedUserData.email;
      F7Store.state.unconfirmedEmail = migratedUserData.unconfirmedEmail;

      let userSettings;
      try {
        userSettings = generateUserSettings({
          userData: migratedUserData,
          existingUserSettings: currentUserSettings,
        });
        setCurrentUserSettings(userSettings);
      } catch (error) {
        console.error('Error generating user settings:', error);
        await clearAuth();

        /**
         * Do not proceed.
         */
        return;
      }

      /**
       * Set the user's account role in the F7 store for resolving routes.
       */
      F7Store.state.activeAccountRole = userSettings.activeAccountRole;

      /**
       * Set the auth related headers for Spraypaint.
       */
      spraypaintMiddleware.setHeaders({
        'X-USER-TOKEN': authCookiesFromLocalStorage.token,
        'X-USER-EMAIL': authCookiesFromLocalStorage.userEmail,
        ...(userSettings.activeAccountRole
          ? {'X-ACCOUNT-TYPE': userSettings.activeAccountRole}
          : {}),
      });

      /**
       * Initialise the credit cards context for the current user.
       * It is import that this happens BEFORE initialising the subscriptions context.
       */
      await creditCards.initialise({currentUser: migratedUserData});

      /**
       * Initialise the subscriptions context for the current user.
       * It is import that this happens AFTER initialising the credit cards context.
       */
      await subscriptionsContext.initialise({currentUser: migratedUserData});

      /**
       * Indicate that the user is now logged in.
       */
      setIsLoggedIn(true);

      /**
       * Set the logged in status in the F7 store for resolving routes.
       */
      F7Store.state.isLoggedIn = true;
    },
    [
      creditCards,
      subscriptionsContext,
      clearAuth,
      currentUserSettings,
      setAuthCookies,
      spraypaintMiddleware,
      setCurrentUserData,
      setCurrentUserSettings,
    ],
  );

  /**
   * On initial mount, we need to check whether the auth cookies are present
   * and the validity of the current user data and settings in local storage,
   * and perform any necessary cleanup.
   */
  const handleInitialMount = useCallback(async () => {
    /**
     * Handle user data in the historical format (i.e. there are no auth cookies,
     * and all auth related data in the user data entry in local storage).
     */
    if (userDataIsHistorical(currentUserData)) {
      migrateHistoricalUserData(currentUserData);
      /**
       * Do not proceed.
       */
      return;
    }

    /**
     * Handle no auth cookies being present.
     */
    if (!authCookiesPresent) {
      /**
       * Ensure that all auth related state is cleared if there are
       * no cookies on mount but local data exists.
       */
      if (!!currentUserData || currentUserSettingsPresent) {
        await clearAuth();
      }

      /**
       * Auth initialisation is now complete.
       */
      setInitialised(true);

      /**
       * Do not proceed.
       */
      return;
    }

    /**
     * If the auth cookies are present but the flag to force reset the cookies is set,
     * then first replace the cookies before proceeding.
     */
    if (FORCE_RESET_COOKIES_ON_LOAD) {
      const currentAuthCookies = {...authCookies};
      clearAuthCookies();
      setAuthCookies(currentAuthCookies);
    }

    /**
     * If the current user data is not present or an invalid shape, we need to
     * fetch the data for the user from the backend and set it in local storage.
     */
    let userData = currentUserData;
    try {
      userData = await fetchUserData();
      setCurrentUserData(userData);
    } catch (error) {
      console.error('Error fetching data for user:', error);
      await clearAuth();

      /**
       * Auth initialisation is now complete.
       */
      setInitialised(true);

      /**
       * Do not proceed.
       */
      return;
    }

    /**
     * Set the user's name and email(s) in the F7 store for resolving routes.
     */
    F7Store.state.name = userData.name;
    F7Store.state.email = userData.email;
    F7Store.state.unconfirmedEmail = userData.unconfirmedEmail;

    /**
     * If the settings for the current user are also invalid, we need to generate
     * new settings for the user.
     */
    let userSettings = currentUserSettings;
    if (!currentUserSettingsValid) {
      try {
        userSettings = generateUserSettings({
          userData,
          existingUserSettings: currentUserSettings,
        });
        setCurrentUserSettings(userSettings);
      } catch (error) {
        console.error('Error generating user settings:', error);
        await clearAuth();

        /**
         * Auth initialisation is now complete.
         */
        setInitialised(true);

        /**
         * Do not proceed.
         */
        return;
      }
    }

    /**
     * Set the user's account role in the F7 store for resolving routes.
     */
    F7Store.state.activeAccountRole = userSettings.activeAccountRole;

    /**
     * Set the auth related headers for Spraypaint.
     */
    spraypaintMiddleware.setHeaders({
      'X-USER-TOKEN': authCookies.token,
      'X-USER-EMAIL': authCookies.userEmail,
      ...(userSettings.activeAccountRole
        ? {'X-ACCOUNT-TYPE': userSettings.activeAccountRole}
        : {}),
    });

    /**
     * Initialise the credit cards context for the current user.
     * It is import that this happens BEFORE initialising the subscriptions context.
     */
    await creditCards.initialise({currentUser: userData});

    /**
     * Initialise the subscriptions context for the current user.
     * It is import that this happens AFTER initialising the credit cards context.
     */
    await subscriptionsContext.initialise({currentUser: userData});

    /**
     * Indicate that the user is now logged in.
     */
    setIsLoggedIn(true);

    /**
     * Set the logged in status in the F7 store for resolving routes.
     */
    F7Store.state.isLoggedIn = true;

    /**
     * Auth initialisation is now complete.
     */
    setInitialised(true);

    /**
     * Do not proceed.
     */
    return;
  }, [
    creditCards,
    subscriptionsContext,
    authCookiesPresent,
    currentUserData,
    migrateHistoricalUserData,
    setAuthCookies,
    clearAuthCookies,
    currentUserSettings,
    fetchUserData,
    clearAuth,
    currentUserSettingsValid,
    setCurrentUserData,
    setCurrentUserSettings,
    currentUserSettingsPresent,
    spraypaintMiddleware,
    authCookies,
  ]);

  /**
   * Invoke initial mount handling.
   */
  useEffect(() => {
    handleInitialMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Handle the result from authenticating a user (via registering, logging in, ghosting, etc).
   */
  const handleAuthResult = useCallback(
    async ({userData, authToken}: AuthResult): Promise<void> => {
      /**
       * Set auth cookies.
       */
      setAuthCookies({
        userId: userData.id,
        userEmail: userData.email,
        token: authToken,
      });

      /**
       * Set other data in local storage.
       */
      setCurrentUserData(userData);

      /**
       * Set the user's name and email(s) in the F7 store for resolving routes.
       */
      F7Store.state.name = userData.name;
      F7Store.state.email = userData.email;
      F7Store.state.unconfirmedEmail = userData.unconfirmedEmail;

      /**
       * Generate and set initial user settings.
       */
      const userSettings = generateUserSettings({
        userData,
        existingUserSettings: currentUserSettings,
      });
      setCurrentUserSettings(userSettings);

      /**
       * Set the user's account role in the F7 store for resolving routes.
       */
      F7Store.state.activeAccountRole = userSettings.activeAccountRole;

      /**
       * Set the auth related headers for Spraypaint.
       */
      spraypaintMiddleware.setHeaders({
        'X-USER-TOKEN': authToken,
        'X-USER-EMAIL': userData.email,
        ...(userSettings.activeAccountRole
          ? {'X-ACCOUNT-TYPE': userSettings.activeAccountRole}
          : {}),
      });

      /**
       * Initialise the credit cards context for the current user.
       * It is import that this happens BEFORE initialising the subscriptions context.
       */
      await creditCards.initialise({currentUser: userData});

      /**
       * Initialise the subscriptions context for the current user.
       * It is import that this happens AFTER initialising the credit cards context.
       */
      await subscriptionsContext.initialise({currentUser: userData});

      /**
       * Indicate that the user is now logged in.
       */
      setIsLoggedIn(true);

      /**
       * Set the logged in status in the F7 store for resolving routes.
       */
      F7Store.state.isLoggedIn = true;
    },
    [
      creditCards,
      subscriptionsContext,
      setAuthCookies,
      setCurrentUserData,
      currentUserSettings,
      setCurrentUserSettings,
      spraypaintMiddleware,
    ],
  );

  /**
   * Handle registering a user.
   */
  const handleRegister = useCallback(
    async (props: RegisterFunctionProps): Promise<void> => {
      await handleAuthResult(await register(props));
    },
    [handleAuthResult],
  );

  /**
   * Handle logging a user in.
   */
  const handleLogin = useCallback(
    async (props: LoginFunctionProps): Promise<void> => {
      await handleAuthResult(await login(props));
    },
    [handleAuthResult],
  );

  /**
   * Handle logging a user out.
   */
  const handleLogout = useCallback<LogoutFunction>(async () => {
    if (isLoggedIn) {
      await clearAuth();
    } else {
      console.error('There is no current user to log out!');
    }
  }, [isLoggedIn, clearAuth]);

  /**
   * Handle ghosting a user.
   */
  const handleGhostUser = useCallback<GhostUserFunction>(
    async ({authResult, redirect}) => {
      /**
       * If there is already a user logged in, we need to log the existing
       * user out first before ghosting the new user.
       */
      if (isLoggedIn) {
        console.warn('A user is already logged in, logging them out first...');
        await handleLogout();
        console.log('Logged out existing user.');
      }

      console.log(`Logging in as ${authResult.userData.email}...`);

      /**
       * Set the auth cookies and user data in local storage.
       */
      await handleAuthResult(authResult);

      /**
       * Identify that the session is for ghosting a user, this is so we can prevent
       * performing some tracking / events if ghosting and to display a warning.
       */
      setIsGhostingUser(true);

      if (redirect) {
        router.navigate(redirect);
      } else {
        router.navigate('/');
      }
    },
    [isLoggedIn, handleLogout, handleAuthResult, setIsGhostingUser, router],
  );

  /**
   * Delete the user's account.
   */
  const handleDeleteAccount = useCallback<DeleteAccountFunction>(async () => {
    /**
     * Check if there is a current user to log out.
     */
    if (!isLoggedIn) {
      throw new Error('There is no user to delete.');
    }

    /**
     * Attempt to delete the user.
     */
    try {
      const user = new User({id: authCookies.userId});
      user.isPersisted = true;
      const result = await user.destroy();
      if (result) {
        await clearAuth();
        toast.success('Your account has been successfully deleted!');
        document.location.replace(`${document.location.origin}/`);
      } else {
        throw new Error(`Error deleting user account`);
      }
    } catch (error) {
      /**
       * Error deleting the user.
       */
      Sentry.withScope((scope) => {
        scope.setTag('action', 'delete_user');
        Sentry.captureException(error);
      });
      throw error;
    }

    toast.success('Your account has been successfully deleted!');

    /**
     * Log the user out of the Customerly integration.
     */
    const {customerly} = window as any;
    if (customerly && customerly.logout) {
      customerly.logout();
    }

    /**
     * Clear the current user data and any settings from local storage.
     */
    clearLocalStorageWithWhitelist(WHITELISTED_LOCAL_STORAGE_KEYS);

    /**
     * Redirect the user to the login page.
     */
    router.navigate('/');
  }, [isLoggedIn, authCookies, router, clearAuth]);

  /**
   * Confirm the user's email address.
   */
  const handleConfirmEmail = useCallback<ConfirmEmailFunction>(
    async ({confirmationCode}) => {
      /**
       * Check if the user to update is logged in.
       */
      if (!isLoggedIn) {
        throw new Error('There is no user logged in.');
      }

      const response = await fetch(
        API_URL + `/users/${[authCookies.userId]}/confirm_email.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
          body: JSON.stringify({code: confirmationCode}),
        },
      );

      if (response.ok) {
        /**
         * Parse the response data.
         */
        const {email} = await response.json();

        /**
         * Check if the email confirmation is for a pending email address (i.e. they have changed
         * their email address and so the current email address for the account is not the email
         * address that is being confirmed).
         */
        const emailChanged = email !== currentUserData.email;

        /**
         * If the user has changed their email and are confirming a different email to the one
         * that is currently set in cookies and local storage, then we need to perform a full
         * update to the auth state. If the email remained the same, we only need to update
         * local storage to indicate that the email is now confirmed.
         */
        if (emailChanged) {
          /**
           * Manually construct the auth result, including the new email address.
           */
          await handleAuthResult({
            userData: {
              ...currentUserData,
              email,
              confirmed: true,
            },
            authToken: authCookies.token,
          });

          /**
           * Force the user data on the account page to be refetched.
           */
          queryClient.invalidateQueries('user-account-page');
        } else {
          /**
           * Indicate that the email is confirmed in the current user data in local storage.
           */
          setCurrentUserData((current) => ({...current, confirmed: true}));
        }
      } else {
        const {errors} = await response.json();
        if (
          typeof errors === 'string' &&
          errors === 'Incorrect verification code'
        ) {
          throw [AuthErrorCode.INVALID_EMAIL_CONFIRMATION_CODE];
        } else {
          /**
           * Throw a generic error if no reason was included in the response.
           */
          throw new Error('Error confirming email address.');
        }
      }
    },
    [
      queryClient,
      isLoggedIn,
      authCookies,
      setCurrentUserData,
      currentUserData,
      handleAuthResult,
    ],
  );

  /**
   * Send an email to the user to reset their password.
   */
  const handleRequestPasswordReset = useCallback<RequestPasswordResetFunction>(
    async (email: string) => {
      const response = await fetch(API_URL + '/users/password.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({user: {email}}),
      });

      if (response.ok) {
        TrackingService.trackEvent(TrackingService.Event.ForgotPassword);
      } else {
        const {errors} = await response.json();

        /**
         * For security, we don't want to throw an error when an account with the provided
         * email doesn't exist, instead we return to allow this function to succeed.
         */
        if (
          !!errors &&
          typeof errors === 'object' &&
          'email' in errors &&
          Array.isArray(errors.email) &&
          errors.email.includes('not found')
        ) {
          return;
        }

        /**
         * Otherwise, throw a generic error.
         */
        throw new Error('Error requesting password reset');
      }
    },
    [],
  );

  /**
   * Reset the user's password using a valid password reset token.
   */
  const handleResetPassword = useCallback<ResetPasswordFunction>(
    async ({passwordResetToken, newPassword}) => {
      try {
        const response = await fetch(API_URL + '/users/password.json', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: {
              resetPasswordToken: passwordResetToken,
              password: newPassword,
              passwordConfirmation: newPassword,
            },
          }),
        });

        if (!response.ok) {
          const {errors} = await response.json();
          if (typeof errors === 'object' && 'reset_password_token' in errors) {
            throw [AuthErrorCode.INVALID_EMAIL_CONFIRMATION_CODE];
          } else {
            /**
             * Throw a generic error if no reason was included in the response.
             */
            throw new Error('Error confirming email address.');
          }
        }
      } catch (error) {
        toast.error('Sorry, there was an error resetting your password.');
      }
    },
    [],
  );

  /**
   * Validates whether a provided password reset token is still valid
   * and has not expired or been overwritten by a subsequent password reset.
   * @param token The password reset token included in the email to the user.
   */
  const handleValidatePasswordResetToken =
    useCallback<ValidatePasswordResetTokenFunction>(async (token: string) => {
      // TODO: Enable below once token validity endpoint has been created
      // ========================================================================
      // const response = await fetch(API_URL + '/users/password/token.json', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({token: token}),
      // });
      // try {
      //   const data = await response.json();
      //   return !data.expired;
      //   return true;
      // } catch (error) {
      //   // TODO: Log error in Sentry
      //   return false;
      // }
      // ========================================================================
      token; // making eslint happy. Dont want to remove the param itself in case it breaks.
      return true;
    }, []);

  // TODO: The below function only updates the name in local storage - to ensure the data
  // stays correct and insync, the request to update the name should be refactored to instead
  // be performed belows
  const handleUpdateName = useCallback(
    async (newName: string) => {
      setCurrentUserData((current) => ({...current, name: newName}));
    },
    [setCurrentUserData],
  );

  /**
   * Update details for an existing user account.
   */
  const handleUpdateUser = useCallback<UpdateUserFunction>(
    async (newUserDetails, options = {}) => {
      /**
       * Check if the user to update is logged in.
       */
      if (!isLoggedIn) {
        throw new Error('There is no user logged in.');
      }

      const hasChangedEmail = newUserDetails.email !== authCookies.userEmail;

      /**
       * Attempt to update the user.
       */
      const {useJsonApi} = options;
      let response;
      if (useJsonApi) {
        /**
         * The JSON API endpoint does not require the user's current password to
         * perform the changes.
         */
        response = await fetch(
          `${API_URL}/users/${currentUserData.id}.jsonapi`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-TOKEN': authCookies.token,
              'X-USER-EMAIL': authCookies.userEmail,
            },
            body: JSON.stringify({
              data: {
                type: 'users',
                id: currentUserData.id,
                attributes: newUserDetails,
              },
            }),
          },
        );
      } else {
        /**
         * The standard API endpoint requires the user's current password to
         * perform the changes.
         */
        response = await fetch(API_URL + `/users.json`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-USER-TOKEN': authCookies.token,
            'X-USER-EMAIL': authCookies.userEmail,
          },
          body: JSON.stringify({user: newUserDetails}),
        });
      }

      /**
       * We attempt to parse the response to ensure it is valid, but we don't need to update
       * anything locally so we don't use the result.
       */
      await parseAuthResponse(response, authCookies.token);

      /**
       * If the user changed their email address, we need to add the unconfirmed email
       * address to the user's local state and indicate that their address is now
       * unconfirmed.
       */
      if (hasChangedEmail) {
        setCurrentUserData((current) => ({
          ...current,
          unconfirmedEmail: newUserDetails.email,
          confirmed: false,
        }));
      }
    },
    [isLoggedIn, authCookies, setCurrentUserData, currentUserData?.id],
  );

  /**
   * Updates the current account type and adds the role to the user if
   * not already present.
   */
  const handleSelectAccountType = useCallback<SelectAccountTypeFunction>(
    async (accountType) => {
      if (!isLoggedIn) {
        throw Error('Cannot select account type - there is no user logged in.');
      }

      /**
       * Determine if the current user already has the account type set on their account.
       */
      const hasRole = currentUserData.roles.includes(accountType);

      /**
       * If the current user does not yet have the selected role, we need to add it to
       * their account.
       */
      if (!hasRole) {
        /**
         * Determine the profile model to user for the selected account type.
         */
        let ProfileModel;
        if (accountType === AccountRole.Landlord) {
          ProfileModel = LandlordProfile;
        } else if (accountType === AccountRole.Renter) {
          ProfileModel = RenterProfile;
        } else {
          throw new Error('Profile model not found for selected account type.');
        }

        /**
         * Create a profile for the selected account type.
         */
        const profile = new ProfileModel({userId: authCookies.userId});

        /**
         * Save the new profile.
         */
        if (
          !(await saveResource(profile, {
            showErrorToast: false,
            showSuccessToast: false,
          }))
        ) {
          throw new Error('Failed to save new profile for user account.');
        }
      }

      /**
       * Set the current account type in the user's settings to the selected account type.
       */
      setCurrentUserSettings((current) => ({
        ...current,
        activeAccountRole: accountType,
      }));

      /**
       * Set the user's account role in the F7 store for resolving routes.
       */
      F7Store.state.activeAccountRole = accountType;
    },
    [isLoggedIn, currentUserData, authCookies, setCurrentUserSettings],
  );

  /**
   * Sets the user's avatar.
   * TODO: This is currently a local-only update and should be refactored so
   * that the avatar update on the user is performed here, and the current user is
   * then set using the customer data within the response of the update.
   */
  const handleSetAvatar = useCallback<SetAvatarFunction>(
    async (avatar) => {
      /**
       * Check if the user to update is logged in.
       */
      if (!isLoggedIn) {
        throw new Error('There is no user logged in.');
      }

      setCurrentUserData((current) => ({...current, avatar}));

      toast.success('Your profile picture has been successfully updated!');
    },
    [isLoggedIn, setCurrentUserData],
  );

  const contextValue = useMemo(
    () => ({
      // isLoggingIn,
      // isLoggingOut,
      isLoggedIn,
      authCookies,
      currentUser: currentUserData,
      userSettings: currentUserSettings,
      register: handleRegister,
      login: handleLogin,
      logout: handleLogout,
      ghostUser: handleGhostUser,
      isGhostingUser,
      deleteAccount: handleDeleteAccount,
      confirmEmail: handleConfirmEmail,
      requestPasswordReset: handleRequestPasswordReset,
      resetPassword: handleResetPassword,
      validatePasswordResetToken: handleValidatePasswordResetToken,
      updateName: handleUpdateName, // TODO: Refactor to use the updateUser function
      updateUser: handleUpdateUser,
      selectAccountType: handleSelectAccountType,
      setAvatar: handleSetAvatar,
    }),
    [
      // isLoggingIn,
      // isLoggingOut,
      isLoggedIn,
      authCookies,
      currentUserData,
      currentUserSettings,
      handleRegister,
      handleLogin,
      handleLogout,
      handleGhostUser,
      isGhostingUser,
      handleDeleteAccount,
      handleConfirmEmail,
      handleRequestPasswordReset,
      handleResetPassword,
      handleValidatePasswordResetToken,
      handleUpdateName,
      handleUpdateUser,
      handleSelectAccountType,
      handleSetAvatar,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <GoogleOAuthProvider clientId="469157937967-86ntkk49m2mjqbf571u9mmkdqh0dae18.apps.googleusercontent.com">
        {!initialised ? (
          <div className="w-full h-full flex items-center justify-center">
            <SpinningLoader color="brand" size="lg" />
          </div>
        ) : (
          children
        )}
      </GoogleOAuthProvider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
