import {useCallback, useEffect} from 'react';

import {Capacitor} from '@capacitor/core';
import {CapacitorUpdater} from '@capgo/capacitor-updater';

import AppStoreUpdateAvailableModal from 'components/modals/AppStoreUpdateAvailableModal';
import AppUpdatingModal from 'components/modals/AppUpdatingModal';
import {Modal} from 'components_sb/layout';

const {useModal} = Modal.Imperative;

const APP_STORE_MESSAGE = '__app_store_update_available__';

const AppUpdateManager = ({children}: {children: React.ReactNode}) => {
  const openModal = useModal();

  const update = useCallback(async () => {
    try {
      const latestVersion = await CapacitorUpdater.getLatest();

      if (
        latestVersion &&
        latestVersion.message &&
        latestVersion.message === APP_STORE_MESSAGE
      ) {
        openModal(AppStoreUpdateAvailableModal, {latestVersion});
      } else if (
        latestVersion &&
        latestVersion.url &&
        latestVersion.url.length > 0
      ) {
        openModal(AppUpdatingModal, {latestVersion});
      }
    } catch (e) {
      console.log(e);
    }
    // Dont add openModal to the dependency array, otherwise it will
    // cause infinitely open and close the modal.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      update();
    }
  }, [update]);

  return <>{children}</>;
};

export default AppUpdateManager;
