import {Router} from 'framework7/types';

import DemoInspectionDetailPage from 'pages/demo/in_app/DemoInspectionDetailPage';
import DemoPropertyDetailPage from 'pages/demo/in_app/DemoPropertyDetailPage';
import DemoServiceRequestDetailPage from 'pages/demo/in_app/DemoServiceRequestDetailPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER, LOGGED_IN, IS_LANDLORD} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/demo',
    async: resolveRoute(
      {conditions: [NEVER]},
      {redirect: '/demo/properties/1'},
    ),
    routes: [
      {
        path: 'properties',
        async: resolveRoute(
          {conditions: [NEVER]},
          {redirect: '/demo/properties/1'},
        ),
        routes: [
          {
            path: ':propertyId',
            id: 'DemoProperty',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: DemoPropertyDetailPage,
            }),
            routes: [
              {
                path: 'inspections',
                async: resolveRoute(
                  {conditions: [NEVER]},
                  {redirect: '/demo/properties/1/inspections/1'},
                ),
                routes: [
                  {
                    path: ':inspectionId',
                    id: 'DemoInspection',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: DemoInspectionDetailPage,
                    }),
                  },
                ],
              },
              {
                path: 'service-requests',
                async: resolveRoute(
                  {conditions: [NEVER]},
                  {redirect: '/demo/properties/1/service-requests/1'},
                ),
                routes: [
                  {
                    path: ':serviceRequestId',
                    id: 'DemoServiceRequest',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: DemoServiceRequestDetailPage,
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
