import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  useFormik,
  FormikConfig,
  FormikValues,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import ChattelsField from 'components/forms_fields/ChattelsField';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import StepContainer from '../common/StepContainer';

const ChattelsStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartChattelsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: any, actions: FormikHelpers<any>) => {
      setSubmitting(true);

      /**
       * Set changes on the property.
       */
      property.assignAttributes(formValues);
      property.lastOnboardingStepCompleted = 'new_chattels';
      property.setProfileStepAsCompleted('property_chattels');

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteChattelsStep,
        {
          propertyId: property.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        chattels: property.chattels || {},
        courtesyChattels: property.courtesyChattels || {},
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        chattels: Yup.object().required().label('Chattels'),
        courtesyChattels: Yup.object().optional().label('Courtesy Chattels'),
      }),
    }),
    [handleSubmit, property],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer title="Add the chattels for your property" subtitle="">
      <ChattelsField
        labelProps={{title: 'Chattels', size: 'xl'}}
        formik={form}
        name="chattels"
      />
      <ChattelsField
        labelProps={{
          title: 'Courtesy Chattels',
          size: 'xl',
          helpText:
            'Courtesy chattels are provided by the landlord under the understanding that the landlord is not responsible for their maintenance. An example of a courtesy chattel is a BBQ a landlord has left on their rental property for a tenant to use throughout their tenancy.',
        }}
        formik={form}
        name="courtesyChattels"
      />
    </StepContainer>
  );
};

export default ChattelsStep;
