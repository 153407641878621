import CreditCardInput from 'components_sb/input/CreditCardInput/CreditCardInput';
import {ModalDefinition} from 'components_sb/layout';

const AddCreditCardModal: ModalDefinition = {
  title: 'Add a credit/debit card',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: true,
      },
    ],
  },
  ContentComponent: CreditCardInput,
};

export default AddCreditCardModal;
