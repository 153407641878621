import {FunctionComponent, useMemo} from 'react';

import clsx from 'clsx';

import {Action, actionIsButton, actionIsLink} from 'types/actions';
import getLinkComponent from 'utilities/getLinkComponent';

interface ActionButtonProps {
  action: Action;
}

const ActionButton: FunctionComponent<ActionButtonProps> = ({action}) => {
  const {label, icon: Icon, description} = action;

  const classes = useMemo(
    () =>
      clsx(
        'flex-1',
        'relative flex flex-row justify-start items-center',
        'border-2 cursor-pointer',
        'rounded-xl',
        'transition-all duration-200',
        'scale-100',
        'active:scale-95',
        'ring-0',
        'focus:ring-2',
        'gap-x-4 p-6',
        'text-brand-500',
        'border-brand-500 hover:border-brand-600 active:border-brand-500',
        'bg-transparent hover:bg-brand-100 hover:bg-opacity-20',
      ),
    [],
  );

  const content = useMemo(
    () => (
      <>
        {Icon && (
          <div className="flex-shrink-0">
            {typeof Icon === 'string' ? (
              <img src={Icon} alt={label} className={'w-8 h-8'} />
            ) : (
              <Icon
                className={clsx(
                  'transition-all duration-200',
                  'text-brand-500',
                  'w-8 h-8',
                )}
              />
            )}
          </div>
        )}
        <div
          className={clsx(
            'text-left flex flex-col gap-y-1',
            'transition-all duration-200',
            'text-brand-500',
          )}>
          <div className="font-medium leading-snug">{label}</div>
          {description && (
            <p className={clsx('text-xs opacity-70')}>{description}</p>
          )}
        </div>
      </>
    ),
    [Icon, description, label],
  );

  if (actionIsButton(action)) {
    return (
      <button className={classes} onClick={action.onClick}>
        {content}
      </button>
    );
  }

  if (actionIsLink(action)) {
    const LinkComponent = getLinkComponent(action.linkTo);
    return (
      <LinkComponent className={classes} href={action.linkTo}>
        {content}
      </LinkComponent>
    );
  }

  return null;
};

export default ActionButton;
