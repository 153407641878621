import {HttpError} from 'components_sb/miscellaneous';

const NotFoundPage = (): JSX.Element => (
  <HttpError
    code={404}
    title="Page not found"
    description="The page you are looking for was not found."
  />
);

export default NotFoundPage;
