import {HTMLProps} from 'react';

import clsx from 'clsx';
import {type FormikProps} from 'formik';

import {InlineError} from 'components_sb/feedback';
import {FieldLabel} from 'components_sb/typography';
import TrackingService from 'services/TrackingService';

interface PseudoBooleanFieldProps extends HTMLProps<HTMLSelectElement> {
  formik: FormikProps<any>;
  helpText?: string;
  size?: any;
}

const PseudoBooleanField = ({
  formik,
  label,
  name,
  helpText,
  size = 'md',
  ...rest
}: PseudoBooleanFieldProps) => {
  // const classN = formik.errors[name] ? ' border border-red-500' : '';
  // if (rest.className) {
  //   rest.className += classN;
  // } else {
  //   rest.className = classN;
  // }
  // rest.className = 'select select-bordered w-auto' + rest.className;

  return (
    <div>
      <div className="flex justify-between items-center">
        {label && (
          <FieldLabel title={label} htmlFor={name} helpText={helpText} />
        )}

        <select
          name={name}
          onChange={(e) => {
            formik.setFieldValue(name, e.target.value);
            TrackingService.trackEvent(TrackingService.Event.SelectOption, {
              field: name,
            });
          }}
          value={formik.values[name]}
          className={clsx(
            'box-border',
            'transition-all duration-300',
            'bg-brand-50 hover:bg-brand-75 ',
            'placeholder-brand-800 placeholder-opacity-30',
            'outline-none border-none ring-1 ring-brand-75',
            'focus:ring-2 focus:ring-brand-500',

            size === 'md' &&
              clsx('text-base', 'pr-8', 'pl-4', 'h-12', 'rounded-lg'),
          )}
          {...rest}>
          <option value="Unspecified">Unspecified</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
      </div>
      <InlineError error={formik.errors[name]} />
    </div>
  );
};

export default PseudoBooleanField;
