import {useCallback, useContext, useRef, useState} from 'react';

import {PhotographIcon} from '@heroicons/react/outline';
import TextareaAutosize from 'react-textarea-autosize';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import ChatMessage from 'models/ChatMessage';
import User from 'models/users/User';
import {FullScreenLoaderContext} from 'providers/FullScreenLoader';
import type {ChatChannel} from 'services/PusherService';
import TrackingService from 'services/TrackingService';

const ChatInputBox = ({channel}: {channel: ChatChannel}) => {
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const textarea = useRef<HTMLTextAreaElement>(null);

  const fullScreenLoader = useContext(FullScreenLoaderContext);

  const {currentUser} = useAuth();

  const addMessageToStore = useCallback(
    (savedMessage: ChatMessage) => {
      savedMessage.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
      });
      channel.addSentMessage(savedMessage);
    },
    [channel, currentUser],
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitting(true);

    if (message.length > 0) {
      const chatMessage = new ChatMessage({
        content: message,
        chatableId: channel.model.id,
        chatableType: channel.identifier.replace('Channel', ''),
      });
      const result = await chatMessage.save();

      if (result) {
        addMessageToStore(chatMessage);
        TrackingService.trackEvent(TrackingService.Event.SendTextChatMessage);
      } else {
        toast.error('There was an issue sending your message');
      }
    }

    setMessage('');
    setSubmitting(false);
  };

  const handleImageRead = async (event: any) => {
    setSubmitting(true);
    fullScreenLoader.activate();
    try {
      const file = event.target.files[0];

      const reader = new FileReader();

      const image = new Image();
      const canvas = document.createElement('canvas');

      const width = 2000;
      const height = 2000;

      reader.onload = (readerEvent) => {
        const base64 = readerEvent.target.result.toString();

        image.onload = async () => {
          canvas.width = 2000;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          const dataUrl = canvas.toDataURL('image/jpeg');

          const message = new ChatMessage({
            media: dataUrl,
            chatableId: channel.model.id,
            chatableType: channel.identifier.replace('Channel', ''),
          });
          const result = await message.save();
          setSubmitting(false);
          fullScreenLoader.deactivate();

          if (result) {
            addMessageToStore(message);
            TrackingService.trackEvent(
              TrackingService.Event.SendImageChatMessage,
            );
          } else {
            toast.error('There was an issue sending your message');
          }
        };

        image.src = base64;
      };

      reader.readAsDataURL(file);
    } catch (e) {
      console.log(e);
      fullScreenLoader.deactivate();
    }
  };

  return (
    <div className="w-full relative bounded-x">
      <form className="relative flex shrink" onSubmit={handleSubmit}>
        <TextareaAutosize
          placeholder="Your message here..."
          className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-6 bg-gray-100 rounded-xl border-gray-200 pr-20"
          value={message}
          minRows={2}
          maxRows={10}
          ref={textarea}
          onChange={({target}) => {
            setMessage(target.value);
          }}
        />
        <input
          className="hidden"
          type="file"
          id="chat-file-upload"
          accept=".png,.jpeg,.jpg"
          onChange={handleImageRead}
          disabled={submitting}
        />
        <div className="absolute right-0 items-center inset-y-0 flex">
          <label
            htmlFor="chat-file-upload"
            className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none mr-1 cursor-pointer">
            <PhotographIcon className="w-6 h-6" />
          </label>
          <button
            type="submit"
            disabled={submitting}
            className="inline-flex items-center justify-center rounded-full h-10 w-10 mr-2 transition duration-500 ease-in-out text-white bg-primary hover:bg-primary-focus focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5 transform rotate-90">
              <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatInputBox;
