import {OnlyAvailableOnWeb} from 'components_sb/miscellaneous';
import {SHOW_BILLING_COMMS} from 'globals/app-globals';
import {Page} from 'router/components';

import AboutBillingSection from './AboutBillingSection/AboutBillingSection';
import CreditCardsSection from './CreditCardsSection';
import FreePeriodSection from './FreePeriodSection';
import PropertiesBillingSection from './PropertiesBillingSection/PropertiesBillingSection';

const BillingDetailsPage = () => {
  return (
    <Page
      title={SHOW_BILLING_COMMS ? 'Billing' : 'Account'}
      centred={!SHOW_BILLING_COMMS}>
      {() =>
        !SHOW_BILLING_COMMS ? (
          <OnlyAvailableOnWeb />
        ) : (
          <>
            <AboutBillingSection />
            <FreePeriodSection />
            <CreditCardsSection />
            <PropertiesBillingSection />
          </>
        )
      }
    </Page>
  );
};

export default BillingDetailsPage;
