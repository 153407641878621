import React, {useEffect, useMemo} from 'react';

import clsx from 'clsx';
import {
  HiOutlineChartPie,
  HiOutlineChat,
  HiOutlineCog,
  HiOutlineHome,
  HiOutlineSearch,
} from 'react-icons/hi';

import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

type Tab = {
  title: string;
  path: string;
  icon: any;
};

type LinksType = Record<string, Tab[]>;

const Links: LinksType = {
  Landlord: [
    {
      title: 'Dashboard',
      path: '/',
      icon: HiOutlineHome,
    },
    {
      title: 'Chat',
      path: '/chat',
      icon: HiOutlineChat,
    },
    {
      title: 'Financials',
      path: '/financials',
      icon: HiOutlineChartPie,
    },
    {
      title: 'Settings',
      path: '/account/settings',
      icon: HiOutlineCog,
    },
  ],
  Renter: [
    {
      title: 'Dashboard',
      path: '/',
      icon: HiOutlineHome,
    },
    {
      title: 'Chat',
      path: '/chat',
      icon: HiOutlineChat,
    },
    {
      title: 'Listings',
      path: '/listings',
      icon: HiOutlineSearch,
    },
    {
      title: 'Settings',
      path: '/account/settings',
      icon: HiOutlineCog,
    },
  ],
};

const BottomTabBar = () => {
  const {activeAccountRole} = useLocalUserSettings();

  const router = useRouter();
  const route = useRoute();

  /**
   * Find the value of the bottom safe area from the CSS variable defined by
   * Framework7 and parse to a number.
   */
  const safeAreaBottom = useMemo(() => {
    /**
     * Get the value as a string (i.e. "10px").
     */
    const stringValue = getComputedStyle(document.body).getPropertyValue(
      '--f7-safe-area-bottom',
    );
    /**
     * Attempt to parse the string.
     */
    if (
      !!stringValue &&
      typeof stringValue === 'string' &&
      stringValue.includes('px')
    ) {
      return Number(stringValue.split('px')[0]);
    }
    return 0;
  }, []);

  /**
   * Determine the height for the toolbar.
   */
  const toolbarHeight = useMemo(() => {
    const baseHeight = 50;
    const minimumPadding = 12; // 12px (Tailwind pb-3)
    return (
      baseHeight +
      (safeAreaBottom > minimumPadding ? 0 : minimumPadding - safeAreaBottom)
    );
  }, [safeAreaBottom]);

  /**
   * Apply the custom toolbar height to the CSS variable if not yet applied.
   */
  useEffect(() => {
    const stringValue = `${toolbarHeight}px`;
    if (
      getComputedStyle(document.body).getPropertyValue(
        '--f7-toolbar-height',
      ) !== stringValue
    ) {
      document.documentElement.style.setProperty(
        '--f7-toolbar-height',
        stringValue,
      );
    }
  }, [toolbarHeight]);

  /**
   * Do not render if there is not active account role, as we are unable to
   * determine which tabs should shown.
   */
  if (!activeAccountRole) {
    return null;
  }

  return (
    <div
      className={clsx(
        'flex-1',
        'flex flex-row',
        'items-start justify-between',
        'px-4 pt-3',
      )}>
      {Links[activeAccountRole].map((tab) => {
        const isActive = tab.path === route.path;
        return (
          <div
            className="flex flex-col items-center justify-center gap-y-1"
            key={tab.path}
            onClick={() => router.navigate(tab.path, {transition: 'f7-fade'})}>
            <tab.icon
              className={clsx(
                'w-5 h-5 mb-0',
                'transition-all duration-300',
                isActive
                  ? 'text-brand-50 opacity-100'
                  : 'text-brand-200 opacity-70',
              )}
            />
            <span
              className={clsx(
                'text-xxs',
                'font-medium',
                'transition-all duration-300',
                isActive
                  ? 'text-brand-50 opacity-100'
                  : 'text-brand-200 opacity-70',
              )}>
              {tab.title}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default BottomTabBar;
