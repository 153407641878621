import queryString from 'query-string';
import {QueryParamAdapter, QueryParamAdapterComponent} from 'use-query-params';

import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

/**
 * This is a custom adapter for the use-query-params library for compatibility
 * with Framework7's router, since the library only supports React Router and
 * Reach Router out of the box.
 * Refer to: https://github.com/pbeshai/use-query-params
 */
const F7QueryParamAdapter: QueryParamAdapterComponent = ({children}) => {
  const router = useRouter();
  const route = useRoute();

  const adapter: QueryParamAdapter = {
    replace: (location) => {
      router.updateCurrentUrl(route.path + location.search || '?');
    },
    push: (location) => {
      router.navigate(route.path + location.search || '?', {
        animate: false,
        props: route.props,
      });
    },
    get location() {
      return {
        search: queryString.stringify(route.query),
        state: route.props,
      };
    },
  };

  return children(adapter);
};

export default F7QueryParamAdapter;
