import {AccountRole} from 'models/users/User';

import type {VisibilityConditionFunction} from './types';

/**
 * A predefiend set of functions conditionally determine whether a
 * nav link item should be visible.
 */

/**
 * Any type of user is logged in.
 */
export const LOGGED_IN: VisibilityConditionFunction = ({isLoggedIn}) =>
  isLoggedIn;

/**
 * A logged in user has set an active account role.
 */
export const HAS_ACTIVE_ACCOUNT_ROLE: VisibilityConditionFunction = ({
  isLoggedIn,
  activeAccountRole,
}) => isLoggedIn && activeAccountRole !== null;

/**
 * No user user is logged in.
 */
export const LOGGED_OUT: VisibilityConditionFunction = ({isLoggedIn}) =>
  !isLoggedIn;

/**
 * A user is logged in and has the Landlord role.
 */
export const USER_IS_LANDLORD: VisibilityConditionFunction = ({
  isLoggedIn,
  activeAccountRole,
}) => isLoggedIn && activeAccountRole === AccountRole.Landlord;

/**
 * A user is logged in and has the Renter role.
 */
export const USER_IS_RENTER: VisibilityConditionFunction = ({
  isLoggedIn,
  activeAccountRole,
}) => isLoggedIn && activeAccountRole === AccountRole.Renter;
