import {ComponentType} from 'react';

import {Router} from 'framework7/types';

import {AccountRole} from 'models/users/User';
import PageNotFound from 'pages/shared/not-found';
import F7Store from 'stores/F7Store';
import {emailIsApplePrivateRelay} from 'utilities/apple-private-relay';

import withProviders from './withProviders';

interface AsyncComponentFunction {
  (ctx: Router.RouteCallbackCtx): void;
}

/**
 * Available conditions for a route to be resolved.
 */
export enum RouteCondition {
  ALWAYS = 'ALWAYS',
  NEVER = 'NEVER',
  LOGGED_IN = 'LOGGED_IN',
  LOGGED_OUT = 'LOGGED_OUT',
  IS_LANDLORD = 'IS_LANDLORD',
  IS_RENTER = 'IS_RENTER',
  NO_ACCOUNT_ROLE = 'NO_ACCOUNT_ROLE',
  MISSING_REQUIRED_ACCOUNT_DETAILS = 'MISSING_REQUIRED_ACCOUNT_DETAILS',
  PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION = 'PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION',
}

/**
 * Whether only one or all of the provided conditions is required.
 */
export enum ConditionsMode {
  SOME = 'some',
  EVERY = 'every',
}

/**
 * Deconstruct the enum values for easier usage.
 */
const {
  ALWAYS,
  NEVER,
  LOGGED_IN,
  LOGGED_OUT,
  IS_LANDLORD,
  IS_RENTER,
  NO_ACCOUNT_ROLE,
  MISSING_REQUIRED_ACCOUNT_DETAILS,
  PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION,
} = RouteCondition;

interface ComponentProps {
  [key: string]: any;
}

interface RouteDefinition {
  conditions?: RouteCondition[];
  conditionsMode?: ConditionsMode;
  component?: ComponentType;
  props?: ComponentProps;
}

interface RouteResolverOptions {
  redirect?: string;
}

/**
 * Determines whether the current user is missing required account details based
 * on their details stored in the F7 store.
 */
const isMissingRequiredAccountDetails = (): boolean => {
  /**
   * Access current state from the F7 store.
   */
  const {isLoggedIn, name, email} = F7Store.state;

  return (
    /**
     * Must be logged in.
     */
    isLoggedIn &&
    /**
     * Name is missing.
     */
    (!name ||
      /**
       * Using Apple's private email relay service.
       */
      (!!email && typeof email === 'string' && emailIsApplePrivateRelay(email)))
  );
};

/**
 * Determines whether the current user's activated email address is an Apple private relay email
 * but they have entered a new email address which is pending confirmation, based on their details
 * stored in the F7 store.
 */
const privateRelayEmailIsPendingConfirmation = (): boolean => {
  /**
   * Access current state from the F7 store.
   */
  const {isLoggedIn, email, unconfirmedEmail} = F7Store.state;

  return (
    /**
     * Must be logged in.
     */
    isLoggedIn &&
    /**
     * Has private relay email address but also an unconfirmed (pending) email address.
     */
    typeof email === 'string' &&
    emailIsApplePrivateRelay(email) &&
    !!unconfirmedEmail
  );
};

/**
 * Evaluates a given set of conditions - returns true if the conditions are
 * met, otherwise false.
 */
const evaluateConditions = (
  conditions: RouteCondition[],
  conditionsMode?: ConditionsMode,
): boolean => {
  /**
   * Access current state from the F7 store.
   */
  const {isLoggedIn, activeAccountRole} = F7Store.state;

  /**
   * Function to evaluate a single condition.
   */
  const evaluateCondition = (condition: RouteCondition): boolean => {
    switch (condition) {
      case ALWAYS:
        return true;
      case NEVER:
        return false;
      case LOGGED_IN:
        return isLoggedIn;
      case LOGGED_OUT:
        return !isLoggedIn;
      case IS_LANDLORD:
        return isLoggedIn && activeAccountRole === AccountRole.Landlord;
      case IS_RENTER:
        return isLoggedIn && activeAccountRole === AccountRole.Renter;
      case NO_ACCOUNT_ROLE:
        return isLoggedIn && !activeAccountRole;
      case MISSING_REQUIRED_ACCOUNT_DETAILS:
        return isMissingRequiredAccountDetails();
      case PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION:
        return privateRelayEmailIsPendingConfirmation();
      default:
        throw new Error(`Unsupported condition: ${condition}`);
    }
  };

  /**
   * Evaluate all conditions.
   */
  return conditionsMode === ConditionsMode.SOME
    ? conditions.some(evaluateCondition)
    : conditions.every(evaluateCondition);
};

interface MultipleDefinitionResolver {
  (
    definitions: RouteDefinition[],
    options: RouteResolverOptions,
  ): AsyncComponentFunction;
}

/**
 * Resolves an array of route definitions, returning the first definition that
 * has its conditions met. If no conditions are met, the route will redirect
 * if a redirect is provided in the resolver options, otherwise the route will
 * resolve to the 404 page.
 */
const resolveMultipleDefinitions: MultipleDefinitionResolver =
  (definitions, {redirect}) =>
  ({resolve, reject, router}) => {
    // TODO: Validate combinations of conditions (i.e. can't provide both IS_LANDLORD and IS_RENTER)

    /**
     * Access current state from the F7 store.
     */
    const {isLoggedIn, activeAccountRole} = F7Store.state;

    /**
     * Find the first definition where the conditions are met - or where no conditions
     * have been supplied.
     */
    const definitionWithConditionsMet = definitions.find(
      ({conditions, conditionsMode}) =>
        !conditions || evaluateConditions(conditions, conditionsMode),
    );

    /**
     * Redirect to the account type select page if logged in and no account role exists.
     */
    if (
      isLoggedIn &&
      !activeAccountRole &&
      /**
       * Skip redirecting if the route being resolved explicitly has the condition
       * of NO_ACCOUNT_ROLE, so that we can still resolve the routes for selecting the
       * account type and we don't get stuck in a redirect loop.
       */
      (!definitionWithConditionsMet ||
        !definitionWithConditionsMet.conditions?.includes(NO_ACCOUNT_ROLE))
    ) {
      reject();
      router.navigate('/register/account-type', {reloadCurrent: true});
      return;
    }

    if (
      isMissingRequiredAccountDetails() &&
      /**
       * We only skip redirecting if the route being resolved explicitly has the condition
       * of MISSING_REQUIRED_ACCOUNT_DETAILS, so that we can still resolve the routes for
       * entering the missing account details and we don't get stuck in a redirect loop.
       */
      (!definitionWithConditionsMet ||
        (!definitionWithConditionsMet.conditions?.includes(
          MISSING_REQUIRED_ACCOUNT_DETAILS,
        ) &&
          !definitionWithConditionsMet.conditions?.includes(
            PRIVATE_RELAY_EMAIL_PENDING_CONFIRMATION,
          )))
    ) {
      reject();
      router.navigate('/register/details', {reloadCurrent: true});
      return;
    }

    /**
     * If a definition is found, resolve the route with the component and props
     * if also provided.
     */
    if (definitionWithConditionsMet) {
      const {component, props} = definitionWithConditionsMet;
      resolve({component: withProviders(component), props: props ?? {}});
      return;
    } else if (redirect) {
      /**
       * If no definition is found and a redirect is provided, redirect to the location.
       */
      reject();
      router.navigate(redirect, {reloadCurrent: true});
      return;
    } else {
      /**
       * If no definition is found and no redirect was provided, then check if the route
       * could potentially be resolved after a login.
       */
      const shouldAttemptLogin =
        !isLoggedIn &&
        definitions.some(({conditions}) =>
          [LOGGED_IN, IS_LANDLORD, IS_RENTER].some((condition) =>
            conditions.includes(condition),
          ),
        );

      /**
       *  Redirect to the login page if the route could potentially be resolved after a login.
       */
      if (shouldAttemptLogin) {
        const {history} = router;
        router.navigate('/login', {
          reloadCurrent: true,
          props: {
            redirect: history[history.length - 1],
          },
        });
        return;
      } else {
        /**
         * Resolve the route to the 404 page.
         */
        resolve({component: withProviders(PageNotFound)});
        return;
      }
    }
  };

interface SingleDefinitionResolver {
  (
    definition: RouteDefinition,
    options: RouteResolverOptions,
  ): AsyncComponentFunction;
}

/**
 * Resolves a single route definition. This is a convenience function to
 * allow for providing a non-array as an argument to the resolver function.
 * This function adds the single definition to an array and then uses the
 * multiple definition resolver to resolve the route.
 */
const resolveSingleDefinition: SingleDefinitionResolver = (
  definition,
  options,
) => {
  return resolveMultipleDefinitions([definition], options);
};

interface RouteResolver {
  (
    definition: RouteDefinition | RouteDefinition[],
    options?: RouteResolverOptions,
  ): AsyncComponentFunction;
}

/**
 * Resolve a route from a definition or array of definitions.
 */
const resolveRoute: RouteResolver = (input, options = {}) =>
  Array.isArray(input)
    ? resolveMultipleDefinitions(input, options)
    : resolveSingleDefinition(input, options);

export default resolveRoute;
