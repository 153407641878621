import {Router} from 'framework7/types';

import AssociateDashboardPage from 'pages/associate/AssociateDashboardPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/associates',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
    routes: [
      {
        path: ':associateId',
        id: 'AssociateDashboard',
        async: resolveRoute({component: AssociateDashboardPage}),
      },
    ],
  },
];

export default routes;
