import {useState} from 'react';

import {PhotographIcon} from '@heroicons/react/outline';
import moment from 'moment';

import LightboxGallery from 'components/common/LightboxGallery';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import UserAvatar from 'components/user/UserAvatar';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import ChatMessage from 'models/ChatMessage';
import User from 'models/users/User';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {DATE_FORMAT} from 'utilities/DateHelpers';

const DemoServiceRequestDetailPage = () => {
  const {
    params: {serviceRequestId},
  } = useRoute();

  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [messageText, setMessageText] = useState('');

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmApproveQuote = () => {
    setConfirmationOptions({
      title: 'Approve Quote',
      message:
        'Once you approve, Keyhook will coordinate your maintenance and notify you once the job is complete.',
      buttonTitle: 'Approve',
      action: () => 'noop',
      color: 'success',
    });
  };

  const confirmRejectAllQuotes = () => {
    setConfirmationOptions({
      title: 'Reject All Quotes',
      message: "I'll find my own tradesperson.",
      buttonTitle: 'Reject Quotes',
      action: () => 'noop',
      color: 'error',
    });
  };

  const viewInvoice = () => setInvoiceModalVisible(true);

  if (serviceRequestId === '1') {
    const ll = new User({id: '1', name: 'Demo User', avatar: null});
    const t1 = new User({
      id: '2',
      name: 'John Smith',
      avatar:
        'https://storage.googleapis.com/propup-demo-media/people/renter_1.jpeg',
    });

    const messages = [
      new ChatMessage({
        content: 'Are you sure all the washers are tightened properly?',
        user: ll,
        readMarkers: {},
        createdAt: moment().subtract(6, 'days').set({hour: 13, minutes: 5}),
      }),

      new ChatMessage({
        content: 'Yes, I tightened them as much as possible with a spanner.',
        user: t1,
        readMarkers: {},
        createdAt: moment().subtract(6, 'days').set({hour: 18, minutes: 20}),
      }),

      new ChatMessage({
        content: "Ok thanks for confirming, I'll approve the request.",
        user: ll,
        readMarkers: {},
        createdAt: moment().subtract(6, 'days').set({hour: 13, minutes: 5}),
      }),
    ];

    return (
      <Page title="Maintenance Request">
        {() => (
          <>
            <Card title="The kitchen sink is leaking" className="mt-3">
              <span className="text-secondary text-sm tw-block">
                Category: Plumbing
              </span>
              <span className="text-secondary text-sm tw-block">
                Status: Complete
              </span>
              <Paragraph>
                The kitchen sink has been leaking for a day now, we've tried
                tightening everything that we could. But it still continues to
                leak.
              </Paragraph>

              <div>
                <strong>Finalized Cost: </strong>
                <span>$115.00</span>
              </div>

              <div className="my-4">
                <div>
                  <button
                    className="btn btn-sm btn-neutral demo-invoice-button"
                    onClick={viewInvoice}
                    type="button">
                    View Invoice
                  </button>

                  <DocumentPreviewModal
                    isVisible={invoiceModalVisible}
                    setIsVisible={setInvoiceModalVisible}
                    documentUrl="https://storage.googleapis.com/propup-demo-media/service-request-1/invoice.pdf"
                    title="Invoice"
                  />
                </div>
              </div>

              <div>
                <strong className="tw-block mb-2">Attachments</strong>

                <LightboxGallery
                  sources={[
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-1.jpg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-1.jpg',
                      type: 'image',
                    },
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-2.jpg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-2.jpg',
                      type: 'image',
                    },
                    // {
                    //   source:
                    //     'https://storage.googleapis.com/propup-demo-media/service-request-1/before-video.mp4',
                    //   thumbnail:
                    //     'https://storage.googleapis.com/propup-demo-media/service-request-1/before-image-1.jpg',
                    //   type: 'video',
                    // },
                  ]}
                />
              </div>

              <div className="mt-4">
                <strong className="tw-block mb-2">
                  Attachments ( Post Completion )
                </strong>

                <LightboxGallery
                  sources={[
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-1.jpg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-1.jpg',
                      type: 'image',
                    },
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-2.jpg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-1/after-image-2.jpg',
                      type: 'image',
                    },
                    // {
                    //   source:
                    //     'https://storage.googleapis.com/propup-demo-media/service-request-1/after-video.mp4',
                    //   type: 'video',
                    // },
                  ]}
                />
              </div>
            </Card>

            <Card title="Quote Details">
              <div>
                <strong>Company Name: </strong>
                <span>Simply Plumbing</span>
              </div>
              <div>
                <strong>Phone Number: </strong>
                <span>022 123 5678</span>
              </div>
              <div>
                <strong>Quoted Cost: </strong>
                <span>$115.00</span>
              </div>
            </Card>

            <Card title="Messages" className="messages-card">
              <Paragraph size="sm" secondary>
                If you need to see any more photos of items for further
                inspection, you can request them here. Message sending is
                disabled in demo mode.
              </Paragraph>

              <div>
                {messages.map((item, index) => {
                  const dateAgo = moment(item.createdAt).fromNow();

                  return (
                    <div className="alert mt-2" key={index}>
                      <div className="flex-1">
                        <UserAvatar user={item.user} size="6" />
                        <label className="ml-2">
                          <h4>{item.user.name}</h4>
                          <p className="text-sm text-base-content text-opacity-60">
                            {item.content}
                          </p>

                          <p className="text-xs text-base-content text-opacity-60 italic">
                            {dateAgo}
                          </p>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify-between items-center mt-4">
                <input
                  type="file"
                  id="chatable-image-hidden-input"
                  accept=".png,.jpeg,.jpg"
                  disabled
                  className="hidden"
                />

                <button className="btn btn-neutral mr-2" type="button" disabled>
                  <PhotographIcon className="text-white w-5 h-5" />
                </button>

                <textarea
                  className="textarea textarea-bordered flex-grow"
                  placeholder="Write a message..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.currentTarget.value)}
                  maxLength={300}
                />

                <button className="btn btn-neutral ml-2" type="button" disabled>
                  Send
                </button>
              </div>
            </Card>
          </>
        )}
      </Page>
    );
  } else if (serviceRequestId === '2') {
    const ll = new User({id: '1', name: 'Demo User', avatar: null});
    const t1 = new User({
      id: '2',
      name: 'John Smith',
      avatar:
        'https://storage.googleapis.com/propup-demo-media/people/renter_1.jpeg',
    });

    const messages = [
      new ChatMessage({
        content: 'Did a power surge happen at all?',
        user: ll,
        readMarkers: {},
        createdAt: moment().subtract(1, 'days').set({hour: 13, minutes: 5}),
      }),

      new ChatMessage({
        content:
          'Not that we have been able to tell, every outlet and appliance is working normally.',
        user: t1,
        readMarkers: {},
        createdAt: moment().subtract(1, 'days').set({hour: 18, minutes: 20}),
      }),
    ];

    return (
      <Page title="Maintenance Request">
        {() => (
          <>
            <Card
              title="Bedroom light stopped working"
              className="mt-3 service-request-info">
              <span className="text-secondary text-sm tw-block">
                Category: Electrical
              </span>
              <span className="text-secondary text-sm tw-block">
                Status: Awaiting Quote Acceptance
              </span>
              <Paragraph>
                The light in the master bedroom has stopped working, we have
                tried replacing the light bulb and have checked that none of the
                fuses have been flipped.
              </Paragraph>

              <div>
                <strong className="tw-block mb-2">Attachments</strong>

                <LightboxGallery
                  sources={[
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-2/image-1.jpeg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-2/image-1.jpeg',
                      type: 'image',
                    },
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-2/image-2.jpeg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-2/image-2.jpeg',
                      type: 'image',
                    },
                    {
                      thumbnail:
                        'https://storage.googleapis.com/propup-demo-media/service-request-2/image-3.jpeg',
                      source:
                        'https://storage.googleapis.com/propup-demo-media/service-request-2/image-3.jpeg',
                      type: 'image',
                    },
                  ]}
                />
              </div>
            </Card>

            <Card title="Quotes" className="mt-4 quotes-card">
              <Paragraph>Keyhook has found the following quotes:</Paragraph>

              <div>
                <div className="flex justify-between items-center my-2">
                  <div>
                    <strong className="tw-block">James Electrical</strong>
                    <span className="tw-block text-secondary">
                      Quoted Amount: $60.00
                    </span>
                    <span className="tw-block text-secondary">
                      Expected Date:{' '}
                      {moment().add('3', 'days').format(DATE_FORMAT)}
                    </span>
                  </div>
                  <div className="ml-2 float-right">
                    <button
                      className="btn btn-sm btn-success bg-success demo-accept-button"
                      type="button"
                      onClick={confirmApproveQuote}>
                      Accept
                    </button>
                  </div>
                </div>
                <hr className="border border-gray-200" />
              </div>

              <div>
                <div className="flex justify-between items-center my-2">
                  <div>
                    <strong className="tw-block">Electricians R Us</strong>
                    <span className="tw-block text-secondary">
                      Quoted Amount: $90.00
                    </span>
                    <span className="tw-block text-secondary">
                      Expected Date:{' '}
                      {moment().add('1', 'day').format(DATE_FORMAT)}
                    </span>
                  </div>
                  <div className="ml-2 float-right">
                    <button
                      className="btn btn-sm btn-success bg-success"
                      type="button"
                      onClick={confirmApproveQuote}>
                      Accept
                    </button>
                  </div>
                </div>
                <hr className="border border-gray-200" />
              </div>

              <div>
                <div className="flex justify-between items-center my-2">
                  <div>
                    <strong className="tw-block">Sparkies</strong>
                    <span className="tw-block text-secondary">
                      Quoted Amount: $70.00
                    </span>
                    <span className="tw-block text-secondary">
                      Expected Date:{' '}
                      {moment().add('2', 'days').format(DATE_FORMAT)}
                    </span>
                  </div>
                  <div className="ml-2 float-right">
                    <button
                      className="btn btn-sm btn-success bg-success"
                      type="button"
                      onClick={confirmApproveQuote}>
                      Accept
                    </button>
                  </div>
                </div>
                <hr className="border border-gray-200" />
              </div>

              <button
                className="btn btn-md btn-neutral mt-4"
                type="button"
                onClick={confirmRejectAllQuotes}>
                Reject Maintenance Request
              </button>
            </Card>

            <Card title="Messages">
              <Paragraph size="sm" secondary>
                If you need to see any more photos of the problem, you can
                request them here. Message sending is disabled in demo mode.
              </Paragraph>

              <div>
                {messages.map((item, index) => {
                  const dateAgo = moment(item.createdAt).fromNow();

                  return (
                    <div className="alert mt-2" key={index}>
                      <div className="flex-1">
                        <UserAvatar user={item.user} size="6" />
                        <label className="ml-2">
                          <h4>{item.user.name}</h4>
                          <p className="text-sm text-base-content text-opacity-60">
                            {item.content}
                          </p>

                          <p className="text-xs text-base-content text-opacity-60 italic">
                            {dateAgo}
                          </p>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify-between items-center mt-4">
                <input
                  type="file"
                  id="chatable-image-hidden-input"
                  accept=".png,.jpeg,.jpg"
                  disabled
                  className="hidden"
                />

                <button className="btn btn-neutral mr-2" type="button" disabled>
                  <PhotographIcon className="text-white w-5 h-5" />
                </button>

                <textarea
                  className="textarea textarea-bordered flex-grow"
                  placeholder="Write a message..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.currentTarget.value)}
                  maxLength={300}
                />

                <button className="btn btn-neutral ml-2" type="button" disabled>
                  Send
                </button>
              </div>
            </Card>
          </>
        )}
      </Page>
    );
  } else {
    return <Page loading />;
  }
};

export default DemoServiceRequestDetailPage;
