import React, {useMemo} from 'react';

import Decimal from 'decimal.js';
import {Formik} from 'formik';
import moment from 'moment';
import {HiOutlineCurrencyDollar} from 'react-icons/hi';

import {InputField} from 'components/forms_fields';
import CopyButton from 'components/forms_fields/CopyButton';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {Card} from 'components_sb/layout';
import {FieldLabel, Paragraph} from 'components_sb/typography';
import CompanyInfoGlobals from 'globals/CompanyInfoGlobals';
import TenancyMembership from 'models/properties/TenancyMembership';
import {Action} from 'types/actions';
import {DATE_FORMAT} from 'utilities/DateHelpers';
import {toCurrency} from 'utilities/StringHelpers';

const RentCard = ({
  tenancyMembership: data,
}: {
  tenancyMembership: TenancyMembership;
}) => {
  const cardActions = useMemo<Action[]>(
    () => [
      {
        label: 'View All Rent Payments',
        icon: HiOutlineCurrencyDollar,
        linkTo: 'rent-payments',
      },
    ],
    [],
  );

  if (!data.tenancy.managedRentEnabled) {
    return null;
  }

  if (data?.rentSplit) {
    if (new Decimal(data.rentSplit).equals(new Decimal(0.0))) {
      return (
        <Card title="Rent Payment Instructions" icon={HiOutlineCurrencyDollar}>
          <Paragraph>
            Your share of the rent was set as $0.00, you do not need to worry
            about making any rent payments through Keyhook.
          </Paragraph>
        </Card>
      );
    }

    let rentalPeriod: string;
    if (data.tenancy.rentalPeriod === 'Weekly') {
      rentalPeriod = '7 Days';
    } else if (data.tenancy.rentalPeriod === 'Fortnightly') {
      rentalPeriod = '14 Days';
    }

    /**
     * This is a dummy function that is used to prevent the form from
     * submitting.
     * We just want to use the old TextField components, but they require
     * Formik
     */
    const doNothingOnSubmit = () => {
      'noop';
    };

    return (
      <Card
        title="Rent Payment Instructions"
        icon={HiOutlineCurrencyDollar}
        actions={cardActions}>
        <Paragraph>
          Please use the details below to set up a recurring bank transfer for
          rent payments.{' '}
          <u>It is crucial that you enter these details accurately</u> or we
          will not be able to track your payments.
        </Paragraph>

        <Paragraph>
          Please note that your rent reference code is different to your bond
          reference code.
        </Paragraph>

        <Formik initialValues={{}} onSubmit={doNothingOnSubmit}>
          {(formik) => (
            <>
              <FormRow responsive>
                <FormRowItem>
                  <FieldLabel title="Account Name" />
                  <div className="flex flex-start">
                    <InputField
                      name="rent-account-name"
                      value={CompanyInfoGlobals.bankAccountName}
                      readOnly
                      id="rent-account-name-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{string: CompanyInfoGlobals.bankAccountName}}
                    />
                  </div>
                </FormRowItem>
                <FormRowItem>
                  <FieldLabel title="Account Number" />
                  <div className="flex flex-start">
                    <InputField
                      name="rent-account-number"
                      value={CompanyInfoGlobals.bankAccountNumber}
                      readOnly
                      id="rent-account-number-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: CompanyInfoGlobals.bankAccountNumber,
                      }}
                    />
                  </div>
                </FormRowItem>
              </FormRow>
              <FormRow responsive>
                <FormRowItem>
                  <FieldLabel
                    title="Reference (case sensitive)"
                    helpText="Please copy this exactly, and paste it into the reference field. You do not need to provide anything in the particulars or code fields."
                  />
                  <div className="flex flex-start">
                    <InputField
                      name="rent-reference-field"
                      value={data.rentReference}
                      readOnly
                      id="rent-reference-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: data.rentReference,
                      }}
                    />
                  </div>
                </FormRowItem>
                <FormRowItem>
                  <FieldLabel title="Amount ($)" />
                  <div className="flex flex-start">
                    <InputField
                      name="rent-amount-field"
                      value={toCurrency(data?.rentSplit).replace('$', '')}
                      readOnly
                      id="rent-amount-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: data?.rentSplit.toString(),
                      }}
                    />
                  </div>
                </FormRowItem>
              </FormRow>

              <FormRow responsive>
                <FormRowItem>
                  <FieldLabel title="Next Rent Payment Due" />
                  <div className="flex flex-start">
                    <InputField
                      name="rent-start-date-field"
                      value={moment(data.tenancy.nextRentPaymentDue).format(
                        DATE_FORMAT,
                      )}
                      readOnly
                      id="rent-start-date-field"
                      className="w-full"
                      formik={formik}
                    />
                    <CopyButton
                      copyOptions={{
                        string: moment(data.tenancy.nextRentPaymentDue).format(
                          DATE_FORMAT,
                        ),
                      }}
                    />
                  </div>
                </FormRowItem>
                <FormRowItem>
                  <FieldLabel title="Paid Every" />
                  <InputField
                    name="rent-rental-period-field"
                    value={rentalPeriod}
                    readOnly
                    className="w-full"
                    formik={formik}
                  />
                </FormRowItem>
              </FormRow>
            </>
          )}
        </Formik>
      </Card>
    );
  } else {
    return null;
  }
};

export default RentCard;
