import React, {useCallback, useMemo, useState} from 'react';

import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import LandlordNewEnquiryThreadModal from 'components/enquiries/enquiry_thread/LandlordNewEnquiryThreadModal';
import RentalApplicationDetailView from 'components/rental_application/RentalApplicationDetailView';
import TenantCheckRequestsCard from 'components/tenant_checks/TenantCheckRequestsCard';
import {Modal} from 'components_sb/layout';
import ListingRentalApplication, {
  ListingRentalApplicationTag,
} from 'models/listings/ListingRentalApplication';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';

import ListingUserNotesEditor from '../listings/ListingUserNotesEditor/ListingUserNotesEditor';
import useListingRentalApplicationQuery from './useListingRentalApplicationQuery';

const {useModal} = Modal.Imperative;

const RentalApplicationDetailPage = () => {
  const {
    params: {listingRentalApplicationId, propertyId},
  } = useRoute();

  const queryClient = useQueryClient();

  const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);

  const router = useRouter();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {
    queryKey,
    data: listingRentalApplication,
    error,
    isLoading,
  } = useListingRentalApplicationQuery({id: listingRentalApplicationId});

  const markAsShortlisted = useCallback(async () => {
    const app = listingRentalApplication;
    app.landlordTag = ListingRentalApplicationTag.Shortlisted;

    const result = await app.save();

    if (result) {
      toast.success('Application successfully shortlisted!');
      queryClient.setQueryData(queryKey, app);
    }
  }, [listingRentalApplication, queryClient, queryKey]);

  const markAsExcluded = useCallback(async () => {
    const app = listingRentalApplication;
    app.landlordTag = ListingRentalApplicationTag.Excluded;

    const result = await app.save();

    if (result) {
      toast.success('Application has been archived.');
      queryClient.setQueryData(queryKey, app);
    }
  }, [listingRentalApplication, queryClient, queryKey]);

  const acceptApplication = useCallback(async () => {
    const app = listingRentalApplication;
    app.accepted = true;

    const result = await app.save();

    const requests = {
      tenancyRequests: [] as any[],
      numberOfTenants: null as any,
    };

    requests['tenancyRequests'].push({
      renterEmail: app.rentalApplication.headTenantEmail,
      name: app.rentalApplication.headTenantName,
    });
    app.rentalApplication.rentalApplicationApplicants.forEach((applicant) =>
      requests['tenancyRequests'].push({
        renterEmail: applicant.email,
        name: applicant.name,
      }),
    );
    requests['numberOfTenants'] = requests['tenancyRequests'].length;

    localStorage.setItem(
      `property-${propertyId}-new-invite-tenants`,
      JSON.stringify(requests),
    );

    if (result) {
      toast.success('Application successfully accepted!');
      queryClient.invalidateQueries(queryKey);
    }
  }, [listingRentalApplication, propertyId, queryClient, queryKey]);

  const confirmAcceptApplication = useCallback(() => {
    setConfirmationOptions({
      title: 'Accept Application',
      message:
        'This will let the tenant know they have been chosen for this property. Their information will automatically be added to your in progress lease.',
      buttonTitle: 'Confirm',
      color: 'success',
      action: acceptApplication,
    });
  }, [setConfirmationOptions, acceptApplication]);

  const showTenancyEditor = () => {
    router.navigate(`/properties/${propertyId}/setup`);
  };

  const showEnquiryModal = useCallback(() => setEnquiryModalOpen(true), []);

  const onRequestCheckClicked = useCallback(() => {
    router.navigate('request-tenant-check');
  }, [router]);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () => [
      {label: 'Accept application', onClick: confirmAcceptApplication},
      {label: 'Shortlist', onClick: markAsShortlisted},
      {label: 'Archive', onClick: markAsExcluded},
      {label: 'Send a message', onClick: showEnquiryModal},
    ],
    [
      confirmAcceptApplication,
      markAsShortlisted,
      markAsExcluded,
      showEnquiryModal,
    ],
  );

  return (
    <Page
      title="Rental Application"
      actions={pageActions}
      error={error}
      loading={isLoading}>
      {() => (
        <>
          {listingRentalApplication.accepted && (
            <div
              className="alert alert-success shadow-lg cursor-pointer"
              onClick={showTenancyEditor}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>
                  This application was successfully chosen. Click here to go to
                  your lease builder.
                </span>
              </div>
            </div>
          )}

          <TenantCheckRequestsCard
            listingRentalApplicationId={listingRentalApplicationId}
            isShortlisted={
              listingRentalApplication.landlordTag ===
              ListingRentalApplicationTag.Shortlisted
            }
            markAsShortlisted={markAsShortlisted}
            showRequestModal={onRequestCheckClicked}
          />

          <RentalApplicationDetailView
            rentalApplication={listingRentalApplication.rentalApplication}
            tag={listingRentalApplication.landlordTag as any}
          />

          <ListingUserNotesEditor
            asCard
            listingPrivateId={listingRentalApplication.listingId}
            userId={listingRentalApplication.rentalApplication.userId}
          />

          <LandlordNewEnquiryThreadModal
            modalIsOpen={enquiryModalOpen}
            setModalIsOpen={setEnquiryModalOpen}
            application={listingRentalApplication.rentalApplication}
            listingId={listingRentalApplication.listingId}
          />
        </>
      )}
    </Page>
  );
};

export default RentalApplicationDetailPage;
