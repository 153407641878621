import {useMemo} from 'react';

import {
  Chart as ChartJS,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  BarElement,
  Tooltip,
} from 'chart.js';
import moment from 'moment';
import {Bar} from 'react-chartjs-2';
import {useQuery} from 'react-query';

import {Card} from 'components_sb/layout';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Associate from 'models/associates/Associate';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {DATE_FORMAT, DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {titleize, toCurrency} from 'utilities/StringHelpers';

ChartJS.register(
  BarElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Filler,
  Tooltip,
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
  },
  elements: {
    point: {
      radius: 2,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      title: {
        display: true,
      },
      display: true,
      ticks: {
        display: true,
      },
    },

    y: {
      grid: {
        display: true,
      },

      display: true,
      title: {
        display: true,
      },
      ticks: {
        display: true,
      },
    },
  },
};

const AssociateDashboardPage = () => {
  // const [statPeriod, setStatPeriod] = useState('30d');

  const {
    params: {associateId},
  } = useRoute();

  const isMobile = !useTailwindBreakpoint('sm');

  const {data, isLoading, error} = useQuery(
    `associate-${associateId}`,
    async () => {
      // let dateFilter: string;
      // let maxRange = moment().add(10, 'years').format('YYYY-MM-DD');
      // if (statPeriod === '30d') {
      //   dateFilter = moment().subtract(30, 'days').format('YYYY-MM-DD');
      // } else if (statPeriod === '90d') {
      //   dateFilter = moment().subtract(90, 'days').format('YYYY-MM-DD');
      // } else if (statPeriod === '6m') {
      //   dateFilter = moment().subtract(6, 'months').format('YYYY-MM-DD');
      // } else if (statPeriod === '1y') {
      //   dateFilter = moment().subtract(1, 'year').format('YYYY-MM-DD');
      // } else {
      //   const taxRange = taxYearDates();
      //   dateFilter = taxRange.startDate.format('YYYY-MM-DD');
      //   maxRange = taxRange.endDate.format('YYYY-MM-DD');
      // }

      const associate = await Associate.includes([
        'promo_codes',
        'associate_payments',
      ])
        // .where({
        //   'associate_payments.period_start_date': {
        //     gte: dateFilter,
        //     lte: maxRange,
        //   },
        // })
        .order({'associate_payments.period_start_date': 'desc'})
        .selectExtra(['total_referrals'])
        .find(associateId);

      return associate.data;
    },
    {keepPreviousData: true},
  );

  const chartData = useMemo(() => {
    if (data) {
      const reversed = data.associatePayments.slice().reverse();
      return {
        labels: reversed.map((payment) =>
          moment(payment.periodStartDate).format('MMM YYYY'),
        ),
        datasets: [
          {
            fill: 'start',
            backgroundColor: 'rgba(51,102,255,0.5)',
            borderColor: 'rgba(51,102,255,1)',
            borderWidth: 2,
            data: reversed.map((payment) => Number(payment.amount)),
            label: 'Earnings',
          },
        ],
      };
    } else {
      return {
        labels: [],
        datasets: [
          {
            fill: 'start',
            backgroundColor: 'rgba(51,102,255,0.5)',
            borderColor: 'rgba(51,102,255,1)',
            tension: 0.3,
            borderWidth: 2,
            data: [],
          },
        ],
      };
    }
  }, [data]);

  const totalEarnings = useMemo(() => {
    if (data) {
      return data.associatePayments.reduce(
        (total, payment) => total + Number(payment.amount),
        0,
      );
    } else {
      return 0;
    }
  }, [data]);

  // let datePeriod: string;
  // if (statPeriod === '30d') {
  //   datePeriod = 'Last 30 Days';
  // } else if (statPeriod === '90d') {
  //   datePeriod = 'Last 90 days';
  // } else if (statPeriod === '6m') {
  //   datePeriod = 'Last 6 months';
  // } else if (statPeriod === '1y') {
  //   datePeriod = 'Last 1 year';
  // } else {
  //   const taxRange = taxYearDates();
  //   datePeriod = `Tax year (${taxRange.startDate.format(
  //     DATE_FORMAT,
  //   )} - ${taxRange.endDate.format(DATE_FORMAT)})`;
  // }

  return (
    <Page title="Associate Dashboard" loading={isLoading} error={error}>
      {() => (
        <>
          {/* <div className="flex flex-col lg:flex-row justify-between lg:items-center space-y-2 lg:space-y-0 mt-4 mb-2">
          <h5 className="text-xl font-semibold">{datePeriod}</h5>
          <div className="btn-group">
            {['30d', '90d', '6m', '1y', 'Tax Year'].map((period) => (
              <button
                key={period}
                className={`btn btn-sm btn-${
                  statPeriod === period ? 'primary' : 'neutral'
                }`}
                onClick={() => setStatPeriod(period)}
                type="button">
                {period}
              </button>
            ))}
          </div>
        </div> */}

          <Card className="my-4">
            <div className="flex justify-start items-start gap-2">
              <div className="avatar">
                <div className="w-16 rounded-full">
                  <img src={data.avatar} />
                </div>
              </div>
              <div className="flex flex-col">
                <strong>{data.name}</strong>
                <span className="text-gray-500">{data.email}</span>

                <div className="flex justify-between items-center mt-2">
                  <h5 className="mb-0 font-xl font-semibold">
                    Your promo codes
                  </h5>
                </div>
                <ul>
                  {data.promoCodes.map((promoCode) => (
                    <li key={promoCode.id}>
                      {promoCode.code} - ({promoCode.duration}{' '}
                      {promoCode.period} free)
                    </li>
                  ))}
                </ul>

                <div className="flex justify-between items-center mt-2">
                  <h5 className="mb-0 font-xl font-semibold">
                    Shareable Links
                  </h5>
                </div>
                <ul>
                  {data.promoCodes.map((promoCode) => (
                    <li key={promoCode.id}>
                      <a
                        className="link link-primary"
                        href="https://keyhook.com/{promoCode.code}"
                        target="_blank">
                        https://keyhook.com/{promoCode.code}
                      </a>{' '}
                      - ({promoCode.duration} {promoCode.period} free)
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Card>

          <div className="flex justify-between items-center mb-2">
            <h5 className="mb-0 font-xl font-semibold">Your Earnings</h5>
          </div>

          <div className="my-4">
            <Bar
              height={isMobile ? 200 : 400}
              data={chartData}
              options={chartOptions}
            />
          </div>

          <div className="flex items-center justify-center my-4">
            <div className="w-full">
              <div className="-mx-2 md:flex mb-6">
                <div className="w-full md:w-1/2 px-2">
                  <div className="rounded-lg shadow-sm mb-4">
                    <div className="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                      <div className="px-3 pt-4 pb-5 md:pt-8 md:pb-10 text-center relative">
                        <h4 className="text-sm uppercase text-gray-500 leading-tight">
                          Total Earnings
                        </h4>
                        <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
                          {toCurrency(totalEarnings)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 px-2">
                  <div className="rounded-lg shadow-sm mb-4">
                    <div className="rounded-lg bg-white shadow-lg md:shadow-xl relative overflow-hidden">
                      <div className="px-3 pt-4 pb-5 md:pt-8 md:pb-10 text-center relative">
                        <h4 className="text-sm uppercase text-gray-500 leading-tight">
                          Total Referred Users
                        </h4>
                        <h3 className="text-3xl text-gray-700 font-semibold leading-tight my-3">
                          {data.totalReferrals}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="flex justify-between items-center mb-2">
              <h5 className="mb-0 font-xl font-semibold">Payment History</h5>
            </div>

            {isMobile ? (
              <div className="flex flex-col space-y-2">
                {data.associatePayments.map((ap) => (
                  <Card key={ap.id}>
                    <div className="flex flex-col">
                      <strong>
                        {moment(ap.periodStartDate).format(DATE_FORMAT)} -{' '}
                        {moment(ap.periodEndDate).format(DATE_FORMAT)}
                      </strong>
                      <span className="text-sm">{ap.status}</span>
                    </div>

                    <div className="grid grid-cols-2">
                      <div className="flex flex-col items-center">
                        <span className="text-xs">Users</span>
                        <strong className="text-sm">{ap.numUsers}</strong>
                      </div>

                      <div className="flex flex-col items-center">
                        <span className="text-xs">Amount Earned</span>
                        <strong className="text-sm">
                          {toCurrency(ap.amount)}
                        </strong>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="table table-zebra w-full">
                  <thead className="thead-light">
                    <tr>
                      <th colSpan={2}>Period</th>
                      <th>Status</th>
                      <th>Number of users</th>
                      <th>Amount</th>
                      <th>Date paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.associatePayments.map((ap) => (
                      <tr key={ap.id} className="hover">
                        <td colSpan={2}>
                          {moment(ap.periodStartDate).format(DATE_FORMAT)} -{' '}
                          {moment(ap.periodEndDate).format(DATE_FORMAT)}
                        </td>

                        <td>{titleize(ap.status)}</td>
                        <td>{ap.numUsers}</td>
                        <td>{toCurrency(ap.amount)}</td>
                        <td>
                          {ap.paidAt
                            ? moment(ap.paidAt).format(DATE_TIME_FORMAT)
                            : ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      )}
    </Page>
  );
};

export default AssociateDashboardPage;
