import {FunctionComponent, useMemo} from 'react';

import {isMobile} from 'react-device-detect';

import {Alert} from 'components_sb/feedback';
import {AlertType} from 'components_sb/feedback/Alert/Alert';
import {SHOW_BILLING_COMMS} from 'globals/app-globals';
import useSubscriptions from 'providers/Subscriptions/hooks/useSubscriptions';

/**
 * The maximum number number of days remaining on the Landlord's free
 * period before the alert changes to a warning.
 */
const FREE_PERIOD_DAYS_REMAINING_WARNING = 8;

interface SubscriptionStatusAlertProps {
  billingActionRequired: boolean;
}

/**
 * An alert for the status of a Landlord's trial/bonus period across and
 * whether any billing action is required. This can be used for both a
 * specific property as well as being generalised across all properties
 * by setting the billingActionRequired prop.
 */
const SubscriptionStatusAlert: FunctionComponent<
  SubscriptionStatusAlertProps
> = ({billingActionRequired}) => {
  /**
   * Access the subscriptions context and deconstruct the required properties
   * of the subscription status.
   */
  const {
    subscriptionStatus: {
      isWithinFreePeriod,
      freeDaysRemaining,
      hasReceivedBonusTime,
      freePeriodTypeLabel,
      freePeriodEndDateLabel,
    },
  } = useSubscriptions();

  /**
   * The alert for the subscription status should be shown at all times
   * for a Landlord when the Landlord is within a free period, or if their
   * free period has expired and the property/properties require payment to
   * be configured.
   */
  const shouldShowAlert = useMemo<boolean>(
    () =>
      /**
       * When all subscription comms are allowed, we show the alert for both the free
       * period as well as when billing action is required, otherwise if not allowed
       * we only show when billing action is required and free days is below the warning
       * threshold (with vaguer messaging).
       */
      SHOW_BILLING_COMMS
        ? isWithinFreePeriod || billingActionRequired
        : billingActionRequired &&
          freeDaysRemaining <= FREE_PERIOD_DAYS_REMAINING_WARNING,
    [isWithinFreePeriod, billingActionRequired, freeDaysRemaining],
  );

  /**
   * Evaluate the style for the alert.
   *
   * We use a standard info style alert for most cases, but we use a warning
   * alert when a free period is close to expiring, and an error alert when
   * the free period has expired and billing action is required.
   */
  const alertType = useMemo<AlertType>(() => {
    if (isWithinFreePeriod) {
      if (
        freeDaysRemaining <= FREE_PERIOD_DAYS_REMAINING_WARNING &&
        billingActionRequired
      ) {
        return 'warning';
      } else {
        return 'info';
      }
    } else {
      return 'error';
    }
  }, [isWithinFreePeriod, freeDaysRemaining, billingActionRequired]);

  /**
   * Evaluate the main title to display in the alert.
   */
  const title = useMemo<string>(() => {
    /**
     * Full subscription comms allowed.
     */
    if (SHOW_BILLING_COMMS) {
      if (isWithinFreePeriod) {
        if (
          freeDaysRemaining > FREE_PERIOD_DAYS_REMAINING_WARNING ||
          !billingActionRequired
        ) {
          return `Enjoy your ${freePeriodTypeLabel}!`;
        } else {
          return `Your ${freePeriodTypeLabel} ends ${freePeriodEndDateLabel}.`;
        }
      } else {
        return `Your ${
          hasReceivedBonusTime ? 'bonus' : 'trial'
        } period has ended.`;
      }
    } else {
      /**
       * Non-subscription phrased messaging
       */
      if (billingActionRequired) {
        return 'Your account requires attention.';
      }
    }
  }, [
    isWithinFreePeriod,
    freeDaysRemaining,
    freePeriodTypeLabel,
    freePeriodEndDateLabel,
    hasReceivedBonusTime,
    billingActionRequired,
  ]);

  /**
   * Evaluate the supporting text to display in the alert.
   */
  const description = useMemo<string>(() => {
    /**
     * Full subscription comms allowed.
     */
    if (SHOW_BILLING_COMMS) {
      if (isWithinFreePeriod) {
        if (
          freeDaysRemaining > FREE_PERIOD_DAYS_REMAINING_WARNING ||
          !billingActionRequired
        ) {
          return `Your ${freePeriodTypeLabel} ends ${freePeriodEndDateLabel}. ${
            isMobile ? 'Tap' : 'Click'
          } here to view your subscription details.`;
        } else {
          return `${
            isMobile ? 'Tap' : 'Click'
          } here to manage your subscription and continue using Keyhook.`;
        }
      } else {
        return `${
          isMobile ? 'Tap' : 'Click'
        } here to manage your subscription and continue using Keyhook.`;
      }
    } else {
      /**
       * Non-subscription phrased messaging.
       */
      if (billingActionRequired) {
        return `${isMobile ? 'Tap' : 'Click'} here to manage your account.`;
      }
    }
  }, [
    isWithinFreePeriod,
    freeDaysRemaining,
    freePeriodTypeLabel,
    freePeriodEndDateLabel,
    billingActionRequired,
  ]);

  return !shouldShowAlert ? null : (
    <Alert
      testId="subscription-status-alert"
      type={alertType}
      title={title}
      description={description}
      asLink
      linkTo="/account/billing"
    />
  );
};

export default SubscriptionStatusAlert;
