import {useCallback, useImperativeHandle} from 'react';

import {ModalDefinition} from 'components_sb/layout';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import BillingMethod from 'models/billing/BillingMethod';
import {useCreditCards} from 'providers/CreditCardsProvider';

const SetDefaultCreditCardConfirmationModal: ModalDefinition = {
  title: 'Change default card?',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'confirm',
        label: 'Set as default',
        handle: 'onConfirm',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    /**
     * Deconstruct and cast the credit card prop.
     */
    const creditCard = props.creditCard as BillingMethod;

    /**
     * Access the credit cards context.
     */
    const creditCards = useCreditCards();

    /**
     * Set the billing method as default/primary upon confirmation.
     */
    const onConfirm = useCallback(
      async () => creditCards.setDefault(creditCard),
      [creditCard, creditCards],
    );

    useImperativeHandle(ref, () => ({
      onConfirm,
    }));

    return (
      <>
        <Paragraph>
          {`Please confirm that your would like to set `}
          <strong>
            {creditCard.nickname
              ? creditCard.nickname
              : `the card ending in ${creditCard.metadata.display_number.substring(
                  creditCard.metadata.display_number.lastIndexOf('-') + 1,
                  creditCard.metadata.display_number.length,
                )}`}
          </strong>
          {` as your default credit/debit card.`}
        </Paragraph>
        <Paragraph noMargin>
          Any properties that are currently set to use your default card for
          subscription payments will be updated to use this card.
        </Paragraph>
      </>
    );
  },
};

export default SetDefaultCreditCardConfirmationModal;
