import {Clipboard} from '@capacitor/clipboard';
import chalk from 'chalk';
import {toast} from 'react-toastify';

/**
 * We only want to allow the prop reflecting the type of data to be copied,
 * props for different modes cannot be mixed.
 */
type CopyModeProps =
  | {
      string: string;
      url?: never;
      image?: never;
    }
  | {
      string?: never;
      url: string;
      image?: never;
    }
  | {
      string?: never;
      url: string;
      image?: never;
    };

export type CopyOptions = CopyModeProps & {
  /**
   * User visible label to accompany the copied data (Android Only).
   */
  label?: string;
  /**
   * Wheter to show a toast upon successfully copying.
   */
  showSuccessToast?: boolean;
};

interface CopyToClipboardFunction {
  (options: CopyOptions): Promise<void>;
}

/**
 * Handles copying a value to the clipboard across all platforms with fallbacks
 * to secondary apporaches when the default are not available or fail.
 */
const copyToClipboard: CopyToClipboardFunction = async ({
  string,
  url,
  image,
  label,
  showSuccessToast = true,
}) => {
  /**
   * Invoked upon successfully copying to the clipboard using one of the strategies below.
   */
  const onSuccess = ({method}: {method: string}) => {
    /**
     * Log that the clipboard method was successful.
     */
    console.info(
      chalk.green(`Successfully copied to clipboard using ${method}!`),
    );

    /**
     * Indicate to the user that the copy was performed successfully unless disabled.
     */
    if (showSuccessToast) {
      toast.success('Copied to clipboard!');
    }
  };

  /**
   * First we try to copy to the clipboarding using the Capacitor plugin.
   */
  try {
    await Clipboard.write({
      label,
      /**
       * We can safely add all mode props here, because the function typing
       * will ensure that only one of these props is present, and the others
       * will be undefined and therefore effectively unset.
       */
      string,
      url,
      image,
    });

    /**
     * Handle success.
     */
    onSuccess({method: 'Capacitor Clipboard plugin'});

    /**
     * We can now exit the function.
     */
    return;
  } catch (error) {
    /**
     * Log that the clipboard method failed.
     */
    console.error('Failed to copy to clipboard using Capacitor plugin');
  }

  /**
   * The fallback strategies below do not make any distinctino between the differnt
   * modes of copying, so we can abstract whichever prop was passed to the function
   * into a single variable.
   */
  const value = string ?? url ?? image;

  /**
   * If the Capacitor plugin fails, we can try to use the browser Clipboard API,
   * this may not be supported across all browsers and is also only available in
   * a secure context (HTTPS) so we need to also handle if this fails.
   */
  try {
    await navigator.clipboard.writeText(value);

    /**
     * Handle success.
     */
    onSuccess({method: 'Clipboard API'});

    /**
     * We can now exit the function.
     */
    return;
  } catch {
    /**
     * Log that the clipboard method failed.
     */
    console.error('Failed to copy to clipboard using Clipboard API');
  }

  /**
   * As a last resort, we can try to use the deprecated execCommand approach, where
   * we create a temporary textarea, set the value to be copied as the textarea value,
   * select the value in the textarea, and then execute the copy command.
   */
  try {
    const textArea = document.createElement('textarea');

    /**
     * Set fixed positioning on the textarea to avoid interfering with the layout.
     */
    textArea.style.position = 'fixed';

    /**
     * Hide the textarea from view via the opacity.
     * (if we set the visibility to hidden, we won't be able to focus it).
     */
    textArea.style.opacity = '0';

    /**
     * Set the value to be copied on the textarea.
     */
    textArea.value = value;

    /**
     * Add the textarea to the DOM.
     */
    document.body.appendChild(textArea);

    /**
     * Focus and select the value within the textarea.
     */
    textArea.focus();
    textArea.select();

    /**
     * Execute the copy command on the selected value.
     */
    const success = document.execCommand('copy');

    /**
     * Remove the element from the DOM.
     */
    document.body.removeChild(textArea);

    /**
     * Throw an error if the command failed.
     */
    if (!success) {
      throw new Error();
    }

    /**
     * Handle success.
     */
    onSuccess({
      method: 'execCommand',
    });

    /**
     * We can now exit the function.
     */
    return;
  } catch (error) {
    /**
     * Log that the clipboard method failed.
     */
    console.log('Failed to copy to clipboard using execCommand');
  }

  /**
   * Indicate to the user that the copy could not be performed.
   */
  toast.error('Sorry, there was an issue copying to the clipboard.');
};

export default copyToClipboard;
