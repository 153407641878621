import {FunctionComponent, ReactNode, forwardRef} from 'react';

import clsx from 'clsx';

import {Paragraph, Title} from 'components_sb/typography';
import Tags, {TagDefinition} from 'components_sb/typography/Tags/Tags';
import Property from 'models/properties/Property';
import {RouterLink} from 'router/components';

type PropertyCardProps = {
  children: ReactNode;
  property: Property;
  href: string;
  tags?: TagDefinition[];
} & (
  | {
      index: number;
      demo?: never;
    }
  | {
      index?: never;
      demo: true;
    }
);

const PropertyCard: FunctionComponent<PropertyCardProps> = forwardRef<
  HTMLDivElement,
  PropertyCardProps
>(({index, demo, children: content, property, href, tags}, ref) => (
  <div
    ref={ref}
    data-testid={`property-index-card-${demo ? 'demo' : index + 1}`}>
    <RouterLink
      href={href}
      className={clsx(
        'bg-white rounded-2xl',
        'overflow-hidden',
        'flex justify-center',
        'flex-col md:flex-row',
        'gap-x-4',
        'border-2 border-brand-50',
        'cursor-pointer',
        'transition-all duration-300',
        'drop-shadow-none hover:drop-shadow-xl',
      )}>
      {/* Image */}
      <div className={clsx('w-full md:w-60', 'h-52 md:h-auto')}>
        <div
          className="w-full h-full bg-cover bg-center"
          style={{backgroundImage: `url(${property.mainImage})`}}
        />
      </div>
      {/* Main content */}
      <div className={clsx('flex-1 relative', 'flex flex-col', 'p-4 gap-y-2')}>
        {/* General property details */}
        <div className="flex flex-col w-full gap-y-1">
          {/* Top row */}
          <div
            className={clsx(
              'w-full',
              'flex flex-row',
              'flex-wrap-reverse',
              'gap-2',
              'justify-between',
            )}>
            {/* Property location */}
            <Paragraph secondary noMargin>
              {property.suburb}, {property.city}
            </Paragraph>
            {/* Tags */}
            {tags?.length > 0 && <Tags emphasis="secondary">{tags}</Tags>}
          </div>
          {/* Property street address */}
          <Title
            level="h3"
            size="lg"
            noMargin
            testClass="property-index-card--street-address">
            {property.streetAddress}
          </Title>
        </div>
        {/* Content set by parent */}
        {content && <div className="flex flex-col gap-y-2 mt-2">{content}</div>}
      </div>
    </RouterLink>
  </div>
));

export default PropertyCard;
