import React from 'react';

import {useQuery} from 'react-query';

import LoadingView from 'components/common/LoadingView';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

const EnquiryThreadAutoReplyPage = () => {
  const {
    params: {replyToken},
  } = useRoute();

  const {data, isLoading} = useQuery(
    `auto-reply-enquiry-${replyToken}`,
    async () => {
      const response = await fetch(
        `${API_URL}/enquiry_threads/${replyToken}/mark_as_not_interested.json`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const data = await response.json();
      return data;
    },
  );

  if (isLoading) {
    return <Page title="Enquiry">{() => <LoadingView />}</Page>;
  } else if (data) {
    return (
      <Page title="Enquiry">
        {() => (
          <Card className="mt-4">
            <Paragraph>
              Thank you for your response, the owner of the property has been
              notified that you are no longer intereasted.
            </Paragraph>
          </Card>
        )}
      </Page>
    );
  } else {
    return (
      <Page title="Enquiry">
        {() => (
          <Card className="mt-4">
            <Paragraph>
              Sorry, there was an issue notifying the owner of the property
              about your response.
            </Paragraph>
          </Card>
        )}
      </Page>
    );
  }
};

export default EnquiryThreadAutoReplyPage;
