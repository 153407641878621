import {FunctionComponent, ReactNode, useEffect, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';
import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';
import {CapacitorUpdater} from '@capgo/capacitor-updater';
import {View as F7View, App as F7App, f7ready} from 'framework7-react';
import {Framework7Parameters} from 'framework7/types';

import ErrorBoundary from 'components_sb/error-boundaries/ErrorBoundary';
import {WebHeader} from 'components_sb/layout';
import {
  LOCAL_WEB_SERVER_URL,
  NATIVE_LAYOUT_IN_WEB,
  TARGET_ENV,
} from 'globals/app-globals';
import GhostingUserWarning from 'pages/shared/ghost/GhostingUserWarning';
import routes from 'router/routes';
import F7Store from 'stores/F7Store';

import GlobalProviders from './GlobalProviders';

/**
 * Configuration for Framework7.
 * (routes are omitted here and are handled by the useRoutes hook).
 */
const framework7Config: Omit<Framework7Parameters, 'routes'> = {
  /**
   * App name.
   */
  name: 'Keyhook',
  /**
   * Enforce iOS theme for consistency across platforms.
   */
  theme: 'ios',

  /**
   * Disable translucent bars (such as the navbars).
   */
  iosTranslucentBars: false,

  /**
   * Navbar configuration.
   */
  navbar: {
    iosCenterTitle: false,
  },

  /**
   * View configuration.
   */
  view: {
    /**
     * Enable the iOS dynamic nav bar.
     * Refer to: https://framework7.io/docs/navbar#dynamic-navbar
     */
    iosDynamicNavbar: true,

    /**
     * Update the URL in the browser when navigating.
     */
    browserHistory: true,

    /**
     * Empty string to avoid the default separator.
     */
    browserHistorySeparator: '',

    /**
     * Allow a navigation event when navigating to the same URL as the current one.
     */
    // allowDuplicateUrls: true,

    /**
     * Browser history root URL separator, for example "http://my-app.com/".
     * On web this is able to be determined automatically, but on native, Framework7
     * can't correctly guess it resulting in history not persisting on refresh, so we
     * need to specify it manually here. Ensure there is no trailing slash in the URL
     * defined here.
     */
    browserHistoryRoot: Capacitor.isNativePlatform()
      ? ['staging', 'production'].includes(TARGET_ENV)
        ? Capacitor.getPlatform() === 'ios'
          ? 'capacitor://localhost' // iOS
          : 'http://localhost' // Android
        : LOCAL_WEB_SERVER_URL
      : undefined,

    /**
     * Default page transition (individual routes can override this if specified).
     * Use fading transitions on desktop and push (sliding) on native.
     */
    transition:
      Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB
        ? 'f7-push'
        : 'f7-fade',
  },
};

/**
 * App entry component.
 */
const App: FunctionComponent = () => {
  /**
   * Determine whether the app is currently being built for native.
   */
  const isNative = useMemo(() => Capacitor.isNativePlatform(), []);

  /**
   * Force vertical orientation for native when the app first loads.
   */
  useEffect(() => {
    if (isNative) {
      ScreenOrientation.lock({type: OrientationType.PORTRAIT_PRIMARY});
    }
  }, [isNative]);

  /**
   * Notify Capacitor when the app is ready to be used.
   * This is required for Capacitor Updater to be able to update the app.
   * Without it, it will revert the update back to the previous version.
   */
  useEffect(() => {
    /**
     * Wait for Framework7 to indicate it is ready.
     */
    f7ready(() => {
      if (isNative) {
        CapacitorUpdater.notifyAppReady();
      }
    });
  }, [isNative]);

  useEffect(() => {
    /**
     * This fixes an issue when running without a dev server in capacitor
     * For some reason there is no trailing slash on the url, so we need to
     * redirect to the correct url.
     * This is only required for capacitor://localhost, not for other urls.
     * Otherwise we get a blank white screen.
     */
    const href = document.location.href;
    if (href === 'capacitor://localhost') {
      document.location.replace('capacitor://localhost/');
    }
  }, []);

  /**
   * Render the app.
   */
  return (
    <ErrorBoundary>
      <GlobalProviders>
        <div className="h-full flex flex-col">
          {/* Any content rendered here should be contained within the below element so that
            the height can be calculated when necessary via the top-outer-content element ID. */}
          <div id="top-outer-content">
            <GhostingUserWarning />

            {/* The web header is only displayed on the web. */}
            {!Capacitor.isNativePlatform() && !NATIVE_LAYOUT_IN_WEB && (
              <WebHeader />
            )}
          </div>

          <div id="router-container" className="flex-1 relative">
            <F7App store={F7Store} routes={routes} {...framework7Config}>
              <F7View
                main
                /**
                 * Framework7 initialises the view with a z-index of 5000,
                 * which covers dropdowns in the WebHeader, so we need to
                 * set it to a more reasonable value.
                 */
                className="z-0"
              />
            </F7App>
          </div>
        </div>
      </GlobalProviders>
    </ErrorBoundary>
  );
};

export default App;
