import {useMemo} from 'react';

import {ClockIcon} from '@heroicons/react/outline';
import clsx from 'clsx';
import moment from 'moment';

import ChangePhotoModal from 'components/property/landlord/ChangePhotoModal';
import {Button} from 'components_sb/buttons';
import {Modal} from 'components_sb/layout';
import {Paragraph, Title} from 'components_sb/typography';
import Tags, {TagColor} from 'components_sb/typography/Tags/Tags';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Property from 'models/properties/Property';
import Tenancy, {TenancyStatus} from 'models/properties/Tenancy';
import {AccountRole} from 'models/users/User';

import InlineStats from './InlineStats';

const {useModal} = Modal.Imperative;

const PropertyDetailHeaderSection = ({
  property,
  tenancy,
}: {
  property: Property;
  tenancy?: Tenancy;
}) => {
  const {activeAccountRole} = useLocalUserSettings();

  const isLandlord = useMemo(
    () => activeAccountRole === AccountRole.Landlord,
    [activeAccountRole],
  );

  const openModal = useModal();

  const showChangePhotoModal = () => {
    /**
     * Only the Landlord can change the property photo
     */
    if (isLandlord) {
      openModal(ChangePhotoModal, {property});
    }
  };

  const renderStartDateBanner = () => {
    if (tenancy && tenancy.status === TenancyStatus.AwaitingStartDate) {
      let timeToGo = moment(tenancy.startDate).diff(moment(), 'days');
      let unit = 'Days';

      if (timeToGo <= 3) {
        timeToGo = moment(tenancy.startDate).diff(moment(), 'hours');
        unit = 'Hours';
      }

      return (
        <div className="alert alert-info shadow-lg my-4">
          <div>
            <ClockIcon className="w-5 h-5" />
            <span>
              <strong className="underline">
                {timeToGo} {unit}
              </strong>{' '}
              until this tenancy is active on Keyhook.
            </span>
          </div>
        </div>
      );
    }

    return null;
  };

  /**
   * Render the property stats.
   */
  const stats = useMemo(
    () => (
      <InlineStats
        bedrooms={property.bedrooms}
        bathrooms={property.bathrooms}
        garages={property.garages}
        // Total rent should only be shown to the Landlord
        totalRent={tenancy && isLandlord ? tenancy.totalRent : undefined}
        rentalPeriod={tenancy ? tenancy.rentalPeriod : undefined}
      />
    ),
    [property, tenancy, isLandlord],
  );

  return (
    <div className="relative mb-6">
      {/* Blurred main image as the background banner on large screens */}
      <div className="hidden md:flex absolute z-0 top-0 left-0 w-full h-full overflow-hidden">
        <div
          className="bg-cover bg-center blur-lg -mx-6 -my-6"
          style={{
            backgroundImage: `url(${property.mainImage})`,
            /**
             * Extend the image outside of the container so that we don't
             * see the blurred edges.
             */
            width: 'calc(100% + 48px)',
            height: 'calc(100% + 48px)',
          }}
        />
      </div>
      {/* Add an inner shadow to the bottom of the banner on large screens */}
      <div
        className={clsx(
          'hidden md:flex',
          'absolute z-10 bottom-0 left-0',
          'w-full h-16',
          'bg-gradient-to-t from-neutral-800 to-transparent',
          'origin-bottom',
          'scale-y-100 opacity-[30%]',
        )}
      />
      {/* Render the banner content at the same width as the page content */}
      <div
        className={clsx(
          'py-0 md:py-8 flex items-center w-full h-full relative',
          'border-b-2 md:border-none border-brand-50',
          'unbounded-x md:bounded-x',
        )}>
        {/* Large screens (desktop) */}
        <div className="flex-1 hidden md:flex flex-col justify-center my-6">
          <div className="relative flex flex-col md:flex-row md:space-x-0 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 w-full mx-auto border border-white bg-white">
            <div className="flex w-60 relative mr-4">
              <div
                className="w-full h-full rounded-xl bg-cover bg-center"
                style={{backgroundImage: `url(${property.mainImage})`}}>
                {/* Only the Landlord can change the property photo */}
                {isLandlord && (
                  <div className="absolute rounded-xl top-0 left-0 w-full h-full transition-color duration-300 bg-transparent hover:bg-black hover:bg-opacity-50 cursor-pointer group">
                    <div className="w-full h-full flex justify-center items-center invisible group-hover:visible">
                      <div className="mx-auto">
                        <Button
                          label="Change photo"
                          category="primary"
                          size="base"
                          mode="manual"
                          fillWidth={false}
                          onClick={showChangePhotoModal}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full bg-white flex flex-col space-y-2 p-3">
              <div className="flex justify-between item-center">
                <p className="text-brand-850 text-opacity-70 font-medium md:tw-block">
                  {property.suburb}, {property.city}
                </p>

                {/* Arrears should only be shown to the Landlord */}
                {isLandlord &&
                  tenancy &&
                  tenancy.managedRentEnabled &&
                  tenancy.inArrears && (
                    <Tags>{[{text: 'In Arrears', color: TagColor.Red}]}</Tags>
                  )}
              </div>
              <h3 className="font-black text-brand-850 md:text-3xl text-xl">
                {property.streetAddress}
              </h3>
              {stats}
              {renderStartDateBanner()}
            </div>
          </div>
        </div>

        {/* Small screens (mobile) */}
        <div className="flex md:hidden flex-col justify-center mb-3">
          <div className="flex flex-col w-full mx-auto">
            <img
              src={property.mainImage}
              alt={property.streetAddress}
              className="w-full"
              // Only the Landlord can change the property photo
              onClick={isLandlord ? showChangePhotoModal : undefined}
            />

            <div className="w-full flex flex-col py-3 px-6">
              <Paragraph size="sm" secondary>
                {property.suburb}, {property.city}
              </Paragraph>
              <Title level="h3" size="lg">
                {property.streetAddress}
              </Title>
              {stats}
              {renderStartDateBanner()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailHeaderSection;
