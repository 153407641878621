import UserAvatar from 'components/user/UserAvatar';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import {Paragraph, Title} from 'components_sb/typography';
import TenancyMembership from 'models/properties/TenancyMembership';
import {toCurrency} from 'utilities/StringHelpers';

const TenancyMembershipsTable = ({
  tenancyMemberships,
  headTenantId,
}: {
  tenancyMemberships: TenancyMembership[];
  headTenantId?: string;
}) => {
  if (tenancyMemberships.length > 0) {
    return (
      <div>
        <Paragraph>
          Below are the tenants who have signed up to Keyhook.
        </Paragraph>
        <div className="overflow-x-auto hidden lg:tw-block">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th>Active</th>
                {tenancyMemberships.length > 1 && <th>Head Tenant?</th>}
                <th>Share Of Rent</th>
                <th>Bond Paid?</th>
              </tr>
            </thead>
            <tbody>
              {tenancyMemberships.map((ship) => (
                <tr key={ship.id}>
                  <td>
                    <UserAvatar user={ship.renter} size="10" />
                  </td>
                  <td>{ship.renter.name}</td>
                  <td>{ship.active ? 'Yes' : 'No'}</td>
                  {tenancyMemberships.length > 1 && (
                    <td>{ship.renter.id === headTenantId ? 'Yes' : 'No'}</td>
                  )}
                  <td>{toCurrency(ship.rentSplit)}</td>
                  <td>{ship.bondPaid ? 'Yes' : 'No'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="tw-block lg:hidden">
          <div className="flex flex-col gap-2">
            {tenancyMemberships.map((ship) => (
              <div className="mt-2" key={ship.id}>
                <div className="flex justify-between items-center mb-2">
                  <div className="flex justify-start gap-2 items-center">
                    <UserAvatar user={ship.renter} size={12} />
                    <div>
                      <strong>{ship.renter.name}</strong>
                      <p className="text-sm flex flex-col">
                        {tenancyMemberships.length > 1 &&
                          ship.renter.id === headTenantId && (
                            <span>Head Tenant</span>
                          )}
                        <span>{toCurrency(ship.rentSplit)} rent share</span>
                        <span>
                          {ship.bondPaid ? 'Bond paid' : 'Bond not paid yet'}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="w-full h-px bg-gray-200"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <EmptyContentPlaceholder
        type="people"
        message="There are no active tenants on Keyhook for this tenancy"
      />
    );
  }
};

export default TenancyMembershipsTable;
