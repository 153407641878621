import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {createPortal} from 'react-dom';

import {Capacitor} from '@capacitor/core';
import {KeyboardResize} from '@capacitor/keyboard';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';
import {isMobile} from 'react-device-detect';
import {HiOutlineEyeOff, HiOutlineHome} from 'react-icons/hi';

import ManualPropertyAddressModal from 'components/property/landlord/ManualPropertyAddressModal';
import {TextField} from 'components_sb/input';
import {Modal} from 'components_sb/layout';
import {API_URL} from 'globals/app-globals';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import {useKeyboard} from 'providers/KeyboardProvider';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';

const {useModal} = Modal.Imperative;

const VERTICAL_PADDING = 32; // px
const ENTER_EXIT_DURATION = 600; // milliseconds

const AutocompletePropertyAddressModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const openModal = useModal();

  const {
    keyboardIsOpen,
    openKeyboard,
    setKeyboardResizeMode,
    setDefaultKeyboardResizeMode,
  } = useKeyboard();

  /**
   * We have a secondary state representing the visibility of the modal separate to the
   * isOpen props because we want to perform initialisation on the keyboard resize mode
   * before the modal is actually displayed.
   */
  const [showModal, setShowModal] = useState<boolean>(false);

  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isOpening, setIsOpening] = useState<boolean>(false);

  const searchFieldRef = useRef<HTMLInputElement>();

  /**
   * Track the height of the page's body element.
   */
  const [bodyHeight, setBodyHeight] = useState<number>(
    document.body.getBoundingClientRect().height,
  );

  /**
   * Track the height of the search field container.
   */
  const [searchContainerHeight, setSearchContainerHeight] = useState<number>(0);
  const searchContainerRef = useCallback((element: Element) => {
    setSearchContainerHeight(element?.getBoundingClientRect().height ?? 0);
  }, []);

  /**
   * Handle updating the maximum height when the body element resizes.
   */
  const onBodyResize = useCallback<ResizeObserverCallback>((entries) => {
    if (!entries.length) {
      throw new Error('Body element is not present');
    }
    const [bodyElement] = entries;
    setBodyHeight(bodyElement.contentRect.height);
  }, []);

  /**
   * Create a resize observer on the body element of the page to track
   * the height of the body element.
   */
  useEffect(() => {
    /**
     * Create the resize observer.
     */
    const resizeObserver = new ResizeObserver(onBodyResize);
    /**
     * Observe the body element with the resize observer.
     */
    resizeObserver.observe(document.body);
    /**
     * Unobserve when unmounting.
     */
    () => {
      resizeObserver.unobserve(document.body);
    };
  }, [onBodyResize]);

  /**
   * Sets the search field as focused.
   */
  const focusSearchField = useCallback(() => {
    const element = searchFieldRef.current;
    if (element) {
      element.focus();
    }
  }, [searchFieldRef]);

  /**
   * Focus the search input after the modal is made visible and rendered.
   */
  useEffect(() => {
    if (showModal) {
      setTimeout(() => focusSearchField(), ENTER_EXIT_DURATION);
    }
  }, [showModal, focusSearchField]);

  const safeAreaTop = useMemo<number>(() => {
    /**
     * Get the value as a string (i.e. "10px").
     */
    const stringValue = getComputedStyle(document.body).getPropertyValue(
      '--f7-safe-area-top',
    );
    /**
     * Attempt to parse the string.
     */
    if (
      !!stringValue &&
      typeof stringValue === 'string' &&
      stringValue.includes('px')
    ) {
      return Number(stringValue.split('px')[0]);
    }
    return 0;
  }, []);

  /**
   * Evaluate the maximum height for the list of address results.
   */
  const resultsMaxHeight = useMemo(() => {
    if (!showModal) {
      return 0;
    }

    return (
      bodyHeight -
      VERTICAL_PADDING * (safeAreaTop ? 1 : 2) -
      searchContainerHeight -
      safeAreaTop
    );
  }, [showModal, bodyHeight, searchContainerHeight, safeAreaTop]);

  /**
   * Resets the fields back to the initial state.
   */
  const resetForm = useCallback(() => {
    /**
     * Clear any existing search results.
     */
    setSearchOptions([]);
    /**
     * Clear the search query value.
     */
    setSearch('');
  }, []);

  /**
   * Initialises and opens the modal.
   */
  const handleOpen = useCallback(() => {
    resetForm();
    setShowModal(true);
    setIsOpening(false);
  }, [resetForm]);

  useEffect(() => {
    if (isOpening) {
      if (!keyboardIsOpen) {
        openKeyboard();
      } else {
        handleOpen();
      }
    }
  }, [isOpening, keyboardIsOpen, openKeyboard, handleOpen]);

  /**
   * Handle opening the modal when the isOpen prop is true and
   * not yet initialised (showModal has not yet been set to true).
   */
  useEffect(() => {
    const handle = async () => {
      await setKeyboardResizeMode(KeyboardResize.Body);
      setIsOpening(true);
    };
    if (isOpen && !showModal && !isClosing) {
      handle();
    }
  }, [isOpen, showModal, isClosing, setKeyboardResizeMode]);

  const handleClose = useCallback(
    async (afterClose?: () => void) => {
      /**
       * Close the keyboard and revert the layout back to the default mode.
       */
      await setDefaultKeyboardResizeMode();

      /**
       * Indicate that closing is in progress.
       */
      setIsClosing(true);

      /**
       * Close the modal.
       */
      setShowModal(false);

      /**
       * Wait for the modal (and keyboard) to close before resetting the fields, restoring
       * the keyboard mode back to the default setting and notifying the parent that the
       * modal has closed.
       */
      setTimeout(() => {
        resetForm();
        onClose();
        setIsClosing(false);

        /**
         * Invoke the after close callback if provided.
         */
        if (afterClose) {
          afterClose();
        }
      }, ENTER_EXIT_DURATION);
    },
    [resetForm, setDefaultKeyboardResizeMode, onClose],
  );

  /**
   * Listen for the escape key being pressed to close the modal when not
   * in a Capacitor environment.
   */
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      const onKeydown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          handleClose();
        }
      };
      document.addEventListener('keydown', onKeydown);
      return () => document.removeEventListener('keydown', onKeydown);
    }
  }, [handleClose]);

  /**
   * Show the modal for manually entering an address instead of
   * selecting from the search results.
   */
  const showManualAddressModal = (): void => {
    handleClose();
    setTimeout(() => {
      openModal(ManualPropertyAddressModal);
    }, ENTER_EXIT_DURATION / 2);
  };

  /**
   * Invoke the modal close handler if the isOpen prop changes to false.
   */
  useEffect(() => {
    if (!isOpen && showModal) {
      handleClose();
    }
  }, [isOpen, showModal, handleClose]);

  const [search, setSearch] = useState('');

  const onChangeSearch = useCallback<{
    (event: ChangeEvent<HTMLInputElement>): void;
  }>((event) => {
    setSearch(event.target.value);
  }, []);

  const [searchOptions, setSearchOptions] = useState<any[]>([]);

  const hasSearchOptions = useMemo<boolean>(
    () => searchOptions.length > 0,
    [searchOptions],
  );

  const router = useRouter();

  /**
   * Track that the add property flow and first step has started
   * when the modal opens.
   */
  useEffect(() => {
    if (isOpen && showModal) {
      TrackingService.trackEvent(TrackingService.Event.AddProperty_StartFlow);
      TrackingService.trackEvent(
        TrackingService.Event.AddProperty_StartAddressStep,
      );
    }
  }, [isOpen, showModal]);

  /**
   * Fetch and set results for the current search query.
   */
  const filter = useCallback(async (): Promise<any[]> => {
    if (search.length <= 2) {
      setSearchOptions([]);
      return;
    }

    const url = `${API_URL}/addresses.json?query=${search}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const places = data.addresses;

      setSearchOptions(places);
    } catch (e) {
      setSearchOptions([]);
    }
  }, [search]);

  /**
   * Debounce the search query function.
   */
  const query = AwesomeDebouncePromise(filter, 300);

  /**
   * Handle selecting an address.
   */
  const selectAddress = useCallback(
    async (option: any) => {
      if (option) {
        handleClose(() => {
          /**
           * Will be invoked after the modal has fully closed.
           */
          router.navigate('/properties/initialise', {
            props: {
              addressOptionId: option.id,
            },
          });
        });
      }
    },
    [router, handleClose],
  );

  /**
   * Perform the search query request when the search
   * query value changes.
   */
  useEffect(() => {
    query();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const smBreakpoint = useTailwindBreakpoint('sm');

  return !isOpen && !showModal
    ? null
    : createPortal(
        <AnimatePresence>
          {showModal && (
            <div
              className={clsx(
                'z-100 absolute',
                'top-0 left-0',
                'w-full h-full',
                'flex items-center justify-center',
              )}>
              {/* Background */}
              <motion.div
                role="button"
                onClick={() => handleClose()}
                className={clsx(
                  'absolute',
                  'top-0 left-0',
                  'w-full h-full',
                  'bg-black/40',
                  'backdrop-blur-sm',
                )}
                transition={{
                  type: 'easeOut',
                  duration: (0.5 * ENTER_EXIT_DURATION) / 1000,
                }}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
              />
              {/* Modal */}
              <motion.div
                className={clsx(
                  'pointer-events-none',
                  'w-full',
                  'origin-center',
                  'flex flex-row',
                  'justify-center',
                  'px-2 sm:px-4 md:px-6 lg:px-8 box-border',
                )}
                style={{
                  paddingTop: safeAreaTop,
                }}
                transition={{
                  type: 'spring',
                  duration: ENTER_EXIT_DURATION / 1000,
                }}
                initial={{opacity: 0, y: 100, scale: 0.7}}
                animate={{opacity: 1, y: 0, scale: 1}}
                exit={{opacity: 0, y: 100, scale: 0.7}}>
                <div
                  className={clsx(
                    'pointer-events-auto',
                    'flex-1 max-w-2xl',
                    'bg-white rounded-3xl',
                    'dropshadow-2xl',
                    'overflow-hidden',
                    'flex flex-col',
                  )}>
                  {/* Address search field */}
                  <div
                    ref={searchContainerRef}
                    className={clsx(
                      hasSearchOptions ? 'pl-4 pt-4 pr-4' : 'p-4',
                    )}>
                    <TextField
                      ref={searchFieldRef}
                      name="address"
                      size="lg"
                      mode="manual"
                      value={search}
                      onChange={onChangeSearch}
                      autocomplete="off"
                      spellcheck={false}
                      placeholder={
                        smBreakpoint
                          ? 'Start typing your address to add your property...'
                          : 'Start typing your address...'
                      }
                    />
                  </div>
                  {/* Search results */}
                  <div
                    className={clsx(
                      'transition-all duration-300',
                      'overflow-auto',
                      'h-[500px]',
                      'relative',
                    )}
                    style={{
                      /**
                       * We need to use max-height to allow for the dynamic height transition.
                       */
                      maxHeight: hasSearchOptions ? resultsMaxHeight : 0,
                    }}>
                    <div className="h-full max-h-full pt-3 flex flex-col">
                      <div className="flex-1 overflow-auto">
                        {searchOptions.map((place, index) => {
                          const [street, ...rest] = place.text.split(', ');
                          return (
                            <button
                              key={index}
                              role="button"
                              className={clsx(
                                'flex flex-row justify-start items-center',
                                'gap-x-2 sm:gap-x-4',
                                'px-4 py-2',
                                'transition-colors duration-300',
                                'bg-white hover:bg-brand-50 cursor-pointer',
                              )}
                              onClick={() => selectAddress(place)}>
                              <HiOutlineHome className="w-6 h-6 text-brand-300" />
                              <div className="flex flex-col items-start text-left">
                                <div className="font-medium text-brand-850">
                                  {street}
                                </div>
                                <div className="text-sm text-brand-850 text-opacity-70">
                                  {rest.join(', ')}
                                </div>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                      <button
                        className={clsx(
                          'flex flex-row justify-start items-center',
                          'gap-x-2 sm:gap-x-4',
                          'px-4 py-4',
                          'transition-colors duration-300',
                          'bg-white hover:bg-brand-50 cursor-pointer',
                        )}
                        onClick={showManualAddressModal}>
                        <HiOutlineEyeOff className="w-6 h-6 text-brand-300" />
                        <div className="flex flex-col items-start text-left">
                          <div className="font-medium text-brand-500">
                            Can't find your address?
                          </div>
                          <div className="text-sm text-brand-300">
                            {`${
                              isMobile ? 'Tap' : 'Click'
                            } here to add it manually`}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>,
        document.body,
      );

  // return (
  //   <div data-testid="autocomplete-property-address-modal">
  //     <CommandPalette
  //       onChangeSearch={setSearch}
  //       onChangeOpen={onChangeOpen}
  //       search={search}
  //       isOpen={showModal}
  //       page="root"
  //       placeholder="Start typing your property address...">
  //       <CommandPalette.Page id="root">
  //         {searchOptions.length === 0 && (
  //           <CommandPalette.List>
  //             <AddPropertyDescription close={handleClose} />
  //           </CommandPalette.List>
  //         )}
  //         {searchOptions.length > 0 && (
  //           <CommandPalette.List heading="Address Suggestions">
  //             {searchOptions.map((place, index) => {
  //               const [street, ...rest] = place.text.split(', ');
  //               return (
  //                 <div
  //                   key={index}
  //                   className="flex items-center space-x-2 sm:space-x-4 py-2 hover:bg-neutral-100 cursor-pointer"
  //                   onClick={() => selectAddress(place)}>
  //                   <span className="tw-block text-neutral-400">
  //                     <BiBuildingHouse className="w-6 h-6" />
  //                   </span>
  //                   <div className="flex flex-col">
  //                     <span className="tw-block font-medium text-neutral-700">
  //                       {street}
  //                     </span>
  //                     <span className="tw-block text-sm text-neutral-500">
  //                       {rest.join(', ')}
  //                     </span>
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //           </CommandPalette.List>
  //         )}
  //       </CommandPalette.Page>
  //     </CommandPalette>
  //   </div>
  // );
};

export default AutocompletePropertyAddressModal;
