import {lazy, useState, Suspense} from 'react';

export interface LightboxGallerySource {
  thumbnail?: string;
  source: string;
  type: 'image' | 'video';
}

const LightboxGallery = ({sources}: {sources: LightboxGallerySource[]}) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick = (index: number) => {
    setSelectedIndex(index + 1);
    setLightboxOpen(!lightboxOpen);
  };

  return (
    <div>
      <div className="flex flex-wrap flex-start gap-4">
        {sources.map((source, index) => {
          if (source.type === 'image') {
            return (
              <img
                onClick={() => handleClick(index)}
                key={index}
                className="inline-block max-h-[200px] max-w-[200px] cursor-pointer rounded-lg"
                src={source.thumbnail || source.source}
              />
            );
          } else {
            return (
              <video
                poster={source.thumbnail || null}
                onClick={() => handleClick(index)}
                className="inline-block max-h-[200px] max-w-[200px] cursor-pointer rounded-lg"
                key={index}>
                <source src={source.source} type="video/mp4" />
              </video>
            );
          }
        })}
      </div>
    </div>
  );
};

export default LightboxGallery;
