import {useCallback, useEffect, useMemo, useState} from 'react';

interface ElementSize {
  width: number;
  height: number;
}

interface UseElementSizeHook {
  (elementId: string): ElementSize;
}

const useElementSize: UseElementSizeHook = (elementId) => {
  /**
   * Track the width and height of the element.
   */
  const [size, setSize] = useState<ElementSize>({
    width: 0,
    height: 0,
  });

  const element = useMemo<Element>(
    () => document.getElementById(elementId),
    [elementId],
  );

  /**
   * Handle updating the maximum height when the body element resizes.
   */
  const onResize = useCallback<ResizeObserverCallback>((entries) => {
    if (!entries.length) {
      throw new Error('Body element is not present');
    }
    const [element] = entries;
    const {
      contentRect: {width, height},
    } = element;
    setSize({width, height});
  }, []);

  /**
   * Create a resize observer on the element.
   */
  useEffect(() => {
    /**
     * Do not proceed to add the listener if the element does not exist.
     */
    if (!element) {
      console.warn(`Element with ID '${element}' not found in DOM`);
      return;
    }

    /**
     * Create the resize observer.
     */
    const resizeObserver = new ResizeObserver(onResize);
    /**
     * Observe the body element with the resize observer.
     */
    resizeObserver.observe(element);
    /**
     * Unobserve when unmounting.
     */
    () => {
      resizeObserver.unobserve(element);
    };
  }, [onResize, element]);

  return size;
};

/**
 * Convenience function for accessing only the width of the element.
 */
export const useElementWidth = (elementId: string): number =>
  useElementSize(elementId).width;

/**
 * Convenience function for accessing only the height of the element.
 */
export const useElementHeight = (elementId: string): number =>
  useElementSize(elementId).height;

export default useElementSize;
