import {useCallback, useMemo} from 'react';

import {CalendarIcon} from '@heroicons/react/outline';
import moment from 'moment';
import {RiDoorOpenLine} from 'react-icons/ri';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Listing from 'models/listings/Listing';
import OpenHomeAttendee from 'models/listings/OpenHomeAttendee';
import {AccountRole} from 'models/users/User';
import useRouter from 'router/hooks/useRouter';

import OpenHomeRow from './OpenHomeRow';

interface OpenHomesCardProps {
  listing: Listing;
  currentUserOwnsListing: boolean;
}

const OpenHomesCard = ({
  listing,
  currentUserOwnsListing,
}: OpenHomesCardProps) => {
  const {activeAccountRole} = useLocalUserSettings();

  const filteredOpenHomes = useMemo(
    () =>
      listing.openHomes.filter((oh) => moment(oh.startTime).isAfter(moment())),
    [listing.openHomes],
  );

  /**
   * Scroll the enquiry card into view and focus the message field.
   */
  const onRequestPrivateViewing = useCallback(() => {
    /**
     * Smoothly scroll to the enquiry card.
     */
    document
      .getElementById('enquiry-card')
      ?.scrollIntoView({behavior: 'smooth'});

    /**
     * Allow the scroll to complete before focusing the message field and entering
     * a default message.
     */
    setTimeout(() => {
      const inputElement = document.getElementById('enquiry-message-field');
      if (inputElement) {
        inputElement.focus();
        inputElement.innerText =
          'Hi, I would like to arrange a private viewing of this property please.';
        /**
         * Invoke a change event to ensure the text is detected by the form.
         */
        const event = new Event('input', {bubbles: true});
        inputElement.dispatchEvent(event);
      }
    }, 1000);
  }, []);

  return (
    <Card
      icon={RiDoorOpenLine}
      title="Open Homes"
      subtitle="Find a time to view this property">
      {filteredOpenHomes.length > 0 ? (
        // Listing has open homes
        <>
          <div>
            {filteredOpenHomes.map((openHome) => (
              <OpenHomeRow key={openHome.id} openHome={openHome} />
            ))}
          </div>
          {!currentUserOwnsListing &&
            activeAccountRole !== AccountRole.Landlord && (
              <div className="flex flex-col gap-y-4">
                <Paragraph>
                  Please contact the Landlord if you would like to arrange a
                  private viewing.
                </Paragraph>
                <Button
                  label={`Request a private viewing`}
                  category="primary"
                  size="base"
                  mode="manual"
                  onClick={onRequestPrivateViewing}
                />
              </div>
            )}
        </>
      ) : (
        // Listing does not have any open homes
        <>
          <Paragraph>
            There are currently no open homes for this property.
          </Paragraph>
          {!currentUserOwnsListing &&
            activeAccountRole !== AccountRole.Landlord && (
              <>
                <Paragraph>
                  Please contact the Landlord if you would like to arrange a
                  private viewing.
                </Paragraph>
                <Button
                  label={`Request a private viewing`}
                  category="primary"
                  size="base"
                  mode="manual"
                  onClick={onRequestPrivateViewing}
                />
              </>
            )}
        </>
      )}
    </Card>
  );
};

export default OpenHomesCard;
