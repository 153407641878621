import {useCallback, useEffect, useMemo, useState} from 'react';

import {Device} from '@capacitor/device';
import {Network} from '@capacitor/network';
import {ArrowLeftIcon} from '@heroicons/react/solid';
import {
  PreviewCamera,
  type CaptureSuccessResult,
} from '@numbersprotocol/preview-camera';
import clsx from 'clsx';
import {motion} from 'framer-motion';
import {useTimer} from 'use-timer';

import InspectionAddActionPhotoModal from 'components/inspection/InspectionAddActionPhotoModal';
import InspectionAddNotePhotoModal from 'components/inspection/InspectionAddNotePhotoModal';
import {Modal} from 'components_sb/layout';
import Camera, {CloseCameraFunction} from 'components_sb/miscellaneous/Camera';
import useInspectionQueries from 'hooks/useInspectionQueries';
import {useInspectionUploader} from 'providers/InspectionUploader';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useInspectionStore, {PendingUploadRow} from 'stores/InspectionStore';

import theme from '../../../themes/keyhook';

const {useModal} = Modal.Imperative;

const INACTIVE_COLOR = 'rgba(140, 140, 140, 0.5)';
const ACTIVE_COLOR = '#ffffffb2';
const CAPTURE_BUTTON_SIZE = 78;

const BORDER_WIDTH = CAPTURE_BUTTON_SIZE * 0.1;

const RECORD_VIDEO_THRESHOLD = 20;

type AttachmentType = 'normal' | 'note' | 'action';

let isRecordingVideo = false;

const InspectionCameraInterface = ({
  closeCamera,
}: {
  closeCamera: CloseCameraFunction;
}) => {
  const route = useRoute();

  const {
    params: {roomKey},
  } = route;

  const decodedRoomKey = decodeURIComponent(roomKey);

  const {
    time: pressTime,
    start: startPressTimer,
    reset: resetPressTimer,
  } = useTimer({interval: 10});

  const [isVirtual, setIsVirtual] = useState(true);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const [networkConnected, setNetworkConnected] = useState<boolean>(true);

  const openModal = useModal();

  const inspectionUploader = useInspectionUploader();

  const [attachmentType, setAttachmentType] =
    useState<AttachmentType>('normal');

  const [
    inspection,
    inspectionItems,
    selectedRoomKey,
    setSelectedRoomKey,
    database,
  ] = useInspectionStore((state) => [
    state.inspection,
    state.inspectionItems,
    state.selectedRoomKey,
    state.setSelectedRoomKey,
    state.database,
  ]);

  const handleUpdateRoomKey = useCallback(() => {
    if (!selectedRoomKey) {
      setSelectedRoomKey(decodedRoomKey);
    }
  }, [decodedRoomKey, selectedRoomKey, setSelectedRoomKey]);

  useEffect(() => {
    handleUpdateRoomKey();
  }, [roomKey, handleUpdateRoomKey]);

  const roomItems = useMemo(
    () => inspectionItems?.filter((item) => item.room === selectedRoomKey),
    [inspectionItems, selectedRoomKey],
  );

  const handleMediaCaptured = useCallback(
    async (data: CaptureSuccessResult) => {
      if (!data || !data.path) {
        return;
      }

      if (attachmentType === 'normal') {
        const doc = {
          data: data.path,
          attachmentType: 'normal',
          inspectionId: inspection.id,
          inspectionItemId: roomItems[activeItemIndex].id,
          notes: null,
          size: data.size,
          _id: new Date().getTime().toString(),
        } as PendingUploadRow;
        const result = await database.put(doc);
        if (result.ok && networkConnected) {
          if (!inspectionUploader.isRunning) {
            try {
              inspectionUploader.processQueue(inspection.id);
            } catch (e) {
              console.log(e);
            }
          }
        }
      } else if (attachmentType === 'note') {
        openModal(InspectionAddNotePhotoModal, {
          inspectionItem: roomItems[activeItemIndex],
          inspection,
          propertyId: inspection.tenancy.propertyId,
          filePath: data.path,
          size: data.size,
        });
      } else if (attachmentType === 'action') {
        openModal(InspectionAddActionPhotoModal, {
          inspectionItem: roomItems[activeItemIndex],
          inspection,
          propertyId: inspection.tenancy.propertyId,
          filePath: data.path,
          size: data.size,
        });
      }

      setAttachmentType('normal');
    },
    [
      activeItemIndex,
      attachmentType,
      database,
      inspection,
      inspectionUploader,
      networkConnected,
      openModal,
      roomItems,
    ],
  );

  useEffect(() => {
    Device.getInfo().then((info) => {
      setIsVirtual(info.isVirtual);
    });

    Network.addListener('networkStatusChange', (status) => {
      setNetworkConnected(status.connected);
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  useEffect(() => {
    PreviewCamera.addListener('captureSuccessResult', (result) => {
      handleMediaCaptured(result);
    });

    return () => {
      PreviewCamera.removeAllListeners();
    };
  }, [handleMediaCaptured]);

  const goBack = useCallback(() => {
    closeCamera({
      action: 'back',
    });
  }, [closeCamera]);

  const startCameraPress = () => {
    startPressTimer();
  };

  useEffect(() => {
    if (pressTime > RECORD_VIDEO_THRESHOLD) {
      if (!isVirtual && !isRecordingVideo) {
        isRecordingVideo = true;
        PreviewCamera.startRecord();
      }
    }
  }, [pressTime, isVirtual]);

  const finishCameraPress = () => {
    if (!isVirtual) {
      if (pressTime <= RECORD_VIDEO_THRESHOLD) {
        PreviewCamera.takePhoto();
      } else {
        PreviewCamera.stopRecord();
      }
    }

    resetPressTimer();
    isRecordingVideo = false;
  };

  const navigateToRoomPage = useCallback(() => {
    closeCamera({
      action: 'navigate',
      href: route.path.replace('/camera', ''),
    });
  }, [closeCamera, route.path]);

  return (
    <div className="h-full w-full bg-transparent relative">
      <div className="absolute top-10 left-4 h-3/4 w-3/4 overflow-x-wrap overflow-y-auto flex flex-col items-start space-y-2">
        <a
          className="rounded-full text-white p-2 mb-6 select-none"
          style={{backgroundColor: INACTIVE_COLOR}}
          onClick={goBack}>
          <ArrowLeftIcon className="w-5 h-5" />
        </a>
        {roomItems.map((item, index) => (
          <motion.button
            key={item.id}
            type="button"
            onClick={() => {
              setActiveItemIndex(index);
            }}
            whileTap={{scale: 0.8, transition: {duration: 0.2}}}
            className={clsx(
              'w-auto py-1 px-3 min-h-9 rounded-lg text-sm select-none flex items-center text-left',
              index === activeItemIndex
                ? 'text-brand-850 bg-white/70'
                : 'text-white bg-brand-200/30',
            )}
            style={{
              backgroundColor:
                index === activeItemIndex ? ACTIVE_COLOR : INACTIVE_COLOR,
            }}>
            {item.name}

            {item.inspectionItemAttachments.length > 0 && (
              <div className="bg-info ml-2 w-6 h-6 flex items-center leading-none justify-center text-brand-850 bg-white/70 text-xs font-medium rounded-full">
                {item.inspectionItemAttachments.length}
              </div>
            )}

            {item.inspectionActions.length > 0 && (
              <div className="bg-warning ml-2 w-6 h-6 flex items-center leading-none justify-center text-white text-xs font-medium rounded-full">
                {item.inspectionActions.length}
              </div>
            )}
          </motion.button>
        ))}
        <button
          type="button"
          className="w-auto py-1 px-3 min-h-9 rounded-lg text-sm text-white bg-brand-500/70 select-none"
          onClick={navigateToRoomPage}>
          Review Photos
        </button>

        {/* This is extra padding for small phones so that the Review Photos doesnt hide behind the Add Note button */}
        <div style={{height: 48}}></div>
      </div>

      <div className="absolute bottom-10 left-4 right-4 flex justify-between items-center">
        <motion.button
          className="w-auto py-2 px-3 min-h-9 rounded-lg text-sm text-white select-none"
          whileTap={{scale: 0.8, transition: {duration: 0.2}}}
          style={{
            backgroundColor:
              attachmentType === 'note'
                ? theme.daisyUITheme['info']
                : INACTIVE_COLOR,
          }}
          onClick={() => {
            const newType = attachmentType === 'note' ? 'normal' : 'note';
            setAttachmentType(newType);
          }}>
          Add Note
        </motion.button>
        <motion.button
          className="select-none"
          style={{
            width: CAPTURE_BUTTON_SIZE,
            height: CAPTURE_BUTTON_SIZE,
            borderRadius: 9999,
            borderWidth: BORDER_WIDTH,
            borderColor: 'white',
          }}
          whileTap={{
            backgroundColor: theme.colors.brand[500],
            borderWidth: BORDER_WIDTH * 2,
            transition: {duration: 0.1},
          }}
          onTouchStart={startCameraPress}
          onTouchEnd={finishCameraPress}></motion.button>
        <motion.button
          className="w-auto py-2 px-3 min-h-9 rounded-lg text-sm text-white select-none"
          whileTap={{scale: 0.8, transition: {duration: 0.2}}}
          style={{
            backgroundColor:
              attachmentType === 'action'
                ? theme.daisyUITheme['warning']
                : INACTIVE_COLOR,
          }}
          onClick={() => {
            const newType = attachmentType === 'action' ? 'normal' : 'action';
            setAttachmentType(newType);
          }}>
          Add Action
        </motion.button>
      </div>
    </div>
  );
};

const InspectionCameraPage = () => {
  const route = useRoute();

  const {
    params: {inspectionId},
  } = route;

  const inspectionQueries = useInspectionQueries(inspectionId);

  return (
    <Page
      disableNavbar
      disableTabs
      loading={inspectionQueries.isLoading}
      error={inspectionQueries.isError}>
      {({isIn}) =>
        /**
         * It's important that we only render the Camera component when the page is "in".
         * The Camera component performs the opening and closing of the camera and modification
         * to the root element when the Camera component itself mounts and unmounts, and so
         * if we do not unmount the component when navigating to a deeper page, then the Camera
         * will stay mounted in the background, and cause the device's camera to continue running
         * and the the overlay will persist on the deeper pages.
         */
        !isIn ? null : (
          <>
            <Camera>
              {(close) => <InspectionCameraInterface closeCamera={close} />}
            </Camera>
          </>
        )
      }
    </Page>
  );
};

export default InspectionCameraPage;
