import React, {useCallback, useContext, useMemo, useState} from 'react';

import moment from 'moment';
import {HiOutlineDocument, HiOutlineDocumentText} from 'react-icons/hi';
import {MdOutlineArchive, MdOutlineUnarchive} from 'react-icons/md';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import useAuth from 'auth/provider/useAuth';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Tags, {TagColor} from 'components_sb/typography/Tags/Tags';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import User from 'models/users/User';
import {FullScreenLoaderContext} from 'providers/FullScreenLoader';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {saveResource} from 'utilities/SpraypaintHelpers';

import ListingUserNotesEditor from '../listings/ListingUserNotesEditor/ListingUserNotesEditor';

const EnquiryThreadDetailPage = () => {
  const {
    params: {propertyId, listingPublicId, enquiryThreadId},
  } = useRoute();

  const queryClient = useQueryClient();
  const router = useRouter();

  const {currentUser} = useAuth();
  const [messageText, setMessageText] = useState('');
  const [isSendingNewMessage, setIsSendingNewMessage] = useState(false);

  const {
    data: enquiryThread,
    error,
    isLoading,
  } = useQuery(`enquiry-${enquiryThreadId}`, async () => {
    const thread = await EnquiryThread.includes({
      enquiry_messages: 'user',
    })
      .order({'enquiry_messages.created_at': 'asc'})
      .find(enquiryThreadId);

    return thread.data;
  });

  /**
   * Indicate a reload is required for the enquiry threads list page.
   */
  const invalidateIndexQuery = useCallback(() => {
    queryClient.invalidateQueries([
      `listing-enquiry-threads`,
      {
        listingPublicId,
      },
    ]);
  }, [queryClient, listingPublicId]);

  const sendMessage = async () => {
    setIsSendingNewMessage(true);

    const message = new EnquiryMessage({
      userId: currentUser.id,
      message: messageText,
      enquiryThreadId: enquiryThreadId,
    });

    const result = await message.save();
    if (result) {
      setMessageText('');

      const thread = enquiryThread;
      message.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
      });
      message.user.isPersisted = true;
      enquiryThread.enquiryMessages.push(message);

      queryClient.setQueryData(`enquiry-${enquiryThreadId}`, thread);

      invalidateIndexQuery();
    }

    setIsSendingNewMessage(false);
  };

  const onSchedulePrivateViewing = useCallback(async () => {
    /**
     * Get the name and email address of the person enquring.
     */
    const {name, email} = enquiryThread;

    /**
     * Navigate to the new private viewing page, passing the enquiry details
     * as location state so that the form is prefilled.
     */
    router.navigate(
      `/properties/${propertyId}/listings/${listingPublicId}/viewings/private/new`,
      {
        props: {
          enquiry: {
            name,
            email,
          },
        },
      },
    );
  }, [router, propertyId, listingPublicId, enquiryThread]);

  /**
   * Find the most recent message in the enquiry thread.
   */
  const lastMessage = useMemo(
    () =>
      !enquiryThread
        ? null
        : enquiryThread.enquiryMessages[
            enquiryThread.enquiryMessages.length - 1
          ] ?? null,
    [enquiryThread],
  );

  /**
   * Determine if the last message in the enquiry thread was sent by the current user.
   */
  const currentUserSentLastMessage = useMemo<boolean>(
    () => !!lastMessage && lastMessage.userId === currentUser.id,
    [lastMessage, currentUser],
  );

  const fullScreenLoader = useContext(FullScreenLoaderContext);

  /**
   * Sets whether the enquiry thread should be archived.
   */
  const setArchivedStatus = useCallback(
    async (archived: boolean) => {
      fullScreenLoader.activate();

      /**
       * Set the new archived status.
       */
      enquiryThread.assignAttributes({
        archived,
      });

      /**
       * Save the enquiry thread with the new status.
       */
      const success = await saveResource(enquiryThread);

      if (!success) {
        enquiryThread.rollback();
        toast.error(
          `Sorry, there was an issue ${
            archived ? 'archiving' : 'un-archiving'
          } this enquiry.`,
        );
      } else {
        queryClient.setQueryData(`enquiry-${enquiryThreadId}`, enquiryThread);
      }

      /**
       * Invalidate the index query so that the list of enquiry threads
       * will reload on next visit to the index page.
       */
      invalidateIndexQuery();

      fullScreenLoader.deactivate();

      if (success) {
        toast.success(
          `The enquiry has been ${archived ? 'archived' : 'un-archived'}!`,
        );
        if (archived) {
          router.navigate(
            `/properties/${propertyId}/listings/${listingPublicId}/enquiries`,
          );
        }
      }
    },
    [
      fullScreenLoader,
      invalidateIndexQuery,
      enquiryThread,
      router,
      propertyId,
      listingPublicId,
      enquiryThreadId,
      queryClient,
    ],
  );

  /**
   * Marks the enquiry thread as archived.
   */
  const onArchiveEnquiryThread = useCallback(() => {
    setArchivedStatus(true);
  }, [setArchivedStatus]);

  /**
   * Un-marks the enquiry thread as archived.
   */
  const onUnarchiveEnquiryThread = useCallback(() => {
    setArchivedStatus(false);
  }, [setArchivedStatus]);

  /**
   * Find the email address of the user that is enquiring.
   */
  const enquiringUserEmail = useMemo<string | null>(
    () => enquiryThread?.email ?? null,
    [enquiryThread],
  );

  return (
    <Page
      title={`Listing ${listingPublicId} Enquiry`}
      loading={isLoading}
      error={error}
      actions={
        !enquiryThread
          ? []
          : enquiryThread.archived
          ? [
              {
                label: 'Unarchive enquiry',
                icon: MdOutlineUnarchive,
                onClick: onUnarchiveEnquiryThread,
              },
            ]
          : [
              {
                label: 'Archive enquiry',
                icon: MdOutlineArchive,
                onClick: onArchiveEnquiryThread,
              },
            ]
      }>
      {() => (
        <>
          <div className="w-full flex flex-row flex-wrap mb-6">
            <Tags size="lg">
              {[
                ...(enquiryThread?.archived
                  ? [{text: 'Archived', color: TagColor.Grey}]
                  : []),
                ...(enquiryThread?.hasApplied ? ['Applied'] : []),
                ...(!!lastMessage && !currentUserSentLastMessage
                  ? [{text: 'Awaiting Your Reply', color: TagColor.Yellow}]
                  : []),
              ]}
            </Tags>
          </div>
          {enquiryThread.enquiryMessages.map((msg) => (
            <Card key={msg.id} className="mb-4">
              {msg.user ? (
                <div className="flex justify-start items-center">
                  <div>
                    <UserAvatar user={msg.user} size="12" />
                  </div>
                  <div className="ml-4">
                    {msg.user.id === currentUser.id ? (
                      <h2 className="card-title mb-0 text-brand-850 flex justify-between">
                        You
                      </h2>
                    ) : (
                      <>
                        <h2 className="card-title mb-0 text-brand-850 flex justify-between">
                          {msg.user.name}
                        </h2>
                        <span className="tw-block text-secondary text-sm">
                          Keyhook User
                        </span>
                      </>
                    )}

                    <span className="tw-block text-secondary text-sm">
                      {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="card-title mb-0 text-brand-850 flex justify-between">
                    {msg.name}
                  </h2>
                  <span className="tw-block text-secondary text-sm">
                    {msg.email}
                  </span>
                  <span className="tw-block text-secondary text-sm">
                    {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                  </span>
                </div>
              )}

              {msg.message.split('\n').map((text, index) => (
                <p className="mt-1" key={index}>
                  {text}
                </p>
              ))}
            </Card>
          ))}

          <Card
            title="Reply"
            actions={[
              {
                label: 'Schedule private viewing',
                onClick: onSchedulePrivateViewing,
              },
            ]}>
            <form className="relative flex">
              <textarea
                placeholder="Your message here..."
                className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-300 pl-6 bg-white rounded-full border-gray-200 pr-20"
                value={messageText}
                onChange={(e) =>
                  setMessageText(e.currentTarget.value)
                }></textarea>

              <div className="absolute right-0 items-center inset-y-0 flex">
                <button
                  type="button"
                  disabled={messageText.length === 0 || isSendingNewMessage}
                  onClick={() => sendMessage()}
                  className="inline-flex items-center justify-center rounded-full h-10 w-10 mr-2 transition duration-500 ease-in-out text-white bg-primary hover:bg-primary-focus focus:outline-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5 transform rotate-90">
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                  </svg>
                </button>
              </div>
            </form>
          </Card>

          {/* User's application status */}

          <Card title="Application">
            <div className="flex flex-col gap-y-2">
              <Paragraph>
                {`This person has ${
                  enquiryThread.hasApplied ? '' : 'not yet '
                }applied for your listing.`}
              </Paragraph>
              {enquiryThread.hasApplied && (
                <div className="max-w-auto md:max-w-xs">
                  <Button
                    label="View application"
                    icon={HiOutlineDocumentText}
                    category="primary"
                    size="base"
                    mode="link"
                    linkTo={`/properties/${propertyId}/listings/${listingPublicId}/applications/${enquiryThread.listingRentalApplicationId}`}
                  />
                </div>
              )}
            </div>
          </Card>

          {/* Notes about user */}
          {enquiringUserEmail !== null && (
            <ListingUserNotesEditor
              asCard
              listingPrivateId={enquiryThread.listingId}
              userEmail={enquiringUserEmail}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default EnquiryThreadDetailPage;
