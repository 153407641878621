import React, {Fragment, useRef} from 'react';

import {Dialog, Transition} from '@headlessui/react';

const ProfileItemModal = ({
  modalIsOpen,
  setModalIsOpen,
  title,
  subtitle,
  IconClass,
  bgColor,
  form,
}: {
  modalIsOpen: boolean;
  setModalIsOpen: (val: boolean) => void;
  title: string;
  subtitle: string;
  IconClass: any;
  bgColor: string;
  form: JSX.Element;
}) => {
  /**
   * Set this as a ref on a particular input component to focus on
   * upon opening the modal.
   */
  const initialFocusRef = useRef();

  const height =
    parseInt((window.screen.height * 0.8) as any).toString() + 'px';
  const containerStyle = {
    height: 'auto',
    maxHeight: height,
    maxWidth: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  return (
    <Transition show={modalIsOpen} as={Fragment}>
      <Dialog
        onClose={() => setModalIsOpen(false)}
        className="relative z-50"
        initialFocus={initialFocusRef}>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        </Transition.Child>
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Dialog.Panel className="mx-auto w-full lg:w-2/3 rounded-xl bg-white">
              {/* A hidden element to be focused on upon opening the modal. This ensures
              that the focus is captured within the modal without affecting the focus
              state of any functional elements within the modal. */}
              <div ref={initialFocusRef} className="hidden" />

              <div className="flex justify-start">
                <div
                  className={`w-1/2 ${bgColor} rounded-xl rounded-r-none hidden md:tw-block profile-item-modal"`}
                  style={containerStyle as any}>
                  <div className="flex flex-col h-full justify-center items-center p-8">
                    <div className="bg-white rounded-full w-16 h-16 flex justify-center items-center mb-4">
                      <IconClass className="w-10 h-10 " />
                    </div>
                    <h3 className="text-2xl font-extrabold leading-snug mb-2">
                      {title}
                    </h3>

                    <p className="text-sm">{subtitle}</p>
                  </div>
                </div>
                <div
                  className="h-full w-full md:w-1/2 p-8 overflow-y-auto flex flex-col"
                  style={containerStyle as any}>
                  <div
                    className={`w-full ${bgColor} rounded-xl md:hidden mb-8`}>
                    <div className="flex flex-col justify-center items-center h-full p-4">
                      <div className="bg-white rounded-full w-16 h-16 flex justify-center items-center mb-2">
                        <IconClass className="w-10 h-10 " />
                      </div>
                      <h3 className="text-2xl font-extrabold leading-snug">
                        {title}
                      </h3>

                      <p className="text-sm">{subtitle}</p>
                    </div>
                  </div>

                  {form}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ProfileItemModal;
