import {
  ChangeEventHandler,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {cva} from 'class-variance-authority';
import {FormikProps, useFormik} from 'formik';
import {CodeInput as RCICodeInput, getSegmentCssWidth} from 'rci';
import {useIsFocused} from 'use-is-focused';

import {InlineError} from 'components_sb/feedback';

type CodeState = 'input' | 'loading' | 'error' | 'success';

const classes = {
  container: cva(
    [
      'transition-all duration-300',

      // Hide the caret in the native input field
      '[&_input]:caret-transparent',
      // Hide selection of the native input field
      '[&_input::selection]:bg-transparent',
    ],
    {
      variants: {
        focused: {
          // true: 'bg-fuchsia-500',
          // false: 'bg-lime-500',
        },
        state: {
          input: 'text-brand-850',
          loading: 'text-brand-850 opacity-50',
          error: 'text-red-400',
          success: 'text-green-500',
        },
      },
    },
  ),
  segment: cva(
    [
      'transition-all duration-300',
      'appearance-none',
      'bg-white',
      'rounded-lg',
      'outline-none',
      'border-none',
      'ring-1 ring-brand-75',
      'flex flex-col',
    ],
    {
      variants: {
        state: {
          cursor: '',
          selected: '',
        },
        focused: {
          true: '',
          false: '',
        },
        readOnly: {
          true: '',
          false: '',
        },
      },
      compoundVariants: [
        {
          state: 'cursor',
          readOnly: false,
          focused: true,
          className: [
            'ring-2',
            'ring-brand-500',
            '[&_div]:w-[2px]',
            '[&_div]:flex-1',
            '[&_div]:my-[30%]',
            '[&_div]:self-center',
            '[&_div]:bg-brand-850',
            '[&_div]:animate-caret-blink',
          ],
        },
        {
          state: 'selected',
          readOnly: false,
          focused: true,
          className: 'ring-2 ring-brand-500',
        },
      ],
    },
  ),
};

interface CodeInputProps {
  name: string;
  form: ReturnType<typeof useFormik> | FormikProps<any>;
  length: number;
  submitOnFill?: boolean;
}

const PADDING = '16px';

const CodeInput: FunctionComponent<CodeInputProps> = ({
  name,
  form,
  length,
  submitOnFill,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const focused = useIsFocused(inputRef);

  const width = getSegmentCssWidth(PADDING);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async ({currentTarget: input}) => {
      /**
       * Only accept numbers.
       */
      input.value = input.value.replace(/\D+/g, '');

      /**
       * Set the value for the field in the form.
       */
      await form.setFieldValue(name, input.value);

      /**
       * If submitOnFill is enabled, submit the form the code reaches
       * the expected length.
       */
      if (submitOnFill && input.value.length === length) {
        form.submitForm();
      }
    },
    [form, length, name, submitOnFill],
  );

  /**
   * Determine the field state based on the form state.
   */
  const state = useMemo<CodeState>(() => {
    if (form.isSubmitting) {
      /**
       * Unfocus the field, since the error message gets reset
       * when there is a error and the field becomes focused.
       */
      inputRef.current.blur();
      inputRef.current.blur();
      return 'loading';
    } else if (form.errors[name]) {
      return 'error';
    } else if (form.status === 'success') {
      return 'success';
    }
    return 'input';
  }, [form.isSubmitting, form.status, name, form.errors]);

  const resetField = useCallback(() => {
    /**
     * Because of a bug in the RCI component that prevents the iOS keyboard
     * from opening upon refocus, we need to blur the field to reset the focus,
     * perform the reset actions on the field, and then focus it after a short delay.
     */
    inputRef.current.blur();
    inputRef.current.value = '';
    inputRef.current.dispatchEvent(new Event('input'));
    form.setFieldError(name, undefined);
    setTimeout(() => {
      inputRef.current.focus();
    }, 100); // <-- iOS keyboard will not reshow on focus if this is removed
  }, [form, name]);

  /**
   * Reset the field when focusing after an error has been presented.
   */
  useEffect(() => {
    if (focused && state === 'error') {
      resetField();
    }
  }, [focused, state, resetField]);

  const readOnly = useMemo(() => state !== 'input', [state]);

  return (
    <div className="flex flex-col gap-y-2 items-center">
      <RCICodeInput
        className={classes.container({state})}
        autoFocus={false}
        length={length}
        readOnly={readOnly}
        disabled={state === 'loading'}
        inputRef={inputRef}
        padding={PADDING}
        spacing={PADDING}
        spellCheck={false}
        inputMode="numeric"
        pattern="[0-9]*"
        // autoComplete="one-time-code"
        onChange={onChange}
        renderSegment={(segment) => (
          <div
            key={segment.index}
            className={classes.segment({
              focused,
              state: segment.state,
              readOnly,
            })}
            data-state={segment.state}
            style={{width, height: '100%'}}>
            <div />
          </div>
        )}
      />
      <InlineError error={form.errors[name]} />
    </div>
  );
};

export default CodeInput;
