import {Model, Attr} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';

@Model()
class Subscription extends ApplicationRecord {
  static jsonapiType = 'subscriptions';

  @Attr({persist: false}) name: string;

  @Attr({persist: false}) amount: number;
  @Attr({persist: false}) creditCardAmount: number;
}

export default Subscription;
