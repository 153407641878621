import {Router} from 'framework7/types';

import CancelPrivateViewingPage from 'pages/renter/viewings/CancelPrivateViewingPage';
import ConfirmPrivateViewingPage from 'pages/renter/viewings/ConfirmPrivateViewingPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/private-viewings',
    async: resolveRoute({
      conditions: [NEVER],
    }),
    routes: [
      {
        path: ':publicAccessToken',
        async: resolveRoute({conditions: [NEVER]}),
        routes: [
          {
            path: 'confirm',
            id: 'ConfirmPrivateViewing',
            async: resolveRoute({component: ConfirmPrivateViewingPage}),
          },
          {
            path: 'cancel',
            id: 'CancelPrivateViewing',
            async: resolveRoute({component: CancelPrivateViewingPage}),
          },
        ],
      },
    ],
  },
];

export default routes;
