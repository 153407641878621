import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {omit} from 'lodash';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';

import {UserData} from 'auth/types/UserData';
import usePinPayments, {UsePinPaymentsReturn} from 'hooks/usePinPayments';
import BillingMethod from 'models/billing/BillingMethod';
import {AccountRole} from 'models/users/User';
import {LANDLORD_PROPERTIES_BILLING_QUERY_KEY} from 'pages/landlord/billing/PropertiesBillingSection/PropertiesBillingSection';
import PropertyDetailQuery from 'queries/landlord/PropertyDetailQuery';
import {saveResource} from 'utilities/SpraypaintHelpers';

const QUERY_KEY = 'fetch_credit_cards';

/**
 * The object shape required by Pin Payments for the
 * billing method details (must be snake case).
 */
export interface CardDetails {
  nickname?: string;
  name: string;
  number: string;
  expiry_month: string;
  expiry_year: string;
  cvc: string;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_postcode: string;
  address_country: string;
}

interface CreditCardsProviderProps {
  children: ReactNode;
}

interface InitialiseFunction {
  (args: {currentUser: UserData}): Promise<void>;
}

interface ResetFunction {
  (): void;
}

interface CreatePinPaymentsCardHandler {
  (cardDetails: CardDetails): Promise<any>; // TODO; Type the return from Pin Payments API
}

interface AddCreditCardHandler {
  (cardDetails: CardDetails): Promise<BillingMethod>;
}

interface RemoveCreditCardHandler {
  (creditCard: BillingMethod): Promise<void>;
}

interface RenameCreditCardHandler {
  (creditCard: BillingMethod, nickname: string): Promise<BillingMethod>;
}

interface SetDefaultCreditCardHandler {
  (creditCard: BillingMethod): Promise<BillingMethod>;
}

interface CreditCardsContextValue {
  initialise: InitialiseFunction;
  reset: ResetFunction;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  pinPayments: UsePinPaymentsReturn;
  add: AddCreditCardHandler;
  remove: RemoveCreditCardHandler;
  rename: RenameCreditCardHandler;
  setDefault: SetDefaultCreditCardHandler;
  exist: boolean;
  default: BillingMethod | null;
  all: BillingMethod[] | null;
}

const Context = createContext<CreditCardsContextValue>(
  {} as CreditCardsContextValue,
);

const CreditCardsProvider: FunctionComponent<CreditCardsProviderProps> = ({
  children,
}) => {
  /**
   * Load the Pin Payments API.
   */
  const pinPayments = usePinPayments();

  const [shouldLoadCreditCards, setShouldLoadCreditCards] =
    useState<boolean>(false);

  const creditCardsQuery = useQuery(
    QUERY_KEY,
    async () =>
      (
        await BillingMethod.select([
          'created_at',
          'billable_type',
          'billable_id',
          'provider',
          'token',
          'nickname',
          'metadata',
          'is_primary',
        ])
          .stats({total: 'count'})
          .all()
      ).data,
    {
      enabled: shouldLoadCreditCards,
    },
  );

  const {
    data: unsortedCreditCards,
    isLoading,
    isSuccess,
    isError,
  } = creditCardsQuery;

  const initialise = useCallback<InitialiseFunction>(async ({currentUser}) => {
    if (currentUser.roles.includes(AccountRole.Landlord)) {
      setShouldLoadCreditCards(true);
    } else {
      setShouldLoadCreditCards(false);
    }
  }, []);

  const reset = useCallback<ResetFunction>(() => {
    setShouldLoadCreditCards(false);
    creditCardsQuery.remove();
  }, [creditCardsQuery]);

  /**
   * We sort the credit cards outside of the query itself so that if the query
   * cache gets updated, we can ensure that the credit cards provided by the
   * context will still be sorted.
   * Cards are sorted by earliest creation date, with the default card taking
   * precedence and will always be first.
   */
  const sortedCreditCards = useMemo<BillingMethod[]>(() => {
    /**
     * Return an empty array is the credit cards have not yet been fetched
     * or failed to be fetched.
     */
    if (!isSuccess) {
      return [];
    }

    /**
     * If there is only one card, or no cards, then we can return since
     * sorting is not required.
     */
    if (unsortedCreditCards.length <= 1) {
      return unsortedCreditCards;
    }

    /**
     * Sort the credit cards by creation date, with the earliest created
     * cards first.
     */
    const sortedByCreationDate = unsortedCreditCards.sort((a, b) =>
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1,
    );

    /**
     * Find the index in the sorted array of the default card.
     */
    const defaultCardIndex = sortedByCreationDate.findIndex(
      ({isPrimary}) => isPrimary,
    );

    /**
     * There should always be a default/primary card if cards exist, but for
     * safety we check that there is one. If there isn't we return the sorted
     * array without any changes. If the default card is already first in the
     * sorted array, then we also do not need to perform any changes.
     */
    if (defaultCardIndex <= 0) {
      return sortedByCreationDate;
    }

    /**
     * Get a reference to the default card before we splice the array.
     */
    const defaultCard = sortedByCreationDate[defaultCardIndex];

    /**
     * Remove the default card from the array.
     */
    sortedByCreationDate.splice(defaultCardIndex, 1);

    /**
     * Add the default card back to the array at the start.
     */
    sortedByCreationDate.unshift(defaultCard);

    /**
     * Return the sorted array with the default item at the start.
     */
    return sortedByCreationDate;
  }, [isSuccess, unsortedCreditCards]);

  /**
   * Access the query client.
   */
  const queryClient = useQueryClient();

  /**
   * Any queries that depend on instances of BillingMethod should be
   * invalidated here.
   */
  const invalidateDependentQueries = useCallback(() => {
    /**
     * Refetch the properties in the billing details page.
     */
    queryClient.invalidateQueries(LANDLORD_PROPERTIES_BILLING_QUERY_KEY);
    queryClient.removeQueries('property');
  }, [queryClient]);

  /**
   * Attempts to create a card token with Pin Payments for the provided card details.
   */
  const createPinPaymentsCard = useCallback<CreatePinPaymentsCardHandler>(
    async (cardDetails) => {
      try {
        /**
         * Ensure the Pin Payments API has loaded.
         */
        if (!pinPayments.isReady) {
          throw new Error('Pin Payments has not been initialised.');
        }

        /**
         * Create the card token with Pin Payments.
         */
        return await pinPayments.api.createCardToken(
          omit(cardDetails, 'nickname'),
        );
      } catch (error) {
        /**
         * Log the error in Sentry.
         */
        Sentry.withScope((scope) => {
          scope.setTag('action', 'pin_payments_create_card_token');
          Sentry.captureException(error);
        });

        /**
         * Throw the error to be handled by the caller.
         */
        throw error;
      }
    },
    [pinPayments],
  );

  /**
   * Adds a credit card to the user's account and updates the query cache
   * with the new card if successful.
   */
  const handleAddCreditCard = useCallback<AddCreditCardHandler>(
    async (cardDetails) => {
      try {
        /**
         * Attempt to create a card token with Pin Payments for the provided card details.
         */
        const {token, ...metadata} = await createPinPaymentsCard(cardDetails);

        /**
         * Construct the billing method instance for the credit card.
         */
        const creditCard = new BillingMethod();
        creditCard.nickname = cardDetails.nickname ?? null;
        creditCard.provider = 'pin_payments';
        creditCard.token = token;
        creditCard.metadata = metadata;

        /**
         * Attempt to save the credit card.
         */
        if (
          !(await saveResource(creditCard, {
            showErrorToast: false,
          }))
        ) {
          /**
           * Create an error if the card was not saved.
           */
          const error = new Error(
            `Error adding credit card saved to Pin Payments to the user's account.`,
          );

          /**
           * Log the error in Sentry.
           */
          Sentry.withScope((scope) => {
            scope.setTag('action', 'set_pin_payments_card_token_on_user');
            Sentry.captureException(error);
          });

          /**
           * Throw the error.
           */
          throw error;
        }

        /**
         * Add the new credit card to the query cache.
         */
        queryClient.setQueryData(
          QUERY_KEY,
          (existingCreditCards: BillingMethod[]) => [
            ...existingCreditCards,
            creditCard,
          ],
        );

        /**
         * Invalidate queries that depend on BillingMethod instances so that the updates
         * to the credit cards are reflected.
         */
        invalidateDependentQueries();

        /**
         * Notify the user that the card was added successfully.
         */
        toast.success('Your card has been securely saved!');

        return creditCard;
      } catch (error) {
        /**
         * Notify the user that there was an error adding the card.
         */
        toast.error('Sorry, there was an issue saving your card.');

        /**
         * Throw the error to be handled by the caller.
         */
        throw error;
      }
    },
    [createPinPaymentsCard, queryClient, invalidateDependentQueries],
  );

  /**
   * Removes a credit card from the user's account and updates the query cache
   * to omit the removed card if successful.
   */
  const handleRemoveCreditCard = useCallback<RemoveCreditCardHandler>(
    async (creditCard) => {
      try {
        /**
         * Determine whether the card we are removing is currently the default card.
         */
        const isRemovingDefaultCard = !!creditCard.isPrimary;

        /**
         * Attempt to destroy the billing method instance for the credit card.
         */
        await creditCard.destroy();

        /**
         * Remove the credit card from the query cache.
         */
        queryClient.setQueryData(
          QUERY_KEY,
          (existingCreditCards: BillingMethod[]) => {
            let newCreditCards = existingCreditCards
              /**
               * Remove the destroyed card from the query cache.
               */
              .filter(({id}) => id !== creditCard.id);

            /**
             * If the credit card that is being removed is the default/primary card, and the
             * user has other cards added to their account, then the backend will change the
             * default card to be earliest created remaining card which we need to update the
             * query cache to reflect. If no other cards exist then there will be no
             * default card after removal.
             */
            if (isRemovingDefaultCard && newCreditCards.length > 0) {
              /**
               * If there are two or more other cards, we need to sort them by creation
               * date (earliest created first) to find the card that the backend will
               * use as the new default card.
               */
              if (newCreditCards.length > 1) {
                newCreditCards = newCreditCards.sort((a, b) =>
                  dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? 1 : -1,
                );
              }

              /**
               * Get the earliest created credit card from the filtered array.
               */
              const earliestCreatedCard = newCreditCards[0];

              /**
               * Set the earliest created card as the default card.
               */
              earliestCreatedCard.isPrimary = true;
              earliestCreatedCard.isPersisted = true;
            }

            return newCreditCards;
          },
        );

        /**
         * Invalidate queries that depend on BillingMethod instances so that the updates
         * to the credit cards are reflected.
         */
        invalidateDependentQueries();

        /**
         * Notify the user that the credit card has been removed.
         */
        toast.success('Card successfully removed!');
      } catch (error) {
        /**
         * Log the error in Sentry.
         */
        Sentry.withScope((scope) => {
          scope.setTag('action', 'remove_credit_card');
          Sentry.captureException(error);
        });

        /**
         * Notify the user of the error.
         */
        toast.error('There was an issue removing your card.');

        /**
         * Throw the error to prevent the modal from automatically closing.
         */
        throw error;
      }
    },
    [queryClient, invalidateDependentQueries],
  );

  /**
   * Updates the nickname for a credit card on the user's account and updates the
   * query cache to reflect the new name if successful.
   */
  const handleRenameCreditCard = useCallback<RenameCreditCardHandler>(
    async (creditCard, nickname) => {
      try {
        /**
         * Set and save the new nickname on the credit card.
         */
        creditCard.assignAttributes({
          nickname,
        });

        /**
         * Attempt to save the credit card.
         */
        if (
          !(await saveResource(creditCard, {
            showErrorToast: false,
          }))
        ) {
          /**
           * Roll back the changes.
           */
          creditCard.rollback();

          /**
           * Create an error if the card was not saved.
           */
          const error = new Error(
            `Error updating credit card with new nickname.`,
          );

          /**
           * Log the error in Sentry.
           */
          Sentry.withScope((scope) => {
            scope.setTag('action', 'rename_credit_card');
            Sentry.captureException(error);
          });

          /**
           * Throw the error.
           */
          throw error;
        }

        /**
         * Update the renamed credit card in the query cache.
         */
        queryClient.setQueryData(
          QUERY_KEY,
          (existingCreditCards: BillingMethod[]) => [
            /**
             * Exclude the previous version of the credit card instance.
             */
            ...existingCreditCards.filter(({id}) => id !== creditCard.id),
            /**
             * Add the updated credit card instance.
             */
            creditCard,
          ],
        );

        /**
         * Invalidate queries that depend on BillingMethod instances so that the updates
         * to the credit cards are reflected.
         */
        invalidateDependentQueries();

        /**
         * Notify the user that the card was renamed successfully.
         */
        toast.success('Your card has been renamed!');

        return creditCard;
      } catch (error) {
        /**
         * Notify the user that there was an error renaming the card.
         */
        toast.error('Sorry, there was an issue renaming your card.');

        /**
         * Throw the error to be handled by the caller.
         */
        throw error;
      }
    },
    [queryClient, invalidateDependentQueries],
  );

  /**
   * Sets a credit card on the user's account as the default card and if successful,
   * updates the query cache to reflect the new default card.
   */
  const handleSetDefaultCreditCard = useCallback<SetDefaultCreditCardHandler>(
    async (creditCard) => {
      try {
        /**
         * Set the card as the default/primary card.
         * We don't need to unset any other credit cards in the backend here
         * since the backend will automatically handle this.
         */
        creditCard.isPrimary = true;

        /**
         * Attempt to save the credit card.
         */
        if (
          !(await saveResource(creditCard, {
            showErrorToast: false,
          }))
        ) {
          /**
           * Roll back the changes.
           */
          creditCard.rollback();

          /**
           * Create an error if the card was not saved.
           */
          const error = new Error(`Error updating default credit card.`);

          /**
           * Log the error in Sentry.
           */
          Sentry.withScope((scope) => {
            scope.setTag('action', 'set_default_credit_card');
            Sentry.captureException(error);
          });

          /**
           * Throw the error.
           */
          throw error;
        }

        /**
         * Mark the new default card as default and un-mark any previous default card in
         * the query cache if successful.
         */
        queryClient.setQueryData(
          QUERY_KEY,
          (existingCreditCards: BillingMethod[]) => [
            ...existingCreditCards
              /**
               * Un-mark any previous default card.
               */
              .map((existingCreditCard) => {
                /**
                 * We need to create a cloned instance of each card because React query
                 * won't trigger a state update otherwise.
                 */
                const clonedCard = existingCreditCard.dup();
                if (clonedCard.isPrimary && clonedCard.id !== creditCard.id) {
                  clonedCard.isPrimary = false;
                  clonedCard.isPersisted = true;
                }
                return clonedCard;
              }),
          ],
        );

        /**
         * Notify the user that the default card was updated successfully.
         */
        toast.success('Your default card has been updated!');

        return creditCard;
      } catch (error) {
        /**
         * Notify the user that there was an error updating the default the card.
         */
        toast.error('Sorry, there was an issue updating your default card.');

        /**
         * Throw the error to be handled by the caller.
         */
        throw error;
      }
    },
    [queryClient],
  );

  /**
   * Find the user's default credit/debit card.
   */
  const defaultCard = useMemo<BillingMethod | null>(() => {
    return isSuccess
      ? sortedCreditCards.find(({isPrimary}) => isPrimary)
      : null;
  }, [isSuccess, sortedCreditCards]);

  /**
   * Determine whether the user has added any credit/debit cards to their account.
   */
  const hasAddedCreditCards = useMemo<boolean>(
    () => isSuccess && sortedCreditCards.length > 0,
    [isSuccess, sortedCreditCards],
  );

  const value = useMemo<CreditCardsContextValue>(
    () => ({
      initialise,
      reset,
      isLoading,
      isError,
      isSuccess,
      pinPayments: pinPayments,
      add: handleAddCreditCard,
      remove: handleRemoveCreditCard,
      rename: handleRenameCreditCard,
      setDefault: handleSetDefaultCreditCard,
      exist: hasAddedCreditCards,
      ...(isSuccess
        ? {
            default: defaultCard,
            all: sortedCreditCards,
          }
        : {
            default: null,
            all: null,
          }),
    }),
    [
      initialise,
      reset,
      isLoading,
      isError,
      isSuccess,
      pinPayments,
      handleAddCreditCard,
      handleRemoveCreditCard,
      handleRenameCreditCard,
      handleSetDefaultCreditCard,
      hasAddedCreditCards,
      defaultCard,
      sortedCreditCards,
    ],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCreditCards = () => useContext(Context);

export default CreditCardsProvider;
