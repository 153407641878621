import {FunctionComponent} from 'react';

import {type IconType} from 'react-icons/lib';

interface InlineStatItemProps {
  icon: IconType;
  quantity?: number;
}

const InlineStatItem: FunctionComponent<InlineStatItemProps> = ({
  icon: Icon,
  quantity,
}) => (
  <div className="flex flex-row items-center gap-x-1 opacity-70">
    <Icon className="w-6 h-6 text-brand-500 " />
    <span className="font-medium text-sm text-brand-850">
      {quantity === null ? 'N/A' : quantity}
    </span>
  </div>
);

export default InlineStatItem;
