import {FunctionComponent} from 'react';

import {HiOutlineInformationCircle} from 'react-icons/hi';

import {Card} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph, Title} from 'components_sb/typography';

const AboutBillingSection: FunctionComponent = () => (
  <Card
    limitInitialHeight
    icon={HiOutlineInformationCircle}
    title="Understanding billing">
    <div className="flex flex-col gap-y-4">
      <Paragraph>
        Keyhook's flexible billing model allows you to pay subscription fees
        separately for each property using one of two methods below.
      </Paragraph>
      <Paragraph>
        You will only be charged subscription fees after any trial or bonus
        period has ended.
      </Paragraph>
      <div className="flex flex-col">
        <Title level="h3" size="xs">
          Keyhook Smart Rent
        </Title>
        <Paragraph>
          Your tenants pay rent into Keyhook's rent account. Subscription fees
          are deducted, and the remaining balance is immediately forwarded to
          you.
        </Paragraph>
        <Paragraph>
          Subscription fees will only be deducted when you have an active
          tenancy for your property.
        </Paragraph>
      </div>
      <div className="flex flex-col">
        <Title level="h3" size="xs">
          Credit/debit card
        </Title>
        <Paragraph>
          If you choose to receive your rent payments directly, you are required
          to add a credit/debit card for payment of Keyhook's subscription fees.
        </Paragraph>
        <Paragraph>
          Subscription fees will be deducted from your card weekly until you
          cancel your subscription, regardless of whether your tenancy is
          currently active.
        </Paragraph>
        <Paragraph>
          This payment method incurs a $1 surcharge per transaction in order to
          cover third-party processing fees and related costs.
        </Paragraph>
        <Paragraph>
          Keyhook uses Pin Payments to process payments in order to ensure your
          information is secure. You can read more about Pin Payments' security
          policy&nbsp;
          <Hyperlink
            external
            openInNewTab
            href="https://pinpayments.com/security">
            here.
          </Hyperlink>
        </Paragraph>
      </div>
      <Paragraph secondary>
        To switch between Keyhook Smart Rent and card payments for a property,
        please&nbsp;
        <Hyperlink external openInNewTab href="https://keyhook.com/contact/">
          get in touch
        </Hyperlink>
        &nbsp;with our support team.
      </Paragraph>
    </div>
  </Card>
);
export default AboutBillingSection;
