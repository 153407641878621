import {useQuery} from 'react-query';

import emptyBg from 'assets/img/empty_dataset_images/rental_applications.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import ListingCard from 'components/listing/ListingCard/ListingCard';
import ListingRentalApplication from 'models/listings/ListingRentalApplication';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

const RentalApplicationListingsPage = () => {
  const {
    params: {rentalApplicationId},
  } = useRoute();

  const {
    data: listingsAppliedFor,
    isLoading,
    error,
  } = useQuery(
    `rental-application-${rentalApplicationId}-listings`,
    async () =>
      (
        await ListingRentalApplication.where({
          rental_application_id: rentalApplicationId,
        })
          .includes({listing: 'listing_photos'})
          .all()
      ).data,
  );

  return (
    <Page title="Listings Applied For" loading={isLoading} error={error}>
      {() =>
        listingsAppliedFor.length > 0 ? (
          <div className="grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2">
            {listingsAppliedFor.map(({listing}) => (
              <ListingCard key={listing.id} listing={listing} />
            ))}
          </div>
        ) : (
          <EmptyDataSet
            title="No Listings"
            message="You haven't applied for any listings with this application yet."
            image={emptyBg}
          />
        )
      }
    </Page>
  );
};

export default RentalApplicationListingsPage;
