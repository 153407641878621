import {useCallback, useMemo} from 'react';

import {HiOutlineSearch} from 'react-icons/hi';

import InspectionsTable from 'components/inspection/InspectionsTable';
import {Card} from 'components_sb/layout';
import EmptyContentPlaceholder from 'components_sb/layout/EmptyContentPlaceholder/EmptyContentPlaceholder';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {AccountRole} from 'models/users/User';
import useSubscriptionGuard from 'providers/Subscriptions/hooks/useSubscriptionGuard';
import useRouter from 'router/hooks/useRouter';
import {Action} from 'types/actions';

const InspectionsCard = ({
  property,
  tenancy,
}: {
  property?: Property;
  tenancy?: Tenancy;
}) => {
  const router = useRouter();

  const showInspection = useCallback(
    (inspectionId: string) => {
      router.navigate(`inspections/${inspectionId}`);
    },
    [router],
  );

  const {activeAccountRole} = useLocalUserSettings();

  const isLandlord = useMemo(
    () => activeAccountRole === AccountRole.Landlord,
    [activeAccountRole],
  );

  /**
   * Allow both migrated and new tenancies to create
   * inspections where there are none existing.
   */
  const showNewButton = useMemo(() => {
    if (isLandlord && tenancy && tenancy.inspections.length === 0) {
      return true;
    }
    return false;
  }, [isLandlord, tenancy]);

  const {requireSubscription} = useSubscriptionGuard({
    property,
  });

  const onNewInspection = useCallback(
    () => requireSubscription(() => router.navigate('inspections/new')),
    [requireSubscription, router],
  );

  /**
   * Actions that are applicable to and accessible
   * from within the card.
   */
  const cardActions = useMemo<Action[]>(
    () =>
      !showNewButton
        ? null
        : [
            {
              label: 'New inspection',
              onClick: onNewInspection,
            },
          ],
    [showNewButton, onNewInspection],
  );

  return (
    <Card
      id="inspections"
      icon={HiOutlineSearch}
      title="Inspections"
      actions={cardActions}>
      {tenancy && tenancy.inspections.length > 0 ? (
        <InspectionsTable
          inspections={tenancy.inspections}
          clickHandler={showInspection}
          showCount={true}
        />
      ) : (
        <EmptyContentPlaceholder
          type="inspections"
          message="There are currently no scheduled inspections for this property"
        />
      )}
    </Card>
  );
};

export default InspectionsCard;
