import {useCallback, useMemo} from 'react';

import {useLocalStorage} from 'usehooks-ts';

export interface AuthCookies {
  userId: string;
  userEmail: string;
  token: string;
}

interface SetAuthCookiesFunction {
  (authCookies: AuthCookies): void;
}

interface ClearAuthCookiesFunction {
  (): void;
}

interface UseAuthCookiesIOSHook {
  (): {
    authCookiesPresent: boolean;
    authCookies: AuthCookies;
    setAuthCookies: SetAuthCookiesFunction;
    clearAuthCookies: ClearAuthCookiesFunction;
  };
}

/**
 * Temporary drop-in hook to use local storage instead of cookies to resolve issues
 * with cookies not working in the production build of the iOS app.
 */
const useAuthCookiesIOS: UseAuthCookiesIOSHook = () => {
  /**
   * Configure read and write functionality for the auth related cookies.
   */
  const [userId, setUserId] = useLocalStorage<string>(
    'auth:user_id',
    undefined,
  );
  const [userEmail, setUserEmail] = useLocalStorage<string>(
    'auth:user_email',
    undefined,
  );
  const [token, setToken] = useLocalStorage<string>('auth:token', undefined);

  /**
   * Determine whether the user has all of the cookies requires for auth.
   */
  const authCookiesPresent = useMemo<boolean>(
    () =>
      userId &&
      userId !== undefined &&
      userEmail &&
      userEmail !== undefined &&
      token &&
      token !== undefined,
    [userId, userEmail, token],
  );

  /**
   * Sets all required cookies for auth.
   */
  const setAuthCookies = useCallback<SetAuthCookiesFunction>(
    ({userId, userEmail, token}) => {
      setUserId(userId);
      setUserEmail(userEmail);
      setToken(token);
    },
    [setUserId, setUserEmail, setToken],
  );

  /**
   * Clear all cookies required for auth.
   */
  const clearAuthCookies = useCallback(() => {
    setUserId(undefined);
    setUserEmail(undefined);
    setToken(undefined);
  }, [setUserId, setUserEmail, setToken]);

  return {
    authCookiesPresent,
    authCookies: {
      userId,
      userEmail,
      token,
    },
    setAuthCookies,
    clearAuthCookies,
  };
};

export default useAuthCookiesIOS;
