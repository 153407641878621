import {useCallback, useEffect, useMemo, useState} from 'react';

import {SortAscendingIcon, SortDescendingIcon} from '@heroicons/react/outline';
import {
  HiOutlineCog,
  HiOutlineCurrencyDollar,
  HiOutlinePlus,
} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';

import ConfirmEmailAlert from 'components/auth/ConfirmEmailAlert';
import DemoPropertyCard from 'components/demo/DemoPropertyCard';
import LandlordWelcomeModal from 'components/onboarding/LandlordWelcomeModal';
import PromoCodeEntryBanner from 'components/PromoCodeEntry/PromoCodeEntryBanner';
import AutocompletePropertyAddressModal from 'components/property/landlord/AutocompletePropertyAddressModal';
import LandlordPropertyIndexCard from 'components/property/landlord/PropertyIndexCard';
import {Pagination} from 'components_sb/input';
import {Modal, Stats} from 'components_sb/layout';
import ReferAFriendAlert from 'components_sb/miscellaneous/ReferAFriendAlert';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import useScroll from 'hooks/useScroll';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import {usePushPermissions} from 'providers/PushPermissions/_PushPermissionsProvider';
import useSubscriptions from 'providers/Subscriptions/hooks/useSubscriptions';
import {useTour} from 'providers/ToursProvider';
import {Page} from 'router/components';
import useGlobalUIStore from 'stores/GlobalUIStore';
import LandlordWelcome from 'tours/LandlordWelcome';
import {Action} from 'types/actions';
import {toCurrency} from 'utilities/StringHelpers';

import SubscriptionStatusAlert from '../subscription/SubscriptionStatusAlert/SubscriptionStatusAlert';
import AddPropertyCard from './AddPropertyCard';

const {useModal} = Modal.Imperative;

const PropertyIndexPage = () => {
  const {subscriptionStatus, hasPropertiesRequiringBillingAction} =
    useSubscriptions();

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);

  const onAddressModalClose = useCallback(() => {
    setAddressModalIsOpen(false);
  }, []);

  const isMobile = !useTailwindBreakpoint('sm');

  const [propertyIndexSettings, setPropertyIndexSettings] = useGlobalUIStore(
    (state) => [state.propertyIndexSettings, state.setPropertyIndexSettings],
  );

  const {isLoading, error, data} = useQuery(
    'landlord-properties-index',
    async () => {
      const res = await Property.select({
        properties: [
          'street_address',
          'suburb',
          'city',
          'main_image',
          'tenancies_count',
          'no_tenancy_action_type',
          'last_onboarding_step_completed',
          'billing_method',
        ],
        listings: [
          'id',
          'public_id',
          'property_id',
          'status',
          'created_at',
          'view_count',
          'open_homes_count',
          'listing_rental_applications_count',
          'enquiry_threads_count',
          'private_viewings_count',
        ],
      })
        .includes([
          {
            currentTenancy: [
              'active_tenancy_memberships',
              'open_service_requests',
            ],
            listings: ['external_listing_ads', 'properties'],
          },
        ])
        .stats({total: 'count'})
        .page(propertyIndexSettings.page)
        .per(propertyIndexSettings.perPage)
        .order({
          [propertyIndexSettings.sortField]:
            propertyIndexSettings.sortDirection,
        } as any)
        .all();

      return res;
    },
    {retry: 1},
  );

  const {isLoading: statsIsLoading, data: statData} = useQuery(
    'landlord-property-stats-index',
    async () => {
      const tenancyStats = await Tenancy.where({
        status: ['active', 'active_periodic', 'awaiting_start_date'],
      })
        .select(['id'])
        .stats({
          total: 'count',
          total_rent: 'sum',
          next_inspection_date: 'minimum',
          end_date: 'minimum',
          open_service_requests: 'count',
        })
        .all();

      if (tenancyStats.meta.stats) {
        return {
          stats: {
            total_rent: tenancyStats.meta.stats.total_rent.sum,
            total_tenancies: tenancyStats.meta.stats.total.count,
            end_date_min: tenancyStats.meta.stats.end_date.minimum,
            next_inspection_date_min:
              tenancyStats.meta.stats.next_inspection_date.minimum,
            open_service_requests:
              tenancyStats.meta.stats.open_service_requests.count,
          },
        };
      } else {
        return {
          stats: {
            total_rent: null,
            total_tenancies: null,
            end_date_min: null,
            next_inspection_date_min: null,
            open_service_requests: null,
          },
        };
      }
    },
  );
  const queryClient = useQueryClient();

  const changeSettings = (key: string, value: any) => {
    const newSettings = propertyIndexSettings;
    if (key === 'sortDirection') {
      value = propertyIndexSettings.sortDirection === 'asc' ? 'desc' : 'asc';
    }
    if (key === 'viewType') {
      value = propertyIndexSettings.viewType === 'list' ? 'grid' : 'list';
    }

    // @ts-ignore
    newSettings[key] = value;
    setPropertyIndexSettings(newSettings);
    queryClient.invalidateQueries('landlord-properties-index');
  };

  let totalProperties = 0;
  let fromPagination = 0;
  let toPagination = 0;
  if (data && data.meta) {
    totalProperties = data.meta.stats.total.count;
    fromPagination = propertyIndexSettings.page * propertyIndexSettings.perPage;
    toPagination = fromPagination + data.data.length;
  }

  // ========================================================================
  // Welcome Tour
  // ========================================================================
  const openModal = useModal();
  const landlordDashboardTour = useTour(LandlordWelcome);
  const [showingWelcomeModal, setShowingWelcomeModal] = useState(false);
  const pushPermissions = usePushPermissions();

  const showWelcomeModal = useCallback(async () => {
    const startTour = await openModal(LandlordWelcomeModal);
    setShowingWelcomeModal(false);
    if (startTour) {
      landlordDashboardTour.start();
    } else {
      landlordDashboardTour.skip();
    }
  }, [setShowingWelcomeModal, landlordDashboardTour, openModal]);

  /**
   * Present the welcome modal to the user automatically once the page content
   * has loaded if they have not added a property and have not yet been presented
   * the tour before.
   */
  const hideDemoPropertyFlag = useCurrentUserFlag('hide_demo_property');
  /**
   * TODO: Re-enable the tour modal once the bugs when transitioning pages during the route
   * have been resolved.
   */
  // useEffect(() => {
  //   if (
  //     // Don't show in the testing environment
  //     process.env.TARGET_ENV !== 'test' &&
  //     // Wait for the page content to load
  //     !isLoading &&
  //     !error &&
  //     // Don't re-show if already showing
  //     !showingWelcomeModal &&
  //     // Wait for permissions to be either denied or accepted before showing tour
  //     !!pushPermissions.hasResponded &&
  //     // Wait for the tour flag to be checked
  //     landlordDashboardTour.isReady &&
  //     !landlordDashboardTour.hasBeenPresented &&
  //     // Wait for the demo property flag to be checked
  //     hideDemoPropertyFlag.isReady &&
  //     !hideDemoPropertyFlag.value
  //   ) {
  //     setShowingWelcomeModal(true);
  //     setTimeout(() => showWelcomeModal(), 1000);
  //   }
  // }, [
  //   pushPermissions,
  //   isLoading,
  //   error,
  //   showingWelcomeModal,
  //   showWelcomeModal,
  //   landlordDashboardTour,
  //   hideDemoPropertyFlag,
  //   openModal,
  // ]);
  // ========================================================================

  const Header = () => {
    let nextInspectionDate: string;
    let nextInspectionSubtitle: string;
    let nextLeaseExpires: string;

    if (statData && statData.stats) {
      if (statData.stats.next_inspection_date_min) {
        const today = new Date();
        const inspectionDate = new Date(
          statData.stats.next_inspection_date_min,
        );

        const timeDifference = inspectionDate.getTime() - today.getTime();
        if (timeDifference < 0) {
          nextInspectionDate = 'Overdue';
          nextInspectionSubtitle = undefined;
        } else {
          nextInspectionDate = Number(
            timeDifference / (1000 * 3600 * 24),
          ).toFixed(0);
          nextInspectionSubtitle = 'Days';
        }
      }

      if (statData.stats.end_date_min) {
        const today = new Date();
        const leaseDate = new Date(statData.stats.end_date_min);

        const timeDifference = leaseDate.getTime() - today.getTime();
        nextLeaseExpires = Number(timeDifference / (1000 * 3600 * 24)).toFixed(
          0,
        );
      } else {
        nextLeaseExpires = undefined;
      }
    }

    return (
      <div>
        {!statsIsLoading && statData && totalProperties > 0 && (
          <Stats
            stats={[
              {
                icon: HiOutlineCurrencyDollar,
                title: 'Total rent',
                value: toCurrency(statData.stats.total_rent),
                description: 'Weekly',
                href: '/financials',
              },
              {
                icon: HiOutlineCog,
                title: 'Maintenance requests',
                value: statData.stats.open_service_requests,
                description: 'Needing action',
                // href: "" TODO: Navigate to a page that shows all maintenance requests across properties?
              },
              {
                icon: HiOutlineCog,
                title: 'Next inspection',
                value:
                  nextInspectionDate && nextInspectionDate.length > 0
                    ? nextInspectionDate
                    : undefined,
                description: nextInspectionSubtitle,
                // href: "" TODO: Navigate to a page that shows all inspections across properties?
              },
              {
                icon: HiOutlineCog,
                title: 'Next lease expires',
                value:
                  nextLeaseExpires && nextLeaseExpires.length > 0
                    ? nextLeaseExpires
                    : undefined,
                description:
                  nextLeaseExpires && nextLeaseExpires.length > 0
                    ? 'Days'
                    : undefined,
                // href: "" TODO: Navigate to a page that shows all status of all leases with upcoming expiry?
              },
            ]}
          />
        )}

        {totalProperties >= propertyIndexSettings.perPage && (
          <div className="card bg-brand-50 mb-8 !rounded-xl">
            <div className="card-body p-4">
              <div className="flex justify-between items-center">
                <div>
                  <select
                    id="itemsPerPage"
                    className="select select-bordered select-sm py-0 inline-block"
                    value={propertyIndexSettings.perPage}
                    onChange={({target}) => {
                      changeSettings('perPage', Number(target.value));
                      changeSettings('page', 1);
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>

                  <span className="hidden md:inline-block mb-0 text-nowrap ml-2 text-sm text-brand-850">
                    Showing {fromPagination}-{toPagination} of {totalProperties}{' '}
                    properties
                  </span>
                </div>

                <div>
                  <div className="form-control">
                    <div className="relative">
                      <select
                        id="PropertySortBy"
                        className="select select-sm select-bordered py-0 pr-16"
                        onChange={({target}) =>
                          changeSettings('sortField', target.value)
                        }
                        value={propertyIndexSettings.sortField}>
                        <option value="created_at">Date Added</option>
                        <option value="street_address">Address</option>
                        <option value="next_inspection_date">
                          Next Inspection
                        </option>
                        <option value="tenancy_total_rent">Rent</option>
                        <option value="lease_expires">Lease Expires</option>
                      </select>
                      <button
                        className="absolute top-0 right-0 rounded-l-none btn btn-neutral btn-sm cursor-pointer"
                        onClick={() => changeSettings('sortDirection', '')}>
                        {propertyIndexSettings.sortDirection === 'asc' ? (
                          <SortAscendingIcon className="text-white w-4 h-4" />
                        ) : (
                          <SortDescendingIcon className="text-white w-4 h-4" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="tw-block md:hidden mb-0 text-nowrap ml-2 inline-block text-sm">
                Showing {fromPagination}-{toPagination} of {totalProperties}{' '}
                Properties
              </h6>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Footer = () => {
    const {scrollToTop} = useScroll();
    if (data.meta.stats.total.count > propertyIndexSettings.perPage) {
      return (
        <Pagination
          page={propertyIndexSettings.page}
          totalPages={Math.ceil(
            data.meta.stats.total.count / propertyIndexSettings.perPage,
          )}
          onChange={(page) => {
            changeSettings('page', Number(page));
            scrollToTop();
          }}
        />
      );
    } else {
      return null;
    }
  };

  const showAddressModal = useCallback(() => setAddressModalIsOpen(true), []);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      landlordDashboardTour?.isActive
        ? null
        : [
            {
              label: 'Add property',
              icon: HiOutlinePlus,
              onClick: showAddressModal,
              testId: 'add-property-button',
            },
          ],
    [landlordDashboardTour.isActive, showAddressModal],
  );

  return (
    <Page
      title="Dashboard"
      disableBack
      actions={pageActions}
      loading={isLoading}
      error={error}>
      {() => (
        <>
          <PromoCodeEntryBanner />

          {/* Subscription status across all properties and any billing action required */}
          <SubscriptionStatusAlert
            billingActionRequired={hasPropertiesRequiringBillingAction}
          />

          <ConfirmEmailAlert />

          <ReferAFriendAlert />

          {!isMobile && <Header />}

          <div className="flex flex-col gap-y-8">
            {data.data.length === 0 && (
              <DemoPropertyCard tourIsActive={landlordDashboardTour.isActive} />
            )}

            {data.data.map((property, index) => (
              <LandlordPropertyIndexCard
                index={index}
                key={property.id}
                property={property}
                tenancy={property.currentTenancy}
              />
            ))}
            <AddPropertyCard showAddressModal={showAddressModal} />
          </div>

          <Footer />

          <AutocompletePropertyAddressModal
            isOpen={addressModalIsOpen}
            onClose={onAddressModalClose}
          />
        </>
      )}
    </Page>
  );
};

export default PropertyIndexPage;
