import {
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';
import {HiChevronDown} from 'react-icons/hi';
import {mergeRefs} from 'react-merge-refs';

import {Button, ResponsiveActionSelect} from 'components_sb/buttons';
import {Title} from 'components_sb/typography';
import Tags from 'components_sb/typography/Tags/Tags';
import {Action} from 'types/actions';

/**
 * The maximum height of the card when the limitInitialHeight prop has been set.
 */
const LIMIT_HEIGHT = 300;

/**
 * The duration of the animation when expanding the card to the full height.
 */
const EXPAND_TRANSITION_DURATION = 500; // Milliseconds

export interface CardBannerConfig {
  color: 'gray' | 'amber' | 'green' | 'red';
  text: string;
}

type CardProps = Omit<HTMLAttributes<HTMLElement>, 'title' | 'icon'> & {
  testId?: string;
  icon?: IconType; // TODO: Only permit icon if a title is provided
  title?: string | ReactNode; // TODO: Change components that pass a ReactNode so that only strings are permitted
  subtitle?: string;
  tags?: string[];
  actions?: Action[];
  style?: 'normal' | 'premium' | 'success';
  banner?: CardBannerConfig;
  cssQueryContainer?: boolean;
  limitInitialHeight?: boolean;
};

// TODO: Ignore the limitInitialHeight prop if the content height does not exceed LIMIT_HEIGHT after initial mount
const Card = forwardRef(
  (
    {
      testId = undefined,
      icon,
      title,
      subtitle,
      tags,
      actions,
      style = 'normal',
      children,
      className,
      banner,
      cssQueryContainer = false,
      limitInitialHeight = false,
      ...props
    }: CardProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    /**
     * Internal ref for accessing card height.
     */
    const cardRef = useRef<HTMLDivElement>();

    /**
     * Whether the full height of the card should be shown or not.
     * This will be true by default unless the limitInitialHeight prop has been set,
     * where it will then be expanded upon the user choosing to expand it.
     */
    const [expanded, setExpanded] = useState<boolean>(!limitInitialHeight);

    /**
     * The current maximum height of the card.
     */
    const [maxHeight, setMaxHeight] = useState<number | 'none'>(
      limitInitialHeight ? LIMIT_HEIGHT : 'none',
    );

    /**
     * Handle expanding the card to show the full height.
     */
    const onExpand = useCallback(() => {
      setExpanded(true);
      /**
       * We want to set the max height to the actual content height of the card while
       * the transition is performing, but then switch it to none (auto) after the transition
       * so that resizing does not cause the content to be clipped if the content height
       * changes later due to the resize.
       */
      setMaxHeight(cardRef.current.scrollHeight);
      setTimeout(() => {
        setMaxHeight('none');
      }, EXPAND_TRANSITION_DURATION);
    }, []);

    return (
      <div
        data-testid={testId}
        ref={mergeRefs([cardRef, ref])}
        className={clsx(
          cssQueryContainer && '@container',
          'transition-all',
          'overflow-y-hidden',
          'relative',
          'flex flex-col',
          'mb-6',
          'scroll-m-20',
          'bg-white',
          'rounded-xl',
          'text-brand-850',
          !!banner && 'pt-6',
          style === 'normal' && 'border-2 border-brand-50',
          style === 'premium' && 'border-3 border-amber-400',
          style === 'success' && 'border-3 border-green-500',
          className,
        )}
        style={{
          transitionDuration: `${EXPAND_TRANSITION_DURATION}ms`,
          maxHeight,
        }}
        {...props}>
        {/* Banner */}
        {!!banner && (
          <div
            className={clsx(
              'rounded-t-xl',
              'absolute top-0 left-0 right-0 h-6',
              'text-center',
              'py-1 px-4',
              'text-xs',
              'text-white',
              'font-medium',
              banner.color === 'gray' && 'bg-gray-400 ',
              banner.color === 'amber' && 'bg-amber-500',
              banner.color === 'green' && 'bg-green-500',
              banner.color === 'red' && 'bg-red-500',
            )}>
            {banner.text}
          </div>
        )}

        {/* Card body */}
        <div className="flex-1 flex flex-col p-4 md:p-6 gap-y-2">
          {/* Card header */}
          {(title || actions || tags) && (
            <div className="flex flex-row flex-1 justify-between gap-x-6 gap-y-2 items-start">
              {(title || tags) && (
                <div>
                  {/* Tags */}
                  {tags && tags.length && (
                    <div className="mb-2">
                      <Tags>{tags}</Tags>
                    </div>
                  )}
                  {/* Title */}
                  {title && (
                    <div className="mb-2 lg:mb-4">
                      {title && (
                        <Title
                          icon={icon}
                          subtitle={subtitle}
                          level="h2"
                          size="md"
                          noMargin
                          color={style === 'success' ? 'success' : 'dark'}>
                          {title}
                        </Title>
                      )}
                    </div>
                  )}
                </div>
              )}
              {!!actions && (
                <ResponsiveActionSelect actions={actions} justify="end" />
              )}
            </div>
          )}

          {/* Card content */}
          <div className="w-full max-w-full flex flex-col">{children}</div>

          {/* Expand/read more button */}
          <AnimatePresence>
            {!expanded && (
              <motion.div
                initial={{opacity: 1}}
                exit={{
                  opacity: 0,
                  transition: {
                    duration: EXPAND_TRANSITION_DURATION / 2 / 1000,
                  },
                }}
                className={clsx(
                  'pointer-events-none',
                  'absolute bottom-0 left-0',
                  'w-full h-44',
                  'bg-gradient-to-b from-transparent to-white',
                  'flex flex-row items-end justify-center',
                )}>
                <div className="p-4 relative flex flex-row items-center justify-center">
                  <div className="absolute w-full h-full bg-white rounded-2xl blur-lg" />
                  <div className="pointer-events-auto w-full max-w-[180px]">
                    <Button
                      label="Read more"
                      icon={HiChevronDown}
                      category="tertiary"
                      size="base"
                      fillWidth={false}
                      mode="manual"
                      onClick={onExpand}
                    />
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  },
);

export default Card;
