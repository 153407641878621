import {ReactNode} from 'react';

import {HiCheckCircle} from '@react-icons/all-files/hi/HiCheckCircle';
import clsx from 'clsx';

import {InlineError, SpinningLoader} from 'components_sb/feedback';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import Tags, {TagColor} from 'components_sb/typography/Tags/Tags';
import Property from 'models/properties/Property';

interface BillingMethodSummary {
  (props: {
    children: ReactNode;
    title: string;
    properties: Property[] | null;
    propertiesLoading?: boolean;
    propertiesError?: boolean;
    isDefault?: boolean;
    selectable?: boolean;
    selected?: boolean;
    onSelect?: () => void;
    actions?: ReactNode;
  }): JSX.Element;
}

const BillingMethodSummary: BillingMethodSummary = ({
  children,
  title,
  properties,
  propertiesLoading = false,
  propertiesError = false,
  isDefault = false,
  selectable = false,
  selected = false,
  onSelect,
  actions,
}) => {
  return (
    <div
      className={clsx(
        'flex flex-col',
        'flex-1 rounded-lg',
        'min-h-32',
        'text-brand-850',
        'transition-all duration-200',
        'border-2 border-brand-50 ring-brand-500',
        selectable && 'select-none cursor-pointer',
        selected ? 'ring-2' : 'ring-0',
      )}
      role={selectable ? 'button' : undefined}
      onClick={onSelect}>
      {/* Heading */}
      <div
        className={clsx(
          'flex flex-row items-center justify-center gap-x-4 p-4 pb-2',
          selected ? 'text-brand-500' : 'text-brand-850',
        )}>
        {/* Title */}
        <div className="text-base whitespace-nowrap font-medium">{title}</div>
        {/* Default status */}
        {isDefault && (
          <Tags size="base" emphasis="secondary">
            {[
              {
                text: 'Default',
                color: TagColor.Blue,
              },
            ]}
          </Tags>
        )}
        {/* Selected status */}
        {selectable && (
          <HiCheckCircle
            className={clsx(
              'w-6 h-6 ml-auto text-green-600 transition duration-300',
              selected ? 'scale-100' : 'scale-0',
            )}
          />
        )}
      </div>
      {/* Content */}
      <div className="flex-1 flex flex-col items-center justify-evenly gap-y-4 text-sm p-4 pt-2">
        {children}
        {/* Linked properties (unavailable in selection mode) */}
        {!selectable && (
          <div>
            {/* Properties loading */}
            {propertiesLoading && <SpinningLoader size="sm" color="brand" />}

            {/* Error loading properties */}
            {propertiesError && (
              <InlineError error="There was an issue loading your Smart Rent properties." />
            )}

            {/* Properties ready */}
            {/* {!propertiesLoading &&
              !propertiesError &&
              (properties.length ? (
                <div className="flex flex-col">
                  <Paragraph size="sm">
                    This billing method is currently being used for the
                    following properties:
                  </Paragraph>
                  <ul className="ml-2 list-inside list-disc text-brand-850">
                    {properties.map((property) => (
                      <li key={property.id}>
                        <Hyperlink href={`/properties/${property.id}`}>
                          {property.streetAddress}
                        </Hyperlink>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <Paragraph secondary noMargin size="sm">
                  This billing method is not currently being used for any of
                  your properties.
                </Paragraph>
              ))} */}
          </div>
        )}
      </div>
      {/* Actions / Footer (unavailable in selection mode) */}
      {!selectable && actions && (
        <div className="flex flex-row flex-wrap justify-center gap-2 p-2 border-t-2 border-brand-50">
          {actions}
        </div>
      )}
    </div>
  );
};

export default BillingMethodSummary;
