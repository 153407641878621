import {HTMLProps} from 'react';

import {type FormikProps} from 'formik';

import {errorsFor, valueFor} from 'components/forms_fields/Helpers';
import {InlineError} from 'components_sb/feedback';
import FieldLabel, {
  FieldLabelProps,
} from 'components_sb/typography/FieldLabel/FieldLabel';

interface InputFieldProps extends HTMLProps<HTMLInputElement> {
  formik: FormikProps<any>;
  labelProps: FieldLabelProps;
  name: string;
  incrementValue?: number;
}

const CountableMoneyField = ({formik, labelProps, name}: InputFieldProps) => {
  const errors = errorsFor(formik, name);
  let value = valueFor(formik, name);
  value = Number(value);

  return (
    <div className="flex flex-col justify-center items-center">
      {labelProps && <FieldLabel {...labelProps} />}

      <input
        type="text"
        pattern="[0-9]*"
        className="input input-bordered rounded-xl text-center h-24 border-brand-75 border-1 text-3xl font-semibold mx-4"
        value={value}
        onChange={(e) => {
          const str = e.currentTarget.value.replace(/\D/g, '');
          formik.setFieldValue(name, Number(str));
        }}
      />

      {errors && (
        <InlineError error={typeof errors === 'string' ? errors : null} />
      )}
    </div>
  );
};

export default CountableMoneyField;
