import {CircularProgressBar} from '@tomik23/react-circular-progress-bar';

const ProgressCard = ({percent}: {percent: number}) => {
  return (
    <div className="h-48 py-4 px-6 flex flex-col items-center justify-center gap-y-2">
      <div className="flex flex-col justify-center items-center">
        <CircularProgressBar
          percent={percent}
          colorCircle="#F3F3F3"
          colorSlice="#3366FF"
          size={100}
        />

        <div className="font-semibold text-md text-brand-850 whitespace-nowrap">
          Profile Completion
        </div>
      </div>
    </div>
  );
};

export default ProgressCard;
