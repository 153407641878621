import {Model, Attr, BelongsTo} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import {
  ReferenceCommunicationType,
  ReferencePersonType,
  WrittenReference,
} from 'models/tenant_checks/ReferenceTypes';
import TenantCheckReport from 'models/tenant_checks/TenantCheckReport';

@Model()
class TenantCheckReference extends ApplicationRecord {
  static jsonapiType = 'tenant_check_references';

  /**
   * These are the attributes of the person applying for the property
   */
  @Attr({persist: false}) name: string;
  @Attr({persist: false}) email: string;

  /**
   * These are the attributes of the person who is being used as a reference
   * AKA the person we are contacting to ask about the applicant
   */
  @Attr({persist: false}) referenceName: string;
  @Attr({persist: false}) referenceEmail: string;
  @Attr({persist: false}) referencePhoneNumber: string;
  /**
   * This is an array as someone might be a listed as a refenence for multiple reasons
   * Eg: They were an employer who also provided housing.
   */
  @Attr({persist: false}) referenceTypes: ReferencePersonType[];

  @Attr({persist: false})
  referenceCommunicationType: ReferenceCommunicationType;

  /**
   * This is an array as someone might be a listed as a reference for multiple reasons.
   * It refers to how the referrer knows the applicant. Eg: Manager, Landlord, etc.
   */
  @Attr({persist: false}) referenceRelationships: string[];

  @Attr() completed: boolean;
  @Attr() completedAt: string | null;

  /**
   * If a phone reference is done, then this reference is used
   * Its the write up from the Keyhook employee who phoned the reference
   */
  @Attr({persist: false}) keyhookReference: string;

  /**
   * If a written reference is done, then this reference is used
   * Its the write up from the person who filled out the reference form
   */
  @Attr() userReference: WrittenReference;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @BelongsTo('tenant_check_reports') tenantCheckReport: TenantCheckReport[];
}

export default TenantCheckReference;
