import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {isMobile} from 'react-device-detect';
import {AiOutlineUserSwitch} from 'react-icons/ai';
import {BsShieldCheck} from 'react-icons/bs';
import {HiOutlineClipboardCheck} from 'react-icons/hi';
import {TbUserSearch} from 'react-icons/tb';
import {useQuery} from 'react-query';

import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {Card, ContentError} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {TenantCheckReportStatus} from 'models/tenant_checks/TenantCheckReport';
import TenantCheckRequest from 'models/tenant_checks/TenantCheckRequest';
import {RouterLink} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {Action} from 'types/actions';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const TenantCheckRequestsCard = ({
  listingRentalApplicationId,
  isShortlisted,
  markAsShortlisted,
  showRequestModal,
}: {
  listingRentalApplicationId: string;
  isShortlisted: boolean;
  markAsShortlisted: () => void;
  showRequestModal: () => void;
}) => {
  const {
    data: tenantCheckRequests,
    isLoading,
    isError,
    isSuccess,
  } = useQuery(
    ['tenant-check-requests', listingRentalApplicationId],
    async () =>
      (
        await TenantCheckRequest.includes('tenant_check_reports')
          .where({
            listing_rental_application_id: listingRentalApplicationId,
          })
          .select({
            tenant_check_reports: [
              'id',
              'tenant_check_request_id',
              'status',
              'first_name',
              'middle_name',
              'last_name',
              'email',
            ],
          })
          .all()
      ).data,
    {
      refetchOnWindowFocus: false,
    },
  );

  const statusToString = useCallback(
    (status: TenantCheckReportStatus): string => {
      if (status === TenantCheckReportStatus.AwaitingExtraInformation) {
        return 'Waiting for extra information from the tenant';
      } else if (status === TenantCheckReportStatus.AwaitingResults) {
        return 'The check is underway and will be completed soon.';
      } else if (status === TenantCheckReportStatus.CreditReportCompleted) {
        return 'The credit report has been completed, but the other checks are still underway.';
      } else if (status === TenantCheckReportStatus.Completed) {
        return 'The check has been completed.';
      }
    },
    [],
  );

  const hasExistingTenantCheckRequests = useMemo(
    () => !!tenantCheckRequests && tenantCheckRequests.length > 0,
    [tenantCheckRequests],
  );

  const actions = useMemo<Action[]>(
    () =>
      hasExistingTenantCheckRequests
        ? [
            {
              label: 'Request tenant check',
              onClick: showRequestModal,
              icon: TbUserSearch,
            },
          ]
        : null,
    [hasExistingTenantCheckRequests, showRequestModal],
  );

  return (
    <Card title="Tenant Checks" icon={BsShieldCheck} actions={actions}>
      {isLoading && (
        <div className="flex justify-start p-4">
          <SpinningLoader size="base" color="brand" />
        </div>
      )}
      {isError && (
        <ContentError message="Sorry, there was an issue loading your tenant checks." />
      )}
      {isSuccess && (
        <div className="flex flex-col gap-y-2">
          {!isShortlisted ? (
            <>
              <Paragraph>
                You must first shortlist this application before you can request
                a tenant check.
              </Paragraph>
              <Button
                label="Mark as shortlisted"
                icon={HiOutlineClipboardCheck}
                category="primary"
                size="base"
                fillWidth={false}
                mode="manual"
                onClick={markAsShortlisted}
              />
            </>
          ) : tenantCheckRequests.length > 0 ? (
            tenantCheckRequests.map(({id, checkType, tenantCheckReports}) => (
              <div key={id}>
                {tenantCheckReports.map((report) => (
                  <RouterLink
                    key={report.id}
                    href={`/tenant-check-reports/${report.id}`}>
                    <div className="flex justify-start items-center gap-2 p-2 hover:bg-brand-50 rounded-xl">
                      <div>
                        <AiOutlineUserSwitch className="w-10 h-10" />
                      </div>
                      <div className="flex flex-col">
                        <p>
                          {report.fullName} -{' '}
                          {titleize(removeUnderscores(checkType))} Report
                        </p>
                        <p className="text-xs">
                          {statusToString(report.status)}
                        </p>
                        <p className="text-xs text-base-content text-opacity-60">
                          {report.email}
                        </p>
                      </div>
                    </div>
                  </RouterLink>
                ))}
              </div>
            ))
          ) : (
            <div className="w-auto md:max-w-xs">
              <Button
                label="Request tenant check"
                icon={TbUserSearch}
                category="primary"
                size="base"
                fillWidth
                mode="manual"
                onClick={showRequestModal}
              />
            </div>
          )}
        </div>
      )}
    </Card>
  );
};

export default TenantCheckRequestsCard;
