import {useRef, useState} from 'react';

import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/outline';
import {Formik, Form, type FormikProps} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import DocumentPreviewModal from 'components/document/DocumentPreviewModal';
import {
  DateField,
  SubmitButton,
  SelectField,
  TextareaField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import SignatureModalInput from 'components/forms_fields/SignatureModalInput';
import ChattelsAction from 'components/property/landlord/profile_blocks/ChattelsAction';
import InsurancePoliciesAction from 'components/property/landlord/profile_blocks/InsurancePoliciesAction';
import InviteOtherTenantsAction from 'components/property/landlord/profile_blocks/InviteOtherTenantsAction';
import PersonalProfileAction from 'components/property/landlord/profile_blocks/PersonalProfileAction';
import RequirementsAction from 'components/property/landlord/profile_blocks/RequirementsAction';
import NewRentPriceChangeForm from 'components/rent_price_changes/NewRentPriceChangeForm';
import LeaseConditions from 'components/tenancy/LeaseConditions';
import {statusForRenewal} from 'components/tenancy/RenewalStatusCard';
import {Button} from 'components_sb/buttons';
import {Card, Divider} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph, Title} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import {Clause} from 'models/properties/ClauseLibrary';
import RentPriceChange from 'models/properties/RentPriceChange';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const EditTenancyRenewalPage = () => {
  const {
    params: {propertyId, tenancyRenewalId},
  } = useRoute();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const {authCookies} = useAuth();

  const [selectedOption, setSelectedOption] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const [leaseUrl, setLeaseUrl] = useState(null);

  const [documentModalVisible, setDocumentModalVisible] = useState(false);

  const {data, isLoading, error} = useQuery(
    `landlord-tenancy-renewal-${tenancyRenewalId}`,
    async () => {
      const renewal = await TenancyContinuation.includes({
        tenancy: 'property',
      }).find(tenancyRenewalId);

      return renewal.data;
    },
  );
  const queryClient = useQueryClient();

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleGoPeriodic = async () => {
    setIsSaving(true);
    const renewal = data;
    renewal.landlordRequestedAction = 'go_periodic';

    const result = await renewal?.save();

    if (result) {
      queryClient.setQueryData(
        `landlord-tenancy-renewal-${tenancyRenewalId}`,
        renewal,
      );
      queryClient.invalidateQueries([
        'property',
        {id: propertyId, context: 'detail-page'},
      ]);

      toast.success('Renewal option successfully saved!');
    }
    setIsSaving(false);
  };

  const handleStartNewLeaseTenancy = async (formValues: any, actions: any) => {
    const renewal = data;
    renewal.landlordRequestedAction = 'start_new_lease';

    renewal.assignAttributes(formValues);

    const result = await renewal.save();

    if (result) {
      queryClient.setQueryData(
        `landlord-tenancy-renewal-${tenancyRenewalId}`,
        renewal,
      );
      queryClient.invalidateQueries([
        'property',
        {id: propertyId, context: 'detail-page'},
      ]);

      toast.success('Renewal option successfully saved!');
    } else {
      for (const key of Object.keys(renewal.errors)) {
        const error = renewal.errors[key];
        actions.setFieldError(key, error?.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  const onRentFormSave = async (rpc: RentPriceChange) => {
    const renewal = data;
    renewal.newTotalRent = rpc.newTotalRent;
    renewal.rentChangeEffectiveDate = rpc.effectiveDate;

    const result = await renewal.save();
    if (result) {
      queryClient.setQueryData(
        `landlord-tenancy-renewal-${tenancyRenewalId}`,
        renewal,
      );
      queryClient.invalidateQueries([
        'property',
        {id: propertyId, context: 'detail-page'},
      ]);
    }
  };

  const onKeepSameRent = async () => {
    const renewal = data;
    renewal.newTotalRent = data.tenancy.totalRent;

    const result = await renewal.save();
    if (result) {
      queryClient.setQueryData(
        `landlord-tenancy-renewal-${tenancyRenewalId}`,
        renewal,
      );

      toast.success('Your choice has been successfully saved!');
    }
  };

  const confirmUndoDecision = () => {
    setConfirmationOptions({
      title: 'Undo Renewal Choice',
      message: 'Are you sure you want to undo your choice for this renewal?',
      color: 'error',
      buttonTitle: 'Undo',
      action: undoDecision,
    });
  };

  const undoDecision = async () => {
    const renewal = data;

    renewal.landlordRequestedAction = null;
    renewal.tenantChosenAction = null;

    const result = await renewal.save();

    if (result) {
      queryClient.setQueryData(
        `landlord-tenancy-renewal-${tenancyRenewalId}`,
        renewal,
      );
      queryClient.invalidateQueries([
        'property',
        {id: propertyId, context: 'detail-page'},
      ]);

      toast.success('Renewal choice has been undone.');
    } else {
      toast.error('There was an issue undoing your renewal choice.');
    }
  };

  const quickSetEndDate = (formik: FormikProps<any>, numMonths: number) => {
    if (data.tenancy.endDate) {
      const ed = moment(data.tenancy.endDate);
      const endDate = ed.add(numMonths, 'months').format('YYYY-MM-DD');
      formik.setFieldValue('endDate', endDate);
    }
  };

  const reuseConditions = (formik: FormikProps<any>) => {
    formik.setFieldValue('customClauses', data.tenancy.customClauses);
    formik.setFieldValue('clauses', data.tenancy.clauses);
  };

  const downloadLease = (formik: FormikProps<any>) => {
    const leaseParams = {...formik.values};
    leaseParams.customClauses = leaseParams.customClauses.clauses;

    fetch(
      `${API_URL}/tenancy_continuations/${tenancyRenewalId}/lease_preview.pdf?`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-USER-TOKEN': authCookies.token,
          'X-USER-EMAIL': authCookies.userEmail,
        },
        body: JSON.stringify(leaseParams),
      },
    )
      .then((response) => {
        return response.arrayBuffer();
      })
      .then((data) => {
        setLeaseUrl(data);
        setDocumentModalVisible(true);
      });
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -256,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 256,
        behavior: 'smooth',
      });
    }
  };

  const cardForRenewalType = () => {
    if (isLoading) {
      return null;
    }

    if (data?.landlordRequestedAction) {
      const title = titleize(removeUnderscores(data.landlordRequestedAction));
      const status = statusForRenewal(data);
      return (
        <Card title={`Renewal Option: ${title}`}>
          <Paragraph>{status}</Paragraph>

          {!data.allTenantsSigned && (
            <div>
              <button
                type="button"
                className="btn btn-error mt-4"
                onClick={confirmUndoDecision}>
                Undo Renewal Choice
              </button>
            </div>
          )}
        </Card>
      );
    }

    if (!data.newTotalRent) {
      return (
        <Card
          title="Give notice for changing rent"
          className="mt-4 overflow-visible">
          <Paragraph>
            You're required by law to give your tenant 60 days' notice of any
            rent increases. Enter an updated rent amount to send a formal notice
            to your tenant. If you wish the keep the rent amount the same, click
            the button below and continue to view your tenancy renewal options.
          </Paragraph>

          <Title level="h2" size="md" noMargin>
            Keep the same rent
          </Title>

          <Paragraph>
            If you do not wish to change the rent, click the button below.
          </Paragraph>

          <Button
            mode="manual"
            onClick={onKeepSameRent}
            label="Keep the same rent"
            category="primary"
            size="base"
          />

          <Divider orientation="horizontal" labelPosition="middle">
            OR
          </Divider>

          <NewRentPriceChangeForm
            tenancy={data.tenancy}
            onSuccess={onRentFormSave}
          />
        </Card>
      );
    }

    if (selectedOption === '') {
      return (
        <Card title="Select a Renewal Option" className="mt-4 overflow-visible">
          <Paragraph>
            If you don't select an option, your tenancy will automatically
            become a periodic tenancy after the current end date.
          </Paragraph>
          <Paragraph>
            We recommend discussing this with your tenant prior to selecting an
            option.
          </Paragraph>

          <div className="flex justify-between mt-2">
            <div className="mr-2 w-1/2">
              <button
                className="btn btn-neutral w-full"
                type="button"
                onClick={() => setSelectedOption('periodic')}>
                Go Periodic
              </button>
            </div>

            <div className="ml-2 w-1/2">
              <button
                className="btn btn-neutral w-full"
                type="button"
                onClick={() => setSelectedOption('startNewLease')}>
                Start New Fixed Term Lease
              </button>
            </div>
          </div>
        </Card>
      );
    } else if (selectedOption === 'periodic') {
      return (
        <Card title="Go Periodic" className="mt-4 overflow-visible">
          <Paragraph>
            Periodic leases have no fixed end date and are subject to different
            notice periods, etc. For more information, visit{' '}
            <Hyperlink
              href="https://www.tenancy.govt.nz/starting-a-tenancy/types-of-tenancies/periodic-or-fixed-term-tenancy/"
              openInNewTab>
              here
            </Hyperlink>
            . Once you confirm your choice to go periodic, your tenant will be
            notified and you may not be able to undo this action.
          </Paragraph>

          <Paragraph>
            Click the button below to lock in your decision, this will then be
            sent to the head tenant for approval.
          </Paragraph>

          <div className="mt-2">
            <button
              className="btn btn-success btn-block"
              onClick={handleGoPeriodic}
              disabled={isSaving}>
              Go Periodic
            </button>

            <Divider orientation="horizontal" labelPosition="middle">
              OR
            </Divider>

            <div className="mt-8">
              <button
                className="btn btn-secondary btn-block"
                onClick={() => setSelectedOption('')}>
                Go back
              </button>
            </div>
          </div>
        </Card>
      );
    } else if (selectedOption === 'startNewLease') {
      const minEndDate = moment(data?.tenancy.endDate).add(7, 'days').toDate();
      return (
        <Card
          title="Start new fixed term lease"
          className="mt-3 overflow-visible">
          <Paragraph>
            Easily put together a new lease and send it to to your tenants to
            sign.
          </Paragraph>
          <div>
            <Formik
              initialValues={{
                endDate: '',
                landlordSignature: '',
                leaseDocumentType: 'keyhook_new_zealand',
                customClauses: {
                  clauses: [] as string[],
                },
                clauses: [] as Clause[],
                messageFromLandlord: null,
              }}
              onSubmit={handleStartNewLeaseTenancy}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={Yup.object().shape({
                endDate: Yup.date()
                  .required()
                  .label('End Date')
                  .test(
                    'should-be-greather-than-previous-end-date',
                    'Should be after the previous end date',
                    (value) => {
                      if (!value) {
                        return true;
                      }
                      const endDate = data.tenancy.endDate;
                      if (!endDate) {
                        return true;
                      }

                      return moment(value) > moment(endDate);
                    },
                  ),
                landlordSignature: Yup.string()
                  .required()
                  .min(1)
                  .label('Signature'),
                leaseDocumentType: Yup.string()
                  .required()
                  .oneOf(['keyhook_new_zealand', 'tenancy_services'])
                  .label('Lease Type'),
                customClauses: Yup.object()
                  .shape({
                    clauses: Yup.array().of(
                      Yup.string().min(1).label('Condition'),
                    ),
                  })
                  .label('Conditions'),
                messageFromlandlord: Yup.string()
                  .label('Message to tenants')
                  .nullable()
                  .optional(),
              })}>
              {(formik) => {
                return (
                  <Form>
                    <FormRow responsive>
                      <FormRowItem>
                        <DateField
                          label="End Date"
                          name="endDate"
                          formik={formik}
                          minDate={minEndDate}
                          maxDate={moment().add(100, 'years').toDate()}>
                          <div className="dropdown dropdown-end">
                            <label
                              tabIndex={0}
                              className="btn !rounded-l-none !rounded-r">
                              Quick Set
                            </label>
                            <ul
                              tabIndex={0}
                              className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                              {[6, 12, 24].map((numMonths) => (
                                <li key={numMonths}>
                                  <a
                                    onClick={() =>
                                      quickSetEndDate(formik, numMonths)
                                    }>
                                    {numMonths} Months
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </DateField>
                      </FormRowItem>

                      <FormRowItem>
                        <SelectField
                          name="leaseDocumentType"
                          formik={formik}
                          labelProps={{
                            title: 'Lease type',
                            helpText:
                              'Choose the template you wish to use. We recommend using the Keyhook Lease',
                          }}>
                          <option value="keyhook_new_zealand">
                            Keyhook Lease
                          </option>
                          <option value="tenancy_services">
                            Tenancy Services
                          </option>
                        </SelectField>
                      </FormRowItem>
                    </FormRow>

                    <div className="flex flex-col mt-8">
                      <div className="flex justify-between items-center">
                        <div>
                          <Title level="h4" size="md">
                            Update your lease
                          </Title>
                          <p>
                            Please click on the cards below and make sure they
                            are filled with the correct information. The
                            information on these cards will be used to fill your
                            lease.
                          </p>
                        </div>
                        <div className="flex justify-end gap-2">
                          <button
                            className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 bg-white hover:bg-neutral hover:text-white"
                            type="button"
                            onClick={scrollLeft}>
                            <ArrowLeftIcon className="w-4 h-4" />
                          </button>

                          <button
                            className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 bg-white hover:bg-neutral hover:text-white"
                            type="button"
                            onClick={scrollRight}>
                            <ArrowRightIcon className="w-4 h-4" />
                          </button>
                        </div>
                      </div>

                      <div
                        className="flex overflow-x-scroll mt-4 pb-4 hide-scroll-bar"
                        ref={scrollRef}>
                        <div className="flex flex-nowrap gap-4">
                          <PersonalProfileAction
                            property={data.tenancy.property}
                            tenancy={data.tenancy}
                            storeKey="personal_profile"
                          />

                          <InsurancePoliciesAction
                            property={data.tenancy.property}
                            tenancy={data.tenancy}
                            storeKey="insurance_policies"
                            showDismiss={false}
                          />

                          <ChattelsAction
                            property={data.tenancy.property}
                            tenancy={data.tenancy}
                            storeKey="property_chattels"
                          />

                          <RequirementsAction
                            property={data.tenancy.property}
                            tenancy={data.tenancy}
                            storeKey="requirements"
                          />

                          {!data.tenancy.isNew && (
                            <InviteOtherTenantsAction
                              property={data.tenancy.property}
                              tenancy={data.tenancy}
                              storeKey="invite_other_tenants"
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <LeaseConditions
                        property={data.tenancy.property}
                        formik={formik}
                      />

                      {data.tenancy.isNew && (
                        <button
                          type="button"
                          className="btn btn-info btn-block mt-2"
                          onClick={() => reuseConditions(formik)}>
                          Reuse Conditions From Current Lease
                        </button>
                      )}
                    </div>

                    <div className="mt-8">
                      <p className="mb-2">
                        Once the fields above have been filled. click the button
                        below to preview the new lease and then draw your
                        signature below to sign it if you are satisified.
                      </p>

                      <button
                        className="btn btn-neutral btn-sm"
                        type="button"
                        onClick={() => downloadLease(formik)}>
                        Preview Lease
                      </button>

                      {leaseUrl && (
                        <DocumentPreviewModal
                          isVisible={documentModalVisible}
                          setIsVisible={setDocumentModalVisible}
                          documentUrl={leaseUrl}
                          title="Preview New Lease"
                        />
                      )}
                    </div>

                    <div className="mt-4">
                      <SignatureModalInput
                        mode="formik"
                        name="landlordSignature"
                        labelProps={{
                          title: 'Signature',
                        }}
                      />
                    </div>

                    <div className="mt-4">
                      <TextareaField
                        name="messageFromLandlord"
                        formik={formik}
                        labelProps={{
                          title: 'Message to tenants',
                          size: 'base',
                        }}
                        placeholder="Optionally add a message alongside the lease for your tenant(s)."
                        helpText="This message will be sent to your tenants along with the new lease."
                      />
                    </div>

                    <div className="mt-4">
                      <SubmitButton
                        color="success"
                        formik={formik}
                        text="Save Renewal & Send to tenants"
                        submittingText="Saving"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>

          <Divider orientation="horizontal" labelPosition="middle">
            OR
          </Divider>

          <div className="mt-4">
            <button
              className="btn btn-secondary btn-block"
              onClick={() => setSelectedOption('')}
              type="button">
              Go back
            </button>
          </div>
        </Card>
      );
    } else {
      return null;
    }
  };

  return (
    <Page title="Tenancy Renewal" loading={isLoading} error={error}>
      {() => cardForRenewalType()}
    </Page>
  );
};

export default EditTenancyRenewalPage;
