import {Capacitor} from '@capacitor/core';
import {toast, ToastContainer, cssTransition} from 'react-toastify';
import './Toaster.css';

/**
 * Custom transition generated using Animista (https://animista.net/).
 * Refer to ./Toaster.css for animation properties.
 */
const Transition = cssTransition({
  enter: 'slide-in',
  exit: 'slide-out',
});

/**
 * Provider for displaying toasts across the application.
 */
const Toaster = () => {
  return (
    <ToastContainer
      /**
       * The position on the screen for toasts to appear.
       */
      position={toast.POSITION.TOP_RIGHT}
      /**
       * The animation performed when a toast appears/disappears.
       */
      transition={Transition}
      /**
       * Classes for the root toast container.
       */
      className="fixed top-0 right-0 z-100 w-full p-0 md:px-0 md:w-96"
      /**
       * Classes for the toast itself.
       */
      toastClassName="rounded-none md:rounded-xl m-0 md:m-4"
      /**
       * Classes for the body of the toast.
       */
      bodyClassName="font-poppins text-base text-brand-850 text-opacity-70 flex flex-row gap-x-2 py-4 md:py-3"
      /**
       * Classes for the toast progress bar
       */
      progressClassName=""
      /**
       * Styles for the toast itself.
       * Used for Capacitor safe area padding
       */
      style={
        Capacitor.isNativePlatform()
          ? {
              paddingTop: 'var(--f7-safe-area-top)',
              paddingBottom: 'var(--f7-safe-area-bottom)',
            }
          : {}
      }
    />
  );
};

export default Toaster;
