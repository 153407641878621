import {FunctionComponent} from 'react';

import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {push as Menu} from 'react-burger-menu';

import keyhookLogo from 'assets/img/logos/keyhook/logo-main.png';
import {useMobileMenu} from 'components/navbar/MobileMenu';
import {NATIVE_LAYOUT_IN_WEB} from 'globals/app-globals';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import {RouterLink} from 'router/components';

import WebHeaderContent, {DESKTOP_LAYOUT_BREAKPOINT} from './WebHeaderContent';
import {MenuContent} from './WebHeaderContent/Mobile';

const WebHeader: FunctionComponent = () => {
  const mobileMenu = useMobileMenu();
  const desktopLayout = useTailwindBreakpoint(DESKTOP_LAYOUT_BREAKPOINT);
  return (
    <>
      {/* Main header container */}
      <div className={clsx('w-full', 'bg-brand-0', 'border-b border-brand-50')}>
        <div
          className={clsx(
            'w-full h-full',
            'box-border',
            'flex flex-row',
            'gap-x-8',
            'max-w-7xl mx-auto pl-6 lg:pl-8 box-border',
            'items-center',
            'justify-between',
          )}>
          {/* Logo */}
          <div className="flex-shrink-0">
            <RouterLink external href="https://www.keyhook.com/">
              <img
                src={keyhookLogo}
                alt="Keyhook"
                className="h-10 xs:h-12 w-auto"
              />
            </RouterLink>
          </div>
          {/* Main header content */}
          <div className="h-full flex-1 relative">
            <WebHeaderContent />
          </div>
        </div>
      </div>

      {/* Mobile drawer menu */}
      {!desktopLayout &&
        !Capacitor.isNativePlatform() &&
        !NATIVE_LAYOUT_IN_WEB && (
          <Menu
            right
            width={280}
            customBurgerIcon={false}
            outerContainerId="app"
            pageWrapId="router-container"
            menuClassName="bg-white"
            isOpen={mobileMenu.isOpen}
            onClose={mobileMenu.toggle}>
            <div className="h-full flex flex-col">
              <MenuContent />
            </div>
          </Menu>
        )}
    </>
  );
};

export default WebHeader;
