import {useCallback, useEffect, useMemo, useState} from 'react';

import dayjs from 'dayjs';
import {useFormik, type FormikConfig, FormikHelpers} from 'formik';
import {HiOutlineCalendar, HiOutlineRefresh} from 'react-icons/hi';
import {useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import StepContainer from 'components/walkthrough/common/StepContainer';
import {DatePicker} from 'components_sb/input';
import {DisabledDates} from 'components_sb/input/DatePicker/DatePicker';
import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import {OBFS} from 'constants/onboarding-flow-steps';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

interface FormValues {
  startDate: Date | null;
  endDate: Date | null;
}

const TenancyPeriodStep: OnboardingFlowStepComponent = ({step, property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  /**
   * Client-side only state for determining whether to require
   * just the start date, or both start and end dates.
   */
  const [isOngoing, setIsOngoing] = useState(!tenancy.endDate);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      step === OBFS.MigrateCommencementDate
        ? TrackingService.Event.MigrateTenancy_StartCommencementDateStep
        : TrackingService.Event.NewTenancy_StartRentalAvailabilityStep,
      {
        propertyId: property.id,
        tenancyId: tenancy?.id,
      },
    );
  }, [property.id, tenancy?.id, scrollToTop, step]);

  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState(false);

  const router = useRouter();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set the dates on the tenancy.
       */
      tenancy.assignAttributes({
        startDate: formValues.startDate.toString(),
        /**
         * If the user chose fixed-term, and then set an end date, but
         * then changed back to ongoing, don't save / clear the end date.
         */
        endDate:
          !isOngoing && !!formValues.endDate
            ? formValues.endDate.toString()
            : null,
      });

      /**
       * For the migrate tenancy flow, this will be the last step,
       * so we set the status to pending. For the new tenancy flow,
       * we leave it as a draft.
       */
      if (step === OBFS.MigrateCommencementDate) {
        tenancy.assignAttributes({status: 'pending'});
      }

      /**
       * Save the changes to the tenancy.
       */
      if (!(await saveResource(tenancy))) {
        for (const key in tenancy.errors) {
          const err = tenancy.errors[key]?.fullMessage;
          actions.setFieldError(key, err);
        }
        return;
      }

      /**
       * Set changes on the property.
       */
      property.setOnboardingStepAsCompleted(step);

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        step === OBFS.MigrateCommencementDate
          ? TrackingService.Event.MigrateTenancy_CompleteCommencementDateStep
          : TrackingService.Event.NewTenancy_CompleteRentalAvailabilityStep,
        {
          propertyId: property.id,
          tenancyId: tenancy.id,
        },
      );

      /**
       * Track completion of the flow if currently in the migration flow.
       */
      if (step === OBFS.MigrateCommencementDate) {
        TrackingService.trackEvent(
          TrackingService.Event.MigrateTenancy_CompleteFlow,
          {
            propertyId: property.id,
            tenancyId: tenancy.id,
          },
        );
        localStorage.removeItem('new-property-id');
        toast.success('Your property has been successfully set up!');
        router.navigate('/properties/' + property.id, {reloadCurrent: true});
      }

      setSubmitting(false);
    },
    [router, property, tenancy, queryClient, isOngoing, step],
  );

  /**
   * Define initial form field values.
   */
  const initialValues = useMemo(
    () => ({
      /**
       * If a start date has already been selected, use this as
       * the initial value, otherwise use the day of the week that
       * rent is paid for the week following the current.
       */
      startDate: tenancy.startDate
        ? new Date(tenancy.startDate)
        : dayjs()
            .startOf('isoWeek')
            .add(1, 'week')
            .weekday(
              dayjs()
                .localeData()
                .weekdays()
                .indexOf(tenancy.dayOfWeekRentPaid),
            )
            .toDate(),

      /**
       * If an end date has already been selected, use this as
       * the initial value, otherwise do not set any default value.
       */
      endDate: tenancy.endDate ? new Date(tenancy.endDate) : null,
    }),
    [tenancy],
  );

  /**
   * Define the validation schema based on whether ongoing
   * or fixed-term is selected.
   */
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        startDate: Yup.date()
          .typeError(
            'Please enter the date the tenancy will commence on Keyhook',
          )
          .required(
            'Please enter the date the tenancy will commence on Keyhook',
          ),
        endDate: isOngoing
          ? Yup.date().nullable()
          : Yup.date()
              .typeError('Please enter the date that the tenancy will end')
              .min(
                Yup.ref('startDate'),
                "End date can't be before the start date",
              ),
      }),
    [isOngoing],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormValues>>(
    () => ({
      initialValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema,
    }),
    [initialValues, handleSubmit, validationSchema],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Find today's date.
   */
  const today = useMemo(() => new Date(), []);

  /**
   * Find the date one week from today.
   */
  const oneWeekFromToday = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date;
  }, []);

  /**
   * Days that are not the day that rent is paid are not able to
   * be selected as the commencement date. The memo below finds
   * the indexes of each of the weekdays to set as disabled.
   */
  const disabledStartDateWeekdayIndexes = useMemo<number[]>(
    () =>
      step !== OBFS.MigrateCommencementDate
        ? []
        : dayjs()
            .localeData()
            .weekdays()
            .reduce((disabled, weekday, currentIndex) => {
              return weekday === tenancy.dayOfWeekRentPaid
                ? disabled
                : [...disabled, currentIndex];
            }, []),

    [step, tenancy],
  );

  /**
   * Define the dates that should not be available for selection
   * of the start date.
   */
  const disabledStartDates = useMemo<DisabledDates>(
    () => [
      {
        from: new Date(1900, 1, 1),
        to:
          step === OBFS.MigrateCommencementDate
            ? /**
               * We subtract one day here to account for the case where the rent
               * payment day is the same as the current day, to allow for the user
               * to select the day that is exactly one week away.
               */
              dayjs(oneWeekFromToday).subtract(1, 'day').toDate()
            : today,
      },
      {dayOfWeek: disabledStartDateWeekdayIndexes},
    ],
    [step, oneWeekFromToday, today, disabledStartDateWeekdayIndexes],
  );

  /**
   * Define the dates that should not be available for selection
   * of the end date.
   */
  const disabledEndDates = useMemo<DisabledDates>(
    () => [
      {
        from: new Date(1900, 1, 1),
        to: form.values.startDate ?? new Date(),
      },
    ],
    [form],
  );

  /**
   * Define the description to set on the date picker field based on the step.
   */
  const datePickerFieldDescription = useMemo(
    () => [
      ...(step === OBFS.MigrateCommencementDate
        ? [
            'Please note that the start date is when your tenancy will transition to Keyhook - not the start date of the existing tenancy.',
            'The start date should be at least one week away and align with rent payment.',
          ]
        : []),
      ...(step === OBFS.NewAvailability
        ? [
            'The date you choose as your start date will be the date in which rent will be processed.',
          ]
        : []),
    ],
    [step],
  );

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer title="Let's set the period for your tenancy" subtitle="">
      <BooleanSelect
        labelProps={{
          title:
            step === OBFS.MigrateCommencementDate
              ? 'What type of lease do you currently have?'
              : 'What type of lease would you like?',
          size: 'xl',
          description:
            'If you choose fixed-term now, you can change to ongoing at a later stage.',
          required: true,
        }}
        labels={{
          true: 'Ongoing',
          false: 'Fixed term',
        }}
        icons={{
          true: HiOutlineRefresh,
          false: HiOutlineCalendar,
        }}
        mode="manual"
        value={isOngoing}
        onChange={setIsOngoing}
      />
      <div className="flex flex-col gap-y-4">
        {isOngoing ? (
          // Ongoing - single date picker
          <DatePicker
            labelProps={{
              title: 'When would you like to start your tenancy on Keyhook?',
              description: datePickerFieldDescription,
              size: 'xl',
            }}
            mode="single"
            name="startDate"
            form={form}
            required={true}
            disabledDates={disabledStartDates}
          />
        ) : (
          // Fixed term - date range picker
          <DatePicker
            labelProps={{
              title: 'Enter the period for your tenancy on Keyhook',
              description: datePickerFieldDescription,
              size: 'xl',
            }}
            mode="range"
            name={{
              start: 'startDate',
              end: 'endDate',
            }}
            form={form}
            required={{
              start: true,
              end: true,
            }}
            disabledDates={{
              start: disabledStartDates,
              end: disabledEndDates,
            }}
            quickSetEndDateOptions={[
              {
                value: 3,
                unit: 'months',
              },
              {
                value: 6,
                unit: 'months',
              },
              {
                value: 12,
                unit: 'months',
              },
              {
                value: 24,
                unit: 'months',
              },
            ]}
          />
        )}
      </div>
    </StepContainer>
  );
};

export default TenancyPeriodStep;
