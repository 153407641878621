import {useCallback, useMemo} from 'react';

import useAuth from 'auth/provider/useAuth';
import useLocalUserSettings from 'hooks/useLocalUserSettings';

import NAV_ITEMS from './config';
import {useNavItemBadgeCounts} from './NavItemBadgeCount';
import type {NavItem} from './types';

interface UseNavItemsHook {
  (): NavItem[];
}

/**
 * Returns an array of nav link items to display in either the desktop nav bar
 * or the mobile drawer.
 */
const useNavItems: UseNavItemsHook = () => {
  const {isLoggedIn} = useAuth();
  const {activeAccountRole} = useLocalUserSettings();

  const badgeCounts = useNavItemBadgeCounts();

  /**
   * Recursive function to filter nav items and their sub items based
   * on their visibility conditions.
   * Note that:
   * - Items will be shown by default if no conditions have been set.
   * - A visibility condition evaluating to false on a parent nav item will
   *   prevent any child items from being shown regardless of their visibility
   *   conditions.
   */
  const filterNavItems = useCallback(
    (navItems: NavItem[]): NavItem[] =>
      navItems.reduce((filteredItems, navItem) => {
        /**
         * Evaluate whether to show the current nav item.
         */
        const {conditions} = navItem;
        const show =
          !conditions ||
          !conditions.length ||
          conditions.every((evaluate) =>
            evaluate({isLoggedIn, activeAccountRole}),
          );

        /**
         * If the current item has been evaluated to not be shown, exclude it
         * from the resulting nav link items.
         */
        if (!show) {
          return filteredItems;
        }

        /**
         * If the current nav item has been evaluated to be shown, also
         * recursively filter the sub items if there are any.
         */
        const {subItems} = navItem;
        return [
          ...filteredItems,
          !subItems
            ? navItem
            : {
                ...navItem,
                subItems: filterNavItems(subItems),
              },
        ];
      }, []),
    [isLoggedIn, activeAccountRole],
  );

  /**
   * Apply the current badge count value to each of the nav items.
   */
  const applyBadgeCounts = useCallback(
    (navItems: NavItem[]): NavItem[] =>
      navItems.map((navItem) => {
        const badgeCount = badgeCounts[navItem.id];
        /**
         * Apply badge count to the current nav item.
         */
        const newNavItem = {
          ...navItem,
          badgeCount: badgeCount ?? 0,
        };
        /**
         * Apply badge count to sub items.
         */
        if (navItem.subItems) {
          newNavItem.subItems = applyBadgeCounts(newNavItem.subItems);
        }
        return newNavItem;
      }),
    [badgeCounts],
  );

  /**
   * Evaluate which links to display for the static nav item configuration
   * and apply any badge counts to the resulting items.
   */
  const linkItems = useMemo(
    () => applyBadgeCounts(filterNavItems(NAV_ITEMS)),
    [filterNavItems, applyBadgeCounts],
  );

  return linkItems;
};

export default useNavItems;
