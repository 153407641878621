import {Router} from 'framework7/types';

import EditEndOfTenancyPage from 'pages/renter/end_of_tenancies/EditEndOfTenancyPage';
import RentPaymentIndexPage from 'pages/renter/rent_payments/RentPaymentIndexPage';
import EditServiceRequestPage from 'pages/renter/service_requests/EditServiceRequestPage';
import NewServiceRequestPage from 'pages/renter/service_requests/NewServiceRequestPage';
import ServiceRequestDetailPage from 'pages/renter/service_requests/ServiceRequestDetailPage';
import EditRentSplitsPage from 'pages/renter/tenancies/EditRentSplitsPage';
import TenancyDetailPage from 'pages/renter/tenancies/TenancyDetailPage';
import TenancyIndexPage from 'pages/renter/tenancies/TenancyIndexPage';
import EditTenancyRenewalPage from 'pages/renter/tenancy_renewals/EditTenancyRenewalPage';
import TenancyRequestDetailPage from 'pages/renter/tenancy_requests/TenancyRequestDetailPage';
import FormalNoticeIndexPage from 'pages/shared/formal_notices/FormalNoticeIndexPage';
import NewFormalNoticePage from 'pages/shared/formal_notices/NewFormalNoticePage';
import InspectionActionDetailPage from 'pages/shared/inspections/InspectionActionDetailPage';
import InspectionAmendmentsPage from 'pages/shared/inspections/InspectionAmendmentsPage';
import InspectionCameraPage from 'pages/shared/inspections/InspectionCameraPage';
import InspectionDetailPage from 'pages/shared/inspections/InspectionDetailPage';
import InspectionRoomDetailPage from 'pages/shared/inspections/InspectionRoomDetailPage';
import NewTenancyInvitePage from 'pages/shared/tenancy_invites/NewTenancyInvitePage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER, LOGGED_IN, IS_RENTER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/tenancies',
    id: 'Tenancies',
    async: resolveRoute({
      conditions: [LOGGED_IN, IS_RENTER],
      component: TenancyIndexPage,
    }),
    routes: [
      {
        path: ':tenancyId',
        id: 'Tenancy',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_RENTER],
          component: TenancyDetailPage,
        }),
        routes: [
          {
            path: 'invite-tenants',
            id: 'NewTenancyInvite',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: NewTenancyInvitePage,
            }),
          },
          {
            path: 'edit-rent-amounts',
            id: 'EditTenancyRentSplit',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: EditRentSplitsPage,
            }),
          },
          {
            path: 'rent-payments',
            id: 'TenancyRentPayments',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: RentPaymentIndexPage,
            }),
          },
          {
            path: 'bond-refund',
            id: 'TenancyBondRefund',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: EditEndOfTenancyPage,
            }),
          },
          {
            path: 'notices',
            id: 'TenancyNotices',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_RENTER],
              component: FormalNoticeIndexPage,
            }),
            routes: [
              {
                path: 'new',
                id: 'NewTenancyNotice',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_RENTER],
                  component: NewFormalNoticePage,
                }),
              },
            ],
          },
          {
            path: 'inspections',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: ':inspectionId',
                id: 'TenancyInspection',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_RENTER],
                  component: InspectionDetailPage,
                }),
                routes: [
                  {
                    path: 'actions',
                    async: resolveRoute(
                      {conditions: [NEVER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: ':actionId',
                        id: 'TenancyInspectionAction',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_RENTER],
                          component: InspectionActionDetailPage,
                        }),
                      },
                    ],
                  },
                  {
                    path: 'revisions',
                    id: 'TenancyInspectionAmendments',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_RENTER],
                      component: InspectionAmendmentsPage,
                    }),
                  },
                  {
                    path: 'rooms',
                    async: resolveRoute(
                      {conditions: [LOGGED_IN, IS_RENTER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: ':roomKey',
                        id: 'TenancyInspectionRoom',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_RENTER],
                          component: InspectionRoomDetailPage,
                        }),
                        routes: [
                          {
                            path: 'camera',
                            id: 'TenancyInspectionRoomCamera',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_RENTER],
                              component: InspectionCameraPage,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'service-requests',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: 'new',
                id: 'NewTenancyServiceRequest',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_RENTER],
                  component: NewServiceRequestPage,
                }),
              },
              {
                path: ':serviceRequestId',
                id: 'TenancyServiceRequest',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_RENTER],
                  component: ServiceRequestDetailPage,
                }),
                routes: [
                  {
                    path: 'edit',
                    id: 'EditTenancyServiceRequest',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_RENTER],
                      component: EditServiceRequestPage,
                    }),
                  },
                ],
              },
            ],
          },
          {
            path: 'tenancy-renewals',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: ':tenancyRenewalId',
                async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
                routes: [
                  {
                    path: 'edit',
                    id: 'EditTenancyRenewal',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_RENTER],
                      component: EditTenancyRenewalPage,
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/tenancy-requests',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
    routes: [
      {
        path: ':tenancyRequestId',
        id: 'TenancyRequest',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_RENTER],
          component: TenancyRequestDetailPage,
        }),
      },
    ],
  },
];

export default routes;
