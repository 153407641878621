import {Form, Formik, type FormikHelpers} from 'formik';
import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {DatetimeField, SubmitButton} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {TextAreaField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import OpenHome from 'models/listings/OpenHome';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';

interface Vals {
  notes: string;
  startTime: string;
  endTime: string;
}

const EditOpenHomePage = () => {
  const {
    params: {propertyId, listingPublicId, openHomeId},
  } = useRoute();

  const router = useRouter();
  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `edit-open-home-${openHomeId}`,
    async () => {
      const oh = await OpenHome.find(openHomeId);

      return oh.data;
    },
  );

  const handleSubmit = async (
    formValues: Vals,
    actions: FormikHelpers<Vals>,
  ) => {
    const oh = data;
    oh.assignAttributes(formValues);

    const result = await oh.save();

    if (result) {
      toast.success('Open home successfully updated!');

      await queryClient.invalidateQueries(
        `listing-${listingPublicId}-open-homes`,
      );
      router.navigate(
        `/properties/${propertyId}/listings/${listingPublicId}/viewings`,
      );
    } else {
      for (const key in oh.errors) {
        const message = oh.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Page title="Edit Open Home" loading={isLoading} error={error}>
      {() => (
        <Card title="Schedule open home">
          <Formik
            initialValues={{
              notes: data.notes,
              startTime: data.startTime,
              endTime: data.endTime,
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              notes: Yup.string().optional().nullable().label('Notes'),
              startTime: Yup.string().required().label('Start Time'),
              endTime: Yup.string()
                .required()
                .label('End Time')
                .test(
                  'is-after-start-date',
                  'Must be after the start time',
                  function (val) {
                    return moment(val).isAfter(moment(this.parent.startTime));
                  },
                ),
            })}>
            {(formik) => (
              <Form>
                <Paragraph>
                  Any attendees will be notified of the updated time.
                </Paragraph>

                <TextAreaField
                  form={formik as any}
                  mode="formik"
                  name="notes"
                  label="Notes"
                  placeholder="Include any notes for potential tenants, e.g., how to access the property."
                />

                <FormRow responsive>
                  <FormRowItem>
                    <DatetimeField
                      formik={formik}
                      name="startTime"
                      label="Start Time"
                      minDate={new Date()}
                      maxDate={new Date('2100-01-01')}
                      onBlur={() => {
                        if (
                          formik.values.startTime &&
                          formik.values.startTime.length > 0
                        ) {
                          formik.setFieldValue(
                            'endTime',
                            moment(formik.values.startTime)
                              .add(1, 'hour')
                              .toISOString(),
                          );
                        }
                      }}
                    />
                  </FormRowItem>
                  <FormRowItem>
                    <DatetimeField
                      formik={formik}
                      name="endTime"
                      label="End Time"
                      minDate={new Date()}
                      maxDate={new Date('2100-01-01')}
                    />
                  </FormRowItem>
                </FormRow>
                <SubmitButton
                  formik={formik}
                  text="Update Open Home"
                  submittingText="Updating..."
                  className="mt-10"
                />
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </Page>
  );
};

export default EditOpenHomePage;
