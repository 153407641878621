import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {Capacitor} from '@capacitor/core';
import {Keyboard, KeyboardInfo, KeyboardResize} from '@capacitor/keyboard';
import {omit} from 'lodash';
import {SwipeCallback} from 'react-swipeable';

import useGlobalSwipe from 'providers/GlobalSwipeProvider/useGlobalSwipe';

const DEFAULT_KEYBOARD_RESIZE_MODE = KeyboardResize.Native;

interface KeyboardEventCallback {
  (info?: KeyboardInfo): void;
}

/**
 * Valid keyboard event types for the Capacitor keyboard plugin.
 */
export enum KeyboardEvent {
  KeyboardWillShow = 'keyboardWillShow',
  KeyboardDidShow = 'keyboardDidShow',
  KeyboardWillHide = 'keyboardWillHide',
  KeyboardDidHide = 'keyboardDidHide',
}

/**
 * The state for tracking keyboard listeners across event scopes.
 */
type KeyboardListenerState = {
  [key in KeyboardEvent]: {
    [key: string]: KeyboardEventCallback;
  };
};

interface AddKeyboardListenerFunction {
  (
    /**
     * A key identifying the listener unique to the event scope.
     * (i.e. the same key can be provided if adding listeners for differnt event types)
     */
    key: string,
    /**
     * The type of event to listen for.
     */
    event: KeyboardEvent,
    /**
     * The function to invoke upon the event occuring.
     */
    callback: KeyboardEventCallback,
  ): Promise<void>;
}

interface RemoveKeyboardListenerFunction {
  (
    /**
     * The type of event to listen for.
     */
    key: string,
    /**
     * The type of event to remove. If unspecified, all event types
     * with the given key will be removed.
     */
    event?: KeyboardEvent,
  ): Promise<void>;
}

interface SetKeyboardResizeModeFunction {
  (mode: KeyboardResize): Promise<void>;
}

interface SetDefaultKeyboardResizeModeFunction {
  (): Promise<void>;
}

interface OpenKeyboardFunction {
  (): void;
}

interface CloseKeyboardFunction {
  (): void;
}

interface KeyboardContextValue {
  keyboardIsOpen: boolean;
  keyboardIsOpening: boolean;
  keyboardIsClosing: boolean;
  keyboardIsTransitioning: boolean;
  keyboardHeight: number;
  addKeyboardListener: AddKeyboardListenerFunction;
  removeKeyboardListener: RemoveKeyboardListenerFunction;
  setKeyboardResizeMode: SetKeyboardResizeModeFunction;
  setDefaultKeyboardResizeMode: SetDefaultKeyboardResizeModeFunction;
  openKeyboard: OpenKeyboardFunction;
  closeKeyboard: CloseKeyboardFunction;
}

/**
 * Create the context.
 */
const KeyboardContext = createContext({} as KeyboardContextValue);

interface KeyboardProviderProps {
  children: ReactNode;
}

/**
 * Since the Capacitor keyboard plugin does not have a function for removing
 * individual event listeners and only provides a function for removing all
 * listeners, this provider handles the management of all listeners to enable
 * removal of individual listeners without affecting other components that
 * may also have keyboard listeners added.
 */
const KeyboardProvider: FunctionComponent<KeyboardProviderProps> = ({
  children,
}) => {
  /**
   * Track each of the possible states that they keyboard may be in.
   */
  const [isOpen, setIsOpen] = useState<boolean>(
    /**
     * The keyboard is always considered to be visible when not
     * in a Capacitor environment.
     */
    Capacitor.isNativePlatform() ? false : true,
  );
  const [isOpening, setIsOpening] = useState<boolean>(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);
  const isTransitioning = useMemo<boolean>(
    () => isOpening || isClosing,
    [isOpening, isClosing],
  );

  /**
   * Track the height of the keyboard.
   */
  const [height, setHeight] = useState<number>(0);

  /**
   * Invoked when the keyboard is about to open.
   */
  const onWillShow = useCallback((info: KeyboardInfo) => {
    setIsOpening(true);
    setHeight(info.keyboardHeight);
  }, []);

  /**
   * Invoked when the keyboard has opened.
   */
  const onDidShow = useCallback((info: KeyboardInfo) => {
    setIsOpen(true);
    setIsOpening(false);
    setHeight(info.keyboardHeight);

    /**
     * If an input field was selected causing the keyboard to open,
     * ensure that the field is still in view after the keyboard opens.
     */
    setTimeout(() => {
      if (document.activeElement) {
        try {
          (document.activeElement as any).scrollIntoViewIfNeeded(false);
        } catch (e) {
          console.log(e);
        }
      }
    }, 100);
  }, []);

  /**
   * Invoked when the keyboard is about to close.
   */
  const onWillHide = useCallback(() => {
    /**
     * Clear the explicit height set by the Capacitor keyboard plugin.
     */
    document.body.style.height = '';

    setIsClosing(true);
    setIsOpen(false);
    setHeight(0);
  }, []);

  /**
   * Invoked when the keyboard has closed.
   */
  const onDidHide = useCallback(() => {
    setIsClosing(false);
    setHeight(0);
  }, []);

  /**
   * Store the listeners that have been added for each of the keyboard event types.
   */
  const [listeners, setListeners] = useState<KeyboardListenerState>({
    [KeyboardEvent.KeyboardWillShow]: {provider: onWillShow},
    [KeyboardEvent.KeyboardDidShow]: {provider: onDidShow},
    [KeyboardEvent.KeyboardWillHide]: {provider: onWillHide},
    [KeyboardEvent.KeyboardDidHide]: {provider: onDidHide},
  });

  const applyListeners = useCallback(async () => {
    /**
     * Keyboard will show listeners.
     */
    await Promise.all(
      Object.values(listeners[KeyboardEvent.KeyboardWillShow]).map((callback) =>
        Keyboard.addListener(KeyboardEvent.KeyboardWillShow, callback),
      ),
    );

    /**
     * Keyboard did show listeners.
     */
    await Promise.all(
      Object.values(listeners[KeyboardEvent.KeyboardDidShow]).map((callback) =>
        Keyboard.addListener(KeyboardEvent.KeyboardDidShow, callback),
      ),
    );

    /**
     * Keyboard will hide listeners.
     */
    await Promise.all(
      Object.values(listeners[KeyboardEvent.KeyboardWillHide]).map((callback) =>
        Keyboard.addListener(KeyboardEvent.KeyboardWillHide, callback),
      ),
    );

    /**
     * Keyboard did hide listeners.
     */
    await Promise.all(
      Object.values(listeners[KeyboardEvent.KeyboardDidHide]).map((callback) =>
        Keyboard.addListener(KeyboardEvent.KeyboardDidHide, callback),
      ),
    );
  }, [listeners]);

  /**
   * When the listeners change in state, handle updating the listenrs in the
   * Capacitor keyboard plugin.
   */
  useEffect(() => {
    /**
     * Only add listeners if in a Capacitor environment.
     */
    if (!Capacitor.isNativePlatform()) {
      console.warn(
        'Not running in a Capacitor environment - keyboard listeners will be ignored.',
      );
      return;
    }

    applyListeners();

    /**
     * Remove all listeners.
     */
    return () => {
      Keyboard.removeAllListeners();
    };
  }, [applyListeners]);

  /**
   * Adds a new keyboard listener.
   */
  const addKeyboardListener = useCallback<AddKeyboardListenerFunction>(
    async (key, event, callback) => {
      /**
       * Prevent listeners being added using the internal listener key.
       */
      if (key === 'provider') {
        throw new Error(
          `The 'provider' key is reserved for use by KeyboardProvider internally.`,
        );
      }

      /**
       * Attempt to add the listener to the current state.
       */
      setListeners((current) => {
        /**
         * Check that a listener has not already been added for the given key
         * in the event scope.
         */
        if (key in current[event]) {
          throw new Error(
            `Keyboard listener with key '${key}' already added for event '${event}'.`,
          );
        } else {
          /**
           * Merge the new listener into the current state.
           */
          return {
            ...current,
            [event]: {
              ...current[event],
              [key]: callback,
            },
          };
        }
      });
    },
    [],
  );

  /**
   * Removes a keyboard listener.
   */
  const removeKeyboardListener = useCallback<RemoveKeyboardListenerFunction>(
    async (key, event) => {
      /**
       * Prevent internal listeners from being removed.
       */
      if (key === 'provider') {
        throw new Error(
          `Keyboard listeners with the 'provider' key are required for the KeyboardProvider and cannot be removed.`,
        );
      }

      setListeners((current) => {
        /**
         * Remove only the listener for the given event type if specified.
         */
        if (event) {
          return {
            ...current,
            [event]: omit(current[event], key),
          };
        }

        /**
         * Otherwise, if no event was specified, remove all listeners for the given key
         * across all event scopes.
         */
        return Object.entries(current).reduce(
          (result, listeners) => ({
            ...result,
            [listeners[0]]: omit(listeners[1], key),
          }),
          current,
        );
      });
    },
    [],
  );

  /**
   * Sets the provided keyboard resize mode if in a native platform.
   */
  const setResizeMode = useCallback<SetKeyboardResizeModeFunction>(
    async (mode: KeyboardResize) => {
      if (Capacitor.isNativePlatform()) {
        /**
         * If the resize mode gets changed from Body to another mode while
         * the keyboard is still open, the constrained height of the body
         * element will not get reverted back to its full height when the
         * keyboard closes. To avoid this, we always close the keyboard
         *  before changing the resize mode.
         */
        await Keyboard.hide();

        /**
         * Set the new resize mode.
         */
        await Keyboard.setResizeMode({
          mode,
        });
      }
    },
    [],
  );

  /**
   * Convenience function to set the keyboard resize mode to the default setting.
   */
  const setDefaultResizeMode =
    useCallback<SetDefaultKeyboardResizeModeFunction>(async () => {
      await setResizeMode(DEFAULT_KEYBOARD_RESIZE_MODE);
    }, [setResizeMode]);

  /**
   * While the Capacitor keyboard plugin does have a 'show' function, it
   * only works on Android and is still considered experimental for iOS.
   * The function below is a workaround that opens the keyboard by creating
   * a temporary invisible input and then focusing it.
   */
  const openKeyboard = useCallback<OpenKeyboardFunction>(() => {
    /**
     * Create a new tempoary input element.
     */
    const input = document.createElement('input');

    /**
     * Set fixed positioning on the input to avoid interfering with the layout.
     */
    input.style.position = 'fixed';

    /**
     * Hide the input from view via the opacity.
     * (if we set the visibility to hidden, we won't be able to focus it).
     */
    input.style.opacity = '0';

    /**
     * Add the input to the DOM.
     */
    document.body.appendChild(input);

    /**
     * Focus the input to trigger the keyboard opening.
     */
    input.focus();
  }, []);

  /**
   * Closes the keyboard.
   */
  const closeKeyboard = useCallback<CloseKeyboardFunction>(async () => {
    await Keyboard.hide();
  }, []);

  /**
   * Handle closing the keyboard if open when swiping down.
   */
  const onSwipedDown = useCallback<SwipeCallback>(
    ({velocity}) => {
      if (isOpen && velocity > 1.2) {
        closeKeyboard();
      }
    },
    [isOpen, closeKeyboard],
  );

  /**
   * Add the swipe down listener.
   */
  useGlobalSwipe({onSwipedDown});

  /**
   * The context value to be provided to components where the useKeyboard hook is used.
   */
  const value = useMemo<KeyboardContextValue>(
    () => ({
      keyboardIsOpen: isOpen,
      keyboardIsOpening: isOpening,
      keyboardIsClosing: isClosing,
      keyboardIsTransitioning: isTransitioning,
      keyboardHeight: height,
      addKeyboardListener,
      removeKeyboardListener,
      setKeyboardResizeMode: setResizeMode,
      setDefaultKeyboardResizeMode: setDefaultResizeMode,
      openKeyboard,
      closeKeyboard,
    }),
    [
      isOpen,
      isOpening,
      isClosing,
      isTransitioning,
      height,
      addKeyboardListener,
      removeKeyboardListener,
      setResizeMode,
      setDefaultResizeMode,
      openKeyboard,
      closeKeyboard,
    ],
  );

  return (
    <KeyboardContext.Provider value={value}>
      {children}
    </KeyboardContext.Provider>
  );
};

interface UseKeyboardHook {
  (): KeyboardContextValue;
}

/**
 * Export the hook for use in components.
 */
export const useKeyboard: UseKeyboardHook = () => useContext(KeyboardContext);

/**
 * Export the provider to wrap the app.
 */
export default KeyboardProvider;
