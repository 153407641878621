import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {f7, f7ready} from 'framework7-react';

interface MobileMenuContextValue {
  isOpen: boolean;
  toggle: () => void;
}

/**
 * The context used for handling the opened state of the drawer.
 */
const Context = createContext<MobileMenuContextValue>(
  {} as MobileMenuContextValue,
);

interface MobileMenuProvider {
  (props: {children: ReactNode}): JSX.Element;
}

/**
 * Closes the drawer by disabling the checked value on the
 * checkbox input element.
 */
const close = () => {
  const element = document.getElementById('mobile-drawer') as HTMLInputElement;
  if (element) {
    element.checked = false;
  }
};

/**
 * A drawer that opens from the side of the screen and
 * overlays the page content.
 */
const Provider: MobileMenuProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = useCallback(() => setIsOpen((current) => !current), []);

  const closeOnRouteChange = useCallback(() => {
    setIsOpen((current) => {
      return current ? false : current;
    });
  }, []);

  useEffect(() => {
    f7ready(() => {
      f7.views.main.router.on('routeChange', closeOnRouteChange);
    });
  }, [closeOnRouteChange]);

  const value = useMemo<MobileMenuContextValue>(
    () => ({isOpen, toggle}),
    [isOpen, toggle],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useMobileMenu = () => useContext(Context);

export default {
  Provider,
  close,
};
