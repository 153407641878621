import {FunctionComponent} from 'react';

import useOpenReplay from 'hooks/useOpenReplay';

interface OpenReplayProviderProps {
  children: JSX.Element;
}

const OpenReplayProvider: FunctionComponent<OpenReplayProviderProps> = ({
  children,
}) => {
  useOpenReplay();
  return children;
};

export default OpenReplayProvider;
