import {useMemo} from 'react';

import {useQuery} from 'react-query';

import RentalApplicationDetailView from 'components/rental_application/RentalApplicationDetailView';
import RentalApplication from 'models/listings/RentalApplication';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {Action} from 'types/actions';

const RentalApplicationDetailPage = () => {
  const {
    params: {rentalApplicationId},
  } = useRoute();

  const {data, isLoading, error} = useQuery(
    `rental-application-${rentalApplicationId}`,
    async () => {
      const app = await RentalApplication.includes([
        'rental_application_applicants',
        'user',
      ])
        .order({'rental_applications.id': 'asc'})
        .find(rentalApplicationId);

      return app.data;
    },
  );

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !rentalApplicationId
        ? null
        : [
            {
              label: 'Edit application',
              linkTo: `/rental-applications/${rentalApplicationId}/edit`,
            },
          ],
    [rentalApplicationId],
  );

  return (
    <Page
      title="Rental Application"
      actions={pageActions}
      loading={isLoading}
      error={error}>
      {() => <RentalApplicationDetailView rentalApplication={data} />}
    </Page>
  );
};

export default RentalApplicationDetailPage;
