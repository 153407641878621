import {useCallback, useContext, useMemo} from 'react';

import {useFormik} from 'formik';
import {UnmountClosed} from 'react-collapse';
import {AiOutlineFileSearch, AiOutlineLineChart} from 'react-icons/ai';
import {GrPieChart} from 'react-icons/gr';
import {HiCheck, HiOutlineUser} from 'react-icons/hi';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import BillingMethodSection from 'components/payment/BillingMethodSection';
import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {GridSelect, GridSelectOption} from 'components_sb/input';
import BooleanSelect from 'components_sb/input/GridSelect/BooleanSelect';
import {OnlyAvailableOnWeb} from 'components_sb/miscellaneous';
import {FieldLabel} from 'components_sb/typography';
import Paragraph from 'components_sb/typography/Paragraph/Paragraph';
import {SHOW_BILLING_COMMS} from 'globals/app-globals';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'khui/components/Accordion';
import cn from 'khui/utils/cn';
import {ListingRentalApplicationTag} from 'models/listings/ListingRentalApplication';
import RentalApplication from 'models/listings/RentalApplication';
import RentalApplicationApplicant from 'models/listings/RentalApplicationApplicant';
import {
  RentalApplicationApplicantTenant,
  RentalApplicationTenant,
} from 'models/tenant_checks/RequestTypes';
import TenantCheckRequest, {
  TenantCheckType,
} from 'models/tenant_checks/TenantCheckRequest';
import {useCreditCards} from 'providers/CreditCardsProvider';
import {FullScreenLoaderContext} from 'providers/FullScreenLoader';
import PropertyDetailQuery from 'queries/landlord/PropertyDetailQuery';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {sleep} from 'utilities/MiscHelpers';
import {saveResource} from 'utilities/SpraypaintHelpers';

import useListingRentalApplicationQuery from './useListingRentalApplicationQuery';

interface FormValues {
  checkType: TenantCheckType;
  selectedApplicantIndexes: string[];
  performReferenceChecks: boolean;
}

type Applicant = {
  type: typeof RentalApplication | typeof RentalApplicationApplicant;
  id: number;
  name: string;
  email: string;
};

const RequestTenantCheckPage = () => {
  const router = useRouter();
  const fullScreenLoader = useContext(FullScreenLoaderContext);
  const {
    params: {propertyId, listingPublicId, listingRentalApplicationId},
  } = useRoute();

  /**
   * The URL that is redirected to if the rental application is not shortlisted,
   * or upon successfully creating a tenant check request.
   */
  const redirectUrl = useMemo<string>(
    () =>
      `/properties/${propertyId}/listings/${listingPublicId}/applications/${listingRentalApplicationId}`,
    [propertyId, listingPublicId, listingRentalApplicationId],
  );

  const listingRentalApplicationQuery = useListingRentalApplicationQuery({
    id: listingRentalApplicationId,
  });

  const {data: listingRentalApplication} = listingRentalApplicationQuery;

  const propertyQuery = useQuery(
    PropertyDetailQuery.queryKey(propertyId),
    PropertyDetailQuery.queryFn(propertyId),
    {retry: 1, refetchOnWindowFocus: false},
  );

  const {data: property} = propertyQuery;

  const isShortlisted = useMemo<boolean | null>(
    () =>
      listingRentalApplicationQuery.isSuccess
        ? listingRentalApplication.landlordTag ===
          ListingRentalApplicationTag.Shortlisted
        : null,
    [listingRentalApplicationQuery, listingRentalApplication],
  );

  const rentalApplication = listingRentalApplication?.rentalApplication;

  const rentalApplicationApplicants =
    rentalApplication?.rentalApplicationApplicants;

  /**
   * Options for selecting an applicant may either be a reference to a
   * RentalApplication instance or a RentalApplicationApplicant instance.
   * We need to abstract these into a common type.
   */
  const applicants = useMemo<Applicant[]>(
    () =>
      !listingRentalApplicationQuery.isSuccess
        ? []
        : [
            /**
             * The head tenant is always included in the list of applicants.
             */
            {
              type: RentalApplication,
              id: Number(rentalApplication.id),
              name: rentalApplication.headTenantName,
              email: rentalApplication.headTenantEmail,
            },
            /**
             * Append any other applicants from the rental application.
             */
            ...rentalApplicationApplicants.map(({id, name, email}) => ({
              type: RentalApplicationApplicant,
              id: Number(id),
              name: name,
              email: email,
            })),
          ],
    [
      listingRentalApplicationQuery,
      rentalApplication,
      rentalApplicationApplicants,
    ],
  );

  /**
   * Construct options compatible with the GridSelect component for all applicants.
   */
  const applicantOptions = useMemo<GridSelectOption[]>(
    () =>
      applicants.map(({name}, index) => ({
        id: index.toString(),
        label: name,
        icon: HiOutlineUser,
      })),
    [applicants],
  );

  const queryClient = useQueryClient();
  const creditCards = useCreditCards();

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      /**
       * Deconstruct the submitted values from the form.
       */
      const {checkType, selectedApplicantIndexes, performReferenceChecks} =
        formValues;

      /**
       * Ensure that a credit/debit card has been added if required by the check type.
       */
      if (
        ['basic', 'comprehensive'].includes(checkType) &&
        !creditCards.exist
      ) {
        toast.error(
          'Please add a credit/debit card to request the tenant check.',
        );
        return;
      }

      /**
       * Find the applicants that were selected by their index.
       */
      const selectedApplicants = selectedApplicantIndexes.map(
        (applicantIndex) => applicants[Number(applicantIndex)],
      );

      const tenants: (
        | RentalApplicationTenant
        | RentalApplicationApplicantTenant
      )[] = selectedApplicants.map((applicant) => {
        return {
          name: applicant.name,
          email: applicant.email,
          ...(applicant.type === RentalApplication
            ? {
                type: 'RentalApplication',
                rental_application_id: Number(applicant.id),
              }
            : {
                type: 'RentalApplicationApplicant',
                rental_application_applicant_id: Number(applicant.id),
              }),
        };
      });

      const request = new TenantCheckRequest();
      request.tenants = tenants;
      request.checkType = checkType;
      if (checkType === 'basic') {
        request.shouldPerformReferenceChecks = performReferenceChecks;
      }
      request.listingRentalApplicationId = listingRentalApplication.id;
      request.listingId = listingRentalApplication.listingId;

      const success = await saveResource(request);

      if (success) {
        /**
         * It may take a few seconds to create the reports as these are created in
         * a background job, so to avoid a flicker of old data when navigating back
         * to the application detail page, we show a loader for a few seconds
         * before removing the query for the tenant check requests and navigating back.
         */

        fullScreenLoader.activate();
        await sleep(3000);
        queryClient.removeQueries([
          'tenant-check-requests',
          listingRentalApplication.id,
        ]);
        fullScreenLoader.deactivate();
        toast.success("We're on it! Your tenant check is now underway.");
        router.goBack(redirectUrl);
      } else {
        toast.error(
          'Sorry, there was an issue submitting your tenant check request.',
        );
      }
    },
    [
      applicants,
      creditCards,
      queryClient,
      listingRentalApplication,
      router,
      redirectUrl,
      fullScreenLoader,
    ],
  );

  const form = useFormik<FormValues>({
    onSubmit: handleSubmit,
    initialValues: {
      checkType: null,
      selectedApplicantIndexes: [],
      performReferenceChecks: true,
    },
    validationSchema: Yup.object().shape({
      selectedApplicantIndexes: Yup.array().min(
        1,
        'Please select at least one applicant',
      ),
      checkType: Yup.string()
        .nullable()
        .required(
          'Please select the type of tenant check you would like to perform.',
        ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <Page
      title="Request a tenant check"
      error={listingRentalApplicationQuery.isError || propertyQuery.isError}
      loading={
        listingRentalApplicationQuery.isLoading || propertyQuery.isLoading
      }
      redirect={
        listingRentalApplicationQuery.isSuccess && !isShortlisted
          ? redirectUrl
          : undefined
      }>
      {() =>
        !SHOW_BILLING_COMMS ? (
          <OnlyAvailableOnWeb />
        ) : (
          <div className="w-full flex flex-col gap-y-4 max-w-4xl mx-auto">
            <div className="h-56 -mb-12 -mt-16 -ml-5">
              <dotlottie-player
                autoplay
                loop
                mode="normal"
                src="/assets/lottie/tenant-check.lottie"
                style={{height: 224}}
              />
            </div>
            <div className="flex flex-col mb-4">
              <Paragraph>
                Select the applicants you would like to perform a tenant check
                on and the the type of check you would like to perform.
              </Paragraph>
              <Paragraph>
                Keyhook will conduct the check on your behalf and provide you
                with the results once completed.
              </Paragraph>
              <Accordion type="single" collapsible>
                <AccordionItem value="item-1">
                  <AccordionTrigger>
                    Which places are searched?
                  </AccordionTrigger>
                  <AccordionContent className="@container">
                    <ul
                      className={cn(
                        'list-disc list-inside',
                        'grid gap-4',
                        'grid-cols-1',
                        '@md:grid-cols-2',
                        '@xl:grid-cols-3',
                        '@3xl:grid-cols-4',
                      )}>
                      <li>Tenancy Tribunal</li>
                      <li>Insolvency Register</li>
                      <li>Companies Office</li>
                      <li>New Zealand Police</li>
                      <li>Interpol</li>
                      <li>NZ Parole Board</li>
                      <li>NZ Court Judgments</li>
                      <li>Obituaries</li>
                      <li>NZ Herald</li>
                      <li>Newshub</li>
                      <li>1News</li>
                      <li>Stuff</li>
                      <li>Google</li>
                      <li>Bing</li>
                      <li>Facebook</li>
                      <li>LinkedIn</li>
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
            <GridSelect
              mode="formik"
              form={form}
              name="selectedApplicantIndexes"
              multiple
              labelProps={{
                title: 'Select applicants',
                size: 'base',
              }}
              options={applicantOptions}
            />

            <div className="flex flex-col">
              <GridSelect
                mode="formik"
                form={form}
                name="checkType"
                multiple={false}
                labelProps={{
                  title: 'Select a check type',
                  size: 'base',
                }}
                minColumnWidth={300}
                options={[
                  {
                    id: 'free',
                    label: 'Basic background check',
                    description:
                      'Search public databases, social channels and web search results.',
                    icon: AiOutlineFileSearch,
                    suffix: 'FREE',
                  },
                  {
                    id: 'basic',
                    label: 'Credit report',
                    description:
                      'Full Centrix credit report plus basic background check.',
                    icon: AiOutlineLineChart,
                    suffix: '$30',
                  },
                  {
                    id: 'comprehensive',
                    label: 'Comprehensive report',
                    description: `Over-the-phone reference checks performed by a professional who compiles a comprehensive report on your behalf. Includes credit report and background check. Please allow 48 hours for over-the-phone reference checks to be completed.`,
                    icon: GrPieChart,
                    suffix: '$60',
                  },
                ]}
              />

              {/* Reference checks */}
              <UnmountClosed isOpened={form.values.checkType === 'basic'}>
                <div className="pt-4">
                  <BooleanSelect
                    mode="formik"
                    form={form}
                    name="performReferenceChecks"
                    labelProps={{
                      title:
                        'Would you like us to send email reference checks (at no extra cost)?',
                      size: 'base',
                    }}
                    preset="yes/no"
                  />
                </div>
              </UnmountClosed>

              {/* Payment details */}
              <UnmountClosed
                isOpened={['basic', 'comprehensive'].includes(
                  form.values.checkType,
                )}>
                <div className="pt-4">
                  <div className="flex flex-col">
                    <FieldLabel title="Payment method" size="lg" />
                    {propertyQuery.isRefetching ? (
                      <SpinningLoader size="base" color="brand" />
                    ) : (
                      <BillingMethodSection hideTitle property={property} />
                    )}
                    <Paragraph size="sm" secondary>
                      Your credit card will be charged for any applicable fees.
                      GST is excluded from the prices above.
                    </Paragraph>
                    <Paragraph size="sm" secondary>
                      You will be refunded for any tenant check that is unable
                      to be completed.
                    </Paragraph>
                  </div>
                </div>
              </UnmountClosed>
            </div>
            <div className="max-w-auto md:max-w-xs">
              <Button
                label={
                  form.values.selectedApplicantIndexes?.length > 1
                    ? 'Request tenant checks'
                    : 'Request tenant check'
                }
                icon={HiCheck}
                category="primary"
                size="base"
                fillWidth
                mode="formik"
                form={form}
              />
            </div>
          </div>
        )
      }
    </Page>
  );
};

export default RequestTenantCheckPage;
