import {useCallback, useMemo} from 'react';

import {sortBy} from 'lodash';
import {HiCheckCircle, HiMinusCircle, HiOutlineShare} from 'react-icons/hi';
import {useQuery} from 'react-query';
import accents from 'remove-accents';
import {PersistedSpraypaintRecord} from 'spraypaint';

import ShareListingToFacebookGroupModal from 'components/modals/ShareListingToFacebookGroupModal';
import {Button} from 'components_sb/buttons';
import {SpinningLoader} from 'components_sb/feedback';
import {Card, Modal, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import FacebookGroup from 'models/listings/FacebookGroup';
import Listing from 'models/listings/Listing';

const {
  Table,
  TableHead,
  TableHeadItem,
  TableRow,
  TableRowSeparator,
  TableRowItem,
} = TableComponents;

const {useModal} = Modal.Imperative;

interface FacebookGroupsCardProps {
  listing: Listing;
}

interface RegionsLooselyEqualFunction {
  (regionA: string, regionB: string): boolean;
}

/**
 * Checks if two strings indicating a region are considered
 * to be equal based on a loose comparison:
 * - Ignores casing
 * - Ignores character accents
 * - Checks if there is overlap between the strings:
 *    (i.e. to match "Wellington" to "Wellington/Wairarapa")
 */
const regionsLooselyEqual: RegionsLooselyEqualFunction = (regionA, regionB) => {
  /**
   * Equal if both regions are null.
   */
  if (!regionA && !regionB) return true;

  /**
   * Can't be equal if one of the values is null.
   */
  if (!regionA || !regionB) return false;

  /**
   * Removes accents from a region string and converts
   * to lowercase.
   */
  const simplify = (region: string) => accents.remove(region).toLowerCase();

  /**
   * Simplify each of the input strings.
   */
  const simplified = {
    regionA: simplify(regionA),
    regionB: simplify(regionB),
  };

  /**
   * Regions are loosely equal if the simplified strings
   * have any overlap.
   */
  return (
    simplified.regionA.includes(simplified.regionB) ||
    simplified.regionB.includes(simplified.regionA)
  );
};

interface RegionalFacebookGroups {
  0: string;
  1: PersistedSpraypaintRecord<FacebookGroup>[];
}

const FacebookGroupsCard = ({listing}: FacebookGroupsCardProps) => {
  const openModal = useModal();

  const {
    data: facebookGroups,
    isLoading,
    isSuccess,
  } = useQuery(
    'fetch-facebook-groups',
    async () => (await FacebookGroup.per(1000).all()).data,
  );

  /**
   * Collate the regions that have Facebook groups available.
   */
  const regions = useMemo<string[]>(
    () =>
      !isSuccess
        ? []
        : facebookGroups.reduce(
            (accumulator, {region}) =>
              region && !accumulator.includes(region)
                ? [...accumulator, region]
                : accumulator,
            [],
          ),

    [isSuccess, facebookGroups],
  );

  /**
   * Group each of the Facebook groups by their region.
   */
  const facebookGroupsByRegion = useMemo<RegionalFacebookGroups[]>(() => {
    /**
     * Return an empty array if the groups haven't been
     * fetched yet.
     */
    if (!isSuccess) {
      return [];
    }

    /**
     * First we get all of the applicable groups for
     * the defined regions.
     */
    let regionalGroups = regions.reduce<RegionalFacebookGroups[]>(
      (accumulator, region) => [
        ...accumulator,
        [
          region,
          facebookGroups.filter((group) =>
            regionsLooselyEqual(region, group.region),
          ),
        ],
      ],
      [],
    );

    /**
     * Sort the region group sets alphabetically by region name.
     */
    regionalGroups = sortBy(regionalGroups, (regionalGroup) =>
      regionalGroup[0].toLowerCase(),
    );

    /**
     * We want to show the most relevant group first, so we move the region
     * where the property is located to the start if there are available
     * groups for that region.
     */
    const indexOfListingRegion = regionalGroups.findIndex((regionalGroup) =>
      regionsLooselyEqual(regionalGroup[0], listing.region),
    );
    if (indexOfListingRegion !== -1) {
      regionalGroups.unshift(regionalGroups.splice(indexOfListingRegion, 1)[0]);
    }

    /**
     * Finally if there are groups that don't have a region specified, we
     * assume these are nationwide groups. If these exist, we display these
     * within a "nationwide" group below the property's own regional group,
     * as these are considered to be the second-most relevant.
     */
    const groupsWithNoRegion = facebookGroups.filter(({region}) => !region);
    if (groupsWithNoRegion.length > 0) {
      regionalGroups.splice(1, 0, ['Nationwide', groupsWithNoRegion]);
    }

    return regionalGroups;
  }, [isSuccess, listing, regions, facebookGroups]);

  /**
   * Open the modal to assist the user with sharing their
   * listing to a Facebook group.
   */
  const onShare = useCallback(
    (facebookGroup: FacebookGroup) => {
      openModal(ShareListingToFacebookGroupModal, {facebookGroup, listing});
    },
    [openModal, listing],
  );

  return (
    <Card title="Increase your exposure">
      <Paragraph>
        In addition to the platforms where your property has been listed, we
        recommend also sharing your listing with any relevant Facebook groups.
      </Paragraph>
      {/* Loading view */}
      {isLoading && <SpinningLoader color="brand" size="base" />}
      {/* Fetched Facebook groups */}
      {isSuccess && facebookGroups.length > 0 && (
        <>
          <Paragraph>
            Keyhook is unable to post to these groups on your behalf, however we
            have collated a few groups that may be relevant below to post to
            yourself.
          </Paragraph>
          <Paragraph secondary size="sm">
            Please note that Keyhook has no affiliation with these Facebook
            groups and does not control their content or members' actions. Post
            at your own discretion.
          </Paragraph>
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem minWidth={200}>Name</TableHeadItem>
              <TableHeadItem>Members in group</TableHeadItem>
              <TableHeadItem helpText="Some groups require you to request access before posting, however, this is usually a simple and quick process.">
                Requires joining
              </TableHeadItem>
              <TableHeadItem>Actions</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {facebookGroupsByRegion.map((regionalGroup) => {
              const region = regionalGroup[0];
              const groups = regionalGroup[1];
              return (
                <>
                  <TableRowSeparator id={region} title={region} />
                  {groups.map((group) => (
                    <TableRow key={group.id}>
                      {/* Group name */}
                      <TableRowItem>
                        <Hyperlink
                          allowWrap
                          href={`https://www.facebook.com/groups/${group.facebookId}/`}>
                          {group.name}
                        </Hyperlink>
                      </TableRowItem>
                      {/* Total members */}
                      <TableRowItem>
                        {group.totalMembers > 100
                          ? `> ${(
                              Math.round(group.totalMembers / 100) * 100
                            ).toLocaleString()}`
                          : group.totalMembers}
                      </TableRowItem>
                      {/* Private status */}
                      <TableRowItem>
                        {group.private ? (
                          <HiCheckCircle className="text-brand-500 w-6 h-6" />
                        ) : (
                          <HiMinusCircle className="text-brand-100 w-6 h-6" />
                        )}
                      </TableRowItem>
                      {/* Action */}
                      <TableRowItem>
                        <Button
                          label="Share to group"
                          icon={HiOutlineShare}
                          category="primary"
                          size="sm"
                          mode="manual"
                          onClick={() => onShare(group)}
                        />
                      </TableRowItem>
                    </TableRow>
                  ))}
                </>
              );
            })}
          </Table>
        </>
      )}
    </Card>
  );
};

export default FacebookGroupsCard;
