import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {f7, f7ready} from 'framework7-react';
import {Router} from 'framework7/types';

import {TARGET_ENV} from 'globals/app-globals';

interface RouterProviderProps {
  children: ReactNode;
}

interface ModifiedBackFunction {
  (url?: string, options?: Omit<Router.RouteOptions, 'force'>): void;
}

/**
 * Modify the Framework7 router type to include the modified back function.
 */
export type ModifiedRouter = Exclude<Router.Router, 'back'> & {
  goBack: ModifiedBackFunction;
};

interface ContextValue {
  router: ModifiedRouter;
  route: Router.Route;
}

export const RouterContext = createContext<ContextValue>(null);

const RouterProvider: FunctionComponent<RouterProviderProps> = ({children}) => {
  const [router, setRouter] = useState<Router.Router>();
  const [route, setRoute] = useState<Router.Route>();

  useEffect(() => {
    f7ready(() => {
      const {router} = f7.views.main;
      setRouter(router);
      setRoute(router.currentRoute);
      router.on('routeChange', () => {
        setRoute(router.currentRoute);
      });
      return () => {
        router.off('routeChange');
      };
    });
  }, []);

  /**
   * Due to issues when setting the 'force' parameter on the router.back()
   * and the specified path or URL already being the same as the previous
   * in history, we need to modify the Framework7 router's back function
   * to intercept the call and check if the path is the same. If it is,
   * then we don't need to set the 'href' and 'force' parameters.
   */
  const handleBack = useCallback<ModifiedBackFunction>(
    (url = undefined, options = {}) => {
      /**
       * For some reason, the previousPath will still be present even when
       * calling clearPreviousHistory on the Framework7 router. So to check
       * that history has not been cleared and that there is a valid path to
       * go back to, we also check that the history length is greater than 1.
       */
      const hasPreviousHistory = router.history.length > 1;

      const destinationIsPreviousInHistory =
        !url ||
        (hasPreviousHistory &&
          router.history[router.history.length - 2] === url);

      /**
       * Navigate back to the previous route in history.
       */
      if (destinationIsPreviousInHistory) {
        if (TARGET_ENV === 'development') {
          console.info('Navigating back to previous route in history.');
        }
        /**
         * We can ignore this lint error because this is the only place where
         * we should intentionally be calling router.back.
         */
        // eslint-disable-next-line no-restricted-syntax
        router.back(undefined, options);
        return;
      }

      /**
       * Force backward navigation to the specified path or URL.
       */
      if (TARGET_ENV === 'development') {
        console.info('Force navigating back to:', url);
      }
      /**
       * We can ignore this lint error because this is the only place where
       * we should intentionally be calling router.back.
       */
      // eslint-disable-next-line no-restricted-syntax
      router.back(url, {
        ...options,
        force: true,
      });
    },
    [router],
  );

  /**
   * Construct the modified router by replacing the back function
   * in the Framework7 router.
   */
  const modifiedRouter = useMemo<ModifiedRouter | null>(() => {
    if (!router) {
      return null;
    }
    const modified = router as ModifiedRouter;
    modified.goBack = handleBack;
    return modified;
  }, [router, handleBack]);

  const value = useMemo<ContextValue>(
    () => ({
      router: modifiedRouter,
      route,
    }),
    [modifiedRouter, route],
  );

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};

export default RouterProvider;
