import React, {useCallback, useEffect, useMemo, useState} from 'react';

import camelcaseKeys from 'camelcase-keys';
import clsx from 'clsx';
import {pick} from 'lodash';
import {useQuery} from 'react-query';
import {StringParam, useQueryParams} from 'use-query-params';

import useAuth from 'auth/provider/useAuth';
import {userDataIsValidShape} from 'auth/types/UserData';
import {ContentError} from 'components_sb/layout';
import {API_URL} from 'globals/app-globals';
import {Navigate, Page} from 'router/components';

const GhostPage = () => {
  /**
   * Deconstruct the required data from the query params.
   */
  const [{id, token, email, redirect}] = useQueryParams({
    id: StringParam,
    token: StringParam,
    email: StringParam,
    redirect: StringParam,
  });

  /**
   * Determine whether the required query params are present.
   */
  const requiredParamsPresent = useMemo(
    () => !!id && !!token && !!email,
    [id, token, email],
  );

  /**
   * Get required data/functions from the auth context.
   */
  const {currentUser, isLoggedIn, logout, ghostUser} = useAuth();

  /**
   * Track whether we are currently logging out an existing user.
   */
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  /**
   * Handle logging out a user.
   */
  const handleLogout = useCallback(async () => {
    setIsLoggingOut(true);
    await logout();
    setIsLoggingOut(false);
  }, [logout]);

  /**
   * If there is an existing user logged in (who's email does not match
   * the email provided in the query params), log them out.
   */
  useEffect(() => {
    if (isLoggedIn && currentUser.email !== email && !isLoggingOut) {
      handleLogout();
    }
  }, [isLoggedIn, currentUser, email, isLoggingOut, handleLogout]);

  /**
   * Perform an authentication request for the user to be ghosted using the
   * data provided as query parameters, once any existing user has been logged out.
   */
  const {
    data: userData,
    error,
    isSuccess: loadedUserData,
    isError: errorLoadingUserData,
  } = useQuery(
    ['ghost-user', {userId: id}],
    async () => {
      const response = await (
        await fetch(`${API_URL}/users/${id}.json`, {
          method: 'GET',
          headers: {
            'X-USER-EMAIL': email,
            'X-USER-TOKEN': token,
          },
        })
      ).json();

      if (response.error) {
        throw new Error(response.error);
      }

      let parsedUserData = camelcaseKeys(
        pick(response.data, [
          'id',
          'email',
          'name',
          'unconfirmed_email',
          'avatar',
          'roles',
          'confirmed',
        ]),
      );

      /**
       * Since the confirmed attribute is returned as a string, we need
       * to parse it to a boolean.
       */
      parsedUserData = {
        ...parsedUserData,
        confirmed:
          'confirmed' in parsedUserData && parsedUserData.confirmed === 'true',
      };

      if (!userDataIsValidShape(parsedUserData)) {
        throw new Error('User data is an invalid shape');
      }

      return parsedUserData;
    },
    {enabled: !isLoggedIn && requiredParamsPresent},
  );

  /**
   * Track whether we are currently attempting to ghost the user (after any
   * log out of an existing user has been performed and user data has been fetched).
   */
  const [isAttemptingToGhost, setIsAttemptingToGhost] =
    useState<boolean>(false);

  /**
   * Attempt to ghost the user with the returned user data.
   */
  useEffect(() => {
    if (loadedUserData && !isAttemptingToGhost) {
      setIsAttemptingToGhost(true);
      ghostUser({
        authResult: {
          userData,
          authToken: token,
        },
        redirect,
      });
    }
  }, [
    loadedUserData,
    userData,
    token,
    redirect,
    ghostUser,
    isAttemptingToGhost,
  ]);

  /**
   * Navigate to the index page if requires query params are not present.
   */
  if (!requiredParamsPresent) {
    return <Navigate href="/" />;
  }

  return (
    <Page
      title="Ghosting user..."
      disableBack
      centred
      loading={!errorLoadingUserData}>
      {() => (
        <div>
          <ContentError message="Error ghosting user" />
          <div
            className={clsx(
              'w-full bg-red-100 border-2 border-red-400 text-red-500',
              'p-4 rounded-lg',
            )}>
            {error.toString()}
          </div>
        </div>
      )}
    </Page>
  );
};

export default GhostPage;
