import {useEffect} from 'react';

import {Capacitor} from '@capacitor/core';
import {isMobile} from 'react-device-detect';
import {useQueryClient} from 'react-query';

import ListingCard from 'components/listing/ListingCard/ListingCard';
import StepContainer from 'components/walkthrough/common/StepContainer';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

const ListingPreviewStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent listing for the property.
   */
  const listing = useMostRecentlyCreated(property.listings);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_StartPreviewListingStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );
  }, [property.id, listing.id, scrollToTop]);

  const queryClient = useQueryClient();

  const onClickNext = async () => {
    // TODO: Set submitting state here

    /**
     * Set changes on the property.
     */
    property.lastOnboardingStepCompleted = 'listing_preview';

    /**
     * Save the changes to the property.
     */
    if (!(await saveResource(property))) {
      return;
    }

    /**
     * Track completion of the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.ListProperty_CompletePreviewListingStep,
      {
        propertyId: property.id,
        listingId: listing.id,
      },
    );

    /**
     * Update the property data in the query cache.
     */
    queryClient.setQueryData(
      ['property', {id: property.id, context: 'onboarding-flow'}],
      property,
    );
  };

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
      },
    },
  });

  return (
    <StepContainer
      title="Congratulations!"
      subtitle={`Time to look everything over. ${
        isMobile ? 'Tap' : 'Click'
      } the card to preview your listing...`}>
      <div className="flex flex-col w-full">
        <ListingCard listing={listing} />
      </div>
    </StepContainer>
  );
};

export default ListingPreviewStep;
