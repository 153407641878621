import {FunctionComponent, useCallback, useMemo} from 'react';

import {FormikProps, useFormik} from 'formik';
import {HiOutlineCheck, HiOutlineX} from 'react-icons/hi';
import {IconType} from 'react-icons/lib';

import GridSelect, {GridSelectProps} from './GridSelect';

/**
 * A set of labels representing the true and false values.
 */
interface LabelSet {
  true: string;
  false: string;
}

/**
 * A set of icons representing the true and false values.
 */
interface IconSet {
  true: IconType;
  false: IconType;
}

interface PresetDefinitions {
  [key: string]: {
    labels: LabelSet;
    icons: IconSet;
  };
}

/**
 * Presets for some typical uses of the BooleanSelect component.
 */
const PRESET_DEFINITIONS: PresetDefinitions = {
  'yes/no': {
    labels: {
      true: 'Yes',
      false: 'No',
    },
    icons: {
      true: HiOutlineCheck,
      false: HiOutlineX,
    },
  },
};

/**
 * We allow some GridSelect props to be passed through to the
 * GridSelect component - other props are handled by this wrapper.
 */
type GridSelectPassthroughProps = 'size' | 'disableTicks' | 'labelProps';

/**
 * Conditional props depending on whether the labels are defined
 * explicitly or a preset (only one or the other can be provided).
 */
type BooleanSelectConditionalLabelProps =
  | {
      preset: keyof PresetDefinitions;
      labels?: never;
      icons?: never;
    }
  | {
      preset?: never;
      labels: LabelSet;
      icons?: IconSet;
    };

/**
 * Conditional props depending on whether the mode is
 * manual or Formik.
 */
type BooleanSelectConditionalModeProps =
  | {
      mode: 'manual';
      name?: string;
      value: boolean | null;
      onChange: (value: boolean) => void;
      form?: never;
    }
  | {
      mode: 'formik';
      name: string;
      value?: never;
      onChange?: never;
      form: ReturnType<typeof useFormik> | FormikProps<any>;
    };

/**
 * Passthrough props combined with the wrapper-specific props.
 */
type BooleanSelectProps = Pick<GridSelectProps, GridSelectPassthroughProps> &
  BooleanSelectConditionalLabelProps &
  BooleanSelectConditionalModeProps;

/**
 * A field for selecting a boolean.
 *
 * This component is a wrapper around the GridSelect component for
 * simplification when only a boolean value is required.
 */
const BooleanSelect: FunctionComponent<BooleanSelectProps> = ({
  mode,
  name,
  preset,
  labels,
  icons,
  value,
  onChange,
  form,
  ...passthroughProps
}) => {
  /**
   * Convert the boolean value to a string for compatibility
   * with the GridSelect component.
   */
  const stringValue = useMemo(() => {
    /**
     * If we're in Formik mode, we obtain the value from the form, otherwise
     * for manual mode, we use the value provided in props.
     */
    const fieldValue = mode === 'formik' ? form.values[name] : value;

    if (typeof fieldValue !== 'boolean') {
      return null;
    } else {
      return fieldValue ? 'true' : 'false';
    }
  }, [form, mode, name, value]);

  /**
   * Handle changes to the string value.
   */
  const handleChange = useCallback(
    (value: string) => {
      /**
       * Convert the string to a boolean value.
       */
      const booleanValue = value === 'true';

      /**
       * If we're in Formik mode, we set the new value on the form, otherwise
       * for manual mode, we call the onChange prop provided in props.
       */
      if (mode === 'formik') {
        form.setFieldValue(name, booleanValue);
      } else {
        onChange(booleanValue);
      }
    },
    [form, mode, name, onChange],
  );

  /**
   * Define the labels for the true and false values.
   * (required if no preset is chosen)
   */
  const labelSet = useMemo<LabelSet>(
    () => (preset ? PRESET_DEFINITIONS[preset].labels : labels),
    [preset, labels],
  );

  /**
   * Define the icons for the true and false values.
   */
  const iconSet = useMemo<IconSet>(() => {
    if (preset) {
      return PRESET_DEFINITIONS[preset].icons;
    } else if (icons) {
      return icons;
    } else {
      /**
       * Use the yes/no preset icons if neither a preset
       * or icons are provided.
       */
      return PRESET_DEFINITIONS['yes/no'].icons;
    }
  }, [preset, icons]);

  return (
    <div className="flex-1 max-w-2xl">
      <GridSelect
        {...passthroughProps}
        mode="manual:select"
        error={mode === 'formik' ? form.errors[name] : undefined}
        multiple={false}
        minColumns={2}
        maxColumns={2}
        size="base"
        disableTicks={true}
        options={[
          {
            id: 'true',
            label: labelSet.true,
            icon: iconSet.true,
          },
          {
            id: 'false',
            label: labelSet.false,
            icon: iconSet.false,
          },
        ]}
        value={stringValue}
        onChange={handleChange}
      />
    </div>
  );
};

export default BooleanSelect;
