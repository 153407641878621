import React, {useState} from 'react';

import moment from 'moment';
import {useQuery, useQueryClient} from 'react-query';

import useAuth from 'auth/provider/useAuth';
import UserAvatar from 'components/user/UserAvatar';
import {Button} from 'components_sb/buttons';
import {TextAreaField} from 'components_sb/input';
import {Card} from 'components_sb/layout';
import EnquiryMessage from 'models/listings/EnquiryMessage';
import EnquiryThread from 'models/listings/EnquiryThread';
import User from 'models/users/User';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';

const EnquiryThreadDetailPage = () => {
  const {
    params: {enquiryThreadId},
  } = useRoute();

  const queryClient = useQueryClient();

  const {currentUser} = useAuth();
  const [messageText, setMessageText] = useState('');
  const [isSendingNewMessage, setIsSendingNewMessage] = useState(false);

  const {data, error, isLoading} = useQuery(
    `enquiry-${enquiryThreadId}`,
    async () => {
      const thread = await EnquiryThread.includes([
        {
          enquiry_messages: 'user',
        },
        'listing',
      ]).find(enquiryThreadId);

      return thread.data;
    },
  );

  const sendMessage = async () => {
    setIsSendingNewMessage(true);

    const message = new EnquiryMessage({
      userId: currentUser.id,
      message: messageText,
      enquiryThreadId: enquiryThreadId,
    });

    const result = await message.save();
    if (result) {
      setMessageText('');

      const thread = data;
      message.user = new User({
        id: currentUser.id,
        name: currentUser.name,
        avatar: currentUser.avatar,
      });
      message.user.isPersisted = true;
      data.enquiryMessages.push(message);

      queryClient.setQueryData(`enquiry-${enquiryThreadId}`, thread);

      /**
       * Indicate a reload is required for the enquiry threads list page.
       */
      queryClient.invalidateQueries('renter-listing-enquiries');
    }

    setIsSendingNewMessage(false);
  };

  return (
    <Page title="Enquiry" loading={isLoading} error={error}>
      {() => (
        <>
          {data.enquiryMessages.map((msg) => (
            <Card key={msg.id} className="mb-4">
              {msg.user ? (
                <div className="flex justify-start items-center">
                  <div>
                    <UserAvatar user={msg.user} size="12" />
                  </div>
                  <div className="ml-4">
                    {msg.user.id === currentUser.id ? (
                      <h2 className="card-title mb-0 text-brand-850">You</h2>
                    ) : (
                      <h2 className="card-title mb-0 text-brand-850">
                        {msg.user.name}
                      </h2>
                    )}

                    <span className="tw-block text-secondary text-sm">
                      {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                    </span>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="card-title mb-0 text-brand-850">{msg.name}</h2>
                  <span className="tw-block text-secondary text-sm">
                    {msg.email}
                  </span>
                  <span className="tw-block text-secondary text-sm">
                    {moment(msg.createdAt).format(DATE_TIME_FORMAT)}
                  </span>
                </div>
              )}

              {msg.message.split('\n').map((text, index) => (
                <p className="mt-1" key={index}>
                  {text}
                </p>
              ))}
            </Card>
          ))}

          <Card title="Reply">
            <div className="flex justify-between items-center gap-x-4 mt-4">
              <div className="grow">
                <TextAreaField
                  name="message"
                  mode="manual"
                  onChange={(e) => setMessageText(e.currentTarget.value)}
                  value={messageText}
                  disabled={isSendingNewMessage}
                  placeholder="Write a message..."
                  onClick={undefined}
                />
              </div>

              <div className="grow-0">
                <Button
                  label="Send"
                  loadingLabel="Sending"
                  loading={isSendingNewMessage}
                  onClick={sendMessage}
                  category="primary"
                  mode="manual"
                  size="base"
                  fillWidth={false}
                />
              </div>
            </div>
          </Card>
        </>
      )}
    </Page>
  );
};

export default EnquiryThreadDetailPage;
