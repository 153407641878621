import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {isMobile} from 'react-device-detect';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {Base64FileField} from 'components/forms_fields';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {Alert} from 'components_sb/feedback';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import useScroll from 'hooks/useScroll';
import Document from 'models/properties/Document';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

type FormValues = {
  document: string;
};

const HealthyHomesStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartHealthyHomesStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  const existingDoc = property.documents.find(
    (d) => d.documentType === 'healthy_homes_report',
  );

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      let result = false;
      let doc: Document;

      if (existingDoc && formValues.document.length === 0) {
        result = true;
      } else if (formValues.document.length === 0) {
        actions.setFieldError('document', 'Please select a file');
      } else {
        doc = new Document(formValues);
        doc.documentableType = 'Property';
        doc.documentableId = property.id;
        doc.documentType = 'healthy_homes_report';

        result = await doc.save();
      }

      if (result) {
        /**
         * Set changes on the property.
         */
        property.lastOnboardingStepCompleted = 'new_healthy_homes';

        /**
         * Save the changes to the property.
         */
        if (!(await saveResource(property))) {
          setSubmitting(false);
          return;
        }

        // Add the document to the property.
        // So thats its available in the query cache, if the user navigates back to this step.
        if (doc) {
          if (property.documents.findIndex((d) => d.id === doc.id) === -1) {
            property.documents.push(doc);
          }
        }

        /**
         * Update the property data in the query cache.
         */
        queryClient.setQueryData(
          ['property', {id: property.id, context: 'onboarding-flow'}],
          property,
        );

        /**
         * Track completion of the step.
         */
        TrackingService.trackEvent(
          TrackingService.Event.NewTenancy_CompleteHealthyHomesStep,
          {
            propertyId: property.id,
          },
        );
      }

      setSubmitting(false);
    },
    [existingDoc, property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {document: ''} as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        document: Yup.string().label('Healthy Homes File'),
      }),
    }),
    [handleSubmit],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      title="Healthy Homes compliance"
      subtitle="Upload your Healthy Homes compliance report.">
      {existingDoc && (
        <Alert
          type="success"
          title="You've already uploaded a Healthy Homes compliance report"
          description="You can replace your existing report by uploading a new one below."
        />
      )}

      <div>
        <Base64FileField
          formik={form}
          name="document"
          labelProps={{
            title: 'Healthy Homes compliance report',
            description: 'Please upload a PDF file',
            size: 'xl',
          }}
          accept=".pdf"
        />
      </div>
      <Paragraph>
        New Zealand law requires a report detailing compliance with the Healthy
        Homes Act to be included with any new tenancy agreement.
      </Paragraph>
      <Paragraph>
        {`You can download a Health Homes report by `}
        <Hyperlink
          href="https://www.tenancy.govt.nz/assets/forms-templates/compliance-statement.pdf"
          openInNewTab>
          {`${isMobile ? 'tapping' : 'clicking'} here`}
        </Hyperlink>
        {`.`}
      </Paragraph>
    </StepContainer>
  );
};

export default HealthyHomesStep;
