import {useMemo} from 'react';

import {UseFloatingReturn} from '@floating-ui/react';
import clsx from 'clsx';
import {motion, AnimatePresence} from 'framer-motion';

import {Action, actionIsButton, actionIsLink} from 'types/actions';
import getLinkComponent from 'utilities/getLinkComponent';

interface FloatingActionItem {
  (props: {action: Action}): JSX.Element;
}

const FloatingActionItem: FloatingActionItem = ({action}) => {
  const {label, icon: Icon} = action;

  const classes = clsx(
    'flex flex-row items-center gap-x-2',
    'text-brand-500 text-sm font-medium',
    'rounded-lg bg-transparent px-4 py-3',
    'transition-all duration-300',
    'hover:bg-brand-50 active:bg-brand-75',
    'cursor-pointer',
    'whitespace-nowrap',
  );

  const content = useMemo(
    () => (
      <>
        {Icon && <Icon className="w-5 h-5" />}
        {label}
      </>
    ),
    [Icon, label],
  );

  if (actionIsButton(action)) {
    return (
      <button
        data-testid={action.testId}
        className={classes}
        onClick={action.onClick}>
        {content}
      </button>
    );
  }

  if (actionIsLink(action)) {
    const LinkComponent = getLinkComponent(action.linkTo);
    return (
      <LinkComponent
        data-testid={action.testId}
        className={classes}
        href={action.linkTo}>
        {content}
      </LinkComponent>
    );
  }

  /**
   * The action shape was not valid.
   */
  return null;
};

interface FloatingActions {
  (props: {
    floating: UseFloatingReturn;
    isOpen: boolean;
    onClose: () => void;
    actions: Action[];
  }): JSX.Element;
}

const FloatingActions: FloatingActions = ({
  floating,
  isOpen,
  onClose,
  actions,
  ...props
}) => {
  const {x, y, strategy, refs} = floating;
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={refs.setFloating}
          onClick={onClose}
          className={clsx(
            'z-50',
            'rounded-xl drop-shadow-2xl',
            'bg-white p-2',
            'flex flex-col',
            'pointer-events-auto',
          )}
          transition={{
            type: 'spring',
            duration: 0.6,
          }}
          initial={{opacity: 0, y: -10}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -10}}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...props}>
          {actions.map((action) => (
            <FloatingActionItem key={action.label} action={action} />
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FloatingActions;
