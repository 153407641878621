import {Router} from 'framework7/types';

import EditRentalApplicationApplicantPage from 'pages/shared/applicants/EditRentalApplicationApplicantPage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/applicants',
    async: resolveRoute({conditions: [NEVER]}, {redirect: '/'}),
    routes: [
      {
        path: ':publicAccessToken',
        id: 'EditRentalApplicationApplicantPage',
        async: resolveRoute({component: EditRentalApplicationApplicantPage}),
      },
    ],
  },
];

export default routes;
