import {Router} from 'framework7/types';

import PublicListingDetailPage from 'pages/shared/listings/detail';
import PublicListingsHomePage from 'pages/shared/listings/home';
import PublicListingSearchPage from 'pages/shared/listings/search';
import resolveRoute from 'router/utils/route-resolver';

const routes: Router.RouteParameters[] = [
  {
    path: '/listings',
    id: 'PublicListings',
    async: resolveRoute({component: PublicListingsHomePage}),
    routes: [
      {
        path: 'search',
        id: 'PublicListingSearch',
        async: resolveRoute({component: PublicListingSearchPage}),
      },
      {
        path: ':listingPublicId',
        id: 'PublicListing',
        async: resolveRoute({component: PublicListingDetailPage}),
      },
    ],
  },
];

export default routes;
