import {useMemo} from 'react';

import moment from 'moment';
import {MdEditDocument} from 'react-icons/md';

import {Card} from 'components_sb/layout';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import {Action} from 'types/actions';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

export const statusForRenewal = (renewal: TenancyContinuation): string => {
  let status = '';

  if (!renewal.tenantChosenAction) {
    let prestatus = '';
    if (renewal.landlordRequestedAction === 'go_periodic') {
      prestatus =
        'You have chosen to let this tenancy become a periodic tenancy after its end date. ';
    } else if (renewal.landlordRequestedAction === 'start_new_lease') {
      prestatus = `You have opted to start a new lease with an end date of ${moment(
        renewal.endDate,
      ).format('DD MMM YYYY')}. `;
    }
    status =
      'Your tenant is yet to respond to your offer. You will receive a notification once they have.';

    if (prestatus) {
      status = prestatus + status;
    }
  } else {
    if (renewal.landlordRequestedAction === 'go_periodic') {
      if (renewal.tenantChosenAction === 'go_periodic') {
        status = 'The head tenant agreed with your decision to go periodic.';
      } else {
        status = 'The head tenant has not acted on your decision yet.';
      }
    } else if (renewal.landlordRequestedAction === 'start_new_lease') {
      if (renewal.tenantChosenAction === 'start_new_lease') {
        if (renewal.allTenantsSigned) {
          status = `All tenants have agreed and signed, this tenancy will renew after the end date until ${moment(
            renewal.endDate,
          ).format('DD MMM YYYY')}.`;
        } else {
          status =
            'The head tenant has accepted your decision and we are currently waiting for the other tenants to sign.';
        }
      } else {
        status =
          'The tenants have chosen to not renew for a fixed term and instead go periodic.';
      }
    }
  }

  return status;
};

export const RenewalStatusCard = ({
  renewal,
}: {
  renewal: TenancyContinuation;
}) => {
  const title = titleize(removeUnderscores(renewal.landlordRequestedAction));
  const status = statusForRenewal(renewal);

  const cardActions = useMemo<Action[]>(() => {
    if (renewal) {
      if (!renewal.allTenantsSigned) {
        return [
          {
            label: 'Edit tenancy renewal',
            linkTo: `tenancies/${renewal.tenancyId}/tenancy-renewals/${renewal.id}/edit`,
          },
        ];
      }
    }

    return [];
  }, [renewal]);

  return (
    <Card
      title={`Renewal Option: ${title}`}
      className="mt-8"
      actions={cardActions}
      icon={MdEditDocument}>
      <p className="mb-2">{status}</p>
    </Card>
  );
};
