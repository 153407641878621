import React, {useCallback, useEffect, useMemo} from 'react';

import {Capacitor} from '@capacitor/core';

import ActionsList from 'components/inspection/actions/ActionsList';
import AddInspectionItemModal from 'components/inspection/AddInspectionItemModal';
import InspectionAddActionModal from 'components/inspection/InspectionAddActionModal';
import InspectionAddNoteModal from 'components/inspection/InspectionAddNoteModal';
import InspectionAddPhotosModal from 'components/inspection/InspectionAddPhotosModal';
import ItemOptionsDropdown from 'components/inspection/ItemOptionsDropdown';
import InspectionItemTitleWithCounts from 'components/inspection/items/InspectionItemTitleWithCounts';
import MediaItemGrid from 'components/inspection/media_items/MediaItemGrid';
import NoteList from 'components/inspection/notes/NoteList';
import {Card, Modal} from 'components_sb/layout';
import useInspectionQueries from 'hooks/useInspectionQueries';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import InspectionItem from 'models/inspections/InspectionItem';
import {AccountRole} from 'models/users/User';
import {Navigate, Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import useInspectionStore from 'stores/InspectionStore';
import {Action} from 'types/actions';

const {useModal} = Modal.Imperative;

const InspectionRoomDetailPage = () => {
  const {
    params: {tenancyId, propertyId, inspectionId, roomKey},
  } = useRoute();

  const decodedRoomKey = decodeURIComponent(roomKey);
  const router = useRouter();

  const inspectionQueries = useInspectionQueries(inspectionId);

  const openModal = useModal();

  const {activeAccountRole} = useLocalUserSettings();

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const isMobile = !useTailwindBreakpoint('sm');

  const [
    inspection,
    setInspection,
    inspectionItems,
    setInspectionItems,
    selectedRoomKey,
    setSelectedRoomKey,
  ] = useInspectionStore((state) => [
    state.inspection,
    state.setInspection,
    state.inspectionItems,
    state.setInspectionItems,
    state.selectedRoomKey,
    state.setSelectedRoomKey,
  ]);

  useEffect(() => {
    if (!selectedRoomKey) {
      setSelectedRoomKey(decodedRoomKey);
    }
  }, [roomKey, decodedRoomKey, selectedRoomKey, setSelectedRoomKey]);

  const roomItems = useMemo(
    () => inspectionItems?.filter((item) => item.room === selectedRoomKey),
    [inspectionItems, selectedRoomKey],
  );

  const renderRoomItem = (item: InspectionItem, index: number) => {
    return (
      <div className="mt-4" key={index}>
        <div className="flex justify-between items-center">
          <InspectionItemTitleWithCounts
            item={item}
            size={isMobile ? 'small' : 'large'}
          />

          <ItemOptionsDropdown
            addPhoto={() => showAddPhotoModal(item)}
            addNote={() => showAddNoteModal(item)}
            addAction={() => showAddActionModal(item)}
            deleteItem={() => confirmDeleteItem(item)}
          />
        </div>

        <MediaItemGrid inspection={inspection} item={item} />

        <NoteList inspection={inspection} item={item} />
        <ActionsList inspection={inspection} item={item} />
      </div>
    );
  };

  const renderBasicItem = (item: InspectionItem, index: number) => {
    return (
      <div key={index}>
        <MediaItemGrid inspection={inspection} item={item} />
      </div>
    );
  };

  const confirmDeleteItem = (item: InspectionItem) => {
    setConfirmationOptions({
      color: 'error',
      title: 'Remove Item',
      message: 'Are you sure you want to remove this item?',
      buttonTitle: 'Remove',
      action: () => deleteItem(item),
    });
  };

  const deleteItem = async (item: InspectionItem) => {
    const id = item.id;
    const result = await item.destroy();

    if (result) {
      const items = inspectionItems?.filter((i) => i.id !== id);
      setInspectionItems(items);
    }
  };

  const finishRoom = async () => {
    const i = inspection;
    if (i && !i.completedRooms.includes(selectedRoomKey)) {
      i.completedRooms.push(selectedRoomKey);

      await i.save();

      setInspection(i);
    }

    const backURL =
      activeAccountRole === AccountRole.Landlord
        ? `/properties/${propertyId}/inspections/${inspectionId}`
        : `/tenancies/${tenancyId}/inspections/${inspectionId}`;

    if (Capacitor.isNativePlatform()) {
      router.goBack(backURL, {
        reloadCurrent: true,
        reloadPrevious: true,
      });
    } else {
      router.goBack(backURL, {
        reloadCurrent: true,
      });
    }
  };

  const showAddPhotoModal = (item: InspectionItem) => {
    openModal(InspectionAddPhotosModal, {inspectionItem: item});
  };

  const showAddNoteModal = (item: InspectionItem) => {
    openModal(InspectionAddNoteModal, {inspectionItem: item});
  };

  const showAddActionModal = (item: InspectionItem) => {
    openModal(InspectionAddActionModal, {inspectionItem: item});
  };

  const showAddItemModal = useCallback(
    () => openModal(AddInspectionItemModal, {orderIndex: roomItems.length + 1}),
    [openModal, roomItems],
  );

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !inspection
        ? null
        : [
            ...(activeAccountRole === AccountRole.Landlord &&
            inspection.status !== 'awaiting_sign_offs' &&
            inspection.status !== 'complete'
              ? [
                  {
                    label: 'Add item',
                    onClick: showAddItemModal,
                  },
                ]
              : []),
          ],

    [activeAccountRole, inspection, showAddItemModal],
  );

  if (inspectionQueries.isSuccess && !inspection) {
    if (activeAccountRole === AccountRole.Renter) {
      return (
        <Navigate
          href={`/tenancies/${tenancyId}/inspections/${inspectionId}`}
        />
      );
    } else {
      return (
        <Navigate
          href={`/properties/${propertyId}/inspections/${inspectionId}`}
        />
      );
    }
  }

  const item = roomItems?.length ? roomItems[0] : null;

  return (
    <Page
      title={selectedRoomKey ?? decodedRoomKey}
      actions={pageActions}
      loading={inspectionQueries.isLoading}>
      {() =>
        inspection?.reportType === 'detailed' ? (
          <div>
            <Card className="mt-4">{roomItems?.map(renderRoomItem)}</Card>
            <button
              type="button"
              className="mt-4 btn btn-block btn-success"
              onClick={finishRoom}>
              Finish Area
            </button>
          </div>
        ) : (
          <div>
            <Card title="Photos and videos">
              {renderBasicItem(item, 0)}

              <div className="flex justify-between items-center">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => showAddPhotoModal(item)}>
                  Add Photos
                </button>
              </div>
            </Card>

            <Card title="Notes">
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={() => showAddNoteModal(item)}>
                  Add Note
                </button>
              </div>

              <NoteList inspection={inspection} item={item} />
            </Card>

            <Card title="Actions">
              <div className="flex justify-between items-center">
                <button
                  type="button"
                  className="btn btn-warning btn-sm"
                  onClick={() => showAddActionModal(item)}>
                  Add Action
                </button>
              </div>

              <ActionsList inspection={inspection} item={item} />
            </Card>

            <button
              type="button"
              className="mt-4 btn btn-block btn-success"
              onClick={finishRoom}>
              Finish Area
            </button>
          </div>
        )
      }
    </Page>
  );
};

export default InspectionRoomDetailPage;
