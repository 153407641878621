import {useMemo} from 'react';

import moment from 'moment';
import {useQuery} from 'react-query';

import useAuth from 'auth/provider/useAuth';
import {Card} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import {Paragraph} from 'components_sb/typography';
import PrivateViewing from 'models/listings/PrivateViewing';
import User from 'models/users/User';
import {Page} from 'router/components';

import PrivateViewingRow from './PrivateViewingRow';

const ViewingsIndexPage = () => {
  const {currentUser} = useAuth();

  const {
    data,
    isLoading: isLoadingOpenHomes,
    error: openHomesError,
  } = useQuery('renter-open-homes', async () => {
    const u = await User.select({
      users: ['id'],
      listings: ['street_address', 'suburb', 'city', 'main_image', 'public_id'],
    })
      .includes({open_homes: 'listing'})
      .order({'open_homes.start_time': 'asc'})
      .find(currentUser.id);

    return u.data;
  });

  /**
   * Fetch the private viewings for the user.
   */
  const {
    data: privateViewings,
    isLoading: isLoadingPrivateViewings,
    error: privateViewingsError,
  } = useQuery(
    `renter-private-viewings`,
    async () =>
      (await PrivateViewing.order('starts_at').includes('listing').all()).data,
  );

  const pageError = useMemo(() => {
    if (openHomesError) {
      return openHomesError;
    } else if (privateViewingsError) {
      return privateViewingsError;
    }
    return undefined;
  }, [openHomesError, privateViewingsError]);

  const isLoading = useMemo(
    () => isLoadingOpenHomes || isLoadingPrivateViewings,
    [isLoadingOpenHomes, isLoadingPrivateViewings],
  );

  const filteredOpenHomes = !data
    ? []
    : data.openHomes.filter((openHome) =>
        moment(openHome.startTime).isAfter(moment()),
      );

  const filteredPrivateViewings = !privateViewings
    ? []
    : privateViewings.filter((privateViewing) =>
        moment(privateViewing.startsAt).isAfter(moment()),
      );

  return (
    <Page
      title="Open Homes / Private Viewings"
      loading={isLoading}
      error={pageError}>
      {() =>
        filteredOpenHomes.length === 0 &&
        filteredPrivateViewings.length === 0 ? (
          <Card title="Open Homes">
            <Paragraph>
              There are no upcoming open homes you have RSVP'd to or private
              viewings that been scheduled for you.
            </Paragraph>
          </Card>
        ) : (
          <>
            {filteredOpenHomes.length > 0 && (
              <Card title="Open Homes">
                <div>
                  {filteredOpenHomes.map((openHome) => {
                    const st = moment(openHome.startTime);
                    const et = moment(openHome.endTime);

                    return (
                      <div
                        key={openHome.id}
                        className="flex justify-between items-center mb-8">
                        <div className="flex justify-start items-center">
                          <div>
                            <strong>
                              {st.format('dddd DD MMMM hh:mm a')} -{' '}
                              {et.format('hh:mm a')}
                            </strong>
                            <p className="text-gray-500">
                              {openHome.listing.streetAddress},{' '}
                              {openHome.listing.suburb}, {openHome.listing.city}
                            </p>
                            {openHome.notes && openHome.notes.length > 0 && (
                              <p className="text-gray-500">
                                Landlord Notes: {openHome.notes}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <Hyperlink
                            href={`/listings/${openHome.listing.publicId}`}>
                            View listing
                          </Hyperlink>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Card>
            )}
            {/* We need a relation to the listings instance. */}
            {filteredPrivateViewings.length > 0 && (
              <Card title="Private Viewings">
                {filteredPrivateViewings.map((privateViewing) => (
                  <PrivateViewingRow
                    key={privateViewing.id}
                    privateViewing={privateViewing}
                  />
                ))}
              </Card>
            )}
          </>
        )
      }
    </Page>
  );
};

export default ViewingsIndexPage;
