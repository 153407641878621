import {Router} from 'framework7/types';

import BillingDetailsPage from 'pages/landlord/billing/BillingDetailsPage';
import MyAccountPage from 'pages/shared/account/MyAccountPage';
import SupportPage from 'pages/shared/account/SupportPage';
import ReferralPage from 'pages/shared/misc/ReferralPage';
import SettingsAvatarPage from 'pages/shared/settings/SettingsAvatarPage';
import SettingsIndexPage from 'pages/shared/settings/SettingsIndexPage';
import SettingsLandlordProfilePage from 'pages/shared/settings/SettingsLandlordProfilePage';
import SettingsNotificationsPage from 'pages/shared/settings/SettingsNotificationsPage';
import SettingsRenterProfilePage from 'pages/shared/settings/SettingsRenterProfilePage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER, LOGGED_IN, IS_LANDLORD} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/account',
    id: 'MyAccount',
    async: resolveRoute({
      conditions: [LOGGED_IN],
      component: MyAccountPage,
    }),
    routes: [
      {
        path: 'billing',
        id: 'BillingDetails',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_LANDLORD],
          component: BillingDetailsPage,
        }),
      },
      {
        path: 'support',
        id: 'Support',
        async: resolveRoute({
          conditions: [LOGGED_IN],
          component: SupportPage,
        }),
      },
      {
        path: 'settings',
        id: 'SettingsIndex',
        async: resolveRoute({
          conditions: [LOGGED_IN],
          component: SettingsIndexPage,
        }),
        routes: [
          {
            path: 'general',
            id: 'GeneralSettings',
            async: resolveRoute({
              conditions: [LOGGED_IN],
              component: MyAccountPage,
            }),
          },
          {
            path: 'avatar',
            id: 'AvatarSettings',
            async: resolveRoute({
              conditions: [LOGGED_IN],
              component: SettingsAvatarPage,
            }),
          },
          {
            path: 'notifications',
            id: 'NotificationsSettings',
            async: resolveRoute({
              conditions: [LOGGED_IN],
              component: SettingsNotificationsPage,
            }),
          },
          {
            path: 'profile',
            id: 'ProfileSettings',
            async: resolveRoute(
              {conditions: [NEVER]},
              {redirect: '/account/settings'},
            ),
            routes: [
              {
                path: 'landlord',
                id: 'LandlordProfileSettings',
                async: resolveRoute({
                  conditions: [LOGGED_IN],
                  component: SettingsLandlordProfilePage,
                }),
              },
              {
                path: 'renter',
                id: 'RenterProfileSettings',
                async: resolveRoute({
                  conditions: [LOGGED_IN],
                  component: SettingsRenterProfilePage,
                }),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/refer-a-friend',
    id: 'Referral',
    async: resolveRoute({
      conditions: [LOGGED_IN],
      component: ReferralPage,
    }),
  },
];

export default routes;
