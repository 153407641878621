import {FunctionComponent} from 'react';

import clsx from 'clsx';
import {HiX} from 'react-icons/hi';

type LocationChipProps = {
  label: string;
  onRemove: () => void;
};
const LocationChip: FunctionComponent<LocationChipProps> = ({
  label,
  onRemove,
}) => {
  return (
    <div
      className={clsx(
        'bg-white rounded-full',
        'drop-shadow-md',
        'pl-6 pr-2 py-2',
        'flex flex-row items-center gap-x-2',
        'white-space-nowrap',
      )}>
      <span className="text-brand-850 text-sm">{label}</span>
      <button
        role="button"
        onClick={onRemove}
        className={clsx('bg-white hover:bg-brand-50 p-2 rounded-full w-auto')}>
        <HiX className="text-brand-500 w-4 h-4" />
      </button>
    </div>
  );
};

export default LocationChip;
