import {Model, Attr, BelongsTo, HasMany, HasOne} from 'spraypaint';

import ApplicationRecord from 'models/ApplicationRecord';
import Inspection from 'models/inspections/Inspection';
import LandlordPayment from 'models/payments/LandlordPayment';
import {Clause} from 'models/properties/ClauseLibrary';
import Document from 'models/properties/Document';
import EndOfTenancy from 'models/properties/EndOfTenancy';
import FormalNotice from 'models/properties/FormalNotice';
import Property from 'models/properties/Property';
import RentPriceChange from 'models/properties/RentPriceChange';
import TenancyContinuation from 'models/properties/TenancyContinuation';
import TenancyInvite from 'models/properties/TenancyInvite';
import TenancyMembership from 'models/properties/TenancyMembership';
import TenancyRequest from 'models/properties/TenancyRequest';
import ServiceRequest from 'models/service_requests/ServiceRequest';

export type CustomClauses = {
  clauses: string[];
};

export enum TenancyStatus {
  Draft = 'draft',
  Pending = 'pending',
  AwaitingStartDate = 'awaiting_start_date',
  Active = 'active',
  ActivePeriodic = 'active_periodic',
  Ended = 'ended',
}

export enum LeaseDocumentType {
  KeyhookResidential = 'keyhook_new_zealand',
  TenancyServicesResidential = 'tenancy_services',
  TenancyServicesBoardingHouse = 'tenancy_services_boarding_house',
}

@Model()
class Tenancy extends ApplicationRecord {
  static jsonapiType = 'tenancies';

  @Attr() status: TenancyStatus;

  @Attr() bond: number;

  @Attr() startDate: string;
  @Attr() endDate: string;

  @Attr() dayOfWeekRentPaid: string;

  @Attr() landlordSignature: string;

  @Attr() totalRent: number;
  @Attr() rentalPeriod: string;

  @Attr() isNew: boolean;

  @Attr({persist: false}) inArrears: boolean;

  @Attr() leaseDocumentType?: LeaseDocumentType;

  @Attr() nextInspectionDate: string;
  @Attr({persist: false}) nextRentPaymentDue: string;

  @Attr() weeksRentInAdvance: number;

  @Attr() externalBondId: string;
  @Attr({persist: false}) externalBondFiled: boolean;
  @Attr({persist: false}) readyToBeFiled: boolean;

  @Attr({persist: false}) createdAt: string;
  @Attr({persist: false}) updatedAt: string;

  @Attr() propertyId: string;

  @Attr() headTenantId: string;

  @Attr({persist: false}) originalRenterIds: number[];

  @Attr() customClauses: CustomClauses;
  @Attr() clauses: Clause[];

  @Attr() managedRentEnabled: boolean;

  @Attr() messageFromLandlord: string | null;

  @BelongsTo('properties') property: Property;

  @HasMany('tenancy_requests') tenancyRequests: TenancyRequest[];
  @HasMany('tenancy_invites') tenancyInvites: TenancyInvite[];
  @HasMany('tenancy_memberships') tenancyMemberships: TenancyMembership[];
  @HasMany('tenancy_memberships') activeTenancyMemberships: TenancyMembership[];
  @HasMany('inspections') inspections: Inspection[];
  @HasMany('documents') documents: Document[];

  @HasMany('tenancy_continuations') tenancyContinuations: TenancyContinuation;
  @HasOne('tenancy_continuations')
  activeTenancyContinuation: TenancyContinuation;

  @HasMany('service_requests') serviceRequests: ServiceRequest[];
  @HasMany('service_requests') openServiceRequests: ServiceRequest[];

  @HasMany('rent_price_changes') rentPriceChanges: RentPriceChange[];
  @HasMany('rent_price_changes') unprocessedRentPriceChanges: RentPriceChange[];

  @HasMany('landlord_payments') landlordPayments: LandlordPayment[];
  @HasMany('landlord_payments') unpaidLandlordPayments: LandlordPayment[];
  @HasMany('formal_notices') formalNotices: FormalNotice[];
  @HasOne('end_of_tenancies') endOfTenancy: EndOfTenancy;

  /**
   * Indicates whether the tenancy is in a draft state.
   */
  get isDraft(): boolean {
    return this.status === TenancyStatus.Draft;
  }

  /**
   * Indicates whether the tenancy is in a pending state (awaiting tenant to sign).
   */
  get isPending(): boolean {
    return this.status === TenancyStatus.Pending;
  }

  /**
   * Indicates whether the tenancy is currently active.
   */
  get isActive(): boolean {
    return [TenancyStatus.Active, TenancyStatus.ActivePeriodic].includes(
      this.status,
    );
  }

  /**
   * Indicates whether the tenancy is in one of the post-draft
   * states but is not yet active.
   */
  get isUpcoming(): boolean {
    return [TenancyStatus.Pending, TenancyStatus.AwaitingStartDate].includes(
      this.status,
    );
  }

  /**
   * Indicates whether the tenancy is in one of the post-draft
   * states where the tenant has signed (i.e. it is now a valid
   * tenancy).
   */
  get isSigned(): boolean {
    return this.isActive || this.status === TenancyStatus.AwaitingStartDate;
  }

  /**
   * Indicates whether the listing is in one of the post-draft
   * states, including being active, but not historical.
   */
  get isCurrent(): boolean {
    return this.isActive || this.isUpcoming;
  }

  /**
   * Indicates whether the tenancy is in one of the post-active states.
   */
  get isHistorical(): boolean {
    return this.status === TenancyStatus.Ended;
  }
}

export default Tenancy;
