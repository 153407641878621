import {ComponentType} from 'react';

import {QueryParamProvider} from 'use-query-params';

import RouteContextProvider from 'router/components/RouteContextProvider';
import F7QueryParamAdapter from 'router/F7QueryParamAdapter';
import {RouteComponentProps} from 'router/types';

/**
 * A high order component (HOC) for applying providers within the Framework7 route
 * context without needing to directly declare the providers in JSX for every
 * instance of a route component.
 */
const withProviders =
  (RouteComponent: ComponentType) => (props: RouteComponentProps) =>
    (
      <RouteContextProvider {...props}>
        <QueryParamProvider adapter={F7QueryParamAdapter}>
          <RouteComponent />
        </QueryParamProvider>
      </RouteContextProvider>
    );

export default withProviders;
