import bankAccountValidator from '@fnzc/nz-bank-account-validator';
import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import {InputField, SubmitButton} from 'components/forms_fields';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Property from 'models/properties/Property';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

const EditBankAccountPage = () => {
  const {
    params: {propertyId},
  } = useRoute();

  const queryClient = useQueryClient();

  const {data, isLoading, error} = useQuery(
    `property-${propertyId}-bank-account`,
    async () => {
      const property = await Property.select([
        'id',
        'street_address',
        'bank_account_name',
        'bank_account_number',
      ]).find(propertyId);

      return property.data;
    },
  );

  const handleBankingSubmit = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    const prop = data;

    prop.assignAttributes(formValues);

    const result = await prop.save();

    if (result) {
      queryClient.setQueryData(`property-${propertyId}-bank-account`, prop);
      toast.success('Banking information successfully updated!');
    } else {
      console.log(prop.errors);
      for (const key of Object.keys(prop.errors)) {
        const message = prop.errors[key].fullMessage;
        actions.setFieldError(key, message);
      }
    }

    actions.setSubmitting(false);
  };

  return (
    <Page title="Bank Account Information" loading={isLoading} error={error}>
      {() => (
        <Card title={`${data.streetAddress} Bank Account Information`}>
          <Formik
            initialValues={{
              bankAccountName: data.bankAccountName || '',
              bankAccountNumber: data.bankAccountNumber || '',
            }}
            onSubmit={handleBankingSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              bankAccountName: Yup.string()
                .min(2)
                .required()
                .label('Account Name'),
              bankAccountNumber: Yup.string()
                .required()
                .test(
                  'is-valid-format',
                  'This is not a valid NZ account number',
                  (value) => {
                    if (!value) return false;

                    const parts = value.split('-');
                    if (parts.length === 4) {
                      return bankAccountValidator.isValidNZBankNumber(
                        parts[0],
                        parts[1],
                        parts[2],
                        parts[3],
                      );
                    } else {
                      return false;
                    }
                  },
                )
                .label('Account Number'),
            })}>
            {(formik) => (
              <Form>
                <Paragraph>
                  Keyhook will deposit rent payments into the bank account
                  entered below. Keyhook will collect and calculate rent, and
                  chase arrears on your behalf. Your full rent payment will be
                  transferred to you within 24 hours of Keyhook receiving it,
                  minus any outstanding bills. For more information about our
                  smart-payment structure, visit out FAQs.
                </Paragraph>

                <Paragraph>
                  Please note this is only done if you have Keyhook managed rent
                  enabled for your tenancy.
                </Paragraph>

                <InputField
                  labelProps={{
                    title: 'Account name',
                  }}
                  name="bankAccountName"
                  formik={formik}
                  placeholder="E.g. John Smith"
                  autoCapitalize="words"
                />
                <InputField
                  labelProps={{
                    title: 'Account number',
                  }}
                  name="bankAccountNumber"
                  formik={formik}
                  placeholder="E.g. 02-1234-5678900-00 (include dashes)"
                  autoCapitalize="words"
                />

                <SubmitButton
                  formik={formik}
                  text="Update"
                  submittingText="Updating"
                  className="mt-4"
                />
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </Page>
  );
};

export default EditBankAccountPage;
