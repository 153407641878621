import {useMemo, useState} from 'react';

import {Browser} from '@capacitor/browser';
import {Form, Formik} from 'formik';
import type {FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import ChatableMessages from 'components/chat/ChatableMessages';
import LightboxGallery from 'components/common/LightboxGallery';
import {SubmitButton, TextareaField} from 'components/forms_fields';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import ServiceRequestQuote from 'models/service_requests/ServiceRequestQuote';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import TrackingService from 'services/TrackingService';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';
import {removeUnderscores, titleize, toCurrency} from 'utilities/StringHelpers';

const ServiceRequestDetailPage = () => {
  const {
    params: {serviceRequestId, propertyId},
  } = useRoute();

  const {currentUser} = useAuth();

  const {isLoading, data, error, isSuccess} = useQuery(
    `landlord-service-request-${serviceRequestId}`,
    async () => {
      const request = await ServiceRequest.includes([
        'service_request_attachments',
        'service_request_quotes',
      ]).find(serviceRequestId);

      return request.data;
    },
  );
  const queryClient = useQueryClient();

  const [quoteMutationLoading, setQuoteMutationLoading] = useState(false);
  const [showRejectionReasonInput, setShowRejectionReasonInput] =
    useState(false);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const confirmApproveQuote = (quote: ServiceRequestQuote) => {
    setConfirmationOptions({
      title: 'Approve Quote',
      message:
        'Are you sure you want to accept this quote? We will begin the process with the trades person if you confirm.',
      buttonTitle: 'Approve',
      action: () => approveQuote(quote),
      color: 'success',
    });
  };
  const approveQuote = async (quote: ServiceRequestQuote) => {
    setQuoteMutationLoading(true);

    quote.accepted = true;
    await quote.save();

    const request: ServiceRequest = data;
    request.status = 'confirming_job_with_service_person';

    const result = await request.save();

    if (result) {
      queryClient.setQueryData(
        `landlord-service-request-${serviceRequestId}`,
        request,
      );
      toast.success(
        'You have successfully approved this quote! We will begin to organize a time with the tradesperson.',
      );

      TrackingService.trackEvent(
        TrackingService.Event.ApproveMaintenanceRequestQuote,
        {
          requestId: serviceRequestId,
          quoteId: quote.id,
        },
      );
    } else {
      console.log(request.errors);
    }

    setQuoteMutationLoading(false);
  };

  const rejectServiceRequest = async (
    formValues: any,
    actions: FormikHelpers<any>,
  ) => {
    actions.setSubmitting(true);

    const request: ServiceRequest = data;
    request.landlordRejectedReason = formValues.landlordRejectedReason;
    request.status = 'landlord_rejected';

    const result = await request.save();

    if (result) {
      queryClient.setQueryData(
        `landlord-service-request-${serviceRequestId}`,
        request,
      );
      toast.success('The maintenance request has been rejected.');

      TrackingService.trackEvent(
        TrackingService.Event.RejectMaintenanceRequest,
        {
          requestId: serviceRequestId,
        },
      );
    } else {
      console.log(request.errors);
    }

    actions.setSubmitting(false);
  };

  const viewInvoice = async () => {
    await Browser.open({url: data?.customerInvoiceUrl});
  };

  let quote: ServiceRequestQuote;
  if (data && data.serviceRequestQuotes) {
    const accepted = data.serviceRequestQuotes.filter((qu) => qu.accepted);
    if (accepted && accepted.length > 0) {
      quote = accepted[0];
    }
  }

  const beforeAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'before',
      )
    : [];
  const afterAttachments = data
    ? data?.serviceRequestAttachments.filter(
        (attach) => attach.taken === 'after',
      )
    : [];

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !isSuccess || !currentUser
        ? null
        : [
            ...(data.userId === currentUser.id && data.status === 'completed'
              ? [
                  {
                    label: 'Edit request',
                    linkTo: `/properties/${propertyId}/service-requests/${serviceRequestId}`,
                  },
                ]
              : []),
          ],
    [propertyId, serviceRequestId, isSuccess, currentUser, data],
  );

  return (
    <Page
      title="Maintenance Request"
      actions={pageActions}
      loading={isLoading}
      error={error}>
      {() => (
        <>
          <Card title={data.title} className="lg:mt-4">
            <span className="text-secondary text-sm tw-block">
              Category: {data.category}
            </span>
            <span className="text-secondary text-sm tw-block">
              Status: {titleize(removeUnderscores(data.status))}
            </span>
            <Paragraph>{data.description}</Paragraph>

            {data.category === 'Appliance Repair' && (
              <p>Appliance: {data.applianceMakeAndModel}</p>
            )}

            {data.finalizedCost && (
              <div>
                <strong>Finalized Cost: </strong>
                <span>{toCurrency(data.finalizedCost)}</span>
              </div>
            )}

            {data.status === 'completed' && (
              <div className="my-4">
                {data.customerInvoiceUrl && (
                  <div className="mt-2">
                    <button
                      className="btn btn-sm btn-neutral"
                      onClick={viewInvoice}
                      type="button">
                      View Invoice
                    </button>
                  </div>
                )}
              </div>
            )}

            {beforeAttachments.length > 0 && (
              <div>
                <strong className="tw-block mb-2">Attachments</strong>

                <LightboxGallery
                  sources={beforeAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}

            {afterAttachments.length > 0 && (
              <div className="mt-4">
                <strong className="tw-block mb-2">
                  Attachments ( Post Completion )
                </strong>

                <LightboxGallery
                  sources={afterAttachments.map((attachment) => {
                    if (attachment.attachmentThumbnail) {
                      return {
                        thumbnail:
                          attachment.attachmentThumbnail ||
                          attachment.attachment,
                        source: attachment.attachment,
                        type: 'image',
                      };
                    } else {
                      return {source: attachment.attachment, type: 'video'};
                    }
                  })}
                />
              </div>
            )}
          </Card>
          {quote && (
            <Card title="Quote Details">
              <div>
                <strong>Company Name: </strong>
                <span>{quote.companyName}</span>
              </div>
              <div>
                <strong>Quoted Cost: </strong>
                <span>{quote.quotedAmount}</span>
              </div>
              {quote.notes && (
                <div>
                  <strong>Maintenance Support Notes:</strong>
                  <p>{quote.notes}</p>
                </div>
              )}
            </Card>
          )}

          {(data.status === 'awaiting_quote' ||
            data.status === 'awaiting_site_visit') && (
            <Card title="Awaiting Quotes">
              <Paragraph>
                Our support team is searching for quotes from available
                tradespeople. We will update you once quotes have been found.
              </Paragraph>
            </Card>
          )}

          {data.status === 'awaiting_quote_acceptance' && (
            <Card title="Quotes">
              <Paragraph>
                Keyhook has found these quote(s) for this maintenance request.
                Select a quote or "Reject maintenance request". If you reject
                Keyhook's quotes, you'll need to find a tradesperson yourself.
              </Paragraph>

              {data.serviceRequestQuotes.map((quote) => (
                <div key={quote.id}>
                  <div className="flex justify-between items-center my-2">
                    <div>
                      <strong className="tw-block">{quote.companyName}</strong>
                      <span className="tw-block text-secondary">
                        Quoted Amount: {quote.quotedAmount}
                      </span>
                      <span className="tw-block text-secondary">
                        Timeframe: {quote.timeframe}
                      </span>
                      {quote.notes && (
                        <div>
                          <strong className="text-secondary">
                            Maintenance Support Notes
                          </strong>
                          <p className="text-secondary">{quote.notes}</p>
                        </div>
                      )}
                    </div>
                    <div className="ml-2 float-right">
                      <button
                        className="btn btn-sm btn-success"
                        type="button"
                        onClick={() => confirmApproveQuote(quote)}
                        disabled={quoteMutationLoading}>
                        Accept
                      </button>
                    </div>
                  </div>
                  <hr className="border border-gray-200" />
                </div>
              ))}

              {showRejectionReasonInput ? (
                <div>
                  <Formik
                    initialValues={{landlordRejectedReason: ''}}
                    onSubmit={rejectServiceRequest}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={Yup.object().shape({
                      landlordRejectedReason: Yup.string()
                        .required()
                        .min(5)
                        .label('Reason'),
                    })}>
                    {(formik) => (
                      <Form>
                        <TextareaField
                          formik={formik}
                          name="landlordRejectedReason"
                          labelProps={{
                            title: 'Reason',
                            size: 'base',
                          }}
                          rows={2}
                          className="h-auto"
                          placeholder="Please provide a reason for rejecting this request, it will be shown to your tenants."
                        />

                        <SubmitButton
                          formik={formik}
                          text="Reject Maintenance Request"
                          submittingText="Saving"
                          color="error"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <button
                  className="btn btn-sm btn-neutral mt-4"
                  type="button"
                  onClick={() => setShowRejectionReasonInput(true)}>
                  Reject Maintenance Request
                </button>
              )}
            </Card>
          )}

          {data.status === 'landlord_rejected' && (
            <Card
              title="Maintenance Request Rejected"
              className="mt-4 border-t-3 border-red-500">
              <Paragraph>
                You have rejected this job. If you wish to have it fixed, it
                will be up to you to organise a tradesperson to fix it.
              </Paragraph>

              {data.landlordRejectedReason && (
                <Paragraph>Reason: {data.landlordRejectedReason}</Paragraph>
              )}
            </Card>
          )}

          {(data.status === 'confirming_job_with_service_person' ||
            data.status === 'awaiting_job_completion') && (
            <Card
              title="Quote Accepted"
              className="mt-3 border-t-1 border-success">
              <Paragraph>
                You have chosen a quote. Keyhook is now coordinating with your
                tenant and your selected tradesperson. You will be updated once
                the maintenance has been completed.
              </Paragraph>
            </Card>
          )}

          <ChatableMessages
            chatableId={data.id}
            chatableType="ServiceRequest"
            isLandlord={true}
          />
        </>
      )}
    </Page>
  );
};

export default ServiceRequestDetailPage;
