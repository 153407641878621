import {FunctionComponent, useCallback} from 'react';

import {Browser} from '@capacitor/browser';
import clsx from 'clsx';
import {
  FaFacebook,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import {IconType} from 'react-icons/lib';

interface ShareButtonsProps {
  /**
   * The URL to share.
   */
  url: string;
  /**
   * If provided, this will take priority over the plain URL for apps
   * that support prefilling a message - the URL should be included
   * within the text.
   */
  text?: string;
}

interface SharingPlatformConfig {
  icon: IconType;
  className: string;
  resolveUrl?: (url: string, text?: string) => string;
}

const PLATFORMS: {[key: string]: SharingPlatformConfig} = {
  facebook: {
    icon: FaFacebook,
    className: 'bg-[#1877F2] hover:bg-[#3587f3] ring-[#3587f3]',
    resolveUrl: (url) =>
      `http://www.facebook.com/share.php?u=${encodeURIComponent(url)}`,
  },
  twitter: {
    icon: FaTwitter,
    className: 'bg-[#1DA1F2] hover:bg-[#4db4f5] ring-[#4db4f5]',
    resolveUrl: (url) =>
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
  },
  whatsapp: {
    icon: FaWhatsapp,
    className: 'bg-[#25D366] hover:bg-[#4de083] ring-[bg-[#4de083]',
    resolveUrl: (url, text) =>
      `whatsapp://send?text=${
        text ? encodeURIComponent(text) : encodeURIComponent(url)
      }`,
  },
  telegram: {
    icon: FaTelegramPlane,
    className: 'bg-[#2AABEE] hover:bg-[#5abdf2] ring-[#5abdf2]',
    resolveUrl: (url) =>
      `https://t.me/share/url?url=${encodeURIComponent(url)}`,
  },
};

const LinkShareButtons: FunctionComponent<ShareButtonsProps> = ({
  url: shareUrl,
  text: shareText,
}) => {
  /**
   * Resolve and open the link for the given platform.
   */
  const openUrl = useCallback((resolvedUrl: string) => {
    Browser.open({url: resolvedUrl});
  }, []);

  return (
    <div className="w-full flex flex-row justify-evenly gap-x-2 my-4">
      {Object.entries(PLATFORMS).map(
        ([id, {icon: Icon, className, resolveUrl}]) => (
          <button
            key={id}
            onClick={() => openUrl(resolveUrl(shareUrl, shareText))}
            className={clsx(
              'px-0',
              'rounded-full',
              'w-12 h-12 min-w-[48px] max-w-[48px]',
              'flex',
              'justify-center',
              'items-center',
              'text-center',
              'transition-all',
              'duration-200',
              'scale-100',
              'active:scale-95',
              'ring-0',
              'focus:ring-2',
              'select-none',
              className,
            )}>
            <Icon className="w-5 h-5 text-white" />
          </button>
        ),
      )}
    </div>
  );
};

export default LinkShareButtons;
