import {Router} from 'framework7/types';

import EditDocumentPage from 'pages/landlord/documents/EditDocumentPage';
import NewDocumentPage from 'pages/landlord/documents/NewDocumentPage';
import EditEndOfTenancyPage from 'pages/landlord/end_of_tenancies/EditEndOfTenancyPage';
import EnquiryThreadDetailPage from 'pages/landlord/enquiry_threads/EnquiryThreadDetailPage';
import EnquiryThreadIndexPage from 'pages/landlord/enquiry_threads/EnquiryThreadIndexPage';
import EditInspectionPage from 'pages/landlord/inspections/EditInspectionPage';
import NewInspectionPage from 'pages/landlord/inspections/NewInspectionPage';
import LandlordPaymentDetailPage from 'pages/landlord/landlord_payments/LandlordPaymentDetailPage';
import LandlordPaymentIndexPage from 'pages/landlord/landlord_payments/LandlordPaymentIndexPage';
import EditListingPage from 'pages/landlord/listings/EditListingPage';
import LandlordListingDetailPage from 'pages/landlord/listings/ListingDetailPage';
import LandlordListingIndexPage from 'pages/landlord/listings/ListingIndexPage';
import EditPrivateViewingPage from 'pages/landlord/listings/viewings/private/EditPrivateViewingPage';
import NewPrivateViewingPage from 'pages/landlord/listings/viewings/private/NewPrivateViewingPage';
import PrivateViewingDetailPage from 'pages/landlord/listings/viewings/private/PrivateViewingDetailPage';
import EditOpenHomePage from 'pages/landlord/listings/viewings/public/EditOpenHomePage';
import NewOpenHomePage from 'pages/landlord/listings/viewings/public/NewOpenHomePage';
import OpenHomeDetailPage from 'pages/landlord/listings/viewings/public/OpenHomeDetailPage';
import ViewingsIndexPage from 'pages/landlord/listings/viewings/ViewingsIndexPage';
import OnboardingFlowPage from 'pages/landlord/onboarding/OnboardingFlowPage';
import EditPreferredTradesmanPage from 'pages/landlord/preferred_tradesmen/EditPreferredTradesmanPage';
import NewPreferredTradesmanPage from 'pages/landlord/preferred_tradesmen/NewPreferredTradesmanPage';
import PreferredTradesmanIndexPage from 'pages/landlord/preferred_tradesmen/PreferredTradesmanIndexPage';
import EditBankAccountPage from 'pages/landlord/properties/EditBankAccountPage';
import EditPropertyPage from 'pages/landlord/properties/EditPropertyPage';
import InitialisePropertyPage from 'pages/landlord/properties/InitialisePropertyPage';
import PropertyDetailPage from 'pages/landlord/properties/PropertyDetailPage';
import PropertyIndexPage from 'pages/landlord/properties/PropertyIndexPage';
import NewRentPriceChangePage from 'pages/landlord/rent_price_changes/NewRentPriceChangePage';
import RentalApplicationDetailPage from 'pages/landlord/rental_applications/RentalApplicationDetailPage';
import RentalApplicationIndexPage from 'pages/landlord/rental_applications/RentalApplicationIndexPage';
import RequestTenantCheckPage from 'pages/landlord/rental_applications/RequestTenantCheckPage';
import EditRoomPlanPage from 'pages/landlord/room_plans/EditRoomPlanPage';
import EditServiceRequestPage from 'pages/landlord/service_requests/EditServiceRequestPage';
import NewServiceRequestPage from 'pages/landlord/service_requests/NewServiceRequestPage';
import ServiceRequestDetailPage from 'pages/landlord/service_requests/ServiceRequestDetailPage';
import EditTenancyRenewalPage from 'pages/landlord/tenancy_renewals/EditTenancyRenewalPage';
import EditTenancyRequestPage from 'pages/landlord/tenancy_requests/EditTenancyRequestPage';
import FormalNoticeIndexPage from 'pages/shared/formal_notices/FormalNoticeIndexPage';
import NewFormalNoticePage from 'pages/shared/formal_notices/NewFormalNoticePage';
import InspectionActionDetailPage from 'pages/shared/inspections/InspectionActionDetailPage';
import InspectionAmendmentsPage from 'pages/shared/inspections/InspectionAmendmentsPage';
import InspectionCameraPage from 'pages/shared/inspections/InspectionCameraPage';
import InspectionDetailPage from 'pages/shared/inspections/InspectionDetailPage';
import InspectionRoomDetailPage from 'pages/shared/inspections/InspectionRoomDetailPage';
import NewTenancyInvitePage from 'pages/shared/tenancy_invites/NewTenancyInvitePage';
import resolveRoute, {RouteCondition} from 'router/utils/route-resolver';

const {NEVER, LOGGED_IN, IS_LANDLORD} = RouteCondition;

const routes: Router.RouteParameters[] = [
  {
    path: '/properties',
    id: 'Properties',
    async: resolveRoute({
      conditions: [LOGGED_IN, IS_LANDLORD],
      component: PropertyIndexPage,
    }),
    routes: [
      {
        path: 'initialise',
        id: 'InitialiseProperty',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_LANDLORD],
          component: InitialisePropertyPage,
        }),
      },
      {
        path: ':propertyId',
        id: 'Property',
        async: resolveRoute({
          conditions: [LOGGED_IN, IS_LANDLORD],
          component: PropertyDetailPage,
        }),
        routes: [
          {
            path: 'edit',
            id: 'EditProperty',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: EditPropertyPage,
            }),
          },
          {
            path: 'setup',
            id: 'SetupProperty',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: OnboardingFlowPage,
            }),
          },
          {
            path: 'inspections',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: 'new',
                id: 'NewPropertyInspection',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: NewInspectionPage,
                }),
              },
              {
                path: ':inspectionId',
                id: 'PropertyInspection',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: InspectionDetailPage,
                }),
                routes: [
                  {
                    path: 'edit',
                    id: 'EditPropertyInspection',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EditInspectionPage,
                    }),
                  },
                  {
                    path: 'actions',
                    async: resolveRoute(
                      {conditions: [NEVER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: ':actionId',
                        id: 'PropertyInspectionAction',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: InspectionActionDetailPage,
                        }),
                      },
                    ],
                  },
                  {
                    path: 'revisions',
                    id: 'PropertyInspectionAmendments',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: InspectionAmendmentsPage,
                    }),
                  },
                  {
                    path: 'rooms',
                    async: resolveRoute(
                      {conditions: [LOGGED_IN, IS_LANDLORD]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: ':roomKey',
                        id: 'PropertyInspectionRoom',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: InspectionRoomDetailPage,
                        }),
                        routes: [
                          {
                            path: 'camera',
                            id: 'PropertyInspectionRoomCamera',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: InspectionCameraPage,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'service-requests',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: 'new',
                id: 'PropertyTenancyNewServiceRequest',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: NewServiceRequestPage,
                }),
              },
              {
                path: ':serviceRequestId',
                id: 'PropertyTenancyServiceRequest',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: ServiceRequestDetailPage,
                }),
                routes: [
                  {
                    path: 'edit',
                    id: 'PropertyTenancyEditServiceRequest',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EditServiceRequestPage,
                    }),
                  },
                ],
              },
            ],
          },
          {
            path: 'bank-account',
            id: 'EditPropertyBankAccount',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: EditBankAccountPage,
            }),
          },
          {
            path: 'inspection-template',
            id: 'EditPropertyInspectionTemplate',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: EditRoomPlanPage,
            }),
          },
          {
            path: 'tenancies',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: ':tenancyId',
                async: resolveRoute(
                  {conditions: [NEVER]},
                  {redirect: '../../'},
                ),
                routes: [
                  {
                    path: 'invite-tenants',
                    id: 'PropertyTenancyInviteTenants',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: NewTenancyInvitePage,
                    }),
                  },
                  {
                    path: 'bond-refund',
                    id: 'PropertyTenancyBondRefund',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EditEndOfTenancyPage,
                    }),
                  },
                  {
                    path: 'notices',
                    id: 'PropertyTenancyNotices',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: FormalNoticeIndexPage,
                    }),
                    routes: [
                      {
                        path: 'new',
                        id: 'PropertyTenancyNewNotice',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: NewFormalNoticePage,
                        }),
                      },
                    ],
                  },
                  {
                    path: 'tenancy-requests',
                    async: resolveRoute(
                      {conditions: [NEVER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: ':tenancyRequestId',
                        async: resolveRoute(
                          {conditions: [NEVER]},
                          {redirect: '../'},
                        ),
                        routes: [
                          {
                            path: 'edit',
                            id: 'PropertyTenancyEditTenancyRequest',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: EditTenancyRequestPage,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'tenancy-renewals',
                    async: resolveRoute(
                      {conditions: [NEVER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: ':tenancyRenewalId',
                        async: resolveRoute(
                          {conditions: [NEVER]},
                          {redirect: '../'},
                        ),
                        routes: [
                          {
                            path: 'edit',
                            id: 'PropertyTenancyEditTenancyRenewal',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: EditTenancyRenewalPage,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'rent-changes',
                    async: resolveRoute(
                      {conditions: [NEVER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: 'new',
                        id: 'PropertyTenancyChangeRent',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: NewRentPriceChangePage,
                        }),
                      },
                    ],
                  },
                  {
                    path: 'payments',
                    id: 'PropertyTenancyPayments',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: LandlordPaymentIndexPage,
                    }),
                    routes: [
                      {
                        path: ':paymentId',
                        id: 'PropertyTenancyPayment',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: LandlordPaymentDetailPage,
                        }),
                      },
                    ],
                  },
                  {
                    path: 'service-requests',
                    async: resolveRoute(
                      {conditions: [NEVER]},
                      {redirect: '../'},
                    ),
                    routes: [
                      {
                        path: 'new',
                        id: 'PropertyTenancyNewServiceRequest',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: NewServiceRequestPage,
                        }),
                      },
                      {
                        path: ':serviceRequestId',
                        id: 'PropertyTenancyServiceRequest',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: ServiceRequestDetailPage,
                        }),
                        routes: [
                          {
                            path: 'edit',
                            id: 'PropertyTenancyEditServiceRequest',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: EditServiceRequestPage,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'listings',
            id: 'Listings',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: LandlordListingIndexPage,
            }),
            routes: [
              {
                path: ':listingPublicId',
                id: 'Listing',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: LandlordListingDetailPage,
                }),
                routes: [
                  {
                    path: 'edit',
                    id: 'EditListing',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EditListingPage,
                    }),
                  },
                  {
                    path: 'enquiries',
                    id: 'ListingEnquiries',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EnquiryThreadIndexPage,
                    }),
                    routes: [
                      {
                        path: ':enquiryThreadId',
                        id: 'ListingEnquriy',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: EnquiryThreadDetailPage,
                        }),
                      },
                    ],
                  },
                  {
                    path: 'applications',
                    id: 'ListingRentalApplications',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: RentalApplicationIndexPage,
                    }),
                    routes: [
                      {
                        path: ':listingRentalApplicationId',
                        id: 'ListingRentalApplication',
                        async: resolveRoute({
                          conditions: [LOGGED_IN, IS_LANDLORD],
                          component: RentalApplicationDetailPage,
                        }),
                        routes: [
                          {
                            path: 'request-tenant-check',
                            id: 'RequestTenantCheck',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: RequestTenantCheckPage,
                            }),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'viewings',
                    id: 'ListingViewings',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: ViewingsIndexPage,
                    }),
                    routes: [
                      {
                        path: 'public',
                        async: resolveRoute(
                          {conditions: [NEVER]},
                          {redirect: '../'},
                        ),
                        routes: [
                          {
                            path: 'new',
                            id: 'NewListingOpenHome',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: NewOpenHomePage,
                            }),
                          },
                          {
                            path: ':openHomeId',
                            id: 'ListingOpenHome',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: OpenHomeDetailPage,
                            }),
                            routes: [
                              {
                                path: 'edit',
                                id: 'EditListingOpenHome',
                                async: resolveRoute({
                                  conditions: [LOGGED_IN, IS_LANDLORD],
                                  component: EditOpenHomePage,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: 'private',
                        async: resolveRoute(
                          {conditions: [NEVER]},
                          {redirect: '../'},
                        ),
                        routes: [
                          {
                            path: 'new',
                            id: 'NewListingPrivateViewing',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: NewPrivateViewingPage,
                            }),
                          },
                          {
                            path: ':privateViewingId',
                            id: 'ListingPrivateViewing',
                            async: resolveRoute({
                              conditions: [LOGGED_IN, IS_LANDLORD],
                              component: PrivateViewingDetailPage,
                            }),
                            routes: [
                              {
                                path: 'edit',
                                id: 'EditListingPrivateViewing',
                                async: resolveRoute({
                                  conditions: [LOGGED_IN, IS_LANDLORD],
                                  component: EditPrivateViewingPage,
                                }),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: 'documents',
            async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
            routes: [
              {
                path: 'new',
                id: 'NewPropertyDocument',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: NewDocumentPage,
                }),
              },
              {
                path: ':documentId',
                async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
                routes: [
                  {
                    path: 'edit',
                    id: 'EditPropertyDocument',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EditDocumentPage,
                    }),
                  },
                ],
              },
            ],
          },
          {
            path: 'preferred-tradespeople',
            id: 'PreferredTradespeople',
            async: resolveRoute({
              conditions: [LOGGED_IN, IS_LANDLORD],
              component: PreferredTradesmanIndexPage,
            }),
            routes: [
              {
                path: 'new',
                id: 'NewPreferredTradesperson',
                async: resolveRoute({
                  conditions: [LOGGED_IN, IS_LANDLORD],
                  component: NewPreferredTradesmanPage,
                }),
              },
              {
                path: ':tradespersonId',
                async: resolveRoute({conditions: [NEVER]}, {redirect: '../'}),
                routes: [
                  {
                    path: 'edit',
                    id: 'EditPreferredTradesperson',
                    async: resolveRoute({
                      conditions: [LOGGED_IN, IS_LANDLORD],
                      component: EditPreferredTradesmanPage,
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
