/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useMemo, useState} from 'react';

import {Form, Formik, type FormikHelpers} from 'formik';
import {useQuery, useQueryClient} from 'react-query';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import LoadingView from 'components/common/LoadingView';
import {
  DateField,
  InputField,
  SubmitButton,
  ToggleField,
} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import ListingTagsModalField from 'components/forms_fields/ListingTagsModalField';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import {createListingPhotosStorageHandler} from 'components/walkthrough/new_listing/PhotosStep/listing-photos-storage-handler';
import {TextAreaField} from 'components_sb/input';
import FileUploader from 'components_sb/input/FileUploader/FileUploader';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import Listing from 'models/listings/Listing';
import ListingPhoto from 'models/listings/ListingPhoto';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import {Action} from 'types/actions';
import {errorViewForError} from 'utilities/ErrorHelpers';

type EditListingFormValues = {
  title?: string;
  description: string;
  startDate: string;
  rentAmount: number;
  bondAmount: number;
  rentalPeriod: string;
  garageType: string | null;
  tags: string[];
  photos: string[];
  pauseIncomingNotifications: boolean;
};

const EditListingPage = () => {
  const router = useRouter();
  const route = useRoute();

  const {
    params: {propertyId, listingPublicId},
  } = route;

  const {authCookies, currentUser} = useAuth();

  const queryClient = useQueryClient();

  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);

  const {data, error, isLoading} = useQuery(
    `listing-${listingPublicId}-detail-manage`,
    async () => {
      const listing = await Listing.includes('listing_photos')
        .order({'listing_photos.order_index': 'asc'})
        .find(listingPublicId);

      return listing.data;
    },
    {
      cacheTime: 0,
    },
  );

  const handleSubmit = async (
    formValues: EditListingFormValues,
    actions: FormikHelpers<EditListingFormValues>,
  ) => {
    const values = {...formValues};
    const listing = data.dup();

    if ((values.bondAmount as any) === '') {
      delete values.bondAmount;
    }

    const {photos: photosFormValue} = formValues;

    let listingPhotos = photosFormValue.map(
      (photoUrl: string, index: number) => {
        /**
         * Attempt to find an existing listing photo instance for
         * the photo URL.
         */
        const existingPhoto = listing.listingPhotos.find(
          ({photo}) => photo === photoUrl,
        );

        /**
         * Use the existing listing photo instance if found, otherwise
         * create a new listing photo instance.
         */
        const photoInstance =
          existingPhoto ||
          new ListingPhoto({
            photo: photoUrl,
            listingId: listing.id,
          });

        /**
         * Set the ordering on the photo.
         */
        photoInstance.assignAttributes({
          orderIndex: index,
        });

        return photoInstance;
      },
    );

    /**
     * Find any listing photos that are on the listing but are not included
     * in the form value.
     */
    const listingPhotosToDestroy = listing.listingPhotos.filter(
      ({photo}) => !photosFormValue.includes(photo),
    );

    /**
     * Mark any remaining photos on the listing to be destroyed.
     */
    listingPhotos = [
      ...listingPhotos,
      ...listingPhotosToDestroy.map((listingPhoto) => {
        listingPhoto.isMarkedForDestruction = true;
        return listingPhoto;
      }),
    ];

    /**
     * Set the listing photo instances on the listing.
     */
    listing.listingPhotos = listingPhotos;

    /**
     * Set the changes on the listing.
     */
    listing.assignAttributes(values);

    /**
     * Save the changes to the listing.
     */
    const savedListing = await listing.save({with: 'listingPhotos'});

    if (savedListing) {
      toast.success('Listing successfully updated!');
      await queryClient.invalidateQueries(
        `listing-${listingPublicId}-detail-manage`,
      );
      router.navigate(route.path.replace('/edit', ''), {reloadCurrent: true});
    } else {
      for (const field in listing.errors) {
        const error = listing.errors[field];
        actions.setFieldError(field, error?.fullMessage);
      }
    }

    actions.setSubmitting(false);
  };

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !listingPublicId
        ? null
        : [
            {
              label: 'View listing ad',
              linkTo: `/listings/${listingPublicId}`,
            },
          ],
    [listingPublicId],
  );

  /**
   * Create the storage handler for uploading listing photos
   * to provide to the file uploader.
   */
  const listingPhotosStorageHandler = useMemo(
    () => createListingPhotosStorageHandler({listing: data, authCookies}),
    [data, authCookies],
  );

  if (error) {
    return errorViewForError(error);
  } else if (isLoading) {
    return <Page title="Edit Listing" loading />;
  } else {
    const vals = {
      title: data.title,
      description: data.description,
      startDate: data.startDate,
      rentAmount: data.rentAmount,
      bondAmount: data.bondAmount,
      tags: data.tags,
      photos: data.listingPhotos
        .sort((photoA, photoB) => photoA.orderIndex - photoB.orderIndex)
        .map(({photo}) => photo),
      pauseIncomingNotifications: data.pauseIncomingNotifications,
    } as EditListingFormValues;

    return (
      <Page title={`Edit Listing`} actions={pageActions}>
        {() => (
          <>
            <ManageListingMenu
              propertyId={data.propertyId}
              listingPublicId={listingPublicId}
            />
            <Card title="Edit Listing">
              <small>Fields with a * are required</small>
              <Formik
                initialValues={vals}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={Yup.object().shape({
                  title: Yup.string().optional().nullable().label('Title'),
                  description: Yup.string()
                    .min(100)
                    .required()
                    .label('Description'),
                  startDate: Yup.string().required().label('Available From'),
                  rentAmount: Yup.number().required().min(0).label('Rent'),
                  bondAmount: Yup.number()
                    .min(0)
                    .optional()
                    .nullable()
                    .label('Bond')
                    .test(
                      'max-bond-is-allowed',
                      'Bond can not be greater than 4 weeks rent',
                      function (value) {
                        if (!value || value == 0) {
                          return true;
                        }
                        const rentalPeriod = this.parent.rentalPeriod;
                        const rent = this.parent.rentAmount;
                        if (rentalPeriod === 'Weekly') {
                          return value <= rent * 4;
                        } else if (rentalPeriod === 'Fortnightly') {
                          return value <= rent * 2;
                        } else {
                          return true;
                        }
                      },
                    ),
                  tags: Yup.array().min(0),
                  photos: Yup.array().min(4, 'Please add at least 4 photos'),
                  pauseIncomingNotifications: Yup.boolean()
                    .optional()
                    .nullable()
                    .label('Pause Notifications'),
                })}>
                {(formik) => (
                  <Form>
                    <div className="mb-4">
                      <DateField
                        formik={formik}
                        label="Available From*"
                        name="startDate"
                        minDate={new Date()}
                        maxDate={new Date('2099-01-01')}
                      />
                    </div>

                    <FormRow responsive>
                      <FormRowItem>
                        <InputField
                          formik={formik}
                          labelProps={{
                            title: 'Rent ($ per week)',
                            required: true,
                          }}
                          name="rentAmount"
                          placeholder="E.g. 750.00"
                        />
                      </FormRowItem>

                      <FormRowItem>
                        <InputField
                          formik={formik}
                          labelProps={{
                            title: 'Bond ($)',
                          }}
                          name="bondAmount"
                          placeholder="E.g. 2000.00"
                        />
                      </FormRowItem>
                    </FormRow>

                    <div>
                      <ListingTagsModalField formik={formik} name="tags" />
                    </div>

                    <div className="my-4">
                      <InputField
                        formik={formik}
                        name="title"
                        labelProps={{
                          title: 'Title',
                        }}
                        placeholder="If left blank the address will be used."
                      />
                    </div>

                    <TextAreaField
                      label="Description"
                      name="description"
                      placeholder="Your listing description here, should be at least 100 characters long."
                      rows={6}
                      mode="formik"
                      form={formik as any}
                    />

                    <h2 className="card-title mt-4 mb-4 text-brand-850 flex justify-between">
                      Photos
                    </h2>
                    <small>
                      At least 4 photos are required to publish your listing.
                    </small>

                    <div className="flex w-full">
                      <FileUploader
                        mode="multiple"
                        accept={{
                          'image/png': [],
                          'image/jpeg': [],
                          'image/jpg': [],
                        }}
                        maxFileSize="8MB"
                        files={formik.values.photos}
                        onChange={(files) =>
                          formik.setFieldValue('photos', files)
                        }
                        onActive={() => setIsUploadingPhotos(true)}
                        onIdle={() => setIsUploadingPhotos(false)}
                        storageHandler={listingPhotosStorageHandler}
                        firstFileIsMain
                      />
                    </div>

                    <h2 className="card-title mt-8 text-brand-850 flex justify-between">
                      Notifications
                    </h2>

                    <div className="w-full">
                      <Paragraph>
                        Use the toggle below if you would like to pause
                        notifications about enquiries or applications for this
                        listing.
                      </Paragraph>
                      <Paragraph secondary size="sm">
                        Please note, people will still be able to enquire and
                        apply, you will just not be sent any notifications /
                        emails about them.
                      </Paragraph>
                      <ToggleField
                        formik={formik}
                        name="pauseIncomingNotifications"
                        label="Pause notifications"
                      />
                    </div>

                    <SubmitButton
                      formik={formik}
                      text="Update Listing"
                      submittingText="Saving"
                      className="mt-4"
                      disabled={isUploadingPhotos}
                    />
                  </Form>
                )}
              </Formik>
            </Card>
          </>
        )}
      </Page>
    );
  }
};

export default EditListingPage;
