import clsx from 'clsx';

import {RouterLink} from 'router/components';

const ManageListingMenu = ({
  propertyId,
  listingPublicId,
}: {
  propertyId: string;
  listingPublicId: string;
}) => {
  const items = [
    {
      text: 'Dashboard',
      path: `/properties/${propertyId}/listings/${listingPublicId}`,
    },
    {
      text: 'Enquiries',
      path: `/properties/${propertyId}/listings/${listingPublicId}/enquiries`,
    },
    {
      text: 'Applications',
      path: `/properties/${propertyId}/listings/${listingPublicId}/applications`,
    },
    {
      text: 'Open Homes / Viewings',
      path: `/properties/${propertyId}/listings/${listingPublicId}/viewings`,
    },
    {
      text: 'Edit',
      path: `/properties/${propertyId}/listings/${listingPublicId}/edit`,
    },
  ];

  return (
    <ul
      className={clsx(
        'flex flex-col lg:flex-row',
        'gap-2',
        'bg-brand-50 rounded-xl',
        '!w-full p-2 mb-8',
      )}>
      {items.map((item, index) => (
        <li key={index} className="flex-grow">
          <RouterLink
            href={item.path}
            reloadCurrent
            className={clsx(
              'transition-colors duration-300',
              'flex-grow text-center',
              'h-12 px-6',
              'rounded-lg',
              'flex items-center justify-center',
              'font-medium',
              window.location.pathname === item.path
                ? 'bg-brand-500 hover:bg-brand-600 text-white'
                : clsx(
                    'bg-transparent hover:bg-brand-75 text-brand-500 hover:text-brand-600',
                  ),
            )}>
            {item.text}
          </RouterLink>
        </li>
      ))}
    </ul>
  );
};

export default ManageListingMenu;
