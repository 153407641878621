import {useQuery} from 'react-query';

import NewTenancyInviteForm from 'components/tenancy_invites/NewTenancyInviteForm';
import {Card} from 'components_sb/layout';
import Tenancy from 'models/properties/Tenancy';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

const NewTenancyInvitePage = () => {
  const {
    params: {tenancyId},
  } = useRoute();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-invite-tenants`,
    async () => {
      const t = await Tenancy.includes('property')
        .select({
          tenancies: ['id', 'is_new'],
          properties: ['street_address'],
        })
        .find(tenancyId);

      return t.data;
    },
  );

  return (
    <Page title="Invite Tenant" loading={isLoading} error={error}>
      {() => (
        <Card title="Invite Tenant">
          <NewTenancyInviteForm tenancy={data} />
        </Card>
      )}
    </Page>
  );
};

export default NewTenancyInvitePage;
