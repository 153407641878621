import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import InlineMoneyField from 'components/forms_fields/InlineMoneyField';
import InlineSelectField from 'components/forms_fields/InlineSelectField';
import StepContainer from 'components/walkthrough/common/StepContainer';
import {GridSelect} from 'components_sb/input';
import {Modal} from 'components_sb/layout';
import useMostRecentlyCreated from 'hooks/spraypaint/useMostRecentlyCreated';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import PrepareTenancySwitchToNewModal from '../common/PrepareTenancySwitchToNewModal';

const {useModal} = Modal.Imperative;

type FormValues = {
  totalRent: number;
  rentalPeriod: 'Weekly' | 'Fortnightly';
  dayOfWeekRentPaid:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';
};

const RentStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  /**
   * Find the most recent tenancy for the property.
   */
  const tenancy = useMostRecentlyCreated(property.tenancies);

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.MigrateTenancy_StartRentDetailsStep,
      {
        propertyId: property.id,
        tenancyId: tenancy?.id,
      },
    );
  }, [property.id, tenancy?.id, scrollToTop]);

  const queryClient = useQueryClient();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the tenancy.
       */
      tenancy.assignAttributes(formValues);

      /**
       * Save the changes to the tenancy.
       */
      const savedTenancy = await saveResource(tenancy);

      if (savedTenancy) {
        /**
         * Set changes on the property.
         */
        property.lastOnboardingStepCompleted = 'migrate_rent_information';

        /**
         * Save the changes to the property.
         */
        const savedProperty = await saveResource(property);

        if (savedProperty) {
          /**
           * Update the property data in the query cache.
           */
          queryClient.setQueryData(
            ['property', {id: property.id, context: 'onboarding-flow'}],
            property,
          );

          /**
           * Track completion of the step.
           */
          TrackingService.trackEvent(
            TrackingService.Event.MigrateTenancy_CompleteRentDetailsStep,
            {
              propertyId: property.id,
            },
          );
        }
      } else {
        console.log(tenancy.errors);
        for (const key of Object.keys(tenancy.errors)) {
          const message = tenancy.errors[key].fullMessage;
          actions.setFieldError(key, message);
        }
      }

      setSubmitting(false);
    },
    [property, queryClient, tenancy],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        totalRent: tenancy ? tenancy.totalRent : null,
        rentalPeriod: tenancy ? tenancy.rentalPeriod : null,
        dayOfWeekRentPaid: tenancy ? tenancy.dayOfWeekRentPaid : null,
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        totalRent: Yup.number().nullable().required().min(1).label('Rent'),
        rentalPeriod: Yup.string()
          .nullable()
          .oneOf(['Weekly', 'Fortnightly'])
          .required()
          .label('Rent Frequency'),
        dayOfWeekRentPaid: Yup.string()
          .nullable()
          .oneOf([
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ])
          .required()
          .label('Rent Day'),
      }),
    }),
    [handleSubmit, tenancy],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  const hasCurrentListing = useMemo(
    () => property.hasCurrentListing,
    [property],
  );

  const openModal = useModal();

  const showSwitchToNewTenancyModal = useCallback(async () => {
    await openModal(PrepareTenancySwitchToNewModal, {property});
  }, [openModal, property]);

  /**
   * The previous button on this step behaves differently depending
   * on whether the user is initially following this onboarding flow
   * versus whether they are preparing a tenancy for a property that
   * has a current listing.
   */
  const onClickPrevious = useMemo(
    () => (hasCurrentListing ? showSwitchToNewTenancyModal : 'auto'),
    [hasCurrentListing, showSwitchToNewTenancyModal],
  );

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      previous: {
        disabled: tenancy.isPending,
        onClick: onClickPrevious,
      },
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    <StepContainer
      align="center"
      title="Next up, tenancy information..."
      subtitle="Enter the rent details for this property">
      <div className="flex w-full">
        <InlineMoneyField
          formik={form}
          name="totalRent"
          label="Rent amount"
          placeholder="E.g. $500"
        />
      </div>

      <div className="flex w-full">
        <GridSelect
          mode="formik"
          name="rentalPeriod"
          form={form}
          labelProps={{
            title: 'Frequency',
            size: 'xl',
          }}
          multiple={false}
          minColumns={2}
          options={[
            {
              id: 'Weekly',
              label: 'Weekly',
            },
            {
              id: 'Fortnightly',
              label: 'Fortnightly',
            },
          ]}
        />
      </div>

      <div className="flex w-full">
        <InlineSelectField
          formik={form}
          name="dayOfWeekRentPaid"
          labelProps={{
            title: 'Paid on',
            size: 'xl',
            helpText: 'The day of the week rent is paid',
          }}>
          <option value="">Choose an option</option>
          <option value="Monday">Monday</option>
          <option value="Tuesday">Tuesday</option>
          <option value="Wednesday">Wednesday</option>
          <option value="Thursday">Thursday</option>
          <option value="Friday">Friday</option>
          <option value="Saturday">Saturday</option>
          <option value="Sunday">Sunday</option>
        </InlineSelectField>
      </div>
    </StepContainer>
  );
};

export default RentStep;
