import useAuth from 'auth/provider/useAuth';
import {Alert} from 'components_sb/feedback';

const ConfirmEmailAlert = () => {
  const {currentUser} = useAuth();
  if (currentUser.confirmed) {
    return null;
  } else {
    return (
      <Alert
        type="warning"
        actions={[
          {
            label: 'Confirm email address',
            linkTo: '/register/confirm-email',
          },
        ]}>
        Your email address has not been confirmed.
      </Alert>
    );
  }
};

export default ConfirmEmailAlert;
