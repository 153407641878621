import React, {useMemo} from 'react';

import moment from 'moment';
import {useQuery} from 'react-query';

import {Card, TableComponents} from 'components_sb/layout';
import {Hyperlink} from 'components_sb/navigation';
import FormalNotice from 'models/properties/FormalNotice';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const {Table, TableHead, TableHeadItem, TableRow, TableRowItem} =
  TableComponents;

const FormalNoticeIndexPage = () => {
  const {
    params: {tenancyId},
  } = useRoute();

  const {data, isLoading, error} = useQuery(
    `tenancy-${tenancyId}-formal-notices`,
    async () => {
      const notices = await FormalNotice.includes('sending_user')
        .where({tenancy_id: tenancyId})
        .order({created_at: 'desc'})
        .per(100)
        .all();
      return notices.data;
    },
  );

  const pageActions = useMemo(() => {
    return [
      {
        label: 'Give notice',
        linkTo: `new`,
      },
    ];
  }, []);

  return (
    <Page
      title="Notices"
      actions={pageActions}
      loading={isLoading}
      error={error}>
      {() => (
        <Card className="mt-4" title="Notices given for this tenancy">
          <Table>
            {/* Table head */}
            <TableHead>
              <TableHeadItem>Sent At</TableHeadItem>
              <TableHeadItem>Notice Type</TableHeadItem>
              <TableHeadItem>Sent By</TableHeadItem>
              <TableHeadItem>Actions</TableHeadItem>
            </TableHead>

            {/* Table rows */}
            {data.map((notice) => (
              <TableRow key={notice.id}>
                <TableRowItem>
                  {moment(notice.createdAt).format(DATE_TIME_FORMAT)}
                </TableRowItem>
                <TableRowItem>
                  {titleize(removeUnderscores(notice.noticeType))}
                </TableRowItem>
                <TableRowItem>{notice.sendingUser?.name}</TableRowItem>
                <TableRowItem>
                  {notice.attachment && (
                    <Hyperlink href={notice.attachment} openInNewTab>
                      View
                    </Hyperlink>
                  )}
                </TableRowItem>
              </TableRow>
            ))}
          </Table>
        </Card>
      )}
    </Page>
  );
};

export default FormalNoticeIndexPage;
