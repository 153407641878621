import {useContext} from 'react';

import {RouterContext} from 'router/RouterProvider';

/**
 * Returns the f7router object for the current route.
 */
const useRouter = () => {
  const {router} = useContext(RouterContext);
  return router;
};

export default useRouter;
