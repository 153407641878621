import {isObject} from 'lodash';

import BadRequestPage from 'pages/shared/errors/BadRequestPage';
import NotFoundPage from 'pages/shared/errors/NotFoundPage';
import ServerErrorPage from 'pages/shared/errors/ServerErrorPage';
import UnauthorisedPage from 'pages/shared/errors/UnauthorisedPage';

const errorIsObject = (error: unknown): error is object =>
  typeof error === 'object';

export const errorViewForError = (error: unknown) => {
  if (errorIsObject(error)) {
    if ('originalError' in error && !!error.originalError) {
      error = error.originalError;
    }

    if (errorIsObject(error)) {
      const message: string | null =
        'message' in error && typeof error.message === 'string'
          ? error.message
          : null;

      const code: string | null =
        'code' in error && typeof error.code === 'string' ? error.code : null;

      if (code) {
        if (code === '400') {
          return <BadRequestPage />;
        } else if (code === '404') {
          return <NotFoundPage />;
        } else if (code === '401') {
          return <UnauthorisedPage />;
        } else if (code === '500') {
          return <ServerErrorPage />;
        }
      }

      if (message.toLowerCase().includes('not found')) {
        return <NotFoundPage />;
      } else if (message.toLowerCase().includes('unauthorized')) {
        return <UnauthorisedPage />;
      } else if (message.toLowerCase().includes('server error')) {
        return <ServerErrorPage />;
      } else {
        return <NotFoundPage />;
      }
    }
  }

  // TODO: Return a generic error here
  return <ServerErrorPage />;
};
