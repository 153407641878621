import {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import type {FormikHelpers} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {InputField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import {TextField} from 'components_sb/input';
import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import {API_URL} from 'globals/app-globals';
import useRouter from 'router/hooks/useRouter';
import TrackingService from 'services/TrackingService';

type FormValues = {
  streetAddress: string;
  suburb: string;
  city: string;
  country: string;
  postcode: string;
};

const ManualPropertyAddressModal: ModalDefinition = {
  title: 'Add your property address',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal} = props;

    const router = useRouter();
    const {authCookies} = useAuth();

    const handleSubmit = async (formValues: FormValues) => {
      closeModal();
      router.navigate('/properties/initialise', {
        props: {
          address: formValues,
        },
      });
    };

    const formik = useFormik<FormValues>({
      initialValues: {
        streetAddress: '',
        suburb: '',
        city: '',
        country: 'New Zealand',
        postcode: '',
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        streetAddress: Yup.string().required('Street address is required'),
        suburb: Yup.string().required('Suburb is required'),
        city: Yup.string().required('City is required'),
        postcode: Yup.string().required('Post code is required').length(4),
      }),
    });

    const onSave = useCallback(async () => {
      formik.submitForm();
      return false;
    }, [formik]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <div>
        <Paragraph size="sm" secondary>
          Please use the format Unit/Street Number for houses with apt / unit
          numbers.
        </Paragraph>
        <Paragraph size="sm" secondary>
          E.g. "12/59 Harrington Avenue"
        </Paragraph>

        <div className="mt-4 flex flex-col gap-y-2">
          <TextField
            name="streetAddress"
            label="Street address"
            placeholder="E.g. 123 Example Street"
            size="base"
            mode="formik"
            form={formik}
          />

          <FormRow responsive>
            <FormRowItem>
              <TextField
                name="suburb"
                label="Suburb"
                placeholder="E.g. Tawa"
                size="base"
                mode="formik"
                form={formik}
              />
            </FormRowItem>
            <FormRowItem>
              <TextField
                name="city"
                label="City"
                placeholder="E.g. Wellington"
                size="base"
                mode="formik"
                form={formik}
              />
            </FormRowItem>
          </FormRow>
          <TextField
            name="postcode"
            label="Post code"
            placeholder="E.g. 1234"
            size="base"
            mode="formik"
            form={formik}
            type="number"
          />
        </div>
      </div>
    );
  },
};

export default ManualPropertyAddressModal;
