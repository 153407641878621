import {useEffect, useMemo} from 'react';

import dayjs, {Dayjs} from 'dayjs';

import {NZ_TIMEZONE} from 'globals/app-globals';
import LandlordSubscription from 'models/payments/LandlordSubscription';
import parseDateAsNZTimezone from 'utilities/parse-date-as-nz-timezone';

import {SubscriptionStatus} from '../types';

/**
 * Returns the number of days until this date from the point of invocation as
 * an unrounded number. Will return 0 if the provided date is in the past.
 */
const getDaysUntilDate = (date: Dayjs): number =>
  Math.max(
    0,
    date.diff(
      dayjs().tz(NZ_TIMEZONE),
      'day',
      true, // Include remainder (i.e. will return 0.5 instead of 0 if half a day left)
    ),
  );

interface UseSubscriptionStatusHook {
  (landlordSubscription?: LandlordSubscription): SubscriptionStatus;
}

const useSubscriptionStatus: UseSubscriptionStatusHook = (
  landlordSubscription,
) => {
  /**
   * Whether a there is currently a logged in Landlord with a subscription.
   *
   * Note that the existence of a subscription does not necessarily mean
   * that the subscription is active.
   */
  const hasSubscription = useMemo<boolean>(
    () => !!landlordSubscription,
    [landlordSubscription],
  );

  /**
   * Parse the end date of the trial period for the Landlord's subscription
   * in the New Zealand timezone.
   */
  const trialPeriodEndDate = useMemo<Dayjs | null>(() => {
    if (!hasSubscription) {
      return null;
    }
    /**
     * The time of day will default to the very start of the day if no time
     * is defined in the date string. The trialEndsAt date reflects the end
     * of the specified day, so we need to set this manually.
     */
    const date = parseDateAsNZTimezone(landlordSubscription.trialEndsAt).endOf(
      'day',
    );
    return date.isValid() ? date : null;
  }, [hasSubscription, landlordSubscription?.trialEndsAt]);

  useEffect(() => {
    //trialPeriodEndDate
    console.log('trialPeriodEndDate', trialPeriodEndDate);
  }, [trialPeriodEndDate]);

  /**
   * The number of days remaining on the Landlord's free trial.
   *
   * Null if there is no subscription or trial end date.
   */
  const trialDaysRemaining = useMemo<number | null>(
    () =>
      !hasSubscription || !trialPeriodEndDate
        ? null
        : getDaysUntilDate(trialPeriodEndDate),
    [hasSubscription, trialPeriodEndDate],
  );

  /**
   * Whether the logged in Landlord is currently within their trial period
   * (before any bonus time has been applied).
   *
   * Null if there is no subscription.
   */
  const isWithinTrialPeriod = useMemo<boolean>(
    () => (!hasSubscription ? null : trialDaysRemaining > 0),
    [hasSubscription, trialDaysRemaining],
  );

  /**
   * Whether there is a logged in Landlord that has been granted bonus
   * time on their subscription.
   *
   * Null if there is no subscription.
   *
   * Note that the existence of bonus time does not necessarily mean that
   * the bonus time is still active.
   */
  const hasReceivedBonusTime = useMemo<boolean | null>(
    () =>
      !hasSubscription
        ? null
        : landlordSubscription.bonusTimeGivenUntil !== null,
    [hasSubscription, landlordSubscription?.bonusTimeGivenUntil],
  );

  /**
   * Parse the end date of the bonus period for the Landlord's subscription
   * in the New Zealand timezone.
   */
  const bonusPeriodEndDate = useMemo<Dayjs | null>(() => {
    if (!hasSubscription || !hasReceivedBonusTime) {
      return null;
    }
    /**
     * The time of day will default to the very start of the day if no time
     * is defined in the date string. The bonusTimeGivenUntil date reflects
     * the end of the specified day, so we need to set this manually.
     */
    const date = parseDateAsNZTimezone(
      landlordSubscription.bonusTimeGivenUntil,
    ).endOf('day');
    return date.isValid() ? date : null;
  }, [
    hasSubscription,
    hasReceivedBonusTime,
    landlordSubscription?.bonusTimeGivenUntil,
  ]);

  /**
   * The number of days remaining on the Landlord's bonus time.
   *
   * Null if there is no subscription.
   */
  const bonusDaysRemaining = useMemo<number | null>(
    () =>
      !hasSubscription
        ? null
        : hasReceivedBonusTime
        ? Math.max(0, getDaysUntilDate(bonusPeriodEndDate) - trialDaysRemaining)
        : 0,
    [
      hasSubscription,
      hasReceivedBonusTime,
      bonusPeriodEndDate,
      trialDaysRemaining,
    ],
  );

  /**
   * Whether the logged in Landlord is currently within their bonus period
   * (after their initial trial period has expired).
   *
   * Null if there is no subscription.
   */
  const isWithinBonusPeriod = useMemo<boolean | null>(
    () =>
      !hasSubscription ? null : !isWithinTrialPeriod && bonusDaysRemaining > 0,
    [hasSubscription, isWithinTrialPeriod, bonusDaysRemaining],
  );

  /**
   * Whether the logged in Landlord is currently within any period of having free
   * access to the platform (i.e. either within trial period or within bonus period).
   *
   * Null if there is no subscription.
   */
  const isWithinFreePeriod = useMemo<boolean | null>(
    () =>
      !hasSubscription ? null : isWithinTrialPeriod || isWithinBonusPeriod,
    [hasSubscription, isWithinTrialPeriod, isWithinBonusPeriod],
  );

  /**
   * The number of "free" days remaining (a combination of trial days and bonus days).
   *
   * Null if there is no subscription.
   */
  const freeDaysRemaining = useMemo<number | null>(
    () => (!hasSubscription ? null : trialDaysRemaining + bonusDaysRemaining),
    [hasSubscription, trialDaysRemaining, bonusDaysRemaining],
  );

  /**
   * The date in which the Landlord's free (trial or bonus) period will expire.
   *
   * Null if there is no subscription or the free period has ended.
   */
  const freePeriodEndDate = useMemo<Dayjs | null>(() => {
    if (!hasSubscription || !isWithinFreePeriod) {
      return null;
    }
    /**
     * Only trial period end date exists, return it.
     */
    if (trialPeriodEndDate && !bonusPeriodEndDate) {
      return trialPeriodEndDate;
    }
    /**
     * Only bonus period end date exists, return it.
     */
    if (!trialPeriodEndDate && bonusPeriodEndDate) {
      return bonusPeriodEndDate;
    }
    /**
     * Both trial and bonus period end dates exist, find the date
     * furthest in the future.
     */
    return dayjs.max(trialPeriodEndDate, bonusPeriodEndDate);
  }, [
    hasSubscription,
    isWithinFreePeriod,
    trialPeriodEndDate,
    bonusPeriodEndDate,
  ]);

  /**
   * The label to use when describing the free period.
   *
   * Null if the user is not within a free period.
   */
  const freePeriodTypeLabel = useMemo<string | null>(() => {
    if (!isWithinFreePeriod) {
      return null;
    } else {
      return isWithinTrialPeriod ? 'free trial' : 'free bonus period';
    }
  }, [isWithinFreePeriod, isWithinTrialPeriod]);

  /**
   * An interpretation of the free period end date for display purposes.
   *
   * Null if there is no subscription or the free period has ended.
   */
  const freePeriodEndDateLabel = useMemo<string | null>(() => {
    if (!hasSubscription || !isWithinFreePeriod) {
      return null;
    }

    if (freeDaysRemaining <= 1) {
      return 'today';
    }
    if (freeDaysRemaining > 1 && freeDaysRemaining <= 2) {
      return `tomorrow`;
    } else {
      return `in ${Math.floor(freeDaysRemaining)} days`;
    }
  }, [hasSubscription, isWithinFreePeriod, freeDaysRemaining]);

  return {
    hasSubscription,
    hasReceivedBonusTime,

    isWithinTrialPeriod,
    isWithinBonusPeriod,
    isWithinFreePeriod,

    trialDaysRemaining,
    bonusDaysRemaining,
    freeDaysRemaining,

    trialPeriodEndDate,
    bonusPeriodEndDate,
    freePeriodEndDate,

    freePeriodTypeLabel,
    freePeriodEndDateLabel,
  };
};

export default useSubscriptionStatus;
