import {useCallback, useEffect, useRef, useState} from 'react';

import {Capacitor} from '@capacitor/core';
import clsx from 'clsx';
import {useResizeDetector} from 'react-resize-detector';

import ChatDetailBody from 'components/chat2/ChatDetailBody';
import ChatDetailHeader from 'components/chat2/ChatDetailHeader';
import ChatInputBox from 'components/chat2/ChatInputBox';
import {NATIVE_LAYOUT_IN_WEB} from 'globals/app-globals';
import {useElementHeight} from 'hooks/useElementSize';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import Property from 'models/properties/Property';
import Tenancy from 'models/properties/Tenancy';
import User, {AccountRole} from 'models/users/User';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {type ChatChannel, usePusherService} from 'services/PusherService';

const ChatDetailPage = () => {
  const {
    params: {roomKey},
  } = useRoute();

  const {channels} = usePusherService();

  const [channel, setChannel] = useState<ChatChannel | null>(null);
  const [users, setUsers] = useState<User[]>([]);

  const {activeAccountRole} = useLocalUserSettings();

  /**
   * Set the current channel whenver the channels or room key are updated.
   */
  useEffect(() => {
    const splitKey = roomKey.split('-');
    const chanName = `${splitKey[0]}Channel`;

    if (splitKey.length == 2) {
      const chan = channels.filter(
        (c) =>
          c.identifier === chanName && c.model.id.toString() === splitKey[1],
      );

      if (chan.length > 0) {
        setChannel(chan[0]);
      }
    }
  }, [channels, setChannel, roomKey]);

  useEffect(() => {
    if (channel && channel.identifier === 'PropertyChannel') {
      if (activeAccountRole === AccountRole.Landlord) {
        Property.select({
          properties: ['id'],
          tenancies: ['id'],
          users: ['name', 'id'],
          tenancy_memberships: ['id'],
        })
          .includes([
            {current_tenancy: {tenancy_memberships: 'renter'}},
            'landlord',
          ])
          .find(channel.model.id)
          .then((p) => {
            if (p.data.currentTenancy) {
              if (p.data.currentTenancy.tenancyMemberships) {
                const renters = p.data.currentTenancy.tenancyMemberships.map(
                  (m) => m.renter,
                ) as User[];

                const chatters: User[] = renters.concat(p.data.landlord);

                setUsers(chatters);
              }
            }
          });
      } else {
        Tenancy.select({
          properties: ['id'],
          tenancies: ['id'],
          users: ['name', 'id'],
          tenancy_memberships: ['id'],
        })
          .includes({tenancy_memberships: 'renter', property: 'landlord'})
          .where({propertyId: channel.model.id})
          .first()
          .then((t) => {
            if (t.data) {
              if (t.data.tenancyMemberships) {
                const renters = t.data.tenancyMemberships.map(
                  (m) => m.renter,
                ) as User[];

                const chatters: User[] = renters.concat(
                  t.data.property.landlord,
                );

                setUsers(chatters);
              }
            }
          });
      }
    }
  }, [channel, activeAccountRole]);

  const chatBodyScrollContainerRef = useRef<HTMLDivElement>();
  const {height, ref: chatBodyRef} = useResizeDetector();

  const scrollToLatestMessages = useCallback(() => {
    if (chatBodyScrollContainerRef.current) {
      chatBodyScrollContainerRef.current.scrollTop =
        chatBodyScrollContainerRef.current.scrollHeight;
    }
  }, [chatBodyScrollContainerRef]);

  useEffect(() => {
    scrollToLatestMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, scrollToLatestMessages]);

  /**
   * Height of web header and/or ghost user warning.
   */
  const topOuterContentHeight = useElementHeight('top-outer-content');

  return (
    <Page
      title="Chat"
      unbounded
      disableTabs
      largeTitle={false}
      loading={!channel}>
      {() => (
        <>
          <div
            className="relative"
            style={{
              height: `calc(100vh - (var(--f7-navbar-height) + var(--f7-safe-area-top) + var(--f7-safe-area-bottom)) - ${
                Capacitor.isNativePlatform() || NATIVE_LAYOUT_IN_WEB
                  ? '0px'
                  : `${topOuterContentHeight}px - 1px`
              })`,
            }}>
            <div className="h-full max-h-full flex flex-col">
              <ChatDetailHeader channel={channel} users={users} />
              <div
                ref={chatBodyScrollContainerRef}
                className={clsx(
                  'flex-1 relative',
                  'flex flex-col justify-between',
                  'w-full',
                  'overflow-auto',
                )}>
                <div ref={chatBodyRef}>
                  <ChatDetailBody channel={channel} users={users} />
                </div>
              </div>
              <div className="p-2 border-t border-brand-75">
                <ChatInputBox channel={channel} />
              </div>
            </div>
          </div>
        </>
      )}
    </Page>
  );
};

export default ChatDetailPage;

{
  /* <div className="sticky top-0 left-0 w-full h-32 bg-fuchsia-500"></div> */
}
{
  /* <div
              className={clsx(
                'sticky top-0 left-0',
                'bg-yellow-700',
                // 'flex-1 bg-fuchsia-500 relative',
                // 'flex flex-col justify-between',
                // 'bounded-x w-full box-border',
              )}>
              
            </div> */
}
