import {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {SocialPlatform} from 'auth/social/platforms';
import useSocialAuthScripts from 'auth/social/useSocialAuthScripts';
import TenantRegisterModal from 'components/auth/TenantRegisterModal';
import {SocialAuthButton} from 'components_sb/buttons';
import {TextField} from 'components_sb/input';
import {Divider, Modal, ModalDefinition} from 'components_sb/layout';
import {Title} from 'components_sb/typography';

const {useModal} = Modal.Imperative;

const TenantLoginModal: ModalDefinition = {
  title: '',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'login',
        label: {
          idle: 'Login',
          loading: 'Logging in',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, registerReason, loginReason} = props;
    const openModal = useModal();

    useSocialAuthScripts();

    const {login} = useAuth();

    const showGenericError = useCallback(() => {
      toast.error(
        `There was an issue logging you in. Please try again later or contact us for support.`,
      );
    }, []);

    const handleFormSubmit = useCallback(
      async (formData: any, actions: any) => {
        try {
          await login({method: 'email', loginData: formData});

          closeModal();
        } catch (error) {
          const {message} = error;
          // Handle invalid email or password
          if (message?.toLowerCase().includes('invalid email or password')) {
            actions.setFieldError('email', true);
            actions.setFieldError('password', 'Incorrect email or password');
          } else {
            showGenericError();
          }
          actions.setSubmitting(false);
        }
      },
      [login, showGenericError, closeModal],
    );

    const onSocialAuthSuccess = useCallback(
      async (platform: SocialPlatform, loginData: any) => {
        try {
          // Attempt the login request
          await login({method: platform, loginData});
          closeModal();
        } catch (error) {
          // Error performing the login request
          showGenericError();
        }
      },
      [login, showGenericError, closeModal],
    );

    const onSocialAuthError = useCallback(() => {
      showGenericError();
    }, [showGenericError]);

    const formik = useFormik({
      onSubmit: handleFormSubmit,
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: Yup.object().shape({
        email: Yup.string()
          .email('Invalid email address')
          .required('Please enter your email address'),
        password: Yup.string().required('Please enter your password'),
      }),
      validateOnBlur: false,
      validateOnChange: false,
    });

    const onSave = useCallback(async () => {
      formik.submitForm();
      return false;
    }, [formik]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    const showRegisterModal = () => {
      closeModal();
      setTimeout(
        () => openModal(TenantRegisterModal, {registerReason, loginReason}),
        200,
      );
    };

    return (
      <div>
        <Title level="h1" subtitle={loginReason}>
          Login
        </Title>

        {/* Social auth section */}
        <div className="flex-1 flex flex-col gap-y-4 justify-center items-center mt-0 max-w-none xl:max-w-sm mx-auto">
          {[
            SocialPlatform.Facebook,
            SocialPlatform.Google,
            SocialPlatform.Apple,
          ].map((platform) => (
            <SocialAuthButton
              key={platform}
              platform={platform}
              config={{
                action: 'login',
                onSuccess: onSocialAuthSuccess,
                onError: onSocialAuthError,
              }}
            />
          ))}
        </div>

        <Divider orientation="horizontal" labelPosition="middle">
          OR
        </Divider>

        <form className="flex flex-col items-center gap-y-8">
          <div className="w-full flex flex-col gap-y-8">
            <TextField
              name="email"
              label="Email"
              type="email"
              size="base"
              mode="formik"
              form={formik}
              placeholder="Enter your email address here..."
              required
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              size="base"
              mode="formik"
              form={formik}
              placeholder="Enter your password here..."
              required
            />
          </div>
        </form>
        <span className="w-full flex flex-col xs:flex-row items-center justify-between gap-x-12 gap-y-4">
          <a className="link link-primary" onClick={showRegisterModal}>
            Don't have an account yet?
          </a>
        </span>
      </div>
    );
  },
};

export default TenantLoginModal;
