import {FunctionComponent} from 'react';

import {BiBath, BiBed, BiCar, BiDollarCircle} from 'react-icons/bi';

import {toCurrency} from 'utilities/StringHelpers';

import InlineStatItem from './InlineStatItem';

interface InlineStatsProps {
  bedrooms?: number;
  bathrooms?: number;
  garages?: number;
  totalRent?: number;
  rentalPeriod?: string;
}

const InlineStats: FunctionComponent<InlineStatsProps> = ({
  bedrooms,
  bathrooms,
  garages,
  totalRent,
  rentalPeriod,
}) => (
  <div className="flex flex-row gap-x-6">
    <InlineStatItem icon={BiBed} quantity={bedrooms} />
    <InlineStatItem icon={BiBath} quantity={bathrooms} />
    <InlineStatItem icon={BiCar} quantity={garages} />
    {totalRent && (
      <InlineStatItem
        icon={BiDollarCircle}
        quantity={`${toCurrency(totalRent)} / ${rentalPeriod}` as any}
      />
    )}
  </div>
);

export default InlineStats;
