import {useMemo, useState} from 'react';

import {sortBy} from 'lodash';
import {useQuery} from 'react-query';
import {useLocalStorage} from 'usehooks-ts';

import EnquiryThreadsList from 'components/enquiries/EnquiryThreadsList/EnquiryThreadsList';
import {ListingEnquiryTitleMode} from 'components/enquiries/EnquiryThreadsList/types';
import ManageListingMenu from 'components/listing/ManageListingMenu';
import {Switch} from 'components_sb/input';
import EnquiryThread from 'models/listings/EnquiryThread';
import Listing from 'models/listings/Listing';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import {Action} from 'types/actions';

const EnquiryThreadIndexPage = () => {
  const {
    params: {propertyId, listingPublicId},
  } = useRoute();

  const [showArchived, setShowArchived] = useLocalStorage<boolean>(
    'show-archived-enquiries',
    false,
  );

  const enquiryThreadsQuery = useQuery(
    [
      `listing-enquiry-threads`,
      {
        listingPublicId,
      },
      {
        /**
         * By specifying the state of the archived enquiry visibility here, when the
         * user toggles the visibility, the query will be re fetched.
         */
        includeArchived: showArchived,
      },
    ],
    async () => {
      const listing = await Listing.find(listingPublicId);

      const {data: enquiryThreads} = await EnquiryThread.where({
        listingId: listing.data.id,
        /**
         * When archived enquires are set to be shown, we don't need to specify
         * the archived status of the enquiry threads because we want all enquiry
         * threads to be returned.
         */
        ...(showArchived ? {} : {archived: false}),
      })
        .includes({enquiry_messages: 'user'})
        .order({updated_at: 'desc'})
        .per(100)
        .all();

      return enquiryThreads;
    },
  );

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !listingPublicId
        ? null
        : [
            {
              label: 'View listing ad',
              linkTo: `/listings/${listingPublicId}`,
            },
          ],
    [listingPublicId],
  );

  return (
    <Page title="Enquiries" actions={pageActions}>
      {() => (
        <>
          <ManageListingMenu
            propertyId={propertyId}
            listingPublicId={listingPublicId}
          />
          <div className="w-full flex flex-row justify-end mb-8">
            <Switch
              id="archived-enquiries-toggle"
              labelPosition="left"
              labelProps={{
                title: 'Show archived enquiries',
              }}
              value={showArchived}
              onChange={(value) => setShowArchived(value)}
            />
          </div>
          <EnquiryThreadsList
            query={enquiryThreadsQuery}
            titleMode={ListingEnquiryTitleMode.User}
          />
        </>
      )}
    </Page>
  );
};

export default EnquiryThreadIndexPage;
