import moment from 'moment';
import {useQuery} from 'react-query';

import UserAvatar from 'components/user/UserAvatar';
import {Title} from 'components_sb/typography';
import useTailwindBreakpoint from 'hooks/useTailwindBreakpoint';
import ChatMessage from 'models/ChatMessage';

const PrintableMessages = ({
  chatableId,
  chatableType,
}: {
  chatableId: string;
  chatableType: string;
  isLandlord?: boolean;
  disableMedia?: boolean;
}) => {
  const {data} = useQuery(
    `${chatableType}-${chatableId}-messages`,
    async () => {
      const messages = await ChatMessage.where({
        chatable_id: chatableId,
        chatable_type: chatableType,
      })
        .order('created_at')
        .includes('user')
        .all();

      return messages.data;
    },
  );

  const isMobile = !useTailwindBreakpoint('sm');

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className="break-before-page">
      <Title level="h6" size="sm">
        Messages
      </Title>

      <div>
        {data?.map((message) => (
          <div key={message.id} className="mb-4">
            <div className="flex justify-start items-start space-x-4">
              <UserAvatar user={message.user} size={isMobile ? 8 : 10} />
              <div>
                <strong>{message.user.name}</strong>
                <p className="text-sm text-base-content text-opacity-60">
                  {message.content}
                </p>
                {message.media && message.media.length > 0 && (
                  <div className="my-2">
                    <a href={message.media}>
                      <img
                        className="bg-black tw-block h-[120px] w-[250px] object-cover break-inside-avoid"
                        src={message.mediaPreview || message.media}
                      />
                    </a>
                  </div>
                )}
                <p className="text-xs text-base-content text-opacity-60 italic">
                  {moment(message.createdAt).format('DD/MM/YYYY hh:mm a')}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrintableMessages;
