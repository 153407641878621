import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  type FormikHelpers,
  FormikConfig,
  FormikValues,
  useFormik,
} from 'formik';
import {useQueryClient} from 'react-query';
import * as Yup from 'yup';

import {CountableNumberField} from 'components/forms_fields';
import useScroll from 'hooks/useScroll';
import {useOnboardingFlowNavigation} from 'pages/landlord/onboarding/OnboardingFlowNavigation';
import {OnboardingFlowStepComponent} from 'pages/landlord/onboarding/OnboardingFlowPage';
import TrackingService from 'services/TrackingService';
import {saveResource} from 'utilities/SpraypaintHelpers';

import StepContainer from '../common/StepContainer';

type FormValues = {
  bedrooms: number;
  bathrooms: number;
  garages: number;
};

const GeneralInfoStep: OnboardingFlowStepComponent = ({property}) => {
  const {scrollToTop} = useScroll();

  useEffect(() => {
    /**
     * Scroll to the top of the page.
     */
    scrollToTop();
    /**
     * Track starting the step.
     */
    TrackingService.trackEvent(
      TrackingService.Event.NewTenancy_StartRoomsStep,
      {
        propertyId: property.id,
      },
    );
  }, [property.id, scrollToTop]);

  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    async (formValues: FormValues, actions: FormikHelpers<FormValues>) => {
      setSubmitting(true);

      /**
       * Set changes on the property.
       */
      property.assignAttributes(formValues);
      property.lastOnboardingStepCompleted = 'new_general_information';
      property.setProfileStepAsCompleted('general_information');

      /**
       * Save the changes to the property.
       */
      if (!(await saveResource(property))) {
        setSubmitting(false);
        return;
      }

      /**
       * Update the property data in the query cache.
       */
      queryClient.setQueryData(
        ['property', {id: property.id, context: 'onboarding-flow'}],
        property,
      );

      /**
       * Track completion of the step.
       */
      TrackingService.trackEvent(
        TrackingService.Event.NewTenancy_CompleteRoomsStep,
        {
          propertyId: property.id,
        },
      );

      setSubmitting(false);
      actions.setSubmitting(false);
    },
    [property, queryClient],
  );

  /**
   * Create the form config for defining the tenancy commencement date.
   */
  const formikConfig = useMemo<FormikConfig<FormikValues>>(
    () => ({
      initialValues: {
        bedrooms: property.bedrooms || 1,
        bathrooms: property.bathrooms || 1,
        garages: property.garages || 0,
      } as FormValues,
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        bathrooms: Yup.number().min(1).max(10).required().label('Bathrooms'),
        bedrooms: Yup.number().min(0).max(12).required().label('Bedrooms'),
        garages: Yup.number().min(0).max(12).required().label('Garages'),
      }),
    }),
    [handleSubmit, property],
  );

  /**
   * Create the form instance based on the config;
   */
  const form = useFormik(formikConfig);

  /**
   * Submit the form when the next button is clicked.
   */
  const onClickNext = useCallback(() => {
    form.submitForm();
  }, [form]);

  /**
   * Config for the onboarding flow navigation.
   */
  useOnboardingFlowNavigation({
    buttonsConfig: {
      next: {
        onClick: onClickNext,
        loading: submitting,
      },
    },
  });

  return (
    // TODO: Add a subtitle
    <StepContainer
      align="center"
      title="Tell us about your property..."
      subtitle="">
      <CountableNumberField
        labelProps={{
          title: 'Bedrooms',
          size: 'xl',
        }}
        name="bedrooms"
        form={form}
        align="center"
      />
      <CountableNumberField
        labelProps={{
          title: 'Bathrooms',
          size: 'xl',
        }}
        name="bathrooms"
        incrementValue={0.5}
        form={form}
        align="center"
      />
      <CountableNumberField
        labelProps={{
          title: 'Garages',
          size: 'xl',
        }}
        name="garages"
        form={form}
        align="center"
      />
    </StepContainer>
  );
};

export default GeneralInfoStep;
