import {useCallback, useEffect, useMemo, useState} from 'react';

import {BsPersonAdd, BsPersonGear} from 'react-icons/bs';
import {
  HiOutlineArrowCircleRight,
  HiOutlineCurrencyDollar,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlinePencil,
  HiOutlineTrash,
} from 'react-icons/hi';
import {RiBankLine} from 'react-icons/ri';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import ListingStats from 'components/listing/ListingStats';
import AfterAddPropertyAlert from 'components/property/landlord/detail_page_cards/AfterAddPropertyAlert';
import BondStatusCard from 'components/property/landlord/detail_page_cards/BondStatusCard';
import DocumentsCard from 'components/property/landlord/detail_page_cards/DocumentsCard';
import EndOfTenancyCard from 'components/property/landlord/detail_page_cards/EndOfTenancyCard';
import InspectionsCard from 'components/property/landlord/detail_page_cards/InspectionsCard';
import MainActions from 'components/property/landlord/detail_page_cards/MainActions';
import MaintenanceRequestsCard from 'components/property/landlord/detail_page_cards/MaintenanceRequestsCard';
import PendingTenancyCard from 'components/property/landlord/detail_page_cards/PendingTenancyCard';
import PreTenancyInspectionAlert from 'components/property/landlord/detail_page_cards/PreTenancyInspectionAlert';
import RentPriceChangesCard from 'components/property/landlord/detail_page_cards/RentPriceChangesCard';
import RentStatusCard from 'components/property/landlord/detail_page_cards/RentStatusCard';
import TenancyRenewalCard from 'components/property/landlord/detail_page_cards/TenancyRenewalCard';
import TenancySelectCard from 'components/property/landlord/detail_page_cards/TenancySelectCard';
import TenantsCard from 'components/property/landlord/detail_page_cards/TenantsCard';
import CompleteProfileSection from 'components/property/landlord/profile_blocks/CompleteProfileSection';
import PropertyDetailHeaderSection from 'components/property/shared/PropertyDetailHeaderSection';
import PrepareNextTenancyModal from 'components/tenancy/PrepareNextTenancyModal';
import TenancyStats from 'components/tenancy/TenancyStats';
import {Modal} from 'components_sb/layout';
import usePropertyBillingDetails from 'hooks/usePropertyBillingDetails';
import Tenancy from 'models/properties/Tenancy';
import PropertyDetailQuery from 'queries/landlord/PropertyDetailQuery';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';
import useRouter from 'router/hooks/useRouter';
import useConfirmationModalStore from 'stores/ConfirmationModalStore';
import {Action} from 'types/actions';

import SubscriptionStatusAlert from '../subscription/SubscriptionStatusAlert/SubscriptionStatusAlert';

const {useModal} = Modal.Imperative;

const PropertyDetailPage = () => {
  const route = useRoute();

  const {
    params: {propertyId},
  } = route;

  const router = useRouter();

  const openModal = useModal();

  const [selectedTenancyId, setSelectedTenancyId] = useState<
    string | undefined
  >(undefined);

  const {
    isLoading,
    isSuccess,
    data: property,
    error,
  } = useQuery(
    PropertyDetailQuery.queryKey(propertyId),
    PropertyDetailQuery.queryFn(propertyId),
    {retry: 1, refetchOnWindowFocus: false},
  );

  const {billingActionRequired} = usePropertyBillingDetails(property);

  const tenancy: Tenancy | undefined = useMemo(() => {
    if (!property) {
      return undefined;
    }

    if (selectedTenancyId) {
      if (selectedTenancyId === '-1') {
        return undefined;
      } else {
        return property.tenancies.find((t) => t.id === selectedTenancyId);
      }
    } else {
      const t = property.tenancies.find(
        (t) => t.status === 'active' || t.status === 'active_periodic',
      );
      if (t) {
        return t;
      } else if (property.tenancies.length > 0) {
        return property.tenancies[0];
      } else {
        return undefined;
      }
    }
  }, [property, selectedTenancyId]);

  const activeListing = useMemo(() => {
    if (!property) {
      return undefined;
    }

    return property.listings.find((l) => l.isCurrent);
  }, [property]);

  const setConfirmationOptions = useConfirmationModalStore(
    (state) => state.setConfirmationOptions,
  );

  const deleteProperty = useCallback(async () => {
    if (property) {
      const tenancyCount = property.tenancies.filter((t) =>
        ['active', 'active_periodic', 'ended'].includes(t.status),
      ).length;

      if (tenancyCount > 0) {
        toast.error("You can't delete a property with active tenancies.");
      } else {
        const result = await property.destroy();
        if (result) {
          toast.success('Property deleted successfully.');
          router.navigate('/', {reloadAll: true});
        } else {
          toast.error(
            'There was an error deleting your property, please contact support if you would like assistance.',
          );
        }
      }
    }
  }, [router, property]);

  const confirmDeleteProperty = useCallback(() => {
    setConfirmationOptions({
      title: 'Delete your property',
      message:
        'Are you sure you want to do this? This action cannot be undone.',
      buttonTitle: 'Delete',
      color: 'error',
      action: deleteProperty,
    });
  }, [setConfirmationOptions, deleteProperty]);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () =>
      !isSuccess
        ? null
        : [
            /**
             * Edit property.
             */
            {
              label: 'Edit property',
              icon: HiOutlinePencil,
              linkTo: `edit`,
            },

            /**
             * Tenancy related actions:
             */
            ...(tenancy && tenancy.id
              ? [
                  /**
                   * Invite tenants.
                   */
                  ...(tenancy.isSigned
                    ? [
                        {
                          label: 'Invite tenants',
                          icon: BsPersonAdd,
                          linkTo: `tenancies/${tenancy.id}/invite-tenants`,
                        },
                      ]
                    : []),

                  /**
                   * Landlord Payments
                   */

                  {
                    label: 'Rent Ledger',
                    icon: HiOutlineCurrencyDollar,
                    linkTo: `tenancies/${tenancy.id}/payments`,
                  },

                  /**
                   * Change rent.
                   */
                  ...(tenancy?.unprocessedRentPriceChanges?.length === 0 &&
                  tenancy.isActive
                    ? [
                        {
                          label: 'Change rent',
                          icon: HiOutlineCurrencyDollar,
                          linkTo: `tenancies/${tenancy.id}/rent-changes/new`,
                        },
                      ]
                    : []),

                  /**
                   * Give Notice
                   */
                  ...(tenancy?.isActive
                    ? [
                        {
                          label: 'Give Notice',
                          icon: HiOutlineDocumentText,
                          linkTo: `tenancies/${tenancy.id}/notices/new`,
                        },
                      ]
                    : []),

                  /**
                   * View notices
                   * */
                  {
                    label: 'View notices',
                    icon: HiOutlineDocumentDuplicate,
                    linkTo: `tenancies/${tenancy.id}/notices`,
                  },
                ]
              : []),

            /**
             * Manage preferred tradespeople.
             */
            {
              label: 'Manage preferred tradespeople',
              icon: BsPersonGear,
              linkTo: `preferred-tradespeople`,
            },

            /**
             * View bank account information.
             */
            {
              label: 'View bank account information',
              icon: RiBankLine,
              linkTo: `bank-account`,
            },

            /**
             * Property related actions:
             */
            ...(property && property.id
              ? [
                  /**
                   * Edit inspection template.
                   */
                  ...(property.roomPlan
                    ? [
                        {
                          label: 'Edit inspection template',
                          icon: HiOutlinePencil,
                          linkTo: `inspection-template`,
                        },
                      ]
                    : []),

                  /**
                   * Prepare next tenancy.
                   */
                  ...(property.tenanciesCount >= 1
                    ? [
                        {
                          label: 'Prepare next tenancy',
                          icon: HiOutlineArrowCircleRight,
                          onClick: () =>
                            openModal(PrepareNextTenancyModal, {
                              property: property,
                            }),
                        },
                      ]
                    : []),

                  /**
                   * Delete property.
                   */
                  ...(property.tenancies.filter((t) =>
                    ['active', 'active_periodic', 'ended'].includes(t.status),
                  ).length === 0
                    ? [
                        {
                          label: 'Delete property',
                          icon: HiOutlineTrash,
                          onClick: confirmDeleteProperty,
                        },
                      ]
                    : []),
                ]
              : []),
          ],
    [tenancy, isSuccess, property, confirmDeleteProperty, openModal],
  );

  return (
    <Page
      title={property ? property.streetAddress : ''}
      loading={isLoading}
      error={error}
      actions={pageActions}
      unbounded
      backUrl="/">
      {() => (
        <>
          {/* Header section */}
          <PropertyDetailHeaderSection property={property} tenancy={tenancy} />

          {/* Main page content */}
          <div className="w-full bounded-x">
            {/* The below alert is only shown after redirecting from adding a property */}
            <AfterAddPropertyAlert />

            {/* Subscription status for this particular property and any billing action required */}
            <SubscriptionStatusAlert
              billingActionRequired={billingActionRequired}
            />

            {/* Key stats about the listing */}
            {activeListing && <ListingStats listing={activeListing} />}

            {/* Key stats about the property */}
            {tenancy?.isActive && (
              <TenancyStats property={property} tenancy={tenancy} />
            )}

            {/* The key actions the landlord can perform on the property */}
            <MainActions property={property} tenancy={tenancy} />

            <PreTenancyInspectionAlert tenancy={tenancy} />

            {property && tenancy && !tenancy.isDraft && (
              <CompleteProfileSection property={property} tenancy={tenancy} />
            )}

            <TenancySelectCard
              property={property}
              tenancy={tenancy}
              setSelectedTenancyId={setSelectedTenancyId}
            />

            <EndOfTenancyCard property={property} tenancy={tenancy} />
            <TenancyRenewalCard property={property} tenancy={tenancy} />
            <RentPriceChangesCard property={property} tenancy={tenancy} />

            <RentStatusCard property={property} tenancy={tenancy} />

            <BondStatusCard property={property} tenancy={tenancy} />

            <PendingTenancyCard property={property} tenancy={tenancy} />

            <InspectionsCard property={property} tenancy={tenancy} />
            <MaintenanceRequestsCard property={property} tenancy={tenancy} />
            <DocumentsCard property={property} tenancy={tenancy} />
            <TenantsCard property={property} tenancy={tenancy} />
          </div>
        </>
      )}
    </Page>
  );
};

export default PropertyDetailPage;
