import {sortBy} from 'lodash-es';
import moment from 'moment';

import MobileListItem from 'components_sb/lists/MobileListItem/MobileListItem';
import ServiceRequest from 'models/service_requests/ServiceRequest';
import {AccountRole} from 'models/users/User';
import {DATE_TIME_FORMAT} from 'utilities/DateHelpers';
import {removeUnderscores, titleize} from 'utilities/StringHelpers';

const ServiceRequestsTable = ({
  requests,
  clickHandler,
  accountType = AccountRole.Landlord,
}: {
  requests: ServiceRequest[];
  clickHandler: (requestId: string) => void;
  accountType?: AccountRole;
}) => {
  const sortedRequests = sortBy(requests, (i) => -parseInt(i.id));
  return (
    <div>
      <div className="overflow-x-auto hidden lg:tw-block">
        <table className="table w-full">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Status</th>
              <th>Job Date</th>
              {accountType == AccountRole.Landlord && <th>Final Cost ($)</th>}
            </tr>
          </thead>
          <tbody>
            {sortedRequests.map((request, index) => {
              const date = request.appointmentTime
                ? moment(request.appointmentTime).format(DATE_TIME_FORMAT)
                : 'N/A';

              return (
                <tr
                  key={request.id}
                  onClick={() => clickHandler(request.id)}
                  className="hover cursor-pointer">
                  <td>{requests.length - index}</td>
                  <td>{request.title}</td>
                  <td>{titleize(removeUnderscores(request.status))}</td>
                  <td>{date}</td>
                  {accountType === AccountRole.Landlord && (
                    <td>{request.finalizedCost || 'N/A'}</td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="tw-block lg:hidden">
        <ul className="flex flex-col gap-2">
          {sortedRequests.map((request) => (
            <MobileListItem
              key={request.id}
              link={`service-requests/${request.id}`}
              title={request.title}
              subtitle={
                <>
                  <span>{titleize(removeUnderscores(request.status))}</span>
                  <br />
                  <span>
                    {request.appointmentTime
                      ? moment(request.appointmentTime).format(DATE_TIME_FORMAT)
                      : 'N/A'}
                  </span>
                </>
              }
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ServiceRequestsTable;
