import {useCallback, useMemo} from 'react';

import {AiOutlineRight} from '@react-icons/all-files/ai/AiOutlineRight';
import moment from 'moment';
import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import emptyBg from 'assets/img/empty_dataset_images/rental_applications.svg';
import EmptyDataSet from 'components/common/EmptyDataSet';
import {Card} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import RentalApplication from 'models/listings/RentalApplication';
import {AccountRole} from 'models/users/User';
import {Page, RouterLink} from 'router/components';
import useRouter from 'router/hooks/useRouter';
import {Action} from 'types/actions';

const RentalApplicationIndexPage = () => {
  const {activeAccountRole} = useLocalUserSettings();

  const router = useRouter();

  if (activeAccountRole !== AccountRole.Renter) {
    toast.error('Sorry, this page is only for tenants.');
    router.navigate('/');
  }

  const {data, isLoading, error} = useQuery(
    `renter-rental-applications`,
    async () => {
      const apps = await RentalApplication.select(['id', 'created_at'])
        .includes(['rental_application_applicants'])
        .order({createdAt: 'desc'})
        .all();

      return apps.data;
    },
    {enabled: activeAccountRole === AccountRole.Renter},
  );

  const navigateToNew = useCallback(() => router.navigate('new'), [router]);

  /**
   * General actions that are available for the whole page
   * and accessible throughout the page.
   */
  const pageActions = useMemo<Action[]>(
    () => [
      {
        label: 'New application',
        linkTo: `/rental-applications/new`,
      },
    ],
    [],
  );

  return (
    <Page
      title="Rental Applications"
      actions={pageActions}
      loading={isLoading}
      error={error}>
      {() =>
        data.length > 0 ? (
          <Card title="Your Rental Applications" className="mt-2">
            <Paragraph>
              Below are the rental applications you have created, the most
              recent one will be used to apply when you click the apply button
              on a listing.
            </Paragraph>

            {data.map((app) => (
              <RouterLink
                key={app.id}
                href={app.id}
                className="my-2 py-2 px-2 flex justify-between items-center hover:bg-gray-100">
                <div className="flex flex-col">
                  <strong>
                    Created {moment(app.createdAt).format('DD MMM YYYY')}
                  </strong>
                  {app.rentalApplicationApplicants.length > 0 && (
                    <span className="tw-block text-sm">
                      With:{' '}
                      {app.rentalApplicationApplicants
                        .map((a) => a.name)
                        .join(', ')}
                    </span>
                  )}
                  <RouterLink
                    href={`${app.id}/listings`}
                    className="btn btn-neutral btn-sm mt-2">
                    View Listings Applied For
                  </RouterLink>
                </div>
                <div>
                  <AiOutlineRight className="w-5 h-5 text-gray-300" />
                </div>
              </RouterLink>
            ))}
          </Card>
        ) : (
          <EmptyDataSet
            title="No rental applications found"
            message="You haven't created a rental application yet. Click the button below to create an application and start applying for properties."
            image={emptyBg}
            buttonTitle="New Application"
            buttonAction={navigateToNew}
          />
        )
      }
    </Page>
  );
};

export default RentalApplicationIndexPage;
