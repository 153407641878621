import {useQuery} from 'react-query';

import {ResultMessage} from 'components_sb/feedback';
import PrivateViewing from 'models/listings/PrivateViewing';
import {Page} from 'router/components';
import useRoute from 'router/hooks/useRoute';

import PrivateViewingForm from './shared/PrivateViewingForm';

const EditPrivateViewingPage = () => {
  const {
    params: {listingPublicId, privateViewingId},
  } = useRoute();

  /**
   * Fetch the private viewing.
   */
  const {
    data: privateViewing,
    isLoading: isLoadingPrivateViewing,
    isError: errorLoadingPrivateViewing,
    isSuccess: successfullyLoadedPrivateViewing,
  } = useQuery(
    ['listing', 'private-viewing', privateViewingId],
    async () => (await PrivateViewing.find(privateViewingId)).data,
  );

  return (
    <Page title="Edit Private Viewing" loading={isLoadingPrivateViewing}>
      {() => (
        <>
          {errorLoadingPrivateViewing && (
            <ResultMessage type="error">
              Sorry, there was an issue loading the details for this private
              viewing.
            </ResultMessage>
          )}
          {successfullyLoadedPrivateViewing && (
            <PrivateViewingForm
              mode="edit"
              listingPublicId={listingPublicId}
              privateViewing={privateViewing}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default EditPrivateViewingPage;
