import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import {create} from 'zustand';

import Inspection from 'models/inspections/Inspection';
import InspectionItem from 'models/inspections/InspectionItem';

PouchDB.plugin(PouchDBFind);

type InspectionStore = {
  inspection?: Inspection;
  inspectionItems?: InspectionItem[];
  selectedRoomKey?: string;

  database: PouchDB.Database;

  setInspection: (i: Inspection) => void;
  setInspectionItems: (items: InspectionItem[]) => void;
  setSelectedRoomKey: (key: string) => void;
};

export type PendingUploadRow = {
  data: string;
  size?: number;
  attachmentType: 'normal' | 'action' | 'note';
  inspectionId: string;
  inspectionItemId: string;
  notes?: string | null;
  actionParams?: {
    action: string;
    actionType: 'landlord' | 'tenant';
    amended: boolean;
    shouldCreateMaintenanceRequest: boolean;
    propertyId: string;
  };
  _id: string;
};

class _InspectionEventEmitter {
  private _events: Record<string, any> = {};
  constructor() {
    this._events = {};
  }

  on(name: string, listener: any) {
    if (!this._events[name]) {
      this._events[name] = [];
    }

    this._events[name].push(listener);
  }

  removeListener(name: string, listenerToRemove: any) {
    if (!this._events[name]) {
      throw new Error(
        `Can't remove a listener. Event "${name}" doesn't exist.`,
      );
    }

    const filterListeners = (listener: any) => listener !== listenerToRemove;

    this._events[name] = this._events[name].filter(filterListeners);
  }

  emit(name: string, data: any) {
    if (!this._events[name]) {
      throw new Error(`Can't emit an event. Event "${name}" doesn't exist.`);
    }

    const fireCallbacks = (callback: any) => {
      callback(data);
    };

    this._events[name].forEach(fireCallbacks);
  }
}

export const InspectionEventEmitter = new _InspectionEventEmitter();

const useInspectionStore = create<InspectionStore>(
  (set): InspectionStore => ({
    inspection: undefined,
    inspectionItems: undefined,
    selectedRoomKey: undefined,

    database: new PouchDB('inspections'),

    setInspection: (i) => set({inspection: i}),
    setInspectionItems: (items) => set({inspectionItems: items}),
    setSelectedRoomKey: (key) => set({selectedRoomKey: key}),
  }),
);

export default useInspectionStore;
