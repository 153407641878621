import {useCallback, useImperativeHandle} from 'react';

import {useFormik} from 'formik';
import {isEmpty} from 'lodash';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import useAuth from 'auth/provider/useAuth';
import {TextareaField, ToggleField} from 'components/forms_fields';
import FormRow from 'components/forms_fields/FormRow';
import FormRowItem from 'components/forms_fields/FormRowItem';
import InspectionMediaPreview from 'components/inspection/media_items/InspectionMediaPreview';
import {InlineError} from 'components_sb/feedback';
import {ModalDefinition} from 'components_sb/layout';
import useLocalUserSettings from 'hooks/useLocalUserSettings';
import InspectionAction from 'models/inspections/InspectionAction';
import User, {AccountRole} from 'models/users/User';
import useInspectionStore from 'stores/InspectionStore';

type FormValues = {
  action: string;
  actionType: string | null;
  attachment: string | null;
  shouldCreateMaintenanceRequest: boolean;
  inspectionItemId: string;
};

const InspectionAddActionFromAttachmentModal: ModalDefinition = {
  title: 'Add an action',
  buttonsConfig: {
    cancel: {
      label: 'Cancel',
    },
    actions: [
      {
        id: 'save',
        label: {
          idle: 'Save',
          loading: 'Saving',
        },
        handle: 'onSave',
        closeOnSuccess: false,
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const {closeModal, inspectionItem, attachment} = props;
    const {currentUser} = useAuth();
    const {activeAccountRole} = useLocalUserSettings();

    const [inspection, inspectionItems, setInspectionItems] =
      useInspectionStore((state) => [
        state.inspection,
        state.inspectionItems,
        state.setInspectionItems,
      ]);

    const handleSubmit = useCallback(
      async (formValues: FormValues, formikHelpers: any) => {
        const action = new InspectionAction(formValues);
        action.attachment = attachment.attachment;

        const result = await action.save();

        if (result) {
          const item = inspectionItems.find((i) => i.id === inspectionItem.id);

          action.user = new User({
            id: currentUser.id,
            name: currentUser.name,
            avatar: currentUser.avatar,
            email: currentUser.email,
          });
          action.user.isPersisted = true;

          item.inspectionActions.push(action);

          setInspectionItems([...inspectionItems]);

          inspection.deleteSignOffs();
          formikHelpers.setSubmitting(false);
          closeModal();
          toast.success('Action successfully added!');
        } else {
          for (const key in action.errors) {
            const err = action.errors[key]?.fullMessage;
            formikHelpers.setFieldError(key, err);
          }
        }
      },
      [
        closeModal,
        currentUser,
        inspection,
        inspectionItem,
        inspectionItems,
        attachment,
        setInspectionItems,
      ],
    );

    const formik = useFormik<FormValues>({
      initialValues: {
        action: '',
        actionType: null,
        attachment: attachment.attachment,
        shouldCreateMaintenanceRequest: false,
        inspectionItemId: inspectionItem.id,
      },
      onSubmit: handleSubmit,
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object().shape({
        action: Yup.string().required().min(5).label('Action'),
        actionType: Yup.string()
          .required()
          .nullable()
          .oneOf(['landlord', 'tenant'])
          .label('Action responsibility'),
        attachment: Yup.string().required().nullable().label('Photo'),
        shouldCreateMaintenanceRequest: Yup.boolean().label(
          'Create a maintenance request?',
        ),
      }),
    });

    const onSave = useCallback(async () => {
      const result = await formik.validateForm();
      if (isEmpty(result)) {
        const {setSubmitting, setFieldError} = formik;
        await handleSubmit(formik.values, {setSubmitting, setFieldError});
      }
      return false;
    }, [formik, handleSubmit]);

    useImperativeHandle(ref, () => ({
      onSave,
    }));

    return (
      <>
        <div className="mt-2">
          <InspectionMediaPreview
            source={attachment}
            onClick={() => {
              // TODO: Show lightbox
            }}
          />
        </div>

        <div className="mt-2">
          <TextareaField
            formik={formik}
            labelProps={{title: 'Action', size: 'base'}}
            name="action"
            placeholder="What needs to be done?"
          />
        </div>

        <div className="mt-2">
          <label className="label flex justify-start items-center">
            <span className="label-text mr-2">Who is the action for?</span>
          </label>
          <FormRow>
            <FormRowItem>
              <button
                type="button"
                disabled={
                  formik.isSubmitting ||
                  activeAccountRole === AccountRole.Renter
                }
                className={`btn  w-full ${
                  formik.values.actionType === 'landlord'
                    ? 'btn-primary'
                    : 'btn-neutral btn-outline'
                }`}
                onClick={() => formik.setFieldValue('actionType', 'landlord')}>
                Landlord
              </button>
              <InlineError error={formik.errors.actionType} />
            </FormRowItem>

            <FormRowItem>
              <button
                type="button"
                disabled={formik.isSubmitting}
                className={`btn w-full ${
                  formik.values.actionType === 'tenant'
                    ? 'btn-primary'
                    : 'btn-neutral btn-outline'
                }`}
                onClick={() => formik.setFieldValue('actionType', 'tenant')}>
                Tenant
              </button>
            </FormRowItem>
          </FormRow>
        </div>
        <div className="mt-2">
          <ToggleField
            formik={formik}
            name="shouldCreateMaintenanceRequest"
            label="Create a maintenance request"
          />
        </div>
      </>
    );
  },
};

export default InspectionAddActionFromAttachmentModal;
