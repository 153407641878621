import React, {useMemo, useRef} from 'react';

import {Capacitor} from '@capacitor/core';
import _ from 'lodash';
import moment from 'moment';
import {HiCheck, HiX} from 'react-icons/hi';
import {useReactToPrint} from 'react-to-print';

import ChatableMessages from 'components/chat/ChatableMessages';
import {CalendarIcon} from 'components/common/CalendarIcon';
import HelpTextPresenter from 'components/forms_fields/HelpTextPresenter';
import ActionsList from 'components/inspection/actions/ActionsList';
import InspectionItemTitleWithCounts from 'components/inspection/items/InspectionItemTitleWithCounts';
import MediaItemGrid from 'components/inspection/media_items/MediaItemGrid';
import NoteList from 'components/inspection/notes/NoteList';
import PrintableInspectionList from 'components/inspection/PrintableInspectionList';
import SignOffView from 'components/inspection/sign_offs/SignOffView';
import {Card} from 'components_sb/layout';
import useInspectionStore from 'stores/InspectionStore';
import {Action} from 'types/actions';
import {titleize} from 'utilities/StringHelpers';

const helpTexts = {
  'Lights/Power points':
    'Are all lights and power points in the property working?',
  'Smoke alarms':
    "Test all smoke alarms in the property to check if they're working.",
} as Record<string, string>;

const CompletedInspectionList = () => {
  const [inspection, rawInspectionItems] = useInspectionStore((state) => [
    state.inspection,
    state.inspectionItems,
  ]);

  const inspectionItems = rawInspectionItems
    ? _.groupBy(rawInspectionItems, 'room')
    : {};

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'print-body',
  });

  let title: string;
  if (inspection.inspectionPeriodType === 'pre_tenancy') {
    title = 'Initial Inspection';
  } else if (inspection.inspectionPeriodType === 'final') {
    title = 'Final Inspection';
  } else {
    title = 'Routine Inspection';
  }

  const renderRoomCard = (roomKey: string) => {
    const items = inspectionItems[roomKey];

    if (roomKey === 'All') {
      return <div key={roomKey} />;
    }

    if (inspection.reportType === 'detailed') {
      return (
        <Card title={roomKey} className="mt-4" key={roomKey}>
          {items.map((item, index) => {
            return (
              <div className="my-2" key={index}>
                <InspectionItemTitleWithCounts item={item} size="small" />

                <MediaItemGrid inspection={inspection} item={item} />

                <NoteList inspection={inspection} item={item} />
                <ActionsList inspection={inspection} item={item} />
              </div>
            );
          })}
        </Card>
      );
    } else {
      const item = items[0];

      return (
        <Card className="mt-4" key={roomKey}>
          <InspectionItemTitleWithCounts item={item} />

          <MediaItemGrid inspection={inspection} item={item} />

          <NoteList inspection={inspection} item={item} />
          <ActionsList inspection={inspection} item={item} />
        </Card>
      );
    }
  };

  const renderSignOffs = () => {
    return (
      <Card title="Sign Offs">
        <div className="mt-4 flex justify-start items-center gap-8 flex-wrap">
          {inspection.inspectionSignOffs.map((signOff) => (
            <SignOffView signOff={signOff} key={signOff.id} />
          ))}
        </div>
      </Card>
    );
  };

  const renderAllRoom = () => {
    const all = inspectionItems.All;

    if (all) {
      return (
        <Card>
          {all.map((item, index) => {
            const helpText = helpTexts[item.name];
            return (
              <div key={index} className={index > 0 ? 'mt-4' : ''}>
                <div className="flex flex-row gap-x-2 justify-between items-center">
                  <span className="font-semibold flex items-center">
                    {item.name}
                    <HelpTextPresenter>{helpText}</HelpTextPresenter>
                  </span>
                  <div className="flex-1 h-px bg-brand-50" />
                  {item.toggleState ? (
                    <HiCheck className="text-green-500 w-6 h-6" />
                  ) : (
                    <HiX className="text-red-500 w-6 h-6" />
                  )}
                </div>

                <NoteList inspection={inspection} item={item} />
                <ActionsList inspection={inspection} item={item} />
              </div>
            );
          })}
        </Card>
      );
    }
  };

  const cardActions = useMemo(() => {
    if (Capacitor.isNativePlatform()) {
      return [] as Action[];
    } else {
      return [
        {
          label: 'Print',
          onClick: handlePrint,
        },
      ] as Action[];
    }
  }, [handlePrint]);

  return (
    <div>
      <Card title={title} className="mt-4" actions={cardActions}>
        <div className="flex justify-start items-center gap-4 ">
          <CalendarIcon date={new Date(inspection.finalizedDate)} />

          <div>
            <p className="text-secondary">
              {moment(inspection?.finalizedDate).format(
                inspection?.newInspectionTimeFormat,
              )}
            </p>
            <p className="text-secondary">
              Conducted by the {titleize(inspection.doneBy)}
            </p>
          </div>
        </div>
      </Card>

      {Object.keys(inspectionItems).map(renderRoomCard)}

      {renderAllRoom()}

      {renderSignOffs()}

      <ChatableMessages
        chatableId={inspection.id}
        chatableType="Inspection"
        disableMedia
      />

      {!Capacitor.isNativePlatform() && (
        <div className="hidden">
          <PrintableInspectionList
            ref={printRef}
            inspection={inspection}
            inspectionItems={inspectionItems}
          />
        </div>
      )}
    </div>
  );
};

export default CompletedInspectionList;
