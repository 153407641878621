import {useEffect, useMemo} from 'react';

import dayjs, {Dayjs} from 'dayjs';

import BillingMethod from 'models/billing/BillingMethod';
import Property, {PaymentMethodType} from 'models/properties/Property';
import {TenancyStatus} from 'models/properties/Tenancy';
import {AccountRole} from 'models/users/User';
import {useCreditCards} from 'providers/CreditCardsProvider';
import useSubscriptions from 'providers/Subscriptions/hooks/useSubscriptions';
import parseDateAsNZTimezone from 'utilities/parse-date-as-nz-timezone';

import useLocalUserSettings from './useLocalUserSettings';

export interface PropertyBillingDetails {
  /**
   * The weekly subscription fee for the Landlord when Smart Rent is used for payment.
   */
  smartRentSubscriptionAmount: number | null;
  /**
   * The weekly subscription fee for the Landlord when a credit/debit card is used for payment.
   */
  creditCardSubscriptionAmount: number | null;
  /**
   * The status of the current tenancy for the property.
   */
  currentTenancyStatus: TenancyStatus | null;
  /**
   * How subscription fees will be paid for the property.
   */
  paymentMethodType: PaymentMethodType | null;
  /**
   * Whether the user has explicitly set a credit cart for their property
   * rather than using the default credit card for their account.
   */
  hasExplicitlySetCard: boolean;
  /**
   * The credit or debit card linked to this property that the subscription fee payments will be charged to.
   */
  linkedCard: BillingMethod | null;
  /**
   * Whether the chosen payment method for the property has been configured so that subscription fee payments can be made.
   */
  paymentConfigured: boolean;
  /**
   * The currently active subscription fee and billing period for the property.
   */
  subscriptionPaymentSchedule: string | null;
  /**
   * The date in which the next subscription fee will incur via the chosen payment method.
   */
  nextSubscriptionPaymentDate: Dayjs | null;
  /**
   * Whether the subscription is active for the property (the property is due
   * to be billed at some point in the future).
   */
  subscriptionIsActive: boolean;
  /**
   * Whether the property has an active subscription and payment has not
   * been configured.
   */
  billingActionRequired: boolean;
}

interface UsePropertyBillingDetails {
  (property?: Property): PropertyBillingDetails;
}

const usePropertyBillingDetails: UsePropertyBillingDetails = (property) => {
  const {activeAccountRole} = useLocalUserSettings();

  const isLandlord = useMemo(
    () => activeAccountRole === AccountRole.Landlord,
    [activeAccountRole],
  );

  /**
   * Validate that the property includes the required attributes.
   */
  useEffect(() => {
    if (isLandlord && !!property) {
      if (property.currentTenancy === undefined) {
        throw new Error('Property is missing current_tenancy attribute.');
      } else if (property.paymentMethodType === undefined) {
        throw new Error('Property is missing payment_method_type attribute.');
      } else if (!!property.billingMethodId && !property.billingMethod) {
        console.log('property', property);
        throw new Error(
          'Property has a billing_method_id attribute but billing_method instance is missing.',
        );
      }
    }
  }, [isLandlord, property]);

  /**
   * Access the subscription instance for the Landlord's subscription.
   */
  const {landlordSubscription, subscriptionStatus} = useSubscriptions();

  /**
   * Get the weekly subscription fee when using Smart Rent for the Landlord's subscription.
   */
  const smartRentSubscriptionAmount = useMemo<number | null>(
    () =>
      isLandlord ? Number(landlordSubscription.subscription.amount) : null,
    [isLandlord, landlordSubscription],
  );

  /**
   * Get the weekly subscription fee when using credit cards for the Landlord's subscription.
   */
  const creditCardSubscriptionAmount = useMemo<number | null>(
    () =>
      isLandlord
        ? Number(landlordSubscription.subscription.creditCardAmount)
        : null,
    [isLandlord, landlordSubscription],
  );

  /**
   * Determine the current tenancy status for the property.
   */
  const currentTenancyStatus = useMemo<TenancyStatus | null>(
    () =>
      !isLandlord || !property || !property?.currentTenancy
        ? null
        : property?.currentTenancy.status,
    [isLandlord, property],
  );

  /**
   * The type of subscription payment method that has been chosen for the
   * current tenancy for the property.
   */
  const paymentMethodType = useMemo<PaymentMethodType | null>(
    () => (isLandlord ? property?.paymentMethodType ?? null : null),
    [isLandlord, property?.paymentMethodType],
  );

  /**
   * Access the credit cards context.
   */
  const creditCards = useCreditCards();

  /**
   * Whether the user has explicitly set a credit cart for their property
   * rather than using the default credit card for their account.
   */
  const hasExplicitlySetCard = useMemo<boolean>(
    () => isLandlord && !!property?.billingMethod,
    [isLandlord, property],
  );

  /**
   * The credit/debit card linked to the property if card payment has been configured.
   */
  const linkedCard = useMemo<BillingMethod | null>(() => {
    /**
     * If the property has a credit/debit card explicitly set, return that,
     * otherwise return the default card if there is one. If there are no
     * cards in either case, return null to indicate there is no linked card.
     */
    return !isLandlord
      ? null
      : hasExplicitlySetCard
      ? property.billingMethod
      : creditCards.default;
  }, [isLandlord, hasExplicitlySetCard, property, creditCards]);

  /**
   * Whether a valid payment method has been established for the property.
   * (i.e. can we start charging when we're ready to?)
   */
  const paymentConfigured = useMemo<boolean | null>(() => {
    return !isLandlord
      ? null
      : paymentMethodType === 'rent' ||
          (paymentMethodType === 'card' && !!linkedCard);
  }, [isLandlord, paymentMethodType, linkedCard]);

  /**
   * A string describing the payment schedule for the subscription (amount and period).
   */
  const subscriptionPaymentSchedule = useMemo<string | null>(() => {
    if (!paymentMethodType) {
      return null;
    }

    /**
     * The subscription is only active for smart rent when there is a tenancy active.
     */
    if (paymentMethodType === 'rent' && !property.currentTenancy.isActive) {
      return null;
    }

    const {rentalPeriod} = property.currentTenancy;

    const amount =
      paymentMethodType === 'card'
        ? creditCardSubscriptionAmount
        : smartRentSubscriptionAmount *
          (rentalPeriod === 'Fortnightly' ? 2 : 1);

    return `$${amount.toFixed(2)} + gst / ${
      paymentMethodType === 'card'
        ? 'week'
        : rentalPeriod === 'Fortnightly'
        ? 'fortnight'
        : 'week'
    }`;
  }, [
    paymentMethodType,
    property,
    creditCardSubscriptionAmount,
    smartRentSubscriptionAmount,
  ]);

  /**
   * The date in which the next subscription fee will incur via the chosen payment method.
   */
  const nextSubscriptionPaymentDate = useMemo<Dayjs | null>(() => {
    /**
     * There will be no queued charge if the subscription is not active.
     */
    if (!subscriptionPaymentSchedule) {
      return null;
    }

    /**
     * For Smart Rent, the next payment date will be the next rental payment date,
     * otherwise for credit/debit cards, the next payment date is set on the
     * property instance.
     *
     * NOTE: If the user is within their free period, the next payment date will
     * be AFTER the free period has ended. The backend updates the nextPaymentDate
     * attribute  on the property to reflect this, but we need to manually evaluate
     * this for properties using Smart Rent.
     */
    const date = (() => {
      if (paymentMethodType === 'card') {
        /**
         * Return the next card payment date.
         */
        return parseDateAsNZTimezone(property.nextPaymentDate);
      } else {
        if (subscriptionStatus.isWithinFreePeriod) {
          /**
           * Get the date of the next rent payment AFTER the free period.
           */
          return subscriptionStatus.freePeriodEndDate.weekday(
            dayjs()
              .localeData()
              .weekdays()
              .indexOf(property.currentTenancy.dayOfWeekRentPaid),
          );
        } else {
          /**
           * Return the date of the next rent payment.
           */
          return parseDateAsNZTimezone(
            property.currentTenancy.nextRentPaymentDue,
          );
        }
      }
    })();

    /**
     * If the date is invalid for whatever reason, return null so that the next
     * payment date will display as N/A.
     * This may occur if the next payment date has not been correctly set by the backend.
     */
    return date.isValid() ? date : null;
  }, [
    subscriptionPaymentSchedule,
    paymentMethodType,
    property,
    subscriptionStatus,
  ]);

  /**
   * Whether the subscription is active for the property (the property is due
   * to be billed at some point in the future).
   * Note that this does not necessarily mean the payment has been configured.
   * We can determine if the subscription is active if the property has a
   * subscription payment schedule.
   */
  const subscriptionIsActive = useMemo<boolean | null>(
    () => (!isLandlord ? null : !!subscriptionPaymentSchedule),
    [isLandlord, subscriptionPaymentSchedule],
  );

  /**
   * Whether the property has an active subscription and payment has not
   * been configured.
   */
  const billingActionRequired = useMemo<boolean | null>(
    () =>
      !isLandlord ? null : !!subscriptionPaymentSchedule && !paymentConfigured,
    [isLandlord, subscriptionPaymentSchedule, paymentConfigured],
  );

  return {
    smartRentSubscriptionAmount,
    creditCardSubscriptionAmount,
    currentTenancyStatus,
    paymentMethodType,
    hasExplicitlySetCard,
    linkedCard,
    paymentConfigured,
    subscriptionPaymentSchedule,
    nextSubscriptionPaymentDate,
    subscriptionIsActive,
    billingActionRequired,
  };
};

export default usePropertyBillingDetails;
