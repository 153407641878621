import {
  FunctionComponent,
  ReactNode,
  isValidElement,
  useCallback,
  useMemo,
} from 'react';

import {IconType} from '@react-icons/all-files';
import clsx from 'clsx';
import {Collapse, UnmountClosed} from 'react-collapse';
import {
  HiOutlineCheckCircle,
  HiOutlineExclamation,
  HiOutlineExclamationCircle,
  HiOutlineEyeOff,
  HiOutlineInformationCircle,
  HiOutlineXCircle,
} from 'react-icons/hi';

import {ResponsiveActionSelect} from 'components_sb/buttons';
import useCurrentUserFlag from 'hooks/useCurrentUserFlag';
import {Action} from 'types/actions';
import getLinkComponent from 'utilities/getLinkComponent';

export type AlertType = 'info' | 'success' | 'warning' | 'error';

interface BaseAlertProps {
  testId?: string;
  type?: AlertType;
  icon?: ReactNode | IconType;
  unmountOnClose?: boolean;
  show?: boolean;
}

type ConditionalContentAlertProps =
  | {
      children: string | string[];
      title?: never;
      description?: never;
    }
  | {
      children?: never;
      title: string;
      description?: string | string[];
    };

type ConditionalActionsAlertProps =
  | {
      asLink?: true;
      linkTo: string;
      actions?: never;
    }
  | {
      asLink?: never;
      linkTo?: never;
      actions?: Action[];
    };

type ConditionalVisibilityAlertProps =
  | {
      hideable?: true;
      id: string;
    }
  | {
      hideable?: false;
      id?: string;
    };

/**
 * Combine base props with all conditional props
 */
export type AlertProps = BaseAlertProps &
  ConditionalContentAlertProps &
  ConditionalActionsAlertProps &
  ConditionalVisibilityAlertProps;

const CLASSES = {
  info: 'bg-brand-100 text-brand-600',
  success: 'bg-green-200 text-green-700',
  warning: 'bg-yellow-200 text-yellow-700',
  error: 'bg-red-200 text-red-700',
};

const ICONS = {
  info: HiOutlineInformationCircle,
  success: HiOutlineCheckCircle,
  warning: HiOutlineExclamation,
  error: HiOutlineXCircle,
};

const renderDescription = (description: string | string[]) => (
  <div className="flex flex-col gap-y-1">
    {Array.isArray(description)
      ? description.map((paragraph) => <span key={paragraph}>{paragraph}</span>)
      : description}
  </div>
);

const Alert: FunctionComponent<AlertProps> = ({
  id,
  testId,
  children,
  title,
  description,
  type = 'info',
  asLink = false,
  linkTo,
  show = true,
  hideable = false,
  icon,
  unmountOnClose = true,
  ...props
}) => {
  const hideFlag = useCurrentUserFlag(`hide_alert:${id}`);

  /**
   * The visibility for the alert will be handled differently depending on whether
   * the hideable prop has been set. The show prop indicates whether the alert should
   * be shown, but the hideable prop will take priority once the user hides the alert.
   */
  const shouldShow = useMemo(() => {
    return (
      show &&
      // Hideable prop not set - use value of show prop
      (!hideable ||
        // The hideable prop is set - combine with the show prop and check user flag
        (hideable && hideFlag.isReady && hideFlag.value !== true))
    );
  }, [show, hideable, hideFlag]);

  /**
   * Render the icon element.
   */
  const iconElement = useMemo(() => {
    const renderReactIcon = (Icon: IconType) => (
      <Icon className="w-6 h-6 flex-shrink-0" />
    );
    if (icon) {
      /**
       * If the icon prop is an element, we rendering it directly.
       * Otherwise, we can assume it is a react-icons icon type, so
       * we render the icon as a component.
       */
      if (isValidElement(icon)) {
        return icon;
      } else {
        return renderReactIcon(icon as IconType);
      }
    } else {
      return renderReactIcon(ICONS[type]);
    }
  }, [icon, type]);

  /**
   * The close function within this component is only used when
   * the hideable prop has been set. If the hidable prop is not
   * set, any close functionality must be handled outside of the
   * component via the actions prop.
   */
  const onHide = useCallback(async () => {
    await hideFlag.set(true);
  }, [hideFlag]);

  const WrapperComponent = useMemo(
    () => (asLink ? getLinkComponent(linkTo) : 'div'),
    [asLink, linkTo],
  );

  /**
   * Merge the provided actions with the close action if the
   * hideable prop has been set.
   */
  const actions = useMemo<Action[]>(
    () => [
      ...(props.actions ?? []),
      ...(hideable
        ? [{icon: HiOutlineEyeOff, label: 'Hide', onClick: onHide}]
        : []),
    ],
    [props.actions, hideable, onHide],
  );

  const CollapseComponent = useMemo(
    () => (unmountOnClose ? UnmountClosed : Collapse),
    [unmountOnClose],
  );

  return (
    <CollapseComponent isOpened={shouldShow}>
      {/* react-collapse doesn't like margins on the child
      element, so we add padding to a parent instead */}
      <div className="pb-6 relative">
        <div
          className={clsx(
            CLASSES[type],
            'flex-1',
            'rounded-xl',
            // 'shadow-lg',
            'flex',
            'flex-row',
            'font-normal',
            'gap-x-3',
          )}>
          {/* Main area */}
          <WrapperComponent
            id={id}
            data-testid={testId}
            href={linkTo}
            className={clsx(
              'flex-1',
              'flex flex-row',
              'justify-start items-center',
              'p-4',
              'gap-x-3',
            )}>
            {/* Icon */}
            {iconElement}
            {/* Content */}
            <div className="flex flex-col gap-y-1 text-sm flex-grow">
              <span
                className="font-medium"
                data-testid={testId ? `${testId}--title` : undefined}>
                {!children ? title : renderDescription(children)}
              </span>
              {!children && description && (
                <div
                  className="opacity-70"
                  data-testid={testId ? `${testId}--description` : undefined}>
                  {renderDescription(description)}
                </div>
              )}
            </div>
          </WrapperComponent>
          {/* Actions area */}
          {!!actions && actions.length > 0 && (
            <div className="px-4 py-4 flex items-center">
              <ResponsiveActionSelect justify="end" actions={actions} />
            </div>
          )}
        </div>
      </div>
    </CollapseComponent>
  );
};

export default Alert;
