import {useCallback, useImperativeHandle} from 'react';

import {ModalDefinition} from 'components_sb/layout';
import {Paragraph} from 'components_sb/typography';

const UnsavedNotesWarningModal: ModalDefinition = {
  title: 'You have unsaved changes',
  buttonsConfig: {
    cancel: {
      label: 'No, continue editing',
    },
    actions: [
      {
        id: 'ignore-changes',
        label: 'Yes, cancel changes',
        handle: 'onIgnoreChanges',
      },
    ],
  },
  ContentComponent: (props, ref) => {
    const onCancel = useCallback(() => false, []);
    const onIgnoreChanges = useCallback(() => true, []);

    useImperativeHandle(ref, () => ({
      onCancel,
      onIgnoreChanges,
    }));

    return <Paragraph>Are you sure you want to cancel?</Paragraph>;
  },
};

export default UnsavedNotesWarningModal;
